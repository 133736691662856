<script setup></script>

<template>
  <div class="opp-1">
    <div class="flex flex-col gap-4 justify-center">
      <div class="opp-1-title">Удобный просмотр ресурсов</div>
      <div class="opp-1-text">
        Система позволяет просмотр видео-лекций, PDF-презентаций, ссылки и
        прослушивание аудио файлов
      </div>
    </div>
    <div>
      <img src="../../../../assets/images/landing/opp-1.png" />
    </div>
  </div>
</template>

<style scoped>
.opp-1 {
  background: #e0e5fd;
  border-radius: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  padding: 80px 40px;
}
.opp-1-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
}
.opp-1-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 650px) {
  .opp-1 {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
