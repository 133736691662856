<template>
  <TheCorePageHeader>
    <div class="flex-1 min-w-0">
      <div class="flex place-items-center">
        <button
          type="button"
          class="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
          @click="toggleSidebar"
        >
          <span class="sr-only">Open sidebar</span>
          <svg
            class="h-6 w-6"
            x-description="Heroicon name: outline/menu-alt-2"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M4 6h16M4 12h16M4 18h7"
            ></path>
          </svg>
        </button>
        <TheLogo class="hidden md:block mr-2" route-name="TheLandingPage" />

        <router-link :to="{ name: 'DashboardPage' }" class="hidden md:block">
          <TheButton
            bg="link"
            :class="
              currentRoute === 'all' ? 'text-indigo-600' : 'text-gray-500'
            "
            style="font-weight: 400"
          >
            {{ $t("all_courses") }}
          </TheButton>
        </router-link>
        <router-link
          :to="{ name: 'MyCourses' }"
          v-if="isAuthenticated"
          class="hidden md:block pr-3"
        >
          <TheButton
            :bg="isMyCoursesRoute ? 'link-active' : 'link'"
            style="font-weight: 400"
          >
            {{ $t("my_education") }}
          </TheButton>
        </router-link>
        <GlobalSearchInput />
      </div>
    </div>
    <div class="flex place-items-center" v-if="!isAuthenticated">
      <TheLocaleSelector />
      <router-link :to="{ name: 'Login' }">
        <TheButton bg="link"> {{ $t("enter") }}</TheButton>
      </router-link>
      <!--      <router-link :to="{name:'Registration'}">-->
      <!--        <TheButton bg="primary"> {{$t('to_register')}}</TheButton>-->
      <!--      </router-link>-->
    </div>
    <Menu as="div" class="ml-3 relative" v-else>
      <div>
        <MenuButton
          class="pr-3 max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <Avatar :url="active_user.avatar" />
        </MenuButton>
      </div>
      <TheTransition>
        <MenuItems
          class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <MenuItem>
            <TheButton
              class="block text-gray-700 w-full"
              bg="link"
              size="sm"
              @click="profile"
            >
              {{ $t("profile") }}
            </TheButton>
          </MenuItem>
          <MenuItem>
            <TheButton
              class="block text-gray-700 w-full"
              bg="link"
              size="sm"
              @click="logout"
            >
              {{ $t("logout") }}
            </TheButton>
          </MenuItem>
        </MenuItems>
      </TheTransition>
    </Menu>
  </TheCorePageHeader>
</template>
<script>
import TheButton from "@/components/Button/Button";
import ThePageHeaderMixin from "@/components/ThePageHeader/ThePageHeaderMixin";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import Avatar from "@/components/UserAvatar";
import TheTransition from "@/components/TheTransition";
import TheLocaleSelector from "@/components/TheLocaleSelector/TheLocaleSelector";
import GlobalSearchInput from "@/components/GlobalSearch/GlobalSearchInput";
import TheCorePageHeader from "@/components/ThePageHeader/TheCorePageHeader";
import TheLogo from "@/assets/images/Logo";

export default {
  components: {
    TheLogo,
    TheCorePageHeader,
    GlobalSearchInput,
    TheLocaleSelector,
    TheTransition,
    Avatar,
    TheButton,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  mixins: [ThePageHeaderMixin],
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    isMyCoursesRoute() {
      const routes = [
        "WaitingForStart",
        "MyCourses",
        "CurrentCourses",
        "CourseOverview",
        "CourseContent",
      ];
      return routes.indexOf(this.currentRoute) > -1;
    },
  },
};
</script>
