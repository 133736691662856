<template>
  <div>
  <div class="border-gray-300   w-full cursor-pointer overflow-hidden" :class="showDropzone?'border-2 border-dashed rounded-xl':''">
    <div  v-if="!showDropzone">
      <div
          v-if="type==='image'"
          class="flex dropzone bg-contain bg-no-repeat bg-center place-items-center align-middle place-content-center"
          :style="`background-image:url(${previewFileUrl});height: 226px`">
      </div>
      <audio v-if="type==='audio'" controls :src="previewFileUrl" class="w-full mb-6" controlsList="nodownload">
        Your browser does not support the
        <code>audio</code> element.
      </audio>
      <video width="400" height="300" controls="controls" v-if="type==='video'">
        <source :src="previewFileUrl" ref="video_source">
      </video>
      <PresentationContent :content="previewFileUrl" v-if="type==='presentation'"  width="100"/>
    </div>
      <div v-bind="getRootProps()" :class="showDropzone?'':'hidden'" ref="dropzoneRef">
        <input
            v-bind="getInputProps()"
            @input="handleFilesChange"
            :multiple="multiple"
            :accept="accept"
        >
        <div class="w-full flex place-items-center place-content-center text-center" style="height: 226px">
          <div class="flex place-items-center flex-col">
            <div class="inline-block text-gray-600 text-sm leading-5 font-medium">
              {{$t('to_move')}} {{' '}} {{$t('or')}} {{' '}}<span  class="text-indigo-600">{{$t('select_file')}}</span>
            </div>
            <div class="text-xs leading-4 font-normal text-gray-500 ">
              {{$t(secondaryLabel,{amount:10})}}
            </div>
          </div>
        </div>
      </div>
  </div>
  <div :class="additionalClass"  v-if="!showDropzone">
    <div class="flex flex-col">
      <TheButton bg="secondary" @click="clickInput" class="mb-2">
        <PencilIcon class="h-3 mr-2"/>
        {{$t(updateButtonLabel)}}
      </TheButton>
      <TheButton bg="outline" @click="removeFile">
        {{$t(deleteButtonLabel)}}
      </TheButton>
    </div>
  </div>
  </div>
</template>

<script>
import { useDropzone } from 'vue3-dropzone'
import TheButton from "@/components/Button/Button";
import {PencilIcon} from "@heroicons/vue/outline"
import PresentationContent from "@/components/Resources/ResourceBlock/PresentationContent";
export default {
  name: 'FileDropzone',
  props:{
    accept:{
      type:String,
      default:"image/*"
    },
    type:{
      type:String,
      default:'image'
    },
    secondaryLabel:{
      type:String,
      default:'png_jpg_no_more_than_n_mb'
    },
    modelValue:{},
    multiple:{
      type:Boolean,
      default:false
    },
    updateButtonLabel:{
      type:String,
      default:'update_photo'
    },
    deleteButtonLabel:{
      type:String,
      default:'delete'
    },
    additionalClass: {
      type:String,
      default:'mt-4'
    }
  },
  components:{
    PresentationContent,
    TheButton,
    PencilIcon
  },
  methods:{
    clickInput(){
      this.$refs.dropzoneRef.click();
    },
    removeFile(){
      this.$emit('update:modelValue',null)
    },
    handleFilesChange($event){
      if(this.multiple){
        this.$emit('update:modelValue', $event.target.files)
      }else{
        this.$emit('update:modelValue', $event.target.files[0])
      }
      this.$nextTick(()=>{
        this.$forceUpdate();
        this.$emit('change')
      })
    }
  },
  computed:{
    previewFileUrl(){
      if(!this.modelValue){
        return null
      }
      if (typeof this.modelValue === 'string' || this.modelValue instanceof String){
        return this.modelValue
      }
      if(!this.multiple && this.modelValue){
        return URL.createObjectURL(this.modelValue)
      }
      return null
    },
    showDropzone(){
      return !this.previewFileUrl
    }
  },
  watch:{
    previewFileUrl(newVal){
      if(newVal && this.type==='video'){
        this.$nextTick(()=>{
          // console.log(this.$refs.video_source.parent)
          this.$refs.video_source.parent()[0].load();
        })

      }
    }
  },
  setup() {

    const { getRootProps, getInputProps, ...rest } = useDropzone({  })

    return {
      getRootProps,
      getInputProps,
      ...rest
    }
  }
}
</script>
<!--<style scoped>-->
<!--  .dropzone:hover svg{-->
<!--      display: block;-->
<!--      opacity: 1;-->
<!--  }-->
<!--  .dropzone:hover{-->
<!--    opacity: 0.5;-->
<!--  }-->
<!--</style>-->