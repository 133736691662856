<template>
  <div class="bg-indigo-100">
    <main
      ref="formSection"
      class="relative flex-1 pt-16 px-8 mx-10 flex items-center justify-center flex-col mb-12 adaptive-form"
    >
      <TheCard
        class="flex items-center justify-center flex-col px-16 py-16 mb-10 adaptive-card"
      >
        <div class="mb-4 gap-4 grid adaptive-card-div">
          <p class="text-3xl font-bold text-center form-title">
            {{ $t("want_to_study_in_our_academy") }}
          </p>
          <p class="text-gray-500 text-center form-subtitle">
            {{ $t("landing_form_description") }}
          </p>
        </div>
        <div class="w-full grid gap-4">
          <InputText
            :placeholder="$t('organization_name')"
            v-model="companyName"
            :style="[isCompanyName ? '' : 'border: 1px solid rgb(239 68 68);']"
            is-required
          />

          <InputText
            :placeholder="$t('how_to_call_you')"
            v-model="personName"
            :style="[isPersonName ? '' : 'border: 1px solid rgb(239 68 68);']"
            is-required
          />
          <InputText
            :placeholder="$t('contact_phone')"
            v-model="phone"
            :style="[isPhone ? '' : 'border: 1px solid rgb(239 68 68);']"
            is-phone-number
            is-required
          />
          <InputText
            type="email"
            :placeholder="$t('your_email_optional')"
            v-model="email"
          />
          <InputText
            :placeholder="$t('which_program')"
            v-model="message"
            :style="[isMessage ? '' : 'border: 1px solid rgb(239 68 68);']"
            is-required
          />
          <p class="text-gray-500" v-if="isForm">
            {{ $t("form_request_done") }}
          </p>
          <p class="text-gray-500" v-if="isERR">Введите правильный email!</p>
        </div>
        <TheButton bg="primary" class="mt-4 w-full" @click="handleSend"
          >Оставить заявку
        </TheButton>
      </TheCard>
    </main>
  </div>
</template>

<script>
import TheCard from "@/components/TheCard/TheCard";
import InputText from "@/components/InputText";
import TheButton from "@/components/Button/Button";
import { mapActions } from "vuex";
import { SEND_LANDING_FORM } from "@/store/resources";
import axios from "axios";

export default {
  name: "TheLandingForm",
  components: { TheButton, InputText, TheCard },
  props: {
    formData: {
      type: Object,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(newFormData) {
        this.personName = newFormData.personName || this.personName;
        this.phone = newFormData.phone || this.phone;
        this.email = newFormData.email || this.email;
      },
    },
  },
  data() {
    return {
      companyName: "",
      personName: this.formData.personName || "",
      phone: this.formData.phone || "",
      email: this.formData.email || "",
      message: "",
      isCompanyName: true,
      isPersonName: true,
      isPhone: true,
      isMessage: true,
      isForm: false,
      isERR: false,
    };
  },
  methods: {
    ...mapActions([SEND_LANDING_FORM]),
    handleSend() {
      this.isCompanyName = this.companyName !== "";
      this.isPhone = this.phone !== "";
      this.isMessage = this.message !== "";
      this.isPersonName = this.personName !== "";

      if (this.email && !this.email.includes("@")) {
        this.isERR = true;
        return;
      } else {
        this.isERR = false;
      }
      if (
        this.isCompanyName &&
        this.isPersonName &&
        this.isPhone &&
        this.isMessage
      ) {
        axios
          .post(
            "https://alphaedu.tech/api/v1/application_forms/want_to_learn_application/",
            {
              name: this.personName,
              organization_name: this.companyName,
              contact_phone: this.phone,
              email: this.email,
              program: this.message,
            }
          )
          .catch((err) => {
            console.log(err);
          });
        this.companyName = "";
        this.email = "";
        this.phone = "";
        this.message = "";
        this.personName = "";

        this.isForm = true;
        setTimeout(() => {
          this.isForm = false;
        }, 4000);
      }
    },
  },
};
</script>

<style scoped>
.adaptive-card {
  width: 608px;
}

.adaptive-card-div {
  width: 90%;
}
@media (max-width: 650px) {
  .adaptive-form {
    padding: 40px 4px;
  }

  .adaptive-card {
    width: 100%;
  }
  .adaptive-card-div {
    width: 100%;
  }

  .form-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }

  .form-subtitle {
    font-size: 16px;
  }
}
</style>
