<script>
import { defineComponent } from "vue";
import ButtonTemplate from "@/views/courses/CourseEdit/Attendance/ButtonTemplate.vue";
import GroupSelector from "@/components/Selectors/GroupSelector.vue";
import CategorySelector from "@/components/Selectors/CategorySelector.vue";
import GradeSelector from "@/components/Selectors/GradeSelector.vue";
import TheTable from "@/components/TheTable/TheTable.vue";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell.vue";
import TheTableRow from "@/components/TheTable/TheTableRow.vue";
import TheTableBody from "@/components/TheTable/TheTableBody.vue";
import TheTableHead from "@/components/TheTable/TheTableHead.vue";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell.vue";
import StudentsGradesFetchMixin from "@/views/courses/StudentGrades/StudentsGradesFetchMixin";
import AvatarPlaceholder from "@/views/courses/CourseEdit/Attendance/AvatarPlaceholder.vue";
import PurpleAdminButton from "@/views/certificates/PurpleAdminButton.vue";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs.vue";
import { mapActions } from "vuex";
import { GET_COURSE, GET_COURSE_GROUP } from "@/store/courses";
import { FinalGradeSelector } from "@/components/Selectors/GradeSelector.vue";

export default defineComponent({
  name: "StudentGrades",
  components: {
    Breadcrumbs,
    PurpleAdminButton,
    AvatarPlaceholder,
    TheTableHeadCell,
    TheTableHead,
    TheTableBody,
    TheTableRow,
    TheTableRowCell,
    TheTable,
    GradeSelector,
    CategorySelector,
    GroupSelector,
    ButtonTemplate,
  },
  mixins: [StudentsGradesFetchMixin],
  async mounted() {
    this.course_id = this.$route.params.id;
    this.course = await this.$store.dispatch(GET_COURSE, this.course_id);
    this.breadcrumbs = [
      {
        name: this.$t("courses"),
        path: "/courses/list",
      },
      {
        name: this.course.name,
        path: `/courses/${this.course_id}/edit`,
      },
      {
        name: this.$t("grades"),
        path: this.$route.path,
      },
    ];
    setTimeout(() => {
      this.loading = "Сохранить";
    }, 5000);
  },
  methods: {
    ...mapActions([GET_COURSE_GROUP]),
    updateCategories(categories) {
      this.categories = categories.filter((c) => c.selected);
    },
    updateGradeSelector(gradeSelector) {
      this.gradeSelector = gradeSelector;
    },
  },
  data() {
    return {
      gradeSelector: FinalGradeSelector.All,
      search: "",
      course: null,
      categories: [],
      category_id: null,
      selector_id: null,
      course_id: null,
      group_id: null,
      breadcrumbs: [],
      fetched: false,
      loading: "Сохранить",
      grades: [
        {
          name: "Айман Сеитова",
          email: "aiman.seitova@gmail.com",
          avatar: null,
          grades: [92, 2, 3, 89, 98, 77, 66],
        },
      ],
    };
  },
});
</script>

<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <div class="student-grades flex flex-col gap-6 pt-2">
    <div class="flex justify-between">
      <h1>Оценки</h1>
      <div class="flex gap-4">
        <div class="">
          <div class="search-text flex gap-1 items-center">
            <div>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
                  fill="#9CA3AF"
                />
              </svg>
            </div>
            <div>
              <input
                v-model="search"
                placeholder="Поиск по ФИО"
                type="search"
              />
            </div>
          </div>
        </div>
        <ButtonTemplate
          class-names="btn-download"
          @click="downloadGrades($event)"
          ><div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33398 13.3327L3.33398 14.166C3.33398 15.5467 4.45327 16.666 5.83398 16.666L14.1673 16.666C15.548 16.666 16.6673 15.5467 16.6673 14.166L16.6673 13.3327M13.334 9.99935L10.0007 13.3327M10.0007 13.3327L6.66732 9.99935M10.0007 13.3327L10.0006 3.33268"
                stroke="#4F46E5"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div>Выгрузить таблицу</div></ButtonTemplate
        >
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <div class="flex gap-4">
        <div class="flex-1 flex flex-col gap-2">
          <div class="selector-label">Группа</div>
          <GroupSelector
            label="name"
            :course_id="course_id"
            v-model="group_id"
            :placeholder="$t('select_group')"
            class="text-sm leading-5 font-medium border-none bg-white selector__box"
          />
        </div>
        <div class="flex-1 flex flex-col gap-2">
          <div class="selector-label">Категория задания</div>
          <CategorySelector
            label="name"
            :course_id="course_id"
            @update-selected-categories="updateCategories"
            placeholder="Все категории"
            class="text-sm leading-5 font-medium border-none bg-white selector__box"
          />
        </div>
        <div class="flex-1 flex flex-col gap-2">
          <div class="selector-label">Итоговый балл</div>
          <GradeSelector
            label="name"
            :course_id="course_id"
            v-model="selector_id"
            @update-grade-selector="updateGradeSelector"
            placeholder="Все"
            class="text-sm leading-5 font-medium border-none bg-white selector__box"
          />
        </div>
      </div>
      <div class="student-grades__box">
        <div class="flex flex-col gap-6 mt-8" v-if="hasStudents">
          <TheTable>
            <TheTableHead>
              <TheTableHeadCell>
                <div class="flex flex-col px-2 py-1">
                  <div
                    class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                  >
                    Имя
                  </div>
                </div>
              </TheTableHeadCell>
              <TheTableHeadCell
                v-for="(grade, index) in grade_categories"
                :key="index"
              >
                <div class="flex flex-col px-2 py-1">
                  <div
                    class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                  >
                    {{ grade.name }}
                  </div>
                  <div class="text-xs font-medium tracking-wider text-gray-500">
                    из {{ grade.weight }}
                  </div>
                </div>
              </TheTableHeadCell>
              <TheTableHeadCell>
                <div class="flex flex-col px-2 py-1">
                  <div
                    class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                  >
                    Total
                  </div>
                  <div class="text-xs font-medium tracking-wider text-gray-500">
                    из 100
                  </div>
                </div>
              </TheTableHeadCell>
            </TheTableHead>
            <TheTableBody>
              <template v-for="(st, index) of students" :key="index">
                <TheTableRow>
                  <TheTableRowCell>
                    <div class="flex gap-4 justify-start">
                      <div class="pl-4">
                        <img
                          :src="st.avatar"
                          alt="Avatar"
                          class="avatar"
                          v-if="st.avatar"
                        />
                        <div v-else>
                          <AvatarPlaceholder :student="st" />
                        </div>
                      </div>
                      <div class="flex flex-col">
                        <div
                          class="text-sm font-medium tracking-wider text-gray-900"
                        >
                          {{ st.first_name + " " + st.last_name }}
                        </div>
                        <div class="">{{ st.email }}</div>
                      </div>
                    </div>
                  </TheTableRowCell>
                  <template
                    v-for="(grade, categoryIndex) in grades[st.id]"
                    :key="categoryIndex"
                  >
                    <TheTableRowCell>
                      <div v-if="categoryIndex !== 'total'">
                        <input
                          v-model="grades[st.id][categoryIndex]"
                          class="student-grades__grade-box text-sm leading-5 font-normal px-2 py-1"
                          type="text"
                          placeholder="Enter grade"
                        />
                      </div>
                      <div v-else>
                        <div class="text-sm leading-5 font-normal px-2 py-1">
                          <span
                            class="text-sm leading-5 font-medium text-gray-900"
                          >
                            {{ grade }}
                          </span>
                        </div>
                      </div>
                    </TheTableRowCell>
                  </template>
                </TheTableRow>
              </template>
            </TheTableBody>
          </TheTable>
          <div class="flex justify-end items-center">
            <PurpleAdminButton @click="createCoursePoints">{{
              this.loading
            }}</PurpleAdminButton>
          </div>
        </div>
        <div v-else class="flex flex-col items-center pt-6">
          {{
            this.fetched
              ? "По этому запросу нет результатов."
              : "Выберите группу для отображения"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.student-grades {
}
h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #111827;
}

input {
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  width: 280px;
}

input:focus {
  box-shadow: none;
}

.search-text {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 9px 13px;
  height: 38px;
  width: 360px;
  background: white;
}
.selector-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #374151;
}
.student-grades__box {
  border-radius: 12px;
  padding: 0 24px 24px 24px;
  background: white;
  overflow: hidden;
}
.student-grades__avatar {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  flex-shrink: 0;
  flex-grow: 0;
}
.student-grades__grade-box {
  width: 116px;
  border: 1px solid #d1d5db;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-radius: 6px;
  padding: 8px 12px;
  color: #111827;
}
.student-grades__grade-box:focus {
  border: 1px solid #4f46e5;
}
.selector__box {
  border-radius: 6px;
}
.avatar {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}
</style>
