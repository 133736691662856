<template>
  <div class="px-8 pt-7">
    <div class="flex justify-between">
      <h1 class="text-2xl leading-8 font-semibold mb-4">{{ $t("content") }}</h1>
      <!-- <router-link :to="{name:'ResourceEdit'}" class="hidden md:block">
        <TheButton bg="secondary">
          <PencilIcon class="flex h-4 mr-2"/>
          Редактировать
        </TheButton>
      </router-link> -->
    </div>

    <div
      class="bg-white rounded-xl p-6 mb-6"
      v-for="resource in resources"
      :key="`course_resources_${resource.id}`"
    >
      <h5
        class="flex w-full cursor-pointer"
        @click="resource.is_closed = !resource.is_closed"
      >
        <span class="flex-auto">
          <span class="text-xl leading-7 font-medium" v-if="resource.prefix"
            >{{ resource.prefix }}:</span
          >
          <span class="text-xl leading-7 font-semibold">{{
            resource.name
          }}</span>
        </span>
        <component
          :is="resource.is_closed ? 'ChevronUpIcon' : 'ChevronDownIcon'"
          class="h-6"
        />
      </h5>
      <div v-if="!resource.is_closed">
        <component
          :is="child.is_available ? 'router-link' : 'span'"
          :to="child.route"
          v-for="child in resource.resources"
          :key="`course_content_${child.id}`"
          class="cursor-pointer"
        >
          <div class="flex py-5 text-base leading-6 font-medium">
            <span class="flex-1 flex place-items-center">
              <component
                :is="
                  child.type === 'lesson'
                    ? 'BookOpenIcon'
                    : 'ClipboardCheckIcon'
                "
                class="text-gray-600 h-5 w-5"
              />
              <span class="ml-2">
                {{ child.title }}
              </span>
            </span>
            <span class="flex flex-row place-items-center">
              <!-- <span class="mr-3">{{child.duration}}</span> -->
              <LockClosedIcon
                class="h-5 text-gray-600"
                v-if="!child.is_available"
              />
              <InputCheckbox
                :checked="child.is_passed"
                :disabled="false"
                v-else
              />
            </span>
          </div>

          <hr />
        </component>
      </div>
    </div>
  </div>
</template>
<script>
import { LockClosedIcon } from "@heroicons/vue/solid";
import {
  BookOpenIcon,
  ClipboardCheckIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PencilIcon,
} from "@heroicons/vue/outline";
import InputCheckbox from "@/components/InputCheckbox";
import TheButton from "@/components/Button/Button";
import { mapActions } from "vuex";
import { GET_RESOURCES } from "@/store/resources";
import { mapFields } from "vuex-map-fields";
import { GET_COURSE } from "@/store/courses";
export default {
  components: {
    InputCheckbox,
    LockClosedIcon,
    BookOpenIcon,
    ClipboardCheckIcon,
    ChevronUpIcon,
    ChevronDownIcon,
    PencilIcon,
    TheButton,
  },
  computed: {
    ...mapFields(["resources.resources"]),
  },
  methods: {
    ...mapActions([GET_RESOURCES, GET_COURSE]),
  },
  created() {
    const { id } = this.$route.params;
    this.GET_COURSE(id);
    this.GET_RESOURCES({ course_id: id });
  },
};
</script>
