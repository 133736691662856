import {mapFields} from "vuex-map-fields";
import { mapActions } from "vuex";
import { DELETE_QUESTION, DELETE_CONTENT } from "@/store/resources";

export default {
    props:{
        attachments:[],
        modelValue:{},
        _key:{
            required:true,
            type:String
        },
        content_name:{
            type:String,
            required:true
        },
        show_attachments_button:{
            type:Boolean,
            default:true
        },
        type:{
            type:String
        },
        criteria:[],
        threshold:{
            type:Number,
            default:0
        },
        graders_count:{
            type:Number,
            default:0
        },
        peer_assessment:{
            type:Boolean,
            default:true
        },
        is_threshold:Boolean,
        is_valid_threshold:Boolean,
        is_number_graders:Boolean
    },
    data:()=>({
        localThreshold:0
    }),
    computed:{
      ...mapFields(['resources.form']),
      is_peer_assessment:{
        set(val){
            this.$emit('peerAssessmentClicked', val)
        },
        get(){
            return this.peer_assessment
        }
      },
      local_graders_count:{
        set(val){
            this.$emit('gradersCountChanged', val)
        },
        get(){
            return this.graders_count
        }
      },
      thresholdErrors(){
        if(this.is_threshold){
            return this.$t('enter_threshold')
        } else if(this.is_valid_threshold){
            return this.$t('threshold_bigger')
        } else {
            return ''
        }
      }
    },
    methods:{
        ...mapActions([DELETE_QUESTION, DELETE_CONTENT]),
        async handleRemoveButtonClicked(){
            if(this.type.includes('_question')){
                await this.DELETE_QUESTION(this.modelValue)
            } else {
                await this.DELETE_CONTENT(this.modelValue)
            }
            const {current_page,pages} = this.form
            pages[current_page-1].contents = pages[current_page-1].contents.filter(el=>{
                return el.id !== this.modelValue
            })
            
            
        }
    },
    created(){
        this.localThreshold = this.threshold
    }
}