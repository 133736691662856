<template>
  <div class="relative flex items-start">
    <div class="flex items-center h-5">
      <input
        type="checkbox"
        class="focus:ring-indigo-700 h-4 w-4 text-indigo-600 border-gray-300 rounded"
        v-bind="$attrs"
        v-model="$attrs['modelValue']"
        :id="$attrs['htmlId']"
      />
    </div>
    <div class="ml-3 text-sm">
      <slot name="label" />
    </div>
  </div>
</template>
