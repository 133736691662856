<template>
  <ContentBlockForm
    :content_name="$t('audio_question_label')"
    :show_attachments_button="false"
    :_key="_key"
    :attachments="attachments"
  >
    <TheQuillEditorInput class="mb-4" v-model="content" @change="emitUpdate" />
    <audio
      v-if="previewFileUrl"
      controls
      :src="previewFileUrl"
      class="w-full mb-6"
      controlsList="nodownload"
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
    <div>
      <div
        v-for="(question_answer, index) in available_answers"
        :key="`answer_${question_answer.id || question_answer._key}`"
        class="cursor-pointer w-full bg-gray-50 p-2 inline-flex mb-2 rounded-l place-items-center"
      >
        <input
          type="radio"
          v-model="correct_answer"
          :value="question_answer.id || question_answer._key"
          class="mr-2"
          @change="emitUpdate"
        />
        <InputText
          additionalClass="w-full"
          :placeholder="$t('option_with_number', { number: index + 1 })"
          v-model="question_answer.text"
          @change="emitUpdate"
        />
        <TrashIcon
          v-if="available_answers.length > 2"
          class="h-5 cursor-pointer text-gray-700"
          @click="
            handleRemoveAnswerButtonClicked(
              question_answer.id,
              question_answer._key
            )
          "
        />
      </div>
    </div>
    <input
      type="file"
      accept="audio/*"
      ref="audioRef"
      hidden
      @change="handleFileUpload"
    />

    <div class="inline-flex mt-3">
      <TheButton bg="outline" class="mr-4" @click="$refs.audioRef.click()">
        <PaperClipIcon class="h-4 mr-2" />
        {{ $t("attach_audio") }}
      </TheButton>
      <TheButton bg="secondary" @click="addAvailableAnswer">
        <PlusIcon class="h-5 mr-2" />
        {{ $t("add_answer_option") }}
      </TheButton>
    </div>
  </ContentBlockForm>
</template>
<script>
import ContentBlockForm from "@/views/resources/Form/components/ContentBlockForm";
import TheQuillEditorInput from "@/components/TheQuillEditorInput";
import QuestionMixin from "@/views/resources/Form/components/QuestionMixin";
import TheButton from "@/components/Button/Button";
import { PaperClipIcon, PlusIcon, TrashIcon } from "@heroicons/vue/outline";
import InputText from "@/components/InputText";
export default {
  components: {
    InputText,
    TheButton,
    TheQuillEditorInput,
    ContentBlockForm,
    PaperClipIcon,
    PlusIcon,
    TrashIcon,
  },
  mixins: [QuestionMixin],
  data: () => ({
    src: "",
    content: {},
    correct_answer: "",
    available_answers: [],
  }),
  methods: {
    handleFileUpload(e) {
      this.src = e.target.files[0];
      this.emitUpdate();
    },
    generateNewAvailableAnswer() {
      return {
        text: "",
        _key: this.generateRandomKey(),
      };
    },
    addAvailableAnswer() {
      this.available_answers.push(this.generateNewAvailableAnswer());
    },
    emitUpdate() {
      this.$emit("contentChange", {
        content: this.content,
        available_answers: this.available_answers,
        src: this.src,
      });
    },
  },
  computed: {
    previewFileUrl() {
      if (!this.src) return null;
      if (typeof this.src === "string" || this.src instanceof String) {
        return this.modelValue;
      }
      if (this.src) {
        return URL.createObjectURL(this.src);
      }
      return null;
    },
  },
  beforeUpdate() {
    if (this.available_answers) {
      if (this.available_answers.find((el) => el.is_selected)) {
        this.correct_answer =
          this.available_answers.find((el) => el.is_selected).id ||
          this.available_answers.find((el) => el.is_selected)._key;
      }
      this.emitUpdate();
    }
    while (this.available_answers.length < 4) {
      this.addAvailableAnswer();
    }
  },
  created() {
    if (this.available_answers) {
      if (this.available_answers.find((el) => el.is_selected)) {
        this.correct_answer =
          this.available_answers.find((el) => el.is_selected).id ||
          this.available_answers.find((el) => el.is_selected)._key;
      }
      this.emitUpdate();
    }
    while (this.available_answers.length < 4) {
      this.addAvailableAnswer();
    }
  },
};
</script>
