<template>
  <div class="bg-white rounded-xl max-w-screen-md overflow-hidden relative">
    <video class="w-full" controls="controls" ref="videoContainer" :src="localContent">
      <source :src="localContent" :key="localContent">
      Тег video не поддерживается вашим браузером.
    </video>
    <AttachmentsBlock :attachments="attachments"/>
  </div>
</template>
<script>
import AttachmentsBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
export default {
  components: {AttachmentsBlock},
  props:{
    content:{
      type:String,
      required:true
    },
    attachments:{
      type:Array,
      default:()=>[]
    }
  },
  computed:{
    localContent(){
      if (typeof this.content === 'string' || this.content instanceof String){
        return this.content
      }
      return URL.createObjectURL(this.content)
    }
  }
}
</script>