<template>
  <div class="px-8 pt-7">
    <h1 class="text-2xl leading-8 font-semibold mb-4">
      {{ $t("my_attendance") }}
    </h1>
    <div class="flex flex-wrap">
      <div class="w-full">
        <TheCard class="flex flex-col gap-6">
          <div class="flex justify-between items-center">
            <div class="w-2/5 flex justify-between">
              <AttendanceDatePicker
                v-model="start_date"
                :lower-limit="null"
                :upper-limit="null"
                label="Начало периода"
              />
              <AttendanceDatePicker
                v-model="end_date"
                :lower-limit="start_date"
                :upper-limit="null"
                label="Конец периода"
              />
            </div>
            <div
              class="attendance__stat-perc flex justify-between items-center"
            >
              <div class="attendance__stat-perc-text">Поcещаемость (%)</div>
              <div>
                <span class="attendance__stat-perc-number">{{
                  attendance_perc
                }}</span
                >/100
              </div>
            </div>
          </div>

          <div class="attendance-grid">
            <div
              v-for="(item, index) in attendance"
              :key="index"
              :class="'attendance-item'"
            >
              <div v-if="item.present !== null" class="">
                <div class="attendance__calendar-dates">
                  {{ item.date }}
                </div>
                <div class="attendance__calendar-presence">
                  <component :is="dynamicComponent(item.present)"></component>
                </div>
              </div>
              <div v-else>
                <div class="attendance__calendar-no-dates">
                  {{ item.date }}
                </div>
                <div class="attendance__calendar-presence"></div>
              </div>
            </div>
          </div>
        </TheCard>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { GET_COURSE, START_COURSE_REQUEST } from "@/store/courses";

import TheCard from "@/components/TheCard/TheCard";
import { mapFields } from "vuex-map-fields";
import OutlineIconsMixin from "@/components/TheIconSelector/OutlineIconsMixin";
import BigAttendanceAbsentIcon from "@/assets/icons/BigAttendanceAbsentIcon.vue";
import BigAttendancePresentIcon from "@/assets/icons/BigAttendancePresentIcon.vue";
import DatePeriodSelector from "@/components/Selectors/DatePeriodSelector.vue";
import Datepicker from "vue3-datepicker";
import { GET_MY_ATTENDANCE } from "@/store/attendance";
import moment from "moment/moment";
import { GET_MY_COURSE_GROUPS } from "@/store/student-users";
import AttendanceDatePicker from "@/views/courses/MyAttendance/AttendanceDatePicker.vue";

export default {
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    attendance_perc: 0,
    start_date: new Date(),
    end_date: new Date(),
    show_cant_start_error: false,
    attendance: [],
    attendance2: [
      { date: "01.04.2024", present: true },
      { date: "01.04.2024", present: false },
      { date: "01.04.2024", present: true },
      { date: "01.04.2024", present: false },
      { date: "01.04.2024", present: true },
      { date: "01.04.2024", present: false },
      { date: "01.04.2024", present: true },
      { date: "01.04.2024", present: false },
      { date: "01.04.2024", present: true },
      { date: "01.04.2024", present: false },
      { date: "01.04.2024", present: true },
      { date: "01.04.2024", present: false },
      { date: "01.04.2024", present: true },
    ],
    chunkSize: 9,
    group: null,
    course_id: null,
  }),

  mixins: [OutlineIconsMixin],
  components: {
    AttendanceDatePicker,
    Datepicker,
    DatePeriodSelector,
    BigAttendancePresentIcon,
    BigAttendanceAbsentIcon,
    TheCard,
  },

  methods: {
    ...mapActions([
      GET_COURSE,
      START_COURSE_REQUEST,
      GET_MY_ATTENDANCE,
      GET_MY_COURSE_GROUPS,
    ]),
    async startCourse() {
      try {
        await this[START_COURSE_REQUEST](this.id);
        this.$router.push({
          name: "CourseContent",
          params: { id: this.id },
          replace: true,
        });
      } catch (e) {
        this.show_cant_start_error = true;
        // setTimeout(()=>this.show_cant_start_error=false,2000)
      }
    },
    async getMyAttendance() {
      const data = await this[GET_MY_ATTENDANCE]({
        course: this.group,
        date_from: moment(this.start_date).format("YYYY-MM-DD"),
        date_to: moment(this.end_date).format("YYYY-MM-DD"),
      });
      this.attendance_perc = data?.attendance_true_percentage;
      this.attendance = data?.dates.map((d) => ({ ...d, present: d.attended }));

      // data?.attendance_true_percentage;
      // data?.dates.map((d) => ({ ...d, present: d.attended }));
    },
    updateItemClasses() {
      this.$nextTick(() => {
        const items = this.$el.querySelectorAll(".attendance-item");
        const dates = this.$el.querySelectorAll(".attendance__calendar-dates");

        let previousTop = null;
        items.forEach((item, index) => {
          item.classList.remove("first-in-row", "last-in-row");

          const currentTop = item.getBoundingClientRect().top;

          if (previousTop !== currentTop) {
            if (index !== 0) {
              items[index - 1].classList.add("last-in-row");
            }
            item.classList.add("first-in-row");
          }

          previousTop = currentTop;

          if (index === items.length - 1) {
            item.classList.add("last-in-row");
          }
        });
        previousTop = null;
        dates.forEach((date, index) => {
          date.classList.remove("date-first-in-row", "date-last-in-row");

          const currentTop = date.getBoundingClientRect().top;

          if (previousTop !== currentTop) {
            date.classList.add("date-first-in-row");

            if (index !== 0) {
              dates[index - 1].classList.add("date-last-in-row");
            }
          }
          previousTop = currentTop;

          if (index === dates.length - 1) {
            date.classList.add("date-last-in-row");
          }
        });
      });
    },
  },
  computed: {
    ...mapFields(["courses.course"]),
    totalHours() {
      try {
        return this.course.duration.split(":")[0] * 1;
      } catch (e) {
        return "0";
      }
    },
    dynamicComponent() {
      return (present) =>
        present ? "BigAttendancePresentIcon" : "BigAttendanceAbsentIcon";
    },
  },
  async mounted() {
    window.addEventListener("resize", this.updateItemClasses);

    this.course_id = this.$route.params.id;
    const groups = await this[GET_MY_COURSE_GROUPS]();
    this.group = groups.find((gr) => gr.course === parseInt(this.course_id)).id;
    let newEndDate = new Date();
    let newStartDate = new Date(newEndDate.getTime());
    newStartDate.setDate(newEndDate.getDate() - 7);
    this.start_date = newStartDate;
    this.end_date = newEndDate;
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateItemClasses);
  },
  watch: {
    async start_date() {
      await this.getMyAttendance();
    },
    async end_date() {
      await this.getMyAttendance();
    },
    attendance(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateItemClasses();
      }
    },
  },
};
</script>
<style scoped>
.attendance__stat-perc {
  background: #f9fafb;
  border-radius: 12px;
  padding: 16px;
  width: 300px;
}
.attendance__stat-perc-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #1f2937;
}
.attendance__stat-perc-number {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #059669;
}
.attendance__calendar {
  border: 1px solid #e5e7eb;
  box-shadow: 0px 1px 3px 0px #0000001a;
  border-radius: 8px;
}
.attendance__calendar-dates {
  border-bottom: 1px solid #e5e7eb;
  background: #f9fafb;
  color: #6b7280;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: left;
  padding: 12px 24px;
}
.attendance__calendar-presence {
  padding: 26px 45px;
}

.attendance__calendar-no-dates {
  border-bottom: 1px solid #e5e7eb;
  background: #f9fafb;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: left;
  padding: 12px 24px;
  color: #f9fafb;
}
.calendar__div {
  border-radius: 6px;
  border: 1px solid rgba(60, 60, 60, 0.26);
}

.dp-custom-input {
  outline: none;
  border: none;
}
.dp-custom-input:focus {
  outline: none;
  border: none;
}
.icon__calendar {
  position: absolute;
  right: 16px;
  top: 7px;
  z-index: 10;
}

.attendance-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 24px 0;
}

.attendance-item {
  background: #fff;
  box-sizing: border-box;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
  box-shadow: 0px 5px 5px -5px rgba(0, 0, 0, 0.06);
}

.first-in-row {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-left: 1px solid #e5e7eb;
  box-shadow: -1px 0 5px 1px rgba(0, 0, 0, 0.06);
}

.last-in-row {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-right: 1px solid #e5e7eb;
  box-shadow: 1px 0 5px -1px rgba(0, 0, 0, 0.06);
}

.date-first-in-row {
  border-top-left-radius: 8px;
}

.date-last-in-row {
  border-top-right-radius: 8px;
}
</style>
