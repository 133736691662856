<template>
  <span
    class="inline-flex items-center px-2.5 py-0.5 badge text-center"
    :style="{ borderColor: getBorderColor(programName) }"
  >
    <slot />
  </span>
</template>
<script>
export default {
  props: ["program"],
  data() {
    return {
      programColors: {
        Дизайн: "#FF9B3F",
        "Веб-разработка": "#8CD37A",
        "Data Science": "#1C4FDF",
        "Разработка игр": "#844FED",
        Менеджмент: "#3FBAFF",
        Программирование: "#C9B0FF",
      },
      programName: this.program, // Initialize with the program name
    };
  },
  methods: {
    getBorderColor(program) {
      return this.programColors[program] || "#8cd37a"; // Default color
    },
  },
};
</script>
<style lang="postcss" scoped>
.badge {
  background: white;
  border-radius: 24px;
  border-width: 2px;
  border-style: solid;
  padding: 6px 12px;
}
</style>
