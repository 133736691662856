<template>
  <div class="flex" style="width: calc(100% - 24px);">
    <img :src="logo" class="rounded-xl mr-3" style="width:108px"/>
    <div class="inline-flex flex-col justify-center place-content-around" style="width: calc(100% - 120px);">
      <CourseCardSpecializations :specializations="specializations"/>
      <span class="text-xl leading-7 font-semibold text-gray-900 truncate max-w-[95%]">{{name}}</span>
    </div>
  </div>
</template>
<script>
import CourseCard from "@/components/Courses/Card/CourseCard";

export default {
  mixins:[CourseCard],
}
</script>