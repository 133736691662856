<template>
  <div class="next-stage">
    <div class="flex flex-col gap-4">
      <div class="font-bold font-size-40px text-gray-800 adaptive-block-title">
        Следующий этап
      </div>
      <div class="font-normal text-lg text-gray-500">
        После первого года обучения у вас будет выбор, куда и как двигаться
        дальше
      </div>
    </div>

    <div class="adaptive-next-stage2">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="16"
        :pagination="pagination"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide>
          <div class="next-stage__gradient-item">
            <div
              class="next-stage__item px-10 py-8 flex flex-col gap-6 w-full h-full"
            >
              <div>
                <EmploymentIcon />
              </div>
              <div class="flex flex-col gap-3">
                <div class="font-semibold text-2xl text-gray-900">
                  Трудоустройство
                </div>
                <div class="font-normal text-lg text-gray-500">
                  Вы сможете претендовать на реальные вакансии и начать карьеру
                  в сфере ИТ. Мы подберем вам подходящие предложения, дадим
                  рекомендации к составлению портфолио и резюме. Подготовим к
                  собеседованию и поможем попасть в компанию мечты. У вас будет
                  3 месяца для того, чтобы определиться с местом работы.
                </div>
              </div>
            </div>
          </div></swiper-slide
        >
        <swiper-slide>
          <div class="next-stage__gradient-item">
            <div
              class="next-stage__item px-10 py-8 flex flex-col gap-6 h-full w-full"
            >
              <div>
                <StudyIcon />
              </div>
              <div class="flex flex-col gap-3">
                <div class="font-semibold text-2xl text-gray-900">
                  Продолжить обучение по специализации
                </div>
                <div class="font-normal text-lg text-gray-500">
                  Если захотите дальше развиваться в выбранной отрасли,
                  предложим программы обучения для еще более детального изучения
                  специализации или области.
                </div>
              </div>
            </div>
          </div></swiper-slide
        >
        <swiper-slide>
          <div class="next-stage__gradient-item">
            <div
              class="next-stage__item px-10 py-10 flex flex-col gap-8 h-full w-full"
            >
              <div>
                <BootcampIcon />
              </div>
              <div class="flex flex-col gap-3">
                <div class="font-semibold text-2xl text-gray-900">
                  Поучаствовать в Буткемпах
                </div>
                <div class="font-normal text-lg text-gray-500">
                  Сможете принять участие в мероприятиях, на которые мы будем
                  приглашать компании и решать задачи их бизнеса, чтобы
                  подготовиться к реальной работе.
                </div>
              </div>
            </div>
          </div></swiper-slide
        >
        <swiper-slide>
          <div class="next-stage__gradient-item">
            <div
              class="next-stage__item px-10 py-8 flex flex-col gap-6 h-full w-full"
            >
              <div>
                <InternshipIcon />
              </div>
              <div class="flex flex-col gap-3">
                <div class="font-semibold text-2xl text-gray-900">
                  Принять участие в cтажировках
                </div>
                <div class="font-normal text-lg text-gray-500">
                  Где совместно с другими участниками будете реализовывать идеи
                  проектов и отрабатывать приобретенные знания на практике.
                </div>
              </div>
            </div>
          </div></swiper-slide
        >
        <swiper-slide>
          <div class="next-stage__gradient-item">
            <div
              class="next-stage__item px-10 py-10 flex flex-col gap-8 h-full w-full"
            >
              <div>
                <JobIcon />
              </div>
              <div class="flex flex-col gap-3">
                <div class="font-semibold text-2xl text-gray-900">
                  Применить знания в текущей работе
                </div>
                <div class="font-normal text-lg text-gray-500">
                  Сможете адаптировать полученные знания и навыки в вашей
                  текущей работе.
                </div>
              </div>
            </div>
          </div></swiper-slide
        >
      </swiper>
    </div>
    <div class="adaptive-next-stage">
      <div class="next-stage__gradient-item">
        <div
          class="next-stage__item px-10 py-8 flex flex-col gap-6 w-full h-full"
        >
          <div>
            <EmploymentIcon />
          </div>
          <div class="flex flex-col gap-3">
            <div class="font-semibold text-2xl text-gray-900">
              Трудоустройство
            </div>
            <div class="font-normal text-lg text-gray-500">
              Вы сможете претендовать на реальные вакансии и начать карьеру в
              сфере ИТ. Мы подберем вам подходящие предложения, дадим
              рекомендации к составлению портфолио и резюме. Подготовим к
              собеседованию и поможем попасть в компанию мечты. У вас будет 3
              месяца для того, чтобы определиться с местом работы.
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-6">
        <div class="next-stage__gradient-item">
          <div
            class="next-stage__item px-10 py-8 flex flex-col gap-6 h-full w-full"
          >
            <div>
              <StudyIcon />
            </div>
            <div class="flex flex-col gap-3">
              <div class="font-semibold text-2xl text-gray-900">
                Продолжить обучение по специализации
              </div>
              <div class="font-normal text-lg text-gray-500">
                Если захотите дальше развиваться в выбранной отрасли, предложим
                программы обучения для еще более детального изучения
                специализации или области.
              </div>
            </div>
          </div>
        </div>
        <div class="next-stage__gradient-item">
          <div
            class="next-stage__item px-10 py-10 flex flex-col gap-8 h-full w-full"
          >
            <div>
              <BootcampIcon />
            </div>
            <div class="flex flex-col gap-3">
              <div class="font-semibold text-2xl text-gray-900">
                Поучаствовать в Буткемпах
              </div>
              <div class="font-normal text-lg text-gray-500">
                Сможете принять участие в мероприятиях, на которые мы будем
                приглашать компании и решать задачи их бизнеса, чтобы
                подготовиться к реальной работе.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-6">
        <div class="next-stage__gradient-item">
          <div
            class="next-stage__item px-10 py-8 flex flex-col gap-6 h-full w-full"
          >
            <div>
              <InternshipIcon />
            </div>
            <div class="flex flex-col gap-3">
              <div class="font-semibold text-2xl text-gray-900">
                Принять участие в cтажировках
              </div>
              <div class="font-normal text-lg text-gray-500">
                Где совместно с другими участниками будете реализовывать идеи
                проектов и отрабатывать приобретенные знания на практике.
              </div>
            </div>
          </div>
        </div>
        <div class="next-stage__gradient-item">
          <div
            class="next-stage__item px-10 py-10 flex flex-col gap-8 h-full w-full"
          >
            <div>
              <JobIcon />
            </div>
            <div class="flex flex-col gap-3">
              <div class="font-semibold text-2xl text-gray-900">
                Применить знания в текущей работе
              </div>
              <div class="font-normal text-lg text-gray-500">
                Сможете адаптировать полученные знания и навыки в вашей текущей
                работе.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmploymentIcon from "../../../../assets/icons/EmploymentIcon.vue";
import JobIcon from "../../../../assets/icons/JobIcon.vue";
import StudyIcon from "../../../../assets/icons/StudyIcon.vue";
import InternshipIcon from "../../../../assets/icons/InternshipIcon.vue";
import BootcampIcon from "../../../../assets/icons/BootcampIcon.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "../../../../assets/css/swiper.css";

export default {
  components: {
    Swiper,
    SwiperSlide,
    EmploymentIcon,
    JobIcon,
    StudyIcon,
    InternshipIcon,
    BootcampIcon,
  },
  data() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },

        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
      },
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped>
.next-stage {
  padding: 64px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;

  background: #f4f5fa;
}
.next-stage__item {
  background: white;
  border-radius: 40px;
}
.next-stage__gradient-item {
  background: linear-gradient(
    90.14deg,
    #cc3fd3 55.91%,
    #8453e8 66.25%,
    #525cf3 78.46%
  );
  padding: 1px;
  border-radius: 40px;
}

.font-size-40px {
  font-size: 40px;
}
.text-gray-50 {
  color: #f9fafb;
}

:deep .swiper-pagination-bullet {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e4d7ff;
}

:deep .swiper-pagination-bullet-active {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #5b4ccc;
}

.adaptive-next-stage {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.adaptive-next-stage2 {
  display: none;
}

@media (max-width: 650px) {
  .next-stage {
    padding: 48px 16px;
  }
  .adaptive-next-stage {
    display: none;
  }
  .adaptive-next-stage2 {
    display: flex;
  }
  .adaptive-block-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}
</style>
