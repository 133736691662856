<template>
  <TheSelect
      v-model="localValue"
      :options="options"
      label="instructor.full_name"
      :optionComponentName="optionComponentName"
      :getOptionLabel="instructor => instructor.full_name"
      :multiple="multiple"
      :placeholder="placeholder"
  />
</template>
<script>
  import TheSelect from "@/components/Selectors/TheSelect";
  // import {GET_USERS} from "@/store/users";
  import TheSelectMixin from "@/components/Selectors/TheSelectMixin";

  export default {
    components: {TheSelect},
    mixins:[TheSelectMixin],
    props:{
//       getOptionLabel: {
//         type: Function,
//         default(option) {
//           if (typeof option === 'object') {
//             if (!option.hasOwnProperty(this.label)) {
//               return console.warn(
//                 `[vue-select warn]: Label key "option.${this.label}" does not` +
//                 ` exist in options object ${JSON.stringify(option)}.\n` +
//                 'https://vue-select.org/api/props.html#getoptionlabel'
//               )
//             }
//             return option[this.label]
//           }
//           return option;
//         }
// },
    },
    created() {
      // this.action=GET_USERS
    }
  }
</script>