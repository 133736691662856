<template>
    <div>
      <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0">
        <div
          class="fixed inset-0 bg-gray-600 bg-opacity-75"
          :class="is_sidebar_open?'block':'hidden'"
        />
      </TransitionChild>
      <ThePageHeader/>
      <TheCabinetSidebar/>
      <main class="relative flex-1 px-8 md:ml-64 bg-gray-100 ">
        <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
        >
          <Alert v-if="alert.open" class="absolute top-0 left-0 right-0 h-16" />
        </TransitionChild>
        <div class="pt-6 min-h-screen	">
          <router-view></router-view>
        </div>
      </main>
    </div>
</template>
<script>
import ThePageHeader from "@/components/ThePageHeader/ThePageHeader";
import TheCabinetSidebar from "@/components/TheSidebar/TheCabinetSidebar";
import Alert from '@/components/Alert/Alert';
import { mapFields } from 'vuex-map-fields';
export default {
  components: { TheCabinetSidebar, ThePageHeader, Alert },
  computed: {
    ...mapFields(['core.alert'])
  }
}
</script>
