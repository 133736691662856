<template>
  <div class="bg-white rounded-xl max-w-screen-md overflow-hidden relative">
    <PresentationContent
      :content="content"
      :page="currentPage"
      @setMaxPages="setMaxPages"
    />
    <div class="absolute bottom-0 right-0 left-0">
      <ProgressBar :progress="(currentPage * 100) / maxPages" />
      <div class="w-full flex place-content-between bg-gray-400 p-4">
        <ArrowLeftIcon
          class="text-red-500 h-6"
          @click="setPage(currentPage - 1)"
        />
        <div>{{ currentPage }}/{{ maxPages }}</div>

        <ArrowRightIcon
          class="text-red-500 h-6"
          @click="setPage(currentPage + 1)"
        />
      </div>
    </div>
  </div>
</template>
<script>
import PresentationContent from "@/components/Resources/ResourceBlock/PresentationContent";
import ProgressBar from "@/components/ProgressBar";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/vue/solid";

export default {
  props: {
    content: {
      required: true,
    },
  },
  data() {
    return {
      maxPages: 1,
      currentPage: 1,
    };
  },
  methods: {
    setPage(num) {
      if (num < 1 || num > this.maxPages) {
        return;
      }
      this.currentPage = num;
    },
    setMaxPages(amount) {
      this.maxPages = amount;
    },
  },
  components: {
    ProgressBar,
    PresentationContent,
    ArrowLeftIcon,
    ArrowRightIcon,
  },
};
</script>
