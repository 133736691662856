<template>
  <svg
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="0.998047"
      width="64"
      height="64"
      rx="16"
      fill="#7D69FC"
      fill-opacity="0.2"
    />
    <rect
      x="8.33691"
      y="9.62109"
      width="46.5455"
      height="46.5455"
      rx="16"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.1375 44.8721C31.2717 44.9423 31.4218 44.9779 31.5719 44.9767C31.722 44.9756 31.8709 44.9387 32.0064 44.8674L36.3311 42.5555C37.5574 41.9018 38.5178 41.1708 39.2671 40.3198C40.8962 38.4655 41.7857 36.099 41.7699 33.6588L41.7186 25.6095C41.7138 24.6824 41.1048 23.8551 40.2043 23.5544L32.1589 20.8551C31.6744 20.691 31.1436 20.6946 30.6677 20.8634L22.6528 23.659C21.7572 23.9716 21.1592 24.8048 21.1641 25.7331L21.2153 33.7765C21.2312 36.2202 22.1513 38.5761 23.8072 40.4113C24.5638 41.2504 25.5327 41.9707 26.7725 42.6138L31.1375 44.8721ZM29.9926 35.4119C30.1732 35.5854 30.4075 35.671 30.6418 35.6686C30.8761 35.6674 31.1092 35.5795 31.2874 35.4035L36.0135 30.7442C36.3687 30.3935 36.365 29.8301 36.0062 29.4843C35.6462 29.1384 35.0666 29.1408 34.7115 29.4914L30.6284 33.516L28.9566 31.909C28.5966 31.5631 28.0182 31.5667 27.6619 31.9173C27.3068 32.268 27.3104 32.8314 27.6704 33.1773L29.9926 35.4119Z"
      fill="#7D69FC"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
