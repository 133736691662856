import { mapActions } from "vuex";
import {
  ADD_TO_CART,
  CLEAR_CART,
  REMOVE_FROM_CART,
} from "@/store/shopping-cart";

export const ShoppingCartMixin = {
  computed: {
    cartItems() {
      return this.$store.getters.cartItems || [];
    },
  },
  methods: {
    ...mapActions([ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART]),
    addToCart(item) {
      // Check if item is already in cart
      if (!this.isItemInCart(item)) {
        this[ADD_TO_CART](item);
      } else {
        // Item is already in cart, you can handle this case accordingly
      }
    },
    removeFromCart(item) {
      this[REMOVE_FROM_CART](item);
    },
    clearCart() {
      this[CLEAR_CART]();
    },

    isItemInCart(item) {
      if (!item) return false;
      return this.cartItems.some((cartItem) => cartItem.id === item.id);
    },
  },
};
