<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8" stroke="url(#paint0_angular_2756_34840)" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    <defs>
      <radialGradient id="paint0_angular_2756_34840" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(8 8) rotate(99.1302) scale(7.08983)">
        <stop stop-color="#059669" stop-opacity="0"/>
        <stop offset="0.223958" stop-color="#059669" stop-opacity="0.13"/>
        <stop offset="0.802083" stop-color="#059669" stop-opacity="0.81"/>
        <stop offset="1" stop-color="#059669"/>
      </radialGradient>
    </defs>
  </svg>
</template>
<script>
import IconMixin from "@/assets/icons/IconMixin";

export default {
  mixins:[IconMixin]
}
</script>