<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BigAttendanceAbsentIcon",
});
</script>

<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.4998 14.0008C24.4998 19.7998 19.7988 24.5008 13.9998 24.5008C8.20081 24.5008 3.4998 19.7998 3.4998 14.0008C3.4998 8.20179 8.20082 3.50078 13.9998 3.50078C19.7988 3.50078 24.4998 8.20179 24.4998 14.0008ZM9.7998 11.9008C8.64001 11.9008 7.6998 12.841 7.6998 14.0008C7.6998 15.1606 8.64001 16.1008 9.7998 16.1008H18.1998C19.3596 16.1008 20.2998 15.1606 20.2998 14.0008C20.2998 12.841 19.3596 11.9008 18.1998 11.9008H9.7998Z"
      fill="#EF4444"
      stroke="#EF4444"
      stroke-width="1.4"
    />
  </svg>
</template>

<style scoped></style>
