<template>
  <button  v-bind="$attrs" :class="computedClass" :disabled="loading" >
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" v-if="loading">
      <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
          <slot/>


  </button>
</template>
<script>
  export default {
    name:"TheButton",
    props:{
      size:{
        type:String,
        validator: function (value) {
          return ["xs", "sm", "base","lg","xl"].includes(value)
        },
        default:"base"
      },
      bg:{
        type:String,
        default:"primary"
      },
      loading:{
        type:Boolean,
        default:false
      }
    },
    computed:{
      background(){
        return `button-${this.bg}`
      },
      _size(){
        return `button-${this.size}`
      },
      computedClass(){
        return `button ${this._size} ${this.background}`
      }
    }
  }
</script>
<style lang="postcss">
  .button{
    @apply items-center text-white border border-transparent font-medium rounded-3xl focus:outline-none focus:ring-2 focus:ring-offset-2 flex flex-row place-items-center justify-center
  }
  .button-sm{
    @apply  px-3 py-2 text-sm
  }
  .button-base{
    @apply px-4 py-4 text-base
  }
  .button-primary{
    @apply bg-gurgle-600 hover:bg-gurgle-700 focus:ring-gurgle-500
  }
  .button-primary-transparent{
    @apply bg-gurgle-50 hover:bg-gurgle-100
  }
  .button-white{
    @apply text-gray-700 bg-white
  }
  .button-outline{
    @apply border-gray-300 text-sm leading-5 font-medium text-gray-700
  }
  .button-danger{
    @apply bg-red-600 text-sm leading-5 font-medium
  }
  .button-link{
    @apply bg-none text-base leading-6 text-gray-800 focus:outline-none hover:outline-none
  }
  .button-link-active{
    @apply bg-none text-base leading-6 text-indigo-600 focus:outline-none hover:outline-none
  }
  .button-secondary{
    @apply bg-indigo-50 text-indigo-700
  }
  .button-gray{
    @apply bg-gray-100 text-gray-500
  }

  .button-accept{
    @apply bg-green-50 text-green-600
  }

  .button-finalize{
    @apply bg-red-50 text-red-600
  }
</style>
