<script>
import TheLandingCheckboxItem from "@/views/core/Landing/TheLandingCheckboxItem.vue";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";
export default {
  components: { TheLandingCheckboxItem },
  mixins: [AllCoursesMixin],
  data() {
    return {
      courseType: [],
      courseLevel: [],
      courseDuration: [],
    };
  },
  async mounted() {
    try {
      await this.getJustCourses();
      await this.getCoursesOptions();
    } catch (error) {
      console.error("Error in mounted hook in AllCourses:", error);
    }
  },
  methods: {
    refreshFilters() {
      this.courseDuration = [];
      this.courseLevel = [];
      this.courseType = [];
    },
    applyFilters() {
      this.$router.push({
        path: "/all-courses", // Navigate to the results page
        query: {
          courseType: this.courseType.forEach((c) => c.label),
          courseLevel: this.courseLevel,
          courseDuration: this.courseDuration,
        },
      });
    },

    closePage() {
      this.$router.replace({
        path: "/all-courses",
        query: {
          courseType: "all",
          courseLevel: "all",
          courseDuration: "all",
        },
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="flex justify-between course-filters-title">
      <div>Фильтры</div>
      <div @click="closePage">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.3002 5.70827C18.2077 5.61557 18.0978 5.54202 17.9768 5.49184C17.8559 5.44166 17.7262 5.41583 17.5952 5.41583C17.4643 5.41583 17.3346 5.44166 17.2136 5.49184C17.0926 5.54202 16.9827 5.61557 16.8902 5.70827L12.0002 10.5883L7.11022 5.69827C7.01764 5.60569 6.90773 5.53225 6.78677 5.48214C6.6658 5.43204 6.53615 5.40625 6.40522 5.40625C6.27429 5.40625 6.14464 5.43204 6.02368 5.48214C5.90272 5.53225 5.79281 5.60569 5.70022 5.69827C5.60764 5.79085 5.5342 5.90076 5.4841 6.02173C5.43399 6.14269 5.4082 6.27234 5.4082 6.40327C5.4082 6.5342 5.43399 6.66385 5.4841 6.78481C5.5342 6.90578 5.60764 7.01569 5.70022 7.10827L10.5902 11.9983L5.70022 16.8883C5.60764 16.9809 5.5342 17.0908 5.4841 17.2117C5.43399 17.3327 5.4082 17.4623 5.4082 17.5933C5.4082 17.7242 5.43399 17.8538 5.4841 17.9748C5.5342 18.0958 5.60764 18.2057 5.70022 18.2983C5.79281 18.3909 5.90272 18.4643 6.02368 18.5144C6.14464 18.5645 6.27429 18.5903 6.40522 18.5903C6.53615 18.5903 6.6658 18.5645 6.78677 18.5144C6.90773 18.4643 7.01764 18.3909 7.11022 18.2983L12.0002 13.4083L16.8902 18.2983C16.9828 18.3909 17.0927 18.4643 17.2137 18.5144C17.3346 18.5645 17.4643 18.5903 17.5952 18.5903C17.7262 18.5903 17.8558 18.5645 17.9768 18.5144C18.0977 18.4643 18.2076 18.3909 18.3002 18.2983C18.3928 18.2057 18.4662 18.0958 18.5163 17.9748C18.5665 17.8538 18.5922 17.7242 18.5922 17.5933C18.5922 17.4623 18.5665 17.3327 18.5163 17.2117C18.4662 17.0908 18.3928 16.9809 18.3002 16.8883L13.4102 11.9983L18.3002 7.10827C18.6802 6.72827 18.6802 6.08827 18.3002 5.70827Z"
            fill="#111827"
          />
        </svg>
      </div>
    </div>
    <div class="divider"></div>
    <div class="courses-options">
      <TheLandingCheckboxItem
        :optionsName="'Направления'"
        :options="landingOptions"
        @checkbox-change="handleCheckboxChange"
      />
      <!--      <TheLandingCheckboxItem-->
      <!--        :optionsName="'Уровень'"-->
      <!--        @checkbox-change="handleCheckboxLevelChange"-->
      <!--        :options="landingLevelOptions"-->
      <!--      />-->
      <!--      <TheLandingCheckboxItem-->
      <!--        :optionsName="'Длительность'"-->
      <!--        :options="['Менее 3 месяцев', '3-6 месяцев', '7-12 месяцев']"-->
      <!--        @checkbox-change="landingDurationOptions"-->
      <!--      />-->
    </div>
    <div class="flex gap-2 course-filters-buttons">
      <div class="button-1" @click="refreshFilters">Сбросить</div>
      <div class="button-2" @click="applyFilters">Применить</div>
    </div>
  </div>
</template>

<style scoped>
.course-filters-title {
  padding: 24px 16px;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;

  /* /shadow/base */
}
.divider {
  height: 1px;
  width: 100%;
  background: #cdd0d2;
}
.courses-options {
  background: white;
  width: 100%;
}
.course-filters-buttons {
  padding: 16px 24px;
  display: flex;
  gap: 8px;

  box-shadow: 5px 0px 20px 0px rgba(24, 23, 23, 0.1);
}
.button-1 {
  width: 100%;
  padding: 16px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: #e5e7eb;

  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}

.button-2 {
  width: 100%;
  color: white;
  display: flex;

  padding: 16px 25px;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background: #786ce6;
  /* shadow/sm */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
}
</style>
