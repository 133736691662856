<template>
  <svg
    width="26"
    height="20"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.1074 5.03745C24.8572 4.82852 24.5532 4.69415 24.2302 4.64967C23.9073 4.60519 23.5783 4.65239 23.2809 4.78589L17.7465 7.24682L14.5309 1.44995C14.3772 1.17931 14.1545 0.954238 13.8855 0.79766C13.6165 0.641083 13.3109 0.558594 12.9996 0.558594C12.6884 0.558594 12.3827 0.641083 12.1137 0.79766C11.8448 0.954238 11.6221 1.17931 11.4684 1.44995L8.25275 7.24682L2.71838 4.78589C2.42034 4.65259 2.09095 4.60533 1.76746 4.64944C1.44396 4.69355 1.13926 4.82728 0.887789 5.03551C0.636321 5.24374 0.448118 5.51817 0.344456 5.82776C0.240795 6.13736 0.225808 6.46978 0.301191 6.78745L3.07932 18.6328C3.13244 18.8621 3.23156 19.0782 3.37069 19.2681C3.50981 19.458 3.68604 19.6177 3.88869 19.7374C4.16305 19.9017 4.47674 19.9886 4.7965 19.989C4.95194 19.9887 5.10657 19.9666 5.25588 19.9234C10.3197 18.5234 15.6686 18.5234 20.7324 19.9234C21.1948 20.0449 21.6865 19.9781 22.0996 19.7374C22.3035 19.6192 22.4807 19.46 22.62 19.2699C22.7593 19.0797 22.8577 18.8628 22.909 18.6328L25.6981 6.78745C25.7726 6.46969 25.7568 6.13743 25.6524 5.82819C25.5481 5.51894 25.3593 5.24505 25.1074 5.03745Z"
      fill="#FFCF74"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
