const baseUrl = "/api/v1/specializations"
export default $axios => ({
    get_item(id) {
        return $axios.get(`${baseUrl}/${id}`);
    },
    get_list(params) {
        return $axios.get(`${baseUrl}/`, {params});
    },
    store(data){
        return $axios.post(`${baseUrl}/`,data)
    },
    update(id,data){
        return $axios.put(`${baseUrl}/${id}`,data)
    },
    delete(id) {
        return $axios.delete(`${baseUrl}/${id}`);
    },
});