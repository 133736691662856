import axios from 'axios'

export default (app) =>{
    const {backendUrl:baseURL} = app.config.globalProperties.$config;
    const axiosInstance = axios.create({
        baseURL
    })
    axiosInstance.addToken = (token,type='Bearer')=>{
        const authorizationHeaderValue = `${type} ${token}`
        axiosInstance.defaults.headers.common['Authorization'] = authorizationHeaderValue
        return authorizationHeaderValue
    }
    app.config.globalProperties.$axios = axiosInstance
}

