<script>
import TransparentModal from "@/components/TheModal/TransparentModal.vue";
import { ShoppingCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartMixin";
import Badge from "@/components/TheBadge/TheBadge.vue";
import LandingButton from "@/components/Button/LandingButton.vue";
export default {
  components: { LandingButton, Badge, TransparentModal },
  mixins: [ShoppingCartMixin],
  props: ["modalOpen", "changeIcon"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<template>
  <TransparentModal :show="modalOpen" @close="closeModal">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col gap-5 cart-courses">
        <div
          v-for="(course, index) in cartItems"
          :key="index"
          class="flex flex-col gap-4"
        >
          <div class="flex gap-6">
            <div class="flex flex-col gap-2">
              <div class="flex gap-2">
                <Badge
                  :has_circle="false"
                  :bg="course.level"
                  class="font-medium text-xs py-3 px-3 flex justify-center"
                  >Уровень: {{ course.level }}
                </Badge>
                <Badge
                  :has_circle="false"
                  bg="program"
                  class="font-medium text-xs py-3 px-3 flex justify-center"
                  >Программа: {{ course.program }}</Badge
                >
              </div>
              <div class="flex flex-col gap-1">
                <div class="course-title">{{ course.name }}</div>
                <div class="course-description text-gray-500">
                  {{ course.description }}
                </div>
              </div>
              <div class="course-price">590 000 ₸</div>
            </div>
            <div
              class="flex align-center cursor-pointer"
              @click="this.removeFromCart(course)"
            >
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.8002 5.71022C18.7077 5.61752 18.5978 5.54397 18.4768 5.49379C18.3559 5.44361 18.2262 5.41778 18.0952 5.41778C17.9643 5.41778 17.8346 5.44361 17.7136 5.49379C17.5926 5.54397 17.4827 5.61752 17.3902 5.71022L12.5002 10.5902L7.61022 5.70022C7.51764 5.60764 7.40773 5.5342 7.28677 5.4841C7.1658 5.43399 7.03615 5.4082 6.90522 5.4082C6.77429 5.4082 6.64464 5.43399 6.52368 5.4841C6.40272 5.5342 6.29281 5.60764 6.20022 5.70022C6.10764 5.79281 6.0342 5.90272 5.9841 6.02368C5.93399 6.14464 5.9082 6.27429 5.9082 6.40522C5.9082 6.53615 5.93399 6.6658 5.9841 6.78677C6.0342 6.90773 6.10764 7.01764 6.20022 7.11022L11.0902 12.0002L6.20022 16.8902C6.10764 16.9828 6.0342 17.0927 5.9841 17.2137C5.93399 17.3346 5.9082 17.4643 5.9082 17.5952C5.9082 17.7262 5.93399 17.8558 5.9841 17.9768C6.0342 18.0977 6.10764 18.2076 6.20022 18.3002C6.29281 18.3928 6.40272 18.4662 6.52368 18.5163C6.64464 18.5665 6.77429 18.5922 6.90522 18.5922C7.03615 18.5922 7.1658 18.5665 7.28677 18.5163C7.40773 18.4662 7.51764 18.3928 7.61022 18.3002L12.5002 13.4102L17.3902 18.3002C17.4828 18.3928 17.5927 18.4662 17.7137 18.5163C17.8346 18.5665 17.9643 18.5922 18.0952 18.5922C18.2262 18.5922 18.3558 18.5665 18.4768 18.5163C18.5977 18.4662 18.7076 18.3928 18.8002 18.3002C18.8928 18.2076 18.9662 18.0977 19.0163 17.9768C19.0665 17.8558 19.0922 17.7262 19.0922 17.5952C19.0922 17.4643 19.0665 17.3346 19.0163 17.2137C18.9662 17.0927 18.8928 16.9828 18.8002 16.8902L13.9102 12.0002L18.8002 7.11022C19.1802 6.73022 19.1802 6.09022 18.8002 5.71022Z"
                  fill="#9CA3AF"
                />
              </svg>
            </div>
          </div>
          <div class="stick-grey"></div>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="flex items-center gap-2">
          <div>Итого:</div>
          <div class="course-price">
            {{ (cartItems.length * 590000).toLocaleString("ru-RU") }} ₸
          </div>
        </div>
        <router-link :to="{ name: 'ShoppingCart' }" @click="closeModal">
          <LandingButton>Перейти в корзину</LandingButton></router-link
        >
      </div>
    </div>
  </TransparentModal>
</template>

<style scoped>
.course-price {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
.course-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}
.course-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}
.stick-grey {
  display: flex;
  height: 1.5px;
  align-self: stretch;
  background: #d1d5db;
  width: 100%;
}
</style>
