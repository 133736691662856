<template>
  <div class="banner-card flex items-center">
    <div class="banner-text w-3/5">
      Мы будем рядом и поможем
      <div style="color: #fff">усваивать новые знания</div>
    </div>
    <div class="w-2/5 flex justify-center">
      <TheButton
        bg="white"
        class="gap-2 pl-12"
        style="max-width: 230px; padding: 16px 24px; border-radius: 32px"
        @click="scrollToForm"
        >Оставить заявку
      </TheButton>
    </div>
  </div>
  <div class="faq">
    <div class="font-bold font-size-40px text-gray-800 faq-title">
      Часто задаваемые вопросы
    </div>
    <div class="grid grid-cols-2 gap-8 faq-questions">
      <div
        class="flex flex-col gap-6"
        v-for="(item, index) in faq"
        :key="index"
      >
        <hr class="border-0.5 border-gray-500" />
        <div class="flex flex-col gap-6">
          <div
            class="flex justify-between gap-4 items-center cursor-pointer"
            @click="openFaqQuestion(index)"
            v-bind:class="{ 'opened-question': faq[index].opened }"
          >
            <div class="font-semibold text-xl">{{ faq[index].question }}</div>
            <div>
              <PlusIcon v-if="!faq[index].opened" />
              <CrestIcon v-if="faq[index].opened" />
            </div>
          </div>
          <div
            class="text-base font-medium text-gray-500"
            v-if="faq[index].opened"
          >
            {{ faq[index].answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from "@/components/Button/Button";

import PlusIcon from "../../../assets/icons/PlusIcon.vue";
import CrestIcon from "../../../assets/icons/WhiteCrestIcon.vue";
export default {
  name: "TheLandingBanner",
  components: { TheButton, PlusIcon, CrestIcon },
  data() {
    return {
      faq: [
        {
          question: "Какова структура курсов в вашей онлайн IT-школе?",
          answer:
            "Курсы в нашей онлайн IT-школе обычно состоят из модулей или уроков, охватывающих различные темы. Каждый модуль содержит видеоуроки, задания, практические проекты и тесты для проверки понимания материала. Учащиеся могут пройти курсы в собственном темпе и имеют доступ к материалам на протяжении определенного периода времени.",
          opened: false,
        },
        {
          question: "Как долго длится каждый курс в вашей онлайн IT-школе?",
          answer:
            "Длительность курсов в нашей онлайн IT-школе может различаться в зависимости от конкретного курса. Мы предлагаем как краткосрочные интенсивные курсы, длительностью несколько недель или месяцев, так и долгосрочные программы обучения, которые могут продолжаться несколько месяцев или полугодия. Подробная информация о продолжительности каждого курса доступна на нашем веб-сайте.",
          opened: false,
        },
        {
          question:
            " Каковы требования для поступления в вашу онлайн IT-школу?",
          answer:
            "Мы приветствуем студентов всех уровней знаний, от новичков до опытных специалистов. Требования для поступления в нашу онлайн IT-школу могут варьироваться в зависимости от конкретного курса. Некоторые курсы могут требовать предварительных знаний или опыта в определенной области, в то время как другие курсы могут быть доступны для всех без ограничений.",
          opened: false,
        },
        {
          question:
            "Какими ресурсами и поддержкой я буду обладать во время обучения в вашей онлайн IT-школе?",
          answer:
            "Во время обучения в нашей онлайн IT-школе вы будете иметь доступ к видеоурокам, учебным материалам, заданиям и практическим проектам. Вы также получите поддержку от наших преподавателей и наставников через форумы, чаты или электронную почту. Мы стремимся обеспечить наших студентов всем необходимым для успешного завершения курса и развития своих навыков в IT.",
          opened: false,
        },
      ],
    };
  },
  methods: {
    openFaqQuestion(index) {
      this.faq[index].opened = !this.faq[index].opened;
    },
    scrollToForm() {
      const formElement = document.getElementById("landing-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.banner-text {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}
.banner-card {
  margin-left: 80px;
  margin-right: 80px;
  margin-top: 100px;
  margin-bottom: 100px;
  background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);

  border-radius: 40px;
  padding: 80px;
}

.faq {
  padding: 10px 100px 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.opened-question {
  color: #7062ef;
}
.font-size-40px {
  font-size: 40px;
}

@media (max-width: 650px) {
  .banner-card {
    margin: 32px 16px;
    padding: 56px 24px;
    flex-direction: column;
    gap: 40px;
    text-align: center;
  }
  .banner-text {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    width: 100%;
  }
  .faq {
    padding: 32px 16px;
  }
  .faq-questions {
    display: flex;
    flex-direction: column;
  }
  .faq-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}
</style>
