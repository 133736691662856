<template>
  <div class="flex h-screen">
    <TheFaqPage
      :show_faq_modal="show_faq_modal"
      @closeModal="show_faq_modal = false"
    />
    <DocumentsModal
      :show_doc_modal="show_doc_modal"
      @closeModal="show_doc_modal = false"
    />
    <div
      class="basis-half w-full px-10 sm:px-24 min-w-fit md:w-2/5 items-center place-items-center flex flex-col h-screen pb-8 place-content-center"
    >
      <div class="w-full h-full flex items-center">
        <router-view />
      </div>
      <div class="w-full flex justify-items-between">
        <Locale />
        <!--        <span class="ml-4 flex justify-between">-->
        <!--          <a href="mailto:helpdesk@edca.kz" class="text-base mr-4 whitespace-nowrap text-gray-400">{{$t('email_us')}}</a>-->
        <!--          <a @click="show_faq_modal = !show_faq_modal" class="text-base mr-4 whitespace-nowrap text-gray-400 cursor-pointer">{{$t('faq')}}</a>-->
        <!--          <a @click="show_doc_modal = !show_doc_modal" class="text-base mr-4 whitespace-nowrap text-gray-400 cursor-pointer">{{$t('documents')}}</a>-->
        <!--          &lt;!&ndash; <img src="@/assets/images/social/facebook.png" class="mr-3 object-contain h-5 cursor-pointer"/>-->
        <!--          <img src="@/assets/images/social/instagram.png" class="mr-3 object-contain h-5 cursor-pointer"/>-->
        <!--          <img src="@/assets/images/social/twitter.png" class="object-contain h-5 cursor-pointer"/> &ndash;&gt;-->
        <!--        </span>-->
      </div>
    </div>
    <div
      class="hidden sm:flex w-full items-center place-items-center h-screen place-content-center bg-gray-100 basis-half"
    >
      <img :src="image" alt="" class="auth-image" />
    </div>
  </div>
</template>
<script>
import image from "@/assets/images/AuthLayoutDecorativeImage.svg";
import TheFaqPage from "@/views/core/FAQ/TheFaqPage.vue";
import Locale from "@/components/TheLocaleSelector/TheLocaleSelectorAlt.vue";
import DocumentsModal from "@/views/auth/Additional/DocumentsModal";
export default {
  data() {
    return {
      image,
      show_faq_modal: false,
      show_doc_modal: false,
    };
  },
  components: { DocumentsModal, TheFaqPage, Locale },
  created() {
    this.show_faq_modal = false;
  },
  watch: {
    show_faq_modal: {
      handler() {
        console.log(this.show_faq_modal);
      },
    },
    show_doc_modal: {
      handler() {
        console.log(this.show_doc_modal);
      },
    },
  },
};
</script>
<style>
.auth-image {
  height: 623.7379150390625px;
  width: 716px;
  left: 33px;
  top: 161px;
  border-radius: 0px;
}

.basis-half {
  flex-basis: 50%;
}
</style>
