import {
  ADD_LANDING_COURSES,
  DELETE_LANDING_COURSES,
  GET_LANDING_COURSES,
  GET_LANDING_COURSES_OPTIONS,
  GET_LANDING_COURSE,
} from "@/store/landing-courses";
import { mapActions } from "vuex";

export default {
  data() {
    return {
      courseType: [],
      courses: [],
      course: null,
      all: { label: "Все направления", value: "Все направления" },
    };
  },
  computed: {
    landingCourses() {
      return this.$store.getters.landing_courses || [];
    },
    landingOptions() {
      return this.$store.getters.options["program"] || [];
    },
    landingOptionsMob() {
      if (this.landingOptions) {
        return [this.all, ...this.landingOptions];
      } else return [this.all];
    },
    landingLevelOptions() {
      return this.$store.getters.options["level"] || [];
    },
    landingDurationOptions() {
      return this.$store.getters.options["duration"] || [];
    },
  },
  async mounted() {
    try {
      await this.getJustCourses();

      await this.getCoursesOptions();

      // const { courseType, courseLevel, courseDuration } = this.$route.query;
      // this.courseType = courseType;
      // this.courseDuration = courseDuration;
      // this.courseLevel = courseLevel;
    } catch (error) {
      console.error("Error in mounted hook in AllCourses:", error);
    }
  },
  methods: {
    ...mapActions([
      ADD_LANDING_COURSES,
      GET_LANDING_COURSES,
      DELETE_LANDING_COURSES,
      GET_LANDING_COURSES_OPTIONS,
      GET_LANDING_COURSE,
    ]),
    getCourse(id) {
      this[GET_LANDING_COURSE](id);
    },
    async handleCheckboxChange(checkboxValues) {
      if (
        checkboxValues.length === 0 ||
        checkboxValues.includes("Все направления")
      ) {
        this.courseType = [];
      } else {
        this.courseType = checkboxValues;
      }
      await this.getAllCourses();
      this.courses = this.landingCourses;
    },
    handleCheckboxLevelChange(checkboxValues) {
      this.courseLevel = checkboxValues;
      this.getAllCourses();
    },
    handleCheckboxDurationChange(checkboxValues) {
      this.courseDuration = checkboxValues;
      this.getAllCourses();
    },

    openCourse(courseProgram) {
      console.log(courseProgram);

      if (!courseProgram) {
        return; // Return if the index is invalid
      } else if (courseProgram === "Веб-разработка") {
        return this.$router.push({ path: "/all-courses/web" });
      } else if (courseProgram === "Дизайн") {
        return this.$router.push({ path: "/all-courses/design" });
      } else if (courseProgram === "Программирование") {
        return this.$router.push({ path: "/all-courses/programming" });
      } else if (courseProgram === "Мобильная разработка") {
        return this.$router.push({ path: "/all-courses/mob-dev" });
      } else if (courseProgram === "Разработка игр") {
        return this.$router.push({ path: "/all-courses/game-dev" });
      } else if (courseProgram === "ИИ") {
        return this.$router.push({ path: "/all-courses/gen-ai" });
      } else if (courseProgram === "Программирование ИИ") {
        return this.$router.push({ path: "/all-courses/programming-ai" });
      }
    },
    chooseCourse(temp) {
      if (this.courseType.includes(temp)) {
        // If temp is already in the courseType array, remove it
        this.courseType = this.courseType.filter((course) => course !== temp);
      } else {
        // If temp is not in the courseType array, add it
        this.courseType.push(temp);
      }
      this.getAllCourses();
    },
    chooseCourseMob(temp) {
      if (temp === this.all) {
        this.courseType = [];
        this.getJustCourses();
      } else {
        this.courseType = [temp];
        this.getAllCourses();
      }

      this.show = false;
    },
    addAllCourses(courses) {
      for (let i of courses) {
        const data = {
          name: i.name,
          level: i.level,
          program: i.program,
          investor: i.investor,
          description: i.description,
          skills: i.skills,
          duration: i.duration,
          start_date: i.start_date,
          time: i.time,
          soon: i.soon,
          discount: i.discount,
        };
        this[ADD_LANDING_COURSES](data);
      }
    },
    async getAllCourses() {
      const { courseType, searchText, courseLevel, courseDuration } = this;
      let data = "?";

      if (courseType.length > 0) {
        courseType.forEach((type) => {
          data += "program=" + type.value + "&";
        });
      }
      if (courseLevel.length > 0) {
        courseLevel.forEach((type) => {
          data += "level=" + type.value + "&";
        });
      }
      if (courseDuration.length > 0) {
        courseDuration.forEach((type) => {
          data += "duration=" + type.value + "&";
        });
      }
      data += "search=" + searchText;
      await this.$store.dispatch(GET_LANDING_COURSES, data);
    },
    getJustCourses() {
      this[GET_LANDING_COURSES]("");
    },
    getCoursesOptions() {
      this[GET_LANDING_COURSES_OPTIONS]();
    },
    deleteCourse(id) {
      this[DELETE_LANDING_COURSES](id);
      this.getAllCourses();
    },
  },
};
