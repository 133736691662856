<template>
  <div>
    <TheTable class="mt-8">
      <TheTableHead>
        <TheTableHeadCell
          ><span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
            >{{ $t("name") }}</span
          ></TheTableHeadCell
        >
        <TheTableHeadCell
          ><span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
            >{{ $t("status") }}</span
          ></TheTableHeadCell
        >
        <TheTableHeadCell
          ><span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
            >{{ $t("last_activity") }}</span
          ></TheTableHeadCell
        >
        <TheTableHeadCell />
      </TheTableHead>
      <TheTableBody>
        <template v-for="user of users" :key="user.email">
          <TheTableRow>
            <TheTableRowCell>
              <div class="flex gap-4">
                <UserAvatar class="flex-shrink-0" :url="user.avatar" />
                <div>
                  <div class="text-sm leading-5 font-medium text-gray-900">
                    {{ user.full_name }}
                  </div>
                  <div class="text-sm leading-5 font-normal text-gray-500">
                    {{ user.email }}
                  </div>
                </div>
              </div>
            </TheTableRowCell>
            <TheTableRowCell>
              <div
                class="text-xs leading-4 font-medium py-0.5 px-2.5 rounded-lg inline-block"
                :class="{
                  'bg-green-100 text-green-800': user.status === 'online',
                  'bg-gray-100 text-gray-800': user.status === 'offline',
                }"
              >
                {{ $t(user.status) }}
              </div>
            </TheTableRowCell>
            <TheTableRowCell>
              {{
                `${new Date(user.last_activity).toLocaleDateString("ru-RU", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}, ${new Date(user.last_activity).toLocaleTimeString(
                  "ru-RU",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}`
              }}
            </TheTableRowCell>
            <TheTableRowCell @click="handleActions(user)">
              <span
                class="text-sm leading-5 font-normal text-indigo-600 cursor-pointer"
              >
                {{ $t("all_actions") }}
              </span>
            </TheTableRowCell>
          </TheTableRow>
        </template>
      </TheTableBody>
    </TheTable>
  </div>

  <TheModal :show="show" @close="handleModalClose" className="modal">
    <div class="p-6 flex flex-col">
      <h2 class="text-xl leading-7 font-semibold text-gray-900 mb-10">
        {{ $t("activity_logs") }}
      </h2>
      <div class="flex gap-4 mb-10">
        <UserAvatar class="flex-shrink-0" />
        <div v-if="!!selectedUser">
          <div class="text-sm leading-5 font-medium text-gray-900">
            {{ selectedUser.full_name }}
          </div>
          <div class="text-sm leading-5 font-normal text-gray-500">
            {{ selectedUser.email }}
          </div>
        </div>
      </div>
      <div class="flex justify-between">
        <div class="flex gap-2 items-center">
          <span class="text-sm leading-5 font-medium text-gray-400">
            {{ $t("filter") }}:
          </span>
          <DatePicker
            style="border-radius: 5px"
            v-model="filterDate"
            :typeable="true"
            color="#561BFB"
            class="no-outline w-100 futura"
            :locale="ru"
          />
        </div>
        <Button
          bg="outline"
          class="text-gray-500 bg-white h-fit w-fit mb-1"
          @click="handleDownloadActivityLogsItem"
        >
          <DownloadIcon class="h-5 mr-2" />
          {{ $t("download_activity_logs") }}
        </Button>
      </div>
      <div class="overflow-hidden overflow-y-auto max-h-[360px] px-2 pb-4 mt-8">
        <TheTable>
          <TheTableHead>
            <TheTableHeadCell>{{ $t("date_and_time") }}</TheTableHeadCell>
            <TheTableHeadCell>{{ $t("action") }}</TheTableHeadCell>
          </TheTableHead>
          <TheTableBody>
            <template v-for="log of activity_logs" :key="log.datetime">
              <TheTableRow>
                <TheTableRowCell>
                  {{
                    `${new Date(log.datetime).toLocaleDateString(
                      "ru-RU"
                    )}, ${new Date(log.datetime).toLocaleTimeString("ru-RU", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}`
                  }}
                </TheTableRowCell>
                <TheTableRowCell>
                  <div class="flex gap-4 items-center">
                    <UserIcon class="w-5 h-5" />
                    <div>{{ getTextColumn(log) }}</div>
                  </div>
                </TheTableRowCell>
              </TheTableRow>
            </template>
          </TheTableBody>
        </TheTable>
      </div>
    </div>
  </TheModal>
</template>

<script>
import TheTable from "@/components/TheTable/TheTable";
import TheTableHead from "@/components/TheTable/TheTableHead";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell";
import TheTableBody from "@/components/TheTable/TheTableBody";
import TheTableRow from "@/components/TheTable/TheTableRow";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell";
import UserAvatar from "@/components/UserAvatar";
import { DownloadIcon, UserIcon } from "@heroicons/vue/solid";
import DatePicker from "vue3-datepicker";
import { ru } from "date-fns/locale";
import { mapActions } from "vuex";
import { GET_ACTIVITY_LOGS, GET_DETAILED_ACTIVITY } from "@/store/users";
import Button from "@/components/Button/Button";
import { getCurrentInstance } from "vue";
import TheModal from "@/components/TheModal/TheModal";

export default {
  components: {
    TheTable,
    TheTableHead,
    TheTableHeadCell,
    TheTableBody,
    TheTableRow,
    TheTableRowCell,
    UserAvatar,
    DatePicker,
    UserIcon,
    Button,
    DownloadIcon,
    TheModal,
  },
  props: ["search"],
  data() {
    return {
      show: false,
      selectedUser: null,
      filterDate: null,
      ru: ru,
      users: [],
      activity_logs: [],
    };
  },
  setup() {
    const instance = getCurrentInstance();
    return { instance };
  },
  watch: {
    async filterDate() {
      this.activity_logs = await this[GET_DETAILED_ACTIVITY]({
        email: this.selectedUser.email,
        date: this.filterDate,
      });
    },
    search: async function (newVal) {
      this.users = await this[GET_ACTIVITY_LOGS](newVal);
    },
  },
  async mounted() {
    this.users = await this[GET_ACTIVITY_LOGS]();
  },
  methods: {
    ...mapActions([GET_ACTIVITY_LOGS, GET_DETAILED_ACTIVITY]),
    async handleActions(user) {
      this.show = true;
      this.selectedUser = user;
      this.activity_logs = await this[GET_DETAILED_ACTIVITY]({
        email: user.email,
        date: this.filterDate,
      });
    },
    handleModalClose() {
      this.show = false;
      this.selectedUser = null;
      this.filterDate = null;
    },
    handleDownloadActivityLogsItem() {
      let a = document.createElement("a");
      let baseUrl =
        this.instance.appContext.config.globalProperties.$config.backendUrl;
      let link = "";
      link =
        baseUrl +
        `/api/v1/users/statistics/activity/download?email=${this.selectedUser.email}`;
      a.href = link;
      a.click();
    },
    getTextColumn(log) {
      let text = "";
      let keys = log.context ? Object.keys(log.context) : [];
      switch (log.activity) {
        case "login":
          return this.$t("login_activity");
        case "logout":
          return this.$t("logout_activity");
        case "course visited":
          text = this.$t("activity_course_visited");
          return keys.includes("course_name")
            ? `${text} ${log.context?.course_name}`
            : text;
        case "course test opened":
          text = this.$t("activity_course_test_opened");
          return keys.includes("course_name") && keys.includes("test_name")
            ? `${text} ${log.context?.course_name} - ${log.context?.test_name}`
            : text;
        case "practice completion":
          text = this.$t("activity_practice_completion");
          return keys.includes("course_name") && keys.includes("practice_name")
            ? `${text} ${log.context?.course_name} - ${log.context?.practice_name}`
            : text;
        case "lesson view":
          text = this.$t("activity_lesson_view");
          return keys.includes("course_name") && keys.includes("lesson_name")
            ? `${text} ${log.context?.course_name} - ${log.context?.lesson_name}`
            : text;
        case "test completion":
          text = this.$t("activity_test_completion");
          return keys.includes("course_name") && keys.includes("test_name")
            ? `${text} ${log.context?.course_name} - ${log.context?.test_name}`
            : text;
        case "course started":
          text = this.$t("activity_course_started");
          return keys.includes("course_name")
            ? `${text} ${log.context?.course_name}`
            : text;
        case "course test sent":
          text = this.$t("activity_course_test_sent");
          return keys.includes("course_name") && keys.includes("test_name")
            ? `${text} ${log.context?.course_name} - ${log.context?.test_name}`
            : text;
        case "course test is evaluated":
          text = this.$t("activity_course_test_is_evaluated");
          return keys.includes("course_name") && keys.includes("test_name")
            ? `${text} ${log.context?.course_name} - ${log.context?.test_name}`
            : text;
      }
    },
  },
};
</script>

<style lang="postcss" scoped>
.modal {
  @apply h-[80vh] w-[90%] max-w-[60%];
}
</style>
