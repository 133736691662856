import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apiPlugin from "./plugins/api";
import axiosPlugin from "./plugins/axios";

// Use calendar defaults (optional)
// import './assets/css/main.css'
import filters from "@/plugins/filters";
import i18n from "@/plugins/i18n";
import config from "@/plugins/config";
import Maska from "maska";
import vueDebounce from "vue-debounce";
import Transitions from "vue2-transitions";

const app = createApp(App);
app.use(config(app));
app.use(i18n(app));
app.use(axiosPlugin);
app.use(apiPlugin);
app.use(store(app));
app.use(router);

app.use(filters);
app.use(Maska);
app.use(vueDebounce);
app.use(Transitions);

app.directive("maska", Maska.maska);

app.mount("#app");
