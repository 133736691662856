<template>
  <div class="py-10 px-8 flex flex-col gap-8 teachers__item">
    <div class="flex justify-start items-center">
      <TeacherBadge
        v-for="(pr, index) in teacher.programs"
        :program="pr"
        :key="index"
      >
        <div class="flex gap-1">
          Программа: <span class="font-semibold">{{ pr }}</span>
        </div>
      </TeacherBadge>
    </div>
    <div class="flex gap-6">
      <div class="flex-shrink-0">
        <img
          :src="
            teacher.image.includes('http') || teacher.image.includes('https')
              ? teacher.image
              : requireImage(teacher.image)
          "
          alt="photo"
          class="w-20 h-20 teachers__image flex-shrink-0"
        />
      </div>
      <div class="flex flex-col gap-2">
        <div class="font-semibold text-2xl adaptive-name">
          {{ teacher.name }}
        </div>
        <div class="font-normal text-lg text-gray-500 adaptive-position">
          {{ teacher.position }}
        </div>
      </div>
    </div>
    <div class="teachers__divider"></div>
    <div class="flex flex-col gap-2">
      <div class="text-xl font-semibold adaptive-name">
        Преподаватель программ:
      </div>
      <div class="text-gray-500 text-base font-normal adaptive-position">
        {{ teacher.courses }}
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div class="text-xl font-semibold adaptive-name">
        Профессиональный путь:
      </div>
      <div class="text-gray-500 text-base font-normal adaptive-position">
        {{ teacher.profession }}
      </div>
    </div>
    <div
      class="flex-shrink-0 teachers__company"
      v-if="teacher.company_images && teacher.company_images.length > 0"
    >
      <img
        v-for="(img, index) in teacher.company_images"
        :src="img.image"
        alt="company"
        :key="index"
      />

      <!--      <img-->
      <!--        :src="requireImage(teacher.image)"-->
      <!--        alt=""-->
      <!--        class="w-20 h-20 teachers__image"-->
      <!--      />-->
    </div>
  </div>
</template>

<script>
import TeacherBadge from "@/components/TheBadge/TeacherBadge.vue";

export default {
  components: { TeacherBadge },
  props: {
    teacher: {
      type: Object,
    },
  },
  methods: {
    requireImage(imagePath) {
      if (!imagePath) return "";
      return require(`@/assets/images/landing/${imagePath}`);
    },
  },
};
</script>

<style scoped>
.teachers__divider {
  background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);
  height: 2px;
}
.teachers__item {
  border-radius: 32px;
  background: #f4f5fa;
}
.teachers__image {
  border-radius: 80px;
  flex-shrink: 0;
  object-fit: cover;
  width: 80px;
  height: 80px;
}
.teachers__company {
  display: flex;
  gap: 32px;
  justify-content: center;
  align-items: start;
  justify-self: start;
  align-self: start;
  flex-wrap: wrap;

  img {
    height: 40px;
    object-fit: contain;
  }
}

@media (max-width: 650px) {
  .adaptive-name {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .adaptive-position {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
