<template>
  <TheLandingHeader />
  <div class="py-16 px-20 bg-gray-50">
    <h1 class="text-4xl leading-10 font-extrabold text-gray-900 mb-10">
      {{ $t("info") }}
    </h1>
    <div class="flex gap-20">
      <div class="max-w-xs">
        <ul>
          <li
            v-for="(section, index) of sections"
            :key="index"
            @click="changeSection(index)"
            class="list-none m-0 py-3 border-b"
          >
            <span
              class="text-base leading-6 cursor-pointer"
              :class="{
                'text-indigo-600 font-semibold': index === selectedSection,
                'font-normal text-gray-900': index !== selectedSection,
              }"
            >
              {{ section }}
            </span>
          </li>
        </ul>
      </div>
      <OverviewSection v-if="selectedSection === 0" />
      <StructureSection v-if="selectedSection === 1" />
      <DocumentsSection v-if="selectedSection === 2" />
      <EducationSection v-if="selectedSection === 3" />
    </div>
  </div>
  <TheLandingFooter/>
</template>

<script>
import TheLandingHeader from "@/views/core/Landing/TheLandingHeader";
import OverviewSection from "@/views/core/Landing/InfoSections/OverviewSection";
import StructureSection from "@/views/core/Landing/InfoSections/StructureSection";
import DocumentsSection from "@/views/core/Landing/InfoSections/DocumentsSection";
import EducationSection from "@/views/core/Landing/InfoSections/EducationSection";
import TheLandingFooter from "@/views/core/Landing/TheLandingFooter";

export default {
  components: {
    TheLandingFooter,
    TheLandingHeader,
    OverviewSection,
    StructureSection,
    DocumentsSection,
    EducationSection,
  },
  data() {
    return {
      sections: [
        "Основные сведения",
        "Структура и органы управления организацией",
        "Документы",
        "Образование",
      ],
      selectedSection: 0,
    };
  },
  methods: {
    changeSection(index) {
      this.selectedSection = index;
    },
  },
};
</script>
