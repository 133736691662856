<script setup></script>

<template>
  <svg
    width="146"
    height="105"
    viewBox="0 0 146 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.0097 61.1797C30.6897 64.0597 28.8697 65.2896 26.5397 65.9996C24.645 66.4834 22.721 66.8441 20.7797 67.0796C17.5397 67.5896 14.3697 68.3296 12.3197 70.4496C10.9197 71.9096 9.99973 74.0197 9.96973 77.1897H11.6697C11.5357 75.1545 12.2115 73.1488 13.5497 71.6096C15.3597 69.7296 18.1998 69.1796 21.0598 68.7296C23.5649 68.4745 26.0316 67.9274 28.4097 67.0996C29.6026 66.6575 30.6418 65.8792 31.4014 64.8586C32.161 63.8381 32.6085 62.6192 32.6897 61.3496L31.0097 61.1797Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M59.5699 17.2405C59.8499 14.2405 60.7399 12.5105 62.0799 11.5605C63.5806 10.6596 65.3568 10.3327 67.0799 10.6405C69.9461 11.0868 72.7868 11.6844 75.5899 12.4305C79.3599 13.2005 83.3399 11.3305 83.7999 7.16052L82.1299 6.98047C81.7899 10.0905 78.7399 11.3605 75.9299 10.7805C73.0664 10.0276 70.1659 9.42343 67.2399 8.97046C65.1227 8.6249 62.952 9.05842 61.1299 10.1906C59.4799 11.3606 58.2699 13.4704 57.9199 17.0804L59.5699 17.2405Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M54.9303 15.7803C53.8776 13.6808 53.6355 11.267 54.2503 9.00024L52.6303 8.57031C51.9041 11.2052 52.1784 14.0154 53.4003 16.4602L54.9303 15.7803Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M114.55 64.6503C114.944 64.0055 115.386 63.3906 115.87 62.8103C116.59 62.1103 117.18 61.8103 117.74 61.8703C118.36 62.0317 118.893 62.4256 119.23 62.9702C120.304 64.5694 121.196 66.2833 121.89 68.0802C122.296 69.1376 122.862 70.1259 123.57 71.0103C124.226 71.6544 125.038 72.118 125.926 72.3562C126.815 72.5945 127.749 72.5993 128.64 72.3703C130.299 71.9993 131.859 71.2726 133.21 70.2403L132.21 68.9003C131.068 69.7817 129.747 70.4028 128.34 70.7202C127.72 70.8903 127.067 70.8977 126.443 70.7418C125.82 70.586 125.247 70.272 124.78 69.8302C124.216 69.0871 123.764 68.265 123.44 67.3903C122.687 65.472 121.728 63.6412 120.58 61.9303C119.972 61.043 119.044 60.4263 117.99 60.2102C117.372 60.1634 116.753 60.2663 116.184 60.51C115.614 60.7538 115.112 61.1311 114.72 61.6103C114.151 62.2737 113.633 62.979 113.17 63.7202L114.55 64.6503Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M118.54 58.6399C120.71 58.3799 122.02 59.56 123.27 60.92C124.77 62.58 126.19 64.4999 128.35 65.3699L128.97 63.81C127.05 63.04 125.85 61.27 124.51 59.81C122.91 58.04 121.15 56.6399 118.34 56.9799L118.54 58.6399Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48.4602 92.5898C46.2539 93.2414 44.1979 94.3221 42.4102 95.7699L43.4102 97.0898C45.0358 95.7666 46.909 94.7808 48.9202 94.1899L48.4602 92.5898Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M58.4905 9.58997C59.2094 7.93175 60.3219 6.47396 61.7317 5.34302C63.1414 4.21207 64.8058 3.44214 66.5805 3.09998C68.9205 2.52998 71.5805 2.26001 74.4305 1.64001L74.0805 0C71.1705 0.63 68.5305 0.89998 66.1705 1.47998C64.1555 1.86656 62.2652 2.73903 60.6637 4.02161C59.0623 5.30418 57.7979 6.95809 56.9805 8.83997L58.4905 9.58997Z"
      fill="#A14DF6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.8604 55.3105C23.2814 56.4312 22.427 57.3863 21.3774 58.0861C20.3279 58.7858 19.1176 59.2071 17.8604 59.3105C15.1228 59.5499 12.3654 59.4255 9.66043 58.9406L9.44043 60.6105C12.2956 61.1087 15.2043 61.2264 18.0905 60.9604C19.6217 60.8391 21.0964 60.3279 22.3742 59.4755C23.652 58.623 24.6904 57.4577 25.3904 56.0905L23.8604 55.3105Z"
      fill="#A14DF6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.4497 88.7505C52.7388 90.3726 52.5114 92.0445 51.7997 93.5305C50.7997 95.4505 48.7997 96.6905 46.2197 98.1805L47.0598 99.6305C49.9598 97.9605 52.1297 96.4906 53.2897 94.3206C54.1615 92.5345 54.4518 90.5201 54.1198 88.5605L52.4497 88.7505Z"
      fill="#A14DF6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M122.07 31.2705C123.31 30.0005 125.25 29.7305 127.36 29.7405C130.94 29.7405 135.03 30.6305 137.8 30.1505L137.51 28.5005C134.83 28.9605 130.86 28.0705 127.36 28.0605C124.76 28.0605 122.36 28.5305 120.87 30.0605L122.07 31.2705Z"
      fill="#A14DF6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M95.0299 14.6201C95.5899 11.7601 96.9799 9.22012 98.1999 6.62012L96.6799 5.91016C95.3999 8.66016 93.9699 11.3202 93.3799 14.3202L95.0299 14.6201Z"
      fill="#FFBA16"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.7197 23.9197C33.3097 24.4597 33.8997 25.0096 34.4497 25.5896L35.6597 24.4197C35.0797 23.8197 34.4697 23.2397 33.8497 22.6797L32.7197 23.9197Z"
      fill="#FFBA16"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.2997 93.1501C20.6597 90.1501 24.0697 87.1501 27.3997 84.1501L26.2597 82.9102C22.9397 85.9702 19.5397 88.9102 16.1797 91.9102L17.2997 93.1501Z"
      fill="#FFBA16"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M135.33 56.4001C136.66 57.0901 138.02 57.7401 139.33 58.4901L140.17 57.0302C138.85 56.2802 137.47 55.6202 136.17 54.9102L135.33 56.4001Z"
      fill="#FFBA16"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M134.07 64.0099C134.61 64.5699 135.14 65.1499 135.71 65.6899L136.87 64.4698C136.31 63.9498 135.8 63.3798 135.27 62.8398L134.07 64.0099Z"
      fill="#FFBA16"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.4995 85.1396C30.8695 87.8396 29.4296 90.2197 28.0596 93.0297L29.5695 93.7596C30.9195 90.9996 32.3295 88.6696 33.9395 86.0096L32.4995 85.1396Z"
      fill="#FFBA16"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.9602 20.7203C39.0344 17.3913 36.7726 14.2684 34.2102 11.4004L32.9902 12.5404C35.4685 15.3095 37.6566 18.3253 39.5202 21.5404L40.9602 20.7203Z"
      fill="#FFBA16"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M134.14 76.3896C132.687 78.2345 131.351 80.1683 130.14 82.1797L131.56 83.0597C132.74 81.1125 134.043 79.242 135.46 77.4596L134.14 76.3896Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M128.38 78.0105C130.971 79.4951 133.472 81.1313 135.87 82.9105L136.87 81.5605C134.418 79.7434 131.86 78.0737 129.21 76.5605L128.38 78.0105Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M131.03 77.2598C131.66 79.4798 132.34 81.6898 132.85 83.9398L134.49 83.5698C133.97 81.2898 133.29 79.0498 132.65 76.7998L131.03 77.2598Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.56967 21.1299C7.82967 24.5799 5.87969 27.8999 4.17969 31.3799L5.67969 32.1199C7.37969 28.6499 9.32969 25.3299 11.0597 21.8799L9.56967 21.1299Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.99023 25.4904C5.90023 26.4304 8.74024 28.7604 12.1802 30.6604L12.9902 29.1904C9.41023 27.1904 6.45023 24.8304 2.39023 23.8604L1.99023 25.4904Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.49023 23.1603C6.31023 26.0003 6.99022 28.9003 8.04022 31.6603L9.61023 31.0603C8.61023 28.3403 7.91022 25.4902 7.10022 22.7002L5.49023 23.1603Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.93954 29.4199C6.43643 28.0086 10.0415 26.8816 13.7195 26.0499L13.3096 24.4199C9.55802 25.2794 5.87998 26.4329 2.30957 27.8699L2.93954 29.4199Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M103.19 38.0801H42.3496V77.1902C61.7796 87.6302 81.9596 88.7902 103.19 77.1902V38.0801Z"
      fill="#90E37C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M72.7698 44.2303L145.53 42.6504V43.8303L72.7698 64.4603L0.00976562 43.8303V42.6504L72.7698 44.2303Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M72.76 22.0098L145.52 42.6398L72.76 63.2698L0 42.6398L72.76 22.0098Z"
      fill="#90E37C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M113.08 96.7495C113.08 88.9195 103.65 88.9195 103.47 96.7495V104.479H113.08V96.7495Z"
      fill="#FFBA16"
    />
    <path
      d="M72.7695 41.0098L108.27 53.0098V90.8198"
      stroke="black"
      stroke-width="0.22"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<style scoped></style>
