<template>
  <AddToCartModal @close="closeCartModal" :modal-open="openModal" />
  <div>
    <div class="course">
      <div
        class="course__info grid grid-cols-2 bg-white gap-10"
        id="landing-course-form"
      >
        <CourseInfoVue :course="course" />
        <div class="py-12 relative flex-1">
          <div class="course__blocks absolute top-0 left-0 course__discount">
            <DiscountStarcIconVue />
            <div
              class="absolute course__discount__percent font-bold font-size-36px text-white"
            >
              %
            </div>
          </div>

          <div class="form__item flex flex-col gap-4 px-8 py-8">
            <div
              class="font-size-28px font-semibold text-gray-800 adaptive-form-title"
            >
              Оставьте заявку
            </div>
            <div class="flex flex-col gap-1 font-normal text-lg">
              <div class="flex gap-2">
                <div class="text-gray-500 adaptive-form-text">
                  Начало курса:
                </div>
                <div class="text-black adaptive-form-text-bold">
                  {{ course.start_date }}
                </div>
              </div>
              <div class="text-gray-500 color-orange adaptive-form-text">
                🔥 Осталось 5 мест на курсе
              </div>
            </div>

            <InputText
              :placeholder="'Имя'"
              v-model="personName"
              :style="[isPersonName ? '' : 'border: 1px solid rgb(239 68 68);']"
              :label="'Имя'"
              is-required
            />
            <InputText
              :placeholder="'+7'"
              v-model="phone"
              :label="'Номер телефона'"
              :style="[isPhone ? '' : 'border: 1px solid rgb(239 68 68);']"
              is-phone-number
              is-required
            />
            <InputText
              type="email"
              :label="'E-mail'"
              :placeholder="'example@example.com'"
              v-model="email"
            />

            <div
              class="font-medium text-base text-gray-500 flex items-center gap-2"
            >
              <input
                type="checkbox"
                name="legal"
                id="legal"
                v-model="applicationForm.isLegalEntity"
              />
              <label for="legal">Я юридическое лицо</label>
            </div>

            <p class="text-gray-500" v-if="isForm">
              {{ $t("form_request_done") }}
            </p>
            <p class="text-gray-500" v-if="isERR">Введите правильный email!</p>
            <button
              type="submit"
              class="form__btn text-white py-4 px-4 mt-6"
              @click="submitForm"
            >
              Оставить заявку
            </button>
            <button
              v-if="!isItemInCart(courseInfo)"
              type="submit"
              class="form__btn-white py-4 px-4"
              @click="openCartModal"
            >
              Добавить в корзину
            </button>
            <div v-else class="flex justify-center text-in-cart">
              Уже в корзине
            </div>
            <div class="text-center font-normal text-base text-gray-500">
              Отправляя заявку, вы даёте согласие на обработку своих
              персональных данных в соответствии с
              <span class="form__approval-link"
                >политикой конфиденциальности</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="bottom"></div>
      <div class="company">
        <div class="company__div-1">
          <div class="flex flex-col gap-6">
            <div
              class="font-size-40px font-bold text-white adaptive-company-title"
            >
              Компаниям нужны графические дизайнеры.
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Графические дизайнеры играют ключевую роль в современных
              компаниях, и их присутствие становится все более важным. В целом,
              графические дизайнеры являются неотъемлемой частью бизнеса,
              помогая компаниям привлекать внимание, строить бренд, улучшать
              визуальную коммуникацию и добиваться успеха на рынке. Их
              творческий подход и умение работать с дизайнерскими инструментами
              делают их незаменимыми специалистами в современном бизнес-мире.
            </div>
          </div>
          <div
            class="flex-shrink-0 flex items-center justify-center company__img-div"
          >
            <img
              src="../../../../assets/images/landing/designer.png"
              alt=""
              class="company__img"
            />
          </div>
        </div>
        <div class="company__div-2">
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Востребовано
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              По данным сервиса designer.ru на 7 сентября 2022 года, на рынке
              предлагают более 4 тысяч вакансий в офис и 3,3 тысяч на удаленку
              для дизайнеров различных специальностей.
            </div>
            <div class="font-medium text-lg text-gray-400">Источник: hh.kz</div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Перспективно
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Большинство направлений дизайна позволяют выйти на зарубежный
              рынок и получать высокую зарплату, находясь в любой точке мира.
            </div>
            <div class="font-medium text-lg text-gray-400">
              Источник: Forbes
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Высокооплачиваемо
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Зарплата начинающего дизайнера от 150 000 тенге. А уже через три
              года работы — от 700 000 тенге
            </div>
            <div class="font-medium text-lg text-gray-400">Источник: hh.kz</div>
          </div>
        </div>
        <div class="flex gap-3">
          <div class="font-medium text-xl company__sign cursor-pointer">
            Записаться на программу
          </div>
          <div><PurpleArrowRightIcon :fill="'#FEE0A7'" /></div>
        </div>
      </div>
    </div>
    <div class="demanded">
      <div class="text-4xl font-bold adaptive-company-title">
        Разработчики востребованы на рынке
      </div>
      <div class="flex gap-6 adaptive-demanded">
        <div class="flex flex-col gap-10 px-8 py-10 demanded__box-1">
          <div>
            <div class="text-2xl font-semibold">Более 200 вакансий</div>
            <div class="font-normal text-lg text-gray-500">
              Доступно сейчас в дизайн-сфере
            </div>
          </div>
          <div>
            <div>
              <div class="text-2xl font-semibold demanded__box-1__border">
                400 000 ₸
              </div>
            </div>

            <div class="font-normal text-lg text-gray-500">
              Средняя зарплата дизайнера
            </div>
          </div>
          <div>
            <div class="font-normal text-lg text-gray-500">
              *данные взяты с сайтов по поиску работы в августе 2022 г.
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-10 px-8 py-10 demanded__box-2">
          <div class="text-2xl font-semibold">График роста вакансий</div>
          <div>
            <img src="../../../../assets/images/landing/design-wages.png" />
          </div>
        </div>
      </div>
    </div>
    <CourseAdvantagesVue />
    <CourseFlexibilityVue :is-design="true" color="#FFF6E4" />
    <CourseFitPeopleVue />

    <div class="trajectory">
      <div class="text-4xl font-bold adaptive-company-title">
        Учебная траектория
      </div>
      <div class="grid grid-cols-3 gap-6 adaptive-trajectory">
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>от 6 мес</div>
          <div>обучение</div>
        </div>
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>108+ часов</div>
          <div>обучения</div>
        </div>
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>5 вариантов</div>
          <div>специализаций</div>
        </div>
      </div>

      <div class="divider"></div>
      <div class="flex flex-col gap-4 justify-center items-center">
        <div class="font-medium text-lg">Длительность программы:</div>
        <div class="flex gap-2 adaptive-duration">
          <div
            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-1 length__item"
          >
            <div>6 мес</div>
            <div>|</div>
            <div>Разработчик</div>
          </div>
          <div
            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-2 length__item"
          >
            <div>12 мес</div>
            <div>|</div>
            <div>Digital-master</div>
          </div>
          <div
            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-3 length__item"
          >
            <div>24 мес</div>
            <div>|</div>
            <div>Geek</div>
          </div>
        </div>
      </div>

      <div class="divider"></div>
      <div class="flex flex-col gap-120px">
        <img
          src="../../../../assets/images/landing/study-plan-designer.png"
          alt="study-plan"
          class="study-plan"
        />
        <img
          src="../../../../assets/images/landing/study-plan-design-mob.png"
          alt="study-plan"
          class="study-plan2"
        />
        <div class="flex flex-col gap-8">
          <div class="font-bold before-start__title adaptive-company-title">
            До старта
          </div>
          <div class="grid grid-cols-3 gap-6 adaptive-grid">
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с платформой, графиком обучения
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Познакомим с вашим учебным расписанием, разберемся, где смотреть
                уроки, как общаться с преподавателем и куда сдавать домашние
                задания.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с кураторами и преподавателями
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Представим ваших первых преподавателей, познакомим с кураторами,
                добавим в учебные чаты для общения с другими студентами.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Эффективное обучение
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Научим эффективно учиться, быстро и навсегда запоминать
                информацию любой сложности и объема.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Здоровье Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как сохранить здоровье и зрение, какие недуги у
                профессиональных разработчиков и как их избежать, научим
                обустраивать место для комфортной работы и дадим рекомендации по
                физ. тренировкам для ИТ-инженеров.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Ключевые навыки Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как эффективно управлять своим временем и энергией.
                Вы узнаете, как избежать выгорания и всегда оставаться в хорошем
                настроении, даже если что-то идет не по плану.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Английский язык для Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем о роли английского языка в обучении. Дадим
                реккомендации, как эффективно подтянуть исходный уровень до
                требуемого. Вы получите языковую базу для старта обучения.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CourseNeededMaterials :materials="materials" />
    <SelectionCriteria img="selection-criteria-graphic.png" />
    <CourseMainBlockVue
      :new-course-step="courseStep"
      :new-course-step-2="courseStep2"
      :new-learn-bullet="learnBullet"
      :is-gap="true"
      syllabus="syllabus_graphic_design.pdf"
    />
    <CourseFormVue />
    <CourseNextStageVue />
    <CourseTeachersVue :teachers="teachers" :custom="true" />
    <CourseMaterialVue />
    <GuaranteeSliderVue />
    <!--    <OurStudentsWorks />-->
    <CourseReferralVue :col="false" />
    <CourseFaq :newFaq="faq" />
  </div>
</template>

<script>
import PurpleArrowRightIcon from "../../../../assets/icons/PurpleArrowRightIcon.vue";
import CourseFaq from "./CourseFaq.vue";
import GuaranteeSliderVue from "./GuaranteeSlider.vue";
import CourseReferralVue from "./CourseReferral.vue";
import CourseMaterialVue from "./CourseMaterial.vue";
import CourseTeachersVue from "./CourseTeachers.vue";
import CourseNextStageVue from "./CourseNextStage.vue";
import CourseFormVue from "./CourseForm.vue";
import CourseMainBlockVue from "./CourseMainBlock.vue";
import CourseFitPeopleVue from "./CourseFitPeople.vue";
import CourseFlexibilityVue from "./CourseFlexibility.vue";
import CourseAdvantagesVue from "./CourseAdvantages.vue";
import CourseInfoVue from "./CourseInfo.vue";
import DiscountStarcIconVue from "@/assets/icons/DiscountStarcIcon.vue";
import InputText from "@/components/InputText.vue";
import AddToCartModal from "@/views/core/Landing/NewLanding/sections/AddToCartModal.vue";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";
import { ShoppingCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartMixin";
import { AddToCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/AddToCartMixin";
import CourseNeededMaterials from "@/views/core/Landing/Courses/CourseNeededMaterials.vue";
// import OurStudentsWorks from "@/views/core/Landing/Courses/OurStudentsWorks.vue";
import TheLandingFormMixin from "@/views/core/Landing/SliderComponents/TheLandingFormMixin";
import SelectionCriteria from "@/views/core/Landing/Courses/SelectionCriteria.vue";
export default {
  components: {
    // OurStudentsWorks,
    CourseNeededMaterials,
    AddToCartModal,
    InputText,
    DiscountStarcIconVue,
    CourseInfoVue,
    CourseAdvantagesVue,
    CourseFlexibilityVue,
    CourseFitPeopleVue,
    CourseMainBlockVue,
    CourseFormVue,
    CourseNextStageVue,
    CourseTeachersVue,
    CourseMaterialVue,
    CourseReferralVue,
    GuaranteeSliderVue,
    CourseFaq,
    PurpleArrowRightIcon,
    SelectionCriteria,
  },
  mixins: [
    AllCoursesMixin,
    ShoppingCartMixin,
    AddToCartMixin,
    TheLandingFormMixin,
  ],
  methods: {
    submitForm() {
      this.isCompanyName = this.companyName !== "";
      this.isPhone = this.phone !== "";
      this.isPersonName = this.personName !== "";

      if (this.email && !this.email.includes("@")) {
        this.isERR = true;
        return;
      } else {
        this.isERR = false;
      }
      if (this.isCompanyName && this.isPersonName && this.isPhone) {
        this.sendLikeToStudyForm();
      }
    },
  },
  async mounted() {
    await this.getAllCourses();
    this.courseInfo = this.landingCourses.length > 0 && this.landingCourses[0];
  },

  data() {
    return {
      materials: {
        title: "",
        mat: [
          "Компьютер с доступом в Интернет",
          "Программное обеспечение: Убедитесь, что у вас установлены необходимые программы для работы с графическим дизайном и UI/UX дизайном, такие как Adobe Creative Suite (Photoshop, Illustrator), и Figma.",
        ],
      },
      courseInfo: {},
      searchText: "Графический и UI/UX дизайн",
      personName: "",
      phone: "",
      email: "",
      isPersonName: true,
      isPhone: true,
      isForm: false,
      isERR: false,
      teachers: [
        {
          name: "Ердаулет Абсаттар",
          position:
            "Основатель и директор команды разработки в Alpha Education",
          courses: "Веб-разработка, Дизайн, Управление",
          profession:
            "Основатель и директор Alpha Education. Имеет огромный опыт в преподавании таких курсов как веб-разработка, графический дизайн, UI/UX дизайн, Блокчейн, Data Science, разработка игр, мобильная разработка. ",
          education: [
            "Бакалавр Компьютерных Наук (Nazarbayev University)",
            "Магистр Инженерного Менеджмента (Nazarbayev University) ",
          ],
          image: "yerdaulet.jpeg",
          programs: ["Дизайн"],
        },
      ],
      applicationForm: {
        name: "",
        email: "",
        telephone: "",
        isLegalEntity: false,
      },
      course: {
        name: "Графический и UI/UX дизайн",
        level: "Junior",
        program: "Дизайн",
        description:
          "Этот курс предназначен для тех, кто интересуется разработкой визуальных решений для различных цифровых продуктов. Он объединяет основные принципы графического дизайна и интерфейсного и пользовательского опыта (UI/UX) для создания привлекательных, функциональных и интуитивно понятных пользовательских интерфейсов. В течение курса вы узнаете о различных аспектах графического дизайна, включая композицию, цветовую теорию, типографику и использование графических элементов. Вы также изучите принципы UI/UX дизайна, которые включают в себя исследование пользователей, проектирование информационной архитектуры, создание проводников пользователей, тестирование пользовательского интерфейса и анализ данных.",
        skills: [
          "Adobe Photoshop",
          "Adobe Illustrator",
          "Adobe InDesign",
          "Figma",
        ],
        duration: "6 месяцев по 4,5 часа в неделю (156 академических часов)",
        start_date: "август 2024 г",
        price: "600 000 ₸",
      },
      learnBullet: [
        "Основам графического дизайна",
        "Принципам UI/UX дизайна",
        "Работе с инструментами дизайна: Adobe Photoshop, Adobe Illustrator, Sketch и Figma",
        "Техникам и методам UI/UX дизайна",
      ],
      courseStep: [
        {
          title: "1-2 неделя",
          text: [
            "Введение в графический дизайн",
            "Элементы  графическго дизайна",
            "Изучение  основ в Adobe Photoshop CC",
          ],
          open: false,
        },
        {
          title: "3-4 неделя",
          text: [
            "Слои.  Растровые слои. Композиция.",
            "Основы  редактирования фотографий.",
            "Эффекты.",
            "Дуотоны и градиенты.",
          ],
          open: false,
        },
      ],
      courseStep2: [
        {
          title: "25-26 неделя",
          text: [
            "Работа с клиентами и командой разработчиков",
            "Управление проектами UI/UX дизайна. Подготовка к портфолио. ",
            "Завершение проекта UI/UX дизайна",
            "Подготовка и представление портфолио",
          ],
          open: false,
        },
      ],
      faq: [
        {
          question: "Что такое графический дизайн?",
          answer:
            "Графический дизайн - это процесс создания визуальных материалов с использованием различных элементов дизайна, таких как цвет, форма, текст и изображения. Графический дизайн включает создание логотипов, брендирование, упаковки, рекламных материалов и других графических элементов.",
          open: false,
        },
        {
          question: "Что такое UI/UX дизайн?",
          answer:
            "UI (User Interface) дизайн отвечает за разработку интерфейса, с которым пользователи взаимодействуют на веб-сайтах или в приложениях. UX (User Experience) дизайн, в свою очередь, фокусируется на создании оптимального опыта использования продукта для пользователей. Он включает в себя исследования, проектирование пользовательских путей и обеспечение удобства и эффективности взаимодействия с продуктом.",
          open: false,
        },
        {
          question:
            "Какие программные инструменты используются в графическом и UI/UX дизайне?",
          answer:
            "В графическом дизайне часто используются программы Adobe Photoshop, Adobe Illustrator и Sketch для создания и редактирования графических элементов. В UI/UX дизайне популярные инструменты включают Adobe XD, Sketch, Figma и InVision, которые помогают создавать интерфейсы, прототипы и проводить тестирование пользовательского опыта.",
          open: false,
        },
        {
          question: "Что такое цветовая палитра и как ее выбрать для дизайна?",
          answer:
            "Цветовая палитра - это набор цветов, используемых в дизайне. Хорошо подобранная цветовая палитра может создавать гармоничный и привлекательный вид. При выборе цветовой палитры важно учитывать цветовую теорию, психологию цвета и целевую аудиторию проекта.",
          open: false,
        },
        {
          question:
            "Что такое типографика и как выбрать подходящие шрифты для дизайна?",
          answer:
            "Типографика относится к использованию шрифтов в дизайне. Подбор подходящих шрифтов важен для создания эстетически приятного и легко читаемого текста. При выборе шрифтов рекомендуется учитывать брендовую идентичность, читаемость, соответствие контексту и целевой аудитории.",
          open: false,
        },
        {
          question: "Что такое макет и как его создать?",
          answer:
            "Макет - это визуальное представление дизайна, показывающее расположение элементов на веб-странице или в приложении. Макет можно создать с помощью графических инструментов, таких как Adobe Photoshop, Illustrator или специализированных инструментов для UI/UX дизайна, где вы можете размещать элементы, определить размеры и цвета.",
          open: false,
        },
        {
          question:
            "Что такое пользовательский путь и как его учитывать в UI/UX дизайне?",
          answer:
            "Пользовательский путь - это последовательность шагов, которые пользователь выполняет при взаимодействии с продуктом или интерфейсом. В UI/UX дизайне важно учитывать этот путь, чтобы обеспечить логичное и интуитивное взаимодействие. Это включает планирование и организацию элементов интерфейса, чтобы пользователь мог легко достигнуть своей цели.",
          open: false,
        },
        {
          question:
            "Как проводить тестирование пользовательского опыта (UX-тестирование)?",
          answer:
            "UX-тестирование включает в себя сбор обратной связи от пользователей о взаимодействии с продуктом или интерфейсом. Методы тестирования могут варьироваться, включая проведение опросов, интервью, анализ метрик использования и проведение пользовательских тестов, где пользователи исполняют определенные задачи и предоставляют обратную связь.",
          open: false,
        },
        {
          question:
            "Какие принципы следует учитывать при дизайне пользовательского интерфейса (UI)?",
          answer:
            "При дизайне пользовательского интерфейса (UI) важно учитывать такие принципы, как ясность, простота, консистентность, доступность и возможность обратной связи. Дизайн должен быть интуитивно понятным, эффективным и приятным для пользователей.",
          open: false,
        },
        {
          question:
            "Что такое брендирование и как оно связано с графическим дизайном?",
          answer:
            "Брендирование - это процесс создания уникального образа и идентичности компании или продукта. Графический дизайн играет важную роль в брендировании, включая создание логотипов, цветовой схемы, шрифтов и других элементов, которые помогают передать ценности и идентичность бренда визуально.",
          open: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.course {
  background: #2b2e3b;
}
.course--rounded {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 24px;
}
.course__blocks {
  margin-left: 48px;
}
.course__info {
  background: #f4f5fa;
  padding: 40px 100px;
}
.course__level {
  background: #e1d3fe;
  border-radius: 24px;
}
.course__program {
  background: white;
  border-radius: 24px;
}
.course__technology {
  border-radius: 24px;
}
.course__technology-gradient {
  padding: 1px;
  border-radius: 24px;
  background: linear-gradient(
    90.14deg,
    #cc3fd3 55.91%,
    #8453e8 66.25%,
    #525cf3 78.46%
  );
}
.course__icon {
  background: #ffffff;
  border: 1px solid #c8aeff;
  border-radius: 40px;
}
.course__discount {
  transform: translateX(-45%) translateY(-10%);
}
.course__discount__time {
  background: #f4f5fa;
  border-radius: 12px;
  height: 80px;
}
.course__discount__text {
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}
.course__discount__percent {
  top: 21%;
  left: 32%;
}

.bottom {
  background: #f4f5fa;

  width: 100%;
  height: 120px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.company {
  padding: 64px 100px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.company__div-1 {
  display: flex;
  gap: 90px;
}
.company__div-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}
.company__sign {
  color: #fee0a7;
}
.company__img {
  width: 140px;
  height: 140px;
}

.demanded {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.demanded__box-1 {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-1__border {
  display: inline-block;
  border-bottom: 2px solid #593ed3;
}
.demanded__box-1 {
  flex-basis: 50%;
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-2 {
  flex-basis: 50%;

  background: linear-gradient(261.61deg, #ffe9c8 2.57%, #fff6e4 97.17%);
  border-radius: 32px;
  border-radius: 32px;
}

.bg-gradient {
  background: linear-gradient(
    89.33deg,
    #eb8aff 7.2%,
    #b792ff 42.58%,
    #898fff 84.4%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.authors {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.authors__title {
  font-size: 40px;
}
.authors__bullet {
  margin-top: 10px;
  display: inline-block;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #786ce6;
}

.font-size-40px {
  font-size: 40px;
}
.font-size-36px {
  font-size: 36px;
}

.font-size-28px {
  font-size: 28px;
}
.padding-100px {
  padding: 0px 100px;
}

.form__item {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 32px;
}
.form__item input {
  border: 1px solid #9ca3af;
  border-radius: 16px;
}
.form__item input[type="checkbox"] {
  border: 1px solid #9ca3af;
  border-radius: 4px;
}
.form__btn {
  width: 100%;
  background: #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__btn-white {
  width: 100%;
  background: #fff;
  color: #786ce6;
  border: 2px solid #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}

.form__btn-filled {
  width: 100%;
  color: #fff;
  background: #786ce6;
  border: 2px solid #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}

.form__approval-link {
  color: #786ce6;
}

.color-orange {
  color: #d16e2a;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.text-gray-100 {
  color: #f3f4f6;
}
.text-gray-50 {
  color: #f9fafb;
}
.study-plan2 {
  display: none;
}
.text-in-cart {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #786ce6;
}

@media (max-width: 650px) {
  .course__info {
    padding: 32px 16px;
  }
  #landing-course-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .course__blocks {
    padding: 0;
  }
  .bottom {
    display: none;
  }
  .company {
    padding: 32px 16px;
  }
  .company__div-1 {
    padding: 0;
    width: 100%;
    flex-basis: 100%;
  }
  .company__div-2 {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  .demanded {
    padding: 48px 16px;
  }
  .adaptive-demanded {
    flex-direction: column;
  }
  .trajectory {
    padding: 64px 16px;
  }
  .adaptive-trajectory {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #d1d5db;
  }
  .adaptive-duration {
    flex-direction: column;
    align-items: center;
  }
  .length__item {
    width: fit-content;
  }
  .company__img-div {
    display: none;
  }
  .adaptive-grid {
    display: flex;
    flex-direction: column;
  }
  .study-plan {
    display: none;
  }
  .study-plan2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
  }

  .adaptive-form-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .adaptive-form-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-form-text-bold {
    color: black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-company-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .adaptive-company-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-company-subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .course__discount {
    display: none;
  }
}
</style>
