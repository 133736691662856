<template>
  <div
    class="w-full bg-white px-8 py-8 rounded-xl flex flex-col md:flex-row gap-10"
  >
    <div class="h-[350px] w-[500px] max-w-[100%] bg-gray-200 rounded-xl">
      <embed
        :src="certificate.certificate"
        type="application/pdf"
        width="100%"
        height="100%"
      />
    </div>
    <div class="flex flex-col gap-6 items-start">
      <div>
        <div class="text-sm leading-5 font-medium text-gray-500 mb-1">
          {{ $t("certificate_course_name") }}
        </div>
        <div class="text-lg leading-7 font-semibold text-gray-900">
          {{ certificate.course_details.name }}
        </div>
      </div>
      <div class="mb-auto">
        <div class="text-sm leading-5 font-medium text-gray-500 mb-1">
          Длительность курса
        </div>
        <div class="text-lg leading-7 font-semibold text-gray-900">
          {{ certificate.duration }}
          {{ certificate.duration_unit }}
        </div>
      </div>
      <!--      <div class="mb-auto">-->
      <!--        <div class="text-sm leading-5 font-medium text-gray-500 mb-1">-->
      <!--          {{ $t("certificate_generated_date") }}-->
      <!--        </div>-->
      <!--        <div class="text-lg leading-7 font-semibold text-gray-900">-->
      <!--          {{ new Date(certificate.created_at).toLocaleDateString() }}-->
      <!--        </div>-->
      <!--      </div>-->
      <button class="bg-indigo-50 px-4 py-2 text-indigo-700">
        <a
          :href="certificate.certificate"
          download
          class="flex w-fit items-center"
        >
          <DownloadIcon class="w-4 h-4 flex-shrink-0" />
          <div class="ml-2">{{ $t("download_certificate") }}</div>
        </a>
      </button>
    </div>
  </div>
</template>

<script>
import { DownloadIcon } from "@heroicons/vue/solid";

export default {
  components: { DownloadIcon },
  props: ["certificate"],
};
</script>
