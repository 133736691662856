<template>
  <AddToCartModal @close="closeCartModal" :modal-open="openModal" />
  <div>
    <div class="course">
      <div
        class="course__info grid grid-cols-2 bg-white"
        id="landing-course-form"
      >
        <CourseInfoVue :course="course" />
        <TheLandingForm :course-info="courseInfo" @open="openCartModal" />
      </div>
      <div class="bottom"></div>
      <div class="company">
        <div class="company__div-1">
          <div class="flex flex-col gap-6">
            <div
              class="font-size-40px font-bold text-white adaptive-company-title"
            >
              Компаниям нужны программисты.
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Python является одним из самых популярных и востребованных языков
              программирования на современном рынке труда. Есть несколько
              ключевых причин, почему программисты на языке Python востребованы
              и ценятся:
            </div>
          </div>
          <div
            class="flex-shrink-0 flex items-center justify-center company__img-div"
          >
            <img
              src="../../../../assets/images/landing/html-tags.png"
              alt=""
              class="company__img"
            />
          </div>
        </div>
        <div class="company__div-2">
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Простота и удобство использования
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Python имеет простой и легко читаемый синтаксис, который делает
              его доступным для новичков в программировании. Это позволяет
              быстро разрабатывать и тестировать программы, ускоряя процесс
              разработки.
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Множество применений
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Python широко используется в различных областях, включая
              веб-разработку, научные исследования, анализ данных, машинное
              обучение, искусственный интеллект, автоматизацию задач и многое
              другое. Гибкость и мощность Python позволяют решать разнообразные
              задачи и создавать инновационные продукты.
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Растущий спрос на машинное обучение и анализ данных
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Python является одним из основных языков для разработки и
              реализации алгоритмов машинного обучения и анализа данных.
            </div>
          </div>
        </div>
        <div class="flex gap-3">
          <div class="font-medium text-xl company__sign cursor-pointer">
            Записаться на программу
          </div>
          <div><PurpleArrowRightIcon :fill="'#A599F5'" /></div>
        </div>
      </div>
    </div>
    <div class="demanded">
      <div class="text-4xl font-bold adaptive-company-title">
        Разработчики востребованы на рынке
      </div>
      <div class="flex gap-6 adaptive-demanded">
        <div class="flex flex-col gap-10 px-8 py-10 demanded__box-1">
          <div>
            <div class="text-2xl font-semibold">Более 200 вакансий</div>
            <div class="font-normal text-lg text-gray-500">
              Доступно сейчас в разработке
            </div>
          </div>
          <div>
            <div>
              <div class="text-2xl font-semibold demanded__box-1__border">
                500 000 ₸
              </div>
            </div>

            <div class="font-normal text-lg text-gray-500">
              Средняя зарплата junior разработчика от 450 000 тг
            </div>
          </div>
          <div>
            <div class="font-normal text-lg text-gray-500">
              *данные взяты с сайтов по поиску работы в августе 2022 г.
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-10 px-8 py-10 demanded__box-2">
          <div class="flex justify-between">
            <div class="text-2xl font-semibold">График роста вакансий</div>
            <div class="flex gap-10">
              <div class="flex gap-2 items-center">
                <div class="salary-jun-dot"></div>
                <div class="salary-jun-text">Средняя</div>
              </div>
              <div class="flex gap-2 items-center">
                <div class="salary-middle-dot"></div>
                <div class="salary-jun-text">Медианная</div>
              </div>
            </div>
          </div>
          <div>
            <img src="../../../../assets/images/landing/python-wages.png" />
          </div>
        </div>
      </div>
    </div>
    <CourseAdvantagesVue />
    <CourseFlexibilityVue />
    <CourseFitPeopleVue />

    <div class="trajectory">
      <div class="text-4xl font-bold adaptive-company-title">
        Учебная траектория
      </div>
      <div class="grid grid-cols-3 gap-6 adaptive-trajectory">
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>от 6 мес</div>
          <div>обучение</div>
        </div>
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>108+ часов</div>
          <div>обучения</div>
        </div>
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>5 вариантов</div>
          <div>специализаций</div>
        </div>
      </div>

      <div class="divider"></div>
      <div class="flex flex-col gap-4">
        <div class="font-medium text-lg">Длительность программы:</div>
        <div class="flex gap-2 adaptive-duration">
          <div
            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-1 length__item"
          >
            <div>12 мес</div>
            <div>|</div>
            <div>Разработчик</div>
          </div>
          <div
            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-2 length__item"
          >
            <div>24 мес</div>
            <div>|</div>
            <div>Digital-master</div>
          </div>
          <div
            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-3 length__item"
          >
            <div>36 мес</div>
            <div>|</div>
            <div>Geek</div>
          </div>
        </div>
      </div>

      <div class="divider"></div>
      <div class="flex flex-col gap-120px">
        <img
          src="../../../../assets/images/landing/study-plan.png"
          alt="study-plan"
          class="study-plan"
        />
        <img
          src="../../../../assets/images/landing/study-plan-mob.png"
          alt="study-plan"
          class="study-plan2"
        />
        <div class="flex flex-col gap-8">
          <div class="font-bold before-start__title adaptive-company-title">
            До старта
          </div>
          <div class="grid grid-cols-3 gap-6 adaptive-grid">
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с платформой, графиком обучения
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Познакомим с вашим учебным расписанием, разберемся, где смотреть
                уроки, как общаться с преподавателем и куда сдавать домашние
                задания.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с кураторами и преподавателями
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Представим ваших первых преподавателей, познакомим с кураторами,
                добавим в учебные чаты для общения с другими студентами.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Эффективное обучение
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Научим эффективно учиться, быстро и навсегда запоминать
                информацию любой сложности и объема.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Здоровье Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как сохранить здоровье и зрение, какие недуги у
                профессиональных разработчиков и как их избежать, научим
                обустраивать место для комфортной работы и дадим рекомендации по
                физ. тренировкам для ИТ-инженеров.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Ключевые навыки Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как эффективно управлять своим временем и энергией.
                Вы узнаете, как избежать выгорания и всегда оставаться в хорошем
                настроении, даже если что-то идет не по плану.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Английский язык для Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем о роли английского языка в обучении. Дадим
                реккомендации, как эффективно подтянуть исходный уровень до
                требуемого. Вы получите языковую базу для старта обучения.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    <CourseMainBlockVue-->
    <!--      :new-learn-bullet="learnBullet"-->
    <!--      :new-course-step="courseStep"-->
    <!--    />-->
    <CourseNeededMaterials :materials="materials" />
    <CourseMainBlockVue
      :new-learn-bullet="learnBullet"
      :new-course-step="courseStep"
    />
    <CourseFormVue />
    <CourseNextStageVue />
    <!--    <div class="authors">
          <div class="flex flex-col gap-4">
            <div class="font-size-40px text-gray-800 font-bold">
              Авторы программы
            </div>
            <div class="text-gray-500 text-lg">
              Реальные практики ИТ с многолетним опытом работы в лидирующих проектах
            </div>
          </div>
          <div class="grid grid-cols-2 gap-6">
            <div class="bg-gray-radius py-10 px-8 flex flex-col gap-10">
              <div class="flex gap-6">
                <div>
                  <img src="../../../../assets/images/landing/avatar.png" alt="" />
                </div>
                <div class="flex flex-col gap-2">
                  <div class="font-semibold text-2xl">Имя Фамилия</div>
                  <div class="font-normal text-lg text-gray-500">
                    Генеральный директор Alpha Education
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-4">
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Создатель торговой платформы АГРО24 и образовательных проектов:
                    A2.LIFE, ПОТОК24 и iTec Group.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Выпускник факультета Программного обеспечения в сфере
                    Информационных технологий Белорусского государственного
                    университета информатики и радиоэлектроники.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    В прошлом генеральный директор SaaS провайдера «Мегаплан»,
                    совладелец и управляющий партнер тренинговой компании «Бизнес
                    Молодость».
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-radius py-10 px-8 flex flex-col gap-10">
              <div class="flex gap-6">
                <div>
                  <img src="../../../../assets/images/landing/avatar.png" alt="" />
                </div>
                <div class="flex flex-col gap-2">
                  <div class="font-semibold text-2xl">Имя Фамилия</div>
                  <div class="font-normal text-lg text-gray-500">
                    Генеральный директор Alpha Education
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-4">
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Создатель торговой платформы АГРО24 и образовательных проектов:
                    A2.LIFE, ПОТОК24 и iTec Group.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Выпускник факультета Программного обеспечения в сфере
                    Информационных технологий Белорусского государственного
                    университета информатики и радиоэлектроники.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    В прошлом генеральный директор SaaS провайдера «Мегаплан»,
                    совладелец и управляющий партнер тренинговой компании «Бизнес
                    Молодость».
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->

    <CourseTeachersVue :teachers="teachers" :custom="true" />
    <CourseMaterialVue />
    <GuaranteeSliderVue />
    <OurStudentsWorks />

    <CourseReferralVue :col="true" />
    <CourseFaq :new-faq="faq" />
  </div>
</template>

<script>
import PurpleArrowRightIcon from "../../../../assets/icons/PurpleArrowRightIcon.vue";
// import CourseFaq from "./CourseFaq.vue";
import GuaranteeSliderVue from "./GuaranteeSlider.vue";
import CourseReferralVue from "./CourseReferral.vue";
import CourseMaterialVue from "./CourseMaterial.vue";
import CourseTeachersVue from "./CourseTeachers.vue";
import CourseNextStageVue from "./CourseNextStage.vue";
import CourseFormVue from "./CourseForm.vue";
// import CourseMainBlockVue from "./CourseMainBlock.vue";
import CourseFitPeopleVue from "./CourseFitPeople.vue";
import CourseFlexibilityVue from "./CourseFlexibility.vue";
import CourseAdvantagesVue from "./CourseAdvantages.vue";
import CourseInfoVue from "./CourseInfo.vue";
import CourseFaq from "@/views/core/Landing/Courses/CourseFaq.vue";
import axios from "axios";
import TheLandingForm from "@/views/core/Landing/NewLanding/sections/TheLandingForm.vue";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";
import { ShoppingCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartMixin";
import { AddToCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/AddToCartMixin";
import AddToCartModal from "@/views/core/Landing/NewLanding/sections/AddToCartModal.vue";
import CourseMainBlockVue from "@/views/core/Landing/Courses/CourseMainBlock.vue";
import CourseNeededMaterials from "@/views/core/Landing/Courses/CourseNeededMaterials.vue";
import OurStudentsWorks from "@/views/core/Landing/Courses/OurStudentsWorks.vue";

export default {
  components: {
    OurStudentsWorks,
    CourseNeededMaterials,
    CourseMainBlockVue,
    AddToCartModal,
    TheLandingForm,
    CourseFaq,
    CourseInfoVue,
    CourseAdvantagesVue,
    CourseFlexibilityVue,
    CourseFitPeopleVue,
    // CourseMainBlockVue,
    CourseFormVue,
    CourseNextStageVue,
    CourseTeachersVue,
    CourseMaterialVue,
    CourseReferralVue,
    GuaranteeSliderVue,
    // CourseFaq,
    PurpleArrowRightIcon,
  },
  props: {
    coursee: {
      type: Object,
    },
  },
  methods: {
    submitForm() {
      this.isCompanyName = this.companyName !== "";
      this.isPhone = this.phone !== "";
      this.isPersonName = this.personName !== "";

      if (this.email && !this.email.includes("@")) {
        this.isERR = true;
        return;
      } else {
        this.isERR = false;
      }
      if (this.isCompanyName && this.isPersonName && this.isPhone) {
        axios
          .post(
            "https://alphaedu.tech/api/v1/application_forms/like_to_study_application/",
            {
              name: this.personName,
              contact_phone: this.phone,
              email: this.email,
            }
          )
          .catch((err) => {
            console.log(err);
          });
        this.email = "";
        this.phone = "";
        this.personName = "";

        this.isForm = true;
        setTimeout(() => {
          this.isForm = false;
        }, 4000);
      }
    },
  },
  mixins: [AllCoursesMixin, ShoppingCartMixin, AddToCartMixin],
  async mounted() {
    await this.getAllCourses();
    this.courseInfo = this.landingCourses.length > 0 && this.landingCourses[0];
  },
  data() {
    return {
      materials: {
        title: "",
        mat: [
          "Компьютер с доступом в Интернет",
          "Интерактивные среды разработки (IDE): Для написания и запуска программ на Python вам понадобится установить подходящую интегрированную среду разработки (IDE). Некоторые популярные IDE для Python включают PyCharm, Visual Studio Code, IDLE, Spyder и Jupyter Notebook.",
        ],
      },
      courseInfo: {},
      searchText: "Введение в программирование на Python",
      personName: "",
      phone: "",
      email: "",
      isPersonName: true,
      isPhone: true,
      isForm: false,
      isERR: false,
      teachers: [
        {
          programs: ["Программирование"],
          name: "Ергали Мадияр",
          position: "Fullstack разарботчик Devsoft AM, Q-edu, Halyk bank",
          courses: "Введение в программирование на языке Python",
          profession:
            "Бакалавр Компьютерных Наук (Назарбаев Университет). Опыт разработчика 1,5 года (Devsoft AM, Q-edu, Halyk bank). Опыт дата сайнтиста/ дата аналитика 11 месяцев (Rostelekom, Halyk Bank). Опыт Менторства по программированию 1 год (Seeds School, Alpha education. Основатель стартап-сервиса по доставке еды по подписке Smart-delivery. Занимается разработкой веб сервиса.",
          education: [],
          image: "yergali.jpeg",
        },
      ],
      applicationForm: {
        name: "",
        email: "",
        telephone: "",
        isLegalEntity: false,
      },

      courseStep: [
        {
          title: "1-2 неделя",
          text: [
            "Введение в курс. Что такое веб. Основы веб-технологий. Интернет, W3",
            "Различие между фронтендом и бекендом",
            "Веб-стандарты и протоколы",
          ],
          open: false,
        },
        {
          title: "3-4 неделя",
          text: [
            "Основы HTML и структура веб-страницы",
            "Работа с тегами, атрибутами и элементами",
            " Оформление веб-страниц с помощью CSS. Синтаксис CSS. Псевдоклассы. Разметка таблиц.",
            "    Создание адаптивного дизайна.",
          ],
          open: false,
        },
        {
          title: "5-6 неделя",
          text: [
            " CSS-макеты. Bootstrap4. Работа с Github.",
            "Создание макета (практика)",
            "Flexbox. Введение в препроцессоры.",
          ],
          open: false,
        },
      ],
      learnBullet: [
        "Основы HTML/CSS и JavaScript",
        "Фреймворки и библиотеки: Изучение популярных фреймворков и библиотек, таких как React, Vue.js, для эффективной разработки фронтенд-приложений.",
        "Адаптивный и отзывчивый дизайн: Создание веб-страниц, которые отображаются корректно на разных устройствах и экранах.",
        "Языки программирования: Изучение языков программирования, таких как Python, Node.js, Express.js, для создания серверной логики и обработки данных.",
        "Разработка баз данных: MongoDB",
        "API и веб-серверы. Создание API для взаимодействия с фронтенд-частью и разработка веб-серверов для обработки запросов",
      ],
      course: {
        name: "Введение в программирование на языке Python",
        level: "Junior",
        program: "Программирование",
        description:
          "Курс предназначен для тех, кто только начинает свой путь в программировании и желает освоить язык Python с самого начала. Однако, курс также подходит и для тех, кто уже имеет определенный опыт в программировании, но хочет углубить свои знания и достичь более высокого уровня владения Python. В течение курса вы узнаете основные принципы программирования и научитесь применять их на практике с помощью языка Python. Начиная с основ синтаксиса Python, вы будете изучать различные концепции, такие как переменные, условные операторы, циклы, функции, списки, словари и многое другое. По мере продвижения в курсе, вы будете решать практические задачи и создавать небольшие программы, чтобы закрепить свои знания. Кроме того, вы познакомитесь с различными библиотеками Python, которые помогут вам решать более сложные задачи и разрабатывать приложения.",
        skills: ["Python", "Django"],
        duration: "6 месяцев по 4,5 часа в неделю",
        start_date: "сентябрь 2023",
        price: "100 000 ₸",
      },
      faq: [
        {
          question: "Что такое Python?",
          answer:
            "Python - это высокоуровневый язык программирования, который отличается простотой и читаемостью кода. Он широко используется для разработки веб-приложений, научных исследований, анализа данных и автоматизации задач.",
          open: false,
        },
        {
          question: "Что мы будем изучать на курсе ?",
          answer:
            "На этом курсе мы познакомимся с основами синтаксиса Python: типы данных, условные выражения и циклы, функции, работа со строками и файлами, ошибки и исключения, введение в структуры данных, введение в объектно-ориентированное программирование (ООП), ",
          open: false,
        },
        {
          question: "Какой синтаксис используется в Python?",
          answer:
            "Python использует синтаксис, основанный на отступах (выравнивании). Вместо фигурных скобок или ключевых слов для обозначения блоков кода, в Python используются отступы пробелами или табуляцией.",
          open: false,
        },
        {
          question: "Как объявить переменную в Python?",
          answer:
            "Переменную можно объявить простым присваиванием значения. Например: x = 10. Python сам определит тип переменной на основе присвоенного значения.",
          open: false,
        },
        {
          question: "Какие основные типы данных поддерживает Python?",
          answer:
            "Python поддерживает различные типы данных, включая числа (целые, вещественные, комплексные), строки, списки, кортежи, словари и множества.",
          open: false,
        },
        {
          question: "Как вводить и выводить данные в Python? ",
          answer:
            "Для ввода данных с клавиатуры можно использовать функцию input(), а для вывода данных на экран - функцию print().",
          open: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.course {
  background: #2b2e3b;
}
.course--rounded {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 24px;
}
.course__blocks {
  padding-left: 48px;
}
.course__info {
  background: #f4f5fa;
  padding: 40px 100px;
}
.course__level {
  background: #e1d3fe;
  border-radius: 24px;
}
.course__program {
  background: white;
  border-radius: 24px;
}
.course__technology {
  border-radius: 24px;
}
.course__technology-gradient {
  padding: 1px;
  border-radius: 24px;
  background: linear-gradient(
    90.14deg,
    #cc3fd3 55.91%,
    #8453e8 66.25%,
    #525cf3 78.46%
  );
}
.course__icon {
  background: #ffffff;
  border: 1px solid #c8aeff;
  border-radius: 40px;
}
.course__discount__time {
  background: #f4f5fa;
  border-radius: 12px;
  height: 80px;
}
.course__discount__text {
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.bottom {
  background: #f4f5fa;
  width: 100%;
  height: 120px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.company {
  padding: 64px 100px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.company__div-1 {
  display: flex;
  gap: 90px;
}
.company__div-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}
.company__sign {
  color: #a599f5;
}
.company__img {
  width: 140px;
  height: 140px;
}

.demanded {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.demanded__box-1 {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-1__border {
  display: inline-block;
  border-bottom: 2px solid #593ed3;
}
.demanded__box-1 {
  flex-basis: 50%;
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-2 {
  flex-basis: 50%;
  background: #eee6ff;
  border-radius: 32px;
}

.bg-gradient {
  background: linear-gradient(
    89.33deg,
    #eb8aff 7.2%,
    #b792ff 42.58%,
    #898fff 84.4%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.authors {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.authors__bullet {
  margin-top: 10px;
  display: inline-block;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #786ce6;
}

.font-size-40px {
  font-size: 40px;
}
.font-size-36px {
  font-size: 36px;
}
.font-size-28px {
  font-size: 28px;
}
.padding-100px {
  padding: 0px 100px;
}

.form__item {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 32px;
}
.form__item input {
  border: 1px solid #9ca3af;
  border-radius: 16px;
}
.form__btn {
  width: 100%;
  background: #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__btn-white {
  width: 100%;
  background: #fff;
  color: #786ce6;
  border: 2px solid #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__approval-link {
  color: #786ce6;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.text-gray-100 {
  color: #f3f4f6;
}
.text-gray-50 {
  color: #f9fafb;
}
.study-plan2 {
  display: none;
}
.salary-jun-text {
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
}
.salary-jun-dot {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: #786ce6;
}
.salary-middle-dot {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: #fc893b;
}

@media (max-width: 650px) {
  .course__info {
    padding: 32px 16px;
  }
  #landing-course-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .course__blocks {
    padding: 0;
  }
  .bottom {
    display: none;
  }
  .company {
    padding: 32px 16px;
  }
  .company__div-1 {
    padding: 0;
    width: 100%;
    flex-basis: 100%;
  }
  .company__div-2 {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  .demanded {
    padding: 48px 16px;
  }
  .adaptive-demanded {
    flex-direction: column;
  }
  .trajectory {
    padding: 64px 16px;
  }
  .adaptive-trajectory {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #d1d5db;
  }
  .adaptive-duration {
    flex-direction: column;
    align-items: center;
  }
  .length__item {
    width: fit-content;
  }
  .company__img-div {
    display: none;
  }
  .adaptive-grid {
    display: flex;
    flex-direction: column;
  }
  .study-plan {
    display: none;
  }
  .study-plan2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
  }

  .adaptive-form-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .adaptive-form-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-form-text-bold {
    color: black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-company-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .adaptive-company-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-company-subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
}
</style>
