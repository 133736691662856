<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="1" filter="url(#filter0_d_628_9747)">
      <rect
        x="58"
        y="57.998"
        width="56"
        height="56"
        rx="16"
        transform="rotate(-180 58 57.998)"
        fill="white"
      />
      <path
        d="M28.7161 35.0143C28.8242 34.9075 28.91 34.7803 28.9685 34.6402C29.0271 34.5 29.0573 34.3496 29.0573 34.1976C29.0573 34.0457 29.0271 33.8953 28.9685 33.7551C28.91 33.6149 28.8242 33.4877 28.7161 33.381L26.4994 31.1643H37.2327C37.8744 31.1643 38.3994 30.6393 38.3994 29.9976C38.3994 29.356 37.8744 28.831 37.2327 28.831H26.4994L28.7161 26.6143C28.8242 26.5075 28.91 26.3803 28.9685 26.2402C29.0271 26.1 29.0573 25.9496 29.0573 25.7976C29.0573 25.6457 29.0271 25.4953 28.9685 25.3551C28.91 25.2149 28.8242 25.0877 28.7161 24.981C28.2611 24.526 27.5377 24.526 27.0827 24.981L22.8944 29.1693C22.7863 29.2772 22.7005 29.4054 22.6419 29.5466C22.5834 29.6877 22.5532 29.839 22.5532 29.9918C22.5532 30.1446 22.5834 30.2959 22.6419 30.437C22.7005 30.5782 22.7863 30.7064 22.8944 30.8143L27.0827 35.0143C27.1895 35.1224 27.3167 35.2082 27.4569 35.2667C27.5971 35.3253 27.7475 35.3555 27.8994 35.3555C28.0513 35.3555 28.2018 35.3253 28.3419 35.2667C28.4821 35.2082 28.6093 35.1224 28.7161 35.0143Z"
        fill="#7A69EE"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_628_9747"
        x="0"
        y="0.998047"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_628_9747"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_628_9747"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
