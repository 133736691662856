<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
  >
    <rect x="3" y="3" width="40" height="40" rx="12" fill="white" />
    <path
      d="M19.0415 23.8333C19.2073 23.8333 19.3662 23.7674 19.4834 23.6502C19.6007 23.533 19.6665 23.374 19.6665 23.2083C19.6665 23.0425 19.6007 22.8835 19.4834 22.7663C19.3662 22.6491 19.2073 22.5833 19.0415 22.5833C18.8757 22.5833 18.7168 22.6491 18.5996 22.7663C18.4824 22.8835 18.4165 23.0425 18.4165 23.2083C18.4165 23.374 18.4824 23.533 18.5996 23.6502C18.7168 23.7674 18.8757 23.8333 19.0415 23.8333Z"
      fill="#FBA564"
    />
    <path
      d="M18.4165 13.8333H25.9165C26.9111 13.8333 27.8649 14.2283 28.5682 14.9316C29.2714 15.6349 29.6665 16.5887 29.6665 17.5833V25.0833C29.6678 25.6818 29.5252 26.2719 29.2506 26.8038C28.9761 27.3357 28.5777 27.7938 28.089 28.1395C27.4552 28.5922 26.6954 28.8348 25.9165 28.8333H18.4165C17.8179 28.8345 17.2278 28.6919 16.6959 28.4174C16.164 28.1428 15.7059 27.7444 15.3603 27.2558C14.908 26.6217 14.6654 25.8621 14.6665 25.0833V17.5833C14.6665 16.5887 15.0616 15.6349 15.7649 14.9316C16.4681 14.2283 17.4219 13.8333 18.4165 13.8333ZM17.7915 17.5833C17.6257 17.5833 17.4668 17.6491 17.3496 17.7663C17.2324 17.8835 17.1665 18.0425 17.1665 18.2083C17.1665 18.374 17.2324 18.533 17.3496 18.6502C17.4668 18.7674 17.6257 18.8333 17.7915 18.8333H26.5415C26.7073 18.8333 26.8662 18.7674 26.9834 18.6502C27.1007 18.533 27.1665 18.374 27.1665 18.2083C27.1665 18.0425 27.1007 17.8835 26.9834 17.7663C26.8662 17.6491 26.7073 17.5833 26.5415 17.5833H17.7915ZM19.0415 25.0833C19.5388 25.0833 20.0157 24.8857 20.3673 24.5341C20.719 24.1824 20.9165 23.7055 20.9165 23.2083C20.9165 22.711 20.719 22.2341 20.3673 21.8824C20.0157 21.5308 19.5388 21.3333 19.0415 21.3333C18.5442 21.3333 18.0673 21.5308 17.7157 21.8824C17.3641 22.2341 17.1665 22.711 17.1665 23.2083C17.1665 23.7055 17.3641 24.1824 17.7157 24.5341C18.0673 24.8857 18.5442 25.0833 19.0415 25.0833ZM22.7915 22.5833C22.6257 22.5833 22.4668 22.6491 22.3496 22.7663C22.2324 22.8835 22.1665 23.0425 22.1665 23.2083C22.1665 23.374 22.2324 23.533 22.3496 23.6502C22.4668 23.7674 22.6257 23.8333 22.7915 23.8333H26.5415C26.7073 23.8333 26.8662 23.7674 26.9834 23.6502C27.1007 23.533 27.1665 23.374 27.1665 23.2083C27.1665 23.0425 27.1007 22.8835 26.9834 22.7663C26.8662 22.6491 26.7073 22.5833 26.5415 22.5833H22.7915Z"
      fill="#FBA564"
    />
    <path
      d="M18.1216 30.0833C18.8091 30.8508 19.8066 31.3333 20.9166 31.3333H26.5416C28.0334 31.3333 29.4642 30.7407 30.5191 29.6858C31.5739 28.6309 32.1666 27.2002 32.1666 25.7083V20.0833C32.1666 18.9733 31.6841 17.9746 30.9166 17.2883V25.7083C30.9166 26.8687 30.4556 27.9815 29.6352 28.8019C28.8147 29.6224 27.7019 30.0833 26.5416 30.0833H18.1216Z"
      fill="#FBA564"
    />
    <rect
      x="1.5"
      y="1.5"
      width="43"
      height="43"
      rx="13.5"
      stroke="#FBA564"
      stroke-opacity="0.2"
      stroke-width="3"
    />
  </svg>
</template>

<style scoped></style>
