<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PurpleAdminButton",
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
});
</script>

<template>
  <div class="cert-gen-button cursor-pointer">
    <slot></slot>
  </div>
</template>

<style scoped>
.cert-gen-button {
  border-radius: 6px;
  padding: 9px 17px;
  color: white;
  background: #4f46e5;
}
</style>
