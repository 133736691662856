<template>
  <AddUserModal
    @closeModal="showNewUserModal = false"
    :showNewUserModal="showNewUserModal"
    :user="user"
  />
  <FormLayout>
    <template #title>
      <div class="grid xl:grid-cols-2 lg:grid-cols-1">
        <span class="text-2xl text-gray-900 font-semibold mb-4">
          {{ $t("roles") }}
        </span>
        <div class="flex h-fit place-content-end gap-3 flex-col lg:flex-row">
          <InputText
            :placeholder="$t('search_by_full_name')"
            v-model="search"
            class="h-full"
            style="font-size: initial !important"
          >
            <template #leftIcon>
              <SearchIcon class="h-5 text-gray-400" />
            </template>
          </InputText>
          <input
            type="file"
            :ref="`file`"
            class="hidden"
            accept=".xls,.xlsx"
            id="users_file"
            @change="uploadFile"
          />
          <TheButton bg="outline" class="text-gray-500 bg-white h-fit w-fit">
            <a href="/add_users_example.xlsx" download class="flex w-fit">
              <DownloadIcon class="h-5 mr-2" />
              {{ $t("download_template") }}
            </a>
          </TheButton>
          <TheButton
            bg="outline"
            class="text-gray-500 bg-white h-fit w-fit"
            @click="handleUploadButton"
          >
            <UploadIcon class="h-5 mr-2" />
            {{ $t("upload_from_file") }}
          </TheButton>
          <TheButton bg="primary" @click="showNewUserModal = true">
            {{ $t("add_user") }}
          </TheButton>
        </div>
      </div>
    </template>
  </FormLayout>
  <div>
    <TheTable class="mt-8">
      <TheTableHead>
        <TheTableHeadCell class="pl-4 pr-3 sm:pl-6 uppercase">{{
          $t("name")
        }}</TheTableHeadCell>
        <TheTableHeadCell
          class="pl-4 pr-3 sm:pl-6 uppercase"
          style="min-width: 250px"
          >{{ $t("course") }}</TheTableHeadCell
        >
        <!-- <TheTableHeadCell>{{$t('status')}}</TheTableHeadCell> -->
        <TheTableHeadCell class="pl-4 pr-3 sm:pl-6 uppercase">{{
          $t("role")
        }}</TheTableHeadCell>
        <!--        <TheTableHeadCell class="pl-4 pr-3 sm:pl-6 uppercase">{{$t('permissions')}}</TheTableHeadCell>-->
        <TheTableHeadCell class="pl-4 pr-3 sm:pl-6"></TheTableHeadCell>
      </TheTableHead>
      <TheTableBody>
        <TheTableRow v-for="user in users" :key="`user_role_${user.email}`">
          <TheTableRowCell class="pl-4 pr-3 sm:pl-6">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <UserAvatar
                  class="rounded-full flex-shrink-0"
                  :url="user.avatar"
                />
              </div>
              <div class="ml-4">
                <div class="font-medium text-gray-900">
                  {{
                    user.patronymic_name
                      ? user.last_name +
                        " " +
                        user.first_name +
                        " " +
                        user.patronymic_name
                      : user.full_name
                  }}
                </div>
                <div class="flex text-gray-500">
                  {{ user.email }}
                </div>
              </div>
            </div>
          </TheTableRowCell>
          <TheTableRowCell class="pl-4 pr-3 sm:pl-6 max-w-sm"
            ><span
              class="max-w-100%"
              style="word-break: break-word !important"
              >{{ user.course_name }}</span
            ></TheTableRowCell
          >
          <TheTableRowCell class="pl-4 pr-3 sm:pl-6"
            ><p
              v-for="role in user.roles"
              :key="`role_${user.email}_${role.id}`"
            >
              {{ $t(role.name) }}
            </p></TheTableRowCell
          >
          <!-- <TheTableRowCell class="pl-4 pr-3 sm:pl-6">{{user.permission}}</TheTableRowCell> -->
          <TheTableRowCell class="dropdown">
            <DotsVerticalIcon
              class="h-5 cursor-pointer my-3"
              @click="user.is_dropdown_open = !user.is_dropdown_open"
            />
            <div v-if="user.is_dropdown_open" class="dropdown-content">
              <p
                class="w-full hover:bg-gray-100 p-2 cursor-pointer"
                @click="showEditForm(user)"
              >
                <a class="ml-2 mr-2">{{ $t("edit") }}</a>
              </p>
              <p
                class="w-full hover:bg-gray-100 p-2 cursor-pointer"
                @click="openDeleteUserModal(user)"
              >
                <a class="ml-2 mr-2">{{ $t("delete") }}</a>
              </p>
            </div>
          </TheTableRowCell>
        </TheTableRow>
      </TheTableBody>
    </TheTable>
    <TheButton
      v-if="position"
      class="fixed bottom-2 right-2"
      @click="scrollToTop"
      >{{ $t("back_to_top") }}</TheButton
    >
  </div>
  <UploadingUsersModal
    :show="openUploadFromFileModal"
    @close="openUploadFromFileModal = false"
    class="z-50"
  >
    <div class="p-6">
      <div class="flex justify-between items-center">
        <span class="text-xl leading-7 font-semibold text-gray-900 mb-2"
          >Загрузка пользователей из файла</span
        >
        <XIcon class="h-6 w-6 text-gray-400 cursor-pointer" />
      </div>
      <div class="overflow-hidden overflow-y-auto max-h-[360px] px-2 pb-4">
        <TheTable class="mt-8">
          <TheTableHead>
            <TheTableHeadCell
              ><span
                class="text-xs text-gray-500 leading-4 font-medium tracking-wider uppercase"
                >{{ $t("name") }}</span
              ></TheTableHeadCell
            >
            <TheTableHeadCell
              ><span
                class="text-xs text-gray-500 leading-4 font-medium tracking-wider uppercase"
                >{{ $t("course") }}</span
              ></TheTableHeadCell
            >
            <TheTableHeadCell
              ><span
                class="text-xs text-gray-500 leading-4 font-medium tracking-wider uppercase"
                >{{ $t("role") }}</span
              ></TheTableHeadCell
            >
            <TheTableHeadCell
              ><span
                class="text-xs text-gray-500 leading-4 font-medium tracking-wider uppercase"
                >{{ $t("status") }}</span
              ></TheTableHeadCell
            >
            <TheTableHeadCell
              ><span
                class="text-xs text-gray-500 leading-4 font-medium tracking-wider uppercase"
                >{{ $t("password") }}</span
              ></TheTableHeadCell
            >
            <TheTableHeadCell />
          </TheTableHead>
          <TheTableBody>
            <template v-for="user of uploadingUsers" :key="user.id">
              <TheTableRow>
                <TheTableRowCell>
                  <div class="flex gap-4">
                    <div class="flex-shrink-0">
                      <UserAvatar
                        class="rounded-full flex-shrink-0"
                        :url="user.avatar"
                      />
                    </div>
                    <div class="flex flex-col">
                      <span
                        class="text-sm text-gray-900 leading-5 font-medium"
                        >{{ `${user.first_name} ${user.last_name}` }}</span
                      >
                      <span
                        class="text-sm text-gray-500 leading-5 font-normal"
                        >{{ user.email }}</span
                      >
                    </div>
                  </div>
                </TheTableRowCell>
                <TheTableRowCell>
                  <div class="flex flex-col">
                    <span class="text-sm text-gray-900 leading-5 font-medium">{{
                      user.course
                    }}</span>
                    <span class="text-sm text-gray-500 leading-5 font-normal">{{
                      user.specialization
                    }}</span>
                  </div>
                </TheTableRowCell>
                <TheTableRowCell
                  ><span class="text-sm text-gray-500 leading-5 font-normal">{{
                    $t(user.roles_names.join(""))
                  }}</span></TheTableRowCell
                >
                <TheTableRowCell
                  ><span class="text-sm text-gray-500 leading-5 font-normal">{{
                    user.status ? $t("added") : $t("not_added")
                  }}</span></TheTableRowCell
                >
                <TheTableRowCell>
                  <input
                    type="text"
                    v-model="user.password"
                    class="text-sm text-gray-500 leading-5 font-normal border-[1px] border-gray-300 shadow-sm rounded-md"
                  />
                </TheTableRowCell>
                <TheTableRowCell>
                  <PencilAltIcon class="w-4 text-indigo-500 cursor-pointer" />
                </TheTableRowCell>
              </TheTableRow>
            </template>
          </TheTableBody>
        </TheTable>
      </div>
      <div
        class="flex justify-between items-center mt-6 p-4 bg-gray-50 rounded-xl"
      >
        <span class="text-sm text-gray-700 leading-5 font-medium"
          >Добавить пользователей в список получателей рассылки</span
        >
        <Switch
          as="button"
          v-model="emailSubscriptionEnabled"
          class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline"
          :class="emailSubscriptionEnabled ? 'bg-indigo-600' : 'bg-gray-200'"
          v-slot="{ checked }"
          @click="handleSwitchClick"
        >
          <span
            class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
            :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
          />
        </Switch>
      </div>
      <div class="flex justify-end mt-10">
        <TheButton bg="primary" @click="handleAddUsers"
          ><span class="text-sm leading-5 font-medium"
            >Добавить пользователей</span
          ></TheButton
        >
      </div>
    </div>
  </UploadingUsersModal>
  <TheModal
    :show="deleteUserModalOpen"
    @close="closeDeleteUserModal"
    class="z-50"
  >
    <div class="p-6">
      <div class="flex gap-4 mb-4">
        <div class="w-10 h-10 grid place-items-center bg-red-100 rounded-full">
          <ExclamationIcon class="text-red-600 w-6 h-6" />
        </div>
        <div class="flex flex-col gap-2">
          <div class="text-lg text-gray-900 leading-6 font-medium">
            {{ $t("delete_user") }}
          </div>
          <div class="text-sm text-gray-500 leading-5">
            {{ $t("delete_user_text") }}
          </div>
        </div>
      </div>
      <div class="flex justify-end gap-3">
        <TheButton bg="outline" class="mr-3" @click="closeDeleteUserModal">{{
          $t("cancel")
        }}</TheButton>
        <TheButton bg="danger" class="mr-3" @click="deleteUser">{{
          $t("delete")
        }}</TheButton>
      </div>
    </div>
  </TheModal>
</template>
<script>
import FormLayout from "@/layouts/FormLayout.vue";
import InputText from "@/components/InputText.vue";
import TheButton from "@/components/Button/Button";
import {
  SearchIcon,
  UploadIcon,
  DotsVerticalIcon,
  ExclamationIcon,
} from "@heroicons/vue/outline";
import TheTable from "@/components/TheTable/TheTable.vue";
import TheTableHead from "@/components/TheTable/TheTableHead.vue";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell.vue";
import TheTableBody from "@/components/TheTable/TheTableBody.vue";
import TheTableRow from "@/components/TheTable/TheTableRow.vue";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import {
  GET_USERS_ROLES,
  GET_USERS_NEXT,
  DELETE_USER,
  UPDATE_FROM_FILE,
  CREATE_USERS,
} from "@/store/users";
import { mapActions } from "vuex";
import AddUserModal from "@/components/TheModal/AddUserModal.vue";
import { Switch } from "@headlessui/vue";
import { DownloadIcon, PencilAltIcon } from "@heroicons/vue/solid";
import UploadingUsersModal from "@/views/users/Roles/UploadingUsersModal";
import TheModal from "@/components/TheModal/TheModal.vue";
export default {
  components: {
    FormLayout,
    TheButton,
    InputText,
    SearchIcon,
    UploadIcon,
    TheTable,
    TheTableHead,
    TheTableHeadCell,
    TheTableBody,
    TheTableRow,
    TheTableRowCell,
    DotsVerticalIcon,
    UserAvatar,
    AddUserModal,
    Switch,
    PencilAltIcon,
    UploadingUsersModal,
    DownloadIcon,
    ExclamationIcon,
    TheModal,
  },
  data: () => {
    return {
      showNewUserModal: false,
      users: [],
      nextLink: null,
      search: "",
      user: null,
      position: false,
      openUploadFromFileModal: false,
      uploadingUsers: [],
      emailSubscriptionEnabled: false,
      deleteUserModalOpen: false,
      userToDelete: null,
    };
  },
  async created() {
    const data = await this.GET_USERS_ROLES();
    this.users = data.results;
    this.nextLink = data.next;
    window.onscroll = () => {
      this.extendUsers();
    };
  },
  methods: {
    ...mapActions([GET_USERS_ROLES, GET_USERS_NEXT, DELETE_USER]),
    async extendUsers() {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight
      ) {
        if (this.nextLink) {
          const data = await this.GET_USERS_NEXT(
            this.nextLink.split("/users")[1]
          );
          this.users = this.users.concat(data.results);
          this.nextLink = data.next;
        }
      }
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        this.position = true;
      } else {
        this.position = false;
      }
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    showEditForm(user) {
      this.user = user;
      this.showNewUserModal = true;
    },
    handleSwitchClick() {},
    async uploadFile(e) {
      const file = e.target.files[0];
      const data = await this.$store.dispatch(UPDATE_FROM_FILE, file);
      this.uploadingUsers = data.users;
      e.target.value = "";
      this.openUploadFromFileModal = true;
    },
    handleUploadButton() {
      this.$refs["file"].click();
    },
    async addNewUser() {
      //
    },
    async handleAddUsers() {
      await this.$store.dispatch(CREATE_USERS, {
        users: this.uploadingUsers,
        emailSubscriptionEnabled: this.emailSubscriptionEnabled,
      });
      this.openUploadFromFileModal = false;
    },
    openDeleteUserModal(user) {
      this.deleteUserModalOpen = true;
      this.userToDelete = user;
    },
    closeDeleteUserModal() {
      this.deleteUserModalOpen = false;
      this.userToDelete = null;
    },
    async deleteUser() {
      if (this.userToDelete) {
        await this[DELETE_USER](this.userToDelete.id);
        this.users = this.users.filter((u) => u.id !== this.userToDelete.id);
        this.closeDeleteUserModal();
      }
    },
  },
  watch: {
    search: async function (new_val) {
      const data = await this.GET_USERS_ROLES({ search: new_val });
      this.users = data.results;
      this.nextLink = data.next;
    },
  },
};
</script>
<style lang="postcss">
.text-primary {
  color: #6366f1;
}
.dropdown {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  left: -120px;
  top: 7px;
  z-index: 1;
  padding: 0px;
}
</style>
