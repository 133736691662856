<template>
  <div>
    <p class="text-2xl text-gray-900 font-semibold mb-4">
      {{$t('passing')}}
      <span class="text-gray-600">
      {{courses.length}}
    </span>
    </p>
    <CourseCardRowExtended
        v-for="course in courses"
        :key="`course_${course.id}`"
        class="mb-4"
        :name="course.name"
        :logo = "course.logo"
        :progress="course.progress.progress"
        :currentChapter="course.progress.current_chapter"
        :currentLesson="course.progress.current_lesson"
        :average_grade="course.progress.avg_score"
        :specializations="course.specializations"
        :lastResourceId="course.progress.last_resource"
        :lastPage="course.progress.last_page"
    />
  </div>
</template>
<script>
  import CourseCardRowExtended from "@/components/Courses/Card/CourseCardRowExtended";
  import CurrentCoursesMixin from "@/views/courses/CurrentCourses/CurrentCoursesMixin";
  export default {
    components: {CourseCardRowExtended},
    mixins:[CurrentCoursesMixin],
    computed:{
      // avgGrade(){

      // }
    }
  }
</script>
