<template>
  <p class="text-sm text-indigo-600 font-medium truncate max-w-[95%]">
    {{specializationsTextList}}
  </p>
</template>
<script>
  export default {
    props:{
      specializations: {
        type:Array,
        default:()=>[]
      }
    },
    computed:{
      specializationsTextList(){
        return this.specializations.reduce((acc,cur)=>{
          if(acc ==="")return cur.name
          return `${acc}, ${cur.name}`
        },'')
      }
    }
  }
</script>