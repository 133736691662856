import {mapActions} from "vuex";
import {GET_SPECIALIZATIONS} from "@/store/specializations";
import {mapFields} from "vuex-map-fields";

export default {
    methods:{
        ...mapActions([GET_SPECIALIZATIONS]),
    },
    computed:{
        ...mapFields(['specializations.specializations'])
    },
    created() {
        this[GET_SPECIALIZATIONS]();
    }
}