const baseURL = '/api/v1/mailings';
const fileBaseURL = '/api/v1/mailing_attachments';

export default $axios => ({
	getMailings() {
		return $axios.get(`${baseURL}/`);
	},
	editMailing(mailing) {
		return $axios.put(`${baseURL}/${mailing.id}`, mailing);
	},
	addAttachment(data) {
		return $axios.post(`${fileBaseURL}/`, data);
	},
	deleteAttachment(id) {
		return $axios.delete(`${fileBaseURL}/${id}`);
	}
});