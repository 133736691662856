<template>
  <TheCard>
    <div class="flex place-items-center mb-4 gap-3">
      <UserAvatar
        class="mr-2 flex-shrink-0"
        :url="formatUrl(instructor.avatar)"
      />
      <div>
        <div class="text-sm leading-5 font-normal">{{ $t("instructor") }}</div>
        <div class="text-base leading-6 font-semibold">
          {{ instructor.full_name }}
        </div>
      </div>
    </div>
    <div class="text-sm leading-5 font-normal" v-html="description" />
  </TheCard>
</template>
<script>
import TheCard from "@/components/TheCard/TheCard";
import UserAvatar from "@/components/UserAvatar";
import InstructorInfoMixin from "@/components/Courses/InstructorInfo/InstructorInfoMixin";
export default {
  components: { UserAvatar, TheCard },
  mixins: [InstructorInfoMixin],
  methods: {
    formatUrl(url) {
      return url
        ? url.replace("http://", "https://")
        : "https://www.business2community.com/wp-content/uploads/2017/08/blank-profile-picture-973460_640.png";
    },
  },
};
</script>
