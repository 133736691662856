export default [
    {
        name:"Specializations",
        path:'/specializations',
        component:async ()=>require('../layouts/CabinetLayout'),
        children:[
            {
                name:'SpecializationsList',
                path:'',
                component:async ()=>require('../views/specializations/List/List')
            },
            {
                name:"SpecializationForm",
                path: "form",
                component:async ()=>require('../views/specializations/Form/Form')
            }
        ]
    }
]