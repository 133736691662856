<template>
    <router-view/>
</template>

<script>
import {GET_ACTIVE_USER} from "@/store/auth";

export default {
  created() {
    const token = localStorage.getItem('token')
    if(token){
      this.$store.dispatch(GET_ACTIVE_USER)
    }
  }
}
</script>