<template>
  <TheModal :show="show">
    <div class="flex p-4 w-full">
      <div class="flex mr-4">
        <div class="bg-red-100 p-2 rounded-full align-center h-fit">
          <ExclamationIcon class="h-5 w-5 text-red-600 "/>
        </div>
      </div>
      <div class="flex flex-col flex-grow">
        <h1 class="text-lg leading-6 font-medium text-gray-900 mb-2">
          {{$t('submit_resource_confirmation_title')}}
        </h1>
        <div class="w-100 text-sm leading-5 font-normal text-gray-500 mb-4">
          {{$t('submit_resource_confirmation_body')}}
        </div>
        <div class="flex place-content-end w-full">
          <TheButton bg="outline" @click="$emit('cancel')" class="mr-4">
            {{$t('cancel')}}
          </TheButton>
          <TheButton bg="primary" @click="$emit('submit')">
            {{$t('submit_resource')}}
          </TheButton>
        </div>
      </div>
    </div>
  </TheModal>
</template>
<script>
import TheModal from "@/components/TheModal/TheModal";
import {ExclamationIcon} from '@heroicons/vue/outline'
import TheButton from "@/components/Button/Button";
import {mapActions} from "vuex";
import {GET_RESOURCE} from "@/store/resources";
export default {
  components: {
    TheButton,
    TheModal,
    ExclamationIcon
  },
  props:{
    show:{
      type:Boolean,
      default:false
    }
  },
  methods:{
    ...mapActions([GET_RESOURCE]),

  },
}
</script>