import { createRouter, createWebHistory } from "vue-router";
import authRoutes from "./auth";
import coreRoutes from "./core";
import courseRoutes from "./courses";
import student from "./student";
import resources from "./resources";
import specializations from "@/router/specializations";
import mailings from "@/router/mailings";
import users from "@/router/users";
import stats from "@/router/stats";
import news from "@/router/news";
import certificates from "@/router/certificates";

const routes = [
  ...authRoutes,
  ...coreRoutes,
  ...courseRoutes,
  ...student,
  ...resources,
  ...specializations,
  ...mailings,
  ...users,
  ...stats,
  ...news,
  ...certificates,
];

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { left: 0, top: 0 };
    }
  },
  routes,
});

router.beforeEach((to, from, next) => {
  // Store scroll position before navigating to a new route
  if (from && from.meta.scrollTop === undefined) {
    from.meta.scrollTop =
      window.pageYOffset || document.documentElement.scrollTop;
  }
  next();
});

router.afterEach((to) => {
  // Restore scroll position when navigating back to a previous route
  if (to.meta.scrollTop !== undefined) {
    window.scrollTo(0, to.meta.scrollTop);
  }
});

export default router;
