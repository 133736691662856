<template>
  <span
    class="inline-flex items-center px-2.5 py-0.5 badge text-center"
    :class="colorClass"
  >
    <svg
      v-if="has_circle"
      class="-ml-0.5 mr-1.5 h-2 w-2"
      fill="currentColor"
      viewBox="0 0 8 8"
    >
      <circle cx="4" cy="4" r="3" />
    </svg>
    <slot />
  </span>
</template>
<script>
export default {
  props: {
    bg: {
      type: String,
      default: "primary",
    },
    has_circle: {
      type: Boolean,
      default: true,
    },
    levelOutline: {
      type: String,
      default: "primary",
    },
  },
  computed: {
    colorClass() {
      return `badge-${this.bg.toLowerCase()}`;
    },
  },
};
</script>
<style lang="postcss">
.badge {
  @apply rounded-full;
}
.badge-danger {
  @apply text-red-800 bg-red-100;
}
.badge-success {
  @apply text-green-800 bg-green-100;
}
.badge-primary {
  @apply text-indigo-800 bg-indigo-100;
}
.badge-being_checked {
  @apply text-orange-800 bg-orange-100;
}

.badge-has_grades {
  @apply text-yellow-800 bg-yellow-100;
}

.badge-middle {
  @apply text-black;
  background-color: #f0eafd;
}

.badge-junior {
  @apply text-black;
  background-color: #fee0a7;
}

.badge-senior {
  @apply text-black;
  background-color: #a8ebd3;
}

.badge-program {
  @apply text-black bg-gray-100;
}
.badge-program-white {
  @apply text-black;
  background-color: #fff;
}

.badge-middle_outline {
  @apply text-black;
  border: 1px solid #c8aeff;
}
.badge-junior_outline {
  @apply text-black;
  border: 1px solid #ffcf74;
}
.badge-senior_outline {
  @apply text-black;
  border: 1px solid #40d39f;
}
</style>
