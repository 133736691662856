<template>
  <router-link  custom :to="`/courses/form`" v-slot="{ navigate, href }">
    <a :href="href" @click="navigate" class="border-dashed border-gray-300 border-2 shadow-lg rounded-xl  bg-white w-full sm:max-w-fit	overflow-hidden" style="max-width: 264px">
      <div class="flex flex-col justify-center h-full place-items-center">
        <DocumentAddIcon class="text-indigo-600 h-5 w-5"/>
        <span class="text-sm leading-5 font-medium tex-gray-900">
            {{$t('create_new_course')}}
        </span>
      </div>
    </a>
  </router-link>
</template>
<script>
import {DocumentAddIcon} from "@heroicons/vue/outline"

  export default {
    components:{
      DocumentAddIcon
    }
  }
</script>