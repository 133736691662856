<script>
import InputText from "@/components/InputText.vue";
import { ShoppingCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartMixin";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";
import LandingButton from "@/components/Button/LandingButton.vue";
import TheLandingFormMixin from "@/views/core/Landing/SliderComponents/TheLandingFormMixin";
export default {
  components: { LandingButton, InputText },
  props: ["courseInfo"],
  mixins: [ShoppingCartMixin, AllCoursesMixin, TheLandingFormMixin],
  methods: {
    openCartModal() {
      this.$emit("open");
    },
    submitForm() {
      this.isCompanyName = this.companyName !== "";
      this.isPhone = this.phone !== "";
      this.isPersonName = this.personName !== "";

      if (this.email && !this.email.includes("@")) {
        this.isERR = true;
        return;
      } else {
        this.isERR = false;
      }
      if (this.isCompanyName && this.isPersonName && this.isPhone) {
        this.sendLikeToStudyForm();
      }
    },
  },
  data() {
    return {
      personName: "",
      phone: "",
      email: "",
      isPersonName: true,
      isPhone: true,
      isForm: false,
      isERR: false,
    };
  },
};
</script>

<template>
  <div class="course__blocks flex flex-col gap-4 pt-10">
    <div>
      <div class="flex flex-col gap-10">
        <div class="form__item flex flex-col gap-4 px-8 py-8">
          <div
            class="font-size-28px font-semibold text-gray-800 adaptive-form-title"
          >
            Записаться на курс
          </div>
          <div class="flex flex-col gap-1 font-normal text-lg">
            <div class="flex gap-2">
              <div class="text-gray-500 adaptive-form-text">Начало курса:</div>
              <div class="text-black adaptive-form-text-bold">
                {{ courseInfo.start_date }}
              </div>
            </div>
            <div class="text-gray-500 adaptive-form-text">
              Новый поток каждый месяц
            </div>
          </div>
          <InputText
            :placeholder="'Имя'"
            v-model="personName"
            :style="[isPersonName ? '' : 'border: 1px solid rgb(239 68 68);']"
            :label="'Имя'"
            is-required
          />
          <InputText
            :placeholder="'+7'"
            v-model="phone"
            :label="'Номер телефона'"
            :style="[isPhone ? '' : 'border: 1px solid rgb(239 68 68);']"
            is-phone-number
            is-required
          />
          <InputText
            type="email"
            :label="'E-mail'"
            :placeholder="'example@example.com'"
            v-model="email"
          />

          <p class="text-gray-500" v-if="isForm">
            {{ $t("form_request_done") }}
          </p>
          <p class="text-gray-500" v-if="isERR">Введите правильный email!</p>
          <LandingButton type="submit" @click="submitForm">
            Оставить заявку
          </LandingButton>

          <LandingButton
            shape="outline"
            v-if="!isItemInCart(courseInfo)"
            type="submit"
            @click="openCartModal"
          >
            Добавить в корзину
          </LandingButton>
          <div v-else class="flex justify-center text-in-cart">
            Уже в корзине
          </div>
          <div class="text-center font-normal text-base text-gray-500">
            Отправляя заявку, вы даёте согласие на обработку своих персональных
            данных в соответствии с
            <span class="form__approval-link"
              >политикой конфиденциальности</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.course__blocks {
  padding-left: 48px;
}
.font-size-28px {
  font-size: 28px;
}
.form__item {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 32px;
}
.form__item input {
  border: 1px solid #9ca3af;
  border-radius: 16px;
}
.form__btn {
  width: 100%;
  background: #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__approval-link {
  color: #786ce6;
}
.text-in-cart {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #786ce6;
}

@media (max-width: 650px) {
  .course__blocks {
    padding: 0;
  }

  .adaptive-form-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .adaptive-form-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-form-text-bold {
    color: black;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
