<script>
import { defineComponent } from "vue";
import TheTable from "@/components/TheTable/TheTable.vue";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell.vue";
import TheTableRow from "@/components/TheTable/TheTableRow.vue";
import TheTableBody from "@/components/TheTable/TheTableBody.vue";
import TheTableHead from "@/components/TheTable/TheTableHead.vue";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell.vue";
import MyGradesFetchMixin from "@/views/courses/MyGrades/MyGradesFetchMixin";
import gr from "vue-tailwind/dist/l10n/gr";

export default defineComponent({
  name: "MyGrades",
  computed: {
    gr() {
      return gr;
    },
  },
  components: {
    TheTableHeadCell,
    TheTableHead,
    TheTableBody,
    TheTableRow,
    TheTableRowCell,
    TheTable,
  },
  data() {
    return {};
  },
  mixins: [MyGradesFetchMixin],
});
</script>

<template>
  <div class="px-8 pt-7 flex flex-col gap-6">
    <h1>Мои оценки</h1>
    <div class="my-grades__div">
      <div>
        <TheTable class="mt-8 mb-2">
          <TheTableHead>
            <TheTableHeadCell
              v-for="(grade, index) in grade_categories"
              :key="index"
            >
              <div class="flex flex-col px-2 py-1">
                <div
                  class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  {{ grade.name }}
                </div>
                <div class="text-xs font-medium tracking-wider text-gray-500">
                  из {{ grade.weight }}
                </div>
              </div>
            </TheTableHeadCell>

            <TheTableHeadCell>
              <div class="flex flex-col px-2 py-1">
                <div
                  class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
                >
                  Total
                </div>
                <div class="text-xs font-medium tracking-wider text-gray-500">
                  из 100
                </div>
              </div>
            </TheTableHeadCell>
          </TheTableHead>
          <TheTableBody>
            <TheTableRow>
              <template v-for="(grade, index) of grades" :key="index">
                <TheTableRowCell>
                  <div class="text-sm leading-5 font-normal px-2 py-1">
                    <span class="text-sm leading-5 font-medium text-gray-900">
                      {{ grade }}
                    </span>
                  </div>
                </TheTableRowCell>
              </template>
            </TheTableRow>
          </TheTableBody>
        </TheTable>
      </div>
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #111827;
}
.my-grades__div {
  border-radius: 12px;
  padding: 0 24px 24px 24px;
  box-shadow: 0px 1px 2px 0px #0000000f;
  background: white;
  overflow: hidden;
}
</style>
