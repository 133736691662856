<template>
  <svg
    width="78"
    height="79"
    viewBox="0 0 78 79"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.0681 44.8122C16.8502 39.9166 16.4519 39.4761 16.2234 34.5812C22.835 34.0437 29.4252 33.3581 36.0602 33.045C38.824 32.9145 41.7197 33.1699 44.4652 32.8551C44.5398 32.8466 44.0986 27.7519 44.1165 26.2218C51.105 29.7873 58.1973 33.2106 65.0103 37.1209C59.2617 41.6582 52.0804 46.3589 45.7694 50.2765C45.2591 48.8326 45.0643 44.7873 44.9981 43.2273C42.6782 43.0445 40.4448 43.3829 38.1226 43.4717C31.5778 43.722 23.5762 44.0995 17.0681 44.8122Z"
      stroke="#786CE6"
      stroke-miterlimit="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M60.5761 34.6689C58.8468 37.7805 56.9674 41.269 55.0726 44.2818"
      stroke="#786CE6"
      stroke-miterlimit="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M56.4346 32.4707C53.7039 37.8584 50.6683 43.9427 47.5842 49.1402"
      stroke="#786CE6"
      stroke-miterlimit="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M52.3633 30.4102C50.1185 34.895 47.4377 39.7466 45.0622 44.1645"
      stroke="#786CE6"
      stroke-miterlimit="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M44.4688 32.8457C43.0937 36.1075 41.3063 40.0632 40.0065 43.3525"
      stroke="#786CE6"
      stroke-miterlimit="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M38.8519 33.019C36.9821 36.3431 35.4766 40.2179 33.8111 43.6552"
      stroke="#786CE6"
      stroke-miterlimit="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.2768 33.2015C31.326 36.462 29.7417 40.5824 27.9421 43.9427"
      stroke="#786CE6"
      stroke-miterlimit="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M28.0342 33.584C26.4483 36.9778 24.738 40.8454 23.1931 44.2596"
      stroke="#786CE6"
      stroke-miterlimit="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.9053 34.0078C21.1144 37.327 19.4748 41.1613 18.1293 44.695"
      stroke="#786CE6"
      stroke-miterlimit="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
