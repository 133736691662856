<template>
  <slot name="label" v-if="hasLabel" />
  <div
    class="relative rounded-2xl shadow-sm flex flex-col gap-1"
    :class="additionalClass"
  >
    <div
      class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none"
    >
      <slot name="leftIcon" />
    </div>
    <label class="text-gray-500"> {{ label }}</label>
    <input
      :type="type"
      :class="{
        has_errors: hasErrors,
        no_errors: !hasErrors,
        'input-with-left-icon': hasLeftIcon,
      }"
      class="input"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      v-bind="$attrs"
      v-maska="isPhoneNumber ? '+7(###)###-##-##' : null"
      :required="isRequired"
    />
    <div
      class="absolute inset-y-0 right-0 flex items-center"
      v-if="!!$slots['rightButton']"
    >
      <slot name="rightButton" />
    </div>
    <div
      class="absolute inset-y-0 right-0 pr-3 flex items-center"
      :class="hasErrors ? 'pointer-events-none' : ''"
    >
      <slot name="rightIcon" />
      <ExclamationCircleIcon
        class="h-5 w-5 text-red-500 ml-3"
        aria-hidden="true"
        v-if="hasErrors"
      />
    </div>
  </div>
  <p class="mt-2 text-sm text-red-600" v-if="!!errors[0]">{{ errors[0] }}</p>
</template>
<script>
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

export default {
  components: {
    ExclamationCircleIcon,
  },
  props: {
    modelValue: {},
    errors: {
      type: Array,
      default: () => [],
    },
    additionalClass: {
      type: String,
      default: "",
    },
    isPhoneNumber: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    hasErrors() {
      return !!this.errors[0];
    },
    hasLeftIcon() {
      return !!this.$slots["leftIcon"];
    },
    hasRightIcon() {
      return !!this.$slots["rightIcon"];
    },
    hasLabel() {
      return !!this.$slots["label"];
    },
  },
};
</script>
<style lang="postcss">
.has_errors {
  @apply border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500;
}

.input {
  @apply appearance-none block w-full px-5 py-3 border rounded-[16px] shadow-sm focus:outline-none  sm:text-sm placeholder:text-base text-lg;
}

.input-with-left-icon {
  @apply pl-9;
}

.no_errors {
  @apply border-gray-300 placeholder-gray-400 focus:ring-indigo-500 focus:border-indigo-500;
}
</style>
