<template>
  <TheCard style="padding: 0;border: none" >
    <div class="w-full p-6 place-content-between inline-flex  border-gray-200 border-b">
      <div class=" text-lg leading-8 font-semibold text-gray-900">
        {{content_name}}
      </div>
      <div class="inline-flex">
        <MenuAlt4Icon class="h-5 mr-2 draggable_icon cursor-move" />
        <TrashIcon class="h-5 cursor-pointer" @click="handleRemoveButtonClicked"/>
      </div>
    </div>
    <div class="p-6 border-b" >
      <slot/>
      <div class="grid grid-cols-1 gap-2 sm:grid-cols-2">
        <UploadedFile
            v-for="uploaded_file in attachments"
            :key="`uploaded_file_${uploaded_file.id}_${uploaded_file.status}`"
            :size="uploaded_file.size"
            :name="uploaded_file.name"
            :id="uploaded_file.id"
            @removeFile="removeFile"
            @retryUpload="retryUpload"
        />
      </div>
      <div class="mt-6" v-if="show_attachments_button">
        <input type="file"  hidden ref="attachments_input" 
          @change="handleFileUpload"
        >
        <TheButton bg="outline" @click="clickInputFiles">
          <PaperClipIcon class="h-5 mr-2"/>
          {{$t('attach_file')}}
        </TheButton>
      </div>
    </div>
    <div class="p-6" v-if="type==='file_submission_question'">
      <TheCard class="bg-gray-50" v-if="criteria.length > 0">
        <InputText :errors="[thresholdErrors]" :placeholder="$t('enter_threshold')" v-model="localThreshold" @change="$emit('thresholdChange', localThreshold)" class="mt-2 mb-2">
          <template #label>
            <span class="text-sm leading-5 font-medium text-gray-700">{{$t('threshold')}}</span>
          </template>
        </InputText>
      </TheCard>
      <TheCard v-for="criterion, index in criteria" :key="`criterion_${_key}_${index}`" class="bg-gray-50">
        <TheCardHeader class="border-b">
          <div class="flex justify-between mb-4">
            <p>{{$t('criterion', { number: index + 1 })}}</p>
            <TrashIcon class="text-gray-400 h-5 cursor-pointer" @click="$emit('removeCriterion',index)"/>
          </div>
        </TheCardHeader>
        <InputText :errors="[!criterion.is_empty_description ? '' : $t('enter_description')]" :placeholder="$t('enter_description')" v-model="criterion.description" class="mt-2 mb-2">
          <template #label>
            <span class="text-sm leading-5 font-medium text-gray-700">{{$t('criterion_description')}}</span>
          </template>
        </InputText>
        <InputText :errors="[!criterion.is_empty_weight ? '' : $t('enter_weight')]" :placeholder="$t('enter_weight')" v-model="criterion.weight">
          <template #label>
            <span class="text-sm leading-5 font-medium text-gray-700">{{$t('criterion_weight')}}</span>
          </template>
        </InputText>
      </TheCard>
      <TheCard class="bg-gray-50" v-if="criteria.length > 0">
        <div class="flex justify-between">
          <span class="text-sm leading-5 font-medium text-gray-700">{{$t('peer_assessment')}}</span>
          <Switch
            as="button"
            v-model="is_peer_assessment"
            class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline"
            :class="is_peer_assessment ? 'bg-indigo-600' : 'bg-gray-200'"
            v-slot="{ checked }"
            @click="is_peer_assessment=!is_peer_assessment"
          >
            <span
              class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
              :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
            />
          </Switch>
        </div>
      </TheCard>
      <TheCard class="bg-gray-50" v-if="criteria.length > 0 && is_peer_assessment">
        <InputText :errors="[is_number_graders || typeof is_number_graders == 'undefined' ? '' : $t('enter_number_graders')]" :placeholder="$t('enter_number_graders')" v-model="local_graders_count" class="mt-2 mb-2">
          <template #label>
            <span class="text-sm leading-5 font-medium text-gray-700">{{$t('number_graders')}}</span>
          </template>
        </InputText>
      </TheCard>
      <TheButton bg="secondary" @click="$emit('addCriterion')">
        {{$t('add_criterion')}}
      </TheButton>
    </div>
  </TheCard>
</template>
<script>
  import TheCard from "@/components/TheCard/TheCard";
  import  {MenuAlt4Icon, TrashIcon, PaperClipIcon} from "@heroicons/vue/outline";
  import TheButton from "@/components/Button/Button";
  import ContentBlockMixin from "@/views/resources/Form/components/ContentBlockMixin";
  import { UPLOAD_ATTACHMENT, DELETE_ATTACHMENT } from "@/store/resources";
  import { mapActions } from "vuex";
  import UploadedFile from "@/components/Files/UploadedFile";
  import {reactive} from "vue";
  import InputText from "@/components/InputText.vue";
  import TheCardHeader from "@/components/TheCard/TheCardHeader.vue";
  import { Switch } from "@headlessui/vue";
  export default {
    components: { TheButton, TheCard, MenuAlt4Icon, TrashIcon, PaperClipIcon, UploadedFile, InputText, TheCardHeader, Switch },
    mixins:[ContentBlockMixin],
    methods:{
      ...mapActions([UPLOAD_ATTACHMENT, DELETE_ATTACHMENT]),
      clickInputFiles(){
        this.$refs.attachments_input.click();
      },
      generateFileObject(file){
        return reactive({
          file,
          name:file.name,
          status:"loading",
          size:file.size,
          id:'_'+this.modelValue
        })
      },
      setStatusToObject(fileObject,status){
        fileObject.status=status
      },
      async handleFileUpload(e){
        const file = e.target.files[0];
        const new_file = this.generateFileObject(file)
        this.attachments.push(new_file)
        return this.submitFile(new_file)
      },
      async removeFile(id){
        let fileToDeleteIndex = this.attachments.findIndex(el=>el.id ===id)
        this.attachments[fileToDeleteIndex].status="loading"
        await this.$store.dispatch(DELETE_ATTACHMENT,{id:id,type:this.type})
        this.attachments = this.attachments.filter(file=>file.id !==id)
      },
      async submitFile(fileObject){
        this.setStatusToObject(fileObject,"loading")
        try{
          const {data} = await this.$store.dispatch(UPLOAD_ATTACHMENT,{
            file:fileObject.file,
            type: this.type,
            name:fileObject.name,
            question:this.modelValue})
          Object.assign(fileObject,data)
          this.setStatusToObject(fileObject,"success")
          setTimeout(()=>{
            this.setStatusToObject(fileObject,'default')
          },1000)
        }catch(e){
          this.setStatusToObject(fileObject,"error")
        }
      },
    }
  }
</script>