<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "GuestLectureItem",
  props: {
    guest: {
      type: Object,
      default: () => {
        return {
          name: "Имя Фамилия",
          company: "CEO & Founder Company Name",
          logo: "jusan.png",
          img: "avatar-placeholder.png",
        };
      },
    },
  },
  methods: {
    requireImage(imagePath) {
      if (!imagePath) return "";
      return require(`@/assets/images/landing/${imagePath}`);
    },
  },
});
</script>

<template>
  <div class="guest-lectures__box flex">
    <div class="flex flex-col gap-6 justify-center">
      <div class="guest-lectures__avatar flex justify-center">
        <img :src="requireImage(guest?.img || 'avatar-placeholder.png')" />
      </div>
      <div class="flex flex-col gap-2 justify-center">
        <div class="guest-lectures__name">{{ guest.name }}</div>
        <div class="guest-lectures__company flex flex-col">
          <div>{{ guest.position }}</div>
          <div>{{ guest.company }}</div>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center guest-lectures__logo">
      <img :src="requireImage(guest.logo)" />
    </div>
  </div>
</template>

<style scoped>
.guest-lectures__box {
  border-radius: 40px;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  background: #2f3342;
}
.guest-lectures__avatar img {
  width: 80px;
  height: 80px;
  border-radius: 100px;
  flex-shrink: 0;
  flex-grow: 0;
}
.guest-lectures__name {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
}
.guest-lectures__company {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #f9fafb;
}
.guest-lectures__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.guest-lectures__logo img {
  max-width: 100px;
  max-height: 60px;
}
img {
  object-fit: cover;
}

@media (max-width: 650px) {
  .guest-lectures__box {
    border-radius: 24px;
    padding: 24px 16px;
  }
  .guest-lectures__name {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
  }
  .guest-lectures__company {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
  .guest-lectures__logo img {
    max-width: 80px;
  }
}
</style>
