import { DELETE_ANSWER } from "@/store/resources"
import { mapActions } from "vuex"
export default {
    props:{
        _key:{
            type:String,
            required:true
        },
        // modelValue:{},
        content:'',
        available_answers:[]
    },
    data:()=>({
        correct_answer:'',
        correct_answers:[]
    }),
    methods:{
        ...mapActions([DELETE_ANSWER]),
        generateRandomKey() {
            return (new Date()).getTime() + Math.floor(Math.random() * 10000).toString()
        },
        async handleRemoveAnswerButtonClicked(id, key){
            if(id){
                await this.DELETE_ANSWER(id)
                this.$emit('removeAvailableAnswer', id, true)
            } else {
                this.$emit('removeAvailableAnswer', key, false)
            }
        },
    }
}