<template>
  <div class="bg-white rounded-xl px-6 py-8 shadow-xl">
    <div v-for="faq in faqs" :key="faq" class="pt-4">
      <h5 class="flex w-full cursor-pointer" @click="faq.is_closed = !faq.is_closed">
            <span class="flex-auto">
              <span v-if="faq.is_closed" class="text-l font-medium">
                {{faq.question}}
              </span>
              <span v-if="!faq.is_closed" class="text-l font-medium text-indigo-600">
                {{faq.question}}
              </span>
              </span>
        <ChevronDownIcon v-if="faq.is_closed" class="h-6" />
        <ChevronUpIcon v-else class="h-6 text-indigo-600"/>
      </h5>
      <hr v-if="faq.is_closed" class="mt-6"/>
      <div v-if="!faq.is_closed">
        <div class="flex py-4 text-base">
          <span class="text-l text-gray-500">
            {{faq.ans}}
          </span>
        </div>
        <hr/>
      </div>
    </div>
  </div>
</template>

<script>
import {ChevronUpIcon} from "@heroicons/vue/outline";
import {ChevronDownIcon} from "@heroicons/vue/outline";

export default {
  components: {ChevronUpIcon, ChevronDownIcon},
  props: {
    faqs : {}
  }
}
</script>
