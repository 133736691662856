<template>
  <div class="teachers" :class="bgColor">
    <div class="flex gap-4 justify-between">
      <h4
        class="font-size-40px font-bold adaptive-block-title"
        :class="textColor"
      >
        Преподаватели на курсе
      </h4>
      <div
        class="flex gap-4 adaptive-buttons-mobile"
        v-if="visibleSlides.length > 1"
      >
        <div @click="previous" class="cursor-pointer">
          <CourseTeachersButton>
            <svg
              :class="{ hover: hoverLeft }"
              @mouseover="hoverLeft = true"
              @mouseleave="hoverLeft = false"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                :fill="hoverLeft ? 'white' : '#7A69EE'"
                transform="rotate(180, 14, 14)"
              />
            </svg>
          </CourseTeachersButton>
        </div>
        <div @click="next" class="cursor-pointer">
          <CourseTeachersButton>
            <svg
              :class="{ hover: hoverRight }"
              @mouseover="hoverRight = true"
              @mouseleave="hoverRight = false"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                :fill="hoverRight ? 'white' : '#7A69EE'"
              />
            </svg>
          </CourseTeachersButton>
        </div>
      </div>
    </div>
    <div class="carousel-container">
      <transition-group
        :class="{
          carousel: visibleSlides.length > 1,
          'no-carousel': visibleSlides.length == 1,
        }"
        tag="div"
      >
        <component
          v-for="(slide, i) in visibleSlides"
          :key="i"
          :is="slide.component"
          :teacher="slide.props.teacher"
        ></component>
      </transition-group>
    </div>

    <div class="carousel-container2">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="16"
        :pagination="pagination"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        ><swiper-slide v-for="(slide, i) in computedSlides" :key="`slide-${i}`">
          <component
            :is="slide.component"
            :teacher="slide.props.teacher"
            :class="boxColor"
          ></component
        ></swiper-slide>
      </swiper>
    </div>
    <div class="flex justify-end">
      <router-link :to="{ name: 'AllTeachers' }"
        ><button
          class="flex gap-2 py-4 px-6 justify-center items-center font-medium text-lg bg-white teachers__button"
          :class="buttonColor"
        >
          Посмотреть всех преподавателей
          <ArrowNarrowRightIcon class="h-4 w-4 mt-1" /></button
      ></router-link>
    </div>
  </div>
</template>

<script>
import ButtonLeft from "../../../../assets/icons/ButtonLeft.vue";
import ButtonRight from "../../../../assets/icons/ButtonRight.vue";
import { ArrowNarrowRightIcon } from "@heroicons/vue/outline";
import CourseTeacherItem from "@/views/core/Landing/Courses/CourseTeacherItem.vue";
import EmploymentIcon from "@/assets/icons/EmploymentIcon.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import BootcampIcon from "@/assets/icons/BootcampIcon.vue";
import InternshipIcon from "@/assets/icons/InternshipIcon.vue";
import StudyIcon from "@/assets/icons/StudyIcon.vue";
import JobIcon from "@/assets/icons/JobIcon.vue";
import CourseTeachersButton from "@/views/core/Landing/Courses/CourseTeachersButton.vue";

import "swiper/css";
import "swiper/css/pagination";
import "../../../../assets/css/swiper.css";
import { Navigation, Pagination } from "swiper";
import AllTeachersMixin from "@/views/core/Landing/Courses/AllTeachers/AllTeachersMixin";
export default {
  components: {
    CourseTeachersButton,
    JobIcon,
    StudyIcon,
    InternshipIcon,
    Swiper,
    BootcampIcon,
    SwiperSlide,
    EmploymentIcon,
    ButtonLeft,
    ButtonRight,
    ArrowNarrowRightIcon,
    CourseTeacherItem,
  },
  mixins: [AllTeachersMixin],
  async mounted() {
    try {
      await this.getJustTeachers();
    } catch (error) {
      console.error("Error in mounted hook in AllTeachers:", error);
    }
  },
  props: {
    bg: {
      type: String,
      default: "primary",
    },
    teachers: {
      type: Array,
      default: () => [],
    },
    custom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hoverLeft: false,
      hoverRight: false,

      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },

        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
      },
      modules: [Navigation, Pagination],
      startIndex: 0,
    };
  },
  computed: {
    bgColor() {
      return `bg-${this.bg.toLowerCase()}`;
    },
    textColor() {
      if (this.bg.toLowerCase() == "white") {
        return `text-black`;
      }
      return `text-white`;
    },
    boxColor() {
      if (this.bg.toLowerCase() == "white") {
        return `bg-grey`;
      }
      return `bg-white`;
    },
    buttonColor() {
      if (this.bg.toLowerCase() == "white") {
        return `button-white`;
      }
      return `button-transparent`;
    },

    computedSlides() {
      return (this.custom ? this.teachers : this.landingTeachers).map(
        (teacher) => {
          return {
            component: CourseTeacherItem,
            props: {
              teacher: teacher,
            },
          };
        }
      );
    },
    visibleSlides() {
      if (this.computedSlides.length <= 2) {
        return this.computedSlides;
      }

      const endIndex = this.startIndex + 2;

      const slides = this.computedSlides.concat(this.computedSlides);

      const data = slides.slice(this.startIndex, endIndex);

      return data;
    },
  },
  methods: {
    scrollToForm() {
      const formElement = document.getElementById("landing-course-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    next() {
      if (this.startIndex + 2 < this.computedSlides.length + 2) {
        this.startIndex++;
      } else {
        this.startIndex = 0;
      }
    },
    previous() {
      if (this.startIndex > 0) {
        this.startIndex--;
      } else {
        this.startIndex = this.computedSlides.length;
      }
    },
    handleClick() {
      // Handle click event
    },
  },
};
</script>

<style scoped>
.teachers {
  padding: 78px 80px;
  display: flex;
  flex-direction: column;
  gap: 52px;
  overflow: hidden;
}
.bg-primary {
  background: linear-gradient(90deg, #4776e6 0%, #8e54e9 100%);
  .text-white {
    color: white;
  }
}
.bg-white {
  background: white;
  .text-black {
    color: black;
  }
}

.bg-grey {
  background: #f4f5fa;
}

.carousel-container {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
}

.carousel {
  gap: 24px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.no-carousel {
  flex-basis: 50%;
  overflow: hidden;
}

.carousel > * {
  flex: 0 0 48.5%;
}

.carousel {
  width: 100%;
}

.teachers__button {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  border: 2px solid #6759ea;
  color: #6759ea;
}

.text-gray-50 {
  color: #f9fafb;
}
.font-size-40px {
  font-size: 40px;
}

:deep .swiper-pagination-bullet {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e4d7ff;
}

:deep .swiper-pagination-bullet-active {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: white;
}
.carousel-container2 {
  display: none;
}

button:hover {
  background: #5b4ccc;
  color: white;
}

.button-transparent,
.button-white {
  background: none;
  border: none;
  box-shadow: none;
  gap: 16px;
  padding: 0;
}
.button-transparent {
  color: white;
}
.button-white {
  color: #5b4ccc;
}

@media (max-width: 650px) {
  .teachers {
    padding: 40px 16px;
  }
  .carousel-container {
    display: none;
  }
  .carousel-container2 {
    display: flex;
  }
  .adaptive-block-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .adaptive-buttons-mobile {
    display: none;
  }
  :deep .swiper-pagination-bullet {
    margin-top: 16px;
    width: 48px;
    height: 6px;
    border-radius: 4px;
    background: #e5e7eb;
  }

  :deep .swiper-pagination-bullet-active {
    margin-top: 16px;
    width: 48px;
    height: 6px;
    border-radius: 4px;
    background: #5b4ccc;
  }
}
</style>
