<template>
  <ResourceAttachments v-if="attachments.length>0">
    <FileLink v-for="attachment in attachments"
              :key="`attachment_${attachment.id}`"
              :size="attachment.size"
              :name="attachment.name"
              :url="attachment.url"
    />
  </ResourceAttachments>
</template>
<script>
import ResourceAttachments from "@/components/Resources/ResourceBlock/Attachments/AttachmentsWrapper";
import FileLink from "@/components/Files/FileLink";
export default {
  components: {FileLink, ResourceAttachments},
  props:{
    attachments:{
      type:Array,
      default:()=>[]
    }
  },
  created(){
    this.attachments.forEach(element => {
      if(element.file){
        element.url = element.file
      }
    });
  }
}
</script>