//todo project main state is there
export const SET_LOADING = "SET_LOADING";
export const SEARCH_REQUEST = "SEARCH_REQUEST";
export const SET_SEARCH_REQUEST_RESULT = "SET_SEARCH_REQUEST_RESULT";
export const FILE_UPLOAD_REQUEST = "FILE_UPLOAD_REQUEST";
export const OPEN_ALERT = "OPEN_ALERT";
export const CLOSE_ALERT = "CLOSE_ALERT";
export const SET_ALERT = "SET_ALERT";
export const ADD_NEWS_CONTENT = "ADD_NEWS_CONTENT";
export const REMOVE_NEWS_CONTENT = "REMOVE_NEWS_CONTENT";
export const SET_NEWS_CONTENT = "SET_NEWS_CONTENT";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {
    is_loading: false,
    search: {
      courses: [],
      text: "",
      is_modal_open: false,
    },
    is_sidebar_open: false,
    icon_selector_modal: {
      is_open: false,
      initial_value: null,
      handleChange: () => {},
    },
    alert: {
      open: false,
      variant: "success",
      duration: 3000,
      text: "",
    },
    news: {
      content: [],
    },
  };
  const actions = {
    [SEARCH_REQUEST]: async (store, payload) => {
      //when more requests make them concurrent
      const { data } = await $api.courses.get_list(payload);
      store.commit(SET_SEARCH_REQUEST_RESULT, data);
      return { data };
    },
    [FILE_UPLOAD_REQUEST]: (store, params = {}) => {
      const formData = new FormData();
      Object.keys(params).map((key) => {
        formData.append(key, params[key]);
      });
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            data: {
              id: 24,
              name: params.file.name,
              size: 12345,
              status: "success",
            },
          });
          // reject({
          //     params,
          //     id:params.id,
          // })
        }, 2000);
      });

      // return $api.core.upload_file(formData)
    },
    [OPEN_ALERT]: async (
      { commit },
      { text, variant = "success", duration = 3000 }
    ) => {
      commit(SET_ALERT, { open: true, text, variant, duration });
      setTimeout(() => {
        commit(SET_ALERT, {
          open: false,
          text: "",
          variant: "success",
          duration,
        });
      }, duration);
    },
    [CLOSE_ALERT]: async ({ commit }) => {
      commit(SET_ALERT, {
        open: false,
        text: "",
        variant: "success",
        duration: 3000,
      });
    },
    [ADD_NEWS_CONTENT]: async ({ state, commit }, block) => {
      commit(SET_NEWS_CONTENT, [...state, block]);
    },
    [REMOVE_NEWS_CONTENT]: async ({ state, commit }, block_key) => {
      commit(
        SET_NEWS_CONTENT,
        state.news.content.filter((newsItem) => newsItem._key !== block_key)
      );
    },
  };
  const mutations = {
    [SET_LOADING]: (store, payload) => {
      store.is_loading = payload;
    },
    [SET_SEARCH_REQUEST_RESULT]: (store, payload) => {
      store.search.courses = payload;
    },
    [SET_ALERT]: (store, payload) => {
      store.alert = payload;
    },
    [SET_NEWS_CONTENT]: (store, payload) => {
      store.news.content = payload;
    },
  };
  return {
    actions,
    state,
    mutations,
  };
};
export default (app) => {
  return createStore(app);
};
