<template>
  <div
    :class="{
      'sidebar-open': isOpen,
      'sidebar-closed': !isOpen,
    }"
    @click="closeSidebar"
  >
    <div class="sidebar-content flex justify-center">
      <div class="flex flex-col gap-6 justify-center items-center sidebar-menu">
        <div class="sidebar-title">Меню</div>
        <router-link :to="{ name: 'UserProfile' }" v-if="isAuthenticated">
          <div
            :class="
              currentRoute === 'UserProfile'
                ? 'current-route'
                : 'not-current-route'
            "
          >
            {{ $t("profile") }}
          </div>
        </router-link>
        <router-link :to="{ name: 'AllCourses' }">
          <div
            :class="
              currentRoute === 'AllCourses'
                ? 'current-route'
                : 'not-current-route'
            "
          >
            {{ $t("courses") }}
          </div>
        </router-link>
        <router-link :to="{ name: 'Services' }">
          <div
            :class="
              currentRoute === 'Services'
                ? 'current-route'
                : 'not-current-route'
            "
          >
            {{ $t("services") }}
          </div>
        </router-link>
        <router-link :to="{ name: 'About Us' }">
          <div
            :class="
              currentRoute === 'About Us'
                ? 'current-route'
                : 'not-current-route'
            "
          >
            {{ $t("about_us") }}
          </div>
        </router-link>
        <router-link :to="{ name: 'Tariffs' }">
          <div
            :class="
              currentRoute === 'Tariffs' ? 'current-route' : 'not-current-route'
            "
          >
            {{ $t("tariffs") }}
          </div>
        </router-link>
        <router-link :to="{ name: 'Course Catalog' }">
          <div
            :class="
              currentRoute === 'Course Catalog'
                ? 'current-route'
                : 'not-current-route'
            "
          >
            {{ $t("contacts_low") }}
          </div>
        </router-link>

        <router-link v-if="!isAuthenticated" :to="{ name: 'Login' }">
          <TheButton
            bg="primary"
            style="
              border-radius: 25px;
              padding: 12px 40px;

              font-size: 18px;
            "
          >
            <div class="flex flex-row items-center min-w-28 justify-around">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                class="mr-2"
              >
                <path
                  d="M7.58317 4.41667C7.50597 4.49294 7.44468 4.58377 7.40284 4.6839C7.36101 4.78404 7.33947 4.89148 7.33947 5C7.33947 5.10852 7.36101 5.21596 7.40284 5.3161C7.44468 5.41623 7.50597 5.50706 7.58317 5.58333L9.1665 7.16667H1.49984C1.0415 7.16667 0.666504 7.54167 0.666504 8C0.666504 8.45833 1.0415 8.83333 1.49984 8.83333H9.1665L7.58317 10.4167C7.50597 10.4929 7.44468 10.5838 7.40284 10.6839C7.36101 10.784 7.33947 10.8915 7.33947 11C7.33947 11.1085 7.36101 11.216 7.40284 11.3161C7.44468 11.4162 7.50597 11.5071 7.58317 11.5833C7.90817 11.9083 8.42484 11.9083 8.74984 11.5833L11.7415 8.59167C11.8188 8.51457 11.88 8.423 11.9219 8.32219C11.9637 8.22138 11.9852 8.11331 11.9852 8.00417C11.9852 7.89503 11.9637 7.78696 11.9219 7.68615C11.88 7.58534 11.8188 7.49376 11.7415 7.41667L8.74984 4.41667C8.67357 4.33947 8.58273 4.27817 8.4826 4.23634C8.38247 4.19451 8.27502 4.17297 8.1665 4.17297C8.05798 4.17297 7.95054 4.19451 7.85041 4.23634C7.75027 4.27817 7.65944 4.33947 7.58317 4.41667ZM15.6665 13.8333H9.83317C9.37484 13.8333 8.99984 14.2083 8.99984 14.6667C8.99984 15.125 9.37484 15.5 9.83317 15.5H15.6665C16.5832 15.5 17.3332 14.75 17.3332 13.8333V2.16667C17.3332 1.25 16.5832 0.5 15.6665 0.5H9.83317C9.37484 0.5 8.99984 0.875 8.99984 1.33333C8.99984 1.79167 9.37484 2.16667 9.83317 2.16667H15.6665V13.8333Z"
                  fill="white"
                />
              </svg>
              {{ $t("enter") }}
            </div>
          </TheButton>
        </router-link>
        <router-link v-else :to="{ name: 'Login' }" @click="logout">
          {{ $t("logout") }}
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import TheLandingSidebarMixin from "@/components/TheSidebar/TheLandingSidebarMixin";
import TheButton from "@/components/Button/Button.vue";
import ThePageHeaderMixin from "@/components/ThePageHeader/ThePageHeaderMixin";

export default {
  components: { TheButton },
  mixins: [TheLandingSidebarMixin, ThePageHeaderMixin],
  props: ["landingSidebar", "closeSidebar"],
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    isOpen() {
      return this.landingSidebar;
    },
  },
  watch: {
    isOpen(newVal) {
      console.log("landing_sidebar_open:", newVal);
    },
    landingSidebar(newVal) {
      console.log("landing_sidebar_open:", newVal);
    },
  },
};
</script>

<style scoped>
.sidebar-content {
  position: fixed;
  top: 82px;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: white;
  transition: left 0.3s ease;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.sidebar-open .sidebar-content {
  left: 0;
}

.sidebar-closed {
  left: -100%;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  z-index: 1000; /* Ensure overlay is above other content */
  display: none;
}

.sidebar-open .overlay {
  display: block;
}
.current-route {
  color: rgb(79 70 229);
}
.not-current-route {
  color: rgb(31 41 55);
}

.current-route,
.not-current-route {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
svg {
  width: 24px;
  height: 24px;
}
.sidebar-title {
  font-size: 32px;
  font-weight: 800;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
  color: #1f2937;
}
.sidebar-menu {
  transform: translateY(-10%);
}
</style>
