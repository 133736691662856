//todo logic for store work
import { SET_LOADING } from "@/store/core";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const HANDLE_LOGIN_REQUEST_SUCCESS = "HANDLE_LOGIN_REQUEST_SUCCESS";
export const HANDLE_LOGIN_REQUEST_FAIL = "HANDLE_LOGIN_REQUEST_FAIL";
export const REGISTER_REQUEST = "REGISTER_REQUEST";
export const HANDLE_REGISTER_REQUEST_SUCCESS =
  "HANDLE_REGISTER_REQUEST_SUCCESS";
export const HANDLE_REGISTER_REQUEST_FAIL = "HANDLE_REGISTER_REQUEST_FAIL";
export const SET_REGISTRATION_STEP = "SET_REGISTRATION_STEP";
export const REGISTER_FULL_INFO_REQUEST = "REGISTER_FULL_INFO_REQUEST";
export const HANDLE_REGISTER_FULL_INFO_REQUEST_SUCCESS =
  "HANDLE_REGISTER_FULL_INFO_REQUEST_SUCCESS";
export const HANDLE_REGISTER_FULL_INFO_REQUEST_FAIL =
  "HANDLE_REGISTER_FULL_INFO_REQUEST_FAIL";
export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const HANDLE_FORGOT_PASSWORD_REQUEST_SUCCESS =
  "HANDLE_FORGOT_PASSWORD_REQUEST_SUCCESS";
export const HANDLE_FORGOT_PASSWORD_REQUEST_FAIL =
  "HANDLE_FORGOT_PASSWORD_REQUEST_FAIL";
export const SET_FORGOT_PASSWORD_STEP = "SET_FORGOT_PASSWORD_STEP";
export const SET_ACTIVE_USER = "SET_ACTIVE_USER";
export const EDIT_ACTIVE_USER = "EDIT_ACTIVE_USER";
export const EDIT_ACTIVE_USER_AVATAR = "EDIT_ACTIVE_USER_AVATAR";
export const GET_ACTIVE_USER = "GET_ACTIVE_USER";
export const SET_ACTIVE_USER_PERMISSIONS = "SET_ACTIVE_USER_PERMISSIONS";
export const REGISTRATION_STEP_INPUT_EMAIL = 1;
export const REGISTRATION_STEP_EMAIL_SENT = 2;
export const REGISTRATION_STEP_INPUT_FULL_INFO = 3;
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const UPDATE_PASSWORD = "UPDATE_PASSWORD";

export const FORGOT_PASSWORD_INPUT_EMAIL = 1;
export const FORGOT_PASSWORD_EMAIL_SENT = 2;
export const FORGOT_PASSWORD_INPUT_PASSWORD = 3;

const createStore = (app) => {
  const { $api, $axios } = app.config.globalProperties;
  const state = {
    active_user: {},
    registration_form: {
      email: "",
      password: "",
      password_confirmation: "",
      first_name: "",
      last_name: "",
      phone_number: "+7",
      step: REGISTRATION_STEP_INPUT_EMAIL,
      token: "",
      errors: {},
    },
    active_user_permissions: [],
    login_form: {
      email: "",
      password: "",
    },
    forgot_password_form: {
      email: "",
      step: FORGOT_PASSWORD_INPUT_EMAIL,
      password: "",
      password_confirmation: "",
      token: "",
    },
  };
  const actions = {
    [LOGIN_REQUEST]: async ({ commit, dispatch }, { email, password }) => {
      commit(SET_LOADING, true);
      try {
        const { data } = await $api.auth.login(email, password);
        commit(HANDLE_LOGIN_REQUEST_SUCCESS, {
          token: data.access,
          refresh: data.refresh,
        });
        dispatch(GET_ACTIVE_USER);
        commit(SET_LOADING, false);
      } catch (e) {
        commit(HANDLE_LOGIN_REQUEST_FAIL, e);
        commit(SET_LOADING, false);
        const error = e.response.data.detail;
        throw error;
      }
    },
    [GET_ACTIVE_USER]: async (store) => {
      store.commit(SET_LOADING, true);
      const { data } = await $api.auth.get_current_user();
      if (!state.active_user_permissions || !state.active_user_permissions[0]) {
        const permissions = data.groups.reduce((acc, cur) => {
          const group_permissions = {};
          cur.permissions.forEach(
            (permission) => (group_permissions[permission.codename] = true)
          );
          return { ...acc, ...group_permissions };
        }, {});
        store.commit(SET_ACTIVE_USER_PERMISSIONS, permissions);
      }
      store.commit(SET_ACTIVE_USER, data);
      store.commit(SET_LOADING, false);
    },
    [EDIT_ACTIVE_USER]: async ({ state, commit }, user) => {
      const { first_name, last_name, email, phone_number } = user;
      const formData = new FormData();
      formData.append("first_name", first_name);
      formData.append("last_name", last_name);
      formData.append("email", email);
      formData.append("phone_number", phone_number);

      commit(SET_LOADING, true);
      return $api.users
        .update_user_profile(formData)
        .then(async ({ data }) => {
          const user = JSON.parse(await data.text());
          commit(SET_ACTIVE_USER, { ...state.active_user, ...user });
        })
        .catch((err) => console.log(err))
        .finally(() => commit(SET_LOADING, false));
    },
    [EDIT_ACTIVE_USER_AVATAR]: async ({ state, commit }, user) => {
      const { avatar, email } = user;
      const formData = new FormData();
      formData.append("avatar", avatar);
      formData.append("email", email);

      commit(SET_LOADING, true);
      return $api.users
        .update_user_profile(formData)
        .then(async ({ data }) => {
          const { avatar } = JSON.parse(await data.text());
          commit(SET_ACTIVE_USER, { ...state.active_user, avatar });
        })
        .catch((err) => console.log(err))
        .finally(() => commit(SET_LOADING, false));
    },
    [UPDATE_PASSWORD]: async ({ commit }, data) => {
      commit(SET_LOADING, true);
      await $api.users
        .update_password(data)
        .finally(() => commit(SET_LOADING, false));
    },
    [REGISTER_REQUEST]: async ({ commit }, { email }) => {
      commit(SET_LOADING, true);
      try {
        const { data } = await $api.auth.register({ email });
        commit(HANDLE_REGISTER_REQUEST_SUCCESS, data);
      } catch (e) {
        commit(HANDLE_REGISTER_REQUEST_FAIL, e.response.data);
      }
      commit(SET_LOADING, false);
    },
    [REGISTER_FULL_INFO_REQUEST]: async (
      { commit },
      { password, password_confirmation, ...rest }
    ) => {
      commit(SET_LOADING, true);
      if (password_confirmation != password) {
        commit(HANDLE_REGISTER_FULL_INFO_REQUEST_FAIL, {
          message: "password mismatch",
        });
        return commit(SET_LOADING, false);
      }
      try {
        const { data } = await $api.auth.register_full_info({
          password,
          password_confirmation,
          ...rest,
        });
        commit(HANDLE_REGISTER_FULL_INFO_REQUEST_SUCCESS, data);
      } catch (e) {
        console.log(e);
        commit(HANDLE_REGISTER_FULL_INFO_REQUEST_FAIL, {
          message: "custom error todo",
        });
      }
      commit(SET_LOADING, false);
    },
    [FORGOT_PASSWORD_REQUEST]: async ({ commit }, { email }) => {
      commit(SET_LOADING, true);
      try {
        const { data } = await $api.auth.forgotPassword(email);
        commit(HANDLE_FORGOT_PASSWORD_REQUEST_SUCCESS, data);
      } catch (e) {
        commit(HANDLE_FORGOT_PASSWORD_REQUEST_FAIL, e);
      }
      commit(SET_FORGOT_PASSWORD_STEP, FORGOT_PASSWORD_EMAIL_SENT);
      commit(SET_LOADING, false);
    },
    [LOGOUT_REQUEST]: async ({ commit }) => {
      // await $api.auth.logout() //todo uncomment it
      commit(SET_ACTIVE_USER, {});
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
    },
  };
  const mutations = {
    [SET_ACTIVE_USER]: (store, payload) => {
      store.active_user = payload;
    },
    [HANDLE_LOGIN_REQUEST_SUCCESS]: (store, { token, refresh }) => {
      $axios.addToken(token);
      localStorage.setItem("token", token);
      localStorage.setItem("refresh", refresh);
    },
    [HANDLE_LOGIN_REQUEST_FAIL]: () => {
      localStorage.removeItem("token");
    },
    [HANDLE_REGISTER_REQUEST_SUCCESS]: (store, payload) => {
      store.registration_form.step = REGISTRATION_STEP_EMAIL_SENT;
      console.log(payload);
    },
    [HANDLE_REGISTER_REQUEST_FAIL]: (store, errors) => {
      // store.registration_form.step = REGISTRATION_STEP_EMAIL_SENT
      store.registration_form.errors = errors;
    },
    [HANDLE_REGISTER_FULL_INFO_REQUEST_FAIL]: (store, error) => {
      console.log(error);
    },
    [HANDLE_FORGOT_PASSWORD_REQUEST_SUCCESS]: (store, data) => {
      console.log(data);
    },
    [HANDLE_FORGOT_PASSWORD_REQUEST_FAIL]: (store, data) => {
      console.log(data);
    },
    [SET_REGISTRATION_STEP]: (store, payload) => {
      store.registration_form.step = payload;
    },
    [SET_FORGOT_PASSWORD_STEP]: (store, payload) => {
      store.forgot_password_form.step = payload;
    },
    [SET_ACTIVE_USER_PERMISSIONS]: (store, payload) => {
      store.active_user_permissions = payload;
    },
  };
  const getters = {
    is_authenticated: (store) => store.active_user && store.active_user.email,
    active_user_has_permission: (store) => (permission) =>
      !!store.active_user_permissions[permission],
    user_groups: (store) => {
      if (store.active_user) return store.active_user.groups;
    },
  };
  return {
    actions,
    state,
    mutations,
    getters,
  };
};
export default (app) => createStore(app);
