<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" rx="16" fill="#1C4FDF" />
    <path
      d="M26.434 29.356C26.434 28.6 26.6487 28.0167 27.078 27.606C27.5167 27.1953 28.0813 26.99 28.772 26.99C29.4627 26.99 30.0227 27.1953 30.452 27.606C30.8813 28.0167 31.096 28.6 31.096 29.356C31.096 30.112 30.8813 30.6953 30.452 31.106C30.0227 31.5167 29.4627 31.722 28.772 31.722C28.0813 31.722 27.5167 31.5167 27.078 31.106C26.6487 30.6953 26.434 30.112 26.434 29.356ZM35.94 27.144L30.55 37H28.24L33.63 27.144H35.94ZM28.758 28.39C28.31 28.39 28.086 28.712 28.086 29.356C28.086 29.9907 28.31 30.308 28.758 30.308C28.9727 30.308 29.1407 30.2287 29.262 30.07C29.3833 29.9113 29.444 29.6733 29.444 29.356C29.444 28.712 29.2153 28.39 28.758 28.39ZM33.098 34.788C33.098 34.032 33.3127 33.4487 33.742 33.038C34.1713 32.6273 34.7313 32.422 35.422 32.422C36.1127 32.422 36.6727 32.6273 37.102 33.038C37.5313 33.4487 37.746 34.032 37.746 34.788C37.746 35.544 37.5313 36.1273 37.102 36.538C36.6727 36.9487 36.1127 37.154 35.422 37.154C34.7313 37.154 34.1713 36.9487 33.742 36.538C33.3127 36.1273 33.098 35.544 33.098 34.788ZM35.408 33.822C35.1933 33.822 35.0253 33.9013 34.904 34.06C34.792 34.2187 34.736 34.4613 34.736 34.788C34.736 35.4227 34.96 35.74 35.408 35.74C35.6227 35.74 35.7907 35.6607 35.912 35.502C36.0333 35.3433 36.094 35.1053 36.094 34.788C36.094 34.4707 36.0333 34.2327 35.912 34.074C35.7907 33.906 35.6227 33.822 35.408 33.822Z"
      fill="white"
    />
    <path
      d="M32.1696 16.4746C40.8372 16.4746 47.8636 23.5011 47.8636 32.1686C47.8636 40.8363 40.8372 47.8626 32.1696 47.8626C23.502 47.8626 16.4756 40.8363 16.4756 32.1686C16.4756 23.5011 23.502 16.4746 32.1696 16.4746Z"
      stroke="white"
      stroke-opacity="0.3"
      stroke-width="2.87733"
      stroke-linecap="round"
    />
    <path
      d="M32.1709 16.4746C40.8385 16.4746 47.8649 23.5011 47.8649 32.1686"
      stroke="white"
      stroke-width="2.87733"
      stroke-linecap="round"
    />
  </svg>
</template>
<script>
import IconMixin from "@/assets/icons/IconMixin";

export default {
  mixins: [IconMixin],
};
</script>
