<template>
  <div class="flex flex-col gap-6">
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Полное наименование организации
      </h3>
      <p class="text-sm leading-5 font-normal text-gray-900">
        Общество с ограниченной ответственностью «EdCrunch Academy»
      </p>
    </div>
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Сокращенное наименование организации
      </h3>
      <p class="text-sm leading-5 font-normal text-gray-900">
        ООО «EdCrunch Academy»
      </p>
    </div>
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Наименование на английском языке
      </h3>
      <p class="text-sm leading-5 font-normal text-gray-900">
        LLC «EdCrunch Academy»
      </p>
    </div>
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Дата создания организации
      </h3>
      <p class="text-sm leading-5 font-normal text-gray-900">01.01.2020</p>
    </div>
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Учредитель организации
      </h3>
      <p class="text-sm leading-5 font-normal text-gray-900">
        Общество с ограниченной ответственностью «EdCrunch Academy»
      </p>
    </div>
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Наименование представительств и филиалов образовательной организации
      </h3>
      <p class="text-sm leading-5 font-normal text-gray-900">
        Представительства и филиалы отсутствуют
      </p>
    </div>
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Место нахождения организации
      </h3>
      <p class="text-sm leading-5 font-normal text-gray-900">
        г. Алматы, ул. Байзакова, здание 280, н.п.3 050040
      </p>
    </div>
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Режим и график работы организации
      </h3>
      <p class="text-sm leading-5 font-normal text-gray-900">
        С понедельника по пятницу с 10.00 до 19.00
      </p>
    </div>
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Контактные телефоны организации
      </h3>
      <p class="text-sm leading-5 font-normal text-gray-900">
        +7 (701) 345 13 21
      </p>
    </div>
    <div class="flex flex-col gap-1">
      <h3 class="text-sm leading-5 font-medium text-gray-500">
        Адрес электронной почты организации
      </h3>
      <p class="text-sm leading-5 font-normal text-indigo-600">
        <a href="mailto:helpdesk@edca.kz">helpdesk@edca.kz</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>
