<script setup></script>

<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.83398 3.57617H22.1673C23.0956 3.57617 23.9858 3.94492 24.6422 4.6013C25.2986 5.25768 25.6673 6.14791 25.6673 7.07617V17.5762C25.6673 18.5044 25.2986 19.3947 24.6422 20.051C23.9858 20.7074 23.0956 21.0762 22.1673 21.0762H5.83398C4.90573 21.0762 4.01549 20.7074 3.35911 20.051C2.70273 19.3947 2.33398 18.5044 2.33398 17.5762L2.33398 7.07617C2.33398 6.14791 2.70273 5.25768 3.35911 4.6013C4.01549 3.94492 4.90573 3.57617 5.83398 3.57617ZM10.5007 22.2428H17.5007C17.8101 22.2428 18.1068 22.3658 18.3256 22.5845C18.5444 22.8033 18.6673 23.1001 18.6673 23.4095C18.6673 23.7189 18.5444 24.0157 18.3256 24.2345C18.1068 24.4533 17.8101 24.5762 17.5007 24.5762H10.5007C10.1912 24.5762 9.89449 24.4533 9.67569 24.2345C9.4569 24.0157 9.33398 23.7189 9.33398 23.4095C9.33398 23.1001 9.4569 22.8033 9.67569 22.5845C9.89449 22.3658 10.1912 22.2428 10.5007 22.2428Z"
      fill="#C8AEFF"
    />
  </svg>
</template>

<style scoped></style>
