const baseUrl = "/api/v1/course_task_categories/course";
export default ($axios) => ({
  get_course_task_categories(params) {
    return $axios.get(`${baseUrl}/${params.course}/task_categories/`);
  },
  create_course_task_categories(params) {
    return $axios.post(
      `${baseUrl}/${params.course}/task_categories/create/`,
      params
    );
  },
  create_bulk_course_task_categories(params) {
    return $axios.post(
      `${baseUrl}/${params.course}/task_categories/bulk_create/`,
      params.data
    );
  },
  delete_course_task_category(params) {
    return $axios.delete(
      `${baseUrl}/${params.course}/task_category/${params.task}/`
    );
  },
});
