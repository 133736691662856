<template>
  <ContentBlockForm :content_name="$t('image_question_label')" :_key="_key" :attachments="attachments">
    <TheQuillEditorInput v-model="content" @change="emitUpdate"/>
    <div>
      <div class="my-2">
        <label >
          <input type="checkbox" v-model="multiple_answers_available" @change="emitUpdate"
          >
          {{$t('multiple_answer_available')}}
        </label>
      </div>
      <div
          v-for="question_answer in available_answers"
          :key="`answer_${question_answer.id ||question_answer._key}`"
          class="cursor-pointer w-full bg-gray-50 p-2 inline-flex mb-2 rounded-l place-items-center"
      >
        <input :type="multiple_answers_available?'checkbox':'radio'"
               v-model="correct_answer"
               :value="question_answer.id ||question_answer._key"
               class="mr-2"
        />
        <FileDropzone
            class="max-w-[220px] "
            v-model="question_answer.image"
            additionalClass="mt-0"
            @change="emitUpdate"
        />
        <TrashIcon v-if="available_answers.length > 2" class="h-5 cursor-pointer text-gray-700" @click="handleRemoveAnswerButtonClicked(question_answer.id, question_answer._key)"/>
      </div>
    </div>
    <div class="inline-flex mt-3">
      <TheButton bg="secondary" @click="addAvailableAnswer">
        <PlusIcon class="h-5 mr-2"/>
        {{ $t('add_answer_option') }}
      </TheButton>
    </div>
  </ContentBlockForm>
</template>
<script>
  import ContentBlockForm from "@/views/resources/Form/components/ContentBlockForm";
  import TheQuillEditorInput from "@/components/TheQuillEditorInput";
  import QuestionMixin from "@/views/resources/Form/components/QuestionMixin";
  import FileDropzone from "@/components/FileDropzone";
  import TheButton from "@/components/Button/Button";
  import {PlusIcon, TrashIcon} from "@heroicons/vue/solid";

  export default {
    components: {TheButton, FileDropzone, TheQuillEditorInput, ContentBlockForm,PlusIcon, TrashIcon},
    data:()=>({
      available_answers:[],
      content:{},
      multiple_answers_available:false,
      correct_answer:''
    }),
    mixins:[QuestionMixin],
    methods:{
      generateNewAvailableAnswer(){
        return {
          image:"",
          _key:this.generateRandomKey(),
        }
      },
      addAvailableAnswer(){
        this.available_answers.push(this.generateNewAvailableAnswer())
      },
      emitUpdate(){
        this.$emit('contentChange',{
          content:this.content,
          available_answers:this.available_answers,
          multiple_answers_available:this.multiple_answers_available
        })
      }
    },
    watch:{
      multiple_answers_available(newVal){
        this.correct_answer = newVal?[]:''
        this.emitUpdate()
      }
    },
    beforeUpdate() {
      if(this.available_answers){
        if(this.available_answers.find(el => el.is_selected)){
          this.correct_answer = this.available_answers.find(el => el.is_selected).id || this.available_answers.find(el => el.is_selected)._key
        }
        this.emitUpdate()
      }
      while(this.available_answers.length<4){
        this.addAvailableAnswer()
      }
    },
    created(){
      if(this.available_answers){
        if(this.available_answers.find(el => el.is_selected)){
          this.correct_answer = this.available_answers.find(el => el.is_selected).id || this.available_answers.find(el => el.is_selected)._key
        }
        this.emitUpdate()
      }
      while(this.available_answers.length<4){
        this.addAvailableAnswer()
      }
    }
  }
</script>