<template>
  <div class="  w-full">
    <audio controls :src="localContent" class="w-full mb-6 " controlsList="nodownload">
      Your browser does not support the
      <code>audio</code> element.
    </audio>
    <AttachmentsBlock :attachments="attachments"/>

  </div>
</template>
<script>
import AttachmentsBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
export default {
  components: {AttachmentsBlock},
  props:{
    content:{
      type:String,
      required:true
    },
    attachments:{
      type:Array,
      default:()=>[]
    }
  },
  computed:{
    localContent(){
      if (typeof this.content === 'string' || this.content instanceof String){
        return this.content
      }
      return URL.createObjectURL(this.content)
    }
  }
}
</script>