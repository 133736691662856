import {mapActions} from "vuex";
import {FINISH_RESOURCE} from "@/store/resources";
import {mapFields} from "vuex-map-fields";
export default {
    computed:{
        ...mapFields(['resources.resource'])
    },
    methods:{
        ...mapActions([FINISH_RESOURCE]),
        async finishQuiz(){
            const {id} = this.resource
            try{
                //todo remove try catch
                this.setModalState(false)
                await this.FINISH_RESOURCE(id)
                this.$emit('submitted')
            }catch (e) {
                this.show_finish_quiz_confirmation_modal = false;
                this.show_quiz_timeout_modal = false;
                // this.$router.replace({name:"CourseOverview",params:{id:course_id}})
            }
        }
    }
}