<template>
  <div class="w-full">
    <TheForgotPasswordForm v-if="step===1"/>
    <TheEmailSentNotification v-else-if="step===2"/>
    <TheSetNewPasswordForm v-else-if="step===3"/>
  </div>
</template>
<script>
import TheForgotPasswordForm from "./components/TheForgotPasswordForm/TheForgotPasswordForm";
import ForgotPasswordMixin from "@/views/auth/ForgotPassword/ForgotPasswordMixin";
import TheEmailSentNotification
  from "@/views/auth/ForgotPassword/components/TheEmailSentNotification/TheEmailSentNotification";
import TheSetNewPasswordForm from "@/views/auth/ForgotPassword/components/TheSetNewPasswordForm/TheSetNewPasswordForm";
export default {
  components: {TheSetNewPasswordForm, TheEmailSentNotification, TheForgotPasswordForm},
  mixins:[ForgotPasswordMixin]
}
</script>
