<template>
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 14.6641H13.3333V16.9974H18V19.3307L21.5 15.8307L18 12.3307V14.6641ZM9.83333 0.664062C7.26667 0.664062 5.16667 2.76406 5.16667 5.33073C5.16667 7.8974 7.26667 9.9974 9.83333 9.9974C12.4 9.9974 14.5 7.8974 14.5 5.33073C14.5 2.76406 12.4 0.664062 9.83333 0.664062ZM9.83333 12.3307C4.7 12.3307 0.5 14.4307 0.5 16.9974V19.3307H11.5833C11.2333 18.3974 11 17.4641 11 16.4141C11 15.0141 11.35 13.7307 12.05 12.4474C11.35 12.4474 10.65 12.3307 9.83333 12.3307Z"
      fill="#40D39F"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
