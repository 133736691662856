<script>
import { defineComponent } from "vue";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";
import StudentWorkItem from "@/views/core/Landing/Courses/StudentWorks/StudentWorkItem.vue";

export default defineComponent({
  name: "AllStudentWorks",
  components: { StudentWorkItem },
  mixins: [AllCoursesMixin],
  data() {
    return {
      show: false,
    };
  },
});
</script>

<template>
  <div class="student-works__main">
    <div class="flex flex-col gap-8">
      <h1>Работы наших учеников</h1>

      <div class="adaptive-courses">
        <span
          v-for="(course, index) in landingOptions"
          :key="index"
          class="courses"
        >
          <span
            class="course-chosen"
            v-if="courseType.includes(course)"
            @click="chooseCourse(course)"
            >{{ course["value"] }}</span
          >
          <span class="course" v-else @click="chooseCourse(course)">{{
            course["value"]
          }}</span>
        </span>
      </div>
      <div class="adaptive-courses2 relative">
        <div class="relative w-full flex-1">
          <button
            @click="show = !show"
            class="w-full flex items-center p-4 justify-between rounded-xl border border-indigo-500 bg-white"
          >
            <span class="mr-4 text-base">{{
              courseType.length == 0 ? "Все направления" : courseType[0].label
            }}</span>
            <svg
              class="w-5 h-5 text-indigo-100 dark:text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              v-if="!show"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
                fill="#111827"
              />
            </svg>
            <svg
              v-if="show"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.7071 12.7071C14.3166 13.0976 13.6834 13.0976 13.2929 12.7071L10 9.41421L6.70712 12.7071C6.3166 13.0976 5.68343 13.0976 5.29291 12.7071C4.90238 12.3166 4.90238 11.6834 5.29291 11.2929L9.2929 7.29289C9.68343 6.90237 10.3166 6.90237 10.7071 7.29289L14.7071 11.2929C15.0976 11.6834 15.0976 12.3166 14.7071 12.7071Z"
                fill="#111827"
              />
            </svg>
          </button>

          <div
            v-show="show"
            class="absolute right-0 py-2 mt-2 rounded-xl shadow-xl w-full z-10 border border-indigo-500 bg-white"
          >
            <div
              v-for="(course, index) in landingOptionsMob"
              :key="index"
              class="block px-4 py-2 text-base"
            >
              <div
                v-if="courseType.includes(course)"
                @click="chooseCourseMob(course)"
                class="flex justify-between items-center"
              >
                <div class="text-base text-indigo-500">
                  {{ course.label }}
                </div>
                <div class="w-5 h-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M8.33366 11.3333L13.2503 6.41667C13.4031 6.26389 13.5975 6.1875 13.8337 6.1875C14.0698 6.1875 14.2642 6.26389 14.417 6.41667C14.5698 6.56944 14.6462 6.76389 14.6462 7C14.6462 7.23611 14.5698 7.43056 14.417 7.58333L8.91699 13.0833C8.75033 13.25 8.55588 13.3333 8.33366 13.3333C8.11144 13.3333 7.91699 13.25 7.75033 13.0833L5.58366 10.9167C5.43088 10.7639 5.35449 10.5694 5.35449 10.3333C5.35449 10.0972 5.43088 9.90278 5.58366 9.75C5.73644 9.59722 5.93088 9.52083 6.16699 9.52083C6.4031 9.52083 6.59755 9.59722 6.75033 9.75L8.33366 11.3333Z"
                      fill="#786CE6"
                    />
                  </svg>
                </div>
              </div>

              <div
                class="course-dropdown"
                v-else
                @click="chooseCourseMob(course)"
              >
                {{ course.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="student-works__grid grid grid-cols-4 gap-6">
      <StudentWorkItem />
      <StudentWorkItem /> <StudentWorkItem /> <StudentWorkItem />
      <StudentWorkItem /> <StudentWorkItem /> <StudentWorkItem />
    </div>
  </div>
</template>

<style scoped>
.student-works__main {
  padding: 40px 100px;
  background: #f4f5fa;
  display: flex;
  flex-direction: column;
  gap: 80px;
}
h1 {
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  text-align: left;
  color: #1f2937;
}

.courses {
  display: inline-flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
}

.course-chosen {
  font-size: 20px;
  font-weight: 500;
  padding: 6px 16px;
  color: white;
  background: #786ce6;
  border-radius: 24px;
  cursor: pointer;
  margin-right: 16px;
  border: 1px solid #ffffff;
}

.course {
  padding: 6px 16px;
  background: #ffffff;
  border: 1.5px solid rgba(120, 108, 230, 0.5);
  border-radius: 24px;
  cursor: pointer;
  margin-right: 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}

.adaptive-courses2 {
  display: none;
}
@media (max-width: 650px) {
  .student-works__main {
    padding: 32px 16px;
    gap: 32px;
  }
  h1 {
    font-size: 28px;
    font-weight: 700;
  }
  .adaptive-courses {
    display: none;
  }
  .student-works__grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .adaptive-courses2 {
    display: flex;
  }
}
</style>
