export default [
  {
    name: "CoursesLayout",
    path: "/courses",
    component: async () => require("../layouts/CabinetLayout"),
    children: [
      {
        name: "WaitingForStart",
        path: "waiting-for-start",
        component: async () =>
          require("../views/courses/WaitingForStart/WaitingForStart"),
      },
      {
        name: "FinishedCourses",
        path: "finished",
        component: async () =>
          require("../views/courses/FinishedCourses/FinishedCourses"),
      },
      {
        name: "CoursesList",
        path: "list",
        component: async () => require("../views/courses/List/List"),
      },
      {
        name: "MyCourses",
        path: "my-courses",
        component: async () => require("../views/courses/MyCourses/MyCourses"),
      },
      {
        name: "CurrentCourses",
        path: "current",
        component: async () =>
          require("../views/courses/CurrentCourses/CurrentCourses"),
      },
      {
        name: "CourseUsers",
        path: ":id/users",
        props: true,
        component: async () =>
          require("../views/users/CourseUsers/CourseUsers"),
      },
      {
        name: "CourseForm",
        path: "form",
        props: true,
        component: async () => require("../views/courses/Form/CoursesForm"),
      },
      {
        name: "CourseFormCreate",
        path: "/form",
        props: true,
        component: async () => require("../views/courses/Form/CoursesForm"),
      },
      {
        name: "CourseEdit",
        path: ":id/edit",
        proprs: true,
        component: async () =>
          require("../views/courses/CourseEdit/CourseEdit"),
      },
      {
        name: "GroupsList",
        path: ":id/groups",
        proprs: true,
        component: async () =>
          require("../views/courses/CourseEdit/Groups/GroupsList"),
      },
      {
        name: "AttendanceList",
        path: ":id/attendance",
        props: true,
        component: async () =>
          require("../views/courses/CourseEdit/Attendance/AttendanceList.vue"),
      },
      {
        name: "GroupView",
        path: ":id/groups/view",
        proprs: true,
        component: async () =>
          require("../views/courses/CourseEdit/Groups/GroupView"),
      },
      {
        name: "GroupForm",
        path: ":id/groups/form",
        proprs: true,
        component: async () =>
          require("../views/courses/CourseEdit/Groups/GroupForm"),
      },
      {
        name: "Roles",
        path: "roles",
        component: async () => require("../views/users/Roles/TheRolesPage.vue"),
      },
      {
        name: "Statistics",
        path: ":id/statistics",
        component: async () =>
          require("../views/courses/CourseEdit/Statistics/StatisticsView.vue"),
      },
      {
        name: "CoursePublish",
        path: "publish",
        component: async () =>
          require("../views/courses/CoursePublish/CoursePublish.vue"),
      },
      {
        name: "TaskPercentage",
        path: ":id/task-percentage",
        props: true,
        component: async () =>
          require("../views/courses/TaskPercentage/TaskPercentage.vue"),
      },
      {
        name: "StudentGrades",
        path: ":id/student-grades",
        props: true,
        component: async () =>
          require("../views/courses/StudentGrades/StudentGrades.vue"),
      },
    ],
  },
  {
    name: "CourseLayout",
    path: "/courses/:id",
    component: async () => require("../layouts/CourseStudentLayout"),
    children: [
      {
        name: "CourseOverview",
        path: "overview",
        props: true,
        component: async () =>
          require("../views/courses/TheOverviewPage/TheOverviewPage"),
      },
      {
        name: "CourseContent",
        path: "content",
        props: true,
        component: async () =>
          require("../views/courses/CourseResources/CourseResources"),
      },
      {
        name: "MyAttendance",
        path: "my-attendance",
        props: true,
        component: async () =>
          require("@/views/courses/MyAttendance/MyAttendance.vue"),
      },
      {
        name: "MyGrades",
        path: "my-grades",
        props: true,
        component: async () => require("@/views/courses/MyGrades/MyGrades.vue"),
      },
    ],
  },
];
