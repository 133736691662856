<template>
  <div class="flex gap-1 items-center calendar__div">
    <div class="flex-1 relative">
      <flat-pickr
        v-model="internalValue"
        :config="config"
        class="form-control flatpickr-input"
        placeholder="Select date"
        name="date"
      />

      <div class="icon__calendar">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.83333 5.83333C5.83333 6.29357 6.20643 6.66667 6.66667 6.66667C7.1269 6.66667 7.5 6.29357 7.5 5.83333H5.83333ZM7.5 2.5C7.5 2.03976 7.1269 1.66667 6.66667 1.66667C6.20643 1.66667 5.83333 2.03976 5.83333 2.5H7.5ZM12.5 5.83333C12.5 6.29357 12.8731 6.66667 13.3333 6.66667C13.7936 6.66667 14.1667 6.29357 14.1667 5.83333H12.5ZM14.1667 2.5C14.1667 2.03976 13.7936 1.66667 13.3333 1.66667C12.8731 1.66667 12.5 2.03976 12.5 2.5H14.1667ZM5.83333 8.33333C5.3731 8.33333 5 8.70643 5 9.16667C5 9.6269 5.3731 10 5.83333 10V8.33333ZM14.1667 10C14.6269 10 15 9.6269 15 9.16667C15 8.70643 14.6269 8.33333 14.1667 8.33333V10ZM4.16667 5H15.8333V3.33333H4.16667V5ZM16.6667 5.83333V15.8333H18.3333V5.83333H16.6667ZM15.8333 16.6667H4.16667V18.3333H15.8333V16.6667ZM3.33333 15.8333V5.83333H1.66667V15.8333H3.33333ZM4.16667 16.6667C3.70643 16.6667 3.33333 16.2936 3.33333 15.8333H1.66667C1.66667 17.214 2.78595 18.3333 4.16667 18.3333V16.6667ZM16.6667 15.8333C16.6667 16.2936 16.2936 16.6667 15.8333 16.6667V18.3333C17.214 18.3333 18.3333 17.214 18.3333 15.8333H16.6667ZM15.8333 5C16.2936 5 16.6667 5.3731 16.6667 5.83333H18.3333C18.3333 4.45262 17.214 3.33333 15.8333 3.33333V5ZM4.16667 3.33333C2.78595 3.33333 1.66667 4.45262 1.66667 5.83333H3.33333C3.33333 5.3731 3.70643 5 4.16667 5V3.33333ZM7.5 5.83333V2.5H5.83333V5.83333H7.5ZM14.1667 5.83333V2.5H12.5V5.83333H14.1667ZM5.83333 10H14.1667V8.33333H5.83333V10Z"
            fill="#6B7280"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { ru } from "date-fns/locale";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { Russian } from "flatpickr/dist/l10n/ru.js";

export default defineComponent({
  name: "CalendarSelector",
  components: {
    flatPickr,
  },
  props: {
    modelValue: {
      type: [Date, String],
      required: true,
    },
  },
  data() {
    return {
      ru: ru,
      internalValue: new Date(),
      config: {
        locale: Russian,
      },
    };
  },
  watch: {
    internalValue(newVal) {
      this.$emit("update:modelValue", newVal);
    },
  },
  methods: {
    // onDateChange(selectedDates) {
    //   const date = selectedDates[0] ? new Date(selectedDates[0]) : null;
    //   this.internalDate = date;
    //   this.$emit("update:modelValue", date);
    // },
  },
});
</script>

<style scoped>
.calendar__div {
  border-radius: 5px;
  border: 1px solid rgba(60, 60, 60, 0.26);
}

.dp-custom-input {
  outline: none;
  border: none;
}
.dp-custom-input:focus {
  outline: none;
  border: none;
}
.icon__calendar {
  position: absolute;
  right: 16px;
  top: 7px;
  z-index: 10;
}

.flatpickr-input {
  width: 100%;
  border: none;
  outline: none;
  padding: 8px 12px;
  box-sizing: border-box;
  border-radius: 5px;
  height: 34px;
}
</style>
