<template>
  <div>
    <p class="text-2xl text-gray-900 font-semibold mb-4">
      {{$t('all_courses')}}
    </p>
    <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr	">
      <CreateNewCourseCard v-if="active_user_has_permission('can_create_course')"/>
      <CourseCard
            v-for="course in courses"
            :name="course.name"
            :id="course.id"
            :specializations="course.specializations"
            :key="course.id"
            :logo="course.logo"
            :start_date="course.start_date"
            :end_date="course.end_date"
            :instructors="course.instructors"
            :destination="'edit'"
            :duration="course.duration"
        />
      </div>
  </div>
</template>
<script>
import ListMixin from "@/views/courses/List/ListMixin";
import CourseCard from "@/components/Courses/Card/CourseCard";
import CreateNewCourseCard from "@/components/Courses/Card/CreateNewCourseCard";
export default {
  name:"CoursesList",
  components: {CreateNewCourseCard, CourseCard},
  mixins:[ListMixin]
}
</script>