import { SET_LOADING } from "@/store/core";
export const GET_LANDING_COURSES = "GET_LANDING_COURSES";
export const SET_LANDING_COURSES = "SET_LANDING_COURSES";
export const ADD_LANDING_COURSES = "ADD_LANDING_COURSES";
export const GET_LANDING_COURSE = "GET_LANDING_COURSE";
export const SET_LANDING_COURSE = "SET_LANDING_COURSE";
export const DELETE_LANDING_COURSES = "DELETE_LANDING_COURSES";
export const GET_LANDING_COURSES_OPTIONS = "GET_LANDING_COURSES_OPTIONS";
export const SET_LANDING_COURSES_OPTIONS = "SET_LANDING_COURSES_OPTONS";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {
    landing_courses: [],
    landing_course: null,
    options: [],
  };
  const actions = {
    [GET_LANDING_COURSES]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.landing_courses.get_landing_courses(params);
      commit(SET_LANDING_COURSES, data);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_LANDING_COURSE]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.landing_courses.get_landing_course(params);
      commit(SET_LANDING_COURSE, data);
      commit(SET_LOADING, false);
      return data;
    },
    [ADD_LANDING_COURSES]: async ({ commit }, courseData) => {
      commit(SET_LOADING, true);
      await $api.landing_courses.add_landing_courses(courseData);
      commit(SET_LOADING, false);
    },
    [DELETE_LANDING_COURSES]: async ({ commit }, id) => {
      commit(SET_LOADING, true);
      await $api.landing_courses.delete_landing_courses(id);
      commit(SET_LOADING, false);
    },
    [GET_LANDING_COURSES_OPTIONS]: async ({ commit } = {}) => {
      commit(SET_LOADING, true);
      const { data } = await $api.landing_courses.get_landing_courses_options();
      commit(SET_LANDING_COURSES_OPTIONS, data);
      commit(SET_LOADING, false);
      return data;
    },
  };
  const mutations = {
    [SET_LANDING_COURSES]: (state, payload) => {
      state.landing_courses = payload;
    },
    [SET_LANDING_COURSE]: (state, payload) => {
      state.landing_course = payload;
    },
    [SET_LANDING_COURSES_OPTIONS]: (state, payload) => {
      state.options = payload;
    },
  };
  const getters = {
    landing_courses: (state) => state.landing_courses,
    landing_course: (state) => state.landing_course,
    options: (state) => state.options,
  };
  return {
    actions,
    state,
    mutations,
    getters,
  };
};
export default (app) => createStore(app);
