export default [
	{
		name: 'Profile',
		path: '/profile',
		component: async () => require('../layouts/CabinetLayout'),
		children: [
			{ name: 'UserProfile', path: '', component: async () => require('@/views/users/Profile/Profile') },
		]
	},

]
