<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <div>
    <p class="text-2xl text-gray-900 font-semibold mb-4">
      {{ $t("groups") }}
    </p>
    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr"
    >
      <router-link
        custom
        :to="{ name: 'GroupForm', params: { id: id } }"
        v-slot="{ navigate, href }"
      >
        <a
          :href="href"
          @click="navigate"
          class="border-dashed border-gray-300 border-2 shadow-lg rounded-xl bg-white w-full sm:max-w-fit overflow-hidden"
          style="max-width: 264px"
        >
          <div class="flex flex-col justify-center h-full place-items-center">
            <UserGroupIcon class="text-indigo-600 h-5 w-5" />
            <span class="text-sm leading-5 font-medium tex-gray-900">
              {{ $t("create_new_group") }}
            </span>
          </div>
        </a>
      </router-link>
      <router-link
        v-for="group in groups"
        :key="group.group_number"
        custom
        :to="`groups/view?group_number=${group.group_id}`"
        v-slot="{ navigate, href }"
      >
        <a
          :href="href"
          @click="navigate"
          class="shadow-lg rounded-xl bg-white w-full sm:max-w-fit overflow-hidden p-4"
          style="max-width: 264px"
        >
          <div class="flex flex-col h-full justify-between gap-4">
            <!-- <component :is="route.icon" class="text-indigo-600 h-5 w-5 mb-4" /> -->
            <span class="text-lg leading-5 font-medium tex-gray-900">
              {{ group.group_number }}
            </span>
            <div class="flex flex-col">
              <span v-if="group.end_date" class="text-sm leading-5 font-normal text-gray-400 flex">
                <CalendarIcon class="h-6 text-gray-400 " />
                <span class="mt-1 ml-1">
                  {{ $t("until") }} {{formatDate(group.end_date)}}
                </span>
              </span>
              <span class="mt-2 text-sm leading-5 font-normal text-gray-400 flex">
                <UserGroupIcon class="h-6" />
                <span class="mt-1 ml-1">
                  {{ $t("students_number", { amount: group.students_number }) }}
                </span>
              </span>
              <div v-if="group.tutors.length > 0">
                <InstructorInfoCard
                  v-for="tutor in group.tutors"
                  :key="tutor.id"
                  :instructor="tutor"
                  class="mt-2 -mb-1 p-1"
                />
              </div>
            </div>
          </div>
        </a>
      </router-link>
    </div>
  </div>
</template>
<script>
import InstructorInfoCard from "@/components/Courses/InstructorInfo/InstructorInfoCard.vue";
import { GET_COURSE_GROUPS } from "@/store/users";
import { GET_COURSE } from "@/store/courses";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import { CalendarIcon, UserGroupIcon } from "@heroicons/vue/solid";
import moment from "moment";
export default {
  components: { UserGroupIcon, InstructorInfoCard, Breadcrumbs, CalendarIcon },
  data: () => ({
    id: null,
    groups: [],
    course: null,
    breadcrumbs: [],
  }),
  methods:{
    formatDate(date){
      moment.locale(this.currentLocale)
      return moment(date).format('DD.MM.YYYY')
    }
  },
  computed:{
    currentLocale(){
        return this.$i18n.locale;
    },
  },
  async created() {
    this.id = this.$route.params.id;
    this.groups = await this.$store.dispatch(GET_COURSE_GROUPS, {
      course: this.id,
    });
    this.course = await this.$store.dispatch(GET_COURSE, this.id);
    this.breadcrumbs = [
      {
        name: this.$t("courses"),
        path: "/courses/list",
      },
      {
        name: this.course.name,
        path: `/courses/${this.id}/edit`,
      },
      {
        name: this.$t("groups"),
        path: this.$route.path,
      },
    ];
  },
};
</script>
