<template>
  <router-link  custom :to="`/specializations/form?id=${id}`" v-slot="{ navigate, href }">
    <a :href="href" @click="navigate" class="shadow-lg rounded-xl bg-white w-full overflow-hidden">
      <div
          class="h-40  hidden sm:flex  bg-no-repeat bg-cover	"
          :style="`background-image:url(${logo})`"
      />
      <div class="p-6 text-xl leading-7 font-semibold text-gray-900">
          {{name}}
      </div>
    </a>
  </router-link>
</template>
<script>
  export default {
    props:{
      id:{
        required:true,
        type:[String,Number]
      },
      name:{
        type:String,
        required:true
      },
      logo:{
        type:String,
        default:''
      }
    }
  }
</script>