<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="22" height="22" rx="11" fill="#111827"/>
    <circle cx="12" cy="12" r="4" fill="white"/>
  </svg>

</template>
<script>
import IconMixin from "@/assets/icons/IconMixin";

export default {
  mixins:[IconMixin]
}
</script>