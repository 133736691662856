<template>
  <div class="px-8 pt-7">
    <h1 class="text-2xl leading-8 font-semibold mb-4">
      {{ $t("about_course") }}
    </h1>
    <div class="flex flex-wrap">
      <img
        :src="course.logo"
        class="rounded-md rounded-xl mb-4 w-full flex lg:hidden"
      />
      <div class="w-full lg:w-3/4">
        <TheCard additional_class="min-w-0">
          <template #header>{{ $t("description") }}</template>
          <div v-html="course.description" class="quill" />
        </TheCard>
        <TheCard>
          <template #header>{{ $t("what_you_will_learn") }}</template>
          <div
            v-for="goal in course.goals"
            :key="goal"
            class="flex place-items-center mt-4"
          >
            <CheckCircleIcon class="h-4 w-4 text-indigo-600 flex-shrink-0" />
            <span class="ml-2 text-sm leading-5 font-normal">
              {{ goal.name }}
            </span>
          </div>
        </TheCard>
        <TheCard>
          <template #header>{{ $t("how_study_is_passed") }}</template>
          <div class="flex flex-row flex-wrap gap-y-4">
            <div
              class="flex-row w-full flex"
              v-for="perk in course.perks"
              :key="perk.title"
            >
              <component
                :is="perk.icon_name"
                class="h-5 w-5 text-indigo-600 flex-shrink-0"
              />
              <div class="flex-1 pl-1">
                <h4 class="text-base leading-6 font-semibold">
                  {{ $t(perk.title) }}
                </h4>
                <span class="text-sm leading-5 font-normal">{{
                  $t(perk.description)
                }}</span>
              </div>
            </div>
          </div>
        </TheCard>
      </div>
      <div
        class="w-full pl-0 flex flex-col-reverse lg:w-1/4 lg:pl-4 lg:flex-col"
        v-if="course.overview"
      >
        <TheButton
          bg="primary"
          class="mb-4"
          @click="startCourse"
          v-if="!course.is_started && !show_cant_start_error"
          >{{ $t("start") }}
        </TheButton>
        <TheAlert
          v-if="show_cant_start_error"
          type="danger"
          class="text-sm leading-5 font-normal flex place-content-center mb-4"
          >{{ $t("cant_start_course") }}
        </TheAlert>
        <img
          :src="course.logo"
          class="rounded-md rounded-xl hidden lg:flex mb-4 w-full"
        />
        <div v-if="course.id != 17">
          <OverviewCard
            :score="course.overview.score"
            :quizzes_count="course.overview.quizzes_count"
            :duration_hours="course.overview.duration_hours"
            :skill_level="course.overview.skill_level"
            :chapters_count="course.overview.topic_count * 1"
            :resources_count="course.overview.resources_count * 1"
            :is_certificate_given="course.overview.is_certificate_given"
            :votes="course.overview.votes"
          />
          <InstructorInfoCard
            :key="`instructor_photo_${instructor.id}`"
            v-for="instructor in course.instructors"
            :instructor="instructor.instructor"
            :description="instructor.instructor_description"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { GET_COURSE, START_COURSE_REQUEST } from "@/store/courses";
import { CheckCircleIcon } from "@heroicons/vue/solid";

import TheCard from "@/components/TheCard/TheCard";
import { mapFields } from "vuex-map-fields";
import UserAvatar from "@/components/UserAvatar";
import InstructorInfoCard from "@/components/Courses/InstructorInfo/InstructorInfoCard";
import OverviewCard from "@/components/Courses/OverviewCard/OverviewCard";
import TheButton from "@/components/Button/Button";
import OutlineIconsMixin from "@/components/TheIconSelector/OutlineIconsMixin";
import TheAlert from "@/components/Alert/Alert";

export default {
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data: () => ({
    show_cant_start_error: false,
  }),
  mixins: [OutlineIconsMixin],
  components: {
    TheAlert,
    TheButton,
    OverviewCard,
    InstructorInfoCard,
    UserAvatar,
    TheCard,
    CheckCircleIcon,
  },
  methods: {
    ...mapActions([GET_COURSE, START_COURSE_REQUEST]),
    async startCourse() {
      try {
        await this[START_COURSE_REQUEST](this.id);
        this.$router.push({
          name: "CourseContent",
          params: { id: this.id },
          replace: true,
        });
      } catch (e) {
        console.log("couldnt start course", e);
        this.show_cant_start_error = true;
        // setTimeout(()=>this.show_cant_start_error=false,2000)
      }
    },
  },
  computed: {
    ...mapFields(["courses.course"]),
    totalHours() {
      try {
        return this.course.duration.split(":")[0] * 1;
      } catch (e) {
        return "0";
      }
    },
  },
  created() {
    this.GET_COURSE(this.id);
  },
};
</script>
