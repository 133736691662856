<template>
  <div>
    <div class="video-container">
      <iframe
          :src="url"
          title="YouTube video player"
          frameborder="0"
          width="100%"
          height="100%"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      
    </div>
    <div>
      <AttachmentBlock :attachments="attachments" v-if="!!attachments[0]"/>
    </div>
  </div>
</template>
<script>
import AttachmentBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
export default {
  components: {AttachmentBlock},
  props:{
    url:{
      type:String,
      required:true
    },
    attachments:{
      type:Array,
      default:()=>[]
    }
  }
}
</script>
<style lang="postcss">
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @apply rounded-xl
}
</style>