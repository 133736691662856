<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "ButtonTemplate",
  props: {
    classNames: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
  },
});
</script>

<template>
  <button :class="classNames" @click="handleClick"><slot></slot></button>
</template>

<style scoped>
.btn-attendance,
.btn-download,
.btn-save-data {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.btn-attendance {
  border: 1px solid #d1d5db;
  color: #374151;
}
.btn-download {
  background: #eef2ff;
  color: #4338ca;
}
.btn-save-data {
  background: #4f46e5;
  color: white;
}
</style>
