<script setup></script>

<template>
  <svg
    width="136"
    height="154"
    viewBox="0 0 136 154"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M106.626 88.9549C107.676 87.5871 109.199 86.66 110.896 86.3548C112.55 86.3243 114.15 86.9416 115.356 88.0748C117.04 89.5179 118.593 91.1074 119.996 92.8248C121.664 94.8876 123.526 96.7863 125.556 98.4949C126.908 99.646 128.602 100.321 130.376 100.415C131.391 100.381 132.389 100.14 133.308 99.709C134.228 99.2775 135.05 98.6637 135.726 97.9049L134.556 96.7049C134.029 97.2964 133.39 97.7776 132.676 98.1209C131.962 98.4642 131.187 98.6626 130.396 98.7049C128.98 98.6044 127.636 98.0463 126.566 97.1149C124.628 95.4769 122.853 93.6551 121.266 91.6749C119.549 89.5261 117.562 87.607 115.356 85.9649C114.013 85.0081 112.382 84.542 110.736 84.6449C109.046 84.8149 107.266 85.7248 105.386 87.7848L106.626 88.9549Z"
      fill="#F5ED2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M35.8454 85.685C34.4054 81.125 30.0854 79.195 25.5454 80.635C22.3654 81.635 18.8254 83.575 15.4254 84.335C12.3154 85.025 9.32542 84.675 6.92542 81.335L5.56543 82.335C8.45543 86.335 12.0454 86.815 15.7854 85.985C19.2354 85.215 22.7854 83.265 26.0554 82.245C29.6554 81.105 33.1054 82.585 34.2454 86.245L35.8454 85.685Z"
      fill="#F5ED2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M79.1861 122.205C79.7367 122.07 80.309 122.049 80.8679 122.144C81.4267 122.239 81.9604 122.446 82.4361 122.755C83.5261 123.635 83.7261 125.315 83.4361 127.335C82.9627 129.809 82.7382 132.325 82.7661 134.845C82.8961 137.275 83.5961 139.435 85.2861 140.845C86.7069 141.91 88.4514 142.454 90.2261 142.385V140.705C88.8326 140.768 87.4585 140.359 86.326 139.545C84.986 138.455 84.5261 136.705 84.4261 134.785C84.3982 132.383 84.6126 129.984 85.0661 127.625C85.5061 124.895 84.9861 122.625 83.4961 121.475C82.8446 121.015 82.1038 120.697 81.3217 120.542C80.5396 120.387 79.7336 120.398 78.9561 120.575L79.1861 122.205Z"
      fill="#F5ED2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M92.776 31.3849C92.0742 28.7016 91.8541 25.9151 92.1261 23.1549C92.2602 21.8665 92.7028 20.6294 93.4165 19.5484C94.1302 18.4674 95.0939 17.5744 96.226 16.9449C98.0131 16.0571 99.9251 15.4467 101.896 15.1349C104.117 14.7458 106.191 13.7608 107.896 12.2849L106.756 11.0449C105.28 12.3219 103.481 13.1693 101.556 13.4949C99.4305 13.8509 97.3707 14.5251 95.4461 15.4949C94.0876 16.247 92.9297 17.3145 92.07 18.6075C91.2103 19.9006 90.674 21.3813 90.5061 22.9249C90.2048 25.888 90.4317 28.8811 91.1761 31.7649L92.776 31.3849Z"
      fill="#F5ED2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M34.0662 88.4949C33.0638 87.1984 31.6553 86.2758 30.0662 85.8749C27.9824 85.5615 25.8551 86.0012 24.0662 87.1149C22.5262 87.9249 20.9662 88.9649 19.4162 89.8749C18.1048 90.7282 16.647 91.3317 15.1162 91.6549L15.3662 93.3149C18.4962 92.8449 21.6962 90.2449 24.8462 88.6049C26.2915 87.6725 28.0206 87.2827 29.7262 87.5049C30.9585 87.8361 32.0455 88.5679 32.8162 89.5849L34.0662 88.4949Z"
      fill="#E9D3FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M95.1456 28.8154C95.9256 25.5154 98.1456 24.1654 100.846 23.2854C103.846 22.2854 107.326 21.8953 110.206 20.4953L109.466 18.9854C106.656 20.3554 103.246 20.7454 100.326 21.6954C97.0956 22.6954 94.4456 24.4454 93.5156 28.4254L95.1456 28.8154Z"
      fill="#E9D3FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.916 120.635C85.0398 121.546 85.871 122.766 86.306 124.145C86.886 125.675 87.246 127.355 87.696 128.885C88.296 130.945 89.076 132.735 90.496 133.735C91.7146 134.533 93.1991 134.817 94.626 134.525L94.396 132.865C93.3903 133.087 92.3378 132.904 91.466 132.355C90.326 131.555 89.786 130.065 89.306 128.415C88.826 126.765 88.466 125.115 87.876 123.555C87.3258 121.861 86.281 120.371 84.876 119.275L83.916 120.635Z"
      fill="#E9D3FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.33594 66.1049C6.21594 67.2849 6.93596 68.585 7.79596 69.785L9.16595 68.785C8.29595 67.575 7.56596 66.275 6.67596 65.085L5.33594 66.1049Z"
      fill="#E9D3FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.10583 70.6851C7.32275 68.911 8.80312 67.3328 10.4958 66.0051L9.42584 64.7051C7.61359 66.1257 6.02574 67.8113 4.71582 69.7051L6.10583 70.6851Z"
      fill="#E9D3FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M133.706 112.045C132.386 114.195 130.846 116.175 129.476 118.295L130.916 119.205C132.276 117.075 133.826 115.085 135.166 112.925L133.706 112.045Z"
      fill="#E9D3FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M130.096 114.425C131.786 115.425 133.276 116.685 134.926 117.765L135.846 116.365C134.186 115.275 132.686 114.055 130.986 112.995L130.096 114.425Z"
      fill="#E9D3FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.9158 14.095C52.9024 16.7057 53.0594 19.3146 53.3858 21.905L55.0458 21.675C54.7307 19.1575 54.5804 16.6221 54.5958 14.085L52.9158 14.095Z"
      fill="#53FF16"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.55609 119.605C8.46609 117.945 8.41605 118.025 10.386 116.525L9.38605 115.185C8.02224 116.166 6.71047 117.217 5.45605 118.335L6.55609 119.605Z"
      fill="#53FF16"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M121.215 75.1249C124.353 73.1207 127.639 71.3589 131.045 69.8549L130.415 68.2949C126.93 69.8328 123.567 71.6348 120.355 73.6849L121.215 75.1249Z"
      fill="#53FF16"
    />
    <path
      d="M65.7254 104.685C78.3782 104.685 88.6354 94.4281 88.6354 81.7752C88.6354 69.1224 78.3782 58.8652 65.7254 58.8652C53.0726 58.8652 42.8154 69.1224 42.8154 81.7752C42.8154 94.4281 53.0726 104.685 65.7254 104.685Z"
      fill="#6880FF"
    />
    <path
      d="M57.3261 57.4047C47.4361 46.5547 34.5661 34.0547 24.7661 26.4947C20.8261 23.4947 15.4962 19.3747 10.2762 18.9547C6.19616 18.6247 6.99619 25.0347 7.74619 27.4247C9.86619 34.1447 14.7462 40.6647 19.1662 46.0947"
      stroke="#B5B5C4"
      stroke-width="0.69"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M33.0361 61.875C48.9561 79.395 66.2661 95.065 86.0361 111.415C90.1661 114.825 99.1361 121.815 105.206 125.065C111.276 128.315 123.116 132.165 117.846 120.655C115.807 116.592 113.212 112.833 110.136 109.485C105.526 105.025 100.786 100.195 93.0562 93.725"
      stroke="#B5B5C4"
      stroke-width="0.69"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M63.916 55.1049C64.506 41.3949 66.436 21.7749 70.036 8.52491C70.3598 7.36006 70.7505 6.21482 71.206 5.0949C72.876 0.904901 76.316 -1.2051 77.626 4.3949C78.6117 9.17952 79.0445 14.0615 78.916 18.9449"
      stroke="#B5B5C4"
      stroke-width="0.69"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.9159 37.6748C77.4702 66.8616 74.5397 95.956 70.1358 124.845C69.1358 130.965 66.5059 152.735 62.2959 152.425C57.4259 152.055 55.9359 140.305 55.2159 132.755C54.2759 122.805 54.8659 113.485 55.2159 106.885"
      stroke="#B5B5C4"
      stroke-width="0.69"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M42.7257 94.3145C39.3957 98.1845 35.0457 103.134 33.0657 105.974C31.7757 107.814 30.3857 109.574 29.0657 111.374C27.7457 113.174 26.4957 114.784 25.2857 116.544C24.3057 117.958 23.3823 119.401 22.5157 120.874C20.5157 124.234 10.1057 140.364 23.3857 139.364"
      stroke="#B5B5C4"
      stroke-width="0.69"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M41.2861 131.265C67.6461 107.195 93.9461 81.265 115.776 52.975C118.326 49.665 120.706 46.285 122.976 42.785C124.526 40.395 125.976 37.975 127.436 35.515C130.516 30.215 134.606 21.595 123.956 22.745C122.387 22.9361 120.855 23.3538 119.406 23.985C108.286 28.595 91.7462 42.695 82.8162 50.635"
      stroke="#B5B5C4"
      stroke-width="0.69"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M78.6856 34.2247C81.9772 34.2247 84.6456 31.5563 84.6456 28.2647C84.6456 24.9731 81.9772 22.3047 78.6856 22.3047C75.394 22.3047 72.7256 24.9731 72.7256 28.2647C72.7256 31.5563 75.394 34.2247 78.6856 34.2247Z"
      fill="#E9D3FF"
    />
    <path
      d="M31.6562 140.785C34.9479 140.785 37.6163 138.117 37.6163 134.825C37.6163 131.534 34.9479 128.865 31.6562 128.865C28.3646 128.865 25.6963 131.534 25.6963 134.825C25.6963 138.117 28.3646 140.785 31.6562 140.785Z"
      fill="#E9D3FF"
    />
    <path
      d="M25.6964 58.8653C28.988 58.8653 31.6563 56.1969 31.6563 52.9053C31.6563 49.6137 28.988 46.9453 25.6964 46.9453C22.4047 46.9453 19.7363 49.6137 19.7363 52.9053C19.7363 56.1969 22.4047 58.8653 25.6964 58.8653Z"
      fill="#E9D3FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M43.0258 76.6445C39.1758 91.5245 50.5959 106.195 66.4059 104.525L66.2259 102.865C51.6259 104.405 41.0958 90.8145 44.6458 77.0645L43.0258 76.6445Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5761 50.6445C18.3461 56.7845 25.5061 63.1145 30.7961 57.7645C31.6987 56.6887 32.3053 55.3962 32.5561 54.0145L30.9161 53.6045C30.7508 54.7041 30.2952 55.7398 29.5961 56.6045C25.5961 60.6645 20.2761 55.6545 21.2161 50.9945L19.5761 50.6445Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.4258 132.045C24.9676 133.591 24.933 135.231 25.3258 136.795C25.5813 137.736 26.0445 138.609 26.6813 139.347C27.3181 140.086 28.1124 140.673 29.0058 141.065C32.6258 142.555 37.4458 140.135 38.5658 135.525L36.9358 135.125C36.0758 138.675 32.4258 140.655 29.6458 139.515C28.9796 139.212 28.3896 138.763 27.919 138.203C27.4483 137.642 27.109 136.984 26.9258 136.275C26.6233 135.01 26.6683 133.687 27.0558 132.445L25.4258 132.045Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M73.5463 23.8945C72.6755 25.0111 72.1755 26.3719 72.1163 27.7866C72.0571 29.2013 72.4417 30.5992 73.2163 31.7845C74.0101 32.9639 75.1605 33.8584 76.4992 34.337C77.8379 34.8156 79.2946 34.8533 80.6563 34.4445C82.1006 33.9422 83.3708 33.0365 84.3163 31.8345L83.0263 30.7645C82.3096 31.7054 81.3377 32.4204 80.2263 32.8245C79.2003 33.1369 78.1012 33.1111 77.091 32.7509C76.0809 32.3908 75.2133 31.7154 74.6163 30.8245C74.0294 29.9208 73.7407 28.8558 73.791 27.7795C73.8412 26.7031 74.2278 25.6696 74.8963 24.8245L73.5463 23.8945Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.0156 62.7648C36.0156 68.3248 40.7156 74.2148 45.9156 79.6248C54.5019 88.3338 63.6373 96.4838 73.2656 104.025C79.4756 109.025 85.6557 114.265 92.5657 118.255L93.4056 116.805C86.5656 112.805 80.4657 107.645 74.3157 102.715C64.7432 95.2211 55.6612 87.1212 47.1257 78.4648C41.9357 73.0648 37.2756 67.1848 32.2656 61.6348L31.0156 62.7648Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M76.4855 40.2051C75.3355 51.4251 74.0355 62.8051 73.3855 74.0651C72.9755 81.0651 73.2655 88.5351 72.1455 95.4951L73.8055 95.7651C74.9355 88.7651 74.6555 81.2551 75.0555 74.1551C75.7055 62.9251 77.0555 51.5751 78.1455 40.3751L76.4855 40.2051Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.97606 18.0352C4.06606 23.8652 5.30607 29.2552 7.85607 34.2552C10.4061 39.2552 14.046 43.6152 17.206 47.8251L18.5461 46.8251C15.4461 42.6852 11.7761 38.2752 9.35606 33.5052C7.00606 28.8952 5.80609 23.9352 7.56609 18.5652L5.97606 18.0352Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M90.9961 95.0554C98.5161 101.585 107.116 108.895 112.876 117.055C114.228 118.756 115.41 120.585 116.406 122.515C116.694 123.033 116.875 123.603 116.94 124.192C117.006 124.781 116.953 125.377 116.786 125.945L118.346 126.565C118.597 125.79 118.69 124.972 118.622 124.16C118.553 123.348 118.323 122.558 117.946 121.835C116.906 119.804 115.667 117.881 114.246 116.095C108.416 107.765 99.7161 100.355 92.0961 93.7354L90.9961 95.0554Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0059 22.7948C18.2659 24.2548 23.6958 29.3248 28.4858 33.4148C37.9324 41.4083 46.9705 49.8723 55.5659 58.7748L56.7758 57.6148C48.1429 48.6699 39.0648 40.1658 29.5758 32.1348C24.5758 27.8748 18.9058 22.6748 12.3858 21.1348L12.0059 22.7948Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.376 117.495C18.226 122.265 14.516 128.375 14.156 134.235C14.0236 135.491 14.215 136.759 14.7122 137.92C15.2094 139.081 15.9957 140.095 16.996 140.865C18.0238 141.588 19.2014 142.069 20.4411 142.274C21.6808 142.478 22.9507 142.4 24.156 142.045L23.736 140.425C22.7739 140.713 21.7592 140.781 20.7672 140.624C19.7753 140.466 18.8316 140.087 18.006 139.515C17.232 138.908 16.6256 138.114 16.2441 137.208C15.8626 136.301 15.7187 135.313 15.826 134.335C16.176 128.755 19.826 122.965 22.826 118.425L21.376 117.495Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M42.4761 133.645C52.9361 125.715 61.7361 115.645 71.2261 106.645L70.0661 105.425C60.6261 114.425 51.8761 124.425 41.4561 132.325L42.4761 133.645Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M78.5354 99.2052C81.9754 95.2952 85.9254 91.8552 89.3654 87.9452C100.285 75.5452 110.935 63.0952 120.805 49.8352C123.605 46.0752 126.605 42.4952 129.105 38.5152L127.685 37.6152C125.205 41.6152 122.235 45.1052 119.455 48.8352C109.615 62.0552 98.9954 74.4652 88.1054 86.8352C84.6654 90.7452 80.7154 94.1852 77.2754 98.0952L78.5354 99.2052Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M83.986 52.8653C94.056 44.1853 104.666 35.8053 115.986 28.8653C118.657 27.1644 121.584 25.9049 124.656 25.1353C125.263 24.9714 125.887 24.8808 126.516 24.8653C126.999 24.8591 127.466 25.0339 127.826 25.3553L128.976 24.1353C127.686 22.9153 125.886 23.1353 124.286 23.4953C121.036 24.3017 117.938 25.6325 115.116 27.4353C103.748 34.6018 92.979 42.6783 82.916 51.5853L83.986 52.8653Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.8057 106.205C52.2925 109.609 51.9587 113.037 51.8057 116.475H53.4857C53.6392 113.1 53.973 109.735 54.4857 106.395L52.8057 106.205Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M52.2753 126.025C51.8892 132.478 52.651 138.949 54.5253 145.135C55.4453 148.275 57.2254 152.135 60.1254 153.955L61.0153 152.525C58.4453 150.925 56.9553 147.445 56.1353 144.665C54.3107 138.664 53.5593 132.388 53.9153 126.125L52.2753 126.025Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M63.136 151.025C65.196 147.355 66.136 143.135 67.066 139.025C68.886 130.745 69.696 122.375 71.186 114.025L69.526 113.735C68.046 122.055 67.246 130.405 65.416 138.665C64.556 142.575 63.646 146.665 61.666 150.165L63.136 151.025Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M62.6563 56.2852C63.0563 50.6452 63.0463 44.9452 63.5263 39.2852C64.2397 32.0815 65.375 24.9259 66.9263 17.8551C67.9863 12.6551 69.3263 6.44516 72.3363 1.97516L70.9464 1.03516C67.8163 5.68516 66.3763 12.1152 65.2763 17.5252C63.7193 24.6799 62.5774 31.9186 61.8563 39.2052C61.3763 44.8452 61.3763 50.5452 60.9863 56.2052L62.6563 56.2852Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M75.0254 4.02524C76.2605 9.34486 76.7418 14.8116 76.4554 20.2652L78.1354 20.3152C78.425 14.7068 77.9268 9.08537 76.6554 3.61523L75.0254 4.02524Z"
      fill="black"
    />
  </svg>
</template>

<style scoped></style>
