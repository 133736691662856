<template>
  <div class="bg-white rounded-xl p-4 w-full">
    <div v-html="content">
    </div>
    <AttachmentBlock :attachments="attachments"/>
  </div>
</template>
<script>
  import AttachmentBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
  export default {
    components: {AttachmentBlock},
    props:{
      content:{
        type:String,
        required:true
      },
      attachments:{
        type:Array,
        default:()=>[]
      }
    }
  }
</script>

<!--<style lang="postcss">-->
<!--  a {-->
<!--    word-break: break-all;-->
<!--  }-->
<!--</style>-->