<template>
  <div
    class="bg-white rounded-xl p-6 mb-4 min-w-fit card-container shadow"
    :class="additional_class"
  >
    <h2
      class="text-xl leading-7 font-semibold text-gray-900 mb-6"
      v-if="showHeader"
    >
      <slot name="header" />
    </h2>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    additional_class: String,
  },
  computed: {
    showHeader() {
      return !!this.$slots["header"];
    },
  },
};
</script>
<style>
.card-container p {
  overflow: hidden;
}

@media (max-width: 650px) {
  .card-container {
    padding: 40px 32px;
  }
}
</style>
