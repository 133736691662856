<template>
  <ContentBlockForm :content_name="$t('multiple_answers_question_label')" :_key="_key"  :attachments="attachments">
    <TheQuillEditorInput v-model="localValue" @change="emitUpdate"/>
    <div>
      <div
          v-for="(question_answer,index) in available_answers"
          :key="`answer_${question_answer.id ||question_answer._key}`"
          class="cursor-pointer w-full bg-gray-50 p-2 inline-flex mb-2 rounded-l place-items-center"
      >
        <input type="checkbox"
               v-model="correct_answers"
               :value="question_answer.id ||question_answer._key"
               class="mr-2"
               @change="emitUpdate"
        />
        <InputText
            additionalClass="w-full"
            v-model="question_answer.text"
            :placeholder="$t('option_with_number',{number:index+1})" @change="emitUpdate"/>
        <TrashIcon v-if="available_answers.length > 2" class="h-5 cursor-pointer text-gray-700" @click="handleRemoveAnswerButtonClicked(question_answer.id, question_answer._key)"/>
      </div>
    </div>
    <TheButton bg="secondary" @click="addAvailableAnswer">
      <PlusIcon class="h-5 mr-2"/>
      {{ $t('add_answer_option') }}
    </TheButton>
  </ContentBlockForm>
</template>
<script>
import ContentBlockForm from "@/views/resources/Form/components/ContentBlockForm";
import TheQuillEditorInput from "@/components/TheQuillEditorInput";
import TheButton from "@/components/Button/Button";
import InputText from "@/components/InputText";
import {PlusIcon, TrashIcon} from "@heroicons/vue/outline";
import QuestionMixin from "@/views/resources/Form/components/QuestionMixin";
export default {
  components: {InputText, TheButton, TheQuillEditorInput, ContentBlockForm,PlusIcon, TrashIcon},
  // data:()=>({
  //   available_answers:[],
  //   content:{},
  //   correct_answers:[]
  // }),
  mixins:[QuestionMixin],
  methods:{
    generateNewAvailableAnswer(){
      return {
        text:"",
        _key:this.generateRandomKey(),

      }
    },
    addAvailableAnswer(){
      this.available_answers.push(this.generateNewAvailableAnswer())
    },
    emitUpdate(){
      this.$emit('contentChange',{
        available_answers:this.available_answers,
        correct_answers: this.correct_answers
      })
    }
  },
    computed:{
        localValue:{
            get(){
                return this.content
            },
            set(val){
                this.$emit('contentChange',{
                    content:val,
                    available_answers:this.available_answers,
                    correct_answers:this.correct_answers
                })
            }
        }
    },
    beforeUpdate() {
      if(this.available_answers){
        if(this.available_answers.find(el => el.is_selected)){
          this.correct_answers = this.available_answers.filter(el =>el.is_selected).map(el => el.id || el._key)
          this.emitUpdate()
        }
      }
      while(this.available_answers.length<2){
        this.addAvailableAnswer()
      }
    },
    created(){
      if(this.available_answers){
        if(this.available_answers.find(el => el.is_selected)){
          this.correct_answers = this.available_answers.filter(el =>el.is_selected).map(el => el.id || el._key)
          this.emitUpdate()
        }
      }
      while(this.available_answers.length<2){
        this.addAvailableAnswer()
      }
    }
}
</script>