<template>
  <div>
    <h1 class="text-2xl leading-8 font-semibold mb-4">{{$t('users_list')}}</h1>
    <TheUsersTable :users="users"/>
  </div>
</template>
<script>
  import {mapActions,mapMutations} from "vuex";
  import {GET_USERS, SET_USERS} from "@/store/users";
  import TheUsersTable from "@/components/TheTable/MainTables/TheUsersTable";

  export default{
    components: {TheUsersTable},
    props:{
        id:{
          type:[Number,String],
          required:true
        }
      },
      methods:{
        ...mapActions([GET_USERS]),
        ...mapMutations([SET_USERS])
      },
      created() {
        this[GET_USERS]({course_id:this.id})
        const users=[
            {id:1,full_name:'Тестовый Пользователь 1',avatar:'http://localhost:8000/users/files/avatars/bkg-login.jpg',email:'test@test.kz',},
            {id:2,full_name:'Тестовый Пользователь 2',avatar:'http://localhost:8000/users/files/avatars/bkg-login.jpg',email:'test@test.kz',},
            {id:3,full_name:'Тестовый Пользователь 3',avatar:'http://localhost:8000/users/files/avatars/bkg-login.jpg',email:'test@test.kz',},
        ]
        this.users =users;
        this[SET_USERS](users)
      }
  }
</script>