<template>
  <div class="form">
    <div class="form-div px-16 py-16 grid grid-cols-2">
      <div class="text-white font-size-40px font-bold pr-10 form-text">
        Запросить полную и подробную программу обучения
      </div>
      <div class="flex flex-col gap-10">
        <div class="form__item flex flex-col gap-4 px-8 py-8">
          <InputText
            :placeholder="'Имя'"
            v-model="personName"
            :style="[isPersonName ? '' : 'border: 1px solid rgb(239 68 68);']"
            :label="'Имя'"
            is-required
          />
          <InputText
            :placeholder="'+7'"
            v-model="phone"
            :label="'Номер телефона'"
            :style="[isPhone ? '' : 'border: 1px solid rgb(239 68 68);']"
            is-phone-number
            is-required
          />
          <InputText
            type="email"
            :label="'E-mail'"
            :placeholder="'example@example.com'"
            v-model="email"
          />
          <TheButton bg="primary" class="mt-4" @click="submitForm"
            >Оставить заявку
          </TheButton>
          <p class="text-gray-500" v-if="isForm">
            {{ $t("form_request_done") }}
          </p>
          <p class="text-gray-500" v-if="isERR">Введите правильный email!</p>
          <div class="text-center font-normal text-base text-gray-500">
            Отправляя заявку, вы даёте согласие на обработку своих персональных
            данных в соответствии с
            <span class="form__approval-link"
              >политикой конфиденциальности</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/InputText.vue";
import TheButton from "@/components/Button/Button.vue";
import { mapActions } from "vuex";
import { LIKE_TO_STUDY } from "@/store/landing-form";

export default {
  components: { TheButton, InputText },
  data() {
    return {
      personName: "",
      phone: "",
      email: "",
      isPersonName: true,
      isPhone: true,
      isForm: false,
      isERR: false,
    };
  },
  methods: {
    ...mapActions([LIKE_TO_STUDY]),
    submitForm() {
      this.isCompanyName = this.companyName !== "";
      this.isPhone = this.phone !== "";
      this.isPersonName = this.personName !== "";

      if (this.email && !this.email.includes("@")) {
        this.isERR = true;
        return;
      } else {
        this.isERR = false;
      }
      if (this.isCompanyName && this.isPersonName && this.isPhone) {
        this[LIKE_TO_STUDY]({
          name: this.personName,
          contact_phone: this.phone,
          email: this.email,
        })
          .then(() => {
            this.email = "";
            this.phone = "";
            this.personName = "";

            this.isForm = true;
          })
          .catch((err) => {
            console.log(err);
          });
        setTimeout(() => {
          this.isForm = false;
        }, 4000);
      }
    },
  },
};
</script>

<style scoped>
.form {
  padding: 80px 130px;
}
.form-div {
  background: linear-gradient(70.91deg, #9978df 0%, #667eea 100%);
  border-radius: 40px;
}
.form__item {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 32px;
}
.form__item input {
  border: 1px solid #9ca3af;
  border-radius: 16px;
}
.form__btn {
  width: 100%;
  background: #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__btn-white {
  width: 100%;
  background: #fff;
  color: #786ce6;
  border: 2px solid #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__approval-link {
  color: #786ce6;
}

.text-gray-50 {
  color: #f9fafb;
}
.font-size-40px {
  font-size: 40px;
}

@media (max-width: 650px) {
  .form {
    padding: 48px 16px;
  }

  .form-div {
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  .form-text {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}
</style>
