<template>
  <ContentBlockForm :content_name="$t('presentation')" :_key="_key"  :attachments="attachments">
    <FileDropzone
        accept="application/pdf"
        secondary-label="presentation_in_pdf_no_more_than_n_mb"
        type="presentation"
        v-model="localValue"
        :key="localValue??'no_logo'"
        updateButtonLabel="update_presentation"
        deleteButtonLabel="delete_presentation"
    />
  </ContentBlockForm>
</template>
<script>
import ContentBlockForm from "@/views/resources/Form/components/ContentBlockForm";
import FileDropzone from "@/components/FileDropzone";
export default {
  components: {ContentBlockForm,FileDropzone},
  props:{
    attachments:[],
    content:{},
    _key:{
      type:String,
      required:true
    }
  },
  computed:{
    localValue:{
      get(){
        return this.content
      },
      set(val){
        this.$emit('contentChange',val)
      }
    }
  }
}
</script>