<template>
  <div class="flex flex-col md:flex-row">
    <div class="w-full">
      <span class="flex justify-between items-center mb-6 pr-6">
        <p class="text-2xl text-gray-900 font-semibold">
          {{ $t("my_courses") }}
        </p>
        <router-link class="text-base text-gray-500" to="#">{{
          $t("see_all")
        }}</router-link>
      </span>
      <div
        class="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4"
        v-if="courses.length > 0"
      >
        <CourseCard
          v-for="course in courses"
          :name="course.name"
          :specializations="course.specializations"
          :key="course.id"
          :id="course.id"
          :logo="course.logo"
          :instructors="course.instructors"
          :duration="course.duration"
          :start_date="course.start_date"
          :end_date="course.end_date"
          :destination="'overview'"
        />
      </div>
    </div>
    <!-- <div class="w-1/4 ml-2">
      <p class="text-2xl text-gray-900 font-semibold mb-6">{{$t('deadlines')}}</p>
      <div>
        <DeadlineCard
            :deadline="new Date()"
        />
      </div>
    </div> -->
  </div>
</template>
<script>
import CourseCard from "@/components/Courses/Card/CourseCard.vue";
// import DeadlineCard from "@/components/Schedule/DeadlineCard.vue"
import MyCoursesMixin from "@/views/courses/MyCourses/MyCoursesMixin";
export default {
  components: { CourseCard },
  mixins: [MyCoursesMixin],
};
</script>
