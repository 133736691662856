const baseUrl = "/api/v1/resources";
export default ($axios) => ({
  get_list(params) {
    return $axios.get(baseUrl, { params });
  },
  get_list_with_chapters(params) {
    return $axios.get(`${baseUrl}/chapters`, { params });
  },
  get_item({ id, page, ...params }) {
    return $axios.get(`${baseUrl}/${id}/page/${page}`, { params });
  },
  get_item_for_edit({ id, ...params }) {
    return $axios.get(`${baseUrl}/${id}/edit`, { params });
  },
  finish(quiz_id) {
    return $axios.post(`${baseUrl}/quiz/finish/${quiz_id}`);
  },
  answer({ question_id, answer, option_id }) {
    return $axios.post(`/api/v1/answers/`, {
      question: question_id,
      answer,
      option: option_id,
    });
  },
  submit_file(formData) {
    return $axios.post("/api/v1/answers/upload", formData);
  },
  delete_file(file_id) {
    return $axios.delete(`/api/v1/answers/files/${file_id}`);
  },
  create_attempt(resource_id) {
    return $axios.post(`${baseUrl}/quiz/start/${resource_id}`);
  },
  create_question(data) {
    return $axios.post(`${baseUrl}/question/`, data);
  },
  create_question_attachment(data) {
    return $axios.post(`${baseUrl}/question_attachment/`, data);
  },
  delete_question_attachment(id) {
    return $axios.delete(`${baseUrl}/question_attachment/${id}`);
  },
  delete_question(id) {
    return $axios.delete(`${baseUrl}/question/${id}`);
  },
  delete_answer(id) {
    return $axios.delete(`${baseUrl}/option/${id}`);
  },
  create_content(data) {
    return $axios.post(`${baseUrl}/content_block/`, data);
  },
  create_content_attachment(data) {
    return $axios.post(`${baseUrl}/content_block_attachment/`, data);
  },
  delete_content_attachment(id) {
    return $axios.delete(`${baseUrl}/content_block_attachment/${id}`);
  },
  delete_content(id) {
    return $axios.delete(`${baseUrl}/content_block/${id}`);
  },
  update_content(id, formData) {
    return $axios.patch(`${baseUrl}/media_block/${id}`, formData);
  },
  store(data) {
    return $axios.post(`${baseUrl}/`, data);
  },
  update(id, data) {
    return $axios.put(`${baseUrl}/${id}`, data);
  },
  delete_resource(id) {
    return $axios.delete(`${baseUrl}/${id}`);
  },
  create_chapter(data) {
    return $axios.post(`${baseUrl}/chapters`, data);
  },
  delete_chapter(id) {
    return $axios.delete(`${baseUrl}/chapters/${id}`);
  },
  download_statistics(params) {
    return $axios.get(`${baseUrl}/statistics/download`, { params });
  },
  get_statistics(params) {
    return $axios.get(`${baseUrl}/statistics/`, { params });
  },
  get_next_exams(link) {
    return $axios.get(`${baseUrl}${link}`);
  },
  get_landing_page_news() {
    return $axios.get(`${baseUrl}/landing_page_news/`);
  },
  get_landing_page_news_by_id(id) {
    return $axios.get(`${baseUrl}/landing_page_news/${id}`);
  },
  get_landing_page_popular_courses() {
    return $axios.get(`${baseUrl}/landing_page_popular_courses/`);
  },
  get_landing_page_student_reviews() {
    return $axios.get(`${baseUrl}/landing_page_reviews/`);
  },
  send_landing_page_request(data) {
    return $axios.post(
      "/api/v1/application_forms/want_to_learn_application/",
      data
    );
  },
  alter_resource_type(id, data) {
    return $axios.put(`${baseUrl}/alter_type/${id}`, data);
  },
});
