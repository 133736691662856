<template>
  <ResourceBlockCard>
    <QuestionText :text="content" />
    <audio
      controls
      :src="content"
      class="w-full mb-6"
      controlsList="nodownload"
    >
      Your browser does not support the
      <code>audio</code> element.
    </audio>
    <AttachmentBlock :attachments="attachments" />
    <div
      v-for="question_answer in available_answers"
      :key="`answer_${question_answer.id}`"
      class="bg-gray-50 p-2 flex place-items-center mb-2 rounded-l"
    >
      <input
        type="radio"
        v-model="answer"
        :value="question_answer.id"
        :id="`answer_${question_answer.id}`"
        :disabled="is_disabled"
        :class="{
          'text-green-600': is_disabled && is_correct,
          'text-red-600': is_disabled && !is_correct,
        }"
      />
      <label class="ml-2" :for="`answer_${question_answer.id}`">
        {{ question_answer.text }}
      </label>
    </div>
  </ResourceBlockCard>
</template>
<script>
import CommonQuestionMixin from "@/components/Resources/ResourceBlock/Questions/CommonQuestionMixin";
import QuestionText from "@/components/Resources/ResourceBlock/Questions/QuestionText";
import ResourceBlockCard from "@/components/Resources/ResourceBlock/ResourceBlockCard";
import AttachmentBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
export default {
  components: { AttachmentBlock, ResourceBlockCard, QuestionText },
  mixins: [CommonQuestionMixin],
};
</script>
