<template>
  <div>
    <TheTable class="mt-8">
      <TheTableHead>
        <TheTableHeadCell>
          <span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
          >
            {{ $t("attempt_date") }}
          </span>
        </TheTableHeadCell>
        <TheTableHeadCell>
          <span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
          >
            {{ $t("login_email") }}
          </span>
        </TheTableHeadCell>
        <TheTableHeadCell>
          <span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
          >
            {{ $t("ip_address") }}
          </span>
        </TheTableHeadCell>
        <TheTableHeadCell>
          <span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
          >
            {{ $t("status") }}</span
          >
        </TheTableHeadCell>
      </TheTableHead>
      <TheTableBody>
        <template v-for="row of login_attempts" :key="row.id">
          <TheTableRow>
            <TheTableRowCell>
              <div class="text-sm leading-5 font-normal">
                <span class="text-sm leading-5 font-medium text-gray-900">
                  {{
                    `${new Date(row.datetime).toLocaleDateString("ru-RU", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}, ${new Date(row.datetime).toLocaleTimeString("ru-RU", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}`
                  }}
                </span>
              </div>
            </TheTableRowCell>
            <TheTableRowCell class="w-1/5">
              <div class="text-sm leading-5 font-normal">
                <span class="text-sm leading-5 font-medium text-gray-900">
                  {{ row.email }}
                </span>
              </div>
            </TheTableRowCell>
            <TheTableRowCell class="w-1/5">
              <div class="flex items-center gap-2">
                <span class="text-sm leading-5 font-medium text-gray-900">
                  {{ row.ip_address }}
                </span>
              </div>
            </TheTableRowCell>
            <TheTableRowCell class="w-1/5">
              <div class="flex items-center gap-2">
                <span
                  class="text-sm leading-5 font-medium"
                  :class="{
                    'text-green-500': !!row.status,
                    'text-red-500': !row.status,
                  }"
                >
                  {{ row.status ? $t("success_attempt") : $t("fail_attempt") }}
                </span>
              </div>
            </TheTableRowCell>
          </TheTableRow>
        </template>
      </TheTableBody>
    </TheTable>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GET_LOGIN_ATTEMPTS } from "@/store/users";
import TheTable from "@/components/TheTable/TheTable";
import TheTableHead from "@/components/TheTable/TheTableHead";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell";
import TheTableBody from "@/components/TheTable/TheTableBody";
import TheTableRow from "@/components/TheTable/TheTableRow";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell";

export default {
  components: {
    TheTable,
    TheTableHead,
    TheTableHeadCell,
    TheTableBody,
    TheTableRow,
    TheTableRowCell,
  },
  data() {
    return {
      login_attempts: [],
    };
  },
  async mounted() {
    this.login_attempts = await this[GET_LOGIN_ATTEMPTS]();
  },
  methods: {
    ...mapActions([GET_LOGIN_ATTEMPTS]),
  },
};
</script>
