<template>
    <TheResourcesPageHeader/>
    <main class="pt-16 bg-gray-200 min-h-screen pb-14	">
        <router-view></router-view>
    </main>
  <TheResourcesPageFooter/>
<!--    <TheQuizPageFooter />-->
</template>
<script>
import TheResourcesPageHeader from "@/components/ThePageHeader/TheResourcesPageHeader";
import TheResourcesPageFooter from "@/components/ThePageFooter/TheResourcesPageFooter";
export default {
  components: {TheResourcesPageHeader,TheResourcesPageFooter}
}
</script>