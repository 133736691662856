<script>
export default {};
</script>

<style></style>

<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_2477_20378)">
      <path
        d="M17.5 9.33367H20.3058C20.9895 9.33356 21.6648 9.48366 22.284 9.77336C22.9033 10.063 23.4513 10.4853 23.8893 11.0101C24.3274 11.535 24.6448 12.1497 24.819 12.8107C24.9933 13.4718 25.0202 14.1631 24.8978 14.8357L23.8362 20.669C23.6406 21.7439 23.0741 22.716 22.2354 23.4162C21.3967 24.1163 20.339 24.5 19.2465 24.5003H9.33333V10.5003L11.4578 3.74767C11.8032 2.94267 12.6945 2.21233 13.8192 2.42933C15.603 2.77117 17.5 4.16067 17.5 6.417V9.33367ZM7 10.5003C6.07174 10.5003 5.1815 10.8691 4.52513 11.5255C3.86875 12.1818 3.5 13.0721 3.5 14.0003V21.0003C3.5 21.9286 3.86875 22.8188 4.52513 23.4752C5.1815 24.1316 6.07174 24.5003 7 24.5003V10.5003Z"
        fill="#FFCF74"
      />
    </g>
    <defs>
      <clipPath id="clip0_2477_20378">
        <rect width="28" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
