<template>
  <div class="flex flex-col">
    <div class="thin-scrollbar -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
        <div
          class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-300">
            <slot />
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>

<style scoped>
.thin-scrollbar::-webkit-scrollbar {
  height: 6px;
}

.thin-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
</style>
