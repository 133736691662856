<template>
  <Menu as="div" class=" relative">
    <MenuButton class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none  ">
      {{ $t(activeLocale) }}
    </MenuButton>
    <TheTransition >
      <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
        <MenuItem
            v-for="locale in locales"
            :key="locale"
        >
          <TheButton
              class="block text-gray-700 w-full"
              :bg="activeLocale === locale?'primary':'link'"
              size="sm"
              @click="setLocale(locale)"
              >{{ $t(locale) }}</TheButton>
        </MenuItem>
      </MenuItems>
    </TheTransition>
  </Menu>
</template>
<script>
import TheTransition from "@/components/TheTransition";
import TheButton from "@/components/Button/Button";
import {Menu, MenuButton, MenuItem, MenuItems} from "@headlessui/vue";
import TheLocaleSelectorMixin from "@/components/TheLocaleSelector/TheLocaleSelectorMixin";

export default {
  components: {TheTransition, TheButton ,Menu,MenuButton,MenuItems,MenuItem},
  mixins:[TheLocaleSelectorMixin]
}
</script>