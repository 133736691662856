export default [
    {
        name:"ResourcesLayout",
        path:'',
        component:async ()=>require('../layouts/ResourcesLayout'),
        children:[
            {
                name:'ResourcesPassView',
                path:'/resources/:id/pass',
                props:true,
                component:async ()=>require('../views/resources/PassView')
            },
        ],
    },

    {
        name:'ResourcesAdminLayout',
        component:async ()=>require('../layouts/CourseAdminLayout'),
        children: [
            {
                name:'ResourcesForm',
                path:'/resources/form',
                component:async ()=>require('../views/resources/Form/ResourcesForm')
            },
            {
                name:'ResourcesPreview',
                path:'/resources/preview',
                props:true,
                component:async ()=>require('../views/resources/Form/PreviewPage')
            },
            {
                name:'ResourceEdit',
                path:'/courses/:id/resources/form',
                props:true,
                component:async ()=>require('../views/courses/CourseResourcesEdit/CourseResourcesEdit')
            },
        ]
    }
]