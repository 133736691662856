<template>
  <div
      class="rounded-md p-3 pl-8 font-medium text-base leading-6 flex gap-3 items-center"
      :class="alert.variant === 'success' && 'bg-indigo-600 text-white'"
      :style="{ backgroundColor: alert.variant === 'error' && '#dc3545' }"
  >
    <div class="w-10 h-10 grid place-items-center bg-indigo-800 rounded-lg">
      <CheckCircleIcon class="w-6 h-6" />
    </div>
    <div class="mr-auto">{{ alert.text }}</div>
    <XIcon class="w-6 h-6 cursor-pointer" @click="handleCloseButton" />
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields';
  import { CheckCircleIcon, XIcon } from '@heroicons/vue/outline';
  import { mapActions } from 'vuex';
  import { CLOSE_ALERT } from '@/store/core';

  export default {
    name: 'alert-component',
    components: { CheckCircleIcon, XIcon },
    computed:{
      ...mapFields(['core.alert'])
    },
    methods: {
      ...mapActions([CLOSE_ALERT]),
      handleCloseButton() {
        this[CLOSE_ALERT]();
      },
    },
  }
</script>