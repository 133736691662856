import { mapActions } from "vuex";
import { GET_LANDING_FEEDBACKS } from "@/store/landing-feedback";

export default {
  data() {
    return {
      student_feedbacks: [],
    };
  },
  computed: {
    landingFeedbacks() {
      return this.$store.getters.landing_feedbacks;
    },
  },
  async created() {
    await this.getAllFeedbacks();
  },
  methods: {
    ...mapActions([GET_LANDING_FEEDBACKS]),
    async getAllFeedbacks() {
      await this[GET_LANDING_FEEDBACKS]();
    },
  },
};
