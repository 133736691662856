export default {
    props:{
        modelValue:{},
        optionComponentName:{
            type:String,
            default:'DefaultSelectorOption'
        },
        label:{
            type:String,
            default:"full_name"
        },
        multiple:{
            type:Boolean,
            default:false
        },
        placeholder:{
            type:String,
            default:'select'
        }
    },
    data:()=>({
        options:[],
        action:''
    }),
    methods:{
        async getOptions(ACTION,payload={}){
            this.options = await  this.$store.dispatch(ACTION,payload)
        }
    },
    beforeMount() {
        this.getOptions(this.action)
    },
    computed:{
        localValue:{
            get(){
                return this.modelValue
            },
            set(newVal){
                this.$emit('update:modelValue',newVal)
            }
        }
    }
}