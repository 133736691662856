<template>
  <div class="flex flex-row place-content-between p-3 border-2 border-gray-300 rounded-xl place-items-center">
    <div class="flex flex-row">
      <img :src="logo" class="rounded-xl mr-3" style="width:108px"/>
      <div class="flex flex-col align-middle place-content-around flex-1">
        <div class="text-sm leading-5 font-medium text-gray-900">{{name}}</div>
      </div>
    </div>
    <slot name="rightPlace"/>
  </div>
</template>
<script>
import CourseCard from "@/components/Courses/Card/CourseCard";

export default {
  mixins:[CourseCard],
}
</script>