<template>
  <Listbox as="div" v-model="selected">
    <div class="relative">
      <div class="inline-flex rounded-md divide-x divide-gray-300">
        <div class="relative z-0 inline-flex rounded-md border-gray-300">
          <div
              class="relative inline-flex items-center border-gray-300 bg-transparent
               py-2 pl-3 pr-4 border rounded-l-md text-gray-500"
          >
            <p class="ml-2.5 text-sm font-medium">{{ $t(activeLocale) }}</p>
          </div>
          <ListboxButton
            class="relative inline-flex items-center bg-transparent p-2 rounded-l-none rounded-r-md
             text-sm font-medium text-gray-500 focus:outline-none focus:z-10
              focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 border border-gray-300"
          >
            <ChevronDownIcon class="h-5 w-5 text-gray-500" aria-hidden="true" />
          </ListboxButton>
        </div>
      </div>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <ListboxOptions
          class="origin-top-right absolute z-10 left-11 bottom-11 mt-2 w-72 rounded-md shadow-lg overflow-hidden
           bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <ListboxOption
            v-for="locale in locales"
            :key="locale"
            :value="locale"
          >
            <li
              @click="setLocale(locale)"
              :class="[activeLocale === locale ? 'text-white bg-indigo-500' : 'text-gray-900', 'cursor-default ' +
               'select-none relative p-4 text-sm']">
              <div class="flex flex-col">
                <div class="flex justify-between">
                  <p :class="activeLocale === locale ? 'font-semibold' : 'font-normal'">
                    {{ $t(locale) }}
                  </p>
                </div>
              </div>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
<script>
import TheLocaleSelectorMixin from "@/components/TheLocaleSelector/TheLocaleSelectorMixin";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/solid'

export default {
  components: {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    ChevronDownIcon,
  },
  mixins:[TheLocaleSelectorMixin]
}
</script>
