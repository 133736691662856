<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 6.99961L2.032 4.61561L2.4 13.3996L0.8 13.3996L1.184 4.27161L-2.48698e-08 3.79961L8 0.599609L16 3.79961L8 6.99961ZM8 2.99961C7.56 2.99961 7.2 3.17561 7.2 3.39961C7.2 3.62361 7.56 3.79961 8 3.79961C8.44 3.79961 8.8 3.62361 8.8 3.39961C8.8 3.17561 8.44 2.99961 8 2.99961ZM8 7.79961L12.456 6.01561C13.024 6.76761 13.416 7.67161 13.544 8.65561C13.304 8.62361 13.056 8.59961 12.8 8.59961C10.76 8.59961 8.976 9.69561 8 11.3276C7.5046 10.4962 6.80179 9.80771 5.96038 9.32951C5.11897 8.85131 4.1678 8.5998 3.2 8.59961C2.944 8.59961 2.696 8.62361 2.456 8.65561C2.584 7.67161 2.976 6.76761 3.544 6.01561L8 7.79961Z"
      fill="#7A7DD7"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
