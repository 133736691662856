<template>
  <TheSelect
    :label="label"
    v-model="localValue"
    :options="options"
    :optionComponentName="optionComponentName"
    :multiple="multiple"
    :placeholder="placeholder"
  />
</template>

<script>
import TheSelect from "@/components/Selectors/TheSelect";
import { GET_CERTIFICATE_TYPES } from "@/store/certificates";
import TheSelectMixin from "@/components/Selectors/TheSelectMixin";

export default {
  components: { TheSelect },
  mixins: [TheSelectMixin],
  async created() {
    this.action = GET_CERTIFICATE_TYPES;
  },
};
</script>
