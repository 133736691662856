<template>
    <TheCorePageHeader>
        <div class="w-full text-center text-2xl leading-8 font-semibold">{{$t('assessment')}}</div>
    </TheCorePageHeader>
    <main class="pt-7 bg-gray-200 min-h-screen pb-14">
        <router-view></router-view>
    </main>
<!--    <TheQuizPageFooter />-->
</template>
<script>
import TheCorePageHeader from "@/components/ThePageHeader/TheCorePageHeader.vue";
export default {
  components: { TheCorePageHeader }
}
</script>