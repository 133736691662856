<template>
  <div>
    <span @click="getBack">&lt; {{$t('back')}}</span>
    <TheH1 class="mt-4">{{$t('restore_password')}}</TheH1>
    <div class="text-lg leading-6 font-semibold mt-10">
      {{$t('restore_password_email_sent')}}
    </div>
  </div>
</template>
<script>
import TheH1 from "@/components/Headers/TheH1";
import TheEmailSentNotificationMixin
  from "@/views/auth/ForgotPassword/components/TheEmailSentNotification/TheEmailSentNotificationMixin";
export default {
  components: {TheH1},
  mixins:[TheEmailSentNotificationMixin]
}
</script>
