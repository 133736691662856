<script>
import { defineComponent } from "vue";
import TheModal from "@/components/TheModal/TheModal.vue";
import AvatarPlaceholder from "@/views/courses/CourseEdit/Attendance/AvatarPlaceholder.vue";

export default defineComponent({
  name: "AttendanceModal",
  components: { AvatarPlaceholder, TheModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
      default: () => ({ id: null, first_name: "", last_name: "" }),
    },
  },
  data() {
    return {
      studentName: "",
      studentInfoLeft: [
        {
          label: "ID потока",
          info: "123456",
        },

        {
          label: "Электронная почта",
          info: "sergey.davydov@gmail.com",
        },

        {
          label: "Номер телефона",
          info: "+777",
        },
      ],
      studentInfoRight: [
        {
          label: "Присутствовал",
          info: "22",
        },

        {
          label: "Отсутствовал",
          info: "2",
        },

        {
          label: "Посещаемость",
          info: "90%",
        },
      ],
    };
  },
  methods: {
    handleModalClose() {
      this.$emit("close");
    },
  },
  mounted() {
    this.studentName = this.student.first_name + " " + this.student.last_name;
  },
});
</script>

<template>
  <TheModal :show="show" @close="handleModalClose"
    ><div class="attendance__main flex flex-col gap-10">
      <div class="flex justify-between items-center">
        <div class="flex gap-4 items-center">
          <div class="attendance__avatar flex items-center">
            <img
              :src="student?.user_details?.avatar"
              v-if="student?.user_details?.avatar"
            />
            <AvatarPlaceholder v-else :student="student?.user_details" />
          </div>
          <div class="attendance__name">
            {{
              !!student?.user_details
                ? student?.user_details?.first_name +
                  " " +
                  student?.user_details?.last_name
                : "Loading..."
            }}
          </div>
        </div>
        <div class="cursor-pointer" @click="handleModalClose">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_2860_86155)">
              <rect width="24" height="24" rx="6" fill="white" />
              <path
                d="M6 18L18 6M6 6L18 18"
                stroke="#9CA3AF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_2860_86155">
                <rect width="24" height="24" rx="6" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
      <div class="flex gap-10">
        <div class="flex flex-col gap-3 attendance__info">
          <div class="flex flex-col gap-2">
            <div class="attendance__info-label">ID потока</div>
            <div class="attendance__info-text">
              {{ student?.course_group_details?.id }}
            </div>
            <hr class="attendance__line" />
          </div>
          <div class="flex flex-col gap-2">
            <div class="attendance__info-label">Электронная почта</div>
            <div class="attendance__info-text">
              {{ student?.user_details?.email }}
            </div>
            <hr class="attendance__line" />
          </div>
          <div class="flex flex-col gap-2">
            <div class="attendance__info-label">Номер телефона</div>
            <div class="attendance__info-text">
              {{ student?.user_details?.phone_number || "-" }}
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-3 attendance__info">
          <div class="flex flex-col gap-2">
            <div class="attendance__info-label">Присутствовал</div>
            <div class="attendance__info-text">
              {{ student?.attendance_true_count }}
            </div>
            <hr class="attendance__line" />
          </div>
          <div class="flex flex-col gap-2">
            <div class="attendance__info-label">Отсутствовал</div>
            <div class="attendance__info-text">
              {{ student?.attendance_false_count }}
            </div>
            <hr class="attendance__line" />
          </div>
          <div class="flex flex-col gap-2">
            <div class="attendance__info-label">Посещаемость</div>
            <div class="attendance__info-text">
              {{ student?.attendance_true_percentage }} %
            </div>
          </div>
        </div>
      </div>
    </div></TheModal
  >
</template>

<style scoped>
.attendance__main {
  padding: 48px 32px;
}
.attendance__avatar,
img {
  width: 48px;
  height: 48px;
  border-radius: 24px;
  flex-shrink: 0;
  flex-grow: 0;
}
.attendance__name {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #111827;
}
.attendance__line {
  background: #e5e7eb;
}
.attendance__info {
  flex-basis: 50%;
}
.attendance__info-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #6b7280;
}
.attendance__info-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #111827;
}
</style>
