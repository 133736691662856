<template>
  <TheCard>
    <div class="flex place-items-center -mb-1">
      <span class="text-lg leading-7 font-semibold text-gray-900 pr-1">
        {{ score }}</span
      >
      <star-rating
        :inline="true"
        :show-rating="false"
        :star-size="20"
        :read-only="true"
        :rating="score"
        :rounded-corners="true"
      />
    </div>
    <div class="mb-3">
      <span class="text-xs leading-4 font-medium text-gray-600">
        ({{ $tc("votes_amount", votes, { amount: votes }) }})
      </span>
    </div>
    <div class="text-sm leading-5 font-normal">
      <div class="flex place-items-center mb-1 gap-2 items-center">
        <ClockIcon class="h-4" />
        {{ $tc("hours", duration_hours * 1, { amount: duration_hours }) }}
      </div>
      <div class="flex place-items-center mb-1 gap-2 items-center">
        <StarIcon class="h-4" />
        {{ skill_level }}
      </div>
      <div class="flex place-items-center mb-1 gap-2 items-center">
        <BookOpenIcon class="h-4" />
        {{ $tc("chapters_amount", chapters_count, { amount: chapters_count }) }}
        <!--        {{$t('and')}}-->
        <!--        {{$tc('subchapters_amount',resources_count,{amount:resources_count})}}-->
      </div>
      <!--      <div class="flex place-items-center mb-1">-->
      <!--        <ClipboardCheckIcon class="h-4"/>-->
      <!--        {{$tc('quizzes_amount',quizzes_count,{amount:quizzes_count})}}-->
      <!--      </div>-->
      <div
        class="flex place-items-center mb-1 gap-2"
        v-if="is_certificate_given"
      >
        <AcademicCapIcon class="h-4" />
        {{ $t("certificate_by_the_end") }}
      </div>
    </div>
  </TheCard>
</template>
<script>
import OverviewCardMixin from "@/components/Courses/OverviewCard/OverviewCardMixin";
import {
  AcademicCapIcon,
  ClockIcon,
  StarIcon,
  BookOpenIcon,
} from "@heroicons/vue/outline";
import StarRating from "vue-star-rating";
import TheCard from "@/components/TheCard/TheCard";

export default {
  components: {
    TheCard,
    AcademicCapIcon,
    BookOpenIcon,
    StarIcon,
    ClockIcon,
    StarRating,
  },

  mixins: [OverviewCardMixin],
};
</script>
