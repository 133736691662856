<template>
  <div id="app" style="margin-bottom: 100px" class="sm:p-4">
    <div class="carousel-container">
      <div class="flex carousel-inner-container sm:flex-col">
        <div class="transition--comp">
          <div>
            <component
              v-for="slide in slides"
              :key="slide.id"
              :is="slide.comp"
              v-show="slide.active"
            ></component>
          </div>
        </div>

        <div class="flex justify-between items-center adaptive-carousel2">
          <div class="flex gap-4 boxes">
            <div
              v-for="box in boxes"
              :key="box.id"
              @click="changeSlide(box.id)"
              :class="{
                'colored-box': box.active,
                'uncolored-box': !box.active,
              }"
            ></div>
          </div>

          <div class="carousel-controls flex gap-3">
            <div @click="previous" class="cursor-pointer">
              <CourseTeachersButton>
                <svg
                  :class="{ hover: hoverLeft }"
                  @mouseover="hoverLeft = true"
                  @mouseleave="hoverLeft = false"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                    :fill="hoverLeft ? 'white' : '#7A69EE'"
                    transform="rotate(180, 14, 14)"
                  />
                </svg>
              </CourseTeachersButton>
            </div>
            <div @click="next" class="cursor-pointer">
              <CourseTeachersButton>
                <svg
                  :class="{ hover: hoverRight }"
                  @mouseover="hoverRight = true"
                  @mouseleave="hoverRight = false"
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                    :fill="hoverRight ? 'white' : '#7A69EE'"
                  />
                </svg>
              </CourseTeachersButton>
            </div>
          </div>
        </div>
        <div class="transition--form flex-grow-0 flex-shrink-0">
          <TheLandingStartCourseForm
            :formData="formData"
            @form-data-updated="handleFormDataUpdate"
          />
        </div>
      </div>
      <div class="flex justify-between items-center adaptive-carousel">
        <div class="flex gap-4">
          <div
            class="cursor-pointer"
            v-for="box in boxes"
            :key="box.id"
            @click="changeSlide(box.id)"
            :class="{
              'colored-box': box.active,
              'uncolored-box': !box.active,
            }"
          ></div>
        </div>

        <div class="carousel-controls flex gap-4">
          <div @click="previous" class="cursor-pointer">
            <CourseTeachersButton>
              <svg
                :class="{ hover: hoverLeft }"
                @mouseover="hoverLeft = true"
                @mouseleave="hoverLeft = false"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                  :fill="hoverLeft ? 'white' : '#7A69EE'"
                  transform="rotate(180, 14, 14)"
                />
              </svg>
            </CourseTeachersButton>
          </div>
          <div @click="next" class="cursor-pointer">
            <CourseTeachersButton>
              <svg
                :class="{ hover: hoverRight }"
                @mouseover="hoverRight = true"
                @mouseleave="hoverRight = false"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                  :fill="hoverRight ? 'white' : '#7A69EE'"
                />
              </svg>
            </CourseTeachersButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLandingStartCourseForm from "./SliderComponents/TheLandingStartCourseForm.vue";
import ComponentOne from "./SliderComponents/ComponentOne.vue";
import ComponentFive from "./SliderComponents/ComponentFive.vue";
import ComponentSix from "./SliderComponents/ComponentSix.vue";
import ComponentSeven from "@/views/core/Landing/SliderComponents/ComponentSeven.vue";
import CourseTeachersButton from "@/views/core/Landing/Courses/CourseTeachersButton.vue";
import SlideYDownTransition from "vue2-transitions";

// import ComponentTwo from "./SliderComponents/ComponentTwo.vue";
// import ComponentThree from "./SliderComponents/ComponentThree.vue";
// import ComponentFour from "./SliderComponents/ComponentFour.vue";

export default {
  name: "TheLandingCarousel",
  props: {
    formData: {
      type: Object,
    },
  },
  components: {
    CourseTeachersButton,
    TheLandingStartCourseForm,
    ComponentOne,
    ComponentFive,
    ComponentSix,
    ComponentSeven,
    SlideYDownTransition,
    // ComponentTwo,
    // ComponentThree,
    // ComponentFour,
  },
  data: () => ({
    hoverLeft: false,
    hoverRight: false,
    currentIndex: 0,
    slides: [
      { id: 0, comp: ComponentOne, active: true },
      { id: 1, comp: ComponentFive, active: false },
      { id: 2, comp: ComponentSix, active: false },
      { id: 3, comp: ComponentSeven, active: false },
    ],
    boxes: [
      { id: 0, active: true },
      { id: 1, active: false },
      { id: 2, active: false },
      { id: 3, active: false },
    ],
  }),
  mounted() {
    setInterval(this.next, 4800); // Change slide every 5 seconds (5000 milliseconds)
  },

  computed: {
    activeSlideIndex() {
      return this.slides.findIndex((slide) => slide.active === true);
    },
  },
  methods: {
    changeSlide(index) {
      let desiredIndex = 0;

      this.slides.forEach((slide, i) => {
        if (slide.id === index) {
          desiredIndex = i;
        }
        slide.active = slide.id === index;
      });

      this.boxes.forEach((box) => {
        box.active = box.id === index;
      });

      let movedSlides = this.slides.splice(0, desiredIndex);
      this.slides = this.slides.concat(movedSlides);
    },
    makeActive(id) {
      const activeSlide = this.slides.find((slide) => slide.active);
      const targetSlide = this.slides.find((slide) => slide.id === id);

      if (activeSlide && targetSlide && activeSlide !== targetSlide) {
        activeSlide.active = false;
        targetSlide.active = true;

        this.setBoxInActive(activeSlide.id);
        this.setBoxActive(targetSlide.id);
      }
    },

    handleFormDataUpdate(updatedFormData) {
      this.$emit("form-data-updated", {
        personName: updatedFormData.personName,
        phone: updatedFormData.phone,
        email: updatedFormData.email,
      });
    },
    setBoxInActive(val) {
      this.boxes = this.boxes.map((i) =>
        i.id === val ? { ...i, active: false } : i
      );
    },
    setBoxActive(val) {
      this.boxes = this.boxes.map((i) =>
        i.id === val ? { ...i, active: true } : i
      );
    },

    next() {
      const activeIndex = this.activeSlideIndex;
      this.slides[activeIndex].active = false;
      this.setBoxInActive(this.slides[activeIndex].id);
      this.slides[(activeIndex + 1) % this.slides.length].active = true;
      this.setBoxActive(this.slides[(activeIndex + 1) % this.slides.length].id);

      const first = this.slides.shift();
      this.slides = this.slides.concat(first);
    },

    previous() {
      const activeIndex = this.activeSlideIndex;

      this.slides[activeIndex].active = false;
      this.setBoxInActive(this.slides[activeIndex].id);

      this.slides[
        (activeIndex - 1 + this.slides.length) % this.slides.length
      ].active = true;
      this.setBoxActive(
        this.slides[(activeIndex - 1 + this.slides.length) % this.slides.length]
          .id
      );

      const last = this.slides.pop();
      this.slides = [last].concat(this.slides);
    },
  },
};
</script>

<style scoped>
#app {
  background: #fff;
  transition: all 1s;
  text-align: center;
  margin: 0 auto;
  padding: 48px 80px 72px 80px;
}
.show-transition-enter-active,
.show-transition-leave-active {
  transition: opacity 0.5s;
}

.show-transition-enter,
.show-transition-leave-to {
  opacity: 0;
}

.transition-group {
  overflow: hidden;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  border-radius: 30px;
}

.slide {
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  transition: all 0.8s;
  background-image: url("@/assets/images/landing/banner3.png");
  background-position: bottom;
  background-size: cover;
  padding: 0;
  margin-bottom: 0;
  min-height: 100%;
  width: 100%;
  border-radius: 30px;
}

.carousel-controls {
  display: flex;
  justify-content: space-between;
}
.carousel-controls__button {
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: white;
  color: gray;
  border-radius: 16px;
  border: 1px solid #7a69ee;
  font-size: 20px;
}

.uncolored-box {
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e5e7eb;
}

.colored-box {
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #5b4ccc;
  animation: @moveBox 0.5s forwards;
}

.transition--form {
  flex-basis: 45%;
}
.transition--comp {
  flex-basis: 55%;
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
  border-radius: 30px;
}
transition-group {
}

@media (max-width: 650px) {
  #app {
    padding: 16px;
  }

  .carousel-inner-container {
    flex-direction: column;
  }

  .adaptive-carousel {
    display: none;
  }
  .adaptive-carousel2 {
    display: flex;
    margin-bottom: 40px;
  }
  .carousel-controls {
    padding-top: 12px;
  }
  .boxes {
    padding-top: 16px;
  }
}

@media (min-width: 650px) {
  .adaptive-carousel {
    display: flex;
  }
  .adaptive-carousel2 {
    display: none;
  }
}

.transition--form {
  flex-basis: 45%;
  min-height: 100px; /* Adjust the min-height to fit your content */
}
</style>
