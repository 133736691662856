<template>
  <div>
    <TheTable class="mt-8">
      <TheTableHead>
        <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{
          $t("first_name")
        }}</TheTableHeadCell>
        <!--      <TheTableHeadCell>Title</TheTableHeadCell>-->
        <!--      <TheTableHeadCell>Status</TheTableHeadCell>-->
        <TheTableHeadCell>{{ $t("role") }}</TheTableHeadCell>
        <!--      <TheTableHeadCell scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">-->
        <!--        <span class="sr-only">Edit</span>-->
        <!--      </TheTableHeadCell>-->
      </TheTableHead>
      <TheTableBody>
        <TheTableRow v-for="user in users" :key="`user_${user.id}`">
          <TheTableRowCell class="pl-4 pr-3 sm:pl-6">
            <div class="flex items-center">
              <div class="flex-shrink-0">
                <UserAvatar
                  class="rounded-full flex-shrink-0"
                  :url="user.avatar"
                />
              </div>
              <div class="ml-4">
                <div class="font-medium text-gray-900">
                  {{ user.full_name }}
                </div>
                <div class="text-gray-500">{{ user.email }}</div>
              </div>
            </div>
          </TheTableRowCell>
          <!--        <TheTableRowCell>-->
          <!--          <div class="text-gray-900">{{ user.title }}</div>-->
          <!--          <div class="text-gray-500">{{ person.department }}</div>-->
          <!--        </TheTableRowCell>-->
          <!--        <TheTableRowCell>-->
          <!--          <span class="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">Active</span>-->
          <!--        </TheTableRowCell>-->
          <TheTableRowCell
            class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
          >
            {{ user.role }}
          </TheTableRowCell>
          <!--        <TheTableRowCell class="relative pl-3 pr-4 text-right  font-medium sm:pr-6">-->
          <!--          <a href="#" class="text-indigo-600 hover:text-indigo-900">-->
          <!--            Edit<span class="sr-only">, {{ person.name }}</span></a >-->
          <!--        </TheTableRowCell>-->
        </TheTableRow>
      </TheTableBody>
    </TheTable>
  </div>
</template>
<script>
import TheTable from "@/components/TheTable/TheTable";
import TheTableHead from "@/components/TheTable/TheTableHead";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell";
import TheTableBody from "@/components/TheTable/TheTableBody";
import TheTableRow from "@/components/TheTable/TheTableRow";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell";
import UserAvatar from "@/components/UserAvatar";
export default {
  components: {
    UserAvatar,
    TheTableRowCell,
    TheTableRow,
    TheTableBody,
    TheTableHeadCell,
    TheTableHead,
    TheTable,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
    showEditButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
