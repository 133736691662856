<script setup></script>

<template>
  <div class="opp-3">
    <div class="flex flex-col gap-4 justify-center">
      <div class="opp-1-title">Расписание лекций и вебинаров</div>
      <div class="opp-1-text">
        Обучающиеся также могут просматривать расписание на записанные курсы и
        отслеживать все дедлайны в удобном дэшборде
      </div>
    </div>
    <div>
      <img src="../../../../assets/images/landing/opp-3.png" />
    </div>
  </div>
</template>

<style scoped>
.opp-3 {
  background: #ffefd0;
  border-radius: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  padding: 80px 40px;
}
.opp-1-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
}
.opp-1-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 650px) {
  .opp-3 {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
