<template>
  <h1 class="text-2xl leading-8 font-semibold text-gray-900 mb-6">
    {{ $t("my_certificates") }}
  </h1>
  <ul class="flex flex-col gap-6 list-none pb-6">
    <li
      class="list-none m-0"
      v-for="certificate of certificates"
      :key="certificate.id"
    >
      <CertificateCard :certificate="certificate" />
    </li>
  </ul>
  <div class="no-certificates" v-if="!certificates.length">
    У вас еще нет сертификатов.
  </div>
</template>

<script>
import CertificateCard from "@/views/certificates/CertificateCard.vue";
import { mapActions } from "vuex";
import { GET_MY_CERTIFICATES } from "@/store/certificates";
export default {
  components: { CertificateCard },
  data() {
    return {
      certificates: [],
    };
  },
  async mounted() {
    this.certificates = await this.$store.dispatch(GET_MY_CERTIFICATES);
  },
  methods: {
    ...mapActions([GET_MY_CERTIFICATES]),
    getCertificates() {
      this.certificates = [
        {
          id: 1,
          image: null,
          course: "Программа подготовки кадров",
          duration: "20 мая 2022 - 27 сентября 2022",
          link: "https://test.alpha-education.kz",
        },
        {
          id: 2,
          image: null,
          course: "Программа подготовки кадров",
          duration: "20 мая 2022 - 27 сентября 2022",
          link: "https://test.alpha-education.kz",
        },
      ];
    },
  },
};
</script>
<style scoped>
.no-certificates {
}
</style>
