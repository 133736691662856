<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "PercentageBox",
  props: {
    total: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: "",
    },
    checkFunction: {
      type: Function,
      default: (val) => {
        return val >= 0;
      },
    },
  },
});
</script>

<template>
  <div class="task-percentage__total flex justify-between flex-1">
    <div>{{ text }}</div>
    <div>
      <span
        :class="
          checkFunction(total)
            ? 'task-percentage__total__number__correct'
            : 'task-percentage__total__number__incorrect'
        "
        >{{ total }}</span
      >/100
    </div>
  </div>
</template>

<style scoped>
.task-percentage__total {
  border-radius: 12px;
  padding: 22px;
  background: #f9fafb;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.task-percentage__total__number__incorrect {
  color: #dc2626;
}

.task-percentage__total__number__correct {
  color: #059669;
}
</style>
