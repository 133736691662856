<template>
  <!--  <h1 class="text-2xl leading-8 font-semibold text-gray-900 mb-6">-->
  <!--    {{ $t("my_certificates") }}-->
  <!--  </h1>-->
  <!--  <div class="flex flex-col gap-6">-->
  <!--    <div class="certificate flex flex-col justify-between">-->
  <!--      <div class="flex justify-between">-->
  <!--        <div class="certificate-logo">-->
  <!--          <img src="../../assets/images/landing/alpha-violet.svg" />-->
  <!--        </div>-->

  <!--        <div class="certificate-date">02.03.2024 - #123456</div>-->
  <!--      </div>-->
  <!--      <div class="flex flex-col gap-6">-->
  <!--        <div class="flex flex-col gap-1">-->
  <!--          <div class="certificate-text">Сертификат подтверждает, что</div>-->
  <!--          <div class="certificate-name">Нурсултан Кудайберген</div>-->
  <!--        </div>-->
  <!--        <div class="flex flex-col gap-1">-->
  <!--          <div class="certificate-text">прошел(а) курс по программе</div>-->
  <!--          <div class="certificate-course">«Веб-разработка»</div>-->
  <!--        </div>-->
  <!--        <div class="flex flex-col gap-1">-->
  <!--          <div class="certificate-text">Длительность курса: 26 недель</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="flex justify-between">-->
  <!--        <div class="flex gap-6 items-center">-->
  <!--          <div>-->
  <!--            <img src="../../assets/images/landing/astana-hub-cert.svg" />-->
  <!--          </div>-->
  <!--          <div>-->
  <!--            <img src="../../assets/images/landing/tech-orda-cert.svg" />-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="flex gap-4">-->
  <!--          <div><img src="../../assets/images/landing/signature.svg" /></div>-->
  <!--          <div class="flex flex-col gap-2">-->
  <!--            <div class="certificate-ceo">Ердаулет Абсаттар</div>-->
  <!--            <div class="certificate-text">CEO «‎Alpha Education»‎</div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--    <div class="certificate-white flex flex-col justify-between">-->
  <!--      <div class="flex flex-col gap-6">-->
  <!--        <div class="flex justify-between">-->
  <!--          <div class="certificate-logo">-->
  <!--            <img src="../../assets/images/landing/alpha-edu-black.svg" />-->
  <!--          </div>-->
  <!--          <div class="flex gap-9 certificate-white-title">-->
  <!--            <div class="certificate-date">Сертификат об обучении</div>-->
  <!--            <div class="certificate-date">02.03.2024 - #123456</div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="line"></div>-->
  <!--      </div>-->

  <!--      <div class="flex flex-col gap-6">-->
  <!--        <div class="flex flex-col gap-1">-->
  <!--          <div class="certificate-text">Сертификат подтверждает, что</div>-->
  <!--          <div class="certificate-name">Нурсултан Кудайберген</div>-->
  <!--        </div>-->
  <!--        <div class="flex flex-col gap-1">-->
  <!--          <div class="certificate-text">прошел(а) курс по программе</div>-->
  <!--          <div class="certificate-course">«Веб-разработка»</div>-->
  <!--        </div>-->
  <!--        <div class="flex flex-col gap-1">-->
  <!--          <div class="certificate-text">Длительность курса: 26 недель</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="flex justify-between">-->
  <!--        <div class="flex gap-6 items-center">-->
  <!--          <div>-->
  <!--            <img src="../../assets/images/landing/astana-hub-black.svg" />-->
  <!--          </div>-->
  <!--          <div>-->
  <!--            <img src="../../assets/images/landing/tech-orda-cert.svg" />-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="flex gap-4">-->
  <!--          <div><img src="../../assets/images/landing/signature.svg" /></div>-->
  <!--          <div class="flex flex-col gap-2">-->
  <!--            <div class="certificate-ceo">Ердаулет Абсаттар</div>-->
  <!--            <div class="certificate-text">CEO «‎Alpha Education»‎</div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->

  <!--    <div class="certificate flex flex-col justify-between">-->
  <!--      <div class="flex justify-between">-->
  <!--        <div class="certificate-logo">-->
  <!--          <img src="../../assets/images/landing/alpha-violet.svg" />-->
  <!--        </div>-->

  <!--        <div class="certificate-date">02.03.2024 - #123456</div>-->
  <!--        <div class="certificate-top-badge flex items-center justify-center">-->
  <!--          TOP STUDENT-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="flex flex-col gap-6">-->
  <!--        <div class="flex flex-col gap-1">-->
  <!--          <div class="certificate-text">Сертификат подтверждает, что</div>-->
  <!--          <div class="certificate-name">Нурсултан Кудайберген</div>-->
  <!--        </div>-->
  <!--        <div class="flex flex-col gap-1">-->
  <!--          <div class="certificate-text">прошел(а) курс по программе</div>-->
  <!--          <div class="certificate-course">«Веб-разработка»</div>-->
  <!--        </div>-->
  <!--        <div class="flex flex-col gap-1">-->
  <!--          <div class="certificate-text">Длительность курса: 26 недель</div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--      <div class="flex justify-between">-->
  <!--        <div class="flex gap-6 items-center">-->
  <!--          <div>-->
  <!--            <img src="../../assets/images/landing/astana-hub-cert.svg" />-->
  <!--          </div>-->
  <!--          <div>-->
  <!--            <img src="../../assets/images/landing/tech-orda-cert.svg" />-->
  <!--          </div>-->
  <!--        </div>-->
  <!--        <div class="flex gap-4">-->
  <!--          <div><img src="../../assets/images/landing/signature.svg" /></div>-->
  <!--          <div class="flex flex-col gap-2">-->
  <!--            <div class="certificate-ceo">Ердаулет Абсаттар</div>-->
  <!--            <div class="certificate-text">CEO «‎Alpha Education»‎</div>-->
  <!--          </div>-->
  <!--        </div>-->
  <!--      </div>-->
  <!--    </div>-->
  <!--  </div>-->
  <div class="text-gray-900 flex flex-col gap-4">
    <div
      v-if="showMsg && success"
      class="message-box flex justify-between items-center"
    >
      <div class="flex gap-3 items-center">
        <div>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="8" fill="#3730A3" />
            <path
              d="M17 20L19 22L23 18M29 20C29 24.9706 24.9706 29 20 29C15.0294 29 11 24.9706 11 20C11 15.0294 15.0294 11 20 11C24.9706 11 29 15.0294 29 20Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div>
          Готово! Сертификаты были отправлены студентам курса
          {{ this.courseName }}
        </div>
      </div>
      <div @click="this.showMsg = false" class="cursor-pointer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 18L18 6M6 6L18 18"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div
      v-if="showMsg && !success"
      class="message-box-error flex justify-between items-center"
    >
      <div class="flex gap-3 items-center">
        <div>Ошибка при генерации сертификатов! Попробуйте еще раз.</div>
      </div>
      <div @click="this.showMsg = false" class="cursor-pointer">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 18L18 6M6 6L18 18"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <h1>Сертификаты</h1>
    <div class="cert-gen-card flex flex-col gap-10 items-center">
      <div class="flex flex-col gap-3 items-center">
        <div>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 23.3327L35 14.9993L20 6.66602L5 14.9993L20 23.3327ZM20 23.3327L30.2652 17.6298C31.1696 19.9103 31.6667 22.3967 31.6667 24.9993C31.6667 26.1681 31.5664 27.3136 31.374 28.4274C27.0238 28.8504 23.0817 30.6671 20 33.4253C16.9183 30.6671 12.9762 28.8504 8.62603 28.4274C8.43361 27.3135 8.33333 26.168 8.33333 24.9991C8.33333 22.3967 8.83041 19.9103 9.73479 17.6298L20 23.3327ZM13.3333 33.3326V20.8327L20 17.129"
              stroke="#9CA3AF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="cert-gen-text">Генерация сертификатов</div>
      </div>
      <router-link :to="{ name: 'CertificateGen' }">
        <div class="cert-gen-button">Сгенерировать сертификаты</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { GET_CERTIFICATES } from "@/store/users";
export default {
  data() {
    return {
      courseName: null,
      success: false,
      showMsg: false,
      certificates: [{}],
    };
  },

  unmounted() {
    clearInterval(this.timerInterval);
  },
  mounted() {
    if (this.$route.query.courseName) {
      this.courseName = this.$route.query.courseName;
      this.showMsg = true;
    } else {
      this.showMsg = false;
    }

    if (this.$route.query.success) {
      this.success = this.$route.query.success === "true";
      this.showMsg = true;
    } else {
      this.success = false;
      this.showMsg = false;
    }

    this.timerInterval = setInterval(() => {
      this.showMsg = false;
    }, 3000);
  },

  methods: {
    ...mapActions([GET_CERTIFICATES]),
    getCertificates() {
      this.certificates = [
        {
          id: 1,
          image: null,
          course: "Course",
          dates: "01.12.2022 - 13.12.2022",
          link: "https://test.alpha-education.kz",
        },
        {
          id: 2,
          image: null,
          course: "Course",
          dates: "01.12.2022 - 13.12.2022",
          link: "https://test.alpha-education.kz",
        },
      ];
    },
  },
};
</script>

<style scoped>
.certificate {
  padding: 40px;
  background-image: url("../../assets/images/landing/certificate.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 600px;
  height: 60vh;
  color: #d8d3d3;
}
.certificate-logo {
  transform: translateY(-20%);
}
.certificate-date {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
}
.certificate-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
.certificate-name {
  font-size: 48px;
  font-weight: 700;
  line-height: 53px;
  letter-spacing: 0.02em;
  text-align: left;
}
.certificate-course {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  background: linear-gradient(to right, #e06be9, #b466f4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.certificate-ceo {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.certificate-white {
  padding: 40px;
  background-image: url("../../assets/images/landing/certificate-white.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 600px;
  height: 60vh;
  color: #3d3d3d;
}
.line {
  height: 1px;
  background: #dcdcdc;
}
.certificate-white-title {
  transform: translateY(30%);
}
.certificate-top-badge {
  font-size: 12px;
  font-weight: 900;
  line-height: 14.4px;
  letter-spacing: 0.03em;
  text-align: left;
  border: 2px solid #ffffff;
  border-radius: 24px;
  padding: 8px 12px;
  text-transform: uppercase;
}
h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}
.cert-gen-card {
  border-radius: 12px;
  padding: 24px;
  background: white;
}
.cert-gen-text {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
}
.cert-gen-button {
  border-radius: 6px;
  padding: 9px 17px;
  color: white;
  background: #4f46e5;
}
.message-box {
  background: #4f46e5;
  padding: 12px 32px;
  color: white;
  position: absolute;
  top: -10px; /* Moves up by 10px */
  left: -10px;
}
.message-box-error {
  background: darkred;
  padding: 12px 32px;
  color: white;
}
</style>
