<template>
  <div v-if="breadcrumbs && breadcrumbs.length" class="flex m-10">
    <div v-for="(breadcrumb, index) of breadcrumbs" :key="breadcrumb.name">
      <router-link :to="breadcrumb.path">
        <span
          class="mr-5 text-sm text-gray-500 font-medium leading-5"
          :class="{ 'text-indigo-500': index + 1 === breadcrumbs.length }"
          >{{ breadcrumb.name }}</span
        >
      </router-link>
      <span v-if="index + 1 < breadcrumbs.length" class="mr-5 text-gray-300"
        >/</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "breadcrumbs-component",
  props: ["breadcrumbs"],
};
</script>
