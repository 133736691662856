<template>
  <div class="flex gap-10 items-start pb-18">
    <div
      class="fixed top-0 left-0 w-full h-16 px-8 flex items-center gap-6 shadow-base bg-white z-50"
    >
      <router-link :to="`/news/edit/${$route.params.id}`">
        <div class="bg-indigo-600 px-2 py-2 rounded-md">
          <ArrowLeftIcon class="h-6 w-6 text-white" />
        </div>
      </router-link>
      <div class="flex-1 text-2xl leading-6 font-semibold text-gray-900">
        {{ publication.title }}
      </div>
      <div class="w-14">
        <router-link :to="`/news/edit/${$route.params.id}`">
          <XIcon class="w-6 h-6 text-gray-900" />
        </router-link>
      </div>
    </div>
    <div class="flex-1">
      <h1 class="text-2xl leading-8 font-semibold text-gray-900 mb-4">
        {{ $t("news_content") }}
      </h1>
      <draggable
        :list="contents"
        :group="{ name: 'resources' }"
        itemKey="_key"
        handle=".draggable_icon"
        :move="checkMove"
      >
        <template #item="{ element }">
          <component
            :id="element.id"
            :key="element.key"
            :type="element.type"
            :is="`${element.type}-content-block`"
            :_key="element._key || element.id"
            :content="element.content"
            :text="element.text"
            v-model="element.id"
            @remove="removeResource"
            @contentChange="(e) => setContent(element, e)"
          />
        </template>
      </draggable>
      <draggable
        :list="[]"
        @add="handleAddContent"
        :group="{ name: 'new_item', pull: 'clone', put: true }"
        ref="draggable_place"
        id="draggable_place"
        class="bg-white list-group rounded-xl p-6 mb-4 min-w-fit border-indigo-500 border-dashed border-2 flex place-content-center text-indigo-600 text-center"
      >
        <template #item="{ element }">
          <div class="hidden">
            {{ element.label }}
          </div>
        </template>
      </draggable>
    </div>
    <TheCard class="w-2/5 sticky top-22">
      <TheCardHeader>{{ $t("elements") }}</TheCardHeader>
      <div>
        <draggable
          :list="displayOptions"
          :group="{ name: 'people', pull: 'clone', put: false }"
          @choose="(e) => selectItemForDrag(e, true)"
          @end="(e) => selectItemForDrag(e, false)"
          :itemKey="id"
        >
          <template #item="{ element }">
            <div
              class="flex flex-row place-items-center pt-2 px-3 w-full cursor-pointer"
              :data-id="element.id"
            >
              <component
                :is="element.icon"
                class="h-5 w-5 text-gray-900 mr-2 flex-shrink-0"
              />
              {{ $t(element.label) }}
            </div>
          </template>
        </draggable>
      </div>
    </TheCard>
    <div
      class="fixed bottom-0 right-0 left-0 bg-white px-8 py-3 flex justify-end gap-3"
    >
      <TheButton bg="outline" @click="handlePreview">
        {{ $t("preview") }}
      </TheButton>
      <TheButton bg="primary" @click="submit">
        {{ $t("save") }}
      </TheButton>
    </div>
  </div>
</template>

<script>
import TheCard from "@/components/TheCard/TheCard";
import TheCardHeader from "@/components/TheCard/TheCardHeader";
import Draggable from "vuedraggable";
import TextIcon from "@/assets/icons/TextIcon";
import {
  ArrowLeftIcon,
  PhotographIcon,
  PlayIcon,
  VolumeUpIcon,
  XIcon,
} from "@heroicons/vue/solid";
import TextContentBlock from "@/views/core/News/components/TextContentBlock";
import ImageContentBlock from "@/views/core/News/components/ImageContentBlock";
import AudioContentBlock from "@/views/core/News/components/AudioContentBlock";
import VideoContentBlock from "@/views/core/News/components/VideoContentBlock";
import TheButton from "@/components/Button/Button";
import {
  CREATE_PUBLICATION_BLOCK,
  DELETE_PUBLICATION_BLOCK,
  GET_NEWS_PUBLICATION,
  UPDATE_NEWS_PUBLICATION,
} from "@/store/news";
import { mapActions } from "vuex";

export default {
  components: {
    TheCard,
    TheCardHeader,
    Draggable,
    "text-content-block": TextContentBlock,
    "content-content-block": TextContentBlock,
    "image-content-block": ImageContentBlock,
    "audio-content-block": AudioContentBlock,
    "video-content-block": VideoContentBlock,
    TheButton,
    XIcon,
    ArrowLeftIcon,
  },
  data() {
    return {
      selected_type: "materials",
      available_options: [
        {
          label: "text",
          icon: TextIcon,
          id: "text",
          group_name: "materials",
          initial_data: {
            content: "",
            attachments: [],
          },
        },
        {
          label: "image",
          icon: PhotographIcon,
          id: "image",
          group_name: "materials",
          initial_data: {
            content: "",
          },
        },
        {
          label: "audio",
          icon: VolumeUpIcon,
          id: "audio",
          group_name: "materials",
          initial_data: {
            content: "",
          },
        },
        {
          label: "video",
          icon: PlayIcon,
          id: "video",
          group_name: "materials",
          initial_data: {
            content: "",
          },
        },
      ],
      controlOnStart: true,
      publication: {},
      contents: [],
    };
  },
  async mounted() {
    const el = this.$refs.draggable_place.$el;
    window.el = el;
    el.innerHTML = this.$t("drag_elements_from_sidebar_for_creation");
    const data = await this[GET_NEWS_PUBLICATION](this.$route.params.id);
    this.publication = data;
    this.contents = data.content.map((c) => ({
      ...c,
      content: c.media_file,
    }));
  },
  methods: {
    ...mapActions([
      GET_NEWS_PUBLICATION,
      CREATE_PUBLICATION_BLOCK,
      DELETE_PUBLICATION_BLOCK,
      UPDATE_NEWS_PUBLICATION,
    ]),
    setSelectedType(val) {
      this.selected_type = val;
    },
    selectItemForDrag: function (evt, isDragging) {
      const item = evt.item;
      if (isDragging) {
        item.classList.add(
          "border-[1px]",
          "rounded-xl",
          "border-gray-200",
          "bg-white"
        );
      } else {
        item.classList.remove(
          "border-[1px]",
          "rounded-xl",
          "border-gray-200",
          "bg-white"
        );
      }
    },
    generateRandomKey() {
      return (
        new Date().getTime() + Math.floor(Math.random() * 10000).toString()
      );
    },
    checkMove(evt) {
      const newIdx = evt.draggedContext.futureIndex;
      const oldIdx = evt.draggedContext.index;
      this.contents.map((el) => {
        if (el._key) {
          if (el._key !== evt.draggedContext.element._key) {
            if (el.order - 1 === newIdx) {
              el.order = oldIdx + 1;
            }
          } else {
            el.order = newIdx + 1;
          }
        } else {
          if (el.id !== evt.draggedContext.element.id) {
            if (el.order - 1 === newIdx) {
              el.order = oldIdx + 1;
            }
          } else {
            el.order = newIdx + 1;
          }
        }
      });
    },
    async handleAddContent(event) {
      const elementToAddId = event.item._underlying_vm_.id;
      const initialData = event.item._underlying_vm_.initial_data ?? {};
      const new_resource = {
        type: elementToAddId,
        ...initialData,
        order: this.contents.length + 1,
      };
      const data = await this[CREATE_PUBLICATION_BLOCK]({
        newsId: this.$route.params.id,
        block: new_resource,
      });
      this.contents.push(data);
    },
    async removeResource(id) {
      await this[DELETE_PUBLICATION_BLOCK](id);
      this.contents = this.contents.filter((content) => content.id !== id);
    },
    async setContent(element, e) {
      this.contents = this.contents.map((c) =>
        c.id === element.id ? { ...c, content: e, text: e } : c
      );
    },
    async handlePreview() {
      await this[UPDATE_NEWS_PUBLICATION]({
        ...this.publication,
        content: this.contents,
      });
      this.$router.push({
        name: "NewsPreview",
        params: { id: this.$route.params.id },
      });
    },
    async submit() {
      await this[UPDATE_NEWS_PUBLICATION]({
        ...this.publication,
        content: this.contents,
      });
      this.$router.push({
        name: "NewsEdit",
        params: { id: this.$route.params.id },
      });
    },
  },
  computed: {
    displayOptions() {
      return this.available_options.filter(
        (el) => el.group_name === this.selected_type
      );
    },
  },
};
</script>
