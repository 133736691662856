<template>
  <div>
    <h1 class="text-2xl leading-8 font-semibold text-gray-900 mb-4">
      {{ $t("courses_publication") }}
    </h1>
    <div
      class="shadow-base p-6 rounded-xl w-full bg-white flex flex-col gap-4 mb-6"
    >
      <div class="flex justify-between">
        <h2 class="text-lg leading-8 font-semibold text-gray-900">
          {{ $t("popular_courses") }}
        </h2>
        <Button v-if="publishedCourses.length" bg="outline" @click="handleEdit">
          <div class="flex gap-3 items-center">
            <PencilIcon class="h-5 w-5 text-gray-500" />
            <span>{{ $t("edit") }}</span>
          </div>
        </Button>
      </div>
      <TheTable v-if="publishedCourses.length" class="mt-8">
        <TheTableHead>
          <TheTableHeadCell>
            <div class="flex items-center gap-10">
              <input
                v-if="editing"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 cursor-pointer"
                :checked="checkedCourses.length === publishedCourses.length"
                @change="handleCheckAll"
              />
              <span>{{ $t("course") }}</span>
            </div>
          </TheTableHeadCell>
        </TheTableHead>
        <TheTableBody>
          <template v-for="course of publishedCourses" :key="course.id">
            <TheTableRow>
              <TheTableRowCell>
                <div class="flex items-center gap-10">
                  <input
                    v-if="editing"
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 cursor-pointer"
                    :checked="checkedCourses.includes(course.id)"
                    @change="handleCheckCourse(course.id)"
                  />
                  <CourseCardRowSmall
                    :specializations="course.specializations"
                    :name="course.name"
                    :logo="course.logo"
                  />
                </div>
              </TheTableRowCell>
            </TheTableRow>
          </template>
        </TheTableBody>
      </TheTable>
      <div
        v-else
        class="flex-1 flex flex-col justify-center items-center w-full text-center"
      >
        <DocumentAddIcon class="h-10 w-10 text-gray-400 mb-4" />
        <div class="text-lg leading-7 font-medium text-gray-900 mb-1">
          {{ $t("add_courses") }}
        </div>
        <div class="text-sm leading-5 font-normal text-gray-500 mb-10">
          {{ $t("courses_not_added_yet") }}
        </div>
        <Button @click="handleAddCoursesModalOpen"
          >{{ $t("add_courses") }}
        </Button>
      </div>
      <div v-if="editing" class="flex justify-end gap-3">
        <Button bg="outline" @click="deleteCourse">
          <span v-if="checkedCourses.length <= 1">{{
            $t("delete_course")
          }}</span>
          <span v-else>{{ $t("delete_courses") }}</span>
        </Button>
        <Button bg="primary" @click="handleAddCoursesModalOpen">
          <span v-if="checkedCourses.length <= 1">{{
            $t("choose_another_course")
          }}</span>
          <span v-else>{{ $t("choose_other_courses") }}</span>
        </Button>
      </div>
    </div>
    <div class="shadow-base p-6 rounded-xl w-full bg-white flex flex-col gap-4">
      <h2 class="text-lg leading-8 font-semibold text-gray-900">
        {{ $t("all_courses") }}
      </h2>
      <div
        class="flex-1 flex flex-col justify-center items-center w-full text-center"
      >
        <DocumentAddIcon class="h-10 w-10 text-gray-400 mb-4" />
        <div class="text-lg leading-7 font-medium text-gray-900 mb-1">
          {{ $t("add_courses") }}
        </div>
        <div class="text-sm leading-5 font-normal text-gray-500 mb-10">
          {{ $t("courses_not_added_yet") }}
        </div>
        <Button>{{ $t("add_courses") }}</Button>
      </div>
    </div>
  </div>
  <AddCoursesModal
    :add-courses-modal-open="addCoursesModalOpen"
    :published-courses="publishedCourses"
    @close="handleAddCoursesModalClose"
    @add-courses="handleAddCourses"
  />
</template>

<script>
import { DocumentAddIcon } from "@heroicons/vue/outline";
import Button from "@/components/Button/Button";
import AddCoursesModal from "@/views/courses/CoursePublish/AddCoursesModal";
import TheTable from "@/components/TheTable/TheTable";
import TheTableHead from "@/components/TheTable/TheTableHead";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell";
import TheTableBody from "@/components/TheTable/TheTableBody";
import TheTableRow from "@/components/TheTable/TheTableRow";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell";
import CourseCardRowSmall from "@/components/Courses/Card/CourseCardRowSmall";
import { mapActions } from "vuex";
import { OPEN_ALERT } from "@/store/core";
import { PencilIcon } from "@heroicons/vue/solid";
import {
  GET_PUBLISHED_COURSES,
  PUBLISH_COURSES,
  UNPUBLISH_COURSES,
} from "@/store/courses";

export default {
  components: {
    DocumentAddIcon,
    Button,
    AddCoursesModal,
    TheTable,
    TheTableHead,
    TheTableHeadCell,
    TheTableBody,
    TheTableRow,
    TheTableRowCell,
    CourseCardRowSmall,
    PencilIcon,
  },
  data() {
    return {
      addCoursesModalOpen: false,
      publishedCourses: [],
      selectedCourses: [],
      checkedCourses: [],
      editing: false,
    };
  },
  async mounted() {
    this.publishedCourses = await this[GET_PUBLISHED_COURSES]();
  },
  methods: {
    ...mapActions([
      OPEN_ALERT,
      GET_PUBLISHED_COURSES,
      UNPUBLISH_COURSES,
      PUBLISH_COURSES,
    ]),
    handleAddCoursesModalOpen() {
      this.addCoursesModalOpen = true;
    },
    handleAddCoursesModalClose() {
      this.addCoursesModalOpen = false;
    },
    async handleAddCourses(courses) {
      await this[PUBLISH_COURSES](courses);
      this.publishedCourses = await this[GET_PUBLISHED_COURSES]();
      this.handleAddCoursesModalClose();
      this[OPEN_ALERT]({ text: this.$t("courses_successfully_published") });
    },
    handleEdit() {
      this.editing = !this.editing;
    },
    async deleteCourse() {
      await this[UNPUBLISH_COURSES](this.checkedCourses);
      this.publishedCourses = await this[GET_PUBLISHED_COURSES]();
      this.editing = false;
      this[OPEN_ALERT]({ text: this.$t("courses_successfully_unpublished") });
    },
    handleCheckCourse(courseId) {
      if (!this.checkedCourses.includes(courseId)) {
        this.checkedCourses.push(courseId);
      } else {
        this.checkedCourses = this.checkedCourses.filter((c) => c !== courseId);
      }
    },
    handleCheckAll() {
      if (this.checkedCourses.length === this.publishedCourses.length) {
        this.checkedCourses = [];
      } else {
        this.checkedCourses = this.publishedCourses.map((c) => c.id);
      }
    },
  },
};
</script>
