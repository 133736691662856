<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AvatarPlaceholder",
  props: ["student"],
});
</script>

<template>
  <div class="img-placeholder">
    {{ student?.first_name ? student?.first_name[0] : "." }}
  </div>
</template>

<style scoped>
.img-placeholder {
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9fafb;
  border: 1px solid #6b7280;
}
</style>
