import {mapFields} from "vuex-map-fields";
import {FORGOT_PASSWORD_INPUT_PASSWORD} from "@/store/auth";

export default {
    computed:{
        ...mapFields(['auth.forgot_password_form.step']),
        routeToken(){
            return this.$route.query['token']
        }
    },
    mounted() {
        if(this.routeToken){
            this.step=FORGOT_PASSWORD_INPUT_PASSWORD;
            this.token = this.routeToken;
        }
    }

}