<template>
  <BreadcrumbsComponent :breadcrumbs="breadcrumbs" />
  <FormLayout>
    <template #title>
      {{ $t("create_news") }}
    </template>
    <template #main>
      <TheCard class="flex flex-col gap-6">
        <div>
          <TheCardHeader>{{ $t("news_topic") }}</TheCardHeader>
          <InputText v-model="form.topic" :placeholder="$t('enter_topic')" />
        </div>
        <div>
          <TheCardHeader>{{ $t("topic_color") }}</TheCardHeader>
          <ColorSelector :color="form.topic_color" @change="handleColorChange" />
        </div>
      </TheCard>
      <TheCard>
        <TheCardHeader>{{ $t("heading") }}</TheCardHeader>
        <InputText v-model="form.title" :placeholder="$t('enter_heading')" />
      </TheCard>
      <TheCard>
        <TheCardHeader>{{ $t("short_description") }}</TheCardHeader>
        <div>
          <TheQuillEditorInput v-model="form.short_description" />
        </div>
      </TheCard>
      <TheCard class="mb-20">
        <TheCardHeader>{{ $t("publication_date") }}</TheCardHeader>
        <div class="flex flex-row items-center gap-4">
          <datepicker
            style="border-radius: 5px"
            v-model="form.publication_date"
            :lowerLimit="form.publication_date"
            :typeable="true"
            color="#561BFB"
            class="no-outline futura"
            :locale="ru"
          />
          <CalendarIcon class="h-6 mr-2" />
        </div>
      </TheCard>
    </template>
    <template #secondary>
      <TheCard class="px-3">
        <TheCardHeader>{{ $t("news_cover") }}</TheCardHeader>
        <FileDropzone v-model="form.cover" />
      </TheCard>
    </template>
    <template #actions>
      <div
        class="flex py-3 px-12 flex-row place-content-end absolute bottom-0 right-0 bg-white w-full border-t"
      >
        <TheButton bg="outline" class="mr-3" @click="handleCancel">{{
          $t("cancel")
        }}</TheButton>
        <TheButton bg="primary" @click="handleFormSubmit">
          {{ $t("continue") }}
        </TheButton>
      </div>
    </template>
  </FormLayout>
</template>

<script>
import BreadcrumbsComponent from "@/components/Breadcrumbs/Breadcrumbs";
import TheCard from "@/components/TheCard/TheCard";
import TheCardHeader from "@/components/TheCard/TheCardHeader";
import InputText from "@/components/InputText";
import TheQuillEditorInput from "@/components/TheQuillEditorInput";
import { CalendarIcon } from "@heroicons/vue/outline";
import Datepicker from "vue3-datepicker";
import ColorSelector from "@/views/core/News/ColorSelector";
import FileDropzone from "@/components/FileDropzone";
import FormLayout from "@/layouts/FormLayout";
import TheButton from "@/components/Button/Button";
import { mapActions } from "vuex";
import {
  CREATE_NEWS_PUBLICATION,
  GET_NEWS_PUBLICATION,
  UPDATE_NEWS_PUBLICATION,
} from "@/store/news";

export default {
  components: {
    FormLayout,
    ColorSelector,
    TheCardHeader,
    BreadcrumbsComponent,
    TheCard,
    InputText,
    TheQuillEditorInput,
    CalendarIcon,
    Datepicker,
    FileDropzone,
    TheButton,
  },
  data() {
    return {
      breadcrumbs: [],
      form: {
        publication_date: new Date(),
      },
    };
  },
  async mounted() {
    if (this.$route.query.id) {
      const data = await this[GET_NEWS_PUBLICATION](this.$route.query.id);
      this.form = {
        ...data,
        publication_date: new Date(data.publication_date),
        content: data.content.map(c => ({ ...c, content: c.media_file }))
      };
    }
    this.setBreadcrumbs();
  },
  methods: {
    ...mapActions([
      GET_NEWS_PUBLICATION,
      UPDATE_NEWS_PUBLICATION,
      CREATE_NEWS_PUBLICATION,
    ]),
    handleColorChange(newColor) {
      this.form.topic_color = newColor.color;
    },
    setBreadcrumbs() {
      this.breadcrumbs = [
        {
          path: "/news/list",
          name: this.$t("news_publication"),
        },
        {
          path: this.$route.path,
          name: this.$t("create_news"),
        },
      ];
    },
    handleCancel() {
      this.$router.back();
    },
    async handleFormSubmit() {
      if (this.form.id) {
        await this[UPDATE_NEWS_PUBLICATION](this.form);
        this.$router.push({
          name: "NewsEdit",
          params: { id: this.form.id },
        });
      } else {
        const data = await this[CREATE_NEWS_PUBLICATION](this.form);
        this.$router.push({ name: "NewsEdit", params: { id: data.id } });
      }
    },
  },
};
</script>
