<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.2004 0.398438C4.53766 0.398438 4.0004 0.935697 4.0004 1.59844V2.79844H2.80039C1.47491 2.79844 0.400391 3.87296 0.400391 5.19844V17.1985C0.400391 18.5239 1.47491 19.5985 2.80039 19.5985H17.2004C18.5259 19.5985 19.6004 18.5239 19.6004 17.1985V5.19844C19.6004 3.87296 18.5259 2.79844 17.2004 2.79844H16.0004V1.59844C16.0004 0.935697 15.4632 0.398438 14.8004 0.398438C14.1377 0.398438 13.6004 0.935697 13.6004 1.59844V2.79844H6.4004V1.59844C6.4004 0.935697 5.86314 0.398438 5.2004 0.398438ZM5.2004 6.39845C4.53766 6.39845 4.0004 6.93571 4.0004 7.59845C4.0004 8.26119 4.53766 8.79845 5.2004 8.79845H14.8004C15.4632 8.79845 16.0004 8.26119 16.0004 7.59845C16.0004 6.93571 15.4632 6.39845 14.8004 6.39845H5.2004Z"
      fill="#425FF8"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
