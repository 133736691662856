<template>
<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.998047" width="64" height="64" rx="16" fill="#7D69FC"/>
<path d="M41 43.498H23V41.398C23 38.398 29 36.748 32 36.748C35 36.748 41 38.398 41 41.398M32 25.498C33.1935 25.498 34.3381 25.9722 35.182 26.8161C36.0259 27.66 36.5 28.8046 36.5 29.998C36.5 31.1915 36.0259 32.3361 35.182 33.18C34.3381 34.0239 33.1935 34.498 32 34.498C30.8065 34.498 29.6619 34.0239 28.818 33.18C27.9741 32.3361 27.5 31.1915 27.5 29.998C27.5 28.8046 27.9741 27.66 28.818 26.8161C29.6619 25.9722 30.8065 25.498 32 25.498ZM32 19.498C32.3978 19.498 32.7794 19.6561 33.0607 19.9374C33.342 20.2187 33.5 20.6002 33.5 20.998C33.5 21.3959 33.342 21.7774 33.0607 22.0587C32.7794 22.34 32.3978 22.498 32 22.498C31.6022 22.498 31.2206 22.34 30.9393 22.0587C30.658 21.7774 30.5 21.3959 30.5 20.998C30.5 20.6002 30.658 20.2187 30.9393 19.9374C31.2206 19.6561 31.6022 19.498 32 19.498ZM42.5 19.498H36.23C35.6 17.758 33.95 16.498 32 16.498C30.05 16.498 28.4 17.758 27.77 19.498H21.5C20.7044 19.498 19.9413 19.8141 19.3787 20.3767C18.8161 20.9393 18.5 21.7024 18.5 22.498V43.498C18.5 44.2937 18.8161 45.0568 19.3787 45.6194C19.9413 46.182 20.7044 46.498 21.5 46.498H42.5C43.2956 46.498 44.0587 46.182 44.6213 45.6194C45.1839 45.0568 45.5 44.2937 45.5 43.498V22.498C45.5 21.7024 45.1839 20.9393 44.6213 20.3767C44.0587 19.8141 43.2956 19.498 42.5 19.498Z" fill="white"/>
</svg>


</template>

<script>
export default {

}
</script>

<style>

</style>