<template>
  <form @submit.prevent="submit" class="w-full">
    <TheFormHeader header="restore_password">
      <template #description>
        {{$t('restore_password_description')}}
      </template>
    </TheFormHeader>
    <InputText v-model="email" type="email">
      <template #label>
        <InputLabel class="mt-10">{{ $t('your_email') }}</InputLabel>
      </template>
    </InputText>
    <TheButton type="submit" bg="primary" class="w-full mt-10">{{$t('restore_password')}}</TheButton>
  </form>
</template>
<script>
import TheForgotPasswordFormMixin from "./TheForgotPasswordFormMixin.js";
import InputText from "@/components/InputText";
import InputLabel from "@/components/InputLabel";
import TheButton from "@/components/Button/Button";
import TheFormHeader from "@/views/auth/Common/TheFormHeader/TheFormHeader";
export default {
  components: {TheFormHeader, TheButton, InputLabel, InputText},
  mixins:[TheForgotPasswordFormMixin]
}
</script>
