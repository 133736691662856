<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <div>
    <FormLayout>
      <template #main>
        <div class="mb-20">
          <div class="mb-4">
            <span class="text-2xl text-gray-900 font-semibold">
              {{ $t("add_new_group") }}
            </span>
          </div>
          <div class="grid grid-cols-2 gap-4 mb-5">
            <TheCard>
              <template #header>{{ $t("group_name") }}</template>
              <InputText
                required
                :errors="[is_group_number ? '' : $t('enter_group_name')]"
                :placeholder="$t('enter_group_name')"
                v-model="group_number"
              />
            </TheCard>
            <TheCard>
              <TheCardHeader>
                {{ $t("course_end_date") }}
              </TheCardHeader>
              <div class="flex flex-row items-center mb-5">
                <CalendarIcon class="h-6 mr-2" />
                <datepicker
                  style="border-radius: 5px"
                  v-model="end_date"
                  :lowerLimit="start_date"
                  :typeable="false"
                  color="#561BFB"
                  class="no-outline w-100 futura"
                  :class="{
                    'border-red-300': !is_dates,
                  }"
                  :locale="ru"
                ></datepicker>
              </div>
              <p class="text-red-600 text-sm" v-if="!is_dates">
                {{ $t("enter_valid_dates") }}
              </p>
            </TheCard>
          </div>
          <GroupCard
            ref="group_card"
            :course_id="course_id"
            :group_number="group_number"
            :start_date="start_date"
            :end_date="end_date"
            :addMode="true"
          />
        </div>
      </template>
      <template #actions>
        <div
          class="flex p-3 flex-row place-content-end fixed bottom-0 right-0 bg-white w-full"
        >
          <TheButton bg="outline" class="mr-3 h-fit" @click.prevent="cancel">{{
            $t("cancel")
          }}</TheButton>
          <TheButton bg="primary" class="h-fit" @click="submit">{{
            $t("create_new_group")
          }}</TheButton>
        </div>
      </template>
    </FormLayout>
  </div>
</template>

<script>
import TheCard from "@/components/TheCard/TheCard.vue";
import InputText from "@/components/InputText.vue";
import GroupCard from "@/components/Groups/GroupCard.vue";
import TheButton from "@/components/Button/Button";
import FormLayout from "@/layouts/FormLayout";
import { mapFields } from "vuex-map-fields";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import TheCardHeader from "@/components/TheCard/TheCardHeader.vue";
import { CalendarIcon } from "@heroicons/vue/outline";
import Datepicker from "vue3-datepicker";
import { mapActions } from "vuex";
import { GET_COURSE } from "@/store/courses";
import { ru } from "date-fns/locale";
export default {
  components: {
    TheCard,
    InputText,
    GroupCard,
    FormLayout,
    TheButton,
    Breadcrumbs,
    TheCardHeader,
    CalendarIcon,
    Datepicker,
  },
  data: () => {
    return {
      course_id: null,
      group_number: "",
      start_date: "",
      end_date: "",
      is_dates: true,
      is_group_number: true,
      breadcrumbs: [],
      ru: ru,
    };
  },
  methods: {
    ...mapActions([GET_COURSE]),
    cancel() {
      this.$router.push({ name: "GroupsList", params: { id: this.course_id } });
    },
    async submit() {
      this.start_date = new Date();
      this.is_dates = this.start_date != "" && this.end_date != "";
      if (this.group_number.trim().length == 0 && !this.is_dates) {
        this.is_group_number = false;
        return;
      }
      this.$refs.group_card.addNewUsers();
    },
  },
  computed: {
    ...mapFields(["courses.course"]),
  },
  async created() {
    this.course_id = this.$route.params.id;
    await this.GET_COURSE(this.course_id);
    this.start_date = new Date(this.course.start_date);
    this.end_date = new Date(this.course.end_date);
    this.breadcrumbs = [
      {
        name: this.$t("courses"),
        path: "/courses/list",
      },
      {
        name: this.course.name,
        path: `/courses/${this.id}/edit`,
      },
      {
        name: this.$t("groups"),
        path: `/courses/${this.course_id}/groups`,
      },
      {
        name: this.$t("add_new_group"),
        path: this.$route.path,
      },
    ];
  },
  watch: {
    group_number: function () {
      if (this.group_number.trim().length == 0) {
        this.is_group_number = false;
      } else {
        this.is_group_number = true;
      }
    },
  },
};
</script>
