<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 place-content-center">
    <div class=" sm:flex-1 sm:flex sm:items-center sm:justify-around">
      <div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <a @click="pag.current_page--" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </a>
          <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" -->
          <span v-if="displayed_pages[0] != 1" class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"> ... </span>
          <a v-for="n in displayed_pages" :key="`page_${n}`" @click="pag.current_page = n" aria-current="page" 
          :class="pag.current_page == n ? 'z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium' : 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'"> {{n}} </a>
          <span v-if="pag.max_page > show_in_line && displayed_pages[show_in_line-1] != pag.max_page" class="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700"> ... </span>
          <a @click="pag.current_page++" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/solid'
export default {
  components:{
    ChevronRightIcon,ChevronLeftIcon
  },
  data:()=>{
    return {
      pag:{},
      show_in_line:5
    }
  },
  props:{
    showTotalResults:{
      type:Boolean,
      default:false
    },
    pagination:{
      type:Object,
    }
  },
  created(){
    this.pag = this.pagination
    this.pag.current_page = 1
  },
  computed:{
    displayed_pages(){
      var arr = []
      if(this.pag.max_page <= this.show_in_line){
        for(let i = 1; i <= this.pag.max_page; i++){
          arr.push(i)
        }
        return arr
      }
      if(this.pag.max_page - this.pag.current_page + 1 <= this.show_in_line){
        for(let i = this.pag.max_page - this.show_in_line + 1; i <= this.pag.max_page; i++){
          arr.push(i)
        }
        return arr
      } else {
        for(let i = this.pag.current_page; i <= this.pag.current_page + this.show_in_line - 1; i++){
          arr.push(i)
        }
        return arr
      }
    }
  },
  watch:{
    'pag.current_page':function(newPage, oldPage){
      if(newPage > this.pag.max_page){
        this.pag.current_page = this.pag.max_page
      } else if(newPage < 1){
        this.pag.current_page = 1
      } else if(newPage != oldPage){
        this.$emit('currentPageChange', this.pag.current_page)
      }
    }
  }
}
</script>