<template>
  <div
    class="border-[1px] border-gray-300 rounded-lg p-6 bg-white flex flex-col text-sm shadow-sm"
  >
    <div class="pb-6 border-b flex gap-4 items-center">
      <h3 class="font-medium">{{ $t(mailing.title) }}</h3>
      <div class="mr-auto">
        <Switch
          as="button"
          v-model="active"
          class="relative inline-flex flex-shrink-0 h-6 transition-colors duration-200 ease-in-out border-2 border-transparent rounded-full cursor-pointer w-11 focus:outline-none focus:shadow-outline"
          :class="active ? 'bg-indigo-600' : 'bg-gray-200'"
          v-slot="{ checked }"
          @click="handleSwitchClick"
        >
          <span
            class="inline-block w-5 h-5 transition duration-200 ease-in-out transform bg-white rounded-full"
            :class="{ 'translate-x-5': checked, 'translate-x-0': !checked }"
          />
        </Switch>
      </div>
      <Button
        size="sm"
        bg="outline"
        class="flex gap-2 items-center shadow-sm"
        @click="handleEditButton"
      >
        <PencilIcon class="w-4 h-4" />
        <span class="leading-4">{{ $t("edit") }}</span>
      </Button>
    </div>
    <div class="pt-6">
      <h4 class="font-medium mb-4">{{ $t("mailing_text") }}</h4>
      <p>{{ mailing.message }}</p>
    </div>
  </div>
  <TheModal :show="this.editing" @close="handleEditModalClose" class="z-50">
    <div class="p-6 flex flex-col gap-10">
      <div class="flex justify-between items-center">
        <h3 class="text-xl font-semibold">{{ $t("edit_mailing") }}</h3>
        <XIcon
          class="w-5 h-5 items-center cursor-pointer"
          @click="handleEditModalClose"
        />
      </div>
      <div class="relative">
        <div class="mb-1">{{ $t("mailing_text") }}</div>
        <div>
          <textarea
            v-model="selectedMailing.message"
            class="w-full border-[1px] border-gray-300 rounded-md mb-4 pt-2 pr-3 pl-3 h-20 resize-both min-w-full max-w-[100%]"
          />
        </div>
        <div class="flex flex-col gap-2 mb-4">
          <template
            v-for="(file, index) in mailing.attachments"
            :key="file.name"
          >
            <div
              class="flex justify-between gap-4 w-3/5 bg-gray-50 rounded-xl px-3 py-2 text-xs z-50"
            >
              <div>{{ file.name }}</div>
              <div class="flex gap-4 items-center">
                <span class="text-xs leading-4 font-medium text-gray-400">{{
                  $t("ready")
                }}</span>
                <CheckCircleIcon class="w-4 text-green-600" />
                <TrashIcon
                  class="text-gray-400 h-5 cursor-pointer"
                  @click="handleFileRemove(index)"
                />
              </div>
            </div>
          </template>
          <div
            v-if="loading"
            class="flex justify-between gap-4 w-3/5 bg-gray-50 rounded-xl px-3 py-2 text-xs z-50"
          >
            <div>{{ loadingFileName }}</div>
            <div class="flex gap-4 items-center">
              <span class="text-xs leading-4 font-medium text-gray-400"
                >{{ $t("uploading") }}...</span
              >
              <LoaderIcon class="w-3 animate-spin" />
            </div>
          </div>
        </div>
        <input type="file" id="upload-file" @change="handleFileUpload" hidden />
        <Button
          size="sm"
          bg="outline"
          class="flex gap-2"
          @click="handleFileButton"
          :disabled="loading"
        >
          <PaperClipIcon class="w-3 h-3" />
          <span>{{ $t("attach_file") }}</span>
        </Button>
      </div>
      <div class="flex items-center justify-end gap-3 items-center">
        <Button size="sm" bg="outline" @click="handleEditModalClose">{{
          $t("cancel")
        }}</Button>
        <Button size="sm" bg="primary" @click="handleSaveButton">{{
          $t("save_changes")
        }}</Button>
      </div>
    </div>
  </TheModal>
  <TheModal :show="disabling" @close="handleDisableModalClose" class="z-50">
    <div class="p-6">
      <div class="flex gap-4 items-start">
        <div
          class="w-10 h-10 rounded-full bg-red-100 flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="#DC2626"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
        </div>
        <div class="flex flex-col gap-2 mr-auto">
          <h3 class="text-xl font-medium">{{ $t("disable_mailing") }}</h3>
          <p class="text-sm text-gray-500">{{ $t("disable_mailing_tip") }}</p>
        </div>
        <XIcon class="w-5 h-5 items-center" />
      </div>
      <div class="flex gap-3 mt-4 mb-5 justify-end">
        <Button size="sm" bg="outline" @click="handleCancelDisabling">{{
          $t("cancel")
        }}</Button>
        <Button size="sm" bg="danger" @click="handleDisabling">{{
          $t("disable")
        }}</Button>
      </div>
    </div>
  </TheModal>
</template>

<script>
import { Switch } from "@headlessui/vue";
import Button from "@/components/Button/Button";
import { PencilIcon } from "@heroicons/vue/solid";
import { mapActions } from "vuex";
import {
  ADD_ATTACHMENT,
  DELETE_MAILING_ATTACHMENT,
  SAVE_MAILING,
} from "@/store/mailings";
import TheModal from "@/components/TheModal/TheModal";
import {
  PaperClipIcon,
  XIcon,
  CheckCircleIcon,
  TrashIcon,
} from "@heroicons/vue/outline";
import { mapFields } from "vuex-map-fields";
import LoaderIcon from "@/assets/icons/LoaderIcon";

export default {
  props: ["mailing"],
  components: {
    Button,
    Switch,
    PencilIcon,
    TheModal,
    XIcon,
    PaperClipIcon,
    CheckCircleIcon,
    LoaderIcon,
    TrashIcon,
  },
  data() {
    return {
      text: "",
      active: this.mailing.is_enabled,
      editing: false,
      disabling: false,
      selectedMailing: null,
      loadingFileName: null,
    };
  },
  computed: {
    ...mapFields(["mailings.loading"]),
  },
  methods: {
    ...mapActions([SAVE_MAILING, ADD_ATTACHMENT, DELETE_MAILING_ATTACHMENT]),
    handleEditButton() {
      this.selectedMailing = { ...this.mailing };
      this.editing = true;
    },
    handleEditModalClose() {
      this.editing = false;
    },
    handleSaveButton() {
      this[SAVE_MAILING]({ ...this.selectedMailing });
      this.handleEditModalClose();
    },
    handleFileButton() {
      const fileInput = document.querySelector("#upload-file");
      fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      this.loadingFileName = file.name;
      this[ADD_ATTACHMENT]({
        ...this.mailing,
        attachments: [...this.mailing.attachments, file],
      }).then((file) => {
        this.selectedMailing = {
          ...this.selectedMailing,
          attachments: [...this.selectedMailing.attachments, { ...file }],
        };
      });
    },
    handleFileRemove(fileIndex) {
      const file = this.selectedMailing.attachments[fileIndex];
      this[DELETE_MAILING_ATTACHMENT]({
        mailing: this.selectedMailing,
        fileId: file.id,
      });
      this.selectedMailing = {
        ...this.selectedMailing,
        attachments: [
          ...this.selectedMailing.attachments.filter(
            (attachment) => attachment.id !== file.id
          ),
        ],
      };
    },
    handleDisableModalClose() {
      this.disabling = false;
    },
    handleSwitchClick() {
      if (this.active) {
        this.disabling = true;
      } else {
        this[SAVE_MAILING]({ ...this.mailing, is_enabled: !this.active });
      }
    },
    handleCancelDisabling() {
      this.active = true;
      this.handleDisableModalClose();
    },
    handleDisabling() {
      this.handleDisableModalClose();
      this[SAVE_MAILING]({ ...this.mailing, is_enabled: this.active });
    },
  },
};
</script>
