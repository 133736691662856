<template>
  <TheModal :show="is_modal_open" @close="setSearchModalState(false)" >
    <div>
    <InputText v-model.lazy="text">
      <template #leftIcon>
        <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
      </template>
    </InputText>
    <div class="flex place-items-center justify-center  bg-gray-100 p-0" style="min-height: 200px">
      <div v-if="!courses[0]">
        {{ $t('no_search_results') }}
      </div>
      <div v-for="(course,index) in courses" :key="index" >
        {{course.title}}
      </div>
    </div>
    </div>
  </TheModal>
</template>

<script>
import GlobalSearchInputMixin from "@/components/GlobalSearch/GlobalSearchInputMixin";
import InputText from "@/components/InputText";
import TheModal from "@/components/TheModal/TheModal";

export default {
  components: {
    TheModal,
    InputText,
  },
  mixins:[GlobalSearchInputMixin],
  watch:{
    text(){
      this.makeSearchRequest();
    }
  }

}
</script>