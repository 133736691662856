const baseUrl = "/api/v1/landing_page/course_showcase";
export default ($axios) => ({
  get_landing_courses(params) {
    return $axios.get(`${baseUrl}/list/${params}`);
  },
  get_landing_course(params) {
    return $axios.get(`${baseUrl}/pk/${params}`);
  },
  add_landing_courses(data) {
    return $axios.post(`${baseUrl}/create/`, data);
  },
  delete_landing_courses(id) {
    return $axios.delete(`${baseUrl}/pk/${id}`);
  },
  get_landing_courses_options() {
    return $axios.get(`${baseUrl}/options/`);
  },
});
