import { createStore } from "vuex";
import auth from "./auth";
import courses from "./courses";
import core from "./core";
import users from "./users";
import resources from "./resources";
import specializations from "./specializations";
import { getField, updateField } from "vuex-map-fields";
import { createLogger } from "vuex";
import mailings from "@/store/mailings";
import news from "@/store/news";
import landingCourses from "@/store/landing-courses";
import landingForm from "@/store/landing-form";
import landingTeachers from "@/store/landing-teachers";
import landingFeedback from "@/store/landing-feedback";
import shoppingCart from "@/store/shopping-cart";
import certificates from "@/store/certificates";
import attendance from "@/store/attendance";
import courseTaskCategories from "@/store/course-task-categories";
import studentUsers from "@/store/student-users";
import studentGrades from "@/store/student-grades";

const store = (app) =>
  createStore({
    state: {},
    getters: {
      getField,
    },
    mutations: {
      updateField,
    },
    actions: {},
    modules: {
      auth: auth(app),
      courses: courses(app),
      core: core(app),
      specializations: specializations(app),
      resources: resources(app),
      users: users(app),
      mailings: mailings(app),
      news: news(app),
      landing_courses: landingCourses(app),
      landing_form: landingForm(app),
      landing_teachers: landingTeachers(app),
      landing_feedbacks: landingFeedback(app),
      shopping_cart: shoppingCart(app),
      certificates: certificates(app),
      attendance: attendance(app),
      course_task_categories: courseTaskCategories(app),
      student_users: studentUsers(app),
      student_grades: studentGrades(app),
    },
    plugins: [createLogger()],
  });
export default store;
