<template>
  <TheModal :show="show">
    <div class="flex p-4">
      <div class="flex mr-4">
        <div class="bg-red-100 p-2 rounded-full align-center h-fit">
          <ExclamationIcon class="h-5 w-5 text-red-600 "/>
        </div>
      </div>
      <div class="flex flex-col ">
        <h1 class="text-lg leading-6 font-medium text-gray-900 mb-2">
          {{$t('quiz_start_confirmation_title')}}
        </h1>
        <!-- <div class="w-100 text-sm leading-5 font-normal text-gray-500 mb-4">
          {{$t('quiz_start_confirmation_text',{remaining_time:remainingTimeFormatted})}}
        </div> -->
        <div class="flex place-content-end">
          <TheButton bg="outline" @click="goToPrevPage" class="mr-4">
            {{$t('cancel')}}
          </TheButton>
          <TheButton bg="primary" @click="startQuiz">
            {{$t('start_quiz')}}
          </TheButton>
        </div>

      </div>
    </div>
  </TheModal>
</template>
<script>
import TheModal from "@/components/TheModal/TheModal";
import {mapFields} from "vuex-map-fields";
import {ExclamationIcon} from '@heroicons/vue/outline'
import TheButton from "@/components/Button/Button";
import {mapActions} from "vuex";
import {GET_RESOURCE, RETRY_RESOURCE} from "@/store/resources";
// import moment from "moment";
export default {
  components: {TheButton, TheModal,ExclamationIcon},
  props:{
    resource_id:{
        type:Number,
        required:true
    },
    show:{
        type: Boolean,
        required: true
    }
  },
  computed:{
    ...mapFields(['resources.show_start_confirmation_modal','resources.resource']),
    // remainingTimeFormatted(){
    //   const duration  = moment.duration(this.quiz.remaining_time*1000)
    //   return moment.utc(duration.as('milliseconds')).format('HH:mm:ss')
    // }
  },
  methods:{
    ...mapActions([GET_RESOURCE, RETRY_RESOURCE]),
    goToPrevPage(){
      this.show_start_confirmation_modal = false
      this.$router.back()
    },
    async startQuiz(){
      this.show_start_confirmation_modal = false
      await this[RETRY_RESOURCE](this.resource_id)
      window.location = `/resources/${this.resource_id}/pass`
    }
  },
}
</script>