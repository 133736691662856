<template>
  <TheCard
    class="flex justify-center flex-col relative adaptive-card"
    style="background: #5f46f7"
    ><div class="adaptive-inner-card">
      <div class="adaptive-icon cursor-pointer" @click="handleModal">
        <img
          src="@/assets/images/landing/runningline.png"
          class="youtube-image"
        />
      </div>

      <div
        class="flex flex-row items-center mb-6"
        style="justify-content: start"
      >
        <svg
          width="22"
          height="2"
          viewBox="0 0 22 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="22" height="2" rx="1" fill="#90C3EC" />
        </svg>

        <span class="platform-text">платформа для создания курсов</span>
      </div>
      <div class="flex flex-col gap-10 adaptive-text-button-block">
        <p class="h2-custom text-white">
          Хотите создать свою онлайн-школу? Запустите свои курсы у нас уже
          сейчас
        </p>
        <div class="flex gap-6 adaptive-button-block">
          <Button
            class="flex gap-2 py-4 px-6 justify-center items-center bg-white create__button"
            >Создать свой курс</Button
          >
          <Button
            class="flex gap-2 py-4 px-6 justify-center items-center knowmore__button"
            >Узнать подробнее</Button
          >
        </div>
      </div>
    </div>
  </TheCard>
</template>

<script>
import TheCard from "@/components/TheCard/TheCard";
//   import ComputerIcon from "@/assets/icons/ComputerIcon.vue";
export default {
  name: "TheLandingComponentOne",
  components: { TheCard },
  inject: ["openVideoModal"],
  methods: {
    handleModal() {
      this.openVideoModal(
        "https://www.youtube.com/embed/QqhmhMaJVQ0?si=0NdUFSDmW3QmAemk"
      );
    },
  },
};
</script>

<style scoped>
.para-text {
  height: 96px;
  text-align: start;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  @apply text-gray-500;
}
.h2-custom {
  font-size: 48px;
  font-weight: 800;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
}

.platform-text {
  margin-left: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: #90c3ec;
  font-size: 16px;
  font-style: normal;
  line-height: 120%; /* 19.2px */
}
.knowmore__button {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  color: white;
  background: #5f46f7;
  border: 2px solid white;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.create__button {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background: white;
}
.adaptive-card {
  border-radius: 30px;
  padding: 78px 40px;
  border: 3px solid #786ce6;
  height: 670px;
  margin-bottom: 0;
}
.adaptive-inner-card {
  padding: 120px 64px;
  height: 670px;
  margin-bottom: 0;
}

.adaptive-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: rotate(21.27deg);
}
.youtube-image {
  width: 180px;
  height: 180px;
}

@media (max-width: 650px) {
  .platform-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%; /* 14.4px */
    text-transform: uppercase;
  }
  .h2-custom {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .knowmore__button,
  .create__button {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .adaptive-button-block {
    flex-direction: column;
  }
  .adaptive-icon {
    bottom: 0;
    right: 0;
    top: initial;
  }

  .adaptive-text-button-block {
    gap: 32px;
  }
  .adaptive-card {
    justify-items: start;
    padding: 0;
  }
  .adaptive-inner-card {
    padding: 40px 24px;
  }
}
</style>
