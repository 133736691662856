<template>
  <ContentBlockForm :content_name="$t('file_submission_question_label')" :_key="_key" :attachments="attachments" :criteria="criteria" :threshold="threshold" :peer_assessment="peer_assessment" :is_threshold="is_threshold" :is_valid_threshold="is_valid_threshold" :is_number_graders="is_number_graders" :graders_count="graders_count">
    <TheQuillEditorInput v-model="localValue"/>
  </ContentBlockForm>
</template>
<script>
import ContentBlockForm from "@/views/resources/Form/components/ContentBlockForm";
import TheQuillEditorInput from "@/components/TheQuillEditorInput";
export default {
  components: {ContentBlockForm,TheQuillEditorInput},
  props:{
    attachments:[],
    content:{},
    _key:{
      type:String,
      required:true
    },
    criteria:[],
    threshold:{
      type:Number,
      default:0
    },
    peer_assessment:{
      type:Boolean, 
      default:true
    },
    is_valid_threshold:Boolean,
    is_threshold:Boolean,
    is_number_graders:Boolean
  },
  computed:{
    localValue:{
      get(){
        return this.content
      },
      set(val){
        this.$emit('contentChange',val)
      }
    },
  }
}
</script>
