import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import { DELETE_COURSE, GET_COURSE, SAVE_COURSE } from "@/store/courses";
import { GET_USERS } from "@/store/users";
import { ru } from "date-fns/locale";

export default {
  data: () => ({
    instructors: [],
    ru: ru,
    filled_fields: false,
    is_name: true,
    is_description: true,
    is_goals: true,
    is_perks: true,
    is_course_duration: true,
    is_course_level: true,
    is_instructor_id: true,
    is_groups_amount: true,
    is_max_students_in_group: true,
    is_perk_icon: true,
    is_course_logo: true,
    is_instructor_exist: true,
    all_fields: false,
    modalOpen: false,
    is_dates: true,
    is_specializations: true,
    breadcrumbs: [],
  }),
  computed: {
    ...mapFields(["courses.form", "core.icon_selector_modal"]),
    ...mapGetters(["active_user_has_permission"]),
    isUpdating() {
      return !!(this.form && this.form.id);
    },
  },
  methods: {
    ...mapActions([GET_COURSE, SAVE_COURSE, DELETE_COURSE]),
    getIcon(iconName) {
      return require("@heroicons/vue/outline")[iconName];
    },
    generateNewCourse() {
      return {
        description: "",
        duration: "",
        // end_date: "",
        goals: [],
        overview: {
          duration_hours: "",
          skill_level: "",
          is_certificate_given: false,
        },
        instructor_id: null,
        instructors: [
          // {
          //     course: undefined,
          //     id: undefined,
          //     instructor: null,
          //     instructor_description: ""
          // }
        ],
        logo: "",
        name: "",
        perks: [],
        specialization_ids: [],
        instructor_description: "",
        start_date: "",
        end_date: "",
        groups_amount: "",
        max_students_in_group: "",
        // start_date: new Date(),
        // end_date: new Date()
      };
    },
    generateNewPerk() {
      return {
        _key: new Date(),
        title: "",
        icon_name: "",
        description: "",
      };
    },
    generateNewGoal() {
      return {
        _key: new Date(),
        name: "",
      };
    },
    generateNewInstructor() {
      return {
        _key: new Date(),
        instructor_description: "",
      };
    },
    addGoal() {
      const newGoal = this.generateNewGoal();
      this.form.goals.push(newGoal);
    },
    removeGoal(index) {
      this.form.goals.splice(index, 1);
    },
    addPerk() {
      const newPerk = this.generateNewPerk();
      this.form.perks.push(newPerk);
    },
    removePerk(index) {
      this.form.perks.splice(index, 1);
    },
    addInstructor() {
      const newInstructor = this.generateNewInstructor();
      this.form.instructors.push(newInstructor);
    },
    deleteInstructor(e, id) {
      this.form.instructors = this.form.instructors.filter(
        (inst) => inst.id != id
      );
    },
    closeModal() {
      this.filled_fields = false;
    },
    selectIconForPerk(perk) {
      this.icon_selector_modal = {
        is_open: true,
        initial_value: perk.icon_name,
        handleChange: (result) => {
          perk.icon_name = result;
        },
      };
    },
    submit() {
      this.is_name = this.form.name !== "";
      this.is_goals = this.form.goals.every((el) => el.name.trim() !== "");
      this.is_perks = this.form.perks.every((el) => el.title.trim() !== "");
      this.is_course_duration = this.form.overview.duration_hours !== "";
      this.is_course_level = this.form.overview.skill_level !== "";
      this.is_instructor_id =
        this.form.instructors != [] &&
        this.form.instructors.every((el) => "id" in el);
      this.is_perk_icon = this.form.perks[0].icon_name != "";
      this.is_course_logo = this.form.logo != "";
      this.is_instructor_exist =
        this.form.instructors && this.form.instructors.length > 0;

      const copy_specializations = Object.assign({}, this.form.specializations);
      this.is_specializations = Object.keys(copy_specializations).length != 0;

      this.is_dates = this.form.start_date != "" && this.form.end_date != "";

      const target_copy = Object.assign({}, this.form.description);
      if (
        this.form.description !== "" &&
        target_copy.html !== undefined &&
        target_copy.html !== "<p></p>" &&
        target_copy.html !== ""
      ) {
        this.is_description = true;
      } else {
        this.is_description = false;
      }

      const reg = new RegExp("^[0-9]+$");
      this.is_groups_amount = this.form.groups_amount.toString().match(reg);
      this.is_max_students_in_group = this.form.max_students_in_group
        .toString()
        .match(reg);

      if (
        this.is_name &&
        this.is_description &&
        this.is_goals &&
        this.is_perks &&
        this.is_course_duration &&
        this.is_course_level &&
        this.is_instructor_id &&
        this.is_groups_amount &&
        this.is_max_students_in_group &&
        this.is_perk_icon &&
        this.is_course_logo &&
        this.is_instructor_exist &&
        this.is_dates &&
        this.is_specializations
      ) {
        this.all_fields = true;
        this.filled_fields = true;
        this.form.end_date = this.form.end_date.toISOString();
        this.form.duration = this.form.overview.duration_hours;
        this.form.start_date = this.form.start_date.toISOString();
        this.SAVE_COURSE(this.form).then(
          (res) =>
            // this.$router.push({path:`/courses/${res.data.id}/edit`})
            (window.location.href = `/courses/${res.data.id}/edit`)
        );
      } else {
        this.filled_fields = true;
        return;
      }
    },
    deleteCourse() {
      this[DELETE_COURSE](this.form.id);
      this.$router.push({ name: "CoursesList" });
    },
    cancel() {
      this.$router.back();
    },
    handleModalOpen() {
      this.modalOpen = true;
    },
    handleModalClose() {
      this.modalOpen = false;
    },
  },
  async mounted() {
    const { id } = this.$route.query;
    this.instructors = await this.$store.dispatch(GET_USERS, {
      roles: ["curator"],
    });
    if (id) {
      this.form = await this[GET_COURSE](id);
      this.breadcrumbs = [
        {
          name: this.$t("courses"),
          path: "/courses/list",
        },
        {
          name: this.form.name,
          path: `/courses/${id}/edit`,
        },
        {
          name: this.$t("about_course"),
          path: this.$route.path,
        },
      ];
      this.form.start_date = this.form.start_date
        ? new Date(this.form.start_date)
        : new Date();
      this.form.end_date = this.form.end_date
        ? new Date(this.form.end_date)
        : new Date();
      this.form.specializations = this.form.specializations.map((el) => el.id);
    } else {
      this.form = this.generateNewCourse();
      this.breadcrumbs = [
        {
          name: this.$t("courses"),
          path: "/courses/list",
        },
        {
          name: this.$t("create_new_course"),
          path: this.$route.path,
        },
      ];
      const newInstructor = this.generateNewInstructor();
      this.form.instructors.push(newInstructor);
    }
    if (!this.form.perks[0]) {
      this.addPerk();
    }
    if (!this.form.goals[0]) {
      this.addGoal();
    }
    if (!this.form.overview) {
      this.form.overview = {};
    }
  },
};
