<script>
import InputText from "@/components/InputText.vue";
import TheButton from "@/components/Button/Button.vue";
import { CHECK_CERTIFICATE } from "@/store/certificates";
export default {
  components: { TheButton, InputText },
  data() {
    return {
      certNumber: "",
      certificate: null,
      lastCheckTime: null,
      wait: false,
      notFound: false,
      loading: false,
    };
  },
  methods: {
    async check() {
      if (!this.lastCheckTime || Date.now() - this.lastCheckTime > 60 * 1000) {
        this.loading = true;
        this.lastCheckTime = Date.now();
        this.certificate = await this.$store.dispatch(CHECK_CERTIFICATE, {
          serial_number: this.certNumber,
        });
        if (this.certificate && !this.certificate.course) {
          this.notFound = true;
        } else {
          this.notFound = false;
        }

        this.wait = false;
      } else {
        this.wait = true;
        this.notFound = false;
      }
      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="check-cert__page flex flex-col">
    <h1>Проверка подлинности сертификата</h1>
    <div class="flex flex-col gap-6">
      <div class="flex flex-col gap-3">
        <div class="flex-1 flex gap-6 check-cert__button-box items-end">
          <div class="flex-1">
            <InputText
              label="Серийный номер"
              v-model="certNumber"
              :style="['border: 1px solid #9CA3AF;']"
              is-required
            />
          </div>

          <div class="check-cert__button">
            <TheButton @click="check" class="check-cert__button">{{
              loading ? "Loading..." : "Проверить"
            }}</TheButton>
          </div>
        </div>
        <div v-if="notFound" class="err-msg">Сертификат не найден</div>
        <div class="err-msg" v-if="wait">
          Превышено количество попыток проверки сертификата. Попробуйте через
          минуту
        </div>
      </div>

      <div
        class="check-results p-6 flex flex-col gap-6"
        v-if="certificate && certificate.course"
      >
        <div class="flex gap-3 items-center">
          <div>
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.6654 23.0007L14.082 19.4173C13.7765 19.1118 13.3876 18.959 12.9154 18.959C12.4431 18.959 12.0543 19.1118 11.7487 19.4173C11.4431 19.7229 11.2904 20.1118 11.2904 20.584C11.2904 21.0562 11.4431 21.4451 11.7487 21.7507L16.4987 26.5007C16.832 26.834 17.2209 27.0007 17.6654 27.0007C18.1098 27.0007 18.4987 26.834 18.832 26.5007L28.2487 17.084C28.5543 16.7784 28.707 16.3895 28.707 15.9173C28.707 15.4451 28.5543 15.0562 28.2487 14.7507C27.9431 14.4451 27.5543 14.2923 27.082 14.2923C26.6098 14.2923 26.2209 14.4451 25.9154 14.7507L17.6654 23.0007ZM19.9987 36.6673C17.6931 36.6673 15.5265 36.2295 13.4987 35.354C11.4709 34.4784 9.70703 33.2912 8.20703 31.7923C6.70703 30.2923 5.51981 28.5284 4.64536 26.5007C3.77092 24.4729 3.33314 22.3062 3.33203 20.0007C3.33203 17.6951 3.76981 15.5284 4.64536 13.5007C5.52092 11.4729 6.70814 9.70898 8.20703 8.20898C9.70703 6.70898 11.4709 5.52176 13.4987 4.64732C15.5265 3.77287 17.6931 3.3351 19.9987 3.33398C22.3043 3.33398 24.4709 3.77176 26.4987 4.64732C28.5265 5.52287 30.2904 6.7101 31.7904 8.20898C33.2904 9.70898 34.4781 11.4729 35.3537 13.5007C36.2293 15.5284 36.6665 17.6951 36.6654 20.0007C36.6654 22.3062 36.2276 24.4729 35.352 26.5007C34.4765 28.5284 33.2893 30.2923 31.7904 31.7923C30.2904 33.2923 28.5265 34.4801 26.4987 35.3556C24.4709 36.2312 22.3043 36.6684 19.9987 36.6673Z"
                fill="#69C9A6"
              />
            </svg>
          </div>
          <div class="certificate-check-title">Сертификат подтвержден</div>
        </div>
        <div class="line"></div>
        <div class="flex flex-col gap-4">
          <div class="flex gap-3 items-center">
            <div class="text-lg certificate-check-subtitle">ФИО:</div>
            <div class="text-gray-500 text-lg certificate-check-name">
              {{ certificate.user_details.full_name }}
            </div>
          </div>
          <div class="flex gap-3 items-center">
            <div class="text-lg certificate-check-subtitle">Курс:</div>
            <div class="text-gray-500 text-lg certificate-check-name">
              {{ certificate.course_details.name }}
            </div>
          </div>
          <div class="flex gap-3 items-center">
            <div class="text-lg certificate-check-subtitle">
              Дата выдачи сертификата:
            </div>
            <div class="text-gray-500 text-lg certificate-check-name">
              {{ certificate.issue_date }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.check-cert__page {
  padding: 40px 100px;
  background: #f4f5fa;
  gap: 40px;
}
h1 {
  color: #1f2937;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  text-align: left;
}
.check-results {
  border-radius: 24px;
  background: white;
}
.certificate-check-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: #1f2937;
}
.line {
  border-top: 2px dashed #d1d5db;
  width: 100%;
}
.err-msg {
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #ef4444;
}
@media (max-width: 650px) {
  .check-cert__page {
    padding: 32px 16px;
    gap: 32px;
  }

  h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: left;
  }
  .check-cert__button-box {
    flex-direction: column;
    height: 52px;
    align-items: stretch;
  }
  .check-cert__button {
    width: 100%;
  }

  .check-results {
    padding: 24px 20px;
    border-radius: 20px;
  }

  .certificate-check-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
  }

  .certificate-check-subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
  .certificate-check-name {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
}
</style>
