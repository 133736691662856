<template>
  <div>
    <p class="text-2xl text-gray-900 font-semibold mb-4">
      {{ $t("specializations") }}
    </p>
    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-2 auto-rows-fr"
    >
      <CreateNewSpecializationCard />
      <SpecializationCard
        v-for="specialization in specializations"
        :key="`specialization_${specialization.id}`"
        :id="specialization.id"
        :logo="specialization.logo"
        :name="specialization.name"
      />
    </div>
  </div>
</template>
<script>
import SpecializationCard from "@/components/Specializations/Cards/SpecializationCard";
import CreateNewSpecializationCard from "@/components/Specializations/Cards/CreateNewSpecializationCard";
import ListMixin from "@/views/specializations/List/ListMixin";
export default {
  name: "SpecializationsList",
  components: { CreateNewSpecializationCard, SpecializationCard },
  mixins: [ListMixin],
};
</script>
