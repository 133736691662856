<template>
  <svg
    width="64"
    height="65"
    viewBox="0 0 64 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect y="0.498047" width="64" height="64" rx="16" fill="#7D69FC" />
    <path
      d="M26.434 29.854C26.434 29.098 26.6487 28.5147 27.078 28.104C27.5167 27.6934 28.0813 27.488 28.772 27.488C29.4627 27.488 30.0227 27.6934 30.452 28.104C30.8813 28.5147 31.096 29.098 31.096 29.854C31.096 30.61 30.8813 31.1934 30.452 31.604C30.0227 32.0147 29.4627 32.22 28.772 32.22C28.0813 32.22 27.5167 32.0147 27.078 31.604C26.6487 31.1934 26.434 30.61 26.434 29.854ZM35.94 27.642L30.55 37.498H28.24L33.63 27.642H35.94ZM28.758 28.888C28.31 28.888 28.086 29.21 28.086 29.854C28.086 30.4887 28.31 30.806 28.758 30.806C28.9727 30.806 29.1407 30.7267 29.262 30.568C29.3833 30.4094 29.444 30.1714 29.444 29.854C29.444 29.21 29.2153 28.888 28.758 28.888ZM33.098 35.286C33.098 34.53 33.3127 33.9467 33.742 33.536C34.1713 33.1254 34.7313 32.92 35.422 32.92C36.1127 32.92 36.6727 33.1254 37.102 33.536C37.5313 33.9467 37.746 34.53 37.746 35.286C37.746 36.042 37.5313 36.6254 37.102 37.036C36.6727 37.4467 36.1127 37.652 35.422 37.652C34.7313 37.652 34.1713 37.4467 33.742 37.036C33.3127 36.6254 33.098 36.042 33.098 35.286ZM35.408 34.32C35.1933 34.32 35.0253 34.3994 34.904 34.558C34.792 34.7167 34.736 34.9594 34.736 35.286C34.736 35.9207 34.96 36.238 35.408 36.238C35.6227 36.238 35.7907 36.1587 35.912 36C36.0333 35.8414 36.094 35.6034 36.094 35.286C36.094 34.9687 36.0333 34.7307 35.912 34.572C35.7907 34.404 35.6227 34.32 35.408 34.32Z"
      fill="white"
    />
    <path
      d="M32.1696 16.9727C40.8372 16.9727 47.8636 23.9991 47.8636 32.6667C47.8636 41.3343 40.8372 48.3607 32.1696 48.3607C23.502 48.3607 16.4756 41.3343 16.4756 32.6667C16.4756 23.9991 23.502 16.9727 32.1696 16.9727Z"
      stroke="white"
      stroke-opacity="0.3"
      stroke-width="2.87733"
      stroke-linecap="round"
    />
    <path
      d="M32.1709 16.9727C40.8385 16.9727 47.8649 23.9991 47.8649 32.6667"
      stroke="white"
      stroke-width="2.87733"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
