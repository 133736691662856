import {
  UsersIcon,
  FolderIcon,
  InboxIcon,
  ChartBarIcon,
} from "@heroicons/vue/solid";
import {
  CalendarIcon,
  BookOpenIcon,
  LibraryIcon,
  QuestionMarkCircleIcon,
  MailIcon,
  IdentificationIcon,
  BellIcon,
  SpeakerphoneIcon,
  AcademicCapIcon,
  // ,ChatIcon,UserGroupIcon,
} from "@heroicons/vue/outline";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";
import { GET_NOTIFICATIONS_COUNT } from "@/store/users";
import { mapActions } from "vuex";
import { GET_MY_CERTIFICATES } from "@/store/certificates";
export default {
  components: {
    UsersIcon,
    FolderIcon,
    CalendarIcon,
    InboxIcon,
    ChartBarIcon,
    QuestionMarkCircleIcon,
    IdentificationIcon,
    BellIcon,
  },

  data: () => ({
    certificates: [],
    navigation: [
      {
        name: "main_page_short",
        href: "/dashboard",
        icon: CalendarIcon,
        route_name: "DashboardPage",
        permission: "can_see_published_courses",
      },
      {
        name: "my_courses",
        href: "/courses/waiting-for-start",
        icon: BookOpenIcon,
        route_name: "MyCourses",
        permission: "can_study",
      },
      {
        name: "waiting_for_start",
        href: "/courses/waiting-for-start",
        route_name: "WaitingForStart",
        permission: "can_study",
      },
      {
        name: "passing",
        href: "/courses/current",
        route_name: "CurrentCourses",
        permission: "can_study",
      },
      {
        name: "finished_courses",
        href: "/courses/finished",
        route_name: "FinishedCourses",
        permission: "can_study",
      },
      // { name: 'faq', href: '/faq', route_name: 'FAQ', icon: QuestionMarkCircleIcon,permission:'view_dashboard'},

      {
        name: "specializations",
        href: "/specializations",
        icon: LibraryIcon,
        route_name: "SpecializationsList",
        permission: "can_edit_course",
      },
      {
        name: "courses",
        href: "/courses/list",
        icon: BookOpenIcon,
        route_name: "CoursesList",
        permission: "can_create_course",
      },
      {
        name: "checking",
        href: "/students/grading",
        route_name: "StudentsGrading",
        permission: "can_observe_course_answers",
      },
      // { name: 'students', href: '#', icon: UserGroupIcon, route_name: false },
      // { name: 'chat_with_teacher', href: '#', icon: ChatIcon, route_name: false },
      {
        name: "roles",
        href: "/courses/roles",
        icon: IdentificationIcon,
        route_name: "Roles",
        permission: "can_add_students_to_system",
      },
      // { name: 'roles', href: '#', icon: IdentificationIcon, route_name: false },
      // { name: 'Documents', href: '#', icon: InboxIcon, route_name: false },
      // { name: 'Reports', href: '#', icon: ChartBarIcon, route_name: false },
      {
        name: "statistics",
        href: "/statistics",
        icon: ChartBarIcon,
        route_name: "StatisticsView",
        permission: "can_view_statistics",
      },
      {
        name: "mailings",
        href: "/mailings-list",
        icon: MailIcon,
        route_name: "MailingsList",
        permission: "can_edit_system_subscriptions",
      },
      {
        name: "notifications",
        href: "/notifications",
        icon: BellIcon,
        route_name: "NotificationList",
        permission: "can_view_notifications",
      },

      {
        name: "Сертификаты",
        href: "/certificate-temp",
        icon: AcademicCapIcon,
        route_name: "CertificateTemp",
        permission: "can_send_course_certificate",
      },
    ],
    landingNavigation: [
      {
        name: "courses_publication",
        href: "/courses/publish",
        icon: BookOpenIcon,
        route_name: "CoursePublish",
        permission: "can_publish_course",
      },
      {
        name: "news_publication",
        href: "/news/list",
        icon: SpeakerphoneIcon,
        route_name: "NewsList",
        permission: "can_publish_news",
      },
    ],
    notifications_count: 0,
  }),
  computed: {
    currentRoute() {
      return this.$route.name;
    },
    ...mapActions([GET_NOTIFICATIONS_COUNT, GET_MY_CERTIFICATES]),
    ...mapFields(["core.is_sidebar_open"]),
    ...mapGetters(["active_user_has_permission"]),
  },
  async created() {
    this.notifications_count = await this.GET_NOTIFICATIONS_COUNT;
    this.certificates = await this.GET_MY_CERTIFICATES;
    if (this.certificates.length) {
      this.navigation = [
        ...this.navigation,
        {
          name: "my_certificates",
          href: "/certificates",
          icon: AcademicCapIcon,
          route_name: "CertificateList",
          permission: "can_study",
        },
      ];
    }
  },
};
