export default [
  {
    name: "CertificatesLayout",
    path: "/",
    component: async () => require("../layouts/CabinetLayout.vue"),
    children: [
      {
        name: "CertificateList",
        path: "/certificates",
        component: async () =>
          require("../views/certificates/CertificateList.vue"),
      },
      {
        name: "CertificateTemp",
        path: "/certificate-gen",
        component: async () =>
          require("../views/certificates/CertificateTemp.vue"),
      },

      {
        name: "CertificateGen",
        path: "/certificate-gen-course",
        component: async () =>
          require("../views/certificates/CertificateGen.vue"),
      },
    ],
  },
];
