<template>
  <div class="bg-purple our-courses">
    <main class="relative flex-1">
      <div class="flex flex-col gap-12 w-full adaptive-swiper">
        <span class="flex justify-between items-center">
          <p class="text-3xl text-gray-900 font-bold courses__title">
            {{ $t("our_courses") }}
          </p>
        </span>
        <div class="grid grid-cols-3 gap-6 adaptive-courses">
          <LandingCourseCard
            v-for="(course, index) in landingCourses"
            :course="course"
            :key="index"
            @click="openCourse(index)"
            :level="course.level"
          />
        </div>
        <div class="adaptive-courses2">
          <swiper
            :modules="modules"
            :slides-per-view="1"
            :space-between="16"
            :pagination="pagination"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide
              v-for="(course, index) in landingCourses"
              :key="index"
            >
              <LandingCourseCard
                :course="course"
                @click="openCourse(index)"
                :level="course.level"
            /></swiper-slide>
          </swiper>
        </div>
        <Button
          class="flex gap-2 py-4 px-6 w-fit ml-auto button-white"
          @click="handleClick"
          >{{ $t("all_courses_landing") }}<ArrowNarrowRightIcon class="h-4 w-4"
        /></Button>
      </div>
    </main>
  </div>
</template>

<script>
import LandingCourseCard from "../cards/LandingCourseCard.vue";
import Button from "@/components/Button/Button.vue";
import { ArrowNarrowRightIcon } from "@heroicons/vue/outline";
import { mapActions } from "vuex";
import { GET_PUBLISHED_COURSES } from "@/store/courses";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";
export default {
  components: {
    Swiper,
    SwiperSlide,
    LandingCourseCard,
    Button,
    ArrowNarrowRightIcon,
  },
  mixins: [AllCoursesMixin],
  created() {
    this.getJustCourses();
  },
  methods: {
    ...mapActions([GET_PUBLISHED_COURSES]),
    handleClick() {
      this.$router.push("/all-courses");
    },
    openCourse(index) {
      const course = this.landingCourses[index];

      if (!course) {
        return; // Return if the index is invalid
      } else if (course.program === "Веб-разработка") {
        return this.$router.push({ path: "/all-courses/web" });
      } else if (course.program === "Дизайн") {
        return this.$router.push({ path: "/all-courses/design" });
      } else if (course.program === "Программирование") {
        return this.$router.push({ path: "/all-courses/programming" });
      } else if (course.program === "Мобильная разработка") {
        return this.$router.push({ path: "/all-courses/mob-dev" });
      } else if (course.program === "Разработка игр") {
        return this.$router.push({ path: "/all-courses/game-dev" });
      } else if (course.program === "ИИ") {
        return this.$router.push({ path: "/all-courses/gen-ai" });
      } else if (course.program === "Программирование ИИ") {
        return this.$router.push({ path: "/all-courses/programming-ai" });
      }
    },
    onSwiper: (swiper) => {
      console.log(swiper);
    },
    onSlideChange: () => {
      console.log("slide change");
    },
  },
  data() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },

        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
      },
      modules: [Navigation, Pagination],
    };
  },
};
</script>
<style scoped>
.our-courses {
  padding: 64px 100px 64px 100px;
}

.courses__title {
  font-weight: 700;
  font-size: 40px;
  color: #ffffff;
}

:deep .swiper-pagination-bullet {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e4d7ff;
}

:deep .swiper-pagination-bullet-active {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e5e7eb;
}

:deep .pagination {
  margin-top: 16px;
}

.adaptive-courses2 {
  display: none;
}

@media (max-width: 650px) {
  .adaptive-courses {
    display: none;
  }
  .adaptive-courses2 {
    display: flex;
  }
  .our-courses {
    padding: 32px 16px;
  }
  .courses__title {
    font-size: 28px;
  }

  .adaptive-swiper {
    gap: 32px;
  }
}
</style>
