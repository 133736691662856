<template>
  <ResourceBlockCard>
    <QuestionText :text="content"/>
    <div>
      <textarea
          class="w-full mt-3 border-gray-200 rounded-xl"
          rows="4"
          value="Тут будет ответ"
          :disabled="true "
      />
    </div>
    <AttachmentBlock :attachments="attachments" />
    <div class="flex place-content-between mt-3">
      <TheButton bg="outline" :disabled="true">
        <PaperClipIcon  class="w-4 h-4 inline-block mr-1"/>
        {{$t('attach_file')}}
      </TheButton>
      <TheButton bg="primary" :disabled="true">
        {{$t('send')}}
        <PaperAirplaneIcon class="h-4 w-4 inline-block ml-1 rotate-90"/>
      </TheButton>
    </div>
  </ResourceBlockCard>

</template>
<script>
import QuestionText from "@/components/Resources/ResourceBlock/Questions/QuestionText";
import ResourceBlockCard from "@/components/Resources/ResourceBlock/ResourceBlockCard";
import TheButton from "@/components/Button/Button";
import {PaperClipIcon} from "@heroicons/vue/outline"
import {PaperAirplaneIcon} from "@heroicons/vue/solid"
import AttachmentBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";

export default {
  components: {
    AttachmentBlock,
    TheButton,
    ResourceBlockCard,
    QuestionText,
    PaperClipIcon,
    PaperAirplaneIcon,
  },
  props:{
    content:{
      type:String,
      default:''
    },
    attachments:{
      type:Array,
      default:()=>[]
    }
  },

}
</script>