<template>
  <TransitionRoot as="template" :show="show">
    <Dialog
      as="div"
      class="fixed z-50 inset-0 overflow-y-auto"
      @close="closeModal"
    >
      <DialogOverlay class="fixed inset-0 transition-opacity" />

      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed top-0 right-0 mt-20 mr-8 z-50 bg-white rounded-lg shadow-xl transform transition-all sm:max-w-sm sm:w-full md:max-w-md lg:max-w-lg xl:max-w-2xl modal-border"
          :class="className"
          @mouseleave="closeModal"
        >
          <slot />
        </div>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import TheModalMixin from "@/components/TheModal/TheModalMixin";

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
  },
  mixins: [TheModalMixin],
};
</script>
<style scoped>
.modal-border {
  border: 1px solid #786ce6;
  border-radius: 20px;
  padding: 20px 24px;
  width: 370px;
  max-height: 470px;
  overflow-y: scroll;
}

.modal-border::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px; /* Rounded corners for the track */
}
</style>
