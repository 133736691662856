import { SET_LOADING } from "@/store/core";

export const WANT_TO_LEARN = "WANT_TO_LEARN";
export const LIKE_TO_STUDY = "LIKE_TO_STUDY";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const actions = {
    [LIKE_TO_STUDY]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      await $api.landing_form.like_to_study_application(params);
      commit(SET_LOADING, false);
    },
    [WANT_TO_LEARN]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      await $api.landing_form.want_to_learn_application(params);
      commit(SET_LOADING, false);
    },
  };
  return {
    actions,
  };
};

export default (app) => createStore(app);
