<template>
  <div class="footer grid grid-cols-2">
    <div class="grid grid-cols-2 footer-div">
      <div class="flex flex-col gap-10">
        <div class="flex gap-4 items-center">
          <MainLogoWhiteVue />
          <span class="font-semibold text-white font-size-15px"
            >Alpha Education</span
          >
        </div>
        <div class="cursor-pointer logos-box">
          <div @click="navigateToInstagram">
            <InstagramIconWhiteVue />
          </div>
          <div @click="navigateToLinkedin">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.4701 2.00014H3.53006C3.33964 1.9975 3.15056 2.03239 2.97362 2.10282C2.79669 2.17326 2.63536 2.27786 2.49886 2.41065C2.36235 2.54344 2.25334 2.70182 2.17805 2.87675C2.10276 3.05167 2.06267 3.23972 2.06006 3.43014V20.5701C2.06267 20.7606 2.10276 20.9486 2.17805 21.1235C2.25334 21.2985 2.36235 21.4568 2.49886 21.5896C2.63536 21.7224 2.79669 21.827 2.97362 21.8975C3.15056 21.9679 3.33964 22.0028 3.53006 22.0001H20.4701C20.6605 22.0028 20.8496 21.9679 21.0265 21.8975C21.2034 21.827 21.3648 21.7224 21.5013 21.5896C21.6378 21.4568 21.7468 21.2985 21.8221 21.1235C21.8974 20.9486 21.9375 20.7606 21.9401 20.5701V3.43014C21.9375 3.23972 21.8974 3.05167 21.8221 2.87675C21.7468 2.70182 21.6378 2.54344 21.5013 2.41065C21.3648 2.27786 21.2034 2.17326 21.0265 2.10282C20.8496 2.03239 20.6605 1.9975 20.4701 2.00014ZM8.09006 18.7401H5.09006V9.74014H8.09006V18.7401ZM6.59006 8.48014C6.17632 8.48014 5.77953 8.31578 5.48697 8.02323C5.19442 7.73067 5.03006 7.33388 5.03006 6.92014C5.03006 6.5064 5.19442 6.10961 5.48697 5.81705C5.77953 5.5245 6.17632 5.36014 6.59006 5.36014C6.80975 5.33522 7.03224 5.35699 7.24293 5.42402C7.45363 5.49105 7.6478 5.60183 7.81272 5.7491C7.97763 5.89637 8.10958 6.07682 8.19993 6.27862C8.29028 6.48043 8.33698 6.69904 8.33698 6.92014C8.33698 7.14124 8.29028 7.35985 8.19993 7.56166C8.10958 7.76346 7.97763 7.94391 7.81272 8.09118C7.6478 8.23845 7.45363 8.34923 7.24293 8.41626C7.03224 8.48329 6.80975 8.50505 6.59006 8.48014ZM18.9101 18.7401H15.9101V13.9101C15.9101 12.7001 15.4801 11.9101 14.3901 11.9101C14.0527 11.9126 13.7242 12.0184 13.4489 12.2133C13.1735 12.4082 12.9645 12.6828 12.8501 13.0001C12.7718 13.2352 12.7379 13.4827 12.7501 13.7301V18.7301H9.75006V9.73014H12.7501V11.0001C13.0226 10.5272 13.419 10.1377 13.8965 9.87334C14.374 9.60902 14.9146 9.47999 15.4601 9.50014C17.4601 9.50014 18.9101 10.7901 18.9101 13.5601V18.7401Z"
                fill="white"
              />
            </svg>
          </div>
          <div @click="navigateToTelegram">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.6651 3.7178L2.93509 10.5548C1.72509 11.0408 1.73209 11.7158 2.71309 12.0168L7.26509 13.4368L17.7971 6.7918C18.2951 6.4888 18.7501 6.6518 18.3761 6.9838L9.8431 14.6848H9.84109L9.8431 14.6858L9.5291 19.3778C9.9891 19.3778 10.1921 19.1668 10.4501 18.9178L12.6611 16.7678L17.2601 20.1648C18.1081 20.6318 18.7171 20.3918 18.9281 19.3798L21.9471 5.1518C22.2561 3.9128 21.4741 3.3518 20.6651 3.7178Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="font-semibold text-sm uppercase text-gray-50">Ссылки</div>
        <router-link :to="{ name: 'AllCourses' }">
          <div class="cursor-pointer">Курсы</div></router-link
        >
        <router-link :to="{ name: 'Services' }"
          ><div class="cursor-pointer">Услуги</div></router-link
        >
        <router-link :to="{ name: 'About Us' }"
          ><div class="cursor-pointer">О нас</div>
        </router-link>
        <router-link :to="{ name: 'Tariffs' }"
          ><div class="cursor-pointer">Тарифы</div></router-link
        >
      </div>
    </div>
    <div class="grid grid-cols-2 footer-div">
      <div class="flex flex-col gap-4">
        <div class="font-semibold text-sm uppercase text-gray-50">Контакты</div>
        <div>+7 701 398 368 7</div>
        <div>г. Астана, Мангилик Ел 55, Блок С 3.5</div>
      </div>
      <div class="flex flex-col gap-4">
        <div class="font-semibold text-sm uppercase text-gray-50">
          Возникли вопросы? Напишите нам
        </div>
        <div>alpha.edu.technology@gmail.com</div>
      </div>
    </div>
  </div>
</template>

<script>
import MainLogoWhiteVue from "@/assets/icons/MainLogoWhite.vue";

import InstagramIconWhiteVue from "@/assets/icons/InstagramIconWhite.vue";

export default {
  components: { MainLogoWhiteVue, InstagramIconWhiteVue },
  methods: {
    navigateToInstagram() {
      window.open("https://www.instagram.com/alphaedu.kz", "_blank");
    },
    navigateToLinkedin() {
      window.open(
        "https://www.linkedin.com/company/alpha-design-kz/",
        "_blank"
      );
    },
    navigateToTelegram() {
      window.open("https://www.instagram.com/alphaedu.kz", "_blank");
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  padding: 64px 100px 96px 100px;
  background: #20222e;
  color: #d1d5db;
}

.text-gray-50 {
  color: #f9fafb;
}
.font-size-15px {
  font-size: 15px;
}
.logos-box {
  display: flex;
  flex-direction: row;
  gap: 24px;
}

@media (max-width: 650px) {
  .footer {
    padding: 40px 16px;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .footer-div {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }
}
</style>
