<template>
  <div
    class="bg-gray-50 rounded-xl p-4 flex place-content-between cursor-pointer w-fit"
  >
    <div class="flex grow place-items-center">
      <img class="mr-3 flex-0" :src="fileExtensionSrc" />
      <ThePopper class="text-xs leading-5 font-medium text-gray-800 flex-1">
        <div class="truncate text-ellipsis max-w-[13rem]">{{ name }}</div>
        <template #content>
          {{ name }}
        </template>
      </ThePopper>
    </div>
    <div class="flex place-items-center">
      <span
        class="text-xs leading-4 font-medium mr-3 align-bottom w-fit whitespace-nowrap"
        >{{ formattedSize }}</span
      >
      <a :href="formattedUrl" target="_blank">
        <TheButton bg="primary-transparent">
          <DownloadIcon class="h-4 w-4 text-indigo-600" />
        </TheButton>
      </a>
    </div>
  </div>
</template>
<script>
import TheButton from "@/components/Button/Button";
import { DownloadIcon } from "@heroicons/vue/solid";
import prettyBytes from "pretty-bytes";
import ThePopper from "@/components/ThePopper";
export default {
  components: { ThePopper, TheButton, DownloadIcon },
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 0,
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedUrl() {
      return this.url.replace("http://", "https://");
    },
    formattedSize() {
      return prettyBytes(this.size);
    },
    fileType() {
      return this.name.split(".").pop().toUpperCase();
    },
    // formattedUrl(){
    //   const {backendUrl} = this.$config;
    //   const _backendUrl = backendUrl.substring(0,backendUrl.length-1);
    //   return `${_backendUrl}${this.url}`
    // },
    fileExtensionSrc() {
      try {
        return require(`@/assets/images/filetypes/${this.url}.svg`);
      } catch (e) {
        return null;
      }
    },
  },
};
</script>
