<template>
  <div class="fit">
    <div>
      <div class="text-4xl font-bold bg-gradient adaptive-block-title">
        Эта программа
      </div>
      <div class="text-4xl font-bold text-white adaptive-block-title">
        подойдет тем, кто
      </div>
    </div>
    <div class="flex gap-6 adaptive-fit">
      <div class="flex flex-col gap-1 px-8 py-10 fit__box-1 bg-white">
        <div class="text-2xl font-semibold">Никогда не работал</div>
        <div class="font-normal text-lg text-gray-500">
          и хочет получить
          <span class="bg-gradient">востребованную специальность</span> ,
          трудоустроиться или узнать больше о специальности
        </div>
      </div>
      <div class="flex flex-col gap-1 px-8 py-10 fit__box-2 bg-white">
        <div class="text-2xl font-semibold">Хочет сменить работу</div>
        <div class="font-normal text-lg text-gray-500">
          освоить современные инструменты, технологии и получить актуальную
          <span class="bg-gradient">высокооплачиваемую специальность</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.fit {
  background: #2b2e3b;
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.fit__box-1 {
  flex-basis: 50%;
  background-color: white;
  border-radius: 32px;
}
.fit__box-2 {
  flex-basis: 50%;
  background-color: white;
  border-radius: 32px;
}
.text-gray-50 {
  color: #f9fafb;
}

.bg-gradient {
  background: linear-gradient(
    89.33deg,
    #eb8aff 7.2%,
    #b792ff 42.58%,
    #898fff 84.4%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media (max-width: 650px) {
  .fit {
    padding: 40px 16px;
  }
  .adaptive-fit {
    flex-direction: column;
  }
  .adaptive-block-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}
</style>
