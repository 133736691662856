<template>
  <div class="mt-2 mb-5">
    <span for="comment" class="font-semibold">{{ $t('instructor_comment') }}:</span>
    <TheCard class="bg-gray-50 ">
      {{ comment }}
    </TheCard>
  </div>
</template>
<script>

import TheCard from "@/components/TheCard/TheCard";

export default {
  components: {TheCard},
  props: {
    comment: {
      type: String,
      default: ""
    }
  },
  methods: {},
}
</script>