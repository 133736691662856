<template>
  <div class="mt-6 flex flex-col place-items-center pb-14">
    <div class="max-w-screen-md w-full relative">
      <component
          v-for="content in contents"
          :is="content.type"
          class="mb-6"
          :id="content.id"
          :key="`content_${content.id ||content.key}`"
          :content="content.content.html??content.content"
          :attachments = content.attachments
          :available_answers="content.available_answers"
          :user_answer_files="content.user_answer_files"
          :text_answer="content.answer"
          :is_disabled="true"
          :url="content.content"
          :src="content.src"
          :image="content.content"
          :multiple_answers_available="content.multiple_answers_available"
      />
    </div>
  </div>
</template>

<script>
import {mapFields} from "vuex-map-fields";
import {PaperAirplaneIcon} from "@heroicons/vue/solid"
import ResourceContent from "@/components/Resources/ResourceBlock/ResourceContent";
import TheButton from "@/components/Button/Button";
import TextInputQuestion from "@/components/Resources/ResourceBlock/Questions/TextInputQuestion";
import MultipleChoiceQuestion from "@/components/Resources/ResourceBlock/Questions/MultipleChoiceQuestion";
import MultipleAnswersQuestion from "@/components/Resources/ResourceBlock/Questions/MultipleAnswersQuestion";
import AudioQuestionPreview from "@/views/resources/Form/components/AudioQuestionPreview";
import FileSubmissionQuestionPreview from "@/views/resources/Form/components/FileSubmissionQuestionPreview";
import YoutubeVideoContent from "@/components/Resources/ResourceBlock/YoutubeVideoContent";
import ImageContent from "@/components/Resources/ResourceBlock/ImageContent";
import ImageQuestionPreview from "@/views/resources/Form/components/ImageQuestionPreview";
import AudioContent from "@/components/Resources/ResourceBlock/AudioContent";
import PresentationContentCard from "@/components/Resources/ResourceBlock/PresentationContentCard";
import VideoContent from "@/components/Resources/ResourceBlock/VideoContent";
export default {
  components: {
    PaperAirplaneIcon,
    TheButton,
    "text_input":TextInputQuestion,
    "multiple_choice_question":MultipleChoiceQuestion,
    "multiple_answers_question":MultipleAnswersQuestion,
    "audio_question":AudioQuestionPreview,
    "file_submission_question":FileSubmissionQuestionPreview,
    "text":ResourceContent,
    "image":ImageContent,
    "video":VideoContent,
    "image_question":ImageQuestionPreview,
    "audio":AudioContent,
    "youtube_video":YoutubeVideoContent,
    "presentation":PresentationContentCard
  },
  computed:{
    ...mapFields([
      'resources.form.pages',
      'resources.form.current_page',
    ]),
    contents(){
      return this.pages[this.current_page-1].contents
    }
  },
}
</script>