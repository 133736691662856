import {mapFields} from "vuex-map-fields";

export default {
    computed:{
        ...mapFields(['auth.forgot_password_form.step'])
    },
    methods:{
        getBack(){
            if(this.step>1){
                this.step -=1
            }
        }
    }
}