<template>
  <div class="mt-6 flex flex-col place-items-center pb-14">
    <FinishResourceConfirmationModal />
    <FinishQuizConfirmationModal @submitted="handleSubmit" />
    <QuizTimeoutModal />
    <AttemptOverdueModal
      :show="
        resource.is_overdue &&
        show_attempt_overdue_modal &&
        resource.type !== 'lesson'
      "
    />
    <StartQuizConfirmationModal
      :resource_id="id"
      :show="
        resource.type === 'quiz' &&
        !resource.is_submitted &&
        !resource.is_started &&
        !resource.is_overdue
      "
    />
    <div class="max-w-screen-md w-full relative">
      <component
        v-for="content in resource.contents"
        :is="content.type"
        class="mb-6"
        :id="content.id"
        :key="`content_${content.id}`"
        :content="this.securedContent(content.content)"
        :attachments="content.attachments"
        :available_answers="content.available_answers"
        :user_answer_files="content.user_answer_files"
        :text_answer="content.answer"
        :is_disabled="content.is_disabled"
        :is_correct="content.is_correct"
        :image="this.securedContent(content.image)"
        :url="content.url"
      />
      <TheButton
        class="absolute right-0"
        v-if="showFinishQuizButton"
        bg="primary"
        @click="show_finish_quiz_confirmation_modal = true"
      >
        {{ $t("submit") }}
        <PaperAirplaneIcon class="h-4 w-4 inline-block ml-1 rotate-90" />
      </TheButton>
    </div>

    <ResultsModal
      :show="resource.is_submitted"
      :is_successfully_passed="resource.is_successfully_passed"
      :result="resource.result"
      :resource_id="id"
      :is_overdue="resource.is_overdue"
      :course_id="resource.course_id"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { GET_RESOURCE } from "@/store/resources";
import { mapFields } from "vuex-map-fields";
import { PaperAirplaneIcon } from "@heroicons/vue/solid";
import ResourceContent from "@/components/Resources/ResourceBlock/ResourceContent";
import FinishResourceConfirmationModal from "@/components/Resources/Modals/FinishResourceConfirmationModal";
import StartQuizConfirmationModal from "@/components/Resources/Modals/StartQuizConfirmationModal";
import AttemptOverdueModal from "@/components/Resources/Modals/AttemptOverdueModal";
import QuizTimeoutModal from "@/components/Resources/Modals/QuizTimeoutModal";
import FinishQuizConfirmationModal from "@/components/Resources/Modals/FinishQuizConfirmationModal";
import TheButton from "@/components/Button/Button";
import TextInputQuestion from "@/components/Resources/ResourceBlock/Questions/TextInputQuestion";
import MultipleChoiceQuestion from "@/components/Resources/ResourceBlock/Questions/MultipleChoiceQuestion";
import MultipleAnswersQuestion from "@/components/Resources/ResourceBlock/Questions/MultipleAnswersQuestion";
import AudioQuestion from "@/components/Resources/ResourceBlock/Questions/AudioQuestion";
import FileSubmissionQuestion from "@/components/Resources/ResourceBlock/Questions/FileSubmissionQuestion";
import YoutubeVideoContent from "@/components/Resources/ResourceBlock/YoutubeVideoContent";
import ResultsModal from "@/components/Resources/Modals/ResultsModal";
import ImageContent from "@/components/Resources/ResourceBlock/ImageContent";
import PresentationContentCard from "@/components/Resources/ResourceBlock/PresentationContentCard.vue";
import AudioContent from "@/components/Resources/ResourceBlock/AudioContent.vue";
import VideoContent from "@/components/Resources/ResourceBlock/VideoContent.vue";
export default {
  components: {
    ResultsModal,
    FinishResourceConfirmationModal,
    AttemptOverdueModal,
    PaperAirplaneIcon,
    StartQuizConfirmationModal,
    QuizTimeoutModal,
    FinishQuizConfirmationModal,
    TheButton,
    image: ImageContent,
    text_input: TextInputQuestion,
    multiple_choice: MultipleChoiceQuestion,
    multiple_answers: MultipleAnswersQuestion,
    audio_question: AudioQuestion,
    audio: AudioContent,
    video: VideoContent,
    file_submission: FileSubmissionQuestion,
    content: ResourceContent,
    youtube_video: YoutubeVideoContent,
    presentation: PresentationContentCard,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  methods: {
    ...mapActions([GET_RESOURCE]),
    handleSubmit() {
      this.show_finish_quiz_confirmation_modal = false;
      if (this.current_page === 1) {
        window.location.reload();
      } else {
        this.current_page = 1;
        // this.$router.push(`/courses/${this.resource.course_id}/content`)
        this.$forceUpdate();
      }
    },
    securedContent(content) {
      return typeof content === "string"
        ? content.replace("http://", "https://")
        : content;
    },
  },
  computed: {
    ...mapFields([
      "resources.resource",
      "resources.paginator.current_page",
      "resources.show_start_confirmation_modal",
      "resources.show_attempt_overdue_modal",
      "resources.show_finish_quiz_confirmation_modal",
    ]),
    routePage() {
      return this.$route.query.page;
    },
    showFinishQuizButton() {
      return (
        this.resource.type === "quiz" &&
        this.resource.is_last_page &&
        !this.resource.is_submitted &&
        !this.resource.is_overdue
      );
    },
  },
  mounted() {
    // this.show_attempt_overdue_modal = this.resource.is_overdue
  },
  watch: {
    async current_page(page, oldPage) {
      if (oldPage !== page && oldPage && page) {
        await this.GET_RESOURCE({ id: this.id, page });
      }
    },
    routePage(newVal) {
      this.current_page = newVal * 1;
    },
    id: {
      handler(newVal) {
        const page_query = this.routePage;
        this.current_page = page_query ? page_query * 1 : 1;
        const id = newVal;
        this.GET_RESOURCE({ id, page: this.current_page });
      },
      immediate: true,
    },
  },
};
</script>
