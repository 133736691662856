import { mapActions } from "vuex";
import {
  ADD_LANDING_TEACHER,
  ADD_LANDING_TEACHER_IMAGE,
  GET_LANDING_TEACHERS,
  GET_LANDING_TEACHERS_OPTIONS,
} from "@/store/landing-teachers";

export default {
  computed: {
    landingTeachersOptions() {
      return this.$store.getters.landing_teachers_options["programs"];
    },
    landingTeachers() {
      return this.$store.getters.landing_teachers;
    },
  },
  methods: {
    ...mapActions([
      GET_LANDING_TEACHERS,
      GET_LANDING_TEACHERS_OPTIONS,
      ADD_LANDING_TEACHER,
      ADD_LANDING_TEACHER_IMAGE,
    ]),
    getJustTeachers() {
      this[GET_LANDING_TEACHERS]("");
    },
    getTeacherLandingOptions() {
      this[GET_LANDING_TEACHERS_OPTIONS]();
    },
    addAllTeachers(teachers) {
      for (let i of teachers) {
        const data = {
          name: i.name,
          position: i.position,
          courses: i.courses,
          profession: i.profession,
          education: i.education,
          programs: i.programs,
        };
        this[ADD_LANDING_TEACHER](data);
      }
    },
    getAllTeachers() {
      const { teacherType, searchText } = this;
      let data = "?";

      if (teacherType.length > 0) {
        teacherType.forEach((type) => {
          data += "programs=" + type.value + "&";
        });
      }
      data += "search=" + searchText;
      this.$store.dispatch(GET_LANDING_TEACHERS, data);
    },
    requireImage(imagePath) {
      if (imagePath) return require(`@/assets/images/landing/${imagePath}`);
      return "";
    },
  },
};
