<template>
  <ResourceBlockCard>
    <QuestionText :text="content" />
    <AttachmentBlock :attachments="attachments" />
    <div class="w-full flex flex-wrap space-y-2 mt-2">
      <label
        v-for="(question_answer, index) in available_answers"
        :key="`answer_${question_answer.id}`"
        class="cursor-pointer"
        :class="[
          hasImages
            ? 'w-1/2 flex flex-col-reverse place-items-center '
            : ' w-full bg-gray-50 p-2 flex place-items-center mb-2 rounded-xl',
          is_disabled &&
          question_answer.is_correct &&
          selected_answer === question_answer.id
            ? 'border-green-600 border-[1px]'
            : '',
          is_disabled &&
          !question_answer.is_correct &&
          selected_answer === question_answer.id
            ? 'border-red-600 border-[1px]'
            : '',
        ]"
        :for="`answer_${question_answer.id}`"
      >
        <input
          type="radio"
          v-model="selected_answer"
          :value="question_answer.id"
          :id="`answer_${question_answer.id}`"
          :disabled="is_disabled"
          :class="{
            'text-green-600': is_disabled && question_answer.is_correct,
            'text-red-600': is_disabled && !question_answer.is_correct,
          }"
        />
        <span
          class="ml-2 quill"
          v-if="!question_answer.image"
          v-html="question_answer.text"
        />
        <div
          v-else
          class="flex place-content-between relative"
          :ref="`imageContainer_${index}`"
        >
          <img
            :src="question_answer.image"
            class="mb-1 rounded-xl max-w-[13rem]"
          />
          <TheButton
            bg="white"
            class="absolute bottom-2 right-2"
            size="sm"
            @click="changeSize(index)"
          >
            <ZoomInIcon class="text-indigo-500 h-4" v-if="!isZoomed" />
            <ZoomOutIcon class="text-indigo-500 h-4" v-else />
          </TheButton>
        </div>
      </label>
    </div>
  </ResourceBlockCard>
</template>
<script>
import CommonQuestionMixin from "@/components/Resources/ResourceBlock/Questions/CommonQuestionMixin";
import QuestionText from "@/components/Resources/ResourceBlock/Questions/QuestionText";
import ResourceBlockCard from "@/components/Resources/ResourceBlock/ResourceBlockCard";
import AttachmentBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
import { ZoomInIcon, ZoomOutIcon } from "@heroicons/vue/solid";
import TheButton from "@/components/Button/Button";
export default {
  props: {
    available_answers: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TheButton,
    AttachmentBlock,
    ResourceBlockCard,
    QuestionText,
    ZoomInIcon,
    ZoomOutIcon,
  },
  mixins: [CommonQuestionMixin],
  data: () => ({
    selected_answer: null,
    isZoomed: false,
  }),
  methods: {
    changeSizeUp(index) {
      this.$refs[`imageContainer_${index}`][0].classList.add("absolute");
      this.$refs[`imageContainer_${index}`][0].classList.add("top-10");
      this.$refs[`imageContainer_${index}`][0].classList.remove("relative");
      this.$refs[`imageContainer_${index}`][0].firstChild.classList.remove(
        "max-w-[13rem]"
      );
      this.$refs[`imageContainer_${index}`][0].firstChild.style.width = "900px";
      this.isZoomed = true;
    },
    changeSizeDown(index) {
      this.$refs[`imageContainer_${index}`][0].classList.add("relative");
      this.$refs[`imageContainer_${index}`][0].classList.remove("absolute");
      this.$refs[`imageContainer_${index}`][0].classList.remove("top-10");

      this.$refs[`imageContainer_${index}`][0].firstChild.classList.add(
        "max-w-[13rem]"
      );
      this.isZoomed = false;
    },
    changeSize(index) {
      if (this.isZoomed) {
        this.changeSizeDown(index);
      } else {
        this.changeSizeUp(index);
      }
    },
  },
  computed: {
    hasImages() {
      return !!this.available_answers.find((el) => el.image);
    },
  },
  created() {
    const answer = this.available_answers.find((el) => el.is_selected);
    this.selected_answer = answer ? answer.id : null;
    this.$watch("selected_answer", (val) => {
      this.submitAnswer("", val);
    });
  },
};
</script>
