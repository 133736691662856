<template>
<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.998047" width="64" height="64" rx="16" fill="#7D69FC"/>
<path d="M32 32.9977L18.572 27.6337L19.4 47.3977H15.8L16.664 26.8597L14 25.7977L32 18.5977L50 25.7977L32 32.9977ZM32 23.9977C31.01 23.9977 30.2 24.3937 30.2 24.8977C30.2 25.4017 31.01 25.7977 32 25.7977C32.99 25.7977 33.8 25.4017 33.8 24.8977C33.8 24.3937 32.99 23.9977 32 23.9977ZM32 34.7977L42.026 30.7837C43.304 32.4757 44.186 34.5097 44.474 36.7237C43.934 36.6517 43.376 36.5977 42.8 36.5977C38.21 36.5977 34.196 39.0637 32 42.7357C30.8853 40.865 29.304 39.3159 27.4109 38.2399C25.5177 37.164 23.3776 36.5981 21.2 36.5977C20.624 36.5977 20.066 36.6517 19.526 36.7237C19.814 34.5097 20.696 32.4757 21.974 30.7837L32 34.7977Z" fill="white"/>
<path d="M26.434 30.354C26.434 29.598 26.6487 29.0147 27.078 28.604C27.5167 28.1934 28.0813 27.988 28.772 27.988C29.4627 27.988 30.0227 28.1934 30.452 28.604C30.8813 29.0147 31.096 29.598 31.096 30.354C31.096 31.11 30.8813 31.6934 30.452 32.104C30.0227 32.5147 29.4627 32.72 28.772 32.72C28.0813 32.72 27.5167 32.5147 27.078 32.104C26.6487 31.6934 26.434 31.11 26.434 30.354ZM35.94 28.142L30.55 37.998H28.24L33.63 28.142H35.94ZM28.758 29.388C28.31 29.388 28.086 29.71 28.086 30.354C28.086 30.9887 28.31 31.306 28.758 31.306C28.9727 31.306 29.1407 31.2267 29.262 31.068C29.3833 30.9094 29.444 30.6714 29.444 30.354C29.444 29.71 29.2153 29.388 28.758 29.388ZM33.098 35.786C33.098 35.03 33.3127 34.4467 33.742 34.036C34.1713 33.6254 34.7313 33.42 35.422 33.42C36.1127 33.42 36.6727 33.6254 37.102 34.036C37.5313 34.4467 37.746 35.03 37.746 35.786C37.746 36.542 37.5313 37.1254 37.102 37.536C36.6727 37.9467 36.1127 38.152 35.422 38.152C34.7313 38.152 34.1713 37.9467 33.742 37.536C33.3127 37.1254 33.098 36.542 33.098 35.786ZM35.408 34.82C35.1933 34.82 35.0253 34.8994 34.904 35.058C34.792 35.2167 34.736 35.4594 34.736 35.786C34.736 36.4207 34.96 36.738 35.408 36.738C35.6227 36.738 35.7907 36.6587 35.912 36.5C36.0333 36.3414 36.094 36.1034 36.094 35.786C36.094 35.4687 36.0333 35.2307 35.912 35.072C35.7907 34.904 35.6227 34.82 35.408 34.82Z" fill="white"/>
</svg>


</template>

<script>
export default {

}
</script>

<style>

</style>