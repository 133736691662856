<template>
  <div class="courses-form-container">
    <div class="courses-form">
      <div class="courses-form-text">
        <div class="courses-form-text1">Поможем в выборе профессии</div>
        <div class="courses-form-text2">
          Если у вас есть вопросы и вам нужна помощь, оставьте контактные
          данные, и мы ответим на ваши вопросы и поможем найти подходящий курс.
        </div>
      </div>

      <div class="courses-form-request">
        <div class="form__item flex flex-col gap-4">
          <InputText
            :placeholder="$t('organization_name')"
            :class="{ 'border-error': !isOrganizationValid }"
            v-model="organization"
            label="Наименование организации"
            required
          />

          <InputText
            placeholder="Введите имя"
            v-model="personName"
            is-required
            :class="{ 'border-error': !isPersonNameValid }"
            :label="$t('how_to_call_you')"
          />
          <InputText
            v-model="phone"
            is-phone-number
            is-required
            placeholder="+7"
            label="Номер телефона"
            :class="{ 'border-error': !isPhoneValid }"
          />
          <InputText
            type="email"
            :placeholder="$t('your_email_optional')"
            v-model="email"
            label="E-mail (необязательно)"
          />
          <InputText
            :label="$t('which_program')"
            placeholder="Введите название пограммы"
            v-model="programName"
            :class="{ 'border-error': !isPhoneValid }"
            is-required
          />
          <TheButton
            type="submit"
            bg="primary"
            class="mt-4"
            @click.prevent="submitForm"
          >
            Оставить заявку
          </TheButton>
          <p class="text-gray-200" v-if="isFormSubmitted">
            {{ $t("form_request_done") }}
          </p>
          <!--          <p class="text-gray-500" v-if="isEmailError">-->
          <!--            Введите правильный email!-->
          <!--          </p>-->
          <div class="text-center font-normal text-base text-gray-200">
            Отправляя заявку, вы даёте согласие на обработку своих персональных
            данных в соответствии с
            <span class="form__approval-link">
              политикой конфиденциальности
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/InputText.vue";
import TheButton from "@/components/Button/Button.vue";

import "vue-tel-input/vue-tel-input.css";

import { parsePhoneNumberFromString } from "libphonenumber-js";
import { mapActions } from "vuex";
import { WANT_TO_LEARN } from "@/store/landing-form";
import TheLandingFormMixin from "@/views/core/Landing/SliderComponents/TheLandingFormMixin";

export default {
  components: {
    InputText,
    TheButton,
  },
  mixins: [TheLandingFormMixin],
  props: {
    formData: {
      type: Object,
    },
  },
  watch: {
    formData: {
      deep: true,
      handler(newFormData) {
        this.personName = newFormData.personName || this.personName;
        this.phone = newFormData.phone || this.phone;
        this.email = newFormData.email || this.email;
      },
    },
  },
  data() {
    return {
      country: null,
      personName: "",
      phone: "",
      email: "",
      organization: "",
      programName: "",
      isPersonNameValid: true,
      isOrganizationValid: true,
      isPhoneValid: true,
      isProgramValid: false,
      isFormSubmitted: false,
    };
  },
  methods: {
    ...mapActions([WANT_TO_LEARN]),
    onInput(value) {
      const phoneNumber = parsePhoneNumberFromString(value);
      if (phoneNumber && phoneNumber.country) {
        this.country = phoneNumber.country;
      }
    },
    validateInputs() {
      this.isPersonNameValid = this.personName !== "";
      this.isOrganizationValid = this.organization !== "";
      this.isPhoneValid = this.phone !== "";
      this.isProgramValid = this.program !== "";
      return (
        this.isPersonNameValid &&
        this.isPhoneValid &&
        this.organization &&
        this.isProgramValid
      );
    },
    submitForm() {
      if (this.validateInputs()) {
        this.sendWantToLearnForm();
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.form__approval-link {
  color: #786ce6;
}
.border-error {
  border: 1px solid rgb(239, 68, 68);
}
input::placeholder {
  color: #6b7280;
}
.courses-list div {
  //align-self: self-start;
}

.courses-form-container {
  padding: 120px 80px 110px 80px;
  background: white;
}

.courses-form {
  display: flex;
  flex-wrap: wrap;
  padding: 64px 80px;
  background: #2b2e3b;
  border-radius: 40px;
}

.courses-form-text {
  flex-basis: 50%;
  padding-right: 20px;
}

.courses-form-request {
  flex-basis: 50%;
  padding-left: 20px;
}

.courses-form-text1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  background: linear-gradient(
    89.23deg,
    #f98aff -1.14%,
    #b089ff 31.11%,
    #878eff 69.22%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 15px;
}

.courses-form-text2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #e5e7eb;
}

form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 650px) {
  .courses-form-container {
    padding: 48px 16px;
  }
  .courses-form {
    padding: 32px 20px;
    gap: 32px;
  }
  .courses-form-text {
    flex-basis: 100%;
  }
  .courses-form-text1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .courses-form-text2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .courses-form-request {
    flex-basis: 100%;
    padding: 0;
  }
}
</style>
