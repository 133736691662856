<template>
  <div class="w-full">
    <WelcomeBanner class="mb-7" v-if="active_user.show_entrance_quiz"/>
    <p class="text-2xl text-gray-900 font-semibold mb-6">{{$t('popular_courses')}}</p>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
      <CourseCard
          v-for="course in courses"
          :key="`course_${course.id}`"
          :id="course.id"
          :name="course.name"
          :logo="course.logo"
          :specializations="course.specializations"
          :start_date="course.start_date"
          :end_date="course.end_date"
          :duration="course.duration"
          :instructors="course.instructors"
          :destination="'overview'"
      />
    </div>
  </div>
</template>
<script>
import WelcomeBanner from "./WelcomeBanner.vue";
import CourseCard from "@/components/Courses/Card/CourseCard.vue";
import DashboardPageMixin from "@/views/dashboard/DashboardPageMixin";
// import NotificationBanner from "@/views/core/Notifications/NotificationBanner"
export default{
  components:{
    // NotificationBanner,
    WelcomeBanner, CourseCard
  },
  mixins:[DashboardPageMixin]
}
</script>
