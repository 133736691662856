<template>
  <router-link   custom :to="`/courses/${id}/overview`" v-slot="{ navigate, href }">
<!--    todo ask Yerdaulet to put sizes -->
    <a @click="navigate" :href="href" class="p-7 flex bg-white  rounded-xl shadow-lg" style="min-height: 136px">
      <img :src="logo" class=" hidden lg:flex rounded-md" style="width: 108px;height: 84px" />
      <div class="ml-3">
        <CourseCardSpecializations :specializations="specializations"/>
        <p class="text-gray-900 text-xl font-semibold mt-2" >{{name}}</p>
        <div class="text-gray-900 text-sm font-medium flex mt-2">
          <CalendarIcon class="h-5 w-5 mr-1"/>
          {{formattedStartDate}}
          <ArrowNarrowRightIcon class="h-5 w-5"/>
          {{formattedEndDate}}
        </div>
      </div>
    </a>
  </router-link>
</template>
<script>
  import CourseCard from "@/components/Courses/Card/CourseCard";
  import  {CalendarIcon,ArrowNarrowRightIcon} from "@heroicons/vue/outline";

  export default {
    mixins:[CourseCard],
    components:{
      ArrowNarrowRightIcon,CalendarIcon
    }
  }
</script>