import {mapFields} from "vuex-map-fields";
import {FORGOT_PASSWORD_REQUEST} from "@/store/auth";
import {mapActions} from "vuex";

export default {
    data:()=>({
        show_password:false
    }),
    computed:{
        ...mapFields([
            'auth.forgot_password_form.password',
            'auth.forgot_password_form.password_confirmation',
            'auth.forgot_password_form.token'
        ])
    },

    methods:{
        ...mapActions({FORGOT_PASSWORD_REQUEST}),
        submit(){
            const {password,password_confirmation,token}= this
            this.FORGOT_PASSWORD_REQUEST({password,password_confirmation,token})
        }
    }
}
