<template>
  <div class="flexibility">
    <div class="text-4xl font-bold adaptive-block-title">
      Гибкость и актуальность программы
    </div>
    <div class="flex gap-6 adaptive-flexibility">
      <div class="flex flex-col gap-10 px-8 py-10 demanded__box-1">
        <div class="text-2xl font-semibold text-gray-900">
          Каждый месяц мы общаемся с экспертами и руководителями компаний на
          предмет новых требований к вакансиям и обязанностям внутри них.
        </div>
        <div class="font-normal text-lg text-gray-500">
          Мы регулярно обновляем нашу программу обучения. Даже после вашего
          выпуска программа обучения продолжит обновление, вы всегда сможете
          вернуться и увидеть обновленные материалы.
        </div>
      </div>
      <div
        class="flex flex-col gap-10 px-8 py-10 demanded__box-2"
        :style="{ background: color }"
      >
        <div>
          <div class="text-xl pb-3">
            Вы можете менять специализацию, если понимаете, что ваш текущий
            выбор вас не устраивает.
          </div>
          <div class="text-xl">
            Программы специализаций частично совпадают, поэтому можно изменить
            одну специализацию на другую, для этого нужно досдать оставшуюся
            часть программы.
          </div>
        </div>
        <div
          class="text-2xl flex items-center font-medium justify-between px-10 adaptive-div"
        >
          <div class="flex whitespace-nowrap">
            {{ isDesign ? "🧑🏻‍🎨 Дизайнер" : "👩🏻‍💻Программист" }}
          </div>
          <div class="adaptive-arrow"><PurpleArrowIcon /></div>
          <div class="adaptive-arrow2">
            <svg
              width="81"
              height="81"
              viewBox="0 0 81 81"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41.1442 18.4553C45.9295 19.511 46.458 19.2401 51.2455 20.2854C50.0547 26.8111 49.0124 33.3544 47.5989 39.8446C47.01 42.5481 46.0144 45.2792 45.6084 48.0128C45.5973 48.087 50.6328 48.9785 52.1062 49.3915C46.8546 55.2201 41.7133 61.1856 36.174 66.7555C33.2779 60.029 30.5946 51.8762 28.4425 44.7667C29.9692 44.6472 33.9273 45.5053 35.4513 45.8449C36.228 43.6512 36.4787 41.4062 36.9936 39.1401C38.4445 32.7533 40.1494 24.9263 41.1442 18.4553Z"
                stroke="#786CE6"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M39.6886 63.1053C37.1302 60.6301 34.2464 57.9124 31.8262 55.3029"
                stroke="#786CE6"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M42.8828 59.6719C38.3847 55.6407 33.2926 51.1347 29.0696 46.8112"
                stroke="#786CE6"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M45.9258 56.2734C42.1742 52.9451 38.181 49.1006 34.5278 45.6633"
                stroke="#786CE6"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M45.6152 48.0195C42.82 45.8476 39.4613 43.0979 36.6201 40.9917"
                stroke="#786CE6"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M46.9014 42.5481C44.174 39.8821 40.8204 37.4257 37.9308 34.9279"
                stroke="#786CE6"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M48.1673 37.1144C45.5223 34.3867 41.9519 31.7906 39.1713 29.1832"
                stroke="#786CE6"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M49.1543 31.9531C46.2861 29.5434 42.9925 26.891 40.094 24.5156"
                stroke="#786CE6"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M50.0703 26.8868C47.3273 24.2983 44.0475 21.7228 40.982 19.5091"
                stroke="#786CE6"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="flex whitespace-nowrap">
            {{ isDesign ? "👩🏻‍💻 Программист" : "🧐 Аналитик " }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PurpleArrowIcon from "../../../../assets/icons/PurpleArrowIcon.vue";

export default {
  props: {
    isDesign: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "#E4D7FF",
    },
  },
  components: {
    PurpleArrowIcon,
  },
};
</script>

<style scoped>
.flexibility {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.demanded__box-1 {
  flex-basis: 50%;
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-2 {
  flex-basis: 50%;
  background: #eee6ff;
  border-radius: 32px;
}

.text-gray-50 {
  color: #f9fafb;
}
.adaptive-arrow {
  display: flex;
}
.adaptive-arrow2 {
  display: none;
}
.adaptive-div {
  gap: 80px;
}

@media (max-width: 650px) {
  .flexibility {
    padding: 48px 16px;
  }
  .adaptive-flexibility {
    flex-direction: column;
  }
  .adaptive-arrow {
    display: none;
  }
  .adaptive-arrow2 {
    display: flex;
  }
  .adaptive-div {
    flex-direction: column;
  }
  .adaptive-block-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}
</style>
