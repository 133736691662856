<template>
    <TheModal :show="user_exists">
    <div class="p-5">
      <div class="flex place-content-between items-center mb-5">
        <div class="text-xl leading-7 font-semibold text-gray-900" >{{ $t('user_exists') }}</div>
        <XIcon class="h-4 cursor-pointer text-gray-400 " @click="user_exists = false"/>
      </div>
    </div>
  </TheModal>
    <TheModal :show="showModal" class="mt-20">
        <div class="p-5  min-w-max truncate">
            <div class="flex place-content-between items-center mb-5">
                <div class="text-xl leading-7 font-semibold text-gray-900">{{$t('add_user')}}</div>
                <XIcon class="h-4 cursor-pointer text-gray-400 " @click="$emit('closeModal')"/>
            </div>
            <InputText class="mb-3" :errors="[is_name?'': $t('enter_full_name')]" :placeholder="$t('enter_full_name')" v-model="userform.full_name">
                <template #label><span class="text-gray-700">{{$t('name')}}</span></template>
            </InputText>
            <InputText class="mb-3" :errors="[is_phone_number?'': $t('enter_phone_number')]" :placeholder="$t('enter_phone_number')" v-model="userform.phone_number" :isPhoneNumber="true">
                <template #label><span class="text-gray-700">{{$t('phone_number')}}</span></template>
            </InputText>
            <InputText class="mb-3" :errors="[is_email?'': $t('enter_email')]" :placeholder="$t('enter_email')" v-model="userform.email">
                <template #label><span class="text-gray-700">{{$t('email')}}</span></template>
            </InputText>
            <!-- <div class="mb-3">
                <label for="course-selector"><span class="text-gray-700">{{$t('course')}}</span></label>
                <CoursesSelector
                    id="course-selector"
                    label="name"
                    v-model="userform.course_id"
                    :placeholder="$t('select_course')" 
                    class="bg-white text-sm leading-5 font-medium mb-3 flex-wrap break-word"/>
            </div> -->
            <div class="mb-3">
                <label for="role-selector"><span class="text-gray-700">{{$t('role')}}</span></label>
                <RolesSelector
                    id="role-selector"
                    label="name"
                    v-model="userform.roles"
                    multiple
                    :placeholder="$t('select_role')" 
                    class="bg-white text-sm leading-5 font-medium mb-3 flex-wrap break-word"/>
            </div>
            <!-- <div class="mb-3">
                <label for="permission-selector"><span class="text-gray-700">{{$t('permissions')}}</span></label>
                <PermissionsSelector
                    id="permission-selector"
                    label="name"
                    multiple
                    v-model="userform.permission"
                    :placeholder="$t('select_permission')" 
                    class="bg-white text-sm leading-5 font-medium mb-3 flex-wrap break-word"/>
            </div> -->
            <InputText :errors="[is_password?'': $t('enter_password')]" :placeholder="$t('enter_password')" v-model="userform.password">
                <template #label><span class="text-gray-700">{{$t('password')}}</span></template>
            </InputText>
            <span class="text-sm text-gray-500 mb-4">{{$t('password_text')}}</span>
            <InputCheckbox @click.stop="generatePassword($event)"><template #label><span class="text-gray-700">{{$t('generate_password')}}</span></template></InputCheckbox>
            <TheCard class="mt-4 bg-gray-50">
                <div class="flex place-content-between">
                    <span class="text-gray-700">
                        {{$t('add_email_subscription')}}
                    </span>
                    <Switch v-model="userform.email_subscription" :class="[userform.email_subscription ? 'bg-indigo-600' : 'bg-gray-200', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500']">
                        <span aria-hidden="true" :class="[userform.email_subscription ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']" />
                    </Switch>
                </div>
            </TheCard>
            <div class="mt-4 flex flex-row place-content-end">
                <TheButton bg="primary" @click="addNewUser">{{$t('add_user')}}</TheButton>
            </div>
        </div>
    </TheModal>
</template>
<script>
import TheModal from "@/components/TheModal/TheModal.vue";
import InputText from "@/components/InputText.vue";
import TheButton from "@/components/Button/Button";
import { XIcon } from "@heroicons/vue/outline";
// import CoursesSelector from "@/components/Selectors/CoursesSelector";
import RolesSelector from "@/components/Selectors/RolesSelector";
// import PermissionsSelector from "@/components/Selectors/PermissionsSelector";
import InputCheckbox from "@/components/InputCheckbox.vue";
import { Switch } from "@headlessui/vue";
import TheCard from "@/components/TheCard/TheCard.vue";
import {CREATE_USER_ROLE, UPDATE_USER_ROLE} from "@/store/users"
import { mapActions } from 'vuex'

export default{
    props:{
        showNewUserModal:Boolean,
        user:Object
    },
    components:{
        TheModal,
        InputText,
        TheButton,
        XIcon,
        // CoursesSelector,
        RolesSelector,
        // PermissionsSelector,
        InputCheckbox,
        Switch,
        TheCard
    },
    data:()=>{
        return {
            is_name:false,
            is_phone_number:false,
            is_email:false,
            is_password:false,
            showModal:false,
            userform:null,
            user_exists:false
        }
    },
    created(){
        if(this.user == null){
            this.userform = {
                full_name:'',
                phone_number:'',
                email:'',
                email_subscription:false
            }
        }
    },
    methods:{
        ...mapActions([CREATE_USER_ROLE, UPDATE_USER_ROLE]),
        generatePassword(event, lower=true, upper=true, number=true, symbol=true) {
            if(!event.target.checked){ 
                return
            }
            const randomFunc = {
                lower: getRandomLower,
                upper: getRandomUpper,
                number: getRandomNumber,
                symbol: getRandomSymbol
            }
            let generatedPassword = '';
            const typesCount = lower + upper + number + symbol;
            const typesArr = [{lower}, {upper}, {number}, {symbol}].filter(item => Object.values(item)[0]);
            if(typesCount === 0) {
                return '';
            }
            for(var i=0; i<6; i+=1) {
                typesArr.forEach(type => {
                    const funcName = Object.keys(type)[0];
                    generatedPassword += randomFunc[funcName]();
                });
            }
            const finalPassword = generatedPassword.slice(0, 6);
            this.userform.password = finalPassword
            return finalPassword;
            
            function getRandomLower() {
                return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
            }
            function getRandomUpper() {
                return String.fromCharCode(Math.floor(Math.random() * 26) + 65);
            }
            function getRandomNumber() {
                return +String.fromCharCode(Math.floor(Math.random() * 10) + 48);
            }
            function getRandomSymbol() {
                const symbols = '!@#$.+'
                return symbols[Math.floor(Math.random() * symbols.length)];
            }
        },
        async addNewUser(){
            var arr = this.userform.full_name.split(' ')
            if(arr.length == 1){
                this.userform.last_name = arr[0]
            } else if(arr.length == 2){
                this.userform.last_name = arr[0]
                this.userform.first_name = arr[1]
            } else if(arr.length == 3){
                this.userform.last_name = arr[0]
                this.userform.first_name = arr[1]
                this.userform.patronymic_name = arr[2]
            }
            if(!this.is_email || !this.is_name || !this.is_password || !this.is_phone_number){ return }
            if(!this.userform.id && (!this.userform.password || this.userform.password.trim() === "")){
                this.is_password = false
                return
            }
            var action = this.userform.id ? UPDATE_USER_ROLE : CREATE_USER_ROLE
            this[action]({...this.userform}).then(() => {
                this.$router.go()
            }).catch(() => {
                this.user_exists = true})
        }
    },
    watch:{
        showNewUserModal:function(new_val){
            this.showModal = new_val
        },
        'userform.full_name':function(new_val){
            if(new_val.trim() === ''){
                this.is_name = false
            } else {
                this.is_name = true
            }
            var arr = new_val.split(' ')
            if(arr.length == 1){
                this.userform.last_name = arr[0]
            } else if(arr.length == 2){
                this.userform.last_name = arr[0]
                this.userform.first_name = arr[1]
            } else if(arr.length == 3){
                this.userform.last_name = arr[0]
                this.userform.first_name = arr[1]
                this.userform.patronymic_name = arr[2]
            }
        },
        'userform.phone_number': function(new_val){
            if(new_val == null || new_val.trim().length < 16){
                this.is_phone_number=false
            } else {
                this.is_phone_number=true
            }
        },
        'userform.email': function(new_val){
            if (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(new_val.trim())){
                this.is_email = true
            } else {
                this.is_email = false
            }
        },
        'userform.password': function(new_val){
            if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$.+]).{6,}$/.test(new_val) && !/\s/g.test(new_val)){
                this.is_password = true
            } else {
                this.is_password = false
            }
        },
        showModal:function(new_val){
            if(new_val){
                this.is_name=true
                this.is_phone_number=true
                this.is_email=true
                this.is_password=true
            }
        },
        user:{
            deep:true,
            handler:function(new_val){
                this.userform = {...new_val}
                this.userform.roles = this.userform.roles.map(r => r.id)
            }
        }
    }
}
</script>