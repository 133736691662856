<template>
  <div>
    <TheCoursePageHeader/>
    <main class="flex-1 pt-16 px-8 bg-gray-100 ">
      <div className="pt-7 min-h-screen	">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>
<script>

import TheCoursePageHeader from "@/components/ThePageHeader/TheCoursePageHeader";
export default {
  components: {TheCoursePageHeader}
}
</script>
