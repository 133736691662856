<template>
  <div class="w-full h-full partners">
    <div class="flex h-full adaptive-div">
      <div class="h-12 flex items-center justify-center adaptive-div2">
        <div style="max-width: 624px">
          <h1 class="text-3xl font-bold text-indigo-50 adaptive-title">
            <span style="color: #b3a6f9">1 500</span> выпускников уже изменили
            свой путь с помощью Alpha.Edu. Попробуйте и вы!
          </h1>
          <TheButton
            bg="primary"
            class="mt-12 gap-2"
            style="max-width: 230px; padding: 16px 24px; border-radius: 32px"
            @click="scrollToForm"
            >Начать обучение
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
            >
              <path
                d="M6.91667 0.416551C6.83947 0.49282 6.77817 0.583654 6.73634 0.683788C6.69451 0.783922 6.67297 0.891363 6.67297 0.999884C6.67297 1.1084 6.69451 1.21585 6.73634 1.31598C6.77817 1.41611 6.83947 1.50695 6.91667 1.58322L8.5 3.16655H0.833333C0.375 3.16655 0 3.54155 0 3.99988C0 4.45822 0.375 4.83322 0.833333 4.83322H8.5L6.91667 6.41655C6.83947 6.49282 6.77817 6.58365 6.73634 6.68379C6.69451 6.78392 6.67297 6.89136 6.67297 6.99988C6.67297 7.10841 6.69451 7.21585 6.73634 7.31598C6.77817 7.41611 6.83947 7.50695 6.91667 7.58322C7.24167 7.90822 7.75833 7.90822 8.08333 7.58322L11.075 4.59155C11.1523 4.51446 11.2135 4.42288 11.2554 4.32207C11.2972 4.22126 11.3187 4.11319 11.3187 4.00405C11.3187 3.89491 11.2972 3.78684 11.2554 3.68603C11.2135 3.58522 11.1523 3.49365 11.075 3.41655L8.08333 0.416551C8.00706 0.339351 7.91623 0.278059 7.8161 0.236226C7.71596 0.194393 7.60852 0.172852 7.5 0.172852C7.39148 0.172852 7.28404 0.194393 7.1839 0.236226C7.08377 0.278059 6.99294 0.339351 6.91667 0.416551Z"
                fill="white"
              />
            </svg>
          </TheButton>
          <!-- <p class="text-lg text-indigo-50">Небольшой текст описание к этому блоку на пару строк небольшой текст описание к этому блоку на пару строк небольшой текст описание к этому блоку на пару строк</p> -->
        </div>
      </div>
      <div class="flex partners-div">
        <div class="partners-logo flex flex-col gap-10">
          <div style="color: #f9fafb; font-weight: 500">
            С нами сотрудничают:
          </div>
          <div class="flex flex-col gap-10 adaptive-logo-box">
            <div class="flex gap-10 adaptive-logo">
              <img src="../../../../src/assets/images/landing/MNU-1.svg" />

              <img src="../../../../src/assets/images/landing/oraclus.svg" />
              <img
                src="../../../../src/assets/images/landing/aitu-logo-white-2.svg"
              />
              <img src="../../../../src/assets/images/landing/astana-hub.svg" />
              <img src="../../../../src/assets/images/landing/alpha.svg" />
              <img
                src="../../../../src/assets/images/landing/zerdeli-vertical-logo.svg"
              />
              <img
                src="../../../../src/assets/images/landing/dvorets-shkolnikov.svg"
              />
            </div>
          </div>
          <!--          <h1 class="text-3xl font-bold text-indigo-50">Наши ключевые партнеры</h1>-->
          <!--          <p class="text-lg text-indigo-50">Небольшой текст описание к этому блоку на пару строк небольшой текст описание к этому блоку на пару строк небольшой текст описание к этому блоку на пару строк</p>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheButton from "@/components/Button/Button";

export default {
  name: "TheLandingPartners",
  components: {
    TheButton,
  },
  methods: {
    scrollToForm() {
      const formElement = document.getElementById("landing-form");
      formElement.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.partner-img {
  width: 97px;
  height: 70px;
}

.partners {
  background-color: #20222e;
  padding: 64px;
}

.partners-logo {
  max-width: 624px;
  display: flex;
  flex-direction: column;
}

.adaptive-partner-img {
  margin-left: 112px;
}
.adaptive-logo {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;
  justify-content: center;
  align-items: center;
  justify-self: center;
}
.adaptive-logo img {
  justify-self: center;
  align-self: center;
}

.adaptive-div {
  gap: 80px;
}
@media (max-width: 650px) {
  .partners {
    padding: 0;
  }

  .adaptive-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .adaptive-logo {
    display: grid;
    grid-template-columns: repeat(
      2,
      1fr
    ); /* Creates two columns with equal width */
    gap: 40px; /* Adjust the gap to your preference */
    justify-content: center; /* Centers the grid in the container if there's extra space */
    align-items: center; /* Aligns items vertically in the center */
  }

  /* Optional: If you want to make sure that the logos are centered within each grid item */
  .adaptive-logo img {
    justify-self: center; /* Horizontally center the content of each cell */
    align-self: center; /* Vertically center the content of each cell */
  }

  .adaptive-logo-box {
    gap: 40px;
    justify-content: center;
    align-items: center;
  }

  .adaptive-div {
    flex-direction: column;
    margin: 0;
    padding: 48px 16px;
  }

  .partners-logo {
    justify-content: center;
  }

  .adaptive-partner-img {
    margin-left: 0;
  }

  .adaptive-div2 {
    width: 100%;
  }

  .adaptive-partner-img-div {
    justify-content: space-between;
  }

  .partners-div {
    padding: 16px;
  }

  .img-alpha {
    width: 200px;
    margin-left: 50px;
  }
  .partners-logo {
    justify-content: center;
    align-content: center;
  }
}
</style>
