<template>
    <TheCard style="padding:0" class="criterial-grading">
        <p class="leading-6 font-semibold text-gray-800">{{$t('your_grade')}}:</p>
        <TheCard v-for="criterion in practice.criteria" :key="`criterion_${practice.id}_${criterion.id}`" class="bg-gray-50 mt-3">
            <TheCardHeader class="border-b">
                <div class="mb-4">
                    <p class="leading-6 font-medium">{{criterion.description}}</p>
                    <span class="text-sm leading-5 font-normal text-gray-500">{{$t('points_for_criterion', {point: practice.criteria_points.length != 0 && practice.criteria_points.find(cp => cp.criterion === criterion.id).point ? practice.criteria_points.find(cp => cp.criterion === criterion.id).point : 0, weight: criterion.weight})}}</span>
                </div>
            </TheCardHeader>
            <InputText :errors="[is_point ? '' : $t('enter_point_grade_less')]" :placeholder="$t('enter_point_grade')" v-model="practice.criteria_points.find(cp => cp.criterion === criterion.id).point" class="mt-2 mb-2" :disabled="practice.status==='finished'">
                <template #label>
                    <span class="text-sm leading-5 font-medium text-gray-700">{{$t('point_grade')}}</span>
                </template>
            </InputText>
            </TheCard>
            <TheCard class="bg-gray-50">
            <div class="flex justify-between">
                <span>{{$t('overall_point_grade')}}</span>
                <span><span class="text-lg leading-8 font-semibold" :class="{'text-red-600': calcCriteriaPoints < practice.threshold, 'text-green-600':calcCriteriaPoints >= practice.threshold}">{{calcCriteriaPoints}}</span>/{{calcCriteriaWeight}}</span>
            </div>
        </TheCard>
    </TheCard>
</template>
<script>
import TheCard from "../TheCard/TheCard.vue";
import TheCardHeader from "../TheCard/TheCardHeader.vue";
import InputText from "../InputText.vue";
export default{
    components:{
    TheCard,
    TheCardHeader,
    InputText,
},
    props:{
        practice:{
            type:Object
        },
        is_point:Boolean
    },
    computed:{
        calcCriteriaPoints(){
            let result
            if(this.practice.criteria_points.length > 1){
                result = this.practice.criteria_points.reduce((a,b) => {
                    if(b.point == ''){
                        return parseInt(a) + 0
                    } else 
                        return parseInt(a) + parseInt(b.point)
                }, 0)
            } else if (this.practice.criteria_points.length == 1){
                result = this.practice.criteria_points[0].point ? this.practice.criteria_points[0].point : 0
            } else {
                result = 0
            }
            return result
        },
        calcCriteriaWeight(){
            let result
            if(this.practice.criteria.length > 1){
            result = this.practice.criteria.reduce((a,b) => {return a + b.weight}, 0)
            } else {
            result = this.practice.criteria[0].weight
            }
            return result
        }
    }
}
</script>