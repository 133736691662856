import { SET_LOADING } from "@/store/core";
export const CREATE_ATTENDANCE_RECORD = "CREATE_ATTENDANCE_RECORD";
export const GET_ATTENDANCE_RECORDS = "GET_ATTENDANCE_RECORDS";

export const GET_STUDENT_ATTENDANCE_RECORDS = "GET_STUDENT_ATTENDANCE_RECORDS";
export const GET_MY_COURSE_GROUPS = "GET_MY_COURSE_GROUPS";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {
    attendance: [],
  };
  const actions = {
    [GET_MY_COURSE_GROUPS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.student_users.get_my_course_groups(params);
      commit(SET_LOADING, false);
      return data;
    },
  };
  const mutations = {};
  const getters = {};
  return {
    actions,
    state,
    mutations,
    getters,
  };
};
export default (app) => createStore(app);
