<template>
  <div class="w-full bg-indigo-700  flex pb-0 rounded-xl shadow-xl">
    <div class="flex-auto  py-7 px-12">
      <p class="text-white text-3xl font-extrabold mb-1.5">Не знаете с чего начать?</p>
      <p class="text-lg text-indigo-100 mb-8">Пройдите опрос, и мы поможем вам  выбрать курс</p>
      <span class="flex items-center">
      <TheButton bg="white" class="mr-4">Пройти опрос</TheButton>
      <p class="text-white text-indigo-100 text-base">10 вопросов · 5 мин</p>
    </span>
    </div>
    <img src="@/assets/images/big-images/welcome-banner-image.png" class="hidden lg:flex "/>
  </div>
</template>
<script>
import TheButton from "@/components/Button/Button";
export default{
  components:{TheButton}
}
</script>
