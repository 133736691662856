import {mapActions} from "vuex";
import { GET_PUBLIC_COURSES} from "@/store/courses";
import {mapFields} from "vuex-map-fields";

export default {
    computed:{
        ...mapFields(['courses.courses','core','auth.active_user'])
    },
    methods:{
        ...mapActions([GET_PUBLIC_COURSES])
    },
    async created() {
        await this.GET_PUBLIC_COURSES({type:"popular"})
    }
}