<template>
  <div
    class="flex justify-center items-center relative flex-shrink-0"
    :style="{
      'background-image':
        'url(' + require('@/assets/images/landing/banner3.png') + ')',
      'background-position': 'center',
      'background-fit': 'cover',
      padding: '64px',
      'margin-bottom': '0',
      'min-height': '670px',
      width: '100%',
      'border-radius': '30px',
    }"
  >
    <div
      class="flex flex-col items-center"
      :style="{ transform: 'translateY(20%)' }"
    >
      <div class="flex flex-col gap-3">
        <div class="adaptive-component-title">
          <div class="green">Получите новые навыки</div>
          <div class="yellow">Смените профессию</div>
          <div class="blue">Учитесь новому</div>
        </div>
        <div class="adaptive-component-title-2 relative">
          <div class="green-2">Получите новые навыки</div>
          <div class="yellow-2">Смените профессию</div>
          <div class="blue-2">Учитесь новому</div>
        </div>

        <div
          class="adaptive-component-subtitle"
          :style="{
            'font-weight': '500',
            'line-height': '115%',
            'text-align': 'center',
            color: '#fff',
          }"
        >
          Вместе с Alpha Edu
        </div>
      </div>

      <button
        @click="scrollToForm"
        class="button text-white flex justify-center items-center mt-16 cursor-pointer"
        :style="{
          padding: '16px 24px',
          gap: '8px',
          border: '2px solid #FFFFFF',
          'border-radius': '32px',
        }"
      >
        Оставить заявку

        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.0998 7.69967C13.0072 7.79119 12.9336 7.90019 12.8834 8.02035C12.8332 8.14051 12.8074 8.26944 12.8074 8.39967C12.8074 8.52989 12.8332 8.65882 12.8834 8.77898C12.9336 8.89914 13.0072 9.00814 13.0998 9.09967L14.9998 10.9997H5.7998C5.2498 10.9997 4.7998 11.4497 4.7998 11.9997C4.7998 12.5497 5.2498 12.9997 5.7998 12.9997H14.9998L13.0998 14.8997C13.0072 14.9912 12.9336 15.1002 12.8834 15.2204C12.8332 15.3405 12.8074 15.4694 12.8074 15.5997C12.8074 15.7299 12.8332 15.8588 12.8834 15.979C12.9336 16.0991 13.0072 16.2081 13.0998 16.2997C13.4898 16.6897 14.1098 16.6897 14.4998 16.2997L18.0898 12.7097C18.1825 12.6172 18.2561 12.5073 18.3062 12.3863C18.3564 12.2653 18.3822 12.1356 18.3822 12.0047C18.3822 11.8737 18.3564 11.744 18.3062 11.623C18.2561 11.5021 18.1825 11.3922 18.0898 11.2997L14.4998 7.69967C14.4083 7.60703 14.2993 7.53347 14.1791 7.48328C14.059 7.43308 13.93 7.40723 13.7998 7.40723C13.6696 7.40723 13.5406 7.43308 13.4205 7.48328C13.3003 7.53347 13.1913 7.60703 13.0998 7.69967Z"
            fill="white"
          />
        </svg>
      </button>
    </div>

    <SvgOne class="svg-one" />
    <SvgTwo class="svg-two" />
    <SvgThree class="svg-three" />
  </div>
</template>

<script>
import SvgOne from "@/views/core/Landing/SliderComponents/SvgOne.vue";
import SvgTwo from "@/views/core/Landing/SliderComponents/SvgTwo.vue";
import SvgThree from "@/views/core/Landing/SliderComponents/SvgThree.vue";

export default {
  name: "ComponentTwo",
  components: { SvgThree, SvgTwo, SvgOne },
  data() {
    return {
      items: [
        { key: 1, text: "Получите новые навыки", position: "above" },
        { key: 2, text: "Смените профессию", position: "below" },
        { key: 3, text: "Учитесь новому", position: "below" },
      ],
      nextKey: 2,
      titles: ["Получите новые навыки", "Смените профессию", "Учитесь новому"],
      subtitles: ["Subtitle 1", "Subtitle 2", "Subtitle 3"],
      colors: ["green", "yellow", "blue"],
      svgData: [SvgOne, SvgTwo, SvgThree],
      currentIndex: 0,
    };
  },

  computed: {
    currentTitle() {
      return this.titles[this.currentIndex % this.titles.length];
    },
    currentColor() {
      return this.colors[this.currentIndex % this.colors.length];
    },
    currentSubtitle() {
      return this.subtitles[this.currentIndex % this.subtitles.length];
    },
    currentSvgData() {
      return this.svgData[this.currentIndex % this.svgData.length];
    },
  },

  methods: {
    scrollToForm() {
      const formElement = document.getElementById("landing-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
  // components: { ArrowNarrowRightIcon },
};
</script>

<style scoped lang="css">
.adaptive-component-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 115%;
  text-align: center;
  overflow: hidden;
  height: 80px;
}
.adaptive-component-title-2 {
  display: none;
}

.adaptive-component-subtitle {
  font-size: 36px;
}
@media (max-width: 650px) {
  .adaptive-component-title {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    display: none;
  }
  .adaptive-component-title-2 {
    display: flex;
    flex-direction: column; /* Align text vertically */
    align-items: center; /* Align text horizontally */
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    margin: 48px 0 8px 0; /* Add spacing between title and subtitle */
    position: relative;
  }

  .adaptive-component-subtitle {
    font-size: 22px;
    text-align: center; /* Center align subtitle */
  }

  .green-2 {
    color: #90e37c;
    animation: fadeAndAppearOne 5.5s ease-in-out infinite forwards;
  }

  .yellow-2 {
    color: #ffd483;
    animation: fadeAndAppearTwo 5.5s ease-in-out infinite forwards;
  }

  .blue-2 {
    color: #6880ff;
    animation: fadeAndAppearThree 5.5s ease-in-out infinite forwards;
  }

  .green-2,
  .yellow-2,
  .blue-2 {
    position: absolute;
    bottom: 0;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.green,
.yellow,
.blue {
  opacity: 0; /* Start both texts fully transparent */
}

.green {
  color: #90e37c;
  animation: appearAndFadeGreen 5.5s ease-in-out infinite forwards;
}

.yellow {
  color: #ffd483;
  animation: comeFromBelowAndFadeYellow 5.5s ease-in-out infinite forwards;
}

.blue {
  color: #6880ff;
  animation: comeFromBelowAndFadeBlue 5.5s ease-in-out infinite forwards;
}

.svg-container {
  position: relative;
}
.svg-one {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transform: translate(-15%, 50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-two {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transform: translate(-40%, 40%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-three {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  transform: translate(-20%, 25%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.svg-one {
  animation: fadeAndAppearOne 5.5s ease-in-out infinite forwards;
}

.svg-two {
  animation: fadeAndAppearTwo 5.5s ease-in-out infinite forwards;
}

.svg-three {
  animation: fadeAndAppearThree 5.5s ease-in-out infinite forwards;
}

@keyframes fadeAndAppearOne {
  0% {
    opacity: 0;
  }

  16.36% {
    opacity: 1;
  }
  32.7% {
    opacity: 0;
  }
  54.5% {
    opacity: 0;
  }
}

@keyframes fadeAndAppearTwo {
  27.27% {
    opacity: 0;
  }
  45.45% {
    opacity: 1;
  }
  63.63% {
    opacity: 0;
  }
}
@keyframes fadeAndAppearThree {
  54.54%,
  100% {
    opacity: 0;
  }
  76.356% {
    opacity: 1;
  }
}

@keyframes appearAndFadeGreen {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }

  16.36% {
    transform: translateY(80%);
    opacity: 1;
  }
  32.7% {
    transform: translateY(-100%);
    opacity: 0;
  }
  54.5% {
    opacity: 0;
  }
}

@keyframes comeFromBelowAndFadeYellow {
  27.27% {
    transform: translateY(100%);
    opacity: 0;
  }
  45.45% {
    transform: translateY(-80%);
    opacity: 1;
  }
  63.63% {
    transform: translateY(100%);
    opacity: 0;
  }
}
@keyframes comeFromBelowAndFadeBlue {
  54.54%,
  100% {
    transform: translateY(130%);
    opacity: 0;
  }
  76.356% {
    transform: translateY(-180%);
    opacity: 1;
  }
}
</style>
