import development from "@/config/development.json";
import production from "@/config/production.json";


export default (app)=>{
    if (process.env.NODE_ENV === "production") {
        app.config.globalProperties.$config = Object.freeze(production);
    } else {
        app.config.globalProperties.$config = Object.freeze(development);
    }
}