<template>
  <ResourceBlockCard>
    <QuestionText :text="content"/>
    <AttachmentBlock :attachments="attachments"/>
    <div class="w-full flex flex-wrap space-y-2 mt-2">
    <label
        v-for="question_answer in available_answers"
        :key="`answer_${question_answer.id}`"
        class="cursor-pointer"
        :class="
            [
              ' w-full bg-gray-50 p-2 flex place-items-center mb-2 rounded-xl',
              is_disabled && question_answer.is_correct && selected_answers.indexOf(question_answer.id)>-1?'border-green-600 border-[1px]':'',
              is_disabled && !question_answer.is_correct && selected_answers.indexOf(question_answer.id)>-1?'border-red-600 border-[1px]':''
            ]
        "
        :for="`answer_${question_answer.id}`"
    >
      <input
          type="checkbox"
          v-model="selected_answers"
          :value="question_answer.id"
          :id="`answer_${question_answer.id}`"
          :disabled="is_disabled"
          :class="{
              'text-green-600':is_disabled && question_answer.is_correct,
              'text-red-600':is_disabled && !question_answer.is_correct
          }"
      />
      <span class="ml-2 quill" v-if="!question_answer.image" v-html="question_answer.text"/>
      <div v-else class="flex  place-content-between relative" :ref="`imageContainer_${index}`">
        <img :src="question_answer.image"  class="mb-1 rounded-xl max-w-[13rem]"  />
        <TheButton bg="white" class=" absolute bottom-2 right-2 " size="sm" @click="changeSize(index)">
          <ZoomInIcon  class="text-indigo-500 h-4" v-if="!isZoomed"/>
          <ZoomOutIcon  class="text-indigo-500 h-4" v-else/>
        </TheButton>
      </div>
    </label>
    </div>
  </ResourceBlockCard>
</template>
<script>
import CommonQuestionMixin from "@/components/Resources/ResourceBlock/Questions/CommonQuestionMixin";
import QuestionText from "@/components/Resources/ResourceBlock/Questions/QuestionText";
import ResourceBlockCard from "@/components/Resources/ResourceBlock/ResourceBlockCard";
import AttachmentBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
import TheButton from "@/components/Button/Button";
import {ZoomInIcon, ZoomOutIcon} from "@heroicons/vue/solid";

export default {
  components: {TheButton, AttachmentBlock, ResourceBlockCard, QuestionText,ZoomInIcon, ZoomOutIcon},
  mixins:[CommonQuestionMixin],
  props:{
    available_answers:{
      type:Array,
      default:()=>[]
    }
  },
  data:()=>({
    selected_answers: [],
    isZoomed:false

  }),
  methods:{
    changeSizeUp(index){
      this.$refs[`imageContainer_${index}`][0].classList.add('absolute')
      this.$refs[`imageContainer_${index}`][0].classList.add('top-10')
      this.$refs[`imageContainer_${index}`][0].classList.remove('relative')
      this.$refs[`imageContainer_${index}`][0].firstChild.classList.remove('max-w-[13rem]')
      this.$refs[`imageContainer_${index}`][0].firstChild.style.width ='900px'
      this.isZoomed=true
    },
    changeSizeDown(index){
      this.$refs[`imageContainer_${index}`][0].classList.add('relative')
      this.$refs[`imageContainer_${index}`][0].classList.remove('absolute')
      this.$refs[`imageContainer_${index}`][0].classList.remove('top-10')

      this.$refs[`imageContainer_${index}`][0].firstChild.classList.add('max-w-[13rem]')
      this.isZoomed=false
    },
    changeSize(index){
      if(this.isZoomed){
        this.changeSizeDown(index)
      }else{
        this.changeSizeUp(index)
      }
    }
  },
  computed:{
    hasImages(){
      return !!this.available_answers.find(el=>el.image)
    }
  },
  created(){
    this.selected_answers =  this.available_answers.filter(el=>el.is_selected).map(el=>el.id)
    this.$watch('selected_answers', async (val)=>{
       this.submitAnswer("",val)
      // this.available_answers = available_answers todo fix
      // this.selected_answers =  this.available_answers.filter(el=>el.is_selected).map(el=>el.id)
    })
  }

}
</script>