import {createI18n} from 'vue-i18n'
function loadLocaleMessages() {
    const locales = require.context('../assets/translations', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const messages = {}
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i)
        if (matched && matched.length > 1) {
            const locale = matched[1]
            messages[locale] = locales(key)
        }
    })
    return messages
}
function RuLocalePluralizationRule(choice, choicesLength) {
    if (choice === 0) {
        return 0
    }
    const teen = choice > 10 && choice < 20
    const endsWithOne = choice % 10 === 1
    if (!teen && endsWithOne) {
        return 1
    }
    if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
    }
    return choicesLength < 4 ? 2 : 3
}

export default  (app) =>{
    const messages = loadLocaleMessages()
    const locale = localStorage.getItem('locale')|| 'ru';
    const i18n = new createI18n({
        locale,
        pluralizationRules: {
            ru: RuLocalePluralizationRule
        },
        messages,
    })
    app.use(i18n)
}
