<template>
  <div class="relative ad-main-block">
    <div class="flex ad-container py-6">
      <div class="content flex gap-5">
        <div
          v-for="word in bannerwords"
          :key="word.k"
          class="flex flex-row px-10 custom-tab justify-center align-center"
        >
          <span class="font-semibold text-white">{{ word.name }}</span>
        </div>
      </div>
    </div>
    <div
      class="absolute right-0 cursor-pointer"
      style="top: -50px"
      @click="handleModal"
    >
      <img
        src="@/assets/images/landing/runningline.png"
        class="youtube-image"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  inject: ["openVideoModal"],
  methods: {
    handleModal() {
      return this.openVideoModal(
        "https://www.youtube.com/embed/QqhmhMaJVQ0?si=0NdUFSDmW3QmAemk"
      );
    },
  },
  data() {
    return {
      bannerwords: [
        { name: "Графический UI/UX дизайн  - старт курса - август 2024", k: 1 },
        { name: "Веб разработка  - старт курса - август 2024", k: 2 },
        {
          name: "Основы программирования на языке Python с применением ИИ - старт курса - август 2024",
          k: 3,
        },

        { name: "Графический UI/UX дизайн  - старт курса - август 2024", k: 4 },
        { name: "Веб разработка  - старт курса - август 2024", k: 5 },
        {
          name: "Основы программирования на языке Python с применением ИИ - старт курса - август 2024",
          k: 6,
        },
      ],
    };
  },
};
</script>
<style scoped>
.ad-main-block {
  margin: 100px 0px;
  background-color: #5f6be4;
}
.ad-container {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  animation: runningLine 16s linear infinite;
}

.custom-tab {
  color: #fff;
  font-size: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1.5px solid #fba564;
}

.custom-tab:last-child {
  border-right: none;
  margin-right: 0;
}

@keyframes runningLine {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-50%);
  }
}

.custom-icon {
  height: 60px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.youtube-image {
  width: 180px;
  height: 180px;
}
</style>
