<template>
  <TheModal :show="show_courses_select_modal" @close="closeModal" class="z-50" >
    <div class="p-5 truncate">
      <div class="flex place-content-between items-center mb-5">
        <div class="text-xl leading-7 font-semibold text-gray-900">{{$t('add_courses')}}</div>
        <XIcon class="h-4 cursor-pointer text-gray-400 " @click="closeModal"/>
      </div>
      <InputText :placeholder="$t('search_by_name')" v-model="filter.text">
        <template #leftIcon>
          <SearchIcon class="text-gray-400 h-5"/>
        </template>
        <template #rightButton>
          <button type="button" @click="revertOrder"
                  class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
            <component :is="filter.order_dir==='asc'?'SortDescendingIcon':'SortAscendingIcon'" class="h-5 w-5 text-gray-400" aria-hidden="true" />
            <span>{{ $t('sorting') }}</span>
          </button>
        </template>
      </InputText>
      <div class="mt-9">
        <div class="flex items-center mb-4"  v-for="course in filteredCourses" :key="`course_${course.id}`" :show="!course.is_hidden">
            <CourseCardRowSmall
                :specializations = "course.specializations"
                :name = "course.name"
                :logo="course.logo"
            />
            <input
                type="checkbox"
                class="focus:ring-indigo-700 h-6 w-6 text-indigo-600 border-gray-300 rounded"
                v-model="selected_course_ids"
                :value="course.id"
            />
        </div>
      </div>
      <div class="mt-4 flex flex-row place-content-end">
        <TheButton bg="outline" class="mr-6" @click="closeModal">{{$t('cancel')}}</TheButton>
        <TheButton bg="primary" @click="handleAddCourses">{{$t('add')}}</TheButton>
      </div>
    </div>
  </TheModal>
</template>
<script>
import { GET_PUBLIC_COURSES} from "@/store/courses";
  import TheModal from "@/components/TheModal/TheModal";
  import {mapFields} from "vuex-map-fields";
  import {XIcon,SearchIcon, SortAscendingIcon,SortDescendingIcon} from "@heroicons/vue/outline";
  import InputText from "@/components/InputText";
  import CourseCardRowSmall from "@/components/Courses/Card/CourseCardRowSmall";
  import InputCheckbox from "@/components/InputCheckbox";
  import TheButton from "@/components/Button/Button";

  export default {
    components: {
      TheButton,
      InputCheckbox,
      CourseCardRowSmall, InputText, TheModal,XIcon,SearchIcon, SortAscendingIcon,SortDescendingIcon},
    props:{
      modelValue:{},
    },
    computed:{
      ...mapFields(['specializations.modals.show_courses_select_modal']),
      filteredCourses(){
        return this.courses.filter( el => el.name.toLowerCase().includes(this.filter.text.toLowerCase()))
      }
    },
    data:()=>({
      courses:[],
      filter:{
        order_by:'',
        text:'',
        order_dir:'asc'
      },
      selected_course_ids:[]
    }),
    methods:{
      async getCourses(){
        this.courses = await this.$store.dispatch(GET_PUBLIC_COURSES,{take:3})
      },
      revertOrder(){
        this.filter.order_dir = this.filter.order_dir ==='asc'?'desc':'asc'
      },
      closeModal(){
        this.show_courses_select_modal=false
      },
      handleAddCourses(){
        this.$emit('update:modelValue', this.selected_course_ids)
        this.show_courses_select_modal = false
      }
    },
    watch:{
      'show_courses_select_modal'(newVal){
        this.selected_course_ids = []
        if(newVal){
          this.selected_course_ids = this.modelValue
          this.getCourses()
        }
      },
      'filter.order_dir'(newVal){
        if(newVal === 'asc'){
          this.courses = this.courses.sort((a,b) => a.name.localeCompare(b.name))
        } else {
          this.courses = this.courses.sort((a,b) => b.name.localeCompare(a.name))
        }
      }
    }
  }
</script>