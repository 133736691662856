<template>
  <svg
    width="26"
    height="24"
    viewBox="0 0 26 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1033 0.815034C12.4701 0.0717885 13.5299 0.0717872 13.8967 0.815033L16.8818 6.86349C17.0275 7.15863 17.3091 7.3632 17.6348 7.41053L24.3096 8.38044C25.1298 8.49963 25.4574 9.5076 24.8638 10.0861L20.0339 14.7942C19.7982 15.0239 19.6906 15.3549 19.7463 15.6793L20.8865 22.3272C21.0266 23.1441 20.1691 23.7671 19.4355 23.3814L13.4653 20.2427C13.174 20.0895 12.826 20.0895 12.5347 20.2427L6.56448 23.3814C5.83085 23.7671 4.97342 23.1441 5.11353 22.3272L6.25373 15.6793C6.30937 15.3549 6.20182 15.0239 5.96614 14.7942L1.13616 10.0861C0.542642 9.5076 0.870151 8.49963 1.69037 8.38044L8.36524 7.41053C8.69095 7.3632 8.97251 7.15863 9.11817 6.86349L12.1033 0.815034Z"
      fill="#C8AEFF"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
