<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1233_1058)">
      <path
        d="M32.6667 0H7.33333C3.28325 0 0 3.28325 0 7.33333V32.6667C0 36.7168 3.28325 40 7.33333 40H32.6667C36.7168 40 40 36.7168 40 32.6667V7.33333C40 3.28325 36.7168 0 32.6667 0Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0645 22.0604C19.0833 22.045 19.1021 22.0295 19.1206 22.0137L19.0645 22.0604Z"
        fill="#4310D3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.9473 20.3827C17.9661 20.3674 17.9848 20.3518 18.0034 20.3359L17.9473 20.3827Z"
        fill="#EEEEEE"
      />
      <path
        d="M8.51328 21.1122C8.97086 25.682 13.0808 29.0122 17.693 28.5504C18.8983 28.4297 20.0184 28.062 21.0077 27.5032L19.4678 21.8496L19.4277 21.9128C18.9405 22.6598 18.1276 23.1885 17.1658 23.2849C15.4886 23.4528 13.9941 22.2418 13.8277 20.5801C13.6613 18.9183 14.886 17.4351 16.5632 17.2671C17.5305 17.1703 18.4371 17.5257 19.0632 18.168L19.4228 12.3484C18.3618 12.0133 17.2153 11.8835 16.036 12.0016C11.4237 12.4634 8.05571 16.5424 8.51328 21.1122Z"
        fill="#1F2937"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.4666 21.8519L21.0064 27.5055C22.1706 26.8478 23.1534 25.9253 23.8797 24.8288L30.4742 13.5124C30.9033 12.776 30.6668 11.8318 29.9412 11.3847C28.1809 10.2998 25.8722 10.8853 24.8416 12.6779L23.4458 15.1059L23.5972 15.3008L20.6768 19.8964L19.4666 21.8519Z"
        fill="#1F2937"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.4468 15.1057L32.9452 25.9911C33.5191 26.6488 33.4687 27.6429 32.8311 28.2391C31.3345 29.6386 28.974 29.5122 27.6355 27.9607L20.6779 19.8962L19.063 18.17L19.4225 12.3504C21.096 12.879 22.5568 13.9182 23.5983 15.3005L23.4468 15.1057Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="clip0_1233_1058">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
