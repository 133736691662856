<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.5" y="2.5" width="19" height="19" rx="3.5" :fill="fill"/>
    <path d="M16.4312 8.31759C16.6346 8.521 16.7488 8.79685 16.7488 9.08448C16.7488 9.3721 16.6346 9.64795 16.4312 9.85137L11.0077 15.2749C10.8043 15.4783 10.5284 15.5925 10.2408 15.5925C9.95317 15.5925 9.67732 15.4783 9.47391 15.2749L7.30449 13.1055C7.1069 12.9009 6.99757 12.6269 7.00004 12.3425C7.00251 12.0581 7.11659 11.786 7.3177 11.5849C7.51882 11.3838 7.79088 11.2697 8.07528 11.2673C8.35969 11.2648 8.63369 11.3741 8.83827 11.5717L10.2408 12.9742L14.8975 8.31759C15.1009 8.11424 15.3767 8 15.6643 8C15.952 8 16.2278 8.11424 16.4312 8.31759Z" fill="white"/>
    <rect x="2.5" y="2.5" width="19" height="19" rx="3.5" stroke="#111827"/>
  </svg>
</template>
<script>
  import IconMixin from "@/assets/icons/IconMixin";

  export default {
  mixins:[IconMixin]
  }
</script>