<template>
  <TheModal :show="icon_selector_modal.is_open">
    <div class="p-6 w-fit">
      <div class="text-gray-900 text-xl leading-7 font-semibold flex place-content-between place-items-center ">
        {{$t('add_icon')}}:
        <XIconSolid class="h-6 cursor-pointer" @click="closeModal"/>
      </div>
      <div class="grid grid-cols-11 gap-4 max-h-[450px] overflow-y-scroll   justify-items-center px-4 my-6">
        <div
            v-for="component in selectorIconsArray"
            :key="component"
            @click="selectIcon(component)"
            class="w-[48px] h-[48px] border-2 border-gray-300 flex place-items-center place-content-center rounded-xl cursor-pointer hover:bg-gray-100"
            :class="localValue[component]?'bg-gray-100':''"
        >
          <component :is="selectorIcons[component]"  class="h-5 w-5 text-indigo-600 " />
        </div>
      </div>
      <div class="flex flex-row justify-end">
        <TheButton bg="outline" @click="closeModal" class="mr-6">{{$t(cancelButtonLabel)}}</TheButton>
        <TheButton bg="primary" @click="apply"> {{$t(selectButtonLabel)}}</TheButton>
      </div>
    </div>
  </TheModal>
</template>
<script>
import TheModal from "@/components/TheModal/TheModal";
import {mapFields} from "vuex-map-fields";
import {XIcon as XIconSolid} from "@heroicons/vue/solid";
import OutlineIconsMixin from "@/components/TheIconSelector/OutlineIconsMixin";
import TheButton from "@/components/Button/Button";

export default {
  computed:{
    ...mapFields(['core.icon_selector_modal'])
  },
  mixins:[OutlineIconsMixin],
  props:{
    cancelButtonLabel:{
      type:String,
      default:'cancel'
    },
    selectButtonLabel:{
      type:String,
      default:'add'
    },
    multiple:{
      type:Boolean,
      default:false
    }
  },
  components: {
    TheButton,
    TheModal,
    XIconSolid,
  },
  data:()=>({
    localValue:{}
  }),
  methods:{
    selectIcon(iconName){
      if(this.multiple){
        this.localValue[iconName] = !this.localValue[iconName]
      }else{
        this.localValue = {
          [iconName]:!this.localValue[iconName]
        }
      }
    },
    closeModal(){
      this.icon_selector_modal.is_open = false
      this.icon_selector_modal.handleChange = ()=>{}
    },
    apply(){
      const filteredIcons = Object.keys(this.localValue).filter(key=>this.localValue[key])
      const result = this.multiple ? filteredIcons:filteredIcons[0]
      this.icon_selector_modal.handleChange(result);
      this.closeModal()
    }
  }
}
</script>