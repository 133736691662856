<template>
  <TheLandingHeader />
  <TheModal :show="modalOpen" @close="handleModalClose" class="z-50">
    <TheLandingForm/>
  </TheModal>

  <div
    class="bg-indigo-50 w-full flex pt-16 pl-16 pr-12 lg:pt-25 lg:pl-26 lg:pr-20"
  >
    <div class="flex-1 pb-6">
      <div
        v-if="course.specializations"
        class="text-lg leading-7 font-semibold text-indigo-600 mb-4"
      >
        {{ course.specializations.map((spec) => spec.name).join(", ") }}
      </div>
      <h1 class="text-6xl font-extrabold text-gray-800 mb-10">
        {{ course.name }}
      </h1>
      <TheButton
          @click="handleModalOpen"
      >{{ $t("enroll_to_course") }}</TheButton>
    </div>
    <div class="flex-1 items-end justify-end">
      <img
        src="./EnglishCourseIcon.svg"
        alt="English Course Icon"
        class="block flex-shrink-0"
      />
    </div>
  </div>
  <div class="py-16 px-20 bg-gray-50">
    <div>
      <h1 class="text-2xl leading-8 font-semibold mb-10">
        {{ $t("about_course") }}
      </h1>
      <div class="flex flex-wrap">
        <img
          :src="course.logo"
          class="rounded-md rounded-xl mb-4 w-full flex lg:hidden"
        />
        <div class="w-full lg:w-3/4">
          <TheCard additional_class="min-w-0">
            <template #header>{{ $t("description") }}</template>
            <div v-html="course.description" class="quill" />
          </TheCard>
          <TheCard>
            <template #header>{{ $t("what_you_will_learn") }}</template>
            <div
              v-for="goal in course.goals"
              :key="goal"
              class="flex place-items-center mt-4"
            >
              <CheckCircleIcon class="h-4 w-4 text-indigo-600 flex-shrink-0" />
              <span class="ml-2 text-sm leading-5 font-normal">
                {{ goal.name }}
              </span>
            </div>
          </TheCard>
          <TheCard>
            <template #header>{{ $t("how_study_is_passed") }}</template>
            <div class="flex flex-row flex-wrap gap-y-4">
              <div
                class="flex-row w-full flex"
                v-for="perk in course.perks"
                :key="perk.title"
              >
                <component
                  :is="perk.icon_name"
                  class="h-5 w-5 text-indigo-600 flex-shrink-0"
                />
                <div class="flex-1 pl-1">
                  <h4 class="text-base leading-6 font-semibold">
                    {{ $t(perk.title) }}
                  </h4>
                  <span class="text-sm leading-5 font-normal">{{
                    $t(perk.description)
                  }}</span>
                </div>
              </div>
            </div>
          </TheCard>
        </div>
        <div
          class="w-full pl-0 flex flex-col-reverse lg:w-1/4 lg:pl-4 lg:flex-col"
          v-if="course.overview"
        >
          <div v-if="course.id !== 17">
            <OverviewCard
              :score="course.overview.score"
              :quizzes_count="course.overview.quizzes_count"
              :duration_hours="course.overview.duration_hours"
              :skill_level="course.overview.skill_level"
              :chapters_count="course.overview.topic_count * 1"
              :resources_count="course.overview.resources_count * 1"
              :is_certificate_given="course.overview.is_certificate_given"
              :votes="course.overview.votes"
            />
            <InstructorInfoCard
              :key="`instructor_photo_${instructor.id}`"
              v-for="instructor in course.instructors"
              :instructor="instructor.instructor"
              :description="instructor.instructor_description"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <TheLandingFooter/>
</template>

<script>
import TheLandingHeader from "@/views/core/Landing/TheLandingHeader";
import { mapActions } from "vuex";
import { GET_PUBLISHED_COURSE } from "@/store/courses";
import TheCard from "@/components/TheCard/TheCard";
import TheButton from "@/components/Button/Button";
import OverviewCard from "@/components/Courses/OverviewCard/OverviewCard";
import InstructorInfoCard from "@/components/Courses/InstructorInfo/InstructorInfoCard";
import TheAlert from "@/components/Alert/Alert";
import { CheckCircleIcon } from "@heroicons/vue/solid";
import TheLandingFooter from "@/views/core/Landing/TheLandingFooter";
import TheModal from "@/components/TheModal/TheModal";
import TheLandingForm from "@/views/core/Landing/TheLandingForm";

export default {
  components: {
    TheLandingForm,
    TheLandingFooter,
    TheLandingHeader,
    TheCard,
    TheButton,
    OverviewCard,
    InstructorInfoCard,
    TheAlert,
    CheckCircleIcon,
    TheModal,
  },
  methods: {
    ...mapActions([GET_PUBLISHED_COURSE]),
    handleModalOpen() {
      this.modalOpen = true;
    },
    handleModalClose() {
      this.modalOpen = false;
    },
  },
  data() {
    return {
      course: {},
      modalOpen: false,
    }
  },
  async mounted() {
    this.course = await this[GET_PUBLISHED_COURSE](this.$route.params.id);
  },
};
</script>
