<template>
  <div>
    <TheCard class="flex text-sm leading-5 font-medium  overflow-hidden mb-4 " style="padding: 0px">
      <div class="w-1/2  p-6 place-content-center flex cursor-pointer"
           :class="selected_type==='materials'?'selected_content_types_list':'not_selected_content_types_list'"
           @click="setSelectedType('materials')"
      >{{$t('materials')}}</div>
      <div class="divider" ></div>
      <div class="w-1/2 p-6 place-content-center flex cursor-pointer"
           :class="selected_type==='question_types'?'selected_content_types_list':'not_selected_content_types_list'"
           @click="setSelectedType('question_types')"
      >{{$t('testing_questions')}}</div>
    </TheCard>
    <TheCard>
      <TheCardHeader>{{$t('elements')}}</TheCardHeader>
      <div >
        <draggable
            :list="displayOptions"
            :group="{ name: 'people', pull: 'clone', put: false }"
            @choose="e=>selectItemForDrag(e,true)"
            @end="e=>selectItemForDrag(e,false)"
            itemKey="id"
            >
          <template #item="{element}">
            <div class="flex flex-row place-items-center pt-2 px-3 w-full cursor-pointer " :data-id="element.id">
              <component :is="element.icon" class="h-5 w-5 text-gray-900 mr-2 flex-shrink-0"/>
              {{ $t(element.label) }}
            </div>
          </template>
        </draggable>
      </div>
    </TheCard>
  </div>
</template>
<script>
  import TheCard from "@/components/TheCard/TheCard";
  import TheCardHeader from "@/components/TheCard/TheCardHeader";
  import TextIcon from "@/assets/icons/TextIcon";
  import DialogQuestionIcon from "@/assets/icons/DialogQuestionIcon";
  import {PhotographIcon, VolumeUpIcon,PlayIcon, PresentationChartBarIcon} from "@heroicons/vue/solid";
  import Draggable from 'vuedraggable'
  import CheckboxCheckedIcon from "@/assets/icons/CheckboxCheckedIcon";
  import RadioCheckedIcon from "@/assets/icons/RadioCheckedIcon"
  // import Draggable from "vue3-draggable"
  export default {
    components: {TheCardHeader, TheCard, TextIcon,PhotographIcon,VolumeUpIcon,Draggable,PresentationChartBarIcon},
    props:{
      resource_type:String
    },
    data:()=>({
      selected_type:"materials",
      available_options:[
        {
          label:"text",
          icon:TextIcon,
          id:"text",
          group_name:"materials",
          initial_data:{
            content:"",
            attachments:[]
          }
        },
        {
          label:"image",
          icon:PhotographIcon,
          id:"image",
          group_name:"materials",
          initial_data:{
            content:""
          }
        },
        {
          label:"audio",
          icon:VolumeUpIcon,
          id:"audio",
          group_name:"materials",
          initial_data:{
            content:""
          }
        },
        {
          label:"video",
          icon:PlayIcon,
          id:"video",
          group_name:"materials",
          initial_data:{
            content:""
          }
        },
        {
          label:"youtube_video",
          icon:PlayIcon,
          id:"youtube_video",
          group_name:"materials",
          initial_data:{
            src:""
          }
        },
        {
          label:"presentation",
          icon:PresentationChartBarIcon,
          id:"presentation",
          group_name:"materials",
          initial_data: {
            content:''
          }
        },
        {
          label:"multiple_choice_question_label",
          icon:RadioCheckedIcon,
          id:"multiple_choice_question",
          group_name:"question_types",
          initial_data: {
            content:'',
          }
        },
        {
          label:"multiple_answers_question_label",
          icon:CheckboxCheckedIcon,
          id:"multiple_answers_question",
          group_name:"question_types",
          initial_data:{
            content:'',
            attachments:[]
          }
        },
        {
          label:"file_submission_question_label",
          icon:DialogQuestionIcon,
          id:"file_submission_question",
          group_name:"question_types",
          initial_data:{
            content:'',
            attachments:[]
          }
        },
        {
          label:"audio_question_label",
          icon:VolumeUpIcon  ,
          id:"audio_question",
          group_name:"question_types",
          initial_data: {
            content:{},
            available_answers:[],
            src:""
          }
        },
        {
          label:"image_question_label",
          icon:PhotographIcon,
          id:"image_question",
          group_name:"question_types",
          initial_data: {
            content: {},
            available_answers: {},
            multiple_answers_available:false,
            correct_answer:''
          }
        },
      ],
      controlOnStart: true
    }),
    methods:{
      setSelectedType(val){
        this.selected_type = val;
      },
      selectItemForDrag: function(evt,isDragging) {
        const item = evt.item;
        if(isDragging){
          item.classList.add('border-[1px]','rounded-xl','border-gray-200','bg-white');
        }else{
          item.classList.remove('border-[1px]','rounded-xl','border-gray-200','bg-white');
        }
      },
    },
    computed:{
      displayOptions(){
          return this.available_options.filter(el=>el.group_name===this.selected_type)
      }
    }
  }
</script>
<style scoped lang="postcss">
  .selected_content_types_list{
    @apply text-gray-900 border-b-2 border-indigo-700 text-gray-700
  }
  .not_selected_content_types_list{
    @apply text-gray-500
  }
  .divider{
    border-width: 1px;
    @apply w-0 border-gray-200
  }
</style>