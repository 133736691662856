<template>
  <div>
    <VideoModal
      :close-video-modal="closeVideoModal"
      :show-video-modal="showVideoModal"
      :url-video="urlVideo"
      :show-modal="showVideoModal"
    />
    <TheLandingCarousel
      :formData="formData"
      @form-data-updated="handleFormData"
    />
    <TheAdLine />
    <TheLandingCoursesCarousel />
    <TheLandingOurCourses />
    <TheLandingGoals />
    <TheLandingAdvantages />
    <CourseTeachers bg="white" />
    <TheLandingStats />
    <TheLandingReviews />
    <StudentStories />
    <GuestLectures />
    <TheCheckCert />
    <TheLandingPartners />
    <TheLandingBanner />
    <!-- <TheLandingNews /> -->
    <CoursesDarkForm id="landing-form" ref="landingForm" :formData="formData" />
  </div>
</template>
<script>
import TheLandingCarousel from "@/views/core/Landing/TheLandingCarousel";
import TheLandingOurCourses from "./NewLanding/sections/TheLandingOurCourses.vue";
import TheLandingAdvantages from "./NewLanding/sections/TheLandingAdvantages.vue";
import TheLandingStats from "./NewLanding/sections/TheLandingStats.vue";
import TheLandingReviews from "./NewLanding/sections/NewLandingReviews.vue";
import TheAdLine from "./NewLanding/sections/TheAdLine.vue";
import TheLandingGoals from "@/views/core/Landing/TheLandingGoals";
import TheLandingPartners from "@/views/core/Landing/TheLandingPartners";
// import TheLandingNews from "@/views/core/Landing/TheLandingNews";
// import TheLandingReviews from "@/views/core/Landing/TheLandingReviews";
import TheLandingBanner from "@/views/core/Landing/TheLandingBanner";
import TheLandingCoursesCarousel from "./NewLanding/TheLandingCoursesCarousel.vue";
import fo from "vue-tailwind/dist/l10n/fo";
import CourseTeachers from "@/views/core/Landing/Courses/CourseTeachers.vue";
import StudentStories from "@/views/core/Landing/NewLanding/sections/StudentStories.vue";
import CoursesDarkForm from "@/views/core/Landing/Courses/CoursesDarkForm.vue";
import VideoModal from "@/views/core/Landing/VideoModal.vue";
import TheCheckCert from "@/views/core/Landing/TheCheckCert.vue";
import GuestLectures from "@/views/core/Landing/Courses/GuestLectures/GuestLectures.vue";
export default {
  computed: {
    fo() {
      return fo;
    },
  },
  components: {
    GuestLectures,
    TheCheckCert,
    VideoModal,
    CoursesDarkForm,
    StudentStories,
    CourseTeachers,
    TheLandingCoursesCarousel,
    TheLandingReviews,
    // TheLandingNews,
    TheLandingPartners,
    TheLandingStats,
    TheLandingGoals,
    TheAdLine,
    TheLandingCarousel,
    TheLandingAdvantages,
    TheLandingBanner,
    TheLandingOurCourses,
  },
  data() {
    return {
      scrollPosition: 0,
      urlVideo: "https://www.youtube.com/embed/QqhmhMaJVQ0?si=0NdUFSDmW3QmAemk",
      showVideoModal: false,
      showModal: true,
      formData: {
        personName: "",
        phone: "",
        email: "",
      },

      teachers: [
        {
          name: "Ердаулет Абсаттар",
          position:
            "Основатель и директор команды разработки в Alpha Education",
          courses: "Веб-разработка, Дизайн, Управление",
          profession:
            "Основатель и директор Alpha Education. Имеет огромный опыт в преподавании таких курсов как веб-разработка, графический дизайн, UI/UX дизайн, Блокчейн, Data Science, разработка игр, мобильная разработка. ",
          education: [
            "Бакалавр Компьютерных Наук (Nazarbayev University)",
            "Магистр Инженерного Менеджмента (Nazarbayev University) ",
          ],
          image: "yerdaulet.jpeg",
          programs: ["Дизайн"],
        },
        {
          name: "Асхат Молкенов",
          position:
            "Основатель и директор команды разработки в Alpha Education",
          courses: "Программирование, Data Science",
          profession:
            "Основатель и директор команды разработки в Alpha Education. Автор образовательных программ: Программирование, Алгоритмы и структуры данных,  Базы данных, Data Science. ",
          education: [
            "Бакалавр наук: математика и компьютерное моделирование (ENU)",
            "Магистр Математической Биологии (University of Dundee)",
            "Научный исследователь в “Center for Life Sciences”, Назарбаев Университет",
          ],
          image: "askhat.jpeg",
          programs: ["Data Science"],
        },
        {
          name: "Ботагоз Абсаттар",
          position: "Основатель и PM Alpha Education",
          courses: "Бизнес анализ, Веб-разработка, Компьютерные сети",
          profession:
            "Основатель и преподаватель школы программирования Alpha Education. Более 10 лет занимается веб-разработкой, работала фронт-енд разработчиком, бизнес-аналитиком в IT-компаниях Казахстана, а также имеет опыт в сфере информационной безопасности.  Сейчас является проджект менеджером по созданию веб-приложений. ",
          education: [
            "Бакалавр Компьютерных Наук (Eurasian National University)",
            "Магистр Компьютерных Наук (Eurasian National University)",
            "Магистр Телекоммуникации и связи (University College of London)",
          ],
          image: "botagoz.jpeg",
          programs: ["Веб-разработка"],
        },
      ],
    };
  },
  provide() {
    return {
      openVideoModal: this.openVideoModal,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, this.scrollPosition);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    this.scrollPosition = window.scrollY;
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.scrollY;
    },
    openVideoModal(url) {
      this.urlVideo = url;
      this.showVideoModal = true;
    },
    closeVideoModal() {
      this.showVideoModal = false;
    },
    openModal() {
      this.showModal = true;
      console.log(this.showModal);
    },
    closeModal() {
      this.showModal = false;
    },
    handleFormData(updatedFormData) {
      this.formData.personName = updatedFormData.personName;
      this.formData.phone = updatedFormData.phone;
      this.formData.email = updatedFormData.email;
    },
  },
};
</script>

<style scoped>
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Style for modal content */
.modal-content2 {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
}

/* Style for close button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
