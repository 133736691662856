<template>
  <div class="padding-100px">
    <div class="stories-title">
      <div class="adaptive-title">Истории наших студентов, которыми</div>
      <div class="font-bold adaptive-gradient">мы гордимся</div>
    </div>
    <div class="flex gap-12 adaptive-flex">
      <div
        class="flex flex-col basis-half gap-6"
        v-if="this.landingFeedbacks.length > 0"
      >
        <StudentStoryCard :story="this.landingFeedbacks[0]" />
      </div>
      <div
        class="flex flex-col basis-half justify-between gap-12"
        v-if="this.landingFeedbacks.length > 1"
      >
        <div
          v-for="(story, index) in this.landingFeedbacks.slice(1, 3)"
          :key="index"
          class="flex gap-6"
        >
          <div class="flex flex-col gap-2 basis-half">
            <div class="story-title">
              {{ story.title }}
            </div>
            <div class="story-text">{{ story.content }}</div>
          </div>
          <div class="flex story-box-card-small relative">
            <div
              class="story-box-small rounded-6xl flex flex-col justify-between"
            >
              <div class="flex gap-2 align-middle">
                <div class="emoji-box-small">🥇</div>
                <div class="emoji-text-small flex flex-col">
                  <div>История</div>
                  <div>успеха</div>
                </div>
              </div>
              <div class="flex flex-col gap-1 text-white">
                <div class="emoji-name-small">{{ story.name }}</div>
                <div class="emoji-position-small">{{ story.program }}</div>
              </div>
            </div>
            <div class="image-container-small">
              <img class="emoji-img" :src="story.image" alt="story1" />
            </div>

            <div
              class="absolute play-button z-10 cursor-pointer"
              @click="handleModal(story.video)"
            >
              <svg
                width="52"
                height="52"
                viewBox="0 0 52 52"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_1988_4530"
                  style="mask-type: luminance"
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="52"
                  height="52"
                >
                  <path
                    d="M26.0003 49.3337C38.8873 49.3337 49.3337 38.8873 49.3337 26.0003C49.3337 13.1133 38.8873 2.66699 26.0003 2.66699C13.1133 2.66699 2.66699 13.1133 2.66699 26.0003C2.66699 38.8873 13.1133 49.3337 26.0003 49.3337Z"
                    fill="white"
                    stroke="white"
                    stroke-width="4"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.334 26.0006V17.918L28.334 21.9593L35.334 26.0006L28.334 30.042L21.334 34.0833V26.0006Z"
                    fill="black"
                    stroke="black"
                    stroke-width="4"
                    stroke-linejoin="round"
                  />
                </mask>
                <g mask="url(#mask0_1988_4530)">
                  <path d="M-2 -2H54V54H-2V-2Z" fill="white" />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="adaptive-slider">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="16"
        :pagination="pagination"
      >
        <swiper-slide v-for="story in landingFeedbacks" :key="story.key">
          <StudentStoryCard :story="story"
        /></swiper-slide>
      </swiper>
    </div>
    <div class="flex justify-end items-center">
      <router-link :to="{ name: 'AllStudentStories' }">
        <button class="flex gap-2 story-button items-center">
          Посмотреть все отзывы
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
              fill="#6759EA"
            />
          </svg></button
      ></router-link>
    </div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";
import StudentStoryCard from "@/views/core/Landing/NewLanding/cards/StudentStoryCard.vue";
import StudentFeedbacksMixin from "@/views/core/Landing/Courses/StudentFeedbacks/StudentFeedbacksMixin";

export default {
  components: {
    StudentStoryCard,
    SwiperSlide,
    Swiper,
  },
  inject: ["openVideoModal"],
  mixins: [StudentFeedbacksMixin],
  methods: {
    handleModal(url) {
      this.openVideoModal(url);
    },
    updateScreenSize() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
  },
  mounted() {
    // Update screen size when the window is resized
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeUnmount() {
    // Remove event listener when component is destroyed
    window.removeEventListener("resize", this.updateScreenSize);
  },
  data() {
    return {
      screenWidth: window.innerWidth,
      screenHeight: window.innerHeight,
      urlVideo: "https://www.youtube.com/embed/vnOONZbh9QA",
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },

        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
        pagination: "swiper-pagination",
      },
      modules: [Navigation, Pagination],
      stories: [
        {
          key: 1,
          name: "Балжан Балтабаева",
          position: "Графический и UI/UX дизайн",
          title: "«Изучение красоты и функциональности»",
          content:
            "Студентка курса Графический и UI/UX дизайн рассказывает как мир, где эстетика и удобство взаимодействия играют ключевую роль. ",
          img: "student1.png",
          video: "https://www.youtube.com/embed/vnOONZbh9QA",

          color: "#8286FF",
        },
        {
          key: 2,
          name: "Ерасыл Аюбеков",
          position: "Анализ данных",
          title: "«Открытие новых горизонтов в аналитике»",
          content:
            "Студент курса Анализ данных делится о своем опыте в освоении новейших методов обработки и интерпретации информации, что помогло прогнозировать тренды в различных областях.",
          img: "student2.png",
          video: "https://www.youtube.com/embed/vnOONZbh9QA",

          color: "#B596FF",
        },
        {
          key: 3,
          name: "Айсана Джандигулова",
          position: "Графический и UI/UX дизайн",
          title: "«Погружение в мир пользовательского опыта»",
          content:
            "Студентка курса Графический и UI/UX дизайн делится о своих впечатлениях о курсе:  о том как создавать интерфейсы, которые не только привлекают внимание, но и делают жизнь пользователей проще. ",
          img: "student3.png",
          video: "https://www.youtube.com/embed/vnOONZbh9QA",
          color: "#F481FF",
        },
      ],
    };
  },
};
</script>
<style scoped>
.padding-100px {
  padding: 100px 100px 100px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.stories-title {
  font-size: 48px;
  font-style: normal;
  font-weight: 800;
  line-height: 115%;
}

.adaptive-gradient {
  background: linear-gradient(67deg, #cc3fd3 0%, #8453e8 45.83%, #525cf3 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 15px;
}

.story-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.story-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.story-box {
  height: 280px;
  border-radius: 24px 0px 0px 24px;
  width: 275px;
  background: #2b2e3b;
  margin-right: -20px;
  position: relative;
  z-index: 1;
  padding: 32px 24px;
}

.emoji-box {
  border-radius: 16px;
  border: 3px solid rgba(130, 134, 255, 0.2);
  width: 48px;
  height: 48px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.emoji-text {
  color: #8286ff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */
  text-transform: uppercase;
}
.emoji-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.emoji-position {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.emoji-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 0px 24px 24px 0px;
}
.image-container {
  flex-grow: 1;
  height: 280px;
}
.basis-half {
  flex-basis: 50%;
}
.adaptive-slider {
  display: none;
}
.story-box-small {
  height: 160px;
  border-radius: 24px 0px 0px 24px;
  width: 130px;
  background: #2b2e3b;
  margin-right: -20px;
  position: relative;
  z-index: 1;
  padding: 20px 12px;
}
.emoji-box-small {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 25px;
  height: 25px;
  border-radius: 8.296px;
  border: 1.556px solid rgba(181, 150, 255, 0.2);
  background: #fff;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
}

.emoji-text-small {
  color: #b596ff;
  font-size: 9px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 11.7px */
  text-transform: uppercase;
}
.emoji-name-small {
  font-size: 9px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  color: white;
}
.emoji-position-small {
  color: #f3f4f6;
  font-size: 7px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.image-container-small {
  flex-grow: 1;
  height: 160px;
}
.container {
  position: relative;
  width: 100%;
  height: auto;
}

.content {
  z-index: 1;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  border-radius: 24px;
}
:deep .swiper-pagination-bullet {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e4d7ff;
}

:deep .swiper-pagination-bullet-active {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #5b4ccc;
}

@media (max-width: 650px) {
  .stories-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }

  .padding-100px {
    padding: 40px 16px;
  }

  .adaptive-flex {
    display: none;
    flex-direction: column;
  }
  .adaptive-slider {
    display: flex;
  }
  .story-box-card {
    width: 100%;
  }
  .story-box {
    width: 190px;
  }
}
.story-button {
  color: #6759ea;
}

.story-box-card-small {
  width: 280px;
}
.play-button svg {
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
}
.play-button {
  right: 16px;
  top: 16px;
}
</style>
