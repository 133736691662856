<template>
  <div class="flex flex-col gap-8">
    <div class="md:flex gap-4">
      <h1
        class="text-2xl leading-8 font-semibold text-gray-900 mr-auto mb-4 md:mb-0"
      >
        {{ $t("statistics") }}
      </h1>
      <div class="flex gap-4">
        <InputText
          :placeholder="$t('search_by_name_email')"
          v-model="search"
          class="h-full"
          style="font-size: initial !important"
        >
          <template #leftIcon>
            <SearchIcon class="h-5 text-gray-400" />
          </template>
        </InputText>
        <TheButton
          bg="outline"
          class="text-gray-500 bg-white h-fit w-fit"
          @click="handleDownloadStatistics"
          :disabled="loading"
        >
          <LoaderIcon v-if="loading" class="h-5 mr-2 animate-spin" />
          <DownloadIcon v-else class="h-5 mr-2" />
          {{ $t("download_statistics") }}
        </TheButton>
      </div>
    </div>
    <div class="p-6 rounded-xl shadow shadow-sm bg-white flex flex-col gap-2">
      <div class="flex gap-9 border-b">
        <div
          class="text-sm leading-5 font-medium cursor-pointer pb-4"
          :class="{
            'text-indigo-600 border-b-2 border-indigo-500': selectedTab === 0,
            'text-gray-500': selectedTab === 1,
          }"
          @click="toggleTab(0)"
        >
          {{ $t("overall_statistics") }}
        </div>
        <div
          class="text-sm leading-5 font-medium cursor-pointer pb-4"
          :class="{
            'text-indigo-600 border-b-2 border-indigo-500': selectedTab === 1,
            'text-gray-500': selectedTab === 0,
          }"
          @click="toggleTab(1)"
        >
          {{ $t("activity_logs") }}
        </div>
        <div
          class="text-sm leading-5 font-medium cursor-pointer pb-4"
          :class="{
            'text-indigo-600 border-b-2 border-indigo-500': selectedTab === 2,
            'text-gray-500': selectedTab === 0,
          }"
          @click="toggleTab(2)"
        >
          {{ $t("login_attempts") }}
        </div>
      </div>
      <OverallStatisticsView v-if="selectedTab === 0" />
      <ActivityLogsView v-if="selectedTab === 1" :search="search" />
      <LoginAttempts v-if="selectedTab === 2" />
    </div>
  </div>
</template>

<script>
import OverallStatisticsView from "@/views/stats/GeneralStatisticsView";
import ActivityLogsView from "@/views/stats/ActivityLogsView";
import InputText from "@/components/InputText";
import { DownloadIcon, SearchIcon } from "@heroicons/vue/solid";
import TheButton from "@/components/Button/Button";
import LoginAttempts from "@/views/stats/LoginAttempts";
import { getCurrentInstance } from "vue";
import { mapActions } from "vuex";
import { DOWNLOAD_GENERAL_STATISTICS } from "@/store/users";
import LoaderIcon from '@/assets/icons/LoaderIcon';

export default {
  name: "statistics-view",
  components: {
    LoginAttempts,
    OverallStatisticsView,
    ActivityLogsView,
    InputText,
    SearchIcon,
    TheButton,
    DownloadIcon,
    LoaderIcon
  },
  setup() {
    const instance = getCurrentInstance();
    return { instance };
  },
  data() {
    return {
      selectedTab: 0,
      search: "",
      loading: false,
    };
  },
  methods: {
    ...mapActions([DOWNLOAD_GENERAL_STATISTICS]),
    toggleTab(tab) {
      this.selectedTab = tab;
    },
    async handleDownloadStatistics() {
      this.loading = true;
      const data = await this[DOWNLOAD_GENERAL_STATISTICS]();
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `single_statistics.xlsx`);
      document.body.appendChild(link);
      link.click();
      this.loading = false;
    },
  },
};
</script>