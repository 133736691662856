<template>
  <div class="content-header">
    <span>Преимущества учебы в Alpha.Edu</span>
    <div
      class="grid gap-12 grid-cols-2 mt-12 adaptive-slider"
      style="row-gap: 24px"
    >
      <AdvantageCard
        v-for="card in cards"
        :key="card.headerText"
        :headerText="card.headerText"
        :text="card.text"
      >
        <template #icon>
          <component :is="card.icon" v-if="card.icon" class="mb-8 w-16 h-16" />
        </template>
      </AdvantageCard>
    </div>

    <div class="adaptive-slider2 mySwiper">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="16"
        :pagination="pagination"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide v-for="card in cards" :key="card.headerText">
          <AdvantageCard :headerText="card.headerText" :text="card.text">
            <template #icon>
              <component
                :is="card.icon"
                v-if="card.icon"
                class="mb-8 w-16 h-16"
              />
            </template> </AdvantageCard
        ></swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import AdvantageCard from "@/views/core/Landing/NewLanding/cards/AdvantageCard.vue";
import TickIcon from "@/assets/icons/TickIcon.vue";
import GreenTickIcon from "@/assets/icons/GreenTick.vue";
import PercentIcon from "@/assets/icons/PercentIcon.vue";
import LandingCourseCard from "@/views/core/Landing/NewLanding/cards/LandingCourseCard.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
export default {
  components: {
    Swiper,
    SwiperSlide,
    LandingCourseCard,
    AdvantageCard,
    TickIcon,
    PercentIcon,
    GreenTickIcon,
  },
  data() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },

        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
      },
      modules: [Navigation, Pagination],
      cards: [
        {
          icon: TickIcon,
          headerText: "Гибкость и удобство",
          text: "Учеба в Alpha.Edu позволяет вам изучать материалы и проходить курсы в любое удобное для вас время и место. Вы можете создать гибкий график обучения, сочетая его с работой или другими обязанностями.",
        },
        {
          icon: PercentIcon,
          headerText: "Качество обучения",
          text: "Alpha.Edu предлагает курсы, разработанные экспертами в области IT, с актуальными и полезными материалами. Вы получите глубокие знания и навыки, необходимые для успешной карьеры в IT-сфере.",
        },
        {
          icon: PercentIcon,
          headerText: "Интерактивность и поддержка",
          text: "Во время обучения в Alpha.Edu вы будете иметь доступ к интерактивным занятиям, заданиям, практическим проектам и форумам, где вы сможете общаться с преподавателями и другими студентами. Вы также получите персональную поддержку и обратную связь от опытных наставников.",
        },
        {
          icon: GreenTickIcon,
          headerText: "Карьерная поддержка",
          text: "Alpha.Edu предоставляет дополнительные возможности для вашего профессионального развития, такие как менторинг, помощь в поиске работы и подготовка к собеседованиям. Вы получите поддержку и ресурсы, чтобы успешно запустить свою карьеру в IT-индустрии.",
        },
      ],
    };
  },
};
</script>
<style scoped>
.content-header {
  padding: 64px 100px;
  background: linear-gradient(90deg, #593ed3 0%, #524ed9 45.83%, #3d64e6 100%);
  /* width: 1240px; */
  /* height: 48px; */
  color: white;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

:deep .swiper-pagination-bullet {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e4d7ff;
}

:deep .swiper-pagination-bullet-active {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e5e7eb;
}

:deep .pagination {
  margin-top: 16px;
}

@media (max-width: 650px) {
  .content-header {
    padding: 40px 16px;
    font-size: 28px;
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  .adaptive-slider {
    display: none;
  }

  .adaptive-slider2 {
    display: flex;
  }
}

@media (min-width: 651px) {
  .adaptive-slider {
    display: grid;
  }

  .adaptive-slider2 {
    display: none;
  }
}
</style>
