<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0073 4.09557C11.8764 4.09544 11.7467 4.12114 11.6256 4.17119C11.5046 4.22125 11.3946 4.29469 11.302 4.38729C11.2094 4.4799 11.136 4.58987 11.0859 4.71089C11.0359 4.83192 11.0102 4.96163 11.0103 5.09259L11.0032 12.001L4.08773 12.001C3.9568 12.001 3.82716 12.0268 3.70619 12.0769C3.58523 12.127 3.47532 12.2005 3.38273 12.293C3.29015 12.3856 3.21671 12.4955 3.16661 12.6165C3.1165 12.7375 3.09071 12.8671 3.09071 12.998C3.09071 13.129 3.1165 13.2586 3.16661 13.3796C3.21671 13.5006 3.29015 13.6105 3.38273 13.703C3.47532 13.7956 3.58523 13.8691 3.70619 13.9192C3.82716 13.9693 3.9568 13.9951 4.08773 13.9951L11.0032 13.9951L11.0032 20.9106C11.0032 21.0415 11.029 21.1712 11.0791 21.2921C11.1292 21.4131 11.2027 21.523 11.2953 21.6156C11.3878 21.7082 11.4978 21.7816 11.6187 21.8317C11.7397 21.8818 11.8693 21.9076 12.0003 21.9076C12.1312 21.9076 12.2608 21.8818 12.3818 21.8317C12.5028 21.7816 12.6127 21.7082 12.7053 21.6156C12.7978 21.523 12.8713 21.4131 12.9214 21.2921C12.9715 21.1712 12.9973 21.0415 12.9973 20.9106L12.9973 13.9951L19.9128 13.9951C20.0437 13.9951 20.1734 13.9693 20.2943 13.9192C20.4153 13.8691 20.5252 13.7956 20.6178 13.703C20.7104 13.6105 20.7838 13.5006 20.8339 13.3796C20.884 13.2586 20.9098 13.129 20.9098 12.998C20.9098 12.8671 20.884 12.7375 20.8339 12.6165C20.7838 12.4955 20.7104 12.3856 20.6178 12.293C20.5252 12.2005 20.4153 12.127 20.2943 12.0769C20.1734 12.0268 20.0437 12.001 19.9128 12.001L12.9973 12.001L12.9973 5.08552C12.9973 4.54812 12.5447 4.09557 12.0073 4.09557Z"
      fill="#D1D5DB"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
