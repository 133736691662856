<template>
  <TheCard style="padding: 0; border: none">
    <div
      class="w-full p-6 place-content-between inline-flex border-gray-200 border-b"
    >
      <div class="text-lg leading-8 font-semibold text-gray-900">
        {{ content_name }}
      </div>
      <div class="inline-flex">
        <MenuAlt4Icon class="h-5 mr-2 draggable_icon cursor-move" />
        <TrashIcon
          class="h-5 cursor-pointer"
          @click="handleRemoveButtonClicked"
        />
      </div>
    </div>
    <div class="p-6 border-b">
      <slot />
    </div>
  </TheCard>
</template>
<script>
import TheCard from "@/components/TheCard/TheCard";
import { MenuAlt4Icon, TrashIcon } from "@heroicons/vue/outline";
import { UPLOAD_ATTACHMENT, DELETE_ATTACHMENT } from "@/store/resources";
import { mapActions } from "vuex";
import { reactive } from "vue";
import ContentBlockMixin from "@/views/core/News/components/ContentBlockMixin";

export default {
  components: {
    TheCard,
    MenuAlt4Icon,
    TrashIcon,
  },
  mixins: [ContentBlockMixin],
  methods: {
    ...mapActions([UPLOAD_ATTACHMENT, DELETE_ATTACHMENT]),
    clickInputFiles() {
      this.$refs.attachments_input.click();
    },
    generateFileObject(file) {
      return reactive({
        file,
        name: file.name,
        status: "loading",
        size: file.size,
        id: "_" + this.modelValue,
      });
    },
    setStatusToObject(fileObject, status) {
      fileObject.status = status;
    },
    async handleFileUpload(e) {
      console.log(e);
    },
    async removeFile(id) {
      console.log(id);
    },
    async submitFile(fileObject) {
      console.log(fileObject);
    },
  },
};
</script>
