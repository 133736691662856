<script>
import TheButton from "@/components/Button/Button.vue";
import OpportunitiesOne from "@/views/core/Landing/Services/OpportunitiesOne.vue";
import OpportunitiesTwo from "@/views/core/Landing/Services/OpportunitiesTwo.vue";
import OpportunitiesThree from "@/views/core/Landing/Services/OpportunitiesThree.vue";
import OpportunitiesFour from "@/views/core/Landing/Services/OpportunitiesFour.vue";
import OpportunitiesFive from "@/views/core/Landing/Services/OpportunitiesFive.vue";
import OpportunitiesSix from "@/views/core/Landing/Services/OpportunitiesSix.vue";

import CourseTeachersButton from "@/views/core/Landing/Courses/CourseTeachersButton.vue";
import TheLandingStartCourseForm from "@/views/core/Landing/SliderComponents/TheLandingStartCourseForm.vue";
import ServicesAdvantages from "@/views/core/Landing/Services/ServicesAdvantages.vue";
import NewLandingReviews from "@/views/core/Landing/NewLanding/sections/NewLandingReviews.vue";
import TheLandingStats from "@/views/core/Landing/NewLanding/sections/TheLandingStats.vue";
import CourseMaterial from "@/views/core/Landing/Courses/CourseMaterial.vue";
import OurPartners from "@/views/core/Landing/AboutUs/OurPartners.vue";
import TheLandingBanner from "@/views/core/Landing/TheLandingBanner.vue";
import CoursesDarkForm from "@/views/core/Landing/Courses/CoursesDarkForm.vue";
export default {
  components: {
    CoursesDarkForm,
    TheLandingBanner,
    OurPartners,
    CourseMaterial,
    TheLandingStats,
    NewLandingReviews,
    ServicesAdvantages,
    TheLandingStartCourseForm,
    TheButton,
    OpportunitiesTwo,
    OpportunitiesThree,
    OpportunitiesOne,
    OpportunitiesFour,
    OpportunitiesFive,
    OpportunitiesSix,

    CourseTeachersButton,
  },
  data() {
    return {
      hoverLeft: false,
      hoverRight: false,
      formData: {
        personName: "",
        phone: "",
        email: "",
      },
      currentIndex: 0,
      slides: [
        { id: 0, comp: OpportunitiesOne, active: true },
        { id: 1, comp: OpportunitiesTwo, active: false },
        { id: 2, comp: OpportunitiesThree, active: false },
        { id: 3, comp: OpportunitiesFour, active: false },
        { id: 4, comp: OpportunitiesFive, active: false },
        { id: 5, comp: OpportunitiesSix, active: false },
      ],
      boxes: [
        { id: 0, active: true },
        { id: 1, active: false },
        { id: 2, active: false },
        { id: 3, active: false },
        { id: 4, active: false },
        { id: 5, active: false },
      ],
    };
  },

  computed: {
    activeSlideIndex() {
      return this.slides.findIndex((slide) => slide.active === true);
    },
  },
  methods: {
    changeSlide(index) {
      let desiredIndex = 0;

      this.slides.forEach((slide, i) => {
        if (slide.id === index) {
          desiredIndex = i;
        }
        slide.active = slide.id === index;
      });

      this.boxes.forEach((box) => {
        box.active = box.id === index;
      });

      let movedSlides = this.slides.splice(0, desiredIndex);
      this.slides = this.slides.concat(movedSlides);
    },

    setBoxInActive(val) {
      this.boxes = this.boxes.map((i) =>
        i.id === val ? { ...i, active: false } : i
      );
    },
    setBoxActive(val) {
      this.boxes = this.boxes.map((i) =>
        i.id === val ? { ...i, active: true } : i
      );
    },

    next() {
      this.currentIndex = (this.currentIndex + 1) % this.slides.length;
      const activeIndex = this.activeSlideIndex;
      this.slides[activeIndex].active = false;
      this.setBoxInActive(this.slides[activeIndex].id);
      this.slides[(activeIndex + 1) % this.slides.length].active = true;
      this.setBoxActive(this.slides[(activeIndex + 1) % this.slides.length].id);

      const first = this.slides.shift();
      this.slides = this.slides.concat(first);
    },

    previous() {
      this.currentIndex = (this.currentIndex - 1) % this.slides.length;

      const activeIndex = this.activeSlideIndex;

      this.slides[activeIndex].active = false;
      this.setBoxInActive(this.slides[activeIndex].id);

      this.slides[
        (activeIndex - 1 + this.slides.length) % this.slides.length
      ].active = true;
      this.setBoxActive(
        this.slides[(activeIndex - 1 + this.slides.length) % this.slides.length]
          .id
      );

      const last = this.slides.pop();
      this.slides = [last].concat(this.slides);
    },
  },
};
</script>

<template>
  <div>
    <div class="services flex flex-col gap-10">
      <h1>Услуги</h1>
      <div class="services-grid">
        <div class="services-grid-box-1 flex flex-col gap-10">
          <div class="flex flex-col gap-4">
            <div class="flex gap-2 justify-start items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="3"
                viewBox="0 0 22 3"
                fill="none"
              >
                <rect y="0.5" width="22" height="2" rx="1" fill="#786CE6" />
              </svg>
              <span class="services-grid-box-1-text"
                >платформа для создания курсов</span
              >
            </div>
            <div class="services-grid-title">
              <div>Хотите создать свою онлайн-школу?</div>
              <div>Запустите свои курсы у нас уже сейчас</div>
            </div>
            <div class="flex flex-col gap-5 services-grid-text">
              <div>
                Наша платформа — это интерактивная онлайн академия, где вы
                можете общаться с преподавателем и другими учениками, выполняете
                задания, делаете домашку и пользуетесь всеми образовательными
                ресурсами школы
              </div>
              <div>Создавайте образовательный контент легко и быстро</div>
              <div>
                Начните использовать все инструменты платформы прямо сейчас!
              </div>
            </div>
          </div>
          <div class="flex justify-start">
            <TheButton
              class="flex gap-2 justify-center items-center request-button"
              >Оставить заявку
            </TheButton>
          </div>
        </div>
        <div class="services-grid-box-2">
          <img src="../../../../assets/images/landing/platform-lms.png" />
        </div>
      </div>
    </div>
    <div class="opportunities flex flex-col gap-8">
      <h2>Возможности платформы</h2>

      <div class="flex flex-col gap-8">
        <div class="flex carousel-inner-container sm:flex-col">
          <div class="transition--comp">
            <div>
              <component
                v-for="slide in slides"
                :key="slide.id"
                :is="slide.comp"
                v-show="slide.active"
              ></component>
            </div>
          </div>

          <div class="flex justify-between items-center adaptive-carousel2">
            <div class="flex gap-4 boxes-mob">
              <div
                v-for="box in boxes"
                :key="box.id"
                @click="changeSlide(box.id)"
                :class="{
                  'colored-box': box.active,
                  'uncolored-box': !box.active,
                }"
              ></div>
            </div>

            <div class="carousel-controls">
              <div class="flex gap-4">
                <div @click="previous" class="cursor-pointer">
                  <CourseTeachersButton>
                    <svg
                      :class="{ hover: hoverLeft }"
                      @mouseover="hoverLeft = true"
                      @mouseleave="hoverLeft = false"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                        :fill="hoverLeft ? 'white' : '#7A69EE'"
                        transform="rotate(180, 14, 14)"
                      />
                    </svg>
                  </CourseTeachersButton>
                </div>
                <div @click="next" class="cursor-pointer">
                  <CourseTeachersButton>
                    <svg
                      :class="{ hover: hoverRight }"
                      @mouseover="hoverRight = true"
                      @mouseleave="hoverRight = false"
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                        :fill="hoverRight ? 'white' : '#7A69EE'"
                      />
                    </svg>
                  </CourseTeachersButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center adaptive-carousel">
          <div class="basis flex gap-4">
            <div
              class="cursor-pointer"
              v-for="box in boxes"
              :key="box.id"
              @click="changeSlide(box.id)"
              :class="{
                'colored-box': box.active,
                'uncolored-box': !box.active,
              }"
            ></div>
          </div>
          <div class="basis flex justify-center items-center">
            {{ currentIndex + 1 }}/{{ slides.length }}
          </div>

          <div class="basis flex gap-4 carousel-controls justify-end items-end">
            <div class="flex gap-4 i">
              <div @click="previous" class="cursor-pointer">
                <CourseTeachersButton>
                  <svg
                    :class="{ hover: hoverLeft }"
                    @mouseover="hoverLeft = true"
                    @mouseleave="hoverLeft = false"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                      :fill="hoverLeft ? 'white' : '#7A69EE'"
                      transform="rotate(180, 14, 14)"
                    />
                  </svg>
                </CourseTeachersButton>
              </div>
              <div @click="next" class="cursor-pointer">
                <CourseTeachersButton>
                  <svg
                    :class="{ hover: hoverRight }"
                    @mouseover="hoverRight = true"
                    @mouseleave="hoverRight = false"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                      :fill="hoverRight ? 'white' : '#7A69EE'"
                    />
                  </svg>
                </CourseTeachersButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ServicesAdvantages />
    <NewLandingReviews />
    <TheLandingStats />
    <CourseMaterial />
    <OurPartners />
    <TheLandingBanner />

    <CoursesDarkForm id="landing-form" ref="landingForm" :formData="formData" />
  </div>
</template>

<style scoped>
.services {
  padding: 40px 100px;
  background: #f4f5fa;
}
h1 {
  font-size: 40px;
  font-weight: 800;
}
h2 {
  font-size: 40px;
  font-weight: 700;
}
.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
}
.services-grid-box-1 {
  padding: 38px 0;
}
.services-grid-box-1-text {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: #6759ea;
  text-transform: uppercase;
}
.services-grid-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
}
.services-grid-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #6b7280;
}
.request-button {
  padding: 16px 40px;
  border-radius: 25px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
.services-grid-box-2 {
  background: #ece9ff;
  border-radius: 32px;
}
.services-grid-box-2 img {
  padding: 87px 0 0 52px;
}
.opportunities {
  padding: 64px 100px;
}

.carousel-controls {
  display: flex;
}
.carousel-controls__button {
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: white;
  color: gray;
  border-radius: 16px;
  border: 1px solid #7a69ee;
  font-size: 20px;
}

.uncolored-box {
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e5e7eb;
}

.colored-box {
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #5b4ccc;
  animation: @moveBox 0.5s forwards;
}

.transition--comp {
  overflow: hidden;
  border-radius: 30px;
}
transition-group {
}
.basis {
  flex-grow: 1; /* Allow items to grow and fill the available space equally */
  flex-shrink: 0; /* Prevent items from shrinking */
  max-width: 33.3%;
}
@media (max-width: 650px) {
  #app {
    padding: 16px;
  }

  .carousel-inner-container {
    flex-direction: column;
  }

  .adaptive-carousel {
    display: none;
  }
  .adaptive-carousel2 {
    display: flex;
    margin-bottom: 40px;
  }
  .carousel-controls {
    padding-top: 12px;
  }
}

@media (min-width: 650px) {
  .adaptive-carousel {
    display: flex;
  }
  .adaptive-carousel2 {
    display: none;
  }
}

@media (max-width: 650px) {
  .services-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .services,
  .opportunities {
    padding: 32px 16px;
  }
  .boxes-mob {
    padding-top: 16px;
    gap: 8px;
  }
  .colored-box,
  .uncolored-box {
    width: 22px;
  }
}

.transition--form {
  flex-basis: 45%;
  min-height: 100px; /* Adjust the min-height to fit your content */
}
</style>
