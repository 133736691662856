import { mapActions } from "vuex";
import { GET_COURSE_GROUP, GET_COURSE } from "@/store/courses";
import { GET_COURSE_GROUPS, GET_USERS_NEXT } from "@/store/users";

export default {
  data() {
    return {
      students: [],
      nextLink: null,
      position: false,
      course_id: null,
      group_id: null,
      search: "",
      fetched: false,
      fetching: false,
    };
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale;
    },
  },

  methods: {
    ...mapActions([
      GET_COURSE,
      GET_COURSE_GROUP,
      GET_USERS_NEXT,
      GET_COURSE_GROUPS,
    ]),

    async getUsers(role, search) {
      this.fetching = true;
      var response = await this.$store.dispatch(GET_COURSE_GROUPS, {
        course: this.course_id,
        role: role,
        group_number: this.group_id,
        search: search,
      });
      this.nextLink = response.next;
      this.fetching = false;
      return response;
    },

    async extendUsersList() {
      const notFetching = !this.fetching;
      const hasMoreData = this.nextLink;

      if (notFetching && hasMoreData) {
        this.fetching = true;
        if (this.nextLink) {
          const result = await this.GET_USERS_NEXT(
            this.nextLink.split("/users")[1]
          );
          // this.students = this.students.concat(result.results);
          this.students = this.students.concat(
              result.results.map((r) => ({
                ...r.user,
                attended: null,
                group_number: r.group_number,
              }))
          );
          this.nextLink = result.next;
        }
        this.fetching = false;
        if (
          document.body.scrollTop > 20 ||
          document.documentElement.scrollTop > 20
        ) {
          this.position = true;
        } else {
          this.position = false;
        }
      }
    },
    checkIfNearEnd() {
      const sentinel = this.$refs.sentinel;
      if (sentinel) {
        if (sentinel instanceof HTMLElement) {
          const sentinelRect = sentinel.getBoundingClientRect();
          const isNearEnd = sentinelRect.top <= window.innerHeight;

          if (isNearEnd) {
            this.extendUsersList();
          }
        } else {
          console.error("Sentinel is not an HTMLElement", sentinel);
        }
      } else {
        console.error("Sentinel ref is not defined");
      }
    },
  },
  watch: {
    async group_id(newVal) {
      if (newVal !== null) {
        await this.getUsers("student", this.search).then((res) => {
          this.students = res.results.map((r) => ({
            ...r.user,
            attended: null,
            group_number: r.group_number,
          }));
          this.fetched = true;
        });
      } else {
        this.students = [];
      }
      window.addEventListener("scroll", this.checkIfNearEnd);
    },
  },
};
