import {mapActions} from "vuex";
import {SUBMIT_ANSWER} from "@/store/resources";

export default {
    props:{
        id:{
            type:[Number,Text],
            required:true
        },
        attachments:{
            type:Array,
            default:()=>[]
        },
        content:{
            type:String,
            default:''
        },
        available_answers:{
            type:Array,
            default:()=>[]
        },
        src:{
            type:String,
            default:''
        },
        is_disabled:{
            type:Boolean,
            default:false
        },
        is_correct:{
            type:Boolean,
            default:false
        }
    },
    data:()=>({
        answer:"",
    }),
    methods:{
        ...mapActions([SUBMIT_ANSWER]),
        submitAnswer(answer='',option_id=null){
            const payload={
                question_id:this.id,
                option_id,
                answer
            }
            return this[SUBMIT_ANSWER](payload)
        }
    },
    computed:{
        is_answered(){
            return !!this.answer
        },
    }
}