<template>
<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.998047" width="64" height="64" rx="16" fill="#7D69FC"/>
<path d="M41 31.498C43.235 31.498 45.305 32.203 47 33.388V26.998C47 25.333 45.665 23.998 44 23.998H38V20.998C38 19.333 36.665 17.998 35 17.998H29C27.335 17.998 26 19.333 26 20.998V23.998H20C18.335 23.998 17.015 25.333 17.015 26.998L17 43.498C17 45.163 18.335 46.498 20 46.498H31.52C30.759 44.8983 30.4154 43.132 30.5212 41.3636C30.6271 39.5952 31.1789 37.8825 32.1254 36.385C33.0718 34.8874 34.382 33.6539 35.9338 32.7994C37.4856 31.9449 39.2285 31.4972 41 31.498ZM29 20.998H35V23.998H29V20.998Z" fill="white"/>
<path d="M41 34.498C36.86 34.498 33.5 37.858 33.5 41.998C33.5 46.138 36.86 49.498 41 49.498C45.14 49.498 48.5 46.138 48.5 41.998C48.5 37.858 45.14 34.498 41 34.498ZM43.475 45.523L40.25 42.298V37.498H41.75V41.683L44.525 44.458L43.475 45.523Z" fill="white"/>
</svg>

</template>

<script>
export default {

}
</script>

<style>

</style>