<template>
  <div
    class="flex flex-col justify-start bg-transparent px-10 pb-20 pt-10 advantage-box"
    style="border-radius: 40px"
  >
    <slot name="icon" />
    <span
      class="text-3xl text-white font-semibold text-start mb-4 adaptive-title"
      >{{ headerText }}</span
    >
    <span class="text-lg leading-7 text-white font-normal adaptive-subtitle">{{
      text
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    headerText: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.bg-transparent {
  background: rgba(255, 255, 255, 0.3);
  color: white;
}

@media (max-width: 650px) {
  .adaptive-title {
    font-size: 24px;
  }
  .adaptive-subtitle {
    font-size: 16px;
  }
  .advantage-box {
    padding: 24px 20px;
  }
}
</style>
