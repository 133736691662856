<template>
  <div>
    <h1 class="text-2xl leading-8 font-semibold text-gray-900 mb-4">
      {{ $t("news_publication") }}
    </h1>
    <div class="grid grid-cols-3 gap-6">
      <router-link :to="{ name: 'NewsForm' }">
        <div
          class="border-dashed border-gray-300 border-2 rounded-xl h-130 bg-white flex flex-col gap-3 justify-center items-center"
        >
          <NewspaperIcon class="h-10 w-10 text-indigo-600" />
          <div class="text-sm leading-5 font-medium text-gray-900">
            {{ $t("create_news") }}
          </div>
        </div>
      </router-link>
      <template v-for="newsItem in news" :key="newsItem.id">
        <router-link :to="{ name: 'NewsEdit', params: { id: newsItem.id } }">
          <div
            class="rounded-xl h-130 bg-white overflow-hidden flex flex-col shadow-md cursor-pointer"
          >
            <img
              :src="newsItem.cover"
              :alt="newsItem.name"
              class="h-[192px] w-full object-cover"
            />
            <div class="flex-1 p-6 flex flex-col gap-3">
              <h3 class="text-xl leading-7 font-semibold text-gray-900">
                {{ newsItem.title }}
              </h3>
              <p
                class="max-h-36 overflow-hidden text-base leading-6 font-normal text-gray-500 mb-auto"
                v-html="newsItem.short_description"
              />
              <div class="text-sm leading-5 font-normal text-gray-500">
                {{
                  `${new Date(newsItem.publication_date).toLocaleDateString(
                    "en-US",
                    {
                      month: "short",
                      day: "2-digit",
                      year: "numeric",
                    }
                  )} · 3 min read`
                }}
              </div>
            </div>
          </div>
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { NewspaperIcon } from "@heroicons/vue/outline";
import { mapActions } from "vuex";
import { GET_NEWS_LIST } from "@/store/news";

export default {
  components: { NewspaperIcon },
  methods: {
    ...mapActions([GET_NEWS_LIST]),
  },
  data() {
    return {
      news: [],
    };
  },
  async mounted() {
    this.news = await this[GET_NEWS_LIST]();
  },
};
</script>