import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { LOGIN_REQUEST } from "@/store/auth";

export default {
  data: () => ({
    show_password: false,
    errors: "",
  }),
  methods: {
    ...mapActions({ LOGIN_REQUEST }),
    async submit() {
      const { email, password, remember_me } = this;
      try {
        await this.LOGIN_REQUEST({ email, password, remember_me });
        this.$router.push({ name: "MyCourses" });
      } catch (error_text) {
        this.errors = [error_text];
      }
    },
  },
  computed: {
    ...mapFields({
      email: "auth.login_form.email",
      password: "auth.login_form.password",
      remember_me: "auth.login_form.remember_me",
    }),
  },
};
