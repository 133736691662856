<template>
  <div class="flex flex-col gap-3 pb-4">
    <MailingItem v-for="mailing in mailings" :key="mailing.id" :mailing="mailing" />
  </div>
</template>

<script>
import MailingItem from '@/views/mailings/MailingItem/MailingItem';
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import { GET_MAILINGS } from '@/store/mailings';

export default {
  components: { MailingItem },
  computed: {
    ...mapFields(['mailings.mailings'])
  },
  methods: {
    ...mapActions([GET_MAILINGS])
  },
  mounted() {
    this[GET_MAILINGS]();
  }
}
</script>