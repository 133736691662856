<template>
  <TheModal
    :show="modalOpen"
    className="register-modal-block"
    @close="closeModal"
    ><div class="register-modal">
      <div class="flex flex-col gap-6">
        <div class="close-modal-block justify-between">
          <div v-if="cartItems.length > 0">Добавлено в корзину!</div>
          <div v-else>
            Ошибка при добавлении в корзину. Попытайтесь еще раз пожалуйста.
          </div>
          <div class="cursor-pointer" @click="closeModal">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.5004 9.51704C30.3462 9.36253 30.163 9.23995 29.9614 9.15632C29.7598 9.07268 29.5437 9.02963 29.3254 9.02963C29.1071 9.02963 28.891 9.07268 28.6893 9.15632C28.4877 9.23995 28.3046 9.36253 28.1504 9.51704L20.0004 17.6504L11.8504 9.50037C11.6961 9.34607 11.5129 9.22367 11.3113 9.14016C11.1097 9.05665 10.8936 9.01367 10.6754 9.01367C10.4572 9.01367 10.2411 9.05665 10.0395 9.14016C9.83786 9.22367 9.65468 9.34607 9.50037 9.50037C9.34607 9.65468 9.22367 9.83786 9.14016 10.0395C9.05665 10.2411 9.01367 10.4572 9.01367 10.6754C9.01367 10.8936 9.05665 11.1097 9.14016 11.3113C9.22367 11.5129 9.34607 11.6961 9.50037 11.8504L17.6504 20.0004L9.50037 28.1504C9.34607 28.3047 9.22367 28.4879 9.14016 28.6895C9.05665 28.8911 9.01367 29.1072 9.01367 29.3254C9.01367 29.5436 9.05665 29.7597 9.14016 29.9613C9.22367 30.1629 9.34607 30.3461 9.50037 30.5004C9.65468 30.6547 9.83786 30.7771 10.0395 30.8606C10.2411 30.9441 10.4572 30.9871 10.6754 30.9871C10.8936 30.9871 11.1097 30.9441 11.3113 30.8606C11.5129 30.7771 11.6961 30.6547 11.8504 30.5004L20.0004 22.3504L28.1504 30.5004C28.3047 30.6547 28.4879 30.7771 28.6895 30.8606C28.8911 30.9441 29.1072 30.9871 29.3254 30.9871C29.5436 30.9871 29.7597 30.9441 29.9613 30.8606C30.1629 30.7771 30.3461 30.6547 30.5004 30.5004C30.6547 30.3461 30.7771 30.1629 30.8606 29.9613C30.9441 29.7597 30.9871 29.5436 30.9871 29.3254C30.9871 29.1072 30.9441 28.8911 30.8606 28.6895C30.7771 28.4879 30.6547 28.3047 30.5004 28.1504L22.3504 20.0004L30.5004 11.8504C31.1337 11.217 31.1337 10.1504 30.5004 9.51704Z"
                fill="#B3A6F9"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-col gap-8 cart-courses">
          <div class="flex gap-6 course-border" v-if="cartItems.length > 0">
            <div class="flex flex-col gap-2">
              <div class="flex gap-2">
                <Badge
                  :has_circle="false"
                  :bg="this.cartItems[this.cartItems.length - 1].level"
                  class="font-medium text-sm py-3 px-3 flex justify-center"
                  >Уровень:
                  {{ this.cartItems[this.cartItems.length - 1].level }}
                </Badge>
                <Badge
                  :has_circle="false"
                  bg="program"
                  class="font-medium text-sm py-3 px-3 flex justify-center"
                  >Программа:
                  {{ this.cartItems[this.cartItems.length - 1].program }}</Badge
                >
              </div>
              <div class="course-title">
                {{ this.cartItems[this.cartItems.length - 1].name }}
              </div>
              <div class="course-description text-gray-500">
                {{ this.cartItems[this.cartItems.length - 1].description }}
              </div>
            </div>
            <div class="flex align-center course-price">590 000 ₸</div>
            <div class="flex align-center">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.6654 23.0007L14.082 19.4173C13.7765 19.1118 13.3876 18.959 12.9154 18.959C12.4431 18.959 12.0543 19.1118 11.7487 19.4173C11.4431 19.7229 11.2904 20.1118 11.2904 20.584C11.2904 21.0562 11.4431 21.4451 11.7487 21.7507L16.4987 26.5007C16.832 26.834 17.2209 27.0007 17.6654 27.0007C18.1098 27.0007 18.4987 26.834 18.832 26.5007L28.2487 17.084C28.5543 16.7784 28.707 16.3895 28.707 15.9173C28.707 15.4451 28.5543 15.0562 28.2487 14.7507C27.9431 14.4451 27.5543 14.2923 27.082 14.2923C26.6098 14.2923 26.2209 14.4451 25.9154 14.7507L17.6654 23.0007ZM19.9987 36.6673C17.6931 36.6673 15.5265 36.2295 13.4987 35.354C11.4709 34.4784 9.70703 33.2912 8.20703 31.7923C6.70703 30.2923 5.51981 28.5284 4.64536 26.5007C3.77092 24.4729 3.33314 22.3062 3.33203 20.0007C3.33203 17.6951 3.76981 15.5284 4.64536 13.5007C5.52092 11.4729 6.70814 9.70898 8.20703 8.20898C9.70703 6.70898 11.4709 5.52176 13.4987 4.64732C15.5265 3.77287 17.6931 3.3351 19.9987 3.33398C22.3043 3.33398 24.4709 3.77176 26.4987 4.64732C28.5265 5.52287 30.2904 6.7101 31.7904 8.20898C33.2904 9.70898 34.4781 11.4729 35.3537 13.5007C36.2293 15.5284 36.6665 17.6951 36.6654 20.0007C36.6654 22.3062 36.2276 24.4729 35.352 26.5007C34.4765 28.5284 33.2893 30.2923 31.7904 31.7923C30.2904 33.2923 28.5265 34.4801 26.4987 35.3556C24.4709 36.2312 22.3043 36.6684 19.9987 36.6673Z"
                  fill="#69C9A6"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="flex flex-col gap-6">-->
      <!--        <div class="close-modal-block justify-between">-->
      <!--          <div>Часто берут вместе:</div>-->
      <!--        </div>-->
      <!--        <div class="flex flex-col gap-8 cart-courses">-->
      <!--          <div-->
      <!--            v-for="(course, index) in courses"-->
      <!--            :key="index"-->
      <!--            class="flex flex-col gap-8"-->
      <!--          >-->
      <!--            <div class="flex gap-6 course-border-unadded">-->
      <!--              <div class="flex flex-col gap-2">-->
      <!--                <div class="flex gap-2">-->
      <!--                  <Badge-->
      <!--                    :has_circle="false"-->
      <!--                    :bg="course.level"-->
      <!--                    class="font-medium text-sm py-3 px-3 flex justify-center"-->
      <!--                    >Уровень: {{ course.level }}-->
      <!--                  </Badge>-->
      <!--                  <Badge-->
      <!--                    :has_circle="false"-->
      <!--                    :bg="program"-->
      <!--                    class="font-medium text-sm py-3 px-3 flex justify-center"-->
      <!--                    >Программа: {{ course.program }}</Badge-->
      <!--                  >-->
      <!--                </div>-->
      <!--                <div class="course-title">{{ course.name }}</div>-->
      <!--                <div class="course-description text-gray-500">-->
      <!--                  {{ course.description }}-->
      <!--                </div>-->
      <!--              </div>-->
      <!--              <div class="flex align-center course-price">374 722 ₸</div>-->
      <!--              <div class="flex align-center">-->
      <!--                <svg-->
      <!--                  width="44"-->
      <!--                  height="44"-->
      <!--                  viewBox="0 0 44 44"-->
      <!--                  fill="none"-->
      <!--                  xmlns="http://www.w3.org/2000/svg"-->
      <!--                >-->
      <!--                  <g filter="url(#filter0_d_797_5146)">-->
      <!--                    <rect-->
      <!--                      x="3"-->
      <!--                      y="2"-->
      <!--                      width="38"-->
      <!--                      height="38"-->
      <!--                      rx="19"-->
      <!--                      stroke="#7A69EE"-->
      <!--                      stroke-width="2"-->
      <!--                    />-->
      <!--                    <path-->
      <!--                      d="M13 12H15L15.4 14M17 22H27L31 14H15.4M17 22L15.4 14M17 22L14.7071 24.2929C14.0771 24.9229 14.5233 26 15.4142 26H27M27 26C25.8954 26 25 26.8954 25 28C25 29.1046 25.8954 30 27 30C28.1046 30 29 29.1046 29 28C29 26.8954 28.1046 26 27 26ZM19 28C19 29.1046 18.1046 30 17 30C15.8954 30 15 29.1046 15 28C15 26.8954 15.8954 26 17 26C18.1046 26 19 26.8954 19 28Z"-->
      <!--                      stroke="#111827"-->
      <!--                      stroke-width="1.7"-->
      <!--                      stroke-linecap="round"-->
      <!--                      stroke-linejoin="round"-->
      <!--                    />-->
      <!--                  </g>-->
      <!--                  <defs>-->
      <!--                    <filter-->
      <!--                      id="filter0_d_797_5146"-->
      <!--                      x="0"-->
      <!--                      y="0"-->
      <!--                      width="44"-->
      <!--                      height="44"-->
      <!--                      filterUnits="userSpaceOnUse"-->
      <!--                      color-interpolation-filters="sRGB"-->
      <!--                    >-->
      <!--                      <feFlood flood-opacity="0" result="BackgroundImageFix" />-->
      <!--                      <feColorMatrix-->
      <!--                        in="SourceAlpha"-->
      <!--                        type="matrix"-->
      <!--                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"-->
      <!--                        result="hardAlpha"-->
      <!--                      />-->
      <!--                      <feOffset dy="1" />-->
      <!--                      <feGaussianBlur stdDeviation="1" />-->
      <!--                      <feColorMatrix-->
      <!--                        type="matrix"-->
      <!--                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"-->
      <!--                      />-->
      <!--                      <feBlend-->
      <!--                        mode="normal"-->
      <!--                        in2="BackgroundImageFix"-->
      <!--                        result="effect1_dropShadow_797_5146"-->
      <!--                      />-->
      <!--                      <feBlend-->
      <!--                        mode="normal"-->
      <!--                        in="SourceGraphic"-->
      <!--                        in2="effect1_dropShadow_797_5146"-->
      <!--                        result="shape"-->
      <!--                      />-->
      <!--                    </filter>-->
      <!--                  </defs>-->
      <!--                </svg>-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="flex justify-end">
        <div class="flex gap-6">
          <button
            class="flex gap-2 py-4 px-6 justify-center items-center font-medium text-lg bg-white continue__button"
            @click="closeModal"
          >
            Продолжить покупки
          </button>
          <router-link :to="{ name: 'ShoppingCart' }">
            <button
              class="flex gap-2 py-4 px-6 justify-center items-center font-medium text-lg bg-white addtocart__button"
            >
              Перейти в корзину
            </button></router-link
          >
        </div>
      </div>
    </div>
  </TheModal>
</template>
<script>
import { defineComponent } from "vue";
import TheModal from "@/components/TheModal/TheModal.vue";
import axios from "axios";
import Badge from "@/components/TheBadge/TheBadge.vue";
import { ShoppingCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartMixin";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";

export default defineComponent({
  components: { Badge, TheModal },
  props: ["modalOpen"],
  mixins: [ShoppingCartMixin, AllCoursesMixin],

  data() {
    return {
      personName: "",
      email: "",
      password: "",
      isPersonNameValid: false,
      isEmailValid: false,
      isPasswordValid: false,
      isFormSubmitted: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },

    validateInputs() {
      this.isPersonNameValid = this.personName !== "";
      this.isEmailValid = this.email !== "" && this.email.includes("@");
      this.isPasswordValid = this.password !== "" && this.password.length > 6;
      return (
        this.isPersonNameValid && this.isEmailValid && this.isPasswordValid
      );
    },
    submitForm() {
      if (this.validateInputs()) {
        axios
          .post("/api/v1/application_forms/like_to_study_application/", {
            name: this.personName,
            contact_phone: this.phone,
            email: this.email,
            organization_name: this.organization,
            program: this.programName,
          })
          .then(() => {
            this.isFormSubmitted = true;
            this.personName = "";
            this.email = "";
            this.password = "";

            // Clear the form or handle the submission success
          })
          .catch((error) => {
            console.error("There was an error submitting the form", error);
            // Handle the submission error
          });
      }
    },
  },
});
</script>

<style scoped>
.register-modal {
  padding: 56px 48px;
  display: flex;
  gap: 32px;
  flex-direction: column;
}
.close-modal-block {
  display: flex;
  gap: 24px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  align-items: center;
}
.border-error {
  border: 1px solid rgb(239, 68, 68);
}
.order-button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 32px;
  background: #7a69ee;
  color: white;
}
.confidential-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.text-politics {
  color: #786ce6;
}
.stick-grey {
  display: flex;
  height: 1.5px;
  align-self: stretch;
  background: #d1d5db;
  width: 100%;
}
.text-login {
  color: #786ce6;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}
.register-modal-block {
  width: 550px;
  border-radius: 32px;
}

.cart-main-block {
  padding: 40px 100px;
  gap: 56px;
  margin-bottom: 80px;
}
h1 {
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
}
.cart-number {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.stick {
  display: flex;
  height: 1.5px;
  align-self: stretch;
  background: #1f2937;
  width: 100%;
}
.stick-grey {
  display: flex;
  height: 1.5px;
  align-self: stretch;
  background: #d1d5db;
  width: 100%;
}
.cart-order {
  display: flex;
  width: 460px;
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  //background-image: url("");
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}
.overall-text {
  font-size: 22px;
}
.past-price {
  font-size: 18px;
  text-decoration-line: line-through;
}
.cart-price {
  gap: 180px;
}
.order-button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 32px;
  background: #7a69ee;
  color: white;
}
.course-price {
  white-space: nowrap;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
}
.course-title {
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.course-description {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.course-duration {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  color: #1f2937;
}
.course-dot {
  width: 6px;
  height: 6px;
  border-radius: 100px;
  background: #6b7280;
}
.promo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Adjust the gap as needed */
}

.promo-input {
  padding: 12px; /* Adjust the padding as needed */
  border: 2px solid #e5e7eb; /* Light gray border */
  border-radius: 32px; /* Rounded corners on the left side */
  outline: none;
}

.promo-input:focus {
  border-color: #60a5fa; /* Blue border for focus */
}

.apply-button {
  padding: 12px 24px; /* Adjust the padding as needed */
  background-color: #000000; /* Black background */
  color: #ffffff; /* White text */
  border: none;
  border-radius: 32px; /* Rounded corners on the right side */
  cursor: pointer;
  outline: none;
  height: 100%;
  position: absolute;
  right: 0;
}

.apply-button:hover {
  background-color: #374151; /* Dark gray background on hover */
}

.overall-block {
  white-space: nowrap;
}
input {
  border-radius: 20px;
}
.promo-input:focus {
  border: 2px solid #7a69ee; /* Adjust the border width and color as needed */
}

.promocode-block {
  width: 100%;
}
.course-border {
  border-radius: 24px;
  border: 1.5px solid #4b5563;
  padding: 24px;
}
.course-border-unadded {
  border-radius: 24px;
  border: 1.5px solid #d1d5db;
  padding: 24px;
}

.continue__button {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  border: 2px solid #7a69ee;
  color: #7a69ee;
}

.continue__button:hover {
  background: #7a69ee;
  color: white;
}

.addtocart__button {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  border: 2px solid #7a69ee;
  color: white;
  background: #7a69ee;
}

.addtocart__button:hover {
  background: white;
  color: #7a69ee;
}
</style>
