<template>
  <TheLandingHeader />
  <main class="py-8 md:py-16 px-10 md:px-20 flex flex-col gap-10">
    <h1 class="font-extrabold text-4xl leading-10 text-gray-900">
      {{ $t("our_news") }}
    </h1>
    <div class="grid grid-cols-2 lg:grid-cols-3 gap-6">
      <template v-for="newsItem of news" :key="newsItem.id">
        <div
            class="h-130 shadow-md shadow-md rounded-xl overflow-hidden flex flex-col cursor-pointer"
            @click="handleClick(newsItem.id)"
        >
          <img
              :src="newsItem.cover"
              :alt="newsItem.title"
              class="h-[192px] w-full object-cover"
          />
          <div class="p-6 flex flex-col flex-1">
            <h3 class="text-xl leading-7 font-semibold text-gray-900 mb-3">
              {{ newsItem.title }}
            </h3>
            <p class="text-base leading-6 font-normal text-gray-500 mb-auto" v-html="newsItem.short_description" />
            <div class="flex mt-12">
              <div class="text-sm leading-5 font-normal text-gray-500">
                {{
                  `${new Date(newsItem.publication_date).toLocaleDateString(
                      "en-US",
                      {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      }
                  )} · 3 min read`
                }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div class="flex justify-center"
         :class="news.length <=6 ?'hidden':' '">
      <TheButton bg="secondary">{{ $t("show_more") }}</TheButton>
    </div>
  </main>
  <TheLandingFooter />
</template>

<script>
import TheLandingHeader from "@/views/core/Landing/TheLandingHeader";
import { mapActions } from "vuex";
import TheButton from "@/components/Button/Button";
import TheLandingFooter from "@/views/core/Landing/TheLandingFooter";
import { GET_NEWS_LIST } from '@/store/news';
export default {
  components: {TheLandingFooter, TheButton, TheLandingHeader },
  data() {
    return {
      news: [],
    };
  },
  async mounted() {
    this.news = await this[GET_NEWS_LIST]();
  },
  methods: {
    ...mapActions([GET_NEWS_LIST]),
    handleClick(id) {
      this.$router.push({ name: "News Item", params: { id } });
    },
  },
};
</script>
