<script>
export default {
  props: ["materials"],
};
</script>

<template>
  <div class="needed-materials flex flex-col gap-8">
    <div class="needed-materials-title">Необходимые материалы</div>
    <div class="needed-materials-box flex flex-col gap-8">
      <div v-if="materials.title" class="materials-title">
        {{ materials.title }}
      </div>
      <div class="flex flex-col gap-4">
        <div
          class="flex gap-3 items-start"
          v-for="(material, index) in materials.mat"
          :key="index"
        >
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.59976 11.798L6.44976 9.64795C6.26642 9.46462 6.03309 9.37296 5.74976 9.37296C5.46642 9.37296 5.23309 9.46462 5.04976 9.64795C4.86642 9.83129 4.77476 10.0646 4.77476 10.348C4.77476 10.6313 4.86642 10.8646 5.04976 11.048L7.89976 13.898C8.09976 14.098 8.33309 14.198 8.59976 14.198C8.86642 14.198 9.09976 14.098 9.29976 13.898L14.9498 8.24796C15.1331 8.06462 15.2248 7.83129 15.2248 7.54796C15.2248 7.26462 15.1331 7.03129 14.9498 6.84796C14.7664 6.66462 14.5331 6.57296 14.2498 6.57296C13.9664 6.57296 13.7331 6.66462 13.5498 6.84796L8.59976 11.798ZM9.99976 19.998C8.61642 19.998 7.31642 19.7353 6.09976 19.21C4.88309 18.6846 3.82476 17.9723 2.92476 17.073C2.02476 16.173 1.31242 15.1146 0.787756 13.898C0.263089 12.6813 0.000422526 11.3813 -0.000244141 9.99796C-0.000244141 8.61462 0.262423 7.31462 0.787756 6.09796C1.31309 4.88129 2.02542 3.82296 2.92476 2.92296C3.82476 2.02296 4.88309 1.31062 6.09976 0.785955C7.31642 0.261289 8.61642 -0.00137801 9.99976 -0.00204468C11.3831 -0.00204468 12.6831 0.260622 13.8998 0.785955C15.1164 1.31129 16.1748 2.02362 17.0748 2.92296C17.9748 3.82296 18.6874 4.88129 19.2128 6.09796C19.7381 7.31462 20.0004 8.61462 19.9998 9.99796C19.9998 11.3813 19.7371 12.6813 19.2118 13.898C18.6864 15.1146 17.9741 16.173 17.0748 17.073C16.1748 17.973 15.1164 18.6856 13.8998 19.211C12.6831 19.7363 11.3831 19.9986 9.99976 19.998Z"
                fill="#69C9A6"
              />
            </svg>
          </div>
          <div class="needed-materials-text">{{ material }}</div>
        </div>
      </div>

      <div v-if="materials.title2" class="materials-title">
        {{ materials.title2 }}
      </div>
      <div v-if="materials.mat2" class="flex flex-col gap-4">
        <div
          class="flex gap-3 items-start"
          v-for="(material, index) in materials.mat2"
          :key="index"
        >
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.59976 11.798L6.44976 9.64795C6.26642 9.46462 6.03309 9.37296 5.74976 9.37296C5.46642 9.37296 5.23309 9.46462 5.04976 9.64795C4.86642 9.83129 4.77476 10.0646 4.77476 10.348C4.77476 10.6313 4.86642 10.8646 5.04976 11.048L7.89976 13.898C8.09976 14.098 8.33309 14.198 8.59976 14.198C8.86642 14.198 9.09976 14.098 9.29976 13.898L14.9498 8.24796C15.1331 8.06462 15.2248 7.83129 15.2248 7.54796C15.2248 7.26462 15.1331 7.03129 14.9498 6.84796C14.7664 6.66462 14.5331 6.57296 14.2498 6.57296C13.9664 6.57296 13.7331 6.66462 13.5498 6.84796L8.59976 11.798ZM9.99976 19.998C8.61642 19.998 7.31642 19.7353 6.09976 19.21C4.88309 18.6846 3.82476 17.9723 2.92476 17.073C2.02476 16.173 1.31242 15.1146 0.787756 13.898C0.263089 12.6813 0.000422526 11.3813 -0.000244141 9.99796C-0.000244141 8.61462 0.262423 7.31462 0.787756 6.09796C1.31309 4.88129 2.02542 3.82296 2.92476 2.92296C3.82476 2.02296 4.88309 1.31062 6.09976 0.785955C7.31642 0.261289 8.61642 -0.00137801 9.99976 -0.00204468C11.3831 -0.00204468 12.6831 0.260622 13.8998 0.785955C15.1164 1.31129 16.1748 2.02362 17.0748 2.92296C17.9748 3.82296 18.6874 4.88129 19.2128 6.09796C19.7381 7.31462 20.0004 8.61462 19.9998 9.99796C19.9998 11.3813 19.7371 12.6813 19.2118 13.898C18.6864 15.1146 17.9741 16.173 17.0748 17.073C16.1748 17.973 15.1164 18.6856 13.8998 19.211C12.6831 19.7363 11.3831 19.9986 9.99976 19.998Z"
                fill="#69C9A6"
              />
            </svg>
          </div>
          <div class="needed-materials-text">{{ material }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.needed-materials {
  background: #f4f5fa;
  padding: 64px 100px;
}
.needed-materials-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #1f2937;
}
.needed-materials-box {
  background: white;
  padding: 40px 24px;
  border-radius: 24px;
}
.needed-materials-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #6b7280;
}
.materials-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #111827;
}

@media (max-width: 650px) {
  .needed-materials {
    padding: 32px 16px;
    gap: 24px;
  }
  .needed-materials-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
  }
  .needed-materials-box {
    padding: 28px 20px;
    gap: 19px;
  }
  .needed-materials-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
  }
}
</style>
