<template>
  <div v-if="group" class="flex flex-col gap-10">
    <div class="flex gap-10">
      <PercentageBox text="Средний балл по курсу:" :total="meanScore" />
      <PercentageBox text="Процент выполнения заданий (%):" total="0" />
    </div>
    <div class="flex flex-col gap-4">
      <div class="statistics-box__title">Распределение оценок</div>
      <div class="flex-1">
        <BarChartWrapper
          v-if="statistics"
          :chartData="chartData"
          :chartOptions="chartOptions"
        />
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col items-center">
    Выберите группу для отображения
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PercentageBox from "@/views/courses/TaskPercentage/PercentageBox.vue";
import BarChartWrapper from "@/views/courses/CourseEdit/Statistics/BarChartWrapper.vue";
// import PerformanceGradesMixin from "@/views/courses/CourseEdit/Statistics/PerformanceGradesMixin";

export default defineComponent({
  name: "PerformanceGrades",
  components: {
    PercentageBox,
    BarChartWrapper,
  },
  props: ["group"],
  // mixins: [PerformanceGradesMixin],
  data() {
    return {
      maxScore: 100,
      meanScore: 0,
      chartData: null,
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: this.maxScore,
          },
        },
      },
    };
  },
  methods: {
    generateChartData() {
      if (!this.statistics) return;
      this.meanScore = this.statistics["total"].mean;
      const labels = Object.keys(this.statistics).map((key) => {
        return key !== "total" ? key : "Total";
      });

      const scores = labels.map((label) => {
        const originalLabel = label === "Total" ? "total" : label;
        const meanVal = this.statistics[originalLabel].mean;
        if (meanVal > this.maxScore) this.maxScore = meanVal;
        return meanVal === 0 ? 0.99 : meanVal;
      });

      const backgroundColor = scores.map((score) =>
        score < 50 ? "#FEF2F2" : score < 70 ? "#FFEADC" : "#ECFDF5"
      );
      const borderColor = scores.map((score) =>
        score < 50 ? "#DC2626" : score < 70 ? "#F97316" : "#059669"
      );

      return {
        labels: labels,
        datasets: [
          {
            label: "Scores",
            data: scores,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            borderWidth: 2,
            borderRadius: 16,
          },
        ],
      };
    },
  },
});
</script>

<style scoped>
.statistics-box__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #111827;
}
</style>
