<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <TheModal :show="filled_fields" @close="closeModal">
    <div class="p-5 min-w-max truncate">
      <div class="flex place-content-between items-center mb-5">
        <div
          class="text-xl leading-7 font-semibold text-gray-900"
          v-if="!all_fields"
        >
          {{ $t("fill_fields") }}
        </div>
        <div class="text-xl leading-7 font-semibold text-gray-900" v-else>
          {{ $t("course_added") }}
        </div>
        <XIcon class="h-4 cursor-pointer text-gray-400" @click="closeModal" />
      </div>
    </div>
  </TheModal>
  <TheIconSelectorModal :multiple="false" />
  <div>
    <FormLayout>
      <template #title>
        {{ $t(isUpdating ? "update_course" : "create_new_course") }}
      </template>
      <template #main>
        <TheCard>
          <TheCardHeader>
            {{ $t("course_name") }}
          </TheCardHeader>
          <InputText
            :placeholder="$t('enter_name')"
            v-model="form.name"
            :errors="[is_name ? '' : $t('enter_name')]"
          />
        </TheCard>
        <TheCard>
          <div class="grid grid-cols-2 gap-4 mb-5">
            <div>
              <TheCardHeader>
                {{ $t("start_date") }}
              </TheCardHeader>
              <div class="flex flex-row items-center mb-5">
                <CalendarIcon class="h-6 mr-2" />
                <datepicker
                  style="border-radius: 5px"
                  v-model="form.start_date"
                  :lowerLimit="null"
                  :typeable="true"
                  color="#561BFB"
                  class="no-outline w-100 futura"
                  :locale="ru"
                ></datepicker>
              </div>
            </div>
            <div>
              <TheCardHeader>
                {{ $t("end_date") }}
              </TheCardHeader>
              <div class="flex flex-row items-center mb-5">
                <CalendarIcon class="h-6 mr-2" />
                <datepicker
                  style="border-radius: 5px"
                  v-model="form.end_date"
                  :lowerLimit="form.start_date"
                  :typeable="true"
                  color="#561BFB"
                  class="no-outline w-100 futura"
                  :locale="ru"
                ></datepicker>
              </div>
            </div>
            <p class="text-red-600 text-sm" v-if="!is_dates">
              {{ $t("enter_valid_dates") }}
            </p>
          </div>
        </TheCard>
        <TheCard>
          <TheCardHeader>{{ $t("specializations") }}</TheCardHeader>
          <SpecializationsSelector
            v-model="form.specializations"
            label="name"
            multiple
            placeholder="select_specializations"
          />
        </TheCard>
        <TheCard style="min-width: unset">
          <TheCardHeader>{{ $t("description") }}</TheCardHeader>
          <div
            :class="[
              is_description
                ? ''
                : 'border border-red-600 rounded-md text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500',
            ]"
          >
            <TheQuillEditorInput v-model="form.description" />
          </div>
          <p class="text-red-600 text-sm mt-1" v-if="!is_description">
            {{ $t("enter_description") }}
          </p>
        </TheCard>
        <TheCard>
          <TheCardHeader>{{ $t("what_you_will_learn") }}</TheCardHeader>
          <InputText
            v-for="(goal, index) in form.goals"
            :key="goal.id || goal._key"
            v-model="goal.name"
            :placeholder="$t('enter_point', { number: index + 1 })"
            class="mt-2 mb-2"
            v-bind:errors="[is_goals ? '' : ' ']"
          >
            <template #label>
              <div class="flex place-content-between">
                <span class="text-sm leading-5 font-medium text-gray-700">{{
                  $t("point", { number: index + 1 })
                }}</span>
                <TrashIcon
                  v-if="form.goals.length > 1"
                  class="text-gray-400 h-5 float-right cursor-pointer"
                  @click="removeGoal(index)"
                />
              </div>
            </template>
          </InputText>
          <p class="text-red-600 text-sm mt-1" v-if="!is_goals">
            {{ $t("enter_points") }}
          </p>
          <TheButton bg="secondary" @click="addGoal" class="mt-4">
            <PlusIcon class="h-4" />
            {{ $t("add_point") }}
          </TheButton>
        </TheCard>
        <TheCard>
          <TheCardHeader>{{ $t("how_study_is_passed") }}</TheCardHeader>
          <div
            class="mb-2"
            v-for="(perk, index) in form.perks"
            :key="perk.id || perk._key"
          >
            <InputText
              class="mb-2 mt-2"
              :placeholder="$t('enter_point', { number: index + 1 })"
              v-model="perk.title"
              v-bind:errors="[is_perks ? '' : ' ']"
            >
              <template #label>
                <div class="flex place-content-between">
                  <span class="text-sm leading-5 font-medium text-gray-700">{{
                    $t("point", { number: index + 1 })
                  }}</span>
                  <TrashIcon
                    v-if="form.perks.length > 1"
                    class="text-gray-400 h-5 float-right cursor-pointer"
                    @click="removePerk(index)"
                  />
                </div>
              </template>
            </InputText>
            <InputText
              class="mb-2"
              :placeholder="$t('enter_text', { number: index + 1 })"
              v-model="perk.description"
              v-bind:errors="[is_perks ? '' : ' ']"
            />
            <TheButton v-if="perk.icon_name" bg="outline" class="mb-4">
              <component
                :is="getIcon(perk.icon_name)"
                class="h-4 text-gray-700 mr-2"
              />
              {{ perk.icon_name }}
            </TheButton>
            <TheButton bg="secondary" @click="selectIconForPerk(perk)">
              <PlusIcon class="h-4 mr-1" />
              {{ $t("add_icon") }}
            </TheButton>
          </div>
          <p class="text-red-600 text-sm" v-if="!is_perks">
            {{ $t("enter_points") }}
          </p>
          <p class="text-red-600 text-sm" v-if="!is_perk_icon && is_perks">
            {{ $t("enter_icon") }}
          </p>
          <TheButton bg="secondary" @click="addPerk" class="mt-5">
            <PlusIcon class="h-4" />
            {{ $t("add_point") }}
          </TheButton>
        </TheCard>
        <TheCard>
          <TheCardHeader>
            {{ $t("course_duration") }}
          </TheCardHeader>
          <InputText
            v-model="form.overview.duration_hours"
            :errors="[is_course_duration ? '' : $t('enter_duration')]"
          />
        </TheCard>
        <TheCard>
          <TheCardHeader>
            {{ $t("course_level") }}
          </TheCardHeader>
          <InputText
            v-model="form.overview.skill_level"
            :errors="[is_course_level ? '' : $t('enter_level')]"
          />
        </TheCard>
        <TheCard class="mb-20">
          <TheCardHeader>
            {{ $t("certificate_by_the_end") }}
          </TheCardHeader>
          <div class="w-full flex flex-wrap space-y-2 mt-2">
            <div
              class="cursor-pointer w-full p-2 inline-flex mb-2 rounded-l place-items-center"
            >
              <input
                type="checkbox"
                v-model="form.overview.is_certificate_given"
                :id="`is_certificate_given_yes`"
              />
              <label class="ml-2" :for="`is_certificate_given_yes`">
                {{ $t("have") }}
              </label>
            </div>
          </div>
        </TheCard>
      </template>
      <template #secondary>
        <TheCard class="px-3">
          <TheCardHeader>{{ $t("course_logo") }}</TheCardHeader>
          <FileDropzone v-model="form.logo" :key="form.logo ?? 'no_logo'" />
          <p class="text-red-600 text-sm mt-1" v-if="!is_course_logo">
            {{ $t("enter_logo") }}
          </p>
        </TheCard>

        <TheCard
          class="px-3"
          v-for="inst in form.instructors"
          :key="inst.id || inst._key"
        >
          <TheCardHeader>{{ $t("course_instructor") }}</TheCardHeader>
          <UsersSelector
            v-model="inst.id"
            optionComponentName="UserWithAvatarSelectorOption"
            :options="instructors"
            placeholder="select_instructor"
            :class="[
              is_instructor_id
                ? ''
                : 'border border-red-600 rounded-md text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500',
            ]"
          />
          <p class="text-red-600 text-sm mt-2" v-if="!is_instructor_id">
            {{ $t("enter_instructor") }}
          </p>
          <textarea
            :key="`instructor_description+${inst.id}`"
            class="py-3 px-4 rounded-md overflow-hidden text-base leading-6 font-normal border-gray-300 mt-4 w-full"
            :placeholder="$t('about_instructor')"
            v-model="inst.instructor_description"
          />
          <TheButton
            bg="outline"
            class="mb-4 text-center"
            @click="deleteInstructor($event, inst.id)"
          >
            {{ $t("delete") }}
          </TheButton>
        </TheCard>
        <TheButton bg="secondary" @click="addInstructor">
          <PlusIcon class="h-4 mr-2" />
          {{ $t("add_instructor") }}
        </TheButton>
        <p class="text-red-600 text-sm mt-2 ml-3" v-if="!is_instructor_exist">
          {{ $t("enter_instructor") }}
        </p>
        <TheCard class="mt-4">
          <TheCardHeader>{{ $t("groups") }}</TheCardHeader>
          <InputText
            :placeholder="$t('input_groups_amount')"
            v-model="form.groups_amount"
            :errors="[is_groups_amount ? '' : $t('input_groups_amount')]"
          >
            <template #label>
              <label class="text-sm leading-5 font-medium">
                {{ $t("groups_amount") }}
              </label>
            </template>
          </InputText>
          <InputText
            :placeholder="$t('input_students_amount')"
            v-model="form.max_students_in_group"
            :errors="[
              is_max_students_in_group ? '' : $t('input_students_amount'),
            ]"
          >
            <template #label>
              <label class="text-sm leading-5 font-medium">
                {{ $t("max_students_in_group_short") }}
              </label>
            </template>
          </InputText>
        </TheCard>
      </template>
      <template #actions>
        <div
          class="flex p-3 flex-row place-content-end absolute bottom-0 right-0 bg-white w-full"
        >
          <TheButton bg="outline" class="mr-3" @click.prevent="cancel">{{
            $t("cancel")
          }}</TheButton>
          <TheButton
            v-if="active_user_has_permission('can_edit_course')"
            bg="secondary"
            class="mr-3"
            @click.prevent="handleModalOpen"
            >{{ $t("delete") }}</TheButton
          >
          <TheButton
            v-if="active_user_has_permission('can_edit_course')"
            bg="primary"
            @click="submit"
            >{{ $t(isUpdating ? "update" : "create") }}</TheButton
          >
        </div>
      </template>
    </FormLayout>
    <TheModal :show="modalOpen" @close="handleModalClose" class="z-50">
      <div class="p-6">
        <div class="flex gap-4 mb-4">
          <div
            class="w-10 h-10 grid place-items-center bg-red-100 rounded-full"
          >
            <ExclamationIcon class="text-red-600 w-6 h-6" />
          </div>
          <div class="flex flex-col gap-2">
            <div class="text-lg text-gray-900 leading-6 font-medium">
              {{ $t("delete_course") }}
            </div>
            <div class="text-sm text-gray-500 leading-5">
              {{ $t("delete_course_text") }}
            </div>
          </div>
        </div>
        <div class="flex justify-end gap-3">
          <TheButton bg="outline" class="mr-3" @click="handleModalClose">{{
            $t("cancel")
          }}</TheButton>
          <TheButton bg="danger" class="mr-3" @click="deleteCourse">{{
            $t("delete")
          }}</TheButton>
        </div>
      </div>
    </TheModal>
  </div>
</template>
<script>
import TheCard from "@/components/TheCard/TheCard";
import InputText from "@/components/InputText";
import TheQuillEditorInput from "@/components/TheQuillEditorInput";
import FileDropzone from "@/components/FileDropzone";
import TheButton from "@/components/Button/Button";
import FormLayout from "@/layouts/FormLayout";
import { ExclamationIcon, PlusIcon } from "@heroicons/vue/outline";
import TheCardHeader from "@/components/TheCard/TheCardHeader";
import UsersSelector from "@/components/Selectors/UsersSelect";
import CoursesFormMixin from "@/views/courses/Form/CoursesFormMixin";
import SpecializationsSelector from "@/components/Selectors/SpecializationsSelector";
import TheIconSelectorModal from "@/components/TheIconSelector/TheIconSelectorModal";
import TheModal from "@/components/TheModal/TheModal";
import { XIcon } from "@heroicons/vue/outline";
import Datepicker from "vue3-datepicker";
import { CalendarIcon, TrashIcon } from "@heroicons/vue/outline";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import Da from "vue-tailwind/dist/l10n/da";

export default {
  computed: {
    Da() {
      return Da;
    },
    startOfOctober() {
      const today = new Date();
      return new Date(today.getFullYear(), 9, 1); // Month is 0-indexed, so 9 is October
    },
  },

  components: {
    TheIconSelectorModal,
    SpecializationsSelector,
    UsersSelector,
    TheCardHeader,
    FormLayout,
    TheButton,
    FileDropzone,
    TheQuillEditorInput,
    InputText,
    TheCard,
    PlusIcon,
    TheModal,
    XIcon,
    Datepicker,
    CalendarIcon,
    TrashIcon,
    ExclamationIcon,
    Breadcrumbs,
  },
  name: "TheCourseForm",
  mixins: [CoursesFormMixin],
};
</script>

<style lang="postcss"></style>
