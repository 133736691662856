<template>
  <div class="card-form">
    <TheCard class="flex items-center justify-center flex-col adaptive-card">
      <div style="width: 90%" class="mb-4 gap-4 grid">
        <p class="text-3xl font-bold text-center form-title">
          Начни обучение бесплатно
        </p>
      </div>
      <div class="w-full grid gap-4">
        <div class="flex flex-col w-full">
          <label class="text-gray-500 text-left" for="name"> Имя</label>
          <InputText
            :placeholder="$t('how_to_call_you')"
            v-model="personName"
            is-required
          />
          <!-- <template #label>
                <InputLabel class="mb-2">{{ $t('name') }}</InputLabel>
              </template> -->
          <!-- </InputText> -->
        </div>
        <div class="flex flex-col">
          <label class="text-gray-500 text-left" for="name">
            Номер телефона</label
          >
          <InputText
            :placeholder="$t('contact_phone')"
            v-model="phone"
            is-phone-number
            is-required
          />
        </div>
        <div class="flex flex-col">
          <label class="text-gray-500 text-left" for="name"> E-mail</label>
          <InputText
            type="email"
            :placeholder="$t('your_email_optional')"
            v-model="email"
          />
        </div>
        <p class="text-gray-500" v-if="isForm">
          {{ $t("form_request_done") }}
        </p>
      </div>
      <div>
        <TheButton bg="primary" class="w-full mt-12" @click="scrollToForm"
          >Начать обучение
        </TheButton>
        <div class="w-full mt-4 text-gray-500">
          Отправляя заявку, вы даёте согласие на обработку своих персональных
          данных в соответствии
          <a style="color: #786ce6; cursor: pointer"
            >с политикой конфиденциальности</a
          >
        </div>
      </div>
    </TheCard>
  </div>
</template>

<script>
import TheCard from "@/components/TheCard/TheCard";
import InputText from "@/components/InputText";
import TheButton from "@/components/Button/Button";
import { mapActions } from "vuex";
import { SEND_LANDING_FORM } from "@/store/resources";
import { SET_FORMDATA } from "@/store/resources";

export default {
  name: "TheLandingForm",
  components: { TheButton, InputText, TheCard },
  props: {
    startCourseFormData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      personName: "",
      phone: "",
      email: "",
    };
  },
  methods: {
    ...mapActions([SEND_LANDING_FORM, SET_FORMDATA]),

    scrollToForm() {
      this.$emit("form-data-updated", {
        personName: this.personName,
        phone: this.phone,
        email: this.email,
      });
      const formElement = document.getElementById("landing-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.card-form {
  max-height: 670px;
  margin-left: 40px;
}
.adaptive-card {
  background-color: #eae5ff;
  border-radius: 30px;
  padding: 78px 40px;
  height: 670px;
}

@media (max-width: 650px) {
  .card-form {
    margin-left: 0px;
  }
  .form-title {
    font-size: 20px;
    font-weight: 600;
  }
  .adaptive-card {
    padding: 32px 20px;
    height: 100%;
  }
}
</style>
