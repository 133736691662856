export const AddToCartMixin = {
  data() {
    return {
      openModal: false,
    };
  },
  methods: {
    openCartModal() {
      this.openModal = true;
      this.addToCart(this.courseInfo);
    },
    closeCartModal() {
      this.openModal = false;
    },
  },
};
