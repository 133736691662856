<template>
  <TheLandingHeader />
  <div class="py-16 px-20 bg-gray-50">
    <h1 class="text-4xl leading-10 font-extrabold text-gray-900">
      {{ $t("catalog_courses") }}
    </h1>
    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr mt-10 mb-10"
    >
      <LandingCourseCard
        v-for="course in courses"
        :name="course.name"
        :id="course.id"
        :specializations="course.specializations"
        :key="course.id"
        :logo="course.logo"
        :start_date="course.start_date"
        :end_date="course.end_date"
        :instructors="course.instructors"
        destination="overview"
      />
    </div>
    <div class="flex justify-center">
      <Button bg="secondary">{{ $t("show_more") }}</Button>
    </div>
  </div>
  <TheNewLandingFooter />
</template>

<script>
import TheLandingHeader from "@/views/core/Landing/TheLandingHeader";
import { mapActions } from "vuex";
import { GET_PUBLISHED_COURSES } from "@/store/courses";
import Button from "@/components/Button/Button";
import LandingCourseCard from "@/views/core/Landing/LandingCourseCard";
import TheNewLandingFooter from "@/views/core/Landing/NewLanding/TheNewLandingFooter.vue";

export default {
  components: {
    TheNewLandingFooter,
    TheLandingHeader,
    LandingCourseCard,
    Button,
  },
  methods: {
    ...mapActions([GET_PUBLISHED_COURSES]),
  },
  data() {
    return {
      courses: [],
    };
  },
  async mounted() {
    this.courses = await this[GET_PUBLISHED_COURSES]();
  },
};
</script>
