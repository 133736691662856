<script setup></script>

<template>
  <div class="our-partners flex flex-col gap-10">
    <h2>Наши партнеры</h2>
    <div class="our-partners-box flex">
      <img src="../../../../assets/images/landing/MNU-1.svg" />

      <img src="../../../../assets/images/landing/oraclus.svg" />
      <img src="../../../../assets/images/landing/aitu-logo-white-2.svg" />
      <img src="../../../../assets/images/landing/astana-hub.svg" />
      <img src="../../../../assets/images/landing/alpha.svg" />
      <img src="../../../../assets/images/landing/zerdeli-vertical-logo.svg" />
      <img src="../../../../assets/images/landing/dvorets-shkolnikov.svg" />
    </div>
  </div>
</template>

<style scoped>
h2 {
  color: #1f2937;
  font-weight: 800;
  font-size: 40px;
}

.audience-box-1 li {
  color: #6b7280;
  font-size: 18px;
  font-weight: 400;
}

.audience-box-2 li {
  font-size: 18px;
  font-weight: 400;
}
.our-partners {
  padding: 40px 80px 64px 80px;
  background: #20222e;
}
.our-partners h2 {
  color: white;
}
.our-partners-box {
  gap: 64px;
}

.platfotm h2 {
  font-weight: 700;
}

.platform-grid img {
  padding-left: 52px;
}

.platform-box-2 li {
  font-size: 18px;
  font-weight: 400;
  color: #6b7280;
}

h3 {
  font-size: 32px;
  font-weight: 700;
  color: white;
}

.standards li {
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.csr h2 {
  color: white;
}

.about-us-img img {
  flex: 1;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 650px) {
  h2 {
    font-weight: 700;
    font-size: 28px;
  }

  .audience-box-1 li {
    font-size: 16px;
  }
  .audience-box-2 li {
    font-size: 16px;
  }
  .our-partners {
    padding: 32px 16px;
  }
  .our-partners-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }

  .bullet-title li {
    font-size: 16px;
    font-weight: 400;
  }

  .standards li {
    font-size: 16px;
  }
}
</style>
