<template>
  <form @submit.prevent="submit" class="w-full">
    <TheFormHeader header="to_login">
      <!--      <template #router>-->
      <!--        <TheRouterLink :to="{name:'Registration'}">-->
      <!--          {{$t("register_myself")}}-->
      <!--        </TheRouterLink>-->
      <!--      </template>-->
    </TheFormHeader>
    <div class="mt-10">
      <InputText
        v-model.lazy="email"
        type="email"
        id="email_input"
        :errors="errors"
      >
        <template #label>
          <InputLabel>{{ $t("email") }}</InputLabel>
        </template>
      </InputText>
    </div>
    <div class="mt-3">
      <InputText
        v-model.lazy="password"
        :type="show_password ? 'text' : 'password'"
      >
        <template #label>
          <InputLabel>{{ $t("password") }}</InputLabel>
        </template>
        <template #rightIcon>
          <EyeOffIcon
            class="h-5 w-5 text-gray-600"
            v-if="show_password"
            @click="show_password = false"
          />
          <EyeIcon
            class="h-5 w-5 text-gray-600"
            v-else
            @click="show_password = true"
          />
        </template>
      </InputText>
    </div>
    <div class="mt-6 flex">
      <InputCheckbox
        htmlId="remember_me_checkbox"
        class="flex-1"
        v-model="remember_me"
      >
        <template #label>
          <InputLabel for="remember_me_checkbox">
            {{ $t("remember_me") }}
          </InputLabel>
        </template>
      </InputCheckbox>
      <!--      <TheRouterLink :to="{name:'ForgotPassword'}">-->
      <!--        {{$t('forgot_password')}}?-->
      <!--      </TheRouterLink>-->
    </div>
    <TheButton type="submit" bg="primary" class="w-full mt-10">{{
      $t("login")
    }}</TheButton>
    <div class="flex justify-center items-center mt-3 text-link">
      <router-link :to="{ name: 'TheLandingPage' }">
        На главную страницу</router-link
      >
    </div>
  </form>
</template>
<script>
import TheLoginFormMixin from "./TheLoginFormMixin.js";
import InputText from "@/components/InputText";
import InputLabel from "@/components/InputLabel";
import TheButton from "@/components/Button/Button";
import TheFormHeader from "@/views/auth/Common/TheFormHeader/TheFormHeader";
import InputCheckbox from "@/components/InputCheckbox";
// import TheRouterLink from "@/components/TheRouterLink";
import { EyeIcon, EyeOffIcon } from "@heroicons/vue/solid";

export default {
  components: {
    InputCheckbox,
    TheFormHeader,
    TheButton,
    InputLabel,
    InputText,
    EyeIcon,
    EyeOffIcon,
  },
  mixins: [TheLoginFormMixin],
};
</script>

<style scoped>
.text-link {
  color: #786ce6;
  text-decoration: underline;
}
</style>
