<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7104 19.2904L17.0004 15.6104C18.4405 13.8148 19.1379 11.5357 18.9492 9.24169C18.7605 6.94769 17.7001 4.81318 15.9859 3.27704C14.2718 1.7409 12.0342 0.919903 9.73332 0.982863C7.43243 1.04582 5.24311 1.98795 3.61553 3.61553C1.98795 5.24311 1.04582 7.43243 0.982863 9.73332C0.919903 12.0342 1.7409 14.2718 3.27704 15.9859C4.81318 17.7001 6.94769 18.7605 9.24169 18.9492C11.5357 19.1379 13.8148 18.4405 15.6104 17.0004L19.2904 20.6804C19.3834 20.7741 19.494 20.8485 19.6158 20.8993C19.7377 20.9501 19.8684 20.9762 20.0004 20.9762C20.1324 20.9762 20.2631 20.9501 20.385 20.8993C20.5068 20.8485 20.6174 20.7741 20.7104 20.6804C20.8906 20.4939 20.9914 20.2447 20.9914 19.9854C20.9914 19.7261 20.8906 19.4769 20.7104 19.2904ZM10.0004 17.0004C8.61592 17.0004 7.26255 16.5899 6.1114 15.8207C4.96026 15.0515 4.06305 13.9583 3.53324 12.6792C3.00342 11.4001 2.8648 9.99263 3.1349 8.63476C3.40499 7.27689 4.07168 6.02961 5.05065 5.05065C6.02961 4.07168 7.27689 3.40499 8.63476 3.1349C9.99263 2.8648 11.4001 3.00342 12.6792 3.53324C13.9583 4.06305 15.0515 4.96026 15.8207 6.1114C16.5899 7.26255 17.0004 8.61592 17.0004 10.0004C17.0004 11.8569 16.2629 13.6374 14.9501 14.9501C13.6374 16.2629 11.8569 17.0004 10.0004 17.0004Z"
      fill="#6B7280"
    />
  </svg>
</template>

<script>
import IconMixin from "@/assets/icons/IconMixin";

export default {
  mixins: [IconMixin],
};
</script>
