<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
        fill-rule="evenodd" clip-rule="evenodd"
        :fill="fill"
        d="M4 0C1.79086 0 0 1.79086 0 4V16C0 18.2091 1.79086 20 4 20H16C18.2091 20 20 18.2091 20 16V4C20 1.79086 18.2091 0 16 0H4ZM6 5V6.32244H9.17188V15.1818H10.7031V6.32244H13.88V5H6Z"
    />
  </svg>
</template>
<script>
  import IconMixin from "@/assets/icons/IconMixin";

  export default {
    mixins:[IconMixin]
  }
</script>