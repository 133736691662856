<script>
import CourseTeachersButton from "@/views/core/Landing/Courses/CourseTeachersButton.vue";
import { ArrowNarrowRightIcon } from "@heroicons/vue/outline";
import StudentWorkItem from "@/views/core/Landing/Courses/StudentWorks/StudentWorkItem.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";

export default {
  components: {
    SwiperSlide,
    Swiper,
    StudentWorkItem,
    ArrowNarrowRightIcon,
    CourseTeachersButton,
  },
  data() {
    return {
      hoverLeft: false,
      hoverRight: false,
      studentWorks: [
        { name: "a" },
        { name: "b" },
        { name: "bb" },
        { name: "bb2" },
      ],

      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },

        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
      },
      modules: [Navigation, Pagination],
      startIndex: 0,
    };
  },
  computed: {
    computedSlides() {
      return this.studentWorks.map((student) => {
        return {
          component: StudentWorkItem,
          props: {
            studentWork: student,
          },
        };
      });
    },
    visibleSlides() {
      if (this.computedSlides.length === 1) {
        return this.computedSlides;
      }

      const endIndex = this.startIndex + this.computedSlides.length;

      const slides = this.computedSlides.concat(this.computedSlides);

      const data = slides.slice(this.startIndex, endIndex);

      return data;
    },
  },
  methods: {
    next() {
      if (this.startIndex + 2 < this.computedSlides.length + 2) {
        this.startIndex++;
      } else {
        this.startIndex = 0;
      }
    },
    previous() {
      if (this.startIndex > 0) {
        this.startIndex--;
      } else {
        this.startIndex = this.computedSlides.length;
      }
    },
  },
};
</script>

<template>
  <div class="students-works flex flex-col gap-12">
    <div class="flex justify-between">
      <div class="students-works-title">Работы наших учеников</div>

      <div
        class="flex gap-4 adaptive-buttons-mobile"
        v-if="visibleSlides.length > 1"
      >
        <div @click="previous" class="cursor-pointer">
          <CourseTeachersButton>
            <svg
              :class="{ hover: hoverLeft }"
              @mouseover="hoverLeft = true"
              @mouseleave="hoverLeft = false"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                :fill="hoverLeft ? 'white' : '#7A69EE'"
                transform="rotate(180, 14, 14)"
              />
            </svg>
          </CourseTeachersButton>
        </div>
        <div @click="next" class="cursor-pointer">
          <CourseTeachersButton>
            <svg
              :class="{ hover: hoverRight }"
              @mouseover="hoverRight = true"
              @mouseleave="hoverRight = false"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.2834 8.98376C15.1754 9.09053 15.0895 9.2177 15.031 9.35789C14.9724 9.49808 14.9423 9.64849 14.9423 9.80042C14.9423 9.95235 14.9724 10.1028 15.031 10.243C15.0895 10.3831 15.1754 10.5103 15.2834 10.6171L17.5001 12.8338H6.76676C6.1251 12.8338 5.6001 13.3588 5.6001 14.0004C5.6001 14.6421 6.1251 15.1671 6.76676 15.1671H17.5001L15.2834 17.3838C15.1754 17.4905 15.0895 17.6177 15.031 17.7579C14.9724 17.8981 14.9423 18.0485 14.9423 18.2004C14.9423 18.3524 14.9724 18.5028 15.031 18.643C15.0895 18.7831 15.1754 18.9103 15.2834 19.0171C15.7384 19.4721 16.4618 19.4721 16.9168 19.0171L21.1051 14.8288C21.2133 14.7208 21.2991 14.5926 21.3576 14.4515C21.4161 14.3103 21.4463 14.1591 21.4463 14.0063C21.4463 13.8535 21.4161 13.7022 21.3576 13.561C21.2991 13.4199 21.2133 13.2917 21.1051 13.1838L16.9168 8.98376C16.81 8.87568 16.6828 8.78987 16.5426 8.7313C16.4024 8.67274 16.252 8.64258 16.1001 8.64258C15.9482 8.64258 15.7978 8.67274 15.6576 8.7313C15.5174 8.78987 15.3902 8.87568 15.2834 8.98376Z"
                :fill="hoverRight ? 'white' : '#7A69EE'"
              />
            </svg>
          </CourseTeachersButton>
        </div>
      </div>
    </div>
    <div class="carousel-container">
      <transition-group
        :class="{
          carousel: visibleSlides.length > 1,
          'no-carousel': visibleSlides.length == 1,
        }"
        tag="div"
      >
        <component
          v-for="(slide, i) in visibleSlides"
          :key="i"
          :is="slide.component"
          :student-work="slide.props.studentWork"
        ></component>
      </transition-group>
    </div>

    <div class="carousel-container2">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="16"
        :pagination="pagination"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        ><swiper-slide v-for="(slide, i) in computedSlides" :key="`slide-${i}`">
          <component
            :is="slide.component"
            :student-work="slide.props.studentWork"
          ></component
        ></swiper-slide>
      </swiper>
    </div>
    <div class="flex justify-end">
      <router-link :to="{ name: 'AllStudentWorks' }"
        ><button class="works__button flex gap-2">
          Посмотреть все работы
          <ArrowNarrowRightIcon class="h-4 w-4 mt-1" /></button
      ></router-link>
    </div>
  </div>
</template>

<style scoped>
.students-works {
  padding: 40px 100px;
}
.students-works-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  color: #1f2937;
}

@media (max-width: 650px) {
  .students-works {
    padding: 24px 16px;
    gap: 40px;
  }
  .adaptive-buttons-mobile {
    display: none;
  }
  .students-works-box {
    flex-direction: column;
    gap: 16px;
  }
  .student-work-box {
    width: 100%;
  }
}

.carousel-container {
  display: flex;
  flex-wrap: nowrap;
  padding-right: 60px;
}

.carousel {
  gap: 24px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  width: 100%;
}
.no-carousel {
  flex-basis: 50%;
}

.carousel > * {
  flex: 0 0 25%;
}

.works__button {
  padding: 16px 24px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  border: 2px solid #6759ea;
  color: #6759ea;
}

:deep .swiper-pagination-bullet {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e4d7ff;
}

:deep .swiper-pagination-bullet-active {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: white;
}
.carousel-container2 {
  display: none;
}

button:hover {
  background: #5b4ccc;
  color: white;
}

@media (max-width: 650px) {
  .carousel-container {
    display: none;
  }
  .carousel-container2 {
    display: flex;
  }

  .adaptive-buttons-mobile {
    display: none;
  }
  :deep .swiper-pagination-bullet {
    margin-top: 16px;
    width: 48px;
    height: 6px;
    border-radius: 4px;
    background: #e5e7eb;
  }

  :deep .swiper-pagination-bullet-active {
    margin-top: 16px;
    width: 48px;
    height: 6px;
    border-radius: 4px;
    background: #5b4ccc;
  }
}
</style>
