<template>
  <div class="referral">
    <div class="flex gap-8 items-center referral-block">
      <div class="flex flex-col gap-8">
        <div class="font-size-40px font-bold text-gray-800 referral-subtitle">
          Приводите друзей учиться и получайте бонусы
        </div>
        <div class="flex flex-col gap-4">
          <div class="flex gap-3 items-center">
            <div><TickCircleIcon /></div>
            <div class="text-lg font-normal text-gray-800">
              Поделитесь с другом скидкой 10% на курс
            </div>
          </div>
          <div class="flex gap-3 items-center">
            <div><TickCircleIcon /></div>
            <div class="text-lg font-normal text-gray-800">
              Получите мини-курс в подарок 🎁
            </div>
          </div>
        </div>
      </div>
      <div v-if="col">
        <img
          src="../../../../assets/images/landing/referral.png"
          alt="Referral Animal"
        />
      </div>
    </div>

    <div class="referral__divider--wide w-full" v-if="!col"></div>
    <div class="flex gap-10 referral-block2" v-bind:class="{ 'flex-col': col }">
      <div class="font-bold font-size-40px referral-subtitle">
        Как работает реферальная программа
      </div>

      <div class="flex flex-col gap-10">
        <div class="flex gap-4">
          <div
            class="font-bold flex items-center justify-center referral__number"
          >
            1
          </div>
          <div class="flex flex-col gap-2">
            <div class="font-medium text-2xl text-gray-900 referral-text">
              Для участия в программе вы должны приобрести любой курс
            </div>
            <div class="font-normal text-lg text-gray-500 referral-text2">
              Выберите и приобретите платный курс из каталога. После покупки в
              личном кабинете у вас появится ваша персональная ссылка, которой
              можно поделиться с друзьями.
            </div>
          </div>
        </div>
        <div class="referral__divider"></div>

        <div class="flex gap-4">
          <div
            class="font-bold flex items-center justify-center referral__number"
          >
            2
          </div>
          <div class="flex flex-col gap-2">
            <div class="font-medium text-2xl text-gray-900 referral-text">
              Ваш друг переходит по ссылке и покупает курс с дополнительной
              скидкой 10% по промокоду
            </div>
            <div class="font-normal text-lg text-gray-500 referral-text2">
              <ul>
                <li>Скидка действует только на первую покупку</li>
                <li>
                  Вашему другу необходимо оставить заявку с использованием
                  вашего промокода по ссылке
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="referral__divider"></div>

        <div class="flex gap-4">
          <div
            class="font-bold flex items-center justify-center referral__number"
          >
            3
          </div>
          <div class="flex flex-col gap-2">
            <div class="font-medium text-2xl text-gray-900 referral-text">
              За каждого приведённого друга вы получаете мини-курс в подарок 🎁
            </div>
            <div class="font-normal text-lg text-gray-500 referral-text2">
              В течение 3 дней после того, как друг оплатит курс по вашей ссылке
              вы получите список мини-курсов и сможете начать изучать материалы
              бесплатно
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TickCircleIcon from "../../../../assets/icons/TickCircleIcon.vue";

export default {
  components: {
    TickCircleIcon,
  },
  props: ["col"],
};
</script>

<style scoped>
.referral {
  padding: 64px 100px;
  display: flex;
  flex-direction: column;
  gap: 90px;
  background: #f4f5fa;
}
.referral__number {
  font-size: 22px;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  color: white;
  background: #7a7dd7;
  border-radius: 100px;
}
.referral__divider--wide {
  height: 2px;
  background: #7a7dd7;
}
.referral__divider {
  height: 1px;
  background: #7a7dd7;
}

.font-size-40px {
  font-size: 40px;
}
.text-gray-50 {
  color: #f9fafb;
}

@media (max-width: 650px) {
  .referral {
    padding: 24px 16px;
  }
  .referral-subtitle {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }

  .referral-block {
    flex-direction: column-reverse;
  }
  .referral-block2 {
    flex-direction: column;
  }
  .referral-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }

  .referral-text2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
