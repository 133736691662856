<template>
  <div>
    <ThePageHeader />
    <TheCourseSidebar />
    <main class="flex-1 ml-14 sm:ml-64 bg-gray-100">
      <div className="min-h-screen	">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>
<script>
import ThePageHeader from "@/components/ThePageHeader/ThePageHeader";
import TheCourseSidebar from "@/components/TheSidebar/TheCourseSidebar";

export default {
  components: { TheCourseSidebar, ThePageHeader },
};
</script>
