import { SET_LOADING } from "@/store/core";
export const CREATE_ATTENDANCE_RECORD = "CREATE_ATTENDANCE_RECORD";
export const GET_ATTENDANCE_RECORDS = "GET_ATTENDANCE_RECORDS";

export const GET_STUDENT_ATTENDANCE_RECORDS = "GET_STUDENT_ATTENDANCE_RECORDS";
export const SET_ATTENDANCE_RECORDS = "SET_ATTENDANCE_RECORDS";
export const DELETE_ATTENDANCE_RECORD = "DELETE_ATTENDANCE_RECORD";
export const DOWNLOAD_ATTENDANCE = "DOWNLOAD_ATTENDANCE";
export const GET_ATTENDANCE_COURSE_GROUPS = "GET_ATTENDANCE_COURSE_GROUPS";
export const GET_MY_ATTENDANCE = "GET_MY_ATTENDANCE";
export const GET_ATTENDANCE_STATISTICS = "GET_ATTENDANCE_STATISTICS";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {
    attendance: [],
  };
  const actions = {
    [CREATE_ATTENDANCE_RECORD]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.attendance.create_attendance_record(params);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_ATTENDANCE_RECORDS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.attendance.get_attendance_records(params);
      commit(SET_LOADING, false);
      return data;
    },
    [DOWNLOAD_ATTENDANCE]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.attendance.download_attendance_records(
        params
      );
      commit(SET_LOADING, false);
      return data;
    },
    [GET_STUDENT_ATTENDANCE_RECORDS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.attendance.get_student_attendance_records(
        params
      );
      commit(SET_LOADING, false);
      return data;
    },
    [GET_ATTENDANCE_COURSE_GROUPS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.attendance.get_course_attendance_groups(
        params
      );
      commit(SET_LOADING, false);
      return data;
    },
    [GET_MY_ATTENDANCE]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.attendance.get_my_attendance(params);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_ATTENDANCE_STATISTICS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.attendance.get_attendance_statistics(params);
      commit(SET_LOADING, false);
      return data;
    },
  };
  const mutations = {
    [SET_ATTENDANCE_RECORDS]: (state, payload) => {
      state.attendance = payload;
    },
  };
  const getters = {
    attendance: (state) => state.attendance,
  };
  return {
    actions,
    state,
    mutations,
    getters,
  };
};
export default (app) => createStore(app);
