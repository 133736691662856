const baseUrl = "/api/v1/news";
export default $axios => ({
	get_news_list() {
		return $axios.get(`${baseUrl}/public`);
	},
	get_news_publication(id) {
		return $axios.get(`${baseUrl}/public/${id}`);
	},
	create_publication(formData) {
		return $axios.post(`${baseUrl}/`, formData);
	},
	update_publication(id, formData) {
		return $axios.put(`${baseUrl}/${id}`, formData);
	},
	delete_publication(id) {
		return $axios.delete(`${baseUrl}/${id}`);
	},
	create_publication_block(id, formData) {
		return $axios.post(`${baseUrl}/${id}/content_block/`, formData);
	},
	delete_publication_block(id) {
		return $axios.delete(`${baseUrl}/content_block/${id}`);
	},
})