<script>
import { defineComponent, nextTick } from "vue";
import CoursesSelector from "@/components/Selectors/CoursesSelector.vue";
import InputCheckbox from "@/components/InputCheckbox.vue";
// import ToggleSwitch from "@/views/certificates/ToggleSwitch.vue";
import { GET_COURSE_GROUPS } from "@/store/users";
import { GET_COURSE, GET_COURSES_SELECT } from "@/store/courses";
import GroupSelector from "@/components/Selectors/GroupSelector.vue";
import CertificateGenMixin from "@/views/certificates/CertificateGenMixin";
import CertificateSelector from "@/components/Selectors/CertificateSelector.vue";
import InputLabel from "@/components/InputLabel.vue";
import PurpleAdminButton from "@/views/certificates/PurpleAdminButton.vue";

export default defineComponent({
  name: "CertificateGen",
  components: {
    PurpleAdminButton,
    InputLabel,
    CertificateSelector,
    GroupSelector,
    InputCheckbox,
    CoursesSelector,
    // ToggleSwitch,
  },
  mixins: [CertificateGenMixin],
  async created() {
    this.courses = await this.$store.dispatch(GET_COURSES_SELECT);
  },
  mounted() {
    nextTick(() => {
      window.addEventListener("scroll", this.checkIfNearEnd);
    });
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.checkIfNearEnd);
  },
  data() {
    return {
      search: "",
      course_id: null,
      group_id: null,
      certificate_type_id: null,
      groups: [],
      course: {},
      breadcrumbs: [],
      role: "student",
      select_all: false,
      courses: [],
      course_name: "",
    };
  },
  methods: {
    checkIfNearEnd() {
      const sentinel = this.$refs.sentinelCert;
      if (sentinel) {
        if (sentinel instanceof HTMLElement) {
          const sentinelRect = sentinel.getBoundingClientRect();
          const isNearEnd = sentinelRect.top <= window.innerHeight;

          if (isNearEnd) {
            this.extendUsersList();
          }
        } else {
          console.error("Sentinel is not an HTMLElement", sentinel);
        }
      } else {
        console.error("Sentinel ref is not defined");
      }
    },
  },
  watch: {
    course_id(newVal) {
      if (newVal !== null) {
        const data = this.courses.find((course) => course.id === newVal);
        if (data) {
          this.course_name = data.name;
        }
      } else {
        this.course_name = "";
      }
    },
    select_all(newVal) {
      if (this.students && this.students.length > 0) {
        this.students = this.students.map((student) => ({
          ...student,
          selected: newVal,
        }));
      }
    },
    students(newVal) {
      if (newVal.length === 0) {
        this.search = "";
      }
    },

    async id(newVal) {
      if (newVal != null) {
        this.groups = await this.$store.dispatch(GET_COURSE_GROUPS, {
          course: newVal,
        });
        this.course = await this.$store.dispatch(GET_COURSE, newVal);
      }
    },
    async group(newVal) {
      if (newVal != null) {
        await this.getUsers();
        this.course = await this.$store.dispatch(GET_COURSE, newVal);
      }
    },
  },
});
</script>

<template>
  <div class="flex flex-col gap-6 text-gray-900 cert-block">
    <h1>Сгенерировать сертификаты</h1>
    <div class="cert-course-name flex flex-col gap-4">
      <div class="cert-course-title">Наименование курса</div>
      <div class="flex flex-col gap-2">
        <CoursesSelector
          label="name"
          v-model="course_id"
          :placeholder="$t('select_course')"
          class="bg-white text-sm leading-5 font-medium mt-2"
        />
        <div class="error-msg">{{ course_error }}</div>
      </div>
      <div class="flex flex-col gap-2" v-if="course_id">
        <GroupSelector
          v-if="course_id"
          label="name"
          :course_id="course_id"
          v-model="group_id"
          :placeholder="$t('select_group')"
          class="text-sm leading-5 font-medium border-none"
        />
        <div class="error-msg">{{ group_error }}</div>
      </div>
      <div class="flex flex-col gap-2">
        <CertificateSelector
          label="name"
          v-model="certificate_type_id"
          :placeholder="$t('select_certificate_type')"
          class="text-sm leading-5 font-medium border-none"
        />
        <div class="error-msg">{{ certificate_error }}</div>
      </div>
      <div class="flex gap-4">
        <div class="flex-1">
          <input-label>Duration </input-label>
          <input class="duration" v-model="duration" type="number" required />
          <div class="error-msg">{{ duration_error }}</div>
        </div>
        <div class="flex-1">
          <input-label>Duration unit </input-label>
          <input
            class="duration"
            v-model="duration_unit"
            type="text"
            required
          />
          <div class="error-msg">{{ duration_unit_error }}</div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div class="error-msg">{{ students_error }}</div>

      <div
        class="cert-course-students flex flex-col gap-6"
        v-if="students.length"
      >
        <div class="flex justify-between items-center">
          <div class="cert-course-title">Ученики</div>

          <div>
            <div class="search-text flex gap-2 items-center">
              <div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4ZM2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 9.29583 13.5892 10.4957 12.8907 11.4765L17.7071 16.2929C18.0976 16.6834 18.0976 17.3166 17.7071 17.7071C17.3166 18.0976 16.6834 18.0976 16.2929 17.7071L11.4765 12.8907C10.4957 13.5892 9.29583 14 8 14C4.68629 14 2 11.3137 2 8Z"
                    fill="#9CA3AF"
                  />
                </svg>
              </div>
              <div><input v-model="search" placeholder="Поиск по ФИО" /></div>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="table-header flex items-center">
            <div class="table-checkbox">
              <input-checkbox v-model="select_all" />
            </div>
            <div class="table-name">ИМЯ</div>
            <div class="table-contacts">КОНТАКТЫ</div>
            <!--            <div class="table-top-student">TOP STUDENT</div>-->
          </div>
          <div
            v-for="student in students"
            :key="student.id"
            class="flex text-gray-900"
          >
            <div class="table-checkbox flex items-center">
              <input-checkbox v-model="student.selected" />
            </div>
            <div class="table-name flex gap-4 table-name-text">
              <div>
                <img :src="student.avatar" alt="Avatar" class="avatar" />
              </div>
              <div class="flex flex-col gap-1">
                <div>{{ student.first_name }} {{ student.last_name }}</div>
                <div>
                  <span class="status">{{ student.status }}</span>
                </div>
              </div>
            </div>
            <div class="table-contacts table-contacts-text flex flex-col gap-2">
              <div class="flex gap-4">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5 6.66602L9.0755 11.0497C9.63533 11.4229 10.3647 11.4229 10.9245 11.0497L17.5 6.66602M4.16667 15.8327H15.8333C16.7538 15.8327 17.5 15.0865 17.5 14.166V5.83268C17.5 4.91221 16.7538 4.16602 15.8333 4.16602H4.16667C3.24619 4.16602 2.5 4.91221 2.5 5.83268V14.166C2.5 15.0865 3.24619 15.8327 4.16667 15.8327Z"
                      stroke="#6B7280"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div>{{ student.email }}</div>
              </div>
              <div class="flex gap-4">
                <div>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.5 4.16667C2.5 3.24619 3.24619 2.5 4.16667 2.5H6.89937C7.25806 2.5 7.57651 2.72953 7.68994 3.06981L8.93811 6.81434C9.06926 7.20777 8.89115 7.63776 8.52022 7.82322L6.63917 8.76375C7.55771 10.801 9.19898 12.4423 11.2363 13.3608L12.1768 11.4798C12.3622 11.1088 12.7922 10.9307 13.1857 11.0619L16.9302 12.3101C17.2705 12.4235 17.5 12.7419 17.5 13.1006V15.8333C17.5 16.7538 16.7538 17.5 15.8333 17.5H15C8.09644 17.5 2.5 11.9036 2.5 5V4.16667Z"
                      stroke="#6B7280"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div>{{ student.phone_number }}</div>
              </div>
            </div>
            <!--            <div class="table-top-student flex items-center">-->
            <!--              <ToggleSwitch-->
            <!--                :is-on="student.top_student"-->
            <!--                @update:isOn="(value) => (student.top_student = value)"-->
            <!--              />-->
            <!--            </div>-->
          </div>
          <div ref="sentinelCert" class="sentinel"></div>
        </div>
      </div>
    </div>

    <div class="flex justify-end gap-3 items-center gen-button-box">
      <router-link :to="{ name: 'CertificateTemp' }">
        <div class="cert-gen-cancel-button cursor-pointer">
          Отменить
        </div></router-link
      >

      <PurpleAdminButton @click="generateCet()">
        {{
          this.loading ? "Загрузка..." : "Сгенерировать сертификаты"
        }}</PurpleAdminButton
      >
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}
.cert-course-name,
.cert-course-students {
  padding: 24px;
  border-radius: 12px;
  background: white;
}
.cert-course-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}
.search-text {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 9px 13px;
  height: 38px;
  width: 360px;
}
input {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  width: 280px;
}

input:focus {
  box-shadow: none;
}
.table-wrapper {
  overflow-y: auto;
  max-height: 400px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  background-color: #f5f5f5;
}

th,
td {
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.avatar {
  width: 40px;
  height: auto;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.status {
  display: block;
  color: #065f46;
  font-size: 0.8rem;
  border-radius: 10px;
  padding: 2px 10px;
  background: #d1fae5;
}
.table-checkbox {
  width: 6%;
  padding: 16px 24px;
}
.table-name {
  width: 34%;
  padding: 16px 24px;
}
.table-contacts {
  width: 37%;
  padding: 16px 24px;
}
.table-top-student {
  width: 23%;
  padding: 16px 24px;
}
.table-header {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.05em;
  text-align: left;
  color: #6b7280;
  background: #f9fafb;
}

.table-name-text {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #111827;
}
.table-contacts-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.table-top-student {
  width: 23%;
  padding: 16px 24px;
}

.cert-gen-cancel-button {
  border-radius: 6px;
  padding: 9px 17px;
  color: #374151;
  border: solid 1px #d1d5db;
  background: white;
}
.gen-button-box {
  position: fixed;
  bottom: 0;
  right: 0;
  background: white;
  padding: 24px 48px;
  width: 100%;
  box-shadow: 0px 1px 2px 0px #0000000d;
  border-top: solid 1px #e5e7eb;
}
.cert-block {
  padding-bottom: 120px;
}
.duration {
  border: solid 1px #dcdcdc;
  border-radius: 4px;
  padding: 4px 24px;
  width: 100%;
}

.error-msg {
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #ef4444;
}
</style>
