<template>
  <svg
    width="96"
    height="96"
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M45.2587 6.59189C49.0129 -1.08491 60.386 0.471796 61.9391 8.87504C63.0933 15.1195 69.5278 18.8787 75.5343 16.8175C83.6172 14.0437 90.5584 23.1865 85.7147 30.2267C82.1153 35.4584 84.0071 42.6664 89.7117 45.4561C97.3885 49.2102 95.8318 60.5833 87.4286 62.1365C81.1841 63.2907 77.425 69.7252 79.4862 75.7316C82.2599 83.8145 73.1171 90.7558 66.0769 85.912C60.8453 82.3126 53.6372 84.2044 50.8475 89.9091C47.0934 97.5859 35.7203 96.0292 34.1671 87.6259C33.013 81.3815 26.5784 77.6223 20.572 79.6835C12.4891 82.4572 5.54785 73.3145 10.3916 66.2742C13.991 61.0426 12.0992 53.8346 6.39453 51.0449C-1.28227 47.2908 0.274436 35.9176 8.67768 34.3645C14.9222 33.2103 18.6813 26.7758 16.6201 20.7693C13.8464 12.6865 22.9891 5.74521 30.0294 10.5889C35.261 14.1884 42.469 12.2966 45.2587 6.59189Z"
      fill="#FD5656"
      stroke="#FFB6B6"
      stroke-width="1.52978"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
