<template>
  <svg
    width="28"
    height="24"
    viewBox="0 0 28 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 11.9988L3.556 7.82683L4.2 23.1988L1.4 23.1988L2.072 7.22483L-4.35221e-08 6.39883L14 0.798828L28 6.39883L14 11.9988ZM14 4.99883C13.23 4.99883 12.6 5.30683 12.6 5.69883C12.6 6.09083 13.23 6.39883 14 6.39883C14.77 6.39883 15.4 6.09083 15.4 5.69883C15.4 5.30683 14.77 4.99883 14 4.99883ZM14 13.3988L21.798 10.2768C22.792 11.5928 23.478 13.1748 23.702 14.8968C23.282 14.8408 22.848 14.7988 22.4 14.7988C18.83 14.7988 15.708 16.7168 14 19.5728C13.133 18.1179 11.9031 16.913 10.4307 16.0761C8.9582 15.2393 7.29366 14.7992 5.6 14.7988C5.152 14.7988 4.718 14.8408 4.298 14.8968C4.522 13.1748 5.208 11.5928 6.202 10.2768L14 13.3988Z"
      fill="#786CE6"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
