<template>
  <TheSelect
      :label="label"
      v-model="localValue"
      :options="options"
      :optionComponentName="optionComponentName"
      :multiple="multiple"
      :placeholder="placeholder"
  />
</template>
<script>
import TheSelect from "@/components/Selectors/TheSelect";
import {GET_SPECIALIZATIONS} from "@/store/specializations";
import TheSelectMixin from "@/components/Selectors/TheSelectMixin";

export default {
  components: {TheSelect},
  mixins:[TheSelectMixin],
  created() {
    this.action=GET_SPECIALIZATIONS
  }
}
</script>