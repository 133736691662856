<script>
import { defineComponent } from "vue";
import BarChartWrapper from "@/views/courses/CourseEdit/Statistics/BarChartWrapper.vue";
import AttendanceDatePicker from "@/views/courses/MyAttendance/AttendanceDatePicker.vue";
// import AttendanceStatisticsMixin from "@/views/courses/CourseEdit/Statistics/AttendanceStatisticsMixin";

export default defineComponent({
  name: "AttendanceStatistics",
  components: { AttendanceDatePicker, BarChartWrapper },
  props: ["group"],
  // mixins: [AttendanceStatisticsMixin],
  mounted() {
    this.end_date = new Date();
    this.start_date = new Date();
    this.start_date.setDate(this.end_date.getDate() - 7);
  },
  data() {
    return {
      start_date: null,
      end_date: null,
      chartData: this.generateChartData(),
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
          },
        },
        scales: {
          y: {
            beginAtZero: true,
            max: 120,
          },
        },
      },
    };
  },
  methods: {
    generateChartData() {
      if (!this.statistics) return;
      const labels = this.statistics.map((st) => st.date);
      const scores = this.statistics.map((st) => st.percentage);

      return {
        labels: labels,
        datasets: [
          {
            label: "Percentage",
            data: scores,
            backgroundColor: ["#4F46E5"],
            borderColor: ["#4F46E5"],
            borderWidth: 0,
            borderRadius: 12,
          },
        ],
      };
    },
  },
});
</script>

<template>
  <div v-if="group" class="flex flex-col gap-4">
    <div class="flex justify-between items-center">
      <div class="statistics-box__title">Регулярность посещений</div>

      <div class="w-2/5 flex justify-between">
        <AttendanceDatePicker
          v-model="start_date"
          :lower-limit="null"
          :upper-limit="null"
          label="Начало периода"
        />
        <AttendanceDatePicker
          v-model="end_date"
          :lower-limit="start_date"
          :upper-limit="null"
          label="Конец периода"
        />
      </div>
    </div>
    <div class="bar-chart flex-1" v-if="statistics">
      <BarChartWrapper :chartData="chartData" :chartOptions="chartOptions" />
    </div>
  </div>
  <div v-else class="flex flex-col items-center">
    Выберите группу для отображения
  </div>
</template>

<style scoped>
.statistics-box__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #111827;
}
</style>
