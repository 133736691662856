<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <TheModal :show="deleteGroupModal" @close="deleteGroupModal=false">
    <div class="flex p-4 w-full">
      <div class="flex mr-4">
        <div class="bg-red-100 p-2 rounded-full align-center h-fit">
          <ExclamationIcon class="h-5 w-5 text-red-600 "/>
        </div>
      </div>
      <div class="flex flex-col w-full">
        <h1 class="text-lg leading-6 font-medium text-gray-900 mb-2">
          {{$t('delete_group')}}
        </h1>
        <div class="w-100 text-sm leading-5 font-normal text-gray-500 mb-4">
          {{$t('delete_group_text')}}
        </div>
        <div class="flex place-content-end">
          <TheButton bg="outline" @click="deleteGroupModal=false" class="mr-3">{{ $t('cancel') }}</TheButton>
          <TheButton bg="danger" @click="deleteGroup">{{ $t('delete') }}</TheButton>
        </div>
      </div>
    </div>

  </TheModal>
  <TheModal :show="nameModal" @close="nameModal = false">
    <div class="p-5 min-w-max">
      <div class="flex place-content-between items-center mb-5">
        <div class="text-xl leading-7 font-semibold text-gray-900">
          {{ $t("edit") }}
        </div>
        <XIcon
          class="h-4 cursor-pointer text-gray-400"
          @click="nameModal = false"
        />
      </div>
      <div class="flex flex-col gap-2">
        <div>
          <InputText
            :errors="[is_group_number ? '' : $t('enter_group_name')]"
            :placeholder="$t('enter_group_name')"
            v-model="new_group_number"
          >
            <template #label>
                <span class="text-sm leading-5 font-medium text-gray-700">{{$t('group_name')}}</span>
            </template>
          </InputText>
        </div>
        <div :class="{
          'mb-56':clicked
        }">
          <span class="text-sm leading-5 font-medium text-gray-700">{{$t('course_end_date')}}</span>
          <datepicker
              style="border-radius: 5px; border-color: #D1D5DB; min-width:100%"
              v-model="new_end_date"
              :lowerLimit="group.start_date"
              :typeable="false"
              color="#561BFB"
              class="no-outline w-100 futura"
              @click="clicked=true"
              @blur="clicked=false"
              :locale="ru"
            ></datepicker>
        </div>
      </div>
      
      <div class="mt-4 flex flex-row place-content-end">
        <TheButton bg="outline" class="mr-6" @click="nameModal = false">{{
          $t("cancel")
        }}</TheButton>
        <TheButton bg="primary" @click="changeGroupNumber">{{
          $t("save_changes")
        }}</TheButton>
      </div>
    </div>
  </TheModal>
  <div @scroll="alert('scrolled')">
    <div class="grid grid-cols-2 mb-6">
      <div class="flex flex-col">
        <span class="text-2xl text-gray-900 font-semibold mb-4">
          {{ title }}
        </span>
        <span  v-if="group.end_date" class="text-sm leading-5 font-medium text-gray-500 flex ">
          <CalendarIcon class="h-6 text-gray-400" />
          <span class="mt-1 ml-1">{{$t('course_end_date')}}: {{formatDate(group.end_date)}}</span>
        </span>
      </div>
      <div v-if="!addMode" class="flex h-fit place-content-end gap-3">
        <InputText
          :placeholder="$t('search_by_name_email')"
          v-model="search"
          class="h-full"
          style="font-size: initial !important"
        >
          <template #leftIcon>
            <SearchIcon class="h-5 text-gray-400" />
          </template>
        </InputText>
        <TheButton
          bg="outline"
          class="button-white"
          @click="
            new_group_number = group.group_number;
            nameModal = true;
          "
        >
          <PencilIcon class="h-5 mr-2" />
          {{ $t("edit") }}
        </TheButton>
        <TheButton
          bg="secondary"
          class="button-white"
          @click="
            deleteGroupModal=true
          "
        >
          <TrashIcon class="h-5 mr-2" />
          {{ $t("delete_group") }}
        </TheButton>
        <TheButton bg="primary" @click="addMode = true">
          {{ $t("add_to_group") }}
        </TheButton>
      </div>
    </div>
    <GroupCard
      @endDate="(end_date, start_date) => setEndDate(group.end_date, group.start_date)"
      ref="group_card"
      :course_id="group.course"
      :group_id="group_number"
      :group_number="group.group_number"
      :addMode="addMode"
      :search="search"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import TheModal from "@/components/TheModal/TheModal.vue";
import InputText from "@/components/InputText.vue";
import TheButton from "@/components/Button/Button";
import { SearchIcon, XIcon, ExclamationIcon } from "@heroicons/vue/outline";
import GroupCard from "@/components/Groups/GroupCard.vue";
import { GET_COURSE_GROUP, CHANGE_GROUP_NUMBER, DELETE_GROUP } from "@/store/courses";
import { PencilIcon } from "@heroicons/vue/solid";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import moment from "moment";
import {CalendarIcon, TrashIcon} from "@heroicons/vue/solid";
import Datepicker from "vue3-datepicker";
import { ru } from "date-fns/locale";
export default {
  data: () => ({
    is_group_number: true,
    group_number: null,
    search: "",
    addMode: false,
    nameModal: false,
    new_group_number: "",
    breadcrumbs: [],
    new_end_date:'',
    end_date:'',
    start_date:'',
    ru:ru,
    clicked:false,
    deleteGroupModal:false
  }),
  components: {
    TheModal,
    InputText,
    SearchIcon,
    TheButton,
    GroupCard,
    PencilIcon,
    XIcon,
    Breadcrumbs,
    CalendarIcon,
    Datepicker,
    TrashIcon,
    ExclamationIcon
  },
  computed: {
    ...mapFields(["courses.group"]),
    title() {
      return this.addMode
        ? this.$t("add_to_group")
        : this.group.course_name + " " + this.group.group_number;
    },
  },
  async created() {
    this.group_number = this.$route.query.group_number;
    await this.GET_COURSE_GROUP(this.group_number)
    this.new_group_number = this.group.group_number;
    this.new_end_date = new Date(this.group.end_date);
    this.breadcrumbs = [
      {
        name: this.$t("courses"),
        path: "/courses/list",
      },
      {
        name: this.group.course_name,
        path: `/courses/${this.id}/edit`,
      },
      {
        name: this.$t("groups"),
        path: `/courses/${this.group.course}/groups`,
      },
      {
        name: this.group.group_number,
        path: this.$route.path,
      },
    ];
  },
  methods: {
    ...mapActions([GET_COURSE_GROUP, CHANGE_GROUP_NUMBER, DELETE_GROUP]),
    async changeGroupNumber() {
      if (this.new_group_number.trim().length == 0) {
        this.is_group_number = false;
        return;
      }
      await this[CHANGE_GROUP_NUMBER]({
        group: this.group.id,
        new_group_number: this.new_group_number,
        end_date:this.new_end_date
      });
      history.replaceState(
        null,
        "",
        window.location.href.split("group_number=")[0] +
          "group_number=" +
          this.group.id
      );
      this.$router.go();
    },
    // setEndDate(end_date, start_date){
    //   this.group.end_date = new Date(end_date)
    //   this.new_end_date = new Date(end_date)
    //   this.group.start_date = new Date(start_date)
    // },
    formatDate(date){
        moment.locale(this.currentLocale)
        return moment(date).format('DD.MM.YYYY')
    },
    async deleteGroup(){
      this[DELETE_GROUP](this.group.id).then(()=>{
        history.replaceState(null, "", window.location.href.split("view?")[0])
        this.$router.go();
      }).catch((err)=>{
        console.log(err)
      });
    }
  },
  watch: {
    new_group_number: function () {
      if (this.new_group_number.trim().length == 0) {
        this.is_group_number = false;
      } else {
        this.is_group_number = true;
      }
    },
    new_end_date:function(){
      this.clicked=false
    },
    addMode: function () {
      if (this.addMode) {
        this.breadcrumbs = [
          {
            name: this.$t("courses"),
            path: "/courses/list",
          },
          {
            name: this.group.course_name,
            path: `/courses/${this.id}/edit`,
          },
          {
            name: this.$t("groups"),
            path: `/courses/${this.group.course}/groups`,
          },
          {
            name: this.group_number,
            path: `${this.$route.path}?group_number=${this.group_number}`,
          },
          {
            name: this.$t("add_to_group"),
            path: `${this.$route.path}?group_number=${this.group_number}`,
          },
        ];
      } else {
        this.breadcrumbs = [
          {
            name: this.$t("courses"),
            path: "/courses/list",
          },
          {
            name: this.group.course_name,
            path: `/courses/${this.id}/edit`,
          },
          {
            name: this.$t("groups"),
            path: `/courses/${this.group.course}/groups`,
          },
          {
            name: this.group_number,
            path: `${this.$route.path}?group_number=${this.group_number}`,
          },
        ];
      }
    },
  },
};
</script>
<style lang="postcss">
.mb-56{
  margin-bottom: 14rem;
}
</style>