export default [
	{
		name: 'Mailings Layout',
		path: '/',
		component: async () => require('@/layouts/CabinetLayout'),
		children: [
			{
				name: 'MailingsList',
				path: '/mailings-list',
				component: async () => require('@/views/mailings/MailingsView'),
			},
		],
	}
];