<template>
  <p class="text-2xl font-semibold leading-8 text-gray-900 pb-6">{{ $t('mailings') }}</p>
  <MailingList />
</template>

<script>
import MailingList from '@/views/mailings/MailingList/MailingList';

export default {
  components: { MailingList }
}
</script>