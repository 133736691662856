<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "BigAttendancePresentIcon",
});
</script>

<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.5008 14.0008C24.5008 19.7998 19.7998 24.5008 14.0008 24.5008C8.20179 24.5008 3.50078 19.7998 3.50078 14.0008C3.50078 8.20179 8.20179 3.50078 14.0008 3.50078C19.7998 3.50078 24.5008 8.20179 24.5008 14.0008ZM19.6857 12.6857C20.5058 11.8656 20.5058 10.536 19.6857 9.71586C18.8656 8.89576 17.536 8.89576 16.7159 9.71586L12.6008 13.8309L11.2857 12.5159C10.4656 11.6958 9.13596 11.6958 8.31586 12.5159C7.49576 13.336 7.49576 14.6656 8.31586 15.4857L11.1159 18.2857C11.936 19.1058 13.2656 19.1058 14.0857 18.2857L19.6857 12.6857Z"
      fill="#10B981"
      stroke="#10B981"
      stroke-width="1.4"
    />
  </svg>
</template>

<style scoped></style>
