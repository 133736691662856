<template>
  <quill-editor
      :value="content"
      @blur="onEditorBlur($event)"
      @focus="onEditorFocus($event)"
      @change="onEditorChange($event)"
  />
</template>

<script>
import { quillEditor } from 'vue3-quill'

export default {
  components: {
    quillEditor
  },
  props:{
    modelValue:{},
  },
  watch:{
    modelValue:{
      handler(val){
        if(val && val.html){
          this.content = val.html
        }else{
          this.content = val;
        }
      },
      immediate: true
    }
  },
  // computed:{
  //   // content(){
  //   //   return this.modelValue
  //   // }
  // },
  data:()=>({
    content:'',
    editorOption: {
      placeholder: 'core',
      // more options
    },
  }),
  methods:{
    onEditorBlur (quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill){
      console.log('quill focus',quill)
    },
    onEditorChange({html,quill}){
      this.$emit('update:modelValue', {
        delta:quill.editor.delta,
        html
      })
    }
  }
}
</script>
<style>
.ql-toolbar{
  border-radius:  6px 6px 0px 0px;
}
.ql-container{
  border-radius:   0px 0px 6px 6px;
  min-height: 144px;
}
</style>