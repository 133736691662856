<template>
  <TheModal :show="addCoursesModalOpen" @close="handleModalClose" class="z-50">
    <div class="p-6 flex flex-col gap-6 max-h-[80vh] overflow-auto relative">
      <div class="flex justify-between">
        <h2 class="text-xl leading-7 font-semibold text-gray-900">
          Выберите курс для копирования:
        </h2>
        <XIcon
          class="w-6 h-6 text-gray-400 cursor-pointer"
          @click="handleModalClose"
        />
      </div>
      <InputText :placeholder="$t('search_by_name')" v-model="filter.text">
        <template #leftIcon>
          <SearchIcon class="text-gray-400 h-5" />
        </template>
        <template #rightButton>
          <button
            type="button"
            @click="revertOrder"
            class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
          >
            <component
              :is="
                filter.order_dir === 'asc'
                  ? 'SortDescendingIcon'
                  : 'SortAscendingIcon'
              "
              class="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span>{{ $t("sorting") }}</span>
          </button>
        </template>
      </InputText>
      <div class="card-container flex flex-col pb-14">
        <div
          class="py-6 flex justify-between items-center"
          v-for="course of filteredCourses"
          :key="course.id"
        >
          <CourseCardRowSmall
            :specializations="course.specializations"
            :name="course.name"
            :logo="course.logo"
          />
          <input
            type="checkbox"
            name="copy-course"
            class="cursor-pointer"
            :checked="!!selectedCourses.find((c) => c.id === course.id)"
            @change="selectCourse(course)"
          />
        </div>
      </div>
      <div
        class="w-full fixed bottom-0 right-0 bg-white flex justify-end gap-6 p-6"
      >
        <Button bg="outline" @click="handleModalClose"
          >{{ $t("cancel") }}
        </Button>
        <Button bg="primary" @click="handleCoursesChoose"
          >{{ $t("choose") }}
        </Button>
      </div>
    </div>
  </TheModal>
</template>

<script>
import { GET_PUBLIC_COURSES } from "@/store/courses";
import { mapActions } from "vuex";
import TheModal from "@/components/TheModal/TheModal";
import {
  SearchIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  XIcon,
} from "@heroicons/vue/solid";
import InputText from "@/components/InputText";
import CourseCardRowSmall from "@/components/Courses/Card/CourseCardRowSmall";
import Button from "@/components/Button/Button";

export default {
  props: ["addCoursesModalOpen", "publishedCourses"],
  components: {
    TheModal,
    XIcon,
    SearchIcon,
    InputText,
    CourseCardRowSmall,
    Button,
    SortAscendingIcon,
    SortDescendingIcon,
  },
  computed: {
    filteredCourses() {
      return this.courses.filter((course) =>
        course.name.toLowerCase().includes(this.filter.text)
      );
    },
  },
  watch: {
    "filter.order_dir"(newVal) {
      if (newVal === "asc") {
        this.courses = this.courses.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } else {
        this.courses = this.courses.sort((a, b) =>
          b.name.localeCompare(a.name)
        );
      }
    },
    publishedCourses(newVal) {
      this.selectedCourses = [...newVal];
    },
  },
  async mounted() {
    this.courses = await this[GET_PUBLIC_COURSES]();
  },
  data() {
    return {
      filter: {
        text: "",
        order_dir: "asc",
      },
      selectedCourses: [],
      courses: [],
    };
  },
  methods: {
    ...mapActions([GET_PUBLIC_COURSES]),
    revertOrder() {
      this.filter.order_dir = this.filter.order_dir === "asc" ? "desc" : "asc";
    },
    handleModalClose() {
      this.$emit("closeModal");
    },
    selectCourse(course) {
      if (this.selectedCourses.includes(course)) {
        this.selectedCourses = this.selectedCourses.filter(
          (c) => c.id !== course.id
        );
      } else {
        this.selectedCourses.push(course);
      }
    },
    handleCoursesChoose() {
      this.$emit(
        "add-courses",
        this.selectedCourses.map((course) => course.id)
      );
    },
  },
};
</script>
