<template>
  <!-- Hardcoded "hover", "openDelay" and "closeDelay" -->
  <Popper v-bind="$attrs" hover arrow>
    <slot />
    <template #content="props">
      <slot name="content" v-bind="props" />
    </template>
  </Popper>
</template>

<script>
import Popper from "vue3-popper";
export default {
  components:{
    Popper
  }
}
</script>
<style scoped lang="postcss">
:deep(.popper) {
  @apply bg-gray-600 text-white py-2 px-3 text-xs leading-4 font-normal rounded
}

:deep(.popper #arrow::before) {
  @apply bg-gray-600
}
</style>
