export default [
    {
        name:"Student",
        path:'/student',
        component:async ()=>require('../layouts/CabinetLayout'),
        children:[
            {name:'DashboardPage',path:'dashboard',component:async ()=>require('../views/dashboard/DashboardPage')},
            {name:'StudentsGrading',path:'/students/grading',component:async ()=>require('../views/students/Grading/StudentsGrading')},
        ]
    },

]
