<template>
  <div v-if="isDesign">
    <svg
      width="152"
      height="152"
      viewBox="0 0 152 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M111.75 0.963867L84.059 28.655L42.7765 40.464C38.7946 41.535 35.1414 43.579 32.1456 46.4123C29.1498 49.2456 26.9054 52.7792 25.6142 56.6952L0.642578 133.803L9.42583 142.586L18.2091 151.369L95.3412 126.379C99.237 125.09 102.753 122.855 105.574 119.875C108.396 116.895 110.436 113.263 111.511 109.303L123.326 67.9714L151.036 40.2496L111.75 0.963867ZM111.75 18.2854L133.714 40.2496L117.875 56.0889L95.9108 34.1246L111.75 18.2854ZM85.4738 41.0152L110.984 66.5197L99.7022 106.026C99.1614 108.039 98.1313 109.887 96.7036 111.405C95.276 112.924 93.495 114.066 91.5192 114.73L24.1013 136.565L59.5957 101.071C60.624 101.35 61.6845 101.495 62.7501 101.5C65.999 101.5 69.1148 100.209 71.4121 97.9117C73.7095 95.6143 75.0001 92.4985 75.0001 89.2496C75.0001 86.0007 73.7095 82.8849 71.4121 80.5876C69.1148 78.2902 65.999 76.9996 62.7501 76.9996C60.8731 77.0018 59.0218 77.4352 57.339 78.2664C55.6561 79.0977 54.1868 80.3045 53.0444 81.7937C51.902 83.283 51.1171 85.0148 50.7503 86.8556C50.3835 88.6963 50.4446 90.5967 50.9288 92.4101L15.4345 127.898L37.2578 60.5234C37.9243 58.5247 39.0779 56.7234 40.6145 55.282C42.151 53.8406 44.0223 52.8044 46.0595 52.2669L85.48 41.0091L85.4738 41.0152Z"
        fill="#21147C"
        fill-opacity="0.6"
      />
    </svg>
  </div>
  <div v-else>
    <svg
      width="152"
      height="129"
      viewBox="0 0 152 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M70.3319 82.0007L86.4713 76.3631C88.6597 75.5987 90.2285 74.2322 91.1778 72.2637C92.1271 70.2952 92.2196 68.2167 91.4552 66.0283C90.6908 63.8399 89.2948 62.2569 87.2672 61.2791C85.2347 60.2989 83.1242 60.191 80.9358 60.9554L57.1371 69.2685C56.043 69.6507 55.1356 70.1988 54.4152 70.9129C53.6898 71.6246 53.0898 72.4726 52.6152 73.4569C52.1405 74.4412 51.8505 75.4387 51.7452 76.4494C51.635 77.4578 51.7711 78.5091 52.1533 79.6033L60.4663 103.402C61.2307 105.59 62.6292 107.175 64.6617 108.155C66.6893 109.133 68.7972 109.239 70.9856 108.475C73.174 107.71 74.7429 106.344 75.6922 104.375C76.6415 102.407 76.7339 100.328 75.9695 98.14L70.3319 82.0007ZM117.208 104.606L101.068 110.244C98.8799 111.008 97.3111 112.375 96.3618 114.343C95.4124 116.312 95.32 118.39 96.0844 120.579C96.8488 122.767 98.2473 124.351 100.28 125.331C102.307 126.309 104.415 126.416 106.604 125.651L130.402 117.338C131.497 116.956 132.404 116.408 133.124 115.694C133.85 114.982 134.45 114.134 134.924 113.15C135.399 112.166 135.689 111.168 135.794 110.157C135.905 109.149 135.769 108.098 135.386 107.004L127.073 83.2049C126.691 82.1108 126.129 81.1572 125.387 80.3443C124.639 79.529 123.806 78.8995 122.885 78.4557C121.96 78.0095 120.948 77.7491 119.85 77.6744C118.747 77.5973 117.648 77.7499 116.554 78.1321C114.366 78.8965 112.797 80.2629 111.847 82.2315C110.898 84.2 110.806 86.2785 111.57 88.4669L117.208 104.606ZM10.0565 134.822C5.99642 132.864 3.21606 129.743 1.71543 125.459C0.222097 121.172 0.45441 116.998 2.41237 112.938L52.2513 9.59017C54.2092 5.53007 57.3303 2.7497 61.6147 1.24908C65.9015 -0.244256 70.075 -0.0119435 74.1351 1.94601L177.483 51.7849C181.543 53.7429 184.324 56.864 185.824 61.1483C187.317 65.4352 187.085 69.6086 185.127 73.6687L135.288 177.017C133.33 181.077 130.209 183.857 125.925 185.358C121.638 186.851 117.465 186.619 113.404 184.661L10.0565 134.822ZM17.1764 120.058L120.524 169.897L170.363 66.5489L67.0152 16.71L17.1764 120.058Z"
        fill="#21147C"
      />
    </svg>
  </div>
</template>
<script>
import IconMixin from "@/assets/icons/IconMixin";

export default {
  mixins: [IconMixin],
  props: ["isDesign"],
};
</script>
