<template>
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" rx="16" fill="#B3ABFF" fill-opacity="0.2" />
    <rect
      x="8.3374"
      y="8.62305"
      width="46.5455"
      height="46.5455"
      rx="16"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.1375 43.8741C31.2717 43.9442 31.4218 43.9799 31.5719 43.9787C31.722 43.9775 31.8709 43.9407 32.0064 43.8693L36.3311 41.5575C37.5574 40.9038 38.5178 40.1728 39.2671 39.3217C40.8962 37.4675 41.7857 35.101 41.7699 32.6607L41.7186 24.6115C41.7138 23.6843 41.1048 22.8571 40.2043 22.5563L32.1589 19.857C31.6744 19.693 31.1436 19.6965 30.6677 19.8653L22.6528 22.6609C21.7572 22.9736 21.1592 23.8068 21.1641 24.7351L21.2153 32.7784C21.2312 35.2222 22.1513 37.578 23.8072 39.4132C24.5638 40.2524 25.5327 40.9727 26.7725 41.6157L31.1375 43.8741ZM29.9926 34.4138C30.1732 34.5874 30.4075 34.6729 30.6418 34.6706C30.8761 34.6694 31.1092 34.5814 31.2874 34.4055L36.0135 29.7461C36.3687 29.3955 36.365 28.8321 36.0062 28.4862C35.6462 28.1403 35.0666 28.1427 34.7115 28.4933L30.6284 32.518L28.9566 30.911C28.5966 30.5651 28.0182 30.5687 27.6619 30.9193C27.3068 31.2699 27.3104 31.8333 27.6704 32.1792L29.9926 34.4138Z"
      fill="#B3ABFF"
    />
  </svg>
</template>
<script>
import IconMixin from "@/assets/icons/IconMixin";

export default {
  mixins: [IconMixin],
};
</script>
