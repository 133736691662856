<template>
  <button :class="[buttonClass, customClass]"><slot></slot></button>
</template>
<script>
export default {
  props: {
    shape: {
      type: String,
      default: "ordinary",
    },
    customClass: {
      type: [String, Object, Array],
      default: "",
    },
  },
  computed: {
    buttonClass() {
      return `button-${this.shape.toLowerCase()}`;
    },
  },
};
</script>
<style scoped>
.button-ordinary {
  border: 2px solid #6759ea;
  color: white;
  background: #786ce6;
  font-style: normal;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
  width: 100%;
}
.button-outline {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #fff;
  color: #786ce6;
  border: 2px solid #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
  padding: 16px;
  font-size: 16px;
  font-weight: 400;
}
</style>
