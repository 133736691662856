export default {
    methods:{
        setLocale(locale){
            localStorage.setItem('locale',locale);
            this.$i18n.locale = locale;
        }
    },
    computed:{
        locales(){
            return this.$i18n.availableLocales
        },
        activeLocale(){
            return this.$i18n.locale
        }
    }
}