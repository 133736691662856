const baseUrl = "/api/v1/courses";
export default ($axios) => ({
  get_courses_select() {
    return $axios.get(`${baseUrl}/selector`);
  },
  get_list(params) {
    return $axios.get(`${baseUrl}/personal`, { params });
  },
  get_public_list(payload) {
    return $axios.get(`${baseUrl}/`, payload);
  },
  get_item(id) {
    return $axios.get(`${baseUrl}/${id}`);
  },
  start(id) {
    return $axios.post(`${baseUrl}/${id}/start`, { course: id });
  },
  store(data) {
    return $axios.post(`${baseUrl}/`, data);
  },
  update(id, data) {
    return $axios.put(`${baseUrl}/${id}`, data);
  },
  delete(id) {
    return $axios.delete(`${baseUrl}/${id}`);
  },
  get_submissions(params = {}) {
    return $axios.get("/api/v1/submissions/", params);
  },
  get_submission_by_id(id) {
    return $axios.get(`/api/v1/submissions/${id}`);
  },
  update_submission(id, data) {
    return $axios.patch(`/api/v1/submissions/${id}`, data);
  },
  copy(data) {
    return $axios.post(`${baseUrl}/copy`, data);
  },
  get_published_list() {
    return $axios.get(`${baseUrl}/published`);
  },
  get_published_course(id) {
    return $axios.get(`${baseUrl}/published/${id}`);
  },
  publish_courses(data) {
    return $axios.post(`${baseUrl}/publish`, data);
  },
  unpublish_courses(data) {
    return $axios.post(`${baseUrl}/unpublish`, data);
  },
  get_course_group(id) {
    return $axios.get(`${baseUrl}/course_group/${id}`);
  },
  change_group_number(params) {
    return $axios.patch(`${baseUrl}/course_group/${params.group}`, params);
  },
  delete_group_number(id) {
    return $axios.delete(`${baseUrl}/course_group/${id}`);
  },
  create_landing_course(id) {
    return $axios.post(`${baseUrl}/course_group/${id}`);
  },
});
