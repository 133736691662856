import { SET_LOADING } from "@/store/core";
export const GET_STUDENT_COURSE_GROUPS = "GET_STUDENT_COURSE_GROUPS";
export const GET_COURSE_GROUP_POINTS = "GET_COURSE_GROUP_POINTS";
export const GET_MY_COURSE_GROUP_POINTS = "GET_MY_COURSE_GROUP_POINTS";
export const CREATE_MANY_COURSE_GROUP_POINTS =
  "CREATE_MANY_COURSE_GROUP_POINTS";
export const DOWNLOAD_GRADES = "DOWNLOAD_GRADES";
export const GET_COURSE_GROUP_POINTS_STATISTICS =
  "GET_COURSE_GROUP_POINTS_STATISTICS";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {};
  const actions = {
    [GET_STUDENT_COURSE_GROUPS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.student_grades.get_course_group_users(params);
      commit(SET_LOADING, false);
      return data;
    },
    [DOWNLOAD_GRADES]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.student_grades.download_grade_records(params);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_COURSE_GROUP_POINTS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.student_grades.get_course_group_points(
        params
      );
      commit(SET_LOADING, false);
      return data;
    },
    [CREATE_MANY_COURSE_GROUP_POINTS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } =
        await $api.student_grades.create_course_group_points_many(params);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_MY_COURSE_GROUP_POINTS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.student_grades.get_my_course_group_points(
        params
      );
      commit(SET_LOADING, false);
      return data;
    },
    [GET_COURSE_GROUP_POINTS_STATISTICS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } =
        await $api.student_grades.get_course_group_points_statistics(params);
      commit(SET_LOADING, false);
      return data;
    },
  };
  const mutations = {};
  const getters = {};
  return {
    actions,
    state,
    mutations,
    getters,
  };
};
export default (app) => createStore(app);
