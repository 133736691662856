<template>
  <ContentBlockForm
    :content_name="$t('image')"
    :_key="_key"
    :attachments="attachments"
  >
    <FileDropzone v-model="localValue" :key="localValue ?? 'no_logo'" />
  </ContentBlockForm>
</template>
<script>
import FileDropzone from "@/components/FileDropzone";
import ContentBlockForm from "@/views/core/News/components/ContentBlockForm";

export default {
  components: { ContentBlockForm, FileDropzone },
  props: {
    attachments: [],
    content: {},
    _key: {
      type: String,
      required: true,
    },
  },
  computed: {
    localValue: {
      get() {
        return this.content;
      },
      set(val) {
        this.$emit("contentChange", val);
      },
    },
  },
};
</script>
