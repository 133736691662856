<script>
import { defineComponent } from "vue";
import TariffComponent from "@/views/core/Landing/Tariffs/TariffComponent.vue";
// import TariffComponent from "@/views/core/Landing/Tariffs/TariffComponent.vue";

export default defineComponent({
  components: { TariffComponent },
  // components: { TariffComponent },
  data() {
    return {
      activeTab: 0,
      tariffs: [
        {
          chosen: false,
          preferred: false,
          name: "Standard",
          benefits: [
            "база интервью вопросов от 50+ компании",
            "более 40 закрытых гостевых лекции",
            "индивидуальный аккаунт на платформе",
            "доступ в закрытую telegram-группу с вакансиями",
            "сертификат о прохождении курса",
            "работа над реальными продуктовыми кейсами компании",
            "доступ к материалам доступен 3 месяца после окончания курса",
          ],
          notBenefits: [
            "30 минут 1-1 консультация CV и профиль карьеры",
            "техническое интервью от Senior PM крупных ИТ компании",
            "менторская программа: 6 сессий по 1 часу",
            "индивидуальный Roadmap",
            "публикация в Media",
          ],
          pricePrev: "500 000 ₸",
          priceNow: "390 000 ₸",
          kaspi: "от 32 500 ₸ x 12 мес",
        },
        {
          chosen: false,

          preferred: true,
          name: "Pro",
          benefits: [
            "база интервью вопросов от 50+ компании",
            "более 40 закрытых гостевых лекции",
            "индивидуальный аккаунт на платформе",
            "доступ в закрытую telegram-группу с вакансиями",
            "сертификат о прохождении курса",
            "работа над реальными продуктовыми кейсами компании",
            "доступ к материалам доступен 3 месяца после окончания курса",
            "30 минут 1-1 консультация CV и профиль карьеры",
            "техническое интервью от Senior PM крупных ИТ компании",
          ],
          notBenefits: [
            "менторская программа: 6 сессий по 1 часу",

            "индивидуальный Roadmap",
            "публикация в Media",
          ],
          pricePrev: "700 000 ₸",
          priceNow: "490 000 ₸",
          kaspi: "от 40 833 ₸ x 12 мес",
        },
        {
          chosen: true,
          preferred: false,
          name: "VIP",
          benefits: [
            "база интервью вопросов от 50+ компании",
            "более 40 закрытых гостевых лекции",
            "индивидуальный аккаунт на платформе",
            "доступ в закрытую telegram-группу с вакансиями",
            "сертификат о прохождении курса",
            "работа над реальными продуктовыми кейсами компании",
            "доступ к материалам доступен 3 месяца после окончания курса",
            "30 минут 1-1 консультация CV и профиль карьеры",
            "техническое интервью от Senior PM крупных ИТ компании",
            "менторская программа: 6 сессий по 1 часу",
            "индивидуальный Roadmap",
            "публикация в Media",
          ],
          notBenefits: [],
          pricePrev: "1 800 000 ₸",
          priceNow: "1 290 000 ₸",
          kaspi: "от 107 500 ₸ x 12 мес",
        },
      ],
      tariff: {
        preferred: true,
        name: "Pro",
        price: "600 000 ₸",
        benefits: [
          "доступ к материалам курса 3 месяца после окончания курса",
          "материалы для самостоятельного изучения: электронные книги, статьи, онлайн ресурсы",
          "менторская поддержка: возможность получения обратной связи от опытных менторов и преподавателей",
          "сертификат о прохождении курса",
          "помощь в подготовке резюме, проведение тренингов по собеседованиям, рекомендации по поиску работы и стажировок",
          "участие в реальных проектах для применения полученных знаний на практике",
          "обратная связь и оценка: регулярная обратная связь по выполненным заданиям и проектам",
        ],
      },
    };
  },
  methods: {
    selectTab(index) {
      this.activeTab = index;
    },
  },
});
</script>

<template>
  <div class="main-tariffs flex flex-col gap-10">
    <h1>Тарифы</h1>
    <!--    <div class="tariffs-mob flex flex-col gap-6">-->
    <!--      <div class="tariffs-tabs-grid">-->
    <!--        <div-->
    <!--          v-for="(tariff, index) in tariffs"-->
    <!--          :key="index"-->
    <!--          @click="selectTab(index)"-->
    <!--          :class="{-->
    <!--            'is-active': index === activeTab,-->
    <!--            'not-active': index !== activeTab,-->
    <!--          }"-->
    <!--        >-->
    <!--          {{ tariff.name }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <TariffComponent :tariff="tariffs[activeTab]" />-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="flex gap-6 tariffs">-->
    <!--      <TariffComponent-->
    <!--        v-for="(tariff, index) in tariffs"-->
    <!--        :key="index"-->
    <!--        :tariff="tariff"-->
    <!--      />-->
    <!--    </div>-->
    <div class="one-tariff flex">
      <div class="tariff-box flex flex-col justify-center gap-10 items-center">
        <div class="flex flex-col gap-2 justify-center">
          <div class="tariff-subheading uppercase">Тариф</div>
          <div class="tariff-heading">{{ tariff.name }}</div>
        </div>
        <div class="price-now">{{ tariff.price }}</div>
      </div>

      <div><div class="line" /></div>
      <div class="tariff-content-box flex flex-col gap-4">
        <div
          class="flex gap-3"
          v-for="(benefit, index) in tariff.benefits"
          :key="index"
        >
          <div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.6 11.8L6.45 9.65C6.26667 9.46667 6.03333 9.375 5.75 9.375C5.46667 9.375 5.23333 9.46667 5.05 9.65C4.86667 9.83333 4.775 10.0667 4.775 10.35C4.775 10.6333 4.86667 10.8667 5.05 11.05L7.9 13.9C8.1 14.1 8.33333 14.2 8.6 14.2C8.86667 14.2 9.1 14.1 9.3 13.9L14.95 8.25C15.1333 8.06667 15.225 7.83333 15.225 7.55C15.225 7.26667 15.1333 7.03333 14.95 6.85C14.7667 6.66667 14.5333 6.575 14.25 6.575C13.9667 6.575 13.7333 6.66667 13.55 6.85L8.6 11.8ZM10 20C8.61667 20 7.31667 19.7373 6.1 19.212C4.88333 18.6867 3.825 17.9743 2.925 17.075C2.025 16.175 1.31267 15.1167 0.788 13.9C0.263333 12.6833 0.000666667 11.3833 0 10C0 8.61667 0.262667 7.31667 0.788 6.1C1.31333 4.88333 2.02567 3.825 2.925 2.925C3.825 2.025 4.88333 1.31267 6.1 0.788C7.31667 0.263333 8.61667 0.000666667 10 0C11.3833 0 12.6833 0.262667 13.9 0.788C15.1167 1.31333 16.175 2.02567 17.075 2.925C17.975 3.825 18.6877 4.88333 19.213 6.1C19.7383 7.31667 20.0007 8.61667 20 10C20 11.3833 19.7373 12.6833 19.212 13.9C18.6867 15.1167 17.9743 16.175 17.075 17.075C16.175 17.975 15.1167 18.6877 13.9 19.213C12.6833 19.7383 11.3833 20.0007 10 20Z"
                fill="#69C9A6"
              />
            </svg>
          </div>
          <div class="tariff-benefits">
            {{ benefit }}
          </div>
        </div>
        <div class="flex justify-start">
          <button>Записаться</button>
        </div>
      </div>
    </div>
    <div class="one-tariff-mob"><TariffComponent :tariff="tariff" /></div>
  </div>
</template>

<style scoped>
.one-tariff-mob {
  display: none;
}
h1 {
  color: #1f2937;
  font-size: 40px;
  font-weight: 800;
}
.main-tariffs {
  background: #f4f5fa;
  padding: 40px 100px;
}
.tariffs-types {
  display: none;
}
.tariffs-mob {
  display: none;
}
.tariffs-tabs-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}
.is-active {
  background: #786ce6;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 6px;
}
.not-active {
  background: white;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #786ce680;
}
.one-tariff {
  border-radius: 24px;
  background: white;
  padding: 40px 0;
}
.tariff-box {
  flex-basis: 43%;
  padding: 0 80px;
}
.tariff-content-box {
  flex-basis: 57%;
  padding: 0 80px;
}
.tariff-subheading {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #786ce6;
}
.tariff-heading {
  font-weight: 600;
  font-size: 28px;
}
.price-now {
  font-size: 40px;
  font-weight: 700;
  color: #1f2937;
}
.line {
  height: 100%;
  border-left: 2px dashed #d1d5db;
  min-width: 2px;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 32px;
}
.tariff-benefits {
  color: #6b7280;
  font-size: 14px;
  font-weight: 400;
}
button {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  padding: 16px 115px;
  border-radius: 25px;
  background: #786ce6;
  color: white;
}
@media (max-width: 650px) {
  .main-tariffs {
    padding: 32px 16px;
    gap: 24px;
  }
  h1 {
    font-size: 28px;
    font-weight: 700;
  }
  .tariffs-types {
    display: flex;
  }
  .tariffs {
    display: none;
  }
  .tariffs-mob {
    display: flex;
  }
  .one-tariff {
    display: none;
  }
  .one-tariff-mob {
    display: flex;
  }
}
</style>
