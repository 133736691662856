<template>
  <div class="flex-shrink-0">
    <div class="flex-shrink-0" v-if="url || requireImage(review.img)">
      <img
        :src="url || requireImage(review.img)"
        alt=""
        class="h-[56px] w-[56px] rounded-full object-center flex-shrink-0"
      />
    </div>
    <div v-else class="flex-shrink-0">
      <div
        class="h-[56px] w-[56px] rounded-full object-center flex-shrink-0 flex items-center text-center justify-center border text-xs"
      >
        NO AVATAR
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    review: {
      type: Object,
      default: () => ({ img: "" }),
    },
    url: {
      type: String,
    },
  },
  methods: {
    requireImage(imagePath) {
      if (!imagePath) return "";
      return require(`@/assets/images/landing/${imagePath}`);
    },
  },
  computed: {
    formattedUrl() {
      return this.review.img && this.review.img !== ""
        ? this.review.img.replace("http://", "https://")
        : "";
    },
    marginClass() {
      return this.$attrs.length - 1 === this.$attrs.index ? "" : "-mr-2";
    },
    imgSrc() {
      return this.review.img
        ? require(`@/assets/images/landing/${this.review.img}`)
        : "";
    },
  },
};
</script>

<style scoped>
.position-center {
  position: center;
}
</style>
