import {mapActions} from "vuex";
import {GET_COURSES} from "@/store/courses";
import {mapFields} from "vuex-map-fields";
// import {GET_QUIZZES} from "@/store/quizzes";

export default {
    computed:{
        ...mapFields(['courses.courses','quizzes.quizzes'])
    },
    methods:{
        ...mapActions([GET_COURSES])
    },
    created() {
        this.GET_COURSES({my_courses:true,amount:3, is_finished: false})
        // this.GET_QUIZZES()
    }
}