<template>
  <router-link custom :to="`/course/${id}`" v-slot="{ navigate, href }">
    <a
      :href="href"
      @click="navigate"
      class="shadow-lg rounded-xl bg-white w-full overflow-hidden"
      style="max-width: 17.125 rem"
    >
      <div
        class="h-40 hidden sm:flex bg-no-repeat bg-cover"
        :style="`background-image:url(${logo})`"
      />
      <div class="p-4 flex flex-col justify-between h-[12.4rem]">
        <span>
          <CourseCardSpecializations :specializations="specializations" />
          <p
            class="text-gray-900 text-lg font-semibold hover:text-clip line-clamp-4 break-word leading-6 h-[6.2rem]"
          >
            {{ name }}
          </p>
        </span>
        <span class="flex">
          <div class="flex items-center overflow-hidden">
            <UserAvatar
              class="flex-shrink-0"
              :length="instructors.length"
              v-for="(instructor, index) in instructors"
              :url="formatUrl(instructor.instructor.avatar)"
              :key="`instructor_avatar_${index}`"
              :index="index"
            />
          </div>
          <span class="ml-3">
            <span>
              <p
                v-for="(instructor, index) in instructors"
                :key="`instructor_name_${index}`"
                class="text-gray-900 text-sm font-medium"
              >
                {{ instructor.instructor.full_name }}
              </p>
            </span>
            <p class="text-gray-500 text-sm font-medium">
              {{ formattedStartDate }}-{{ formattedEndDate }} ·
              {{ totalHours }} ч
            </p>
          </span>
        </span>
      </div>
    </a>
  </router-link>
</template>

<script>
import CourseCardMixin from "@/components/Courses/Card/CourseCardMixin";
import CourseCardSpecializations from "@/components/Courses/Card/CourseCardSpecializations";
import UserAvatar from "@/components/UserAvatar";

export default {
  name: "LandingCourseCard",
  components: { UserAvatar, CourseCardSpecializations },
  mixins: [CourseCardMixin],
  props: {
    instructors: [],
    destination: String,
  },
  methods: {
    formatUrl(url) {
      if (url !== null) {
        return url.replace("http://", "https://");
      }
    },
  },
};
</script>

<style scoped></style>
