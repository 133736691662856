<template>
  <svg
    width="60"
    height="61"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_628_9750)">
      <rect x="2" y="1.99805" width="56" height="56" rx="16" fill="white" />
      <path
        d="M31.2839 24.9818C31.1758 25.0886 31.09 25.2157 31.0315 25.3559C30.9729 25.4961 30.9427 25.6465 30.9427 25.7985C30.9427 25.9504 30.9729 26.1008 31.0315 26.241C31.09 26.3812 31.1758 26.5084 31.2839 26.6151L33.5006 28.8318H22.7673C22.1256 28.8318 21.6006 29.3568 21.6006 29.9985C21.6006 30.6401 22.1256 31.1651 22.7673 31.1651H33.5006L31.2839 33.3818C31.1758 33.4886 31.09 33.6157 31.0315 33.7559C30.9729 33.8961 30.9427 34.0465 30.9427 34.1985C30.9427 34.3504 30.9729 34.5008 31.0315 34.641C31.09 34.7812 31.1758 34.9084 31.2839 35.0151C31.7389 35.4701 32.4623 35.4701 32.9173 35.0151L37.1056 30.8268C37.2137 30.7189 37.2995 30.5907 37.3581 30.4495C37.4166 30.3084 37.4468 30.1571 37.4468 30.0043C37.4468 29.8515 37.4166 29.7002 37.3581 29.5591C37.2995 29.4179 37.2137 29.2897 37.1056 29.1818L32.9173 24.9818C32.8105 24.8737 32.6833 24.7879 32.5431 24.7293C32.4029 24.6708 32.2525 24.6406 32.1006 24.6406C31.9487 24.6406 31.7982 24.6708 31.6581 24.7293C31.5179 24.7879 31.3907 24.8737 31.2839 24.9818Z"
        fill="#7A69EE"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_628_9750"
        x="0"
        y="0.998047"
        width="60"
        height="60"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_628_9750"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_628_9750"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
