<template>
<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.998047" width="64" height="64" rx="16" fill="#7D69FC"/>
<path d="M50 36.898C50 37.798 48.2 38.398 46.1 38.698C44.75 36.148 42.05 34.198 38.9 32.848C39.2 32.398 39.5 32.098 39.8 31.648H41C45.65 31.498 50 34.348 50 36.898ZM24.2 31.498H23C18.35 31.498 14 34.348 14 36.898C14 37.798 15.8 38.398 17.9 38.698C19.25 36.148 21.95 34.198 25.1 32.848L24.2 31.498ZM32 32.998C35.3 32.998 38 30.298 38 26.998C38 23.698 35.3 20.998 32 20.998C28.7 20.998 26 23.698 26 26.998C26 30.298 28.7 32.998 32 32.998ZM32 34.498C25.85 34.498 20 38.398 20 41.998C20 44.998 32 44.998 32 44.998C32 44.998 44 44.998 44 41.998C44 38.398 38.15 34.498 32 34.498ZM40.55 29.998H41C43.55 29.998 45.5 28.048 45.5 25.498C45.5 22.948 43.55 20.998 41 20.998C40.25 20.998 39.65 21.148 39.05 21.448C40.25 22.948 41 24.898 41 26.998C41 28.048 40.85 29.098 40.55 29.998ZM23 29.998H23.45C23.15 29.098 23 28.048 23 26.998C23 24.898 23.75 22.948 24.95 21.448C24.35 21.148 23.75 20.998 23 20.998C20.45 20.998 18.5 22.948 18.5 25.498C18.5 28.048 20.45 29.998 23 29.998Z" fill="white"/>
</svg>


</template>

<script>
export default {

}
</script>

<style>

</style>