<template>
  <div>
    <!-- Conditionally render the layout -->
    <template v-if="shouldRenderLayout">
      <the-landing-layout-vue>
        <router-view></router-view>
      </the-landing-layout-vue>
    </template>
    <!-- If the layout should be excluded, render only the router view -->
    <template v-else>
      <router-view></router-view>
    </template>
  </div>
</template>

<script>
import TheLandingLayoutVue from "./TheLandingLayout.vue";
import { useRoute } from "vue-router";

export default {
  components: {
    TheLandingLayoutVue,
  },
  setup() {
    const route = useRoute();
    const shouldRenderLayout = route.path !== "/all-courses/filters";

    return {
      shouldRenderLayout,
    };
  },
};
</script>

<style scoped>
div {
  width: 100%;
}
</style>
