<template>
  <div>
    <TheTable class="mt-8">
      <TheTableHead>
        <TheTableHeadCell>
          <span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
          >
            {{ $t("name") }}
          </span>
        </TheTableHeadCell>
        <TheTableHeadCell>
          <span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
          >
            {{ $t("course") }}
          </span>
        </TheTableHeadCell>
        <TheTableHeadCell>
          <span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
          >
            {{ $t("progress") }}
          </span>
        </TheTableHeadCell>
        <TheTableHeadCell>
          <span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
          >
            {{ $t("status") }}</span
          >
        </TheTableHeadCell>
        <TheTableHeadCell>
          <span
            class="text-xs leading-4 uppercase font-medium tracking-wider text-gray-500"
            >{{ $t("score") }}</span
          >
        </TheTableHeadCell>
        <TheTableHeadCell />
      </TheTableHead>
      <TheTableBody>
        <template v-for="row of statistics" :key="row.id">
          <TheTableRow>
            <TheTableRowCell>
              <div class="flex gap-4">
                <UserAvatar class="flex-shrink-0" />
                <div>
                  <div class="text-sm leading-5 font-medium text-gray-900">
                    {{ row.user.full_name }}
                  </div>
                  <div class="text-sm leading-5 font-normal text-gray-500">
                    {{ row.user.email }}
                  </div>
                </div>
              </div>
            </TheTableRowCell>
            <TheTableRowCell>
              <div class="text-sm leading-5 font-normal">
                <div class="text-gray-900">{{ row.course_name }}</div>
                <div class="text-gray-500">{{ row.course_specialization }}</div>
              </div>
            </TheTableRowCell>
            <TheTableRowCell class="w-1/5">
              <div class="flex items-center gap-2">
                <div class="flex-1 min-w-[8rem]">
                  <ProgressBar :is_rounded="true" :progress="row.progress" />
                </div>
                <span class="text-sm leading-5 font-normal text-gray-900">
                  {{ row.progress }}%
                </span>
              </div>
            </TheTableRowCell>
            <TheTableRowCell class="w-1/5">
              <div class="flex items-center gap-2">
                <div class="rounded-full w-3 h-3 bg-green-500" />
                <span class="text-sm leading-5 font-medium text-gray-900">
                  {{ row.status }}
                </span>
              </div>
            </TheTableRowCell>
            <TheTableRowCell>
              <span class="text-sm leading-5 font-medium text-gray-900"
                >{{ row.total_score }}%</span
              >
            </TheTableRowCell>
            <TheTableRowCell>
              <DotsVerticalIcon class="h-4 text-gray-500 cursor-pointer" />
            </TheTableRowCell>
          </TheTableRow>
        </template>
      </TheTableBody>
    </TheTable>
  </div>
</template>

<script>
import TheTable from "@/components/TheTable/TheTable";
import TheTableHead from "@/components/TheTable/TheTableHead";
import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell";
import TheTableBody from "@/components/TheTable/TheTableBody";
import TheTableRow from "@/components/TheTable/TheTableRow";
import TheTableRowCell from "@/components/TheTable/TheTableRowCell";
import UserAvatar from "@/components/UserAvatar";
import ProgressBar from "@/components/ProgressBar";
import { DotsVerticalIcon } from "@heroicons/vue/solid";
import { mapActions } from "vuex";
import { GET_GENERAL_STATISTICS } from "@/store/users";

export default {
  components: {
    TheTable,
    TheTableHead,
    TheTableHeadCell,
    TheTableBody,
    TheTableRow,
    TheTableRowCell,
    UserAvatar,
    ProgressBar,
    DotsVerticalIcon,
  },
  data() {
    return {
      statistics: [],
    };
  },
  async mounted() {
    this.statistics = await this[GET_GENERAL_STATISTICS]();
  },
  methods: {
    ...mapActions([GET_GENERAL_STATISTICS]),
  },
};
</script>
