<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "StudentWorkItem",
  data() {
    return {
      hover: false,
    };
  },
  props: {
    studentWork: {
      type: Object,
      default: () => ({
        name: "def",
      }),
    },
  },
});
</script>

<template>
  <div class="relative" @mouseover="hover = true" @mouseleave="hover = false">
    <div class="student-work-box flex items-center justify-center text-white">
      {{ studentWork.name }}
    </div>
    <div
      class="absolute bottom-0 left-0 student-work-gradient"
      v-if="hover"
    ></div>
    <div
      class="flex flex-col gap-1 absolute bottom-0 left-0 py-4 px-6 student-work-text"
      v-if="hover"
    >
      <div class="student-work-title">Название проекта</div>
      <div class="student-work-name">ФИО ученика</div>
    </div>
    <div class="flex flex-col gap-2 py-6 student-work-text__mob">
      <div class="student-work-title">Название проекта</div>
      <div class="student-work-name">ФИО ученика</div>
    </div>
    <div class="student-work-line"></div>
  </div>
</template>

<style scoped>
.student-work-box {
  min-width: 190px;
  max-width: 460px;
  height: 280px;
  border-radius: 24px;
  background: #2b2e3b;
}
.student-work-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: white;
}
.student-work-name {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #e5e7eb;
}

.student-work-gradient {
  background-image: linear-gradient(to bottom, transparent, #2b2e3b);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  width: 100%;
  height: 170px;
}
.student-work-text__mob {
  display: none;
}

@media (max-width: 650px) {
  .students-works {
    padding: 24px 16px;
    gap: 40px;
  }
  .adaptive-buttons-mobile {
    display: none;
  }
  .students-works-box {
    flex-direction: column;
    gap: 16px;
  }
  .student-work-box {
    width: 100%;
  }
  .student-work-gradient {
    display: none;
  }
  .student-work-text {
    display: none;
  }
  .student-work-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 19.2px;
    text-align: left;
    color: black;
  }
  .student-work-name {
    font-size: 16px;
    font-weight: 400;
    line-height: 14.4px;
    text-align: left;
    color: #6b7280;
  }
  .student-work-line {
    height: 1px;
    background: #d1d5db;
  }
  .student-work-text__mob {
    display: flex;
  }
}
</style>
