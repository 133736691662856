<script setup></script>

<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 4.08333C7 3.92862 7.06146 3.78025 7.17085 3.67085C7.28025 3.56146 7.42862 3.5 7.58333 3.5H20.4167C20.5714 3.5 20.7197 3.56146 20.8291 3.67085C20.9385 3.78025 21 3.92862 21 4.08333V4.66667H23.9167C24.0714 4.66667 24.2197 4.72812 24.3291 4.83752C24.4385 4.94692 24.5 5.09529 24.5 5.25V8.75C24.5 9.52355 24.1927 10.2654 23.6457 10.8124C23.0987 11.3594 22.3569 11.6667 21.5833 11.6667H20.6016C20.1846 12.8433 19.461 13.8873 18.5056 14.6907C17.5501 15.494 16.3974 16.0277 15.1667 16.2365V19.8333H18.6667C18.8214 19.8333 18.9697 19.8948 19.0791 20.0042C19.1885 20.1136 19.25 20.262 19.25 20.4167V23.9167C19.25 24.0714 19.1885 24.2197 19.0791 24.3291C18.9697 24.4385 18.8214 24.5 18.6667 24.5H9.33333C9.17862 24.5 9.03025 24.4385 8.92085 24.3291C8.81146 24.2197 8.75 24.0714 8.75 23.9167V20.4167C8.75 20.262 8.81146 20.1136 8.92085 20.0042C9.03025 19.8948 9.17862 19.8333 9.33333 19.8333H12.8333V16.2365C11.6026 16.0277 10.4499 15.494 9.49444 14.6907C8.53897 13.8873 7.81537 12.8433 7.39842 11.6667H6.41667C5.64312 11.6667 4.90125 11.3594 4.35427 10.8124C3.80729 10.2654 3.5 9.52355 3.5 8.75V5.25C3.5 5.09529 3.56146 4.94692 3.67085 4.83752C3.78025 4.72812 3.92862 4.66667 4.08333 4.66667H7V4.08333ZM21 9.33333V5.83333H23.3333V8.75C23.3333 9.21413 23.149 9.65925 22.8208 9.98744C22.4926 10.3156 22.0475 10.5 21.5833 10.5H21V9.33333ZM7 5.83333H4.66667V8.75C4.66667 9.21413 4.85104 9.65925 5.17923 9.98744C5.50742 10.3156 5.95254 10.5 6.41667 10.5H7V9.33333V5.83333Z"
      fill="#425FF8"
    />
  </svg>
</template>

<style scoped></style>
