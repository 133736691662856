const baseUrl = "/api/v1/users";
export default ($axios) => ({
  get_list(params) {
    return $axios.get(`${baseUrl}/`, { params });
  },
  get_course_groups(params) {
    return $axios.get(`${baseUrl}/course_groups`, {
      params,
    });
  },
  delete_user_course_group(user_course_group_id) {
    return $axios.delete(`${baseUrl}/course_groups/${user_course_group_id}`);
  },
  create_user_course_groups(params) {
    return $axios.post(`${baseUrl}/course_groups`, { params });
  },
  create_user_course_groups_file(formData) {
    return $axios.post(`${baseUrl}/course_groups/file`, formData);
  },
  create_roles_file(formData) {
    return $axios.post(`${baseUrl}/roles/upload_excel`, formData);
  },
  create_user_role(params) {
    return $axios.post(`${baseUrl}/roles`, params);
  },
  update_user_role(id, params) {
    return $axios.patch(`${baseUrl}/roles/${id}`, params);
  },
  get_users_next(link) {
    return $axios.get(`${baseUrl}${link}`);
  },
  get_users_roles(params) {
    return $axios.get(`${baseUrl}/roles`, { params });
  },
  get_user_groups(params) {
    return $axios.get(`${baseUrl}/groups`, { params });
  },
  update_user_profile(body) {
    return $axios.put(`${baseUrl}/profile`, body, { responseType: "blob" });
  },
  update_password(body) {
    return $axios.put(`${baseUrl}/profile/update_password`, body);
  },
  delete_user(id) {
    return $axios.delete(`${baseUrl}/roles/${id}`);
  },
  update_from_file(formData) {
    return $axios.post(`${baseUrl}/roles/review_excel`, formData);
  },
  create_users(data) {
    return $axios.post(`${baseUrl}/roles/create_users`, data);
  },
  get_activity_logs(params) {
    return $axios.get(`${baseUrl}/statistics/activity`, { params });
  },
  get_detailed_activity(params) {
    return $axios.get(`${baseUrl}/statistics/activity_detailed`, { params });
  },
  get_notifications_list(params) {
    return $axios.get(`${baseUrl}/notifications`, { params });
  },
  get_notifications_count() {
    return $axios.get(`${baseUrl}/notifications/count`);
  },
  get_general_statistics() {
    return $axios.get(`${baseUrl}/statistics/general_statistics`);
  },
  download_general_statistics() {
    return $axios.get(`${baseUrl}/statistics/general_statistics/download`, {
      responseType: "blob",
    });
  },
  get_login_attempts() {
    return $axios.get(`${baseUrl}/statistics/login_attempts`);
  },
});
