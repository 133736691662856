<template>
  <div>
    <TheLogo class="mb-6" />
    <TheH1 class="mb-2 pt-4">{{ $t(header) }}</TheH1>
    <p
      v-if="hasDescription"
      class="text-sm leading-5 font-normal text-gray-600"
    >
      <slot name="description" />
    </p>
    <p class="mt-2 text-sm text-gray-600" v-if="hasRouteSlot">
      {{ $t("or") }}
      &nbsp;<slot name="router" />
    </p>
  </div>
</template>
<script>
import TheH1 from "@/components/Headers/TheH1";
import TheLogo from "@/assets/images/Logo";
export default {
  components: { TheH1, TheLogo },
  props: {
    header: {
      type: String,
      required: true,
    },
  },
  computed: {
    hasRouteSlot() {
      return !!this.$slots["router"];
    },
    hasDescription() {
      return !!this.$slots["description"];
    },
  },
};
</script>
