<template>
  <ContentBlockForm :content_name="$t('youtube_video')" :_key="_key" :attachments="attachments">
    <InputText  v-model="local_text">
      <template #label>
        <label>{{$t('youtube_url')}}</label>
      </template>
    </InputText>
  </ContentBlockForm>
</template>
<script>
import ContentBlockForm from "@/views/resources/Form/components/ContentBlockForm";
import InputText from "@/components/InputText";
export default {
  components: {ContentBlockForm,InputText},
  props:{
    attachments:[],
    content:{},
    _key:{
      type:String,
      required:true
    }
  },
  computed:{
    local_text:{
      get(){
        return this.content
      },
      set(event){
        const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = event.match(regExp);

        const formatted =  'https://www.youtube.com/embed/'+((match && match[2].length === 11)
            ? match[2]
            : null);
        this.$emit('contentChange',formatted)
      }
    }
  }
}
</script>