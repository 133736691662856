<template>
  <VideoModal
    :close-video-modal="closeVideoModal"
    :show-video-modal="showVideoModal"
    :url-video="urlVideo"
  />
  <div class="student-story-main-block flex flex-col gap-10">
    <h1>Все отзывы</h1>
    <div class="student-stories">
      <StudentStoryCard
        v-for="(story, index) in this.landingFeedbacks"
        :key="index"
        :story="story"
      />
    </div>
    <!--    <div class="flex justify-center">-->
    <!--      <LandingButton type="outline"-->
    <!--        >Показать еще-->
    <!--        <svg-->
    <!--          width="25"-->
    <!--          height="24"-->
    <!--          viewBox="0 0 25 24"-->
    <!--          fill="none"-->
    <!--          xmlns="http://www.w3.org/2000/svg"-->
    <!--        >-->
    <!--          <path-->
    <!--            d="M16.8003 13.1008C16.7088 13.0081 16.5998 12.9346 16.4796 12.8844C16.3595 12.8342 16.2306 12.8083 16.1003 12.8083C15.9701 12.8083 15.8412 12.8342 15.721 12.8844C15.6009 12.9346 15.4919 13.0081 15.4003 13.1008L13.5003 15.0008V5.80078C13.5003 5.25078 13.0503 4.80078 12.5003 4.80078C11.9503 4.80078 11.5003 5.25078 11.5003 5.80078V15.0008L9.60033 13.1008C9.50881 13.0081 9.39981 12.9346 9.27965 12.8844C9.15949 12.8342 9.03056 12.8083 8.90033 12.8083C8.77011 12.8083 8.64118 12.8342 8.52102 12.8844C8.40086 12.9346 8.29186 13.0081 8.20033 13.1008C7.81034 13.4908 7.81034 14.1108 8.20033 14.5008L11.7903 18.0908C11.8828 18.1835 11.9927 18.257 12.1137 18.3072C12.2347 18.3574 12.3644 18.3832 12.4953 18.3832C12.6263 18.3832 12.756 18.3574 12.877 18.3072C12.9979 18.257 13.1078 18.1835 13.2003 18.0908L16.8003 14.5008C16.893 14.4093 16.9665 14.3003 17.0167 14.1801C17.0669 14.0599 17.0928 13.931 17.0928 13.8008C17.0928 13.6706 17.0669 13.5416 17.0167 13.4215C16.9665 13.3013 16.893 13.1923 16.8003 13.1008Z"-->
    <!--            fill="#6759EA"-->
    <!--          />-->
    <!--        </svg>-->
    <!--      </LandingButton>-->
    <!--    </div>-->
  </div>
</template>
<script>
import StudentStoryCard from "@/views/core/Landing/NewLanding/cards/StudentStoryCard.vue";
import VideoModal from "@/views/core/Landing/VideoModal.vue";
import StudentFeedbacksMixin from "@/views/core/Landing/Courses/StudentFeedbacks/StudentFeedbacksMixin";

export default {
  components: { VideoModal, StudentStoryCard },
  mixins: [StudentFeedbacksMixin],
  provide() {
    return {
      openVideoModal: this.openVideoModal,
    };
  },
  methods: {
    openVideoModal(url) {
      this.urlVideo = url;
      this.showVideoModal = true;
    },
    closeVideoModal() {
      this.showVideoModal = false;
    },
  },
  data() {
    return {
      urlVideo: null,
      showVideoModal: false,
      stories: [
        {
          key: 1,
          name: "Балжан Балтабаева",
          position: "Графический и UI/UX дизайн",
          title: "«Изучение красоты и функциональности»",
          content:
            "Студентка курса Графический и UI/UX дизайн рассказывает как мир, где эстетика и удобство взаимодействия играют ключевую роль. ",
          img: "student1.png",
          video: "https://www.youtube.com/embed/vnOONZbh9QA",

          color: "#8286FF",
        },
        {
          key: 2,
          name: "Ерасыл Аюбеков",
          position: "Анализ данных",
          title: "«Открытие новых горизонтов в аналитике»",
          content:
            "Студент курса Анализ данных делится о своем опыте в освоении новейших методов обработки и интерпретации информации, что помогло прогнозировать тренды в различных областях.",
          img: "student2.png",
          video: "https://www.youtube.com/embed/vnOONZbh9QA",

          color: "#B596FF",
        },
        {
          key: 3,
          name: "Айсана Джандигулова",
          position: "Графический и UI/UX дизайн",
          title: "«Погружение в мир пользовательского опыта»",
          content:
            "Студентка курса Графический и UI/UX дизайн делится о своих впечатлениях о курсе:  о том как создавать интерфейсы, которые не только привлекают внимание, но и делают жизнь пользователей проще. ",
          img: "student3.png",
          video: "https://www.youtube.com/embed/vnOONZbh9QA",
          color: "#F481FF",
        },
      ],
    };
  },
};
</script>

<style scoped>
.student-story-main-block {
  background: #f4f5fa;
  padding: 40px 100px;
}
h1 {
  color: #1f2937;
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
}
.student-stories {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 48px 40px;
}

@media (max-width: 650px) {
  .student-stories {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 48px;
  }
  .student-story-main-block {
    padding: 32px 16px;
  }
}
</style>
