<script setup></script>

<template>
  <div class="flex space-x-16 gap-12 adaptive-stats-div">
    <div class="flex flex-col gap-10 basis-half justify-center">
      <div class="basis-half font-extrabold stats-title" style="">
        Количество студентов прошедших наши курсы через B2B партнера (КазГЮУ) по
        специальности IT
      </div>
      <div class="flex gap-4">
        <div class="flex gap-4 adaptive-stats-flex">
          <div class="flex flex-col stats-box basis-half gap-8">
            <div>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="64"
                  height="64"
                  rx="16"
                  fill="#7D69FC"
                  fill-opacity="0.2"
                />
                <rect
                  x="8.3374"
                  y="8.62305"
                  width="46.5455"
                  height="46.5455"
                  rx="16"
                  fill="white"
                />
                <path
                  d="M46 35.0327C46 35.7327 44.6 36.1993 42.9667 36.4327C41.9167 34.4493 39.8167 32.9327 37.3667 31.8827C37.6 31.5327 37.8333 31.2993 38.0667 30.9493H39C42.6167 30.8327 46 33.0493 46 35.0327ZM25.9333 30.8327H25C21.3833 30.8327 18 33.0493 18 35.0327C18 35.7327 19.4 36.1993 21.0333 36.4327C22.0833 34.4493 24.1833 32.9327 26.6333 31.8827L25.9333 30.8327ZM32 31.9993C34.5667 31.9993 36.6667 29.8993 36.6667 27.3327C36.6667 24.766 34.5667 22.666 32 22.666C29.4333 22.666 27.3333 24.766 27.3333 27.3327C27.3333 29.8993 29.4333 31.9993 32 31.9993ZM32 33.166C27.2167 33.166 22.6667 36.1993 22.6667 38.9993C22.6667 41.3327 32 41.3327 32 41.3327C32 41.3327 41.3333 41.3327 41.3333 38.9993C41.3333 36.1993 36.7833 33.166 32 33.166ZM38.65 29.666H39C40.9833 29.666 42.5 28.1493 42.5 26.166C42.5 24.1827 40.9833 22.666 39 22.666C38.4167 22.666 37.95 22.7827 37.4833 23.016C38.4167 24.1827 39 25.6993 39 27.3327C39 28.1493 38.8833 28.966 38.65 29.666ZM25 29.666H25.35C25.1167 28.966 25 28.1493 25 27.3327C25 25.6993 25.5833 24.1827 26.5167 23.016C26.05 22.7827 25.5833 22.666 25 22.666C23.0167 22.666 21.5 24.1827 21.5 26.166C21.5 28.1493 23.0167 29.666 25 29.666Z"
                  fill="#7D69FC"
                />
              </svg>
            </div>
            <div class="flex flex-col gap-4">
              <div class="font-extrabold adaptive-stats-number text-violet">
                1700
              </div>
              <div class="font-extrabold adaptive-stats-text">
                Всего Студентов прошедших наши курсы у партнера университета
              </div>
            </div>
          </div>
          <div class="flex flex-col stats-box basis-half gap-8">
            <div>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="64"
                  height="64"
                  rx="16"
                  fill="#7D69FC"
                  fill-opacity="0.2"
                />
                <rect
                  x="8.3374"
                  y="8.62305"
                  width="46.5455"
                  height="46.5455"
                  rx="16"
                  fill="white"
                />
                <path
                  d="M32 32.0008L21.556 27.8288L22.2 43.2008H19.4L20.072 27.2268L18 26.4008L32 20.8008L46 26.4008L32 32.0008ZM32 25.0008C31.23 25.0008 30.6 25.3088 30.6 25.7008C30.6 26.0928 31.23 26.4008 32 26.4008C32.77 26.4008 33.4 26.0928 33.4 25.7008C33.4 25.3088 32.77 25.0008 32 25.0008ZM32 33.4008L39.798 30.2788C40.792 31.5948 41.478 33.1768 41.702 34.8988C41.282 34.8428 40.848 34.8008 40.4 34.8008C36.83 34.8008 33.708 36.7188 32 39.5748C31.133 38.1198 29.9031 36.915 28.4307 36.0781C26.9582 35.2413 25.2937 34.8011 23.6 34.8008C23.152 34.8008 22.718 34.8428 22.298 34.8988C22.522 33.1768 23.208 31.5948 24.202 30.2788L32 33.4008Z"
                  fill="#7D69FC"
                />
              </svg>
            </div>
            <div class="flex flex-col gap-3">
              <div class="font-extrabold adaptive-stats-number text-violet">
                17
              </div>
              <div class="font-extrabold adaptive-stats-text">
                Различных курсов пройдено
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-4 basis-half justify-center">
      <div class="basis-half font-extrabold stats-title" style="">
        Количество студентов прошедших наши внутренние курсы (для физ.лиц)
      </div>
      <div class="flex gap-4">
        <div class="flex gap-4 adaptive-stats-flex">
          <div class="flex flex-col stats-box basis-half gap-8">
            <div>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="64"
                  height="64"
                  rx="16"
                  fill="#FBA564"
                  fill-opacity="0.2"
                />
                <rect
                  x="8.3374"
                  y="8.62305"
                  width="46.5455"
                  height="46.5455"
                  rx="16"
                  fill="white"
                />
                <path
                  d="M46 35.0327C46 35.7327 44.6 36.1993 42.9667 36.4327C41.9167 34.4493 39.8167 32.9327 37.3667 31.8827C37.6 31.5327 37.8333 31.2993 38.0667 30.9493H39C42.6167 30.8327 46 33.0493 46 35.0327ZM25.9333 30.8327H25C21.3833 30.8327 18 33.0493 18 35.0327C18 35.7327 19.4 36.1993 21.0333 36.4327C22.0833 34.4493 24.1833 32.9327 26.6333 31.8827L25.9333 30.8327ZM32 31.9993C34.5667 31.9993 36.6667 29.8993 36.6667 27.3327C36.6667 24.766 34.5667 22.666 32 22.666C29.4333 22.666 27.3333 24.766 27.3333 27.3327C27.3333 29.8993 29.4333 31.9993 32 31.9993ZM32 33.166C27.2167 33.166 22.6667 36.1993 22.6667 38.9993C22.6667 41.3327 32 41.3327 32 41.3327C32 41.3327 41.3333 41.3327 41.3333 38.9993C41.3333 36.1993 36.7833 33.166 32 33.166ZM38.65 29.666H39C40.9833 29.666 42.5 28.1493 42.5 26.166C42.5 24.1827 40.9833 22.666 39 22.666C38.4167 22.666 37.95 22.7827 37.4833 23.016C38.4167 24.1827 39 25.6993 39 27.3327C39 28.1493 38.8833 28.966 38.65 29.666ZM25 29.666H25.35C25.1167 28.966 25 28.1493 25 27.3327C25 25.6993 25.5833 24.1827 26.5167 23.016C26.05 22.7827 25.5833 22.666 25 22.666C23.0167 22.666 21.5 24.1827 21.5 26.166C21.5 28.1493 23.0167 29.666 25 29.666Z"
                  fill="#FBA564"
                />
              </svg>
            </div>
            <div class="flex flex-col gap-4">
              <div class="font-extrabold adaptive-stats-number text-orange">
                125
              </div>
              <div class="font-extrabold adaptive-stats-text">
                Всего Студентов прошедших наши внутренние курсы
              </div>
            </div>
          </div>
          <div class="flex flex-col stats-box basis-half gap-8">
            <div>
              <svg
                width="64"
                height="64"
                viewBox="0 0 64 64"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="64"
                  height="64"
                  rx="16"
                  fill="#FBA564"
                  fill-opacity="0.2"
                />
                <rect
                  x="8.3374"
                  y="8.62305"
                  width="46.5455"
                  height="46.5455"
                  rx="16"
                  fill="white"
                />
                <path
                  d="M32 32.0008L21.556 27.8288L22.2 43.2008H19.4L20.072 27.2268L18 26.4008L32 20.8008L46 26.4008L32 32.0008ZM32 25.0008C31.23 25.0008 30.6 25.3088 30.6 25.7008C30.6 26.0928 31.23 26.4008 32 26.4008C32.77 26.4008 33.4 26.0928 33.4 25.7008C33.4 25.3088 32.77 25.0008 32 25.0008ZM32 33.4008L39.798 30.2788C40.792 31.5948 41.478 33.1768 41.702 34.8988C41.282 34.8428 40.848 34.8008 40.4 34.8008C36.83 34.8008 33.708 36.7188 32 39.5748C31.133 38.1198 29.9031 36.915 28.4307 36.0781C26.9582 35.2413 25.2937 34.8011 23.6 34.8008C23.152 34.8008 22.718 34.8428 22.298 34.8988C22.522 33.1768 23.208 31.5948 24.202 30.2788L32 33.4008Z"
                  fill="#FBA564"
                />
              </svg>
            </div>
            <div class="flex flex-col gap-3">
              <div class="font-extrabold adaptive-stats-number text-orange">
                9
              </div>
              <div class="font-extrabold adaptive-stats-text">
                Различных курсов пройдено
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-card {
  padding: 100px;
}
.stats-title-box {
  border-radius: 40px;
  background: #06172b;
  padding: 40px;
}
.underscore {
  border-bottom: 1.5px solid #5b4ccc;
  margin-left: 64px;
}

.underscore:first-child {
  margin-left: 0px;
}

.basis-half {
  flex-basis: 50%;
}
.adaptive-gradient {
  background: linear-gradient(67deg, #cc3fd3 0%, #8453e8 45.83%, #525cf3 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 15px;
}

.stats-title {
  color: #1f2937;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.adaptive-stats-number {
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
}

.text-violet {
  color: #6759ea;
}
.text-orange {
  color: #fba564;
}

.adaptive-stats-text {
  color: #6b7280;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.stats-box {
  border-radius: 32px;
  background: #f4f5fa;
  padding: 24px;
}

@media (max-width: 650px) {
  .adaptive-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    padding: 0;
  }
  .adaptive-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 110%;
  }
  .adaptive-gradient {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }

  .stats-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 110%;
  }

  .custom-card {
    padding: 56px 16px;
  }
  .adaptive-stats-number {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .adaptive-stats-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-stats-div {
    flex-direction: column;
  }
  .adaptive-stats-flex {
    flex-direction: column;
  }
}
</style>
