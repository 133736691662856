<script>
import { defineComponent } from "vue";
import TheModal from "@/components/TheModal/TheModal.vue";
import PurpleAdminButton from "@/views/certificates/PurpleAdminButton.vue";
import InputLabel from "@/components/InputLabel.vue";

export default defineComponent({
  name: "TaskPercentageAddModal",
  components: { InputLabel, PurpleAdminButton, TheModal },
  props: ["isOpen"],
  data() {
    return {
      name: "",
      percentage: "",
      nameError: "",
      weightError: "",
    };
  },
  methods: {
    validatePercentage() {
      if (this.percentage < 0) this.percentage = 0;
      if (this.percentage > 100) this.percentage = 100;
    },
    handleClose() {
      this.$emit("close");
    },
    handleClick() {
      if (this.name === "") {
        this.nameError = "Заполните поле";
        return;
      }
      if (this.percentage === "") {
        this.nameError = "Заполните поле";
        return;
      }
      this.$emit("addCategory", {
        name: this.name,
        weight: this.percentage,
      });
      this.handleClose();
      this.name = "";
      this.percentage = null;
    },
  },
});
</script>

<template>
  <TheModal :show="isOpen" @close="handleClose">
    <div class="flex flex-col gap-10 p-6">
      <div class="flex justify-between">
        <div class="task-perc__title">Добавить категорию</div>
        <div class="cursor-pointer" @click="handleClose">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6L18 18"
              stroke="#9CA3AF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="flex gap-6">
        <div class="flex-1">
          <input-label>Название категории </input-label>
          <input v-model="name" placeholder="Введите название" type="text" />
          <div class="error-msg">{{ nameError }}</div>
        </div>
        <div class="flex-1">
          <input-label>Весовой коэффицент заданий </input-label>
          <input
            type="number"
            id="percentage"
            placeholder="0%"
            v-model.number="percentage"
            @input="validatePercentage"
            min="0"
            max="100"
            step="1"
          />
          <div class="error-msg">{{ weightError }}</div>
        </div>
      </div>
      <div class="flex justify-end">
        <PurpleAdminButton @click="handleClick"
          >Добавить категорию</PurpleAdminButton
        >
      </div>
    </div></TheModal
  >
</template>

<style scoped>
.task-perc__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  color: #111827;
}
input {
  border: solid 1px #d1d5db;
  border-radius: 6px;
  padding: 8px 12px;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

input:focus {
  box-shadow: none; /* Optional: removes any shadow */
}

input-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #374151;
}

.error-msg {
  padding-top: 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
  color: #ef4444;
}
</style>
