export const GET_MAILINGS = 'GET_MAILINGS';
export const SET_MAILINGS = 'SET_MAILINGS';
export const SAVE_MAILING = 'SAVE_MAILING';
export const SET_MAILING = 'SET_MAILING';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const DELETE_MAILING_ATTACHMENT = 'DELETE_MAILING_ATTACHMENT';
export const SET_LOADING = 'SET_LOADING';

const createStore = (app) => {
	const { $api } = app.config.globalProperties
	const state = {
		mailings: [],
		loading: false
	};
	const actions = {
		[GET_MAILINGS]: ({ commit }) => {
			$api.mailings.getMailings().then(({ data }) => {
				commit(SET_MAILINGS, data);
			});
		},
		[SAVE_MAILING]: ({ commit }, mailing) => {
			$api.mailings.editMailing(mailing).then(() => {
				commit(SET_MAILING, mailing);
			});
		},
		[ADD_ATTACHMENT]: ({ commit }, mailing) => {
			commit(SET_LOADING, true);
			const file = mailing.attachments[mailing.attachments.length - 1];
			const formData = new FormData();
			formData.append('name', file.name);
			formData.append('mailing', mailing.id);
			formData.append('file', file);
			return (
				$api.mailings.addAttachment(formData)
					.then(({ data }) => {
						commit(SET_MAILING, { ...mailing });
						commit(SET_LOADING, false);
						return data;
					})
			);
		},
		[DELETE_MAILING_ATTACHMENT]: ({ commit }, { mailing, fileId }) => {
			$api.mailings.deleteAttachment(fileId)
				.then(() => {
					commit(SET_MAILING, { ...mailing, attachments: mailing.attachments.filter(attachment => attachment.id !== fileId) });
				})
				.catch(err => console.log(err));
		},
	};
	const mutations = {
		[SET_MAILINGS]: (store, payload) => {
			store.mailings = payload;
		},
		[SET_MAILING]: (store, payload) => {
			store.mailings = store.mailings.map(mailing => mailing.id === payload.id ? { ...payload } : mailing);
		},
		[SET_LOADING]: (store, payload) => {
			store.loading = payload;
		},
	};

	return { state, actions, mutations };
};

export default app => createStore(app);
