import {mapActions, mapGetters} from "vuex";
import { GET_PUBLIC_COURSES} from "@/store/courses";
import {mapFields} from "vuex-map-fields";

export default {
    computed:{
        ...mapGetters(['active_user_has_permission']),
        ...mapFields(['courses.courses'])
    },
    methods:{
        ...mapActions([GET_PUBLIC_COURSES])
    },
    mounted() {
        this[GET_PUBLIC_COURSES](this.filters)
    }
}