//todo logic for store work
import {SET_LOADING} from "@/store/core";
export const GET_COURSES="GET_COURSES"
export const SET_COURSES="SET_COURSES"
export const GET_PUBLIC_COURSES="GET_PUBLIC_COURSES";
export const GET_COURSE="GET_COURSE"
export const SET_COURSE="SET_COURSE"
export const DELETE_COURSE = 'DELETE_COURSE';
export const SET_ACTIVE_COURSE_ID="SET_ACTIVE_COURSE_ID"
export const START_COURSE_REQUEST="START_COURSE_REQUEST"
export const SAVE_COURSE="SAVE_COURSE"
export const GET_COURSES_SELECT="GET_COURSES_SELECT"
export const COPY_COURSE = "COPY_COURSE"
export const GET_PUBLISHED_COURSES = 'GET_PUBLISHED_COURSES';
export const GET_PUBLISHED_COURSE = 'GET_PUBLISHED_COURSE';
export const PUBLISH_COURSES = 'PUBLISH_COURSES';
export const UNPUBLISH_COURSES = 'UNPUBLISH_COURSES';
export const SET_GROUP = "SET_GROUP";
export const GET_COURSE_GROUP = "GET_COURSE_GROUP";
export const CHANGE_GROUP_NUMBER = "CHANGE_GROUP_NUMBER";
export const DELETE_GROUP = "DELETE_GROUP"
const createStore =  app => {
    const {$api} = app.config.globalProperties
    const state={
        courses:[],
        course:{},
        active_course_id:'',
        form:{
            overview:{},
        },
        copy_modal_open: false,
        group:{}
    }
    const actions={
        [GET_COURSES_SELECT]: async({commit})=>{
            commit(SET_LOADING,true)
            const {data} = await $api.courses.get_courses_select()
            commit(SET_LOADING,false)
            return data;
        },
        [GET_COURSES]:async ({commit},payload={})=>{
            commit(SET_LOADING,true)
            const {data} = await $api.courses.get_list(payload)
            commit(SET_COURSES,data)
            commit(SET_LOADING,false)
            return data;
        },
        [GET_PUBLIC_COURSES]:async({commit},payload={})=>{
            commit(SET_LOADING,true)
            const {data} = await $api.courses.get_public_list(payload)
            commit(SET_COURSES,data)
            commit(SET_LOADING,false)
            return data;
        },
        [SAVE_COURSE]:({commit},form)=>{
            commit(SET_LOADING,true)
            const formData = Object.keys(form).reduce((formData,key)=>{
                if(key === "specializations"  ||key=='curator_ids'){
                    form[key].map(el=>{
                        formData.append(`${key}[]`,el)
                    })
                }
                else if(key === "goals" || key === "perks" || key === 'instructors'){
                    form[key].map(el=>{
                        formData.append(`${key}[]`,JSON.stringify(el))
                    })
                }
                else if (key==="description" ||key==="overview") {
                    formData.append(key,JSON.stringify(form[key]))
                }else{
                    formData.append(key,form[key])
                }
                return formData
            }, new FormData())
            if(form.id){
                return $api.courses.update(form.id,formData)
            }else{
                return $api.courses.store(formData)
            }
        },
        [GET_COURSE]:async ({commit},id)=>{
            commit(SET_LOADING,true)
            const {data} = await  $api.courses.get_item(id)
            commit(SET_COURSE,data)
            commit(SET_LOADING,false)
            return data
        },
        [DELETE_COURSE]: async ({ commit }, id) => {
            commit(SET_LOADING, true);
            await $api.courses.delete(id);
            commit(SET_LOADING, false);
        },
        [START_COURSE_REQUEST]:(store,id)=>{
            return $api.courses.start(id)
        },
        [COPY_COURSE]: async ({ commit }, { from, to }) => {
            commit(SET_LOADING, true);
            return $api.courses.copy({ from_course_id: from, to_course_id: to }).then(() => commit(SET_LOADING, false));
        },
        [GET_PUBLISHED_COURSES]: async ({ commit }) => {
            commit(SET_LOADING, true);
            const { data } = await $api.courses.get_published_list();
            commit(SET_LOADING, false);
            return data;
        },
        [GET_PUBLISHED_COURSE]: async ({ commit }, id) => {
            commit(SET_LOADING, true);
            const { data } = await $api.courses.get_published_course(id);
            commit(SET_LOADING, false);
            return data;
        },
        [UNPUBLISH_COURSES]: async ({ commit }, courseIds) => {
            commit(SET_LOADING, true);
            await $api.courses.unpublish_courses({ courseIds })
            commit(SET_LOADING, false);
        },
        [PUBLISH_COURSES]: async ({ commit }, courseIds) => {
            commit(SET_LOADING, true);
            await $api.courses.publish_courses({ courseIds })
            commit(SET_LOADING, false);
        },
        [GET_COURSE_GROUP]: async({commit}, id)=>{
            commit(SET_LOADING, true);
            const { data } = await $api.courses.get_course_group(id);
            commit(SET_GROUP, data);
            commit(SET_LOADING, false);
            return data;
        },
        [CHANGE_GROUP_NUMBER]: (store, payload) => {
            return $api.courses.change_group_number(payload);
        },
        [DELETE_GROUP]:(store, id) => {
          return $api.courses.delete_group_number(id)
        },
    }
    const mutations={
        [SET_ACTIVE_COURSE_ID]:(state,id)=>{
            state.active_course_id=id
        },
        [SET_COURSES]:(state,payload)=>{
            state.courses = payload
        },
        [SET_COURSE]:(state,payload)=>{
            state.course = payload
        },
        [SET_GROUP]: (store, payload) => {
            store.group = payload;
        },
    }
    return {
        actions,
        state,
        mutations,
    }
}
export default app=>createStore(app)