import { mapActions } from "vuex";
import { mapFields } from "vuex-map-fields";
import { GET_COURSE_GROUP, GET_COURSE } from "@/store/courses";
import { GET_COURSE_GROUPS, GET_USERS_NEXT } from "@/store/users";
import { CREATE_BULK_CERTIFICATES } from "@/store/certificates";
import {
  parseISO,
  differenceInDays,
  differenceInMonths,
  differenceInYears,
} from "date-fns";
import StudentsFetchMixin from "@/views/certificates/StudentsFetchMixin";

export default {
  mixins: [StudentsFetchMixin],
  props: {
    group_number: String,
    start_date: String,
    end_date: String,
    addMode: Boolean,
  },
  data: () => {
    return {
      tutors: [],
      usersToAdd: [],
      usersFile: null,
      search_new_users: "",
      users_notif: false,
      role: "student",
      duration: null,
      duration_unit: "",
      course_error: "",
      group_error: "",
      certificate_error: "",
      duration_error: "",
      duration_unit_error: "",
      students_error: "",
      loading: false,
      course: null,
    };
  },
  computed: {
    ...mapFields(["courses.group", "courses.course"]),

    selectAll: {
      get: function () {
        return this.users.every((r) => this.usersToAdd.includes(r.id));
      },
      set: function (value) {
        var selected = [];

        if (value) {
          this.users.forEach(function (user) {
            selected.push(user.id);
          });
        }

        this.usersToAdd = selected;
      },
    },
  },

  methods: {
    ...mapActions([
      GET_COURSE,
      GET_COURSE_GROUP,
      GET_USERS_NEXT,
      GET_COURSE_GROUPS,
    ]),

    calculateDuration() {
      const startDate = parseISO(this.course.start_date);
      const endDate = parseISO(this.course.end_date);
      const durationInDays = differenceInDays(endDate, startDate);
      const durationInMonths = differenceInMonths(endDate, startDate);
      const durationInYears = differenceInYears(endDate, startDate);

      if (durationInYears > 0) {
        return {
          duration: durationInYears,
          duration_unit: durationInYears === 1 ? "год" : "года",
        };
      } else if (durationInMonths > 0) {
        return {
          duration: durationInMonths,
          duration_unit:
            durationInMonths <= 4
              ? durationInMonths === 1
                ? "месяц"
                : "месяца"
              : "месяцев",
        };
      } else {
        return {
          duration: durationInDays,
          duration_unit: durationInDays === 1 ? "день" : "дней",
        };
      }
    },
    retrieveDuration() {
      const { duration } = this.calculateDuration();
      return duration;
    },

    retrieveDurationUnit() {
      const { duration_unit } = this.calculateDuration();
      return duration_unit;
    },
    validateData(students) {
      if (!this.course_id) {
        this.course_error = "Выберите курс";
        return false;
      }
      if (!this.group_id) {
        this.group_error = "Выберите группу";
        return false;
      }
      if (!this.certificate_type_id) {
        this.certificate_error = "Выберите тип сертификата";
        return false;
      }
      if (!this.duration) {
        this.duration_error = "Выберите duration";
        return false;
      }
      if (!this.duration_unit) {
        this.duration_unit_error = "Выберите duration unit";
        return false;
      }

      if (!students.length) {
        this.students_error = "Выберите студентов";

        return false;
      }
      return true;
    },
    async generateCet() {
      const students_emails = this.students
        .filter((st) => st.selected)
        .map((st) => st.email);

      if (!this.validateData(students_emails)) {
        return;
      }
      const params = {
        course: this.course_id,
        type: this.certificate_type_id,
        duration: this.duration,
        duration_unit: this.duration_unit,
        issue_date: new Date(),
        is_visible: true,
        user_emails: students_emails,
      };
      this.loading = true;
      try {
        await this.$store.dispatch(CREATE_BULK_CERTIFICATES, params);
        this.loading = false;
        this.$router.push({
          name: "CertificateTemp",
          query: {
            courseName: this.course_name,
            success: true,
          },
        });
      } catch (err) {
        this.$router.push({
          name: "CertificateTemp",
          query: {
            courseName: this.course_name,
            success: true,
          },
        });
      }
    },

    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
  watch: {
    async course_id(newVal) {
      if (newVal !== null) {
        await this.$store.dispatch(GET_COURSE, this.course_id).then((res) => {
          this.course = res;
          this.duration = this.retrieveDuration();
          this.duration_unit = this.retrieveDurationUnit();
        });
      }
    },

    async search(newVal) {
      await this.getUsers("student", newVal).then((res) => {
        this.students = res.results.map((r) => ({
          ...r.user,
          selected: false,
          top_student: false,
        }));
      });
    },
  },
};
