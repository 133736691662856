import Auth from "@/api/auth";
import Courses from "@/api/courses";
import Core from "@/api/core";
import Resources from "@/api/resources";
import Users from "@/api/users";
import Specializations from "@/api/specializations";
import Mailings from "@/api/mailings";
import News from "@/api/news";
import Certificates from "@/api/certificates";
import LandingCourses from "@/api/landing-courses";
import LandingForm from "@/api/landing-form";
import LandingTeachers from "@/api/landing-teachers";
import LandingFeedback from "@/api/landing-feedback";
import Attendance from "@/api/attendance";
import CourseTaskCategories from "@/api/course-task-categories";
import StudentUsers from "@/api/student-users";
import StudentGrades from "@/api/student-grades";
const logout = () => {
  localStorage.removeItem("refresh");
  localStorage.removeItem("token");
  window.location = "/auth/login";
};
export default (app) => {
  const { $axios } = app.config.globalProperties;
  const token = localStorage.getItem("token");
  if (token) {
    $axios.addToken(token);
  }
  const repositories = {
    auth: Auth($axios),
    courses: Courses($axios),
    core: Core($axios),
    resources: Resources($axios),
    users: Users($axios),
    specializations: Specializations($axios),
    mailings: Mailings($axios),
    news: News($axios),
    certificates: Certificates($axios),
    landing_courses: LandingCourses($axios),
    landing_form: LandingForm($axios),
    landing_teachers: LandingTeachers($axios),
    landing_feedbacks: LandingFeedback($axios),
    attendance: Attendance($axios),
    course_task_categories: CourseTaskCategories($axios),
    student_users: StudentUsers($axios),
    student_grades: StudentGrades($axios),
  };
  app.config.globalProperties.$api = repositories;
  $axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (
        error.response.status === 401 &&
        originalRequest.url === "/api/v1/auth/refresh/"
      ) {
        logout();
        return Promise.reject(error);
      } else if (
        error.response.status === 401 &&
        !originalRequest._retry &&
        originalRequest.url !== "/api/v1/auth/token/"
      ) {
        originalRequest._retry = true;
        const old_refresh = localStorage.getItem("refresh");
        if (!old_refresh) {
          logout();
          return Promise.reject(error);
        }
        try {
          const { data } = await repositories.auth.refresh(old_refresh);
          localStorage.setItem("token", data.access);
          originalRequest.headers.Authorization = $axios.addToken(data.access);
        } catch (e) {
          console.log(e);
        }
        return $axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );
  window.$api = repositories;
};
