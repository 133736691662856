<script setup></script>

<template>
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 13.9988L3.556 9.82683L4.2 25.1988L1.4 25.1988L2.072 9.22483L-4.35221e-08 8.39883L14 2.79883L28 8.39883L14 13.9988ZM14 6.99883C13.23 6.99883 12.6 7.30683 12.6 7.69883C12.6 8.09083 13.23 8.39883 14 8.39883C14.77 8.39883 15.4 8.09083 15.4 7.69883C15.4 7.30683 14.77 6.99883 14 6.99883ZM14 15.3988L21.798 12.2768C22.792 13.5928 23.478 15.1748 23.702 16.8968C23.282 16.8408 22.848 16.7988 22.4 16.7988C18.83 16.7988 15.708 18.7168 14 21.5728C13.133 20.1179 11.9031 18.913 10.4307 18.0761C8.9582 17.2393 7.29366 16.7992 5.6 16.7988C5.152 16.7988 4.718 16.8408 4.298 16.8968C4.522 15.1748 5.208 13.5928 6.202 12.2768L14 15.3988Z"
      fill="#40D39F"
    />
  </svg>
</template>

<style scoped></style>
