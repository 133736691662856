import { SET_LOADING } from "@/store/core";

export const GET_LANDING_FEEDBACKS = "GET_LANDING_FEEDBACKS";
export const SET_LANDING_FEEDBACKS = "SET_LANDING_FEEDBACKS";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {
    landing_feedbacks: [],
  };
  const actions = {
    [GET_LANDING_FEEDBACKS]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.landing_feedbacks.getFeedbackList();
      commit(SET_LANDING_FEEDBACKS, data);
      commit(SET_LOADING, false);
      return data;
    },
  };
  const mutations = {
    [SET_LANDING_FEEDBACKS]: (state, payload) => {
      state.landing_feedbacks = payload;
    },
  };
  const getters = {
    landing_feedbacks: (state) => state.landing_feedbacks,
  };
  return {
    actions,
    state,
    mutations,
    getters,
  };
};
export default (app) => createStore(app);
