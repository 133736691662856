<script setup></script>

<template>
  <svg
    shallowRef
    width="113"
    height="133"
    viewBox="0 0 113 133"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.4279 74.3428C23.6966 72.8015 24.3036 71.3389 25.2054 70.0603C26.7054 68.0953 28.0104 67.1653 29.2629 66.8803C31.1679 66.4453 32.8704 67.4653 34.6479 68.3053C36.9429 69.3853 39.3279 70.2253 42.1479 68.4928L41.4879 67.4203C40.7838 67.8663 39.9823 68.1354 39.1517 68.2046C38.3211 68.2739 37.4861 68.1411 36.7179 67.8178C35.6454 67.4428 34.6179 66.8803 33.5829 66.4153C32.2283 65.6911 30.6806 65.4104 29.1579 65.6128C27.6579 65.8978 25.9929 66.9103 24.1854 69.3028C23.1745 70.7138 22.4927 72.3335 22.1904 74.0428L23.4279 74.3428Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.6759 115.127C39.4187 114.083 40.4436 113.271 41.6309 112.787C44.0309 111.91 46.5284 113.117 49.0859 114.497C50.9933 115.648 53.0016 116.622 55.0859 117.407C56.1234 117.805 57.2378 117.962 58.3447 117.865C59.4517 117.769 60.5223 117.423 61.4759 116.852L60.8234 115.772C60.0205 116.245 59.1209 116.53 58.1921 116.606C57.2633 116.681 56.3295 116.545 55.4609 116.207C53.4569 115.441 51.5245 114.5 49.6859 113.395C46.7909 111.827 43.9334 110.605 41.2034 111.602C39.7968 112.17 38.5797 113.124 37.6934 114.355L38.6759 115.127Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M89.0381 79.0678C90.8231 79.8178 91.6406 81.3178 92.1206 83.0353C92.6606 84.9028 92.7956 86.9653 93.1706 88.6303C93.5231 90.1753 94.1081 91.3903 95.1881 91.9678C96.1452 92.4116 97.2332 92.4838 98.2406 92.1703L97.9481 90.9478C97.2702 91.1664 96.5384 91.1506 95.8706 90.9028C95.5898 90.749 95.3428 90.5403 95.1443 90.289C94.9459 90.0377 94.8001 89.7491 94.7156 89.4403C94.416 88.52 94.2051 87.5732 94.0856 86.6128C93.8987 84.95 93.5392 83.3112 93.0131 81.7228C92.7303 80.8795 92.2747 80.1045 91.6755 79.4472C91.0763 78.7899 90.3467 78.2647 89.5331 77.9053L89.0381 79.0678Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M90.2679 74.8381C92.5599 74.509 94.8979 74.7671 97.0629 75.5881L97.4454 74.3881C95.0994 73.5058 92.5675 73.2348 90.0879 73.6006L90.2679 74.8381Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.7233 12.8429C69.0808 10.0679 71.1433 9.32536 73.5208 9.35536C75.3556 9.45998 77.1777 9.726 78.9658 10.1504C84.0358 11.1479 89.2333 11.8904 92.5183 5.90535L91.4233 5.29785C88.4758 10.6679 83.7508 9.79785 79.2058 8.91285C77.343 8.4771 75.4459 8.20358 73.5358 8.09535C70.6858 8.09535 68.2183 8.96535 66.5908 12.2878L67.7233 12.8429Z"
      fill="#279FF2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.4454 78.5428C29.9454 76.6003 31.9254 76.0678 33.9954 75.6478C36.3129 75.1678 38.6979 74.8153 40.6479 73.2403L39.8529 72.2578C38.0679 73.7053 35.8629 73.9753 33.7404 74.4103C31.3779 74.8978 29.1054 75.5653 27.4404 77.7928L28.4454 78.5428Z"
      fill="#A14DF6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M45.3506 117.453C49.1456 120.618 51.9506 122.928 57.0581 121.165L56.6456 119.973C52.0481 121.563 49.5731 119.335 46.1456 116.485L45.3506 117.453Z"
      fill="#A14DF6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M64.5131 11.0802C65.8706 7.78766 68.3756 6.48267 71.1806 5.52266C74.1806 4.50266 77.5781 3.86516 80.4206 1.96016L79.7231 0.910156C76.9706 2.75516 73.7231 3.34016 70.7756 4.33766C67.6331 5.40266 64.8656 6.91766 63.3506 10.5927L64.5131 11.0802Z"
      fill="#A14DF6"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M103.258 58.0078C101.983 59.5078 100.731 60.9478 99.373 62.3728L100.288 63.2428C101.661 61.7953 102.928 60.3403 104.218 58.8253L103.258 58.0078Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M98.3232 59.4552C100.341 60.2652 102.261 62.0577 103.971 63.4602L104.766 62.4927C102.973 60.9927 100.918 59.1402 98.7657 58.2852L98.3232 59.4552Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.0679 42.3623C22.3554 45.8798 21.3579 49.2848 20.5479 52.7648L21.7704 53.0498C22.5879 49.5623 23.5929 46.1423 24.2979 42.6098L23.0679 42.3623Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.4932 45.7224C21.3432 46.9599 23.7432 48.8274 26.4807 50.2674L27.0732 49.1499C24.3132 47.6949 21.8682 45.8199 18.9957 44.5674L18.4932 45.7224Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.3955 43.8407C21.9263 46.2836 22.6178 48.6888 23.4655 51.0407L24.6355 50.5757C23.8107 48.2739 23.1343 45.9216 22.6105 43.5332L21.3955 43.8407Z"
      fill="#F56C2C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.0979 49.278L26.0979 46.503L25.5654 45.3555L19.5654 48.138L20.0979 49.278Z"
      fill="#F56C2C"
    />
    <path d="M99.2752 15.6025H30.5527V28.645H99.2752V15.6025Z" fill="#D9DBE5" />
    <path
      d="M36.5752 14.0935L24.3047 17.3584L29.5348 37.0145L41.8053 33.7496L36.5752 14.0935Z"
      fill="#919191"
    />
    <path
      d="M99.2927 15.579L64.1357 71.5156L75.1783 78.456L110.335 22.5194L99.2927 15.579Z"
      fill="#C2C4CF"
    />
    <path
      d="M30.5542 15.5867L19.873 23.0713L57.7867 77.1774L68.4679 69.6928L30.5542 15.5867Z"
      fill="#C2C4CF"
    />
    <path
      d="M65.0306 109.938C77.5564 109.938 87.7106 99.784 87.7106 87.2581C87.7106 74.7323 77.5564 64.5781 65.0306 64.5781C52.5048 64.5781 42.3506 74.7323 42.3506 87.2581C42.3506 99.784 52.5048 109.938 65.0306 109.938Z"
      fill="black"
    />
    <path
      d="M61.3636 109.938C73.8894 109.938 84.0436 99.784 84.0436 87.2581C84.0436 74.7323 73.8894 64.5781 61.3636 64.5781C48.8378 64.5781 38.6836 74.7323 38.6836 87.2581C38.6836 99.784 48.8378 109.938 61.3636 109.938Z"
      fill="#FFD483"
    />
    <path
      d="M78.5834 47.0426L77.2334 49.3809L90.555 57.0721L91.905 54.7339L78.5834 47.0426Z"
      fill="#353848"
    />
    <path
      d="M53.2382 46.9546L41.7949 55.9531L43.4639 58.0755L54.9071 49.077L53.2382 46.9546Z"
      fill="#353848"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M61.6111 72.2578L65.1211 83.0503H76.4686L67.2886 89.7253L70.7911 100.518L61.6111 93.8503L52.4236 100.518L55.9336 89.7253L46.7236 83.0578H58.0711L61.6111 72.2578Z"
      fill="#CC9835"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M73.7759 106.713C66.1484 109.9 57.7484 110.463 52.0259 107.56C48.6584 105.858 46.0784 103 43.8134 100.06C40.464 95.6968 38.9128 90.2165 39.4784 84.745L38.2259 84.6025C37.6281 90.4001 39.2703 96.2068 42.8159 100.833C45.1934 103.9 47.9234 106.885 51.4559 108.67C57.4559 111.715 66.2759 111.198 74.2709 107.86L73.7759 106.713Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M55.7228 89.8975C54.3279 92.789 53.1082 95.7619 52.0703 98.8L53.2478 99.2425C54.2657 96.252 55.4654 93.3265 56.8403 90.4825L55.7228 89.8975Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M61.8203 94.1358C64.2203 96.1608 66.7553 97.9533 69.2078 99.8883L69.9578 98.8983C67.5128 96.9783 64.9928 95.1858 62.6003 93.1758L61.8203 94.1358Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M67.776 89.6054C70.2885 87.8804 72.801 86.1104 75.4035 84.5129L74.7435 83.4404C72.126 85.0454 69.5985 86.8304 67.0635 88.5629L67.776 89.6054Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M48.3809 84.3181C50.9721 86.2198 53.4606 88.2577 55.8358 90.4231L56.7134 89.5231C54.3091 87.3237 51.788 85.2558 49.1609 83.3281L48.3809 84.3181Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M61.4311 72.2578C59.9311 75.5803 59.5111 79.2703 58.1836 82.6603L59.3536 83.1178C60.6736 79.7428 61.1011 76.0753 62.5861 72.7753L61.4311 72.2578Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M81.5078 109.293C83.3903 111.588 85.2578 113.905 87.1178 116.223L88.1003 115.435C86.2328 113.11 84.3503 110.793 82.4753 108.49L81.5078 109.293Z"
      fill="#FFD483"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M86.8779 105.798L87.5454 106.113L88.0779 104.966L87.4029 104.658L86.8779 105.798Z"
      fill="#FFD483"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.3936 12.7749C23.3536 10.3899 21.3436 7.98992 19.3936 5.54492L18.4111 6.33992C20.3911 8.79242 22.4086 11.1999 24.4636 13.5924L25.3936 12.7749Z"
      fill="#FFD483"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.3281 6.67738C25.2806 7.92238 26.2256 9.16738 27.1931 10.3899L28.1831 9.63988C27.2156 8.41738 26.2781 7.18738 25.3331 5.94238L24.3281 6.67738Z"
      fill="#FFD483"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M105.275 42.858C107.638 42.993 110 43.173 112.363 43.128V41.868C110.03 41.913 107.698 41.733 105.365 41.6055L105.275 42.858Z"
      fill="#FFD483"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M106.723 40.9682C108.388 40.9082 108.276 40.8932 109.993 40.8782V39.6182C108.268 39.6182 108.373 39.6182 106.686 39.7082L106.723 40.9682Z"
      fill="#FFD483"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.6655 89.0724C27.9205 87.8649 31.153 86.6274 34.348 85.2699L33.853 84.1074C30.673 85.4574 27.463 86.6949 24.2305 87.8574L24.6655 89.0724Z"
      fill="#FFD483"
    />
  </svg>
</template>

<style scoped></style>
