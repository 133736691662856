<template>
  <div class="flex flex-col gap-6 story-box-card flex-shrink">
    <div class="flex-shrink flex relative w-full story-box-inner-card">
      <div class="story-box rounded-6xl flex flex-col justify-between">
        <div class="flex gap-4 align-middle">
          <div class="emoji-box">🥇</div>
          <div class="emoji-text flex flex-col">
            <div>История</div>
            <div>успеха</div>
          </div>
        </div>
        <div class="flex flex-col gap-1 text-white">
          <div class="emoji-name">{{ story.name }}</div>
          <div class="emoji-position">{{ story.program }}</div>
        </div>
      </div>
      <div class="image-container">
        <img :src="story.image" alt="story1" class="emoji-img" />
      </div>
      <div
        class="absolute play-button z-10 cursor-pointer"
        @click="handleModal"
      >
        <svg
          width="54"
          height="54"
          viewBox="0 0 54 54"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20.3335 19.8673V34.134C20.3335 35.1562 20.8002 35.934 21.7335 36.4673C22.6668 37.0006 23.5779 36.9562 24.4668 36.334L35.5335 29.2673C36.3779 28.734 36.8002 27.9784 36.8002 27.0006C36.8002 26.0229 36.3779 25.2673 35.5335 24.734L24.4668 17.6673C23.5779 17.0451 22.6668 17.0007 21.7335 17.534C20.8002 18.0673 20.3335 18.8451 20.3335 19.8673ZM27.0002 53.6673C23.3113 53.6673 19.8446 52.9669 16.6002 51.566C13.3557 50.1651 10.5335 48.2655 8.1335 45.8673C5.7335 43.4673 3.83394 40.6451 2.43483 37.4006C1.03572 34.1562 0.335274 30.6895 0.333496 27.0006C0.333496 23.3118 1.03394 19.8451 2.43483 16.6007C3.83572 13.3562 5.73527 10.534 8.1335 8.13398C10.5335 5.73398 13.3557 3.83443 16.6002 2.43532C19.8446 1.03621 23.3113 0.335762 27.0002 0.333984C30.6891 0.333984 34.1557 1.03443 37.4002 2.43532C40.6446 3.83621 43.4668 5.73576 45.8668 8.13398C48.2668 10.534 50.1673 13.3562 51.5682 16.6007C52.969 19.8451 53.6686 23.3118 53.6668 27.0006C53.6668 30.6895 52.9664 34.1562 51.5655 37.4006C50.1646 40.6451 48.2651 43.4673 45.8668 45.8673C43.4668 48.2673 40.6446 50.1678 37.4002 51.5686C34.1557 52.9695 30.6891 53.6691 27.0002 53.6673Z"
            fill="whitexzzxz"
          />
        </svg>
      </div>
    </div>
    <div class="flex flex-col gap-2">
      <div class="story-title">
        {{ story.title }}
      </div>
      <div class="story-text">
        {{ story.content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  inject: ["openVideoModal"],
  props: {
    story: {
      type: Object,
    },
  },
  methods: {
    requireImage(imagePath) {
      if (!imagePath) return "";
      return require(`@/assets/images/landing/${imagePath}`);
    },
    handleModal() {
      this.openVideoModal(this.story.video);
    },
  },
};
</script>
<style scoped>
.story-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.story-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.story-box {
  height: 280px;
  border-radius: 24px 0px 0px 24px;
  width: 275px;
  background: #2b2e3b;
  margin-right: -20px;
  position: relative;
  z-index: 1;
  padding: 32px 24px;
}

.emoji-box {
  border-radius: 16px;
  border: 3px solid rgba(130, 134, 255, 0.2);
  width: 48px;
  height: 48px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.emoji-text {
  color: #8286ff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 23.4px */
  text-transform: uppercase;
}
.emoji-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
}
.emoji-position {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.emoji-img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 0px 24px 24px 0px;
}
.image-container {
  flex-grow: 1;
  height: 280px;
}
.basis-half {
  flex-basis: 50%;
}
.play-button {
  top: 32px;
  right: 26px;
}

@media (max-width: 650px) {
  .story-box-card {
    width: 100%;
  }

  .story-box {
    width: 55%;
  }

  .image-container {
    width: 45%;
  }
}
</style>
