<template>
  <div class="relative">
    <div class="p-4 bg-white rounded-2xl w-auto" style="margin-left: 15px">
      <div class="flex items-center">
        <UserAvatar :review="review" class="flex-shrink-0" />
        <div class="flex flex-col ml-4">
          <span class="font-semibold">{{ review.title }}</span>
          <span class="text-gray-500 font-normal">{{ review.subtitle }}</span>
        </div>
      </div>
      <div class="text-black text-base font-normal mt-6">
        {{ review.content }}
      </div>
      <div class="flex flex-row mt-4">
        <StarIcon />
        <span class="ml-2">{{ review.rating }}/5 </span>
      </div>
    </div>
    <div class="absolute bottom-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="16"
        viewBox="0 0 30 16"
        fill="none"
      >
        <path
          d="M14.2893 0.718108C14.6806 0.322711 15.3194 0.322711 15.7107 0.718108L28.9025 14.0465C29.5274 14.678 29.0802 15.75 28.1917 15.75H1.80826C0.919842 15.75 0.472561 14.678 1.09752 14.0465L14.2893 0.718108Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import UserAvatar from "@/components/UserAvatar";
import StarIcon from "@/assets/icons/StarIcon.vue";
export default {
  components: {
    UserAvatar,
    StarIcon,
  },
  props: {
    review: {
      type: Object,
    },
  },
};
</script>
<style scoped></style>
