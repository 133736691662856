<template>
  <div class="courses-top">
    <div class="courses-clip-top"></div>
  </div>
  <div class="courses">
    <div class="courses-title sm:text-5xl">Направления обучения</div>
    <div class="swiper-text">
      <swiper
        :modules="modules"
        :slides-per-view="
          screenWidth > 1360 ? 4.5 : screenWidth < 700 ? 1.5 : 2
        "
        :space-between="24"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
      >
        <swiper-slide :style="{ overflow: 'visible', paddingTop: '20px' }">
          <div class="marketing">
            <div class="marketing-emoji-div">
              <div class="marketing-emoji">🔥</div>
            </div>
            <div class="marketing-img-div">
              <img
                src="../../../../assets/images/landing/marketing.png"
                class="marketing-img"
                alt="marketing"
              />
            </div>
            <div class="course-text">
              <div class="course-title">Программирование</div>
              <div class="course-title-info">2 курса</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide :style="{ overflow: 'visible', paddingTop: '20px' }">
          <div class="web">
            <div class="web-img-div">
              <img
                src="../../../../assets/images/landing/web.png"
                class="web-img"
                alt="web"
              />
            </div>
            <div class="course-text">
              <div class="course-title">Веб-разработка</div>
              <div class="course-title-info">2 курса</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide :style="{ overflow: 'visible', paddingTop: '20px' }">
          <div class="design-div">
            <div class="design">
              <div class="design-img-div">
                <img
                  src="../../../../assets/images/landing/design.png"
                  class="design-img"
                  alt="design"
                />
              </div>
              <div class="course-text">
                <div class="course-title">Дизайн</div>
                <div class="course-title-info">1 курс</div>
              </div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide :style="{ overflow: 'visible', paddingTop: '20px' }">
          <div class="management">
            <div class="management-img-div">
              <img
                src="../../../../assets/images/landing/management.png"
                class="management-img"
                alt="management"
              />
            </div>
            <div class="course-text">
              <div class="course-title">Менеджмент</div>
              <div class="course-title-info">1 курс</div>
            </div>
          </div>
        </swiper-slide>
        <swiper-slide :style="{ overflow: 'visible', paddingTop: '20px' }">
          <div class="data-science-div">
            <div class="data-science">
              <div class="data-science-img-div">
                <img
                  src="../../../../assets/images/landing/data-science.png"
                  class="data-science-img"
                  alt="data-science"
                />
              </div>
              <div class="course-text">
                <div class="course-title">Data Science</div>
                <div class="course-title-info">2 курса</div>
              </div>
            </div>
          </div>
        </swiper-slide>

        <!--        <swiper-slide :style="{ overflow: 'visible', paddingTop: '20px' }">-->
        <!--          <div class="business">-->
        <!--            <div class="business-img-div">-->
        <!--              <img-->
        <!--                src="../../../../assets/images/landing/business.png"-->
        <!--                class="business-img"-->
        <!--                alt="business"-->
        <!--              />-->
        <!--            </div>-->
        <!--            <div class="course-text">-->
        <!--              <div class="course-title">Бизнес</div>-->
        <!--              <div class="course-title-info">1 курса</div>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </swiper-slide>-->
        <swiper-slide :style="{ overflow: 'visible', paddingTop: '20px' }">
          <div class="games">
            <div class="games-img-div">
              <img
                src="../../../../assets/images/landing/games.png"
                class="games-img"
                alt="games"
              />
            </div>
            <div class="course-text">
              <div class="course-title-white">Разработка игр</div>
              <div class="course-title-info-white">1 курса</div>
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
  <div class="courses-bottom">
    <div class="courses-clip-bottom"></div>
  </div>
</template>

<script>
import { Navigation } from "swiper";

import { Swiper, SwiperSlide } from "swiper/vue";

import "swiper/css";
import "swiper/css/navigation";
export default {
  name: "TheLandingCoursesCarousel",
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
  methods: {
    onSwiper: (swiper) => {
      console.log(swiper);
    },
    onSlideChange: () => {
      console.log("slide change");
    },
    updateScreenSize() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
  },
  data() {
    return {
      modules: [Navigation],
      screenWidth: 0,
      screenHeight: 0,
    };
  },
};
</script>

<style scoped>
.courses {
  padding: 64px 32px 64px 100px;
  background: #2b2e3b;
  display: flex;
  flex-direction: column;
  gap: 45px;
}
.courses-title {
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
}

.course-text {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.course-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #111827;
}
.course-title-info {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #1f2937;
}
.course-title-white {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: white;
}
.course-title-info-white {
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: white;
}

.marketing {
  background: linear-gradient(217.16deg, #dad5fa 31.66%, #ecfcda 79.16%);
  border-radius: 24px;
  padding: 24px 20px;

  min-width: 200px;
  min-height: 238px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: visible;
}
.marketing-emoji-div {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  background: linear-gradient(180deg, #ea2d00 0%, #ff841e 52.08%, #fdf88a 100%),
    linear-gradient(0deg, #ffffff, #ffffff);

  transform: translateY(-50%) translateX(50%);
}
.marketing-emoji {
  width: 39px;
  height: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 50%;
  background: white;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}
.marketing-img-div {
  display: flex;
  align-content: center;
  justify-content: center;
}
.marketing-img {
  width: 120px;
}

.web {
  min-width: 200px;
  min-height: 238px;
  background: linear-gradient(230.04deg, #ddd5f7 26.86%, #f7deda 79.31%);
  border-radius: 16px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.web-img-div {
  display: flex;
  align-content: center;
  justify-content: center;
}
.web-img {
  width: 127px;
  transform: translateX(20%);
}
.design-div {
  background: linear-gradient(
    126.83deg,
    #ce65ff 2.06%,
    #a392ff 56.13%,
    #ffef60 95.59%
  );
  min-width: 200px;

  min-height: 238px;
  border-radius: 16px;
  display: flex;
  padding: 2px 4px;
}

.design {
  background: #ffffff;
  padding: 24px 20px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  /* Add margin to position the inner div inside the outer div */
  margin: 5px;
  flex-grow: 1;
}

.design-img-div {
  transform: translateY(-20%) translateX(-20%);
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-content: center;
  justify-content: center;
}
.design-img {
  width: 90px;
}

.management {
  min-width: 200px;

  min-height: 238px;
  background: linear-gradient(256.7deg, #f3dddf 4.84%, #ecfcda 83.43%);
  border-radius: 16px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.management-img-div {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}
.management-img {
  width: 153px;
}

.data-science-div {
  background: linear-gradient(
    126.83deg,
    #ef9b6b 2.06%,
    #90e37c 49.8%,
    #a392ff 95.59%
  );
  min-width: 200px;

  min-height: 238px;
  border-radius: 16px;
  display: flex;
  padding: 2px 4px;
}
.data-science {
  background: #ffffff;

  padding: 24px 20px;

  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  margin: 5px;
  flex-grow: 1;
}
.data-science-img-div {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.data-science-img {
  width: 90px;
  display: flex;
}

.business {
  min-width: 270px;
  min-height: 238px;
  background: #deceff;

  border-radius: 16px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.business-img-div {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}
.business-img {
  width: 52px;
  transform: translateX(-20%) translateY(10%);
}

.games {
  min-width: 200px;

  min-height: 238px;
  background: #844fed;

  border-radius: 16px;
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: white;
}
.games-img-div {
  display: flex;
  align-content: flex-end;
  justify-content: flex-end;
}
.games-img {
  width: 90px;
  transform: translateX(-20%) translateY(10%);
}

.courses-top {
  height: 40px;
  background: #2b2e3b;
}
.courses-bottom {
  height: 40px;
  background: #2b2e3b;
  display: flex;
  flex-direction: column-reverse;
}
.courses-clip-top {
  height: 20px;
  background: white;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}
.courses-clip-bottom {
  height: 20px;
  background: linear-gradient(90deg, #4776e6 0%, #b587fc 100%);
  border-top-left-radius: 100%;
  border-top-right-radius: 100%;
}
.course-title-info-white {
  color: white;
}

@media (max-width: 650px) {
  .courses {
    padding: 32px 16px;
  }

  .courses-title {
    font-size: 28px;
  }
  .courses-clip-bottom {
    display: none;
  }

  .courses-clip-top {
    display: none;
  }

  .courses-bottom {
    display: none;
  }

  .courses-top {
    display: none;
  }
  .course-title {
    font-size: 20px;
  }
  .course-title-info {
    font-size: 16px;
  }
  .course-title-white {
    font-size: 20px;
  }
  .course-title-info-white {
    font-size: 16px;
  }
}
</style>
