const baseUrl = "/api/v1/auth";
export default ($axios) => ({
  forgot_password(payload) {
    return $axios.post(`${baseUrl}/reset-password/`, payload);
  },

  login(email, password, remember_me = false) {
    return $axios.post(`${baseUrl}/token/`, { email, password, remember_me });
  },
  logout() {
    return $axios.get(`${baseUrl}/logout/`);
  },
  refresh(refresh) {
    return $axios.post(`${baseUrl}/refresh/`, { refresh });
  },
  register(payload) {
    return $axios.post(`${baseUrl}/register/`, payload);
  },
  register_full_info(payload) {
    return $axios.post(`${baseUrl}/register/`, payload);
  },
  get_current_user() {
    return $axios.get(`${baseUrl}/user/`);
  },
});
