<template>
  <TheModal :show="show_quiz_timeout_modal">
    <div class="flex p-4">
      <div class="flex mr-4">
        <div class="bg-red-100 p-2 rounded-full align-center h-fit">
          <ExclamationIcon class="h-5 w-5 text-red-600 "/>
        </div>
      </div>
      <div class="flex flex-col ">
        <h1 class="text-lg leading-6 font-medium text-gray-900 mb-2">
          {{$t('quiz_timeout_title')}}
        </h1>
        <div class="w-100 text-sm leading-5 font-normal text-gray-500 mb-4">
          {{$t('quiz_timeout_text')}}
        </div>
        <TheButton bg="primary" class="w-full" @click="finishQuiz">
          {{$t('finish')}}
        </TheButton>
      </div>
    </div>
  </TheModal>
</template>
<script>
import TheModal from "@/components/TheModal/TheModal";
import {mapFields} from "vuex-map-fields";
import {ExclamationIcon} from '@heroicons/vue/outline'
import TheButton from "@/components/Button/Button";
import FinishQuizMixin from "@/components/Resources/Modals/FinishQuizMixin";
export default {
  components: {TheButton, TheModal,ExclamationIcon},
  mixins:[FinishQuizMixin],
  computed:{
    ...mapFields(['resources.show_quiz_timeout_modal']),
  }
}
</script>