import {mapFields} from "vuex-map-fields";
import {SEARCH_REQUEST} from "@/store/core";

export default {
    methods:{
        setSearchModalState(val){
            this.is_modal_open =val
        },
        makeSearchRequest(){
            const {text} = this;
            this.$store.dispatch(SEARCH_REQUEST,{text})
        }
    },

    computed:{
        ...mapFields(['core.search.text','core.search.is_modal_open','core.search.courses'])
    },
}