<template>
  <div class="advantages">
    <div class="text-white text-4xl font-bold adaptive-block-title">
      Делаем обучение удобным и интересным
    </div>
    <div class="advantages__block grid grid-cols-2 gap-6">
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <ThumbUpIcon />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Лучшие методологии</div>
          <div class="text-lg">
            Мы разработали платформу, которая позволяет эффективно организовать
            учебный материал и мотивировать ваших учеников при помощи специально
            разработанной системе вознаграждений
          </div>
        </div>
      </div>
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <ResultHat />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Нацеленность на результат</div>
          <div class="text-lg">
            Наша платформа предоставляет быстрый доступ ко всему материалу для
            эффективного обучения, а специально созданная игровая среда поможет
            вам не терять мотивацию и успешно усваивать новые знания
          </div>
        </div>
      </div>
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <PortalComputer />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Открытый портал</div>
          <div class="text-lg">
            В системе предусмотрены внутренняя валюта и очки опыта, которые
            присуждаются после выполнения определенных задач и достижений.
          </div>
        </div>
      </div>
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <AchievementIcon />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Система вознаграждений</div>
          <div class="text-lg">
            В системе предусмотрены внутренняя валюта и очки опыта, которые
            присуждаются после выполнения определенных задач и достижений.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ThumbUpIcon from "@/assets/icons/ThumbUpIcon.vue";
import ResultHat from "@/assets/icons/ResultHat.vue";
import PortalComputer from "@/assets/icons/PortalComputer.vue";
import AchievementIcon from "@/assets/icons/AchievementIcon.vue";

export default {
  components: {
    AchievementIcon,
    PortalComputer,
    ResultHat,
    ThumbUpIcon,
  },
};
</script>

<style scoped>
.advantages {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background: linear-gradient(90deg, #593ed3 0%, #524ed9 45.83%, #3d64e6 100%);
  color: white;
}
.advantages__block__item {
  padding: 40px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 32px;
}
.icon__div {
  width: 64px;
  height: 64px;
}
.icon {
  width: 46px;
  height: 46px;
}

.bg-transparent {
  background: rgba(255, 255, 255, 0.2);
}

@media (max-width: 650px) {
  .advantages {
    padding: 40px 16px;
  }
  .advantages__block {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .adaptive-block-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}
</style>
