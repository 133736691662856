<template>
  <div
    class="sticky top-0 px-5 z-50 shadow-sm border border-[1px] border-gray-600 bg-white"
  >
    <div
      class="flex items-center justify-between place-content-center py-4 px-2"
    >
      <slot />
    </div>
  </div>
</template>
<script>
import ThePageHeaderMixin from "@/components/ThePageHeader/ThePageHeaderMixin";
export default {
  components: {},
  mixins: [ThePageHeaderMixin],
};
</script>
