<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
        <rect width="64" height="64" rx="16" fill="#425FF8" fill-opacity="0.2"/>
        <rect x="8.3374" y="8.6227" width="46.5455" height="46.5455" rx="16" fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1375 43.874C31.2717 43.9441 31.4218 43.9798 31.5719 43.9786C31.722 43.9774 31.8709 43.9405 32.0064 43.8692L36.3311 41.5574C37.5574 40.9036 38.5178 40.1726 39.2671 39.3216C40.8962 37.4674 41.7857 35.1008 41.7699 32.6606L41.7186 24.6113C41.7138 23.6842 41.1048 22.8569 40.2043 22.5562L32.1589 19.8569C31.6744 19.6929 31.1436 19.6964 30.6677 19.8652L22.6528 22.6608C21.7572 22.9734 21.1592 23.8066 21.1641 24.735L21.2153 32.7783C21.2312 35.2221 22.1513 37.5779 23.8072 39.4131C24.5638 40.2523 25.5327 40.9726 26.7725 41.6156L31.1375 43.874ZM29.9926 34.4137C30.1732 34.5872 30.4075 34.6728 30.6418 34.6704C30.8761 34.6692 31.1092 34.5813 31.2874 34.4054L36.0135 29.746C36.3687 29.3954 36.365 28.832 36.0062 28.4861C35.6462 28.1402 35.0666 28.1426 34.7115 28.4932L30.6284 32.5179L28.9566 30.9109C28.5966 30.565 28.0182 30.5685 27.6619 30.9192C27.3068 31.2698 27.3104 31.8332 27.6704 32.1791L29.9926 34.4137Z" fill="#425FF8"/>
    </svg>
</template>
<script>
import IconMixin from "@/assets/icons/IconMixin";

export default {
mixins:[IconMixin]
}
</script>