import { mapActions } from "vuex";
import { LOGOUT_REQUEST } from "@/store/auth";
import { mapFields } from "vuex-map-fields";
export default {
  computed: {
    isAuthenticated() {
      return this.$store.getters.is_authenticated;
    },
    ...mapFields(["auth.active_user", "core.is_sidebar_open"]),
  },
  methods: {
    ...mapActions({ LOGOUT_REQUEST }),
    async logout() {
      await this.LOGOUT_REQUEST();
      this.$router.push({ name: "Login" });
    },
    toggleSidebar() {
      this.is_sidebar_open = !this.is_sidebar_open;
    },
    async profile() {
      this.$router.push({ name: "UserProfile" });
    },
  },
};
