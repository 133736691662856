import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import {
  DELETE_SPECIALIZATION,
  GET_SPECIALIZATION,
  SAVE_SPECIALIZATION,
} from "@/store/specializations";
import { GET_PUBLIC_COURSES } from "@/store/courses";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";

export default {
  components: { Breadcrumbs },
  data() {
    return {
      courses: [],
      modalOpen: false,
      breadcrumbs: [],
    };
  },
  computed: {
    ...mapFields([
      "specializations.form",
      "specializations.modals.show_courses_select_modal",
    ]),
    isUpdating() {
      return !!(this.form && this.form.id);
    },
  },
  methods: {
    ...mapActions([
      GET_SPECIALIZATION,
      GET_PUBLIC_COURSES,
      SAVE_SPECIALIZATION,
      DELETE_SPECIALIZATION,
    ]),
    generateNewSpecialization() {
      return {
        name: "",
        logo: "",
        description: "",
        course_ids: [],
      };
    },
    openCoursesSelectorModal() {
      this.show_courses_select_modal = true;
    },
    async getCourses() {
      const payload = {
        id__in: this.form.course_ids,
        short: true,
      };
      const courses = await this.GET_PUBLIC_COURSES(payload);
      this.courses = courses.reduce(
        (acc, cur) => ({ ...acc, [cur.id]: cur }),
        {}
      );
    },
    removeCourse(id) {
      this.form.course_ids = this.form.course_ids.filter((_id) => _id !== id);
    },
    submit() {
      this.SAVE_SPECIALIZATION(this.form).then(() =>
        this.$router.push({ name: "SpecializationsList" })
      );
    },
    deleteSpecialization() {
      this[DELETE_SPECIALIZATION](this.form.id);
      this.$router.push({ name: "SpecializationsList" });
    },
    cancel() {
      this.$router.back();
    },
    handleModalOpen() {
      this.modalOpen = true;
    },
    handleModalClose() {
      this.modalOpen = false;
    },
  },
  watch: {
    "form.course_ids": {
      handler(newVal) {
        if (newVal.length > 0) this.getCourses();
      },
      immediate: true,
    },
  },
  async mounted() {
    const { id } = this.$route.query;
    if (id) {
      this.form = await this[GET_SPECIALIZATION](id);
      this.breadcrumbs = [
        {
          name: this.$t("specializations"),
          path: "/specializations",
        },
        {
          name: this.form.name,
          path: `${this.$route.path}?id=${id}`,
        },
      ];
    } else {
      this.form = this.generateNewSpecialization();
      this.breadcrumbs = [
        {
          name: this.$t("specializations"),
          path: "/specializations",
        },
        {
          name: this.$t("create_new_specialization"),
          path: this.$route.path,
        },
      ];
    }
  },
};
