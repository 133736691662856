<template>
  <div class="faq">
    <div class="font-bold font-size-40px text-gray-800 faq-title">
      Часто задаваемые вопросы
    </div>
    <div class="grid grid-cols-2 gap-8 adaptive-faq">
      <div
        class="flex flex-col gap-6"
        v-for="(item, index) in faq"
        :key="index"
      >
        <hr class="border-0.5 border-gray-500" />
        <div class="flex flex-col gap-6">
          <div
            class="flex justify-between gap-4 items-center cursor-pointer"
            @click="openFaqQuestion(index)"
            v-bind:class="{
              'opened-question faq-opened-question': faq[index].open,
            }"
          >
            <div class="font-semibold text-xl faq-question">
              {{ faq[index].question }}
            </div>
            <div>
              <PlusIcon v-if="!faq[index].open" />
              <CrestIcon v-if="faq[index].open" />
            </div>
          </div>
          <div
            class="text-base font-medium text-gray-500"
            v-if="faq[index].open"
          >
            {{ faq[index].answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlusIcon from "../../../../assets/icons/PlusIcon.vue";
import CrestIcon from "../../../../assets/icons/WhiteCrestIcon.vue";

export default {
  components: { PlusIcon, CrestIcon },
  props: {
    newFaq: {
      type: Array,
      default: () => {
        return [
          {
            question: "Что такое вводный урок?",
            answer:
              " На вводном уроке вам расскажут подробнее о выбранном курсе, познакомят с платформой и помогут определить уровень и цели. Это бесплатно и займёт 30–40 минут.",
            open: false,
          },
          {
            question: "Какие навыки и знания я получу на курсах?",
            answer:
              " Мы активно сотрудничаем с ведущими компаниями и работодателями в отрасли, что дает нашим студентам широкие возможности для трудоустройства. Мы организуем специальные мероприятия, встречи с работодателями и предоставляем поддержку в поиске работы после окончания обучения. Многие наши выпускники успешно находят работу в технологических компаниях и стартапах.",
            open: false,
          },
          {
            question: "Сколько времени требуется для освоения курса?",
            answer:
              " Продолжительность курса зависит от выбранной программы. Мы предлагаем как краткосрочные интенсивные курсы, так и более длительные программы обучения. Обычно наши курсы длительностью от нескольких недель до нескольких месяцев, что позволяет студентам быстро освоить нужные навыки и приступить к работе в выбранной области.",
            open: false,
          },
          {
            question: "Есть ли поддержка после окончания курса? ",
            answer:
              " Да, после окончания курса мы предоставляем поддержку нашим выпускникам. У нас есть менторская программа, где опытные специалисты из индустрии помогают выпускникам советами и рекомендациями. Мы также предоставляем доступ к нашим ресурсам и обновленной информации, чтобы выпускники могли оставаться в курсе последних трендов и развиваться профессионально.",
            open: false,
          },
          {
            question:
              "Нужны ли предварительные знания или опыт для поступления на курс?",
            answer:
              "Наши курсы доступны как для начинающих, так и для тех, кто уже имеет некоторый опыт в выбранной области. Мы предлагаем программы обучения на разных уровнях сложности, чтобы каждый студент мог найти подходящий уровень. Наша команда преподавателей готова помочь вам освоить новые знания и навыки, независимо от вашего опыта.",
            open: false,
          },
          {
            question: "Какая форма обучения предлагается: очная или онлайн?",
            answer:
              "Мы предлагаем онлайн-курсы. Вы можете заниматься из любой точки мира в любое удобное для вас время. Школа обеспечивают высокое качество образования и поддержку со стороны нашей команды.",
            open: false,
          },
        ];
      },
    },
  },
  data() {
    return {
      faq: this.newFaq,
    };
  },
  methods: {
    openFaqQuestion(index) {
      this.faq[index].open = !this.faq[index].open;
    },
  },
};
</script>

<style scoped>
.faq {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.opened-question {
  color: #7062ef;
}

.text-gray-50 {
  color: #f9fafb;
}
.font-size-40px {
  font-size: 40px;
}

@media (max-width: 650px) {
  .faq {
    padding: 24px 16px;
  }
  .adaptive-faq {
    display: flex;
    flex-direction: column;
  }
  .faq-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }

  .faq-question {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }

  .faq-opened-question {
    color: #7062ef;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
  }
}
</style>
