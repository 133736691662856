<template>
  <div>
    <p class="text-2xl text-gray-900 font-semibold mb-4">
      {{$t('finished')}}
      <span class="text-gray-600">
        {{courses.length}}
      </span>
    </p>
    <CourseCardRow
        v-for="course in courses"
        :name="course.name"
        :id="course.id"
        :specializations="course.specializations"
        :key="course.id"
        :logo="course.logo"
        :start_date="course.start_date"
        :end_date="course.end_date"
        class="mb-4"

    />
  </div>
</template>
<script>
import CourseCardRow from "@/components/Courses/Card/CourseCardRow";
import FinishedCoursesMixin from "./FinishedCoursesMixin";
export default {
  components: {CourseCardRow},
  mixins:[FinishedCoursesMixin]
}
</script>