<script>
import { defineComponent } from "vue";
import TheSmallModal from "@/components/TheModal/TheSmallModal.vue";

export default defineComponent({
  name: "TaskPercentageDeleteModal",
  components: { TheSmallModal },
  props: ["isDeleteOpen"],
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleDelete() {
      this.$emit("deleteCategory");
      this.handleClose();
    },
  },
});
</script>

<template>
  <TheSmallModal
    :show="isDeleteOpen"
    @close="handleClose"
    class-name="delete-modal"
  >
    <div class="task-perc__delete flex flex-col gap-8">
      <div class="flex gap-4 justify-start">
        <div>
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="40" height="40" rx="20" fill="#FEE2E2" />
            <path
              d="M11.3393 24L10.4733 23.5L11.3393 24ZM28.6598 24L29.5258 23.5L28.6598 24ZM21.7316 12L20.8656 12.5V12.5L21.7316 12ZM18.2675 12L19.1335 12.5V12.5L18.2675 12ZM20.9995 17C20.9995 16.4477 20.5518 16 19.9995 16C19.4473 16 18.9995 16.4477 18.9995 17H20.9995ZM18.9995 19C18.9995 19.5523 19.4473 20 19.9995 20C20.5518 20 20.9995 19.5523 20.9995 19H18.9995ZM19.9995 22C19.4473 22 18.9995 22.4477 18.9995 23C18.9995 23.5523 19.4473 24 19.9995 24V22ZM20.0095 24C20.5618 24 21.0095 23.5523 21.0095 23C21.0095 22.4477 20.5618 22 20.0095 22V24ZM20.8656 12.5L27.7938 24.5L29.5258 23.5L22.5976 11.5L20.8656 12.5ZM26.9277 26H13.0713V28H26.9277V26ZM12.2053 24.5L19.1335 12.5L17.4015 11.5L10.4733 23.5L12.2053 24.5ZM13.0713 26C12.3015 26 11.8204 25.1667 12.2053 24.5L10.4733 23.5C9.31857 25.5 10.7619 28 13.0713 28V26ZM27.7938 24.5C28.1787 25.1667 27.6975 26 26.9277 26V28C29.2371 28 30.6805 25.5 29.5258 23.5L27.7938 24.5ZM22.5976 11.5C21.4429 9.5 18.5562 9.5 17.4015 11.5L19.1335 12.5C19.5184 11.8333 20.4807 11.8333 20.8656 12.5L22.5976 11.5ZM18.9995 17V19H20.9995V17H18.9995ZM19.9995 24H20.0095V22H19.9995V24Z"
              fill="#DC2626"
            />
          </svg>
        </div>
        <div class="flex flex-col gap-6">
          <div class="flex flex-col gap-2">
            <div class="task-perc__delete__title">Удалить категорию</div>
            <div class="task-perc__delete__text">
              Вы уверены, что хотите удалить категорию задания?
            </div>
          </div>
        </div>
        <div class="cursor-pointer" @click="handleClose">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 18L18 6M6 6L18 18"
              stroke="#9CA3AF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
      <div class="flex justify-end">
        <div class="flex gap-3">
          <div class="cancel-button cursor-pointer" @click="handleClose">
            Отмена
          </div>
          <div class="delete-button cursor-pointer" @click="handleDelete">
            Удалить
          </div>
        </div>
      </div>
    </div>
  </TheSmallModal>
</template>

<style scoped>
.task-perc__delete {
  padding: 24px;
}
.cancel-button {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #374151;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid #d1d5db;
}
.delete-button {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: white;
  background: #dc2626;
  padding: 8px 16px;
  border-radius: 6px;
}
.task-perc__delete__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #111827;
}
.task-perc__delete__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #6b7280;
}
.delete-modal {
  width: 512px;
}
</style>
