<template>
  <ContentBlockForm :content_name="$t('text')" :id="id">
    <TheQuillEditorInput v-model="local_text" />
  </ContentBlockForm>
</template>
<script>
import TheQuillEditorInput from "@/components/TheQuillEditorInput";
import ContentBlockForm from "@/views/core/News/components/ContentBlockForm";

export default {
  components: { ContentBlockForm, TheQuillEditorInput },
  props: {
    text: {},
    id: {
      type: Number,
      required: true,
    },
  },
  computed: {
    local_text: {
      get() {
        return this.text;
      },
      set(event) {
        this.$emit("contentChange", event);
      },
    },
  },
};
</script>