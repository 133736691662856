<template>
  <div>
    <p class="text-2xl text-gray-900 font-semibold mb-4">
      <slot name="title"/>
    </p>
    <div class="w-full flex ">
      <div class="w-4/5">
        <slot name="main"/>
      </div>
      <div class="w-1/5 pl-4 flex place-content-between flex-col">
        <div>
          <slot name="secondary"/>
        </div>
        <slot name="actions"/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {

  }
</script>