export default {
    props:{
        score:{
            type:Number,
            default:5
        },
        votes:{
            type:Number,
            default:0
        },
        duration_hours:{
            type:Number,
            default:0
        },
        skill_level:{
            type:String,
            default:''
        },
        chapters_count:{
            type:Number,
            default:0
        },
        resources_count:{
            type:Number,
            default:0
        },
        quizzes_count:{
            type:Number,
            default:0
        },
        is_certificate_given:{
            type:Boolean,
            default:false
        }
    }
}