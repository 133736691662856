<template>
  <div class="bg-white rounded-xl  w-full overflow-hidden">
    <img :src="localContent" class="w-full"/>
    <AttachmentsBlock :attachments="attachments"/>
  </div>
</template>
<script>
  import AttachmentsBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
  export default {
    components: {AttachmentsBlock},
    props:{
      image:{
        type:String,
        required:true
      },
      attachments:{
        type:Array,
        default:()=>[]
      }
    },
    computed:{
      localContent(){
        if (typeof this.image === 'string' || this.image instanceof String){
          return this.image
        }else{
          return URL.createObjectURL(this.image)
        }
      }
    }
  }
</script>

