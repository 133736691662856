<template>
  <ContentBlockForm :content_name="$t('audio')" :show_attachments_button="false" :_key="_key" :attachments="attachments">
    <FileDropzone
        accept="audio/*"
        secondaryLabel="audio_no_more_than_n_mb"
        v-model="localValue"
        type="audio"
        updateButtonLabel="update_audio"
        deleteButtonLabel="delete_audio"
    />
  </ContentBlockForm>
</template>
<script>
import ContentBlockForm from "@/views/resources/Form/components/ContentBlockForm";
import FileDropzone from "@/components/FileDropzone";
export default {
  components: {ContentBlockForm,FileDropzone},
  props:{
    attachments:[],
    content:{},
    _key:{
      type:String,
      required:true
    }
  },
  computed:{
    localValue:{
      get(){
        return this.content
      },
      set(val){
        this.$emit('contentChange',val)
      }
    }
  }
}
</script>