import { mapActions } from 'vuex';
import { REMOVE_NEWS_CONTENT } from '@/store/core';

export default {
  props: {
    attachments: [],
    modelValue: {},
    id: {
      required: true,
      type: Number,
    },
    content_name: {
      type: String,
      required: true,
    },
    show_attachments_button: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
    },
  },
  methods: {
    ...mapActions([REMOVE_NEWS_CONTENT]),
    handleRemoveButtonClicked() {
      this.$emit('remove', this.id);
    },
  },
};
