<template>
  <TheModal :show="show_faq_modal" class="mt-6">
    <div class="flex flex-col md:flex-row p-6">
      <div class="w-full">
      <span class="flex justify-between items-center mb-6 p-4">
        <p class="text-xl text-gray-900 font-semibold">{{$t('faq')}}</p>
        <TheButton bg="outline" @click="$emit('closeModal')"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
</svg></TheButton>
      </span>
        <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-3 items-start ">
          <FaqList
              v-bind:faqs="faqs_ru"
          />
          <FaqList
              v-bind:faqs="faqs_kaz"
          />
        </div>
      </div>
    </div>
  </TheModal>
</template>
<script>
import FaqList from "@/views/core/FAQ/FaqList";
import TheModal from "@/components/TheModal/TheModal.vue";
import TheButton from "@/components/Button/Button";
export default{
  components: {FaqList, TheModal, TheButton},
  data:()=>({
    faqs_ru:[
      {
        is_closed: true,
        question:"Что такое EdCrunch Academy?",
        ans:"EdCrunch Academy – образовательная онлайн-платформа, которая соединяет уникальный опыт лучших в своём деле людей с новейшими образовательными практиками. Вы получаете контент, который захватывает и вдохновляет: наши авторы впервые выступают в роли наставников, чтобы передать вам свой опыт. Если вы ищете доступ к уникальному контенту и возможности для саморазвития и профессионального роста – наши курсы вам подходят, и то, что входит в них, не узнать больше нигде."
      },
      {
        is_closed: true,
        question:"Где и когда проходят занятия?",
        ans:"Обучение проходит на образовательной платформе EdCrunch Academy. У каждого курса есть свои сроки сдачи заданий и график вебинаров, с которыми вы можете ознакомиться в содержании курса.  Курс доступен 24/7 в рамках сроков указанных на карточке курса. Изучать материал курсов можно онлайн: везде, где есть выход в Интернет, в то время, которое вам удобно.",
      },
      {
        is_closed: true,
        question:"Когда начинается курс?",
        ans:"Каждый курс имеет свои индивидуальные сроки обучения. Даты начала обучения указаны на карточке курсов. Карточки курсов вы можете найти на главной странице платформы.",
      },
      {
        is_closed: true,
        question:"Не подходит логин или пароль?",
        ans:"Для восстановления доступа в личный кабинет необходимо обратиться  в службу технической поддержки по адресу helpdesk@edca.kz с почтового адреса зарегистрированного на платформе.",
      },
      {
        is_closed: true,
        question:"Отсутствует логин или пароль?",
        ans:"Для получения логин/пароля для авторизации можете написать школьному координатору или  обратиться в службу технической поддержки по адресу helpdesk@edca.kz с почтового адреса зарегистрированного на платформе. Если Вы не уверены под каким адресом Вы зарегистрированы в системе, то Вы можете обратиться к школьному координатору.",
      },
      {
        is_closed: true,
        question:"Указан неверный email, как могу поменять email/логин?",
        ans:"Для исправления логина или пароля личного кабинета необходимо направить официальный запрос на смену email/логина от имени школьной администрации на почту helpdesk@edca.kz.",
      },
      {
        is_closed: true,
        question:"Где я могу получить ссылку на Whatsapp чат с куратором курса?",
        ans:"Ссылка на чат была выслана на почту с которой Вы зарегистрированы. Если Вы не можете найти письмо, пожалуйста проверьте папку “Спам”. Если Вы так и не смогли найти письмо. то обратитесь в службу технической поддержки по адресу helpdesk@edca.kz с почтового адреса зарегистрированного на платформе. ",
      },
      {
        is_closed: true,
        question:"Что делать, если не успел(а) сдать задание и курс уже закрыт?",
        ans:"Доступ к курсу будет открыт в сроки, указанные на карточке курса. Карточки курсов вы можете найти на главной странице платформы. Убедительная просьба уделить внимание срокам сдачи заданий в каждом курсе.  Рекомендуется сдавать все задания в срок, указанный в содержании курса. К сожалению, после окончания срока обучения на курсе у пользователя не будет возможности отправить выполненные задания на проверку.",
      },
      {
        is_closed: true,
        question:"Не пришло письмо о старте обучения на курсе?",
        ans:"Письмо о старте обучения на курсе приходит только записавшимся на данный курс слушателям. Если у вас нет данного письма, необходимо проверить папку “Спам” на наличие данного письма. Если нет письма, то значит вы не записывались на курс, обучение на котором проходит в данный период. Уточнить запись на курс вы можете  у службы технической поддержки для этого обратитесь в службу технической поддержки по адресу helpdesk@edca.kz с почтового адреса зарегистрированного на платформе.. Также вы можете узнать дату начала курса, на который вы записались, на главной странице платформы.",
      },
      {
        is_closed: true,
        question:"Отправил(а) задание на проверку, когда проверят?",
        ans:"Задания проверяются кураторами группы в порядке очередности в течение 2-3 календарных дней с момента сдачи работы. Все работы сданные в срок обязательно будут проверены.",
      },
      {
        is_closed: true,
        question:"Отправил(а) на проверку задания, как можно узнать, есть ли “зачет” за курс?",
        ans:"Для получения “зачета” за курс необходимо сдать все проверочные работы. По окончанию обучения академические часы за зачтенный курс будут внесены в сертификат.",
      }
    ],
    faqs_kaz:[
      {
        is_closed: true,
        question:"EdCrunch академиясы дегеніміз не?",
        ans:"EdCrunch Academy - білім беру саласындағы ең үздік адамдардың бірегей тәжірибесін білім берудің жаңа тәжірибелерімен байланыстыратын білім беру онлайн-платформасы. Сіз қызықты және шабыттандыратын ақпарат аласыз: авторлар алдымен өз тәжірибелерін сізге жеткізу үшін тәлімгер ретінде әрекет етеді. Егер сіз бірегей ақпаратқа қол жеткізуді және өзін – өзі дамыту мен кәсіби өсу мүмкіндіктерін іздесеңіз-біздің курстар сізге сәйкес келеді және оларға кіретін ақпараттарды басқа жерден таба алмайсыз."
      },
      {
        is_closed: true,
        question:"Сабақтар қайда және қашан өтеді?",
        ans:"Оқу EdCrunch Academy білім беру платформасында жүргізіледі. Әр курстың өзіндік мерзімі және вебинарлар кестесі бар, олармен сіз курстың мазмұнынан таныса аласыз.  Курс карточкасында көрсетілген мерзім аясында 24/7 қолжетімді. Сіз курстардың материалдарын онлайн оқи аласыз: Интернетке шығу мүмкіндігі бар жерде, сізге ыңғайлы уақытта."
      },
      {
        is_closed: true,
        question:"Курс қашан басталады?",
        ans:"Әр курстың жеке оқу мерзімі бар. Оқудың басталу күні Курс карточкасында көрсетілген. Курс карталарын платформаның басты бетінен таба аласыз."
      },
      {
        is_closed: true,
        question:"Логин немесе пароль дұрыс емес?",
        ans:"Жеке кабинетке кіруді қалпына келтіру үшін платформада тіркелген helpdesk@edca.kz пошта мекен жайының техникалық қолдау қызметіне жаза аласыз."
      },
      {
        is_closed: true,
        question:"Логин немесе пароль жоқ?",
        ans:"Авторизация үшін логин/құпиясөзді алу үшін мектеп үйлестірушісіне жаза аласыз немесе платформада тіркелген helpdesk@edca.kz пошта мекен-жайының техникалық қолдау қызметіне хабарласа аласыз. Егер сіз жүйеде қандай мекен-жайға тіркелгеніңізге сенімді болмасаңыз, онда сіз мектеп үйлестірушісімен байланыса аласыз."
      },
      {
        is_closed: true,
        question:"Email/логин дұрыс көрсетілмеген, қалай өзгерте аламын?",
        ans:"Жеке кабинеттің логинін немесе паролін түзету үшін мектеп әкімшілігі атынан электрондық поштаны/логинді ауыстыруға ресми сұрау жіберу керек helpdesk@edca.kz."
      },
      {
        is_closed: true,
        question:"Курс кураторымен Whatsapp чат сілтемесін қайдан алуға болады?",
        ans:"Чатқа сілтеме сіз тіркелген поштаға жіберіледі. Егер сіз хатты таба алмасаңыз, “Спам” папкасынан тексеріңіз. Егер сіз хатты  “Спам” папкасынан да таппаған жағдайда, платформада тіркелген helpdesk@edca.kz пошта мекен-жайының техникалық қолдау қызметіне жазыңыз."
      },
      {
        is_closed: true,
        question:"Егер сіз тапсырманы тапсырып үлгермесеңіз және курс жабық болса не істеу керек?",
        ans:"Курсқа кіру курс карточкасында көрсетілген мерзімде ашылады. Курс карталарын платформаның басты бетінен таба аласыз. Әр курста тапсырмаларды тапсыру мерзіміне назар аударуды сұраймыз. Барлық тапсырмаларды курс мазмұнында көрсетілген мерзімде тапсыру ұсынылады. Өкінішке орай, курста оқу мерзімі аяқталғаннан кейін пайдаланушы аяқталған тапсырмаларды тексеруге жібере алмайды."
      },
      {
        is_closed: true,
        question:"Курста оқуды бастау туралы хат келген жоқ па?",
        ans:"Курста оқуды бастау туралы хат тек осы курсқа жазылған тыңдаушыларға келеді. Егер сізде бұл хат болмаса, “Спам” папкасынан осы хаттың бар-жоғын тексеру керек. Егер хат болмаса, онда сіз осы кезеңде өтетін курсқа жазылмадыңыз. Курсқа жазылуды платформада тіркелген helpdesk@edca.kz пошта мекен-жайының техникалық қолдау қызметіне жаза аласыз. Сондай-ақ, сіз платформаның басты бетінен жазылған курстың басталу күнін біле аласыз."
      },
      {
        is_closed: true,
        question:"Тексеру тапсырмасын қашан жіберді?",
        ans:"Тапсырмаларды топ кураторлары жұмыс тапсырылған сәттен бастап 2-3 күнтізбелік күн ішінде кезектілік тәртібімен тексереді. Мерзімінде тапсырылған барлық жұмыстар міндетті түрде тексерілетін болады."
      },
      {
        is_closed: true,
        question:"Тапсырманы тексеруге жібердім, курс үшін 'сынақ' бар-жоғын қалай білуге болады?",
        ans:"Курсқа 'сынақ' алу үшін барлық тексеру жұмыстарын тапсыру қажет. Оқу аяқталғаннан кейін сынақ курсы үшін академиялық сағаттар сертификатқа енгізіледі."
      }
    ]
  }),
  props:{
    show_faq_modal:{
      type:Boolean
    }
  }
}
</script>