<template>
  <div class="page">
    <div class="courses-category">
      <h1 class="title">Все курсы</h1>
      <!--      <div @click="deleteCourse(4)">get course</div>-->
      <div class="adaptive-courses2 relative">
        <div class="relative w-full flex-1">
          <button
            @click="show = !show"
            class="w-full flex items-center p-4 justify-between rounded-xl border border-indigo-500"
          >
            <span class="mr-4 text-base">{{
              courseType.length == 0 ? "Все направления" : courseType[0].label
            }}</span>
            <svg
              class="w-5 h-5 text-indigo-100 dark:text-white"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              v-if="!show"
            >
              <path
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-if="show"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.7071 12.7071C14.3166 13.0976 13.6834 13.0976 13.2929 12.7071L10 9.41421L6.70712 12.7071C6.3166 13.0976 5.68343 13.0976 5.29291 12.7071C4.90238 12.3166 4.90238 11.6834 5.29291 11.2929L9.2929 7.29289C9.68343 6.90237 10.3166 6.90237 10.7071 7.29289L14.7071 11.2929C15.0976 11.6834 15.0976 12.3166 14.7071 12.7071Z"
                fill="#111827"
              />
            </svg>
          </button>

          <div
            v-show="show"
            class="absolute right-0 py-2 mt-2 rounded-xl shadow-xl w-full z-10 border border-indigo-500 bg-white"
          >
            <div
              v-for="(course, index) in landingOptionsMob"
              :key="index"
              class="block px-4 py-2 text-base"
            >
              <div
                v-if="courseType.includes(course)"
                @click="chooseCourseMob(course)"
                class="flex justify-between items-center"
              >
                <div class="text-base text-indigo-500">
                  {{ course.label }}
                </div>
                <div class="w-5 h-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M8.33366 11.3333L13.2503 6.41667C13.4031 6.26389 13.5975 6.1875 13.8337 6.1875C14.0698 6.1875 14.2642 6.26389 14.417 6.41667C14.5698 6.56944 14.6462 6.76389 14.6462 7C14.6462 7.23611 14.5698 7.43056 14.417 7.58333L8.91699 13.0833C8.75033 13.25 8.55588 13.3333 8.33366 13.3333C8.11144 13.3333 7.91699 13.25 7.75033 13.0833L5.58366 10.9167C5.43088 10.7639 5.35449 10.5694 5.35449 10.3333C5.35449 10.0972 5.43088 9.90278 5.58366 9.75C5.73644 9.59722 5.93088 9.52083 6.16699 9.52083C6.4031 9.52083 6.59755 9.59722 6.75033 9.75L8.33366 11.3333Z"
                      fill="#786CE6"
                    />
                  </svg>
                </div>
              </div>

              <div
                class="course-dropdown"
                v-else
                @click="chooseCourseMob(course)"
              >
                {{ course.label }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="adaptive-courses">
        <span
          v-for="(course, index) in landingOptions"
          :key="index"
          class="courses"
        >
          <span
            class="course-chosen"
            v-if="courseType.includes(course)"
            @click="chooseCourse(course)"
            >{{ course["value"] }}</span
          >
          <span class="course" v-else @click="chooseCourse(course)">{{
            course["value"]
          }}</span>
        </span>
      </div>
      <div class="adaptive-search">
        <div class="search-div flex-1">
          <label for="search" class="search-label">
            <SearchIcon />
          </label>
          <input
            type="search"
            id="search"
            name="search"
            class="search-input"
            placeholder="Поиск по программам"
            v-model="searchText"
          />
        </div>
        <div
          class="adaptive-filter-logo cursor-pointer flex justify-center items-center rounded-xl bg-gray-100 py-2 px-4"
          @click="openFilters"
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.9585 10.166H2M3.9585 10.166C3.9585 11.2477 4.83534 12.1245 5.91699 12.1245C6.99864 12.1245 7.87549 11.2477 7.87549 10.166M3.9585 10.166C3.9585 9.08437 4.83534 8.20752 5.91699 8.20752C6.99864 8.20752 7.87549 9.08437 7.87549 10.166M15.7095 16.0415C15.7095 14.9599 14.8326 14.083 13.751 14.083C12.6693 14.083 11.7925 14.9599 11.7925 16.0415M15.7095 16.0415C15.7095 17.1232 14.8326 18 13.751 18C12.6693 18 11.7925 17.1232 11.7925 16.0415M15.7095 16.0415H17.668M11.7925 16.0415H2M7.87549 10.166H17.668M15.7095 4.29053C15.7095 3.20888 14.8326 2.33203 13.751 2.33203C12.6693 2.33203 11.7925 3.20888 11.7925 4.29053M15.7095 4.29053C15.7095 5.37217 14.8326 6.24902 13.751 6.24902C12.6693 6.24902 11.7925 5.37217 11.7925 4.29053M15.7095 4.29053L17.668 4.29053M11.7925 4.29053H2"
              stroke="#111827"
              stroke-width="1.7"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="bottom"></div>
    <div class="courses-container">
      <div class="courses-all">
        <div class="courses-options">
          <TheLandingCheckboxItem
            :optionsName="'Направления'"
            :options="landingOptions"
            :checked-values="courseType"
            @checkbox-change="handleCheckboxChange"
          />
          <TheLandingCheckboxItem
            :optionsName="'Уровень'"
            @checkbox-change="handleCheckboxLevelChange"
            :options="landingLevelOptions"
          />
          <TheLandingCheckboxItem
            :optionsName="'Длительность'"
            :options="landingDurationOptions"
            @checkbox-change="handleCheckboxDurationChange"
          />
        </div>
        <div class="courses-list">
          <LandingCourseCard
            v-for="(course, index) in landingCourses"
            :course="course"
            :key="index"
            :level="course.level"
            @click="openCourse(course.program)"
          />
        </div>
      </div>
      <!--      <div class="courses-button-container" @click="addCourses">-->
      <!--        <div class="courses-button">-->
      <!--          <span>Показать еще</span>-->
      <!--          <ArrowDown />-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="courses-form-container">
      <div class="courses-form">
        <div class="courses-form-text">
          <div class="courses-form-text1">Поможем в выборе профессии</div>
          <div class="courses-form-text2">
            Если у вас есть вопросы и вам нужна помощь, оставьте контактные
            данные, и мы ответим на ваши вопросы и поможем найти подходящий курс
          </div>
        </div>

        <div class="courses-form-request">
          <div class="form__item flex flex-col gap-4">
            <InputText
              :placeholder="'Имя'"
              v-model="personName"
              :style="[isPersonName ? '' : 'border: 1px solid rgb(239 68 68);']"
              :label="'Имя'"
              is-required
            />
            <InputText
              :placeholder="'+7'"
              v-model="phone"
              :label="'Номер телефона'"
              :style="[isPhone ? '' : 'border: 1px solid rgb(239 68 68);']"
              is-phone-number
              is-required
            />
            <InputText
              type="email"
              :label="'E-mail'"
              :placeholder="'example@example.com'"
              v-model="email"
            />
            <TheButton bg="primary" class="mt-4" @click="submitForm"
              >Оставить заявку
            </TheButton>
            <p class="text-gray-500" v-if="isForm">
              {{ $t("form_request_done") }}
            </p>
            <p class="text-gray-500" v-if="isERR">Введите правильный email!</p>
            <div class="text-center font-normal text-base text-gray-500">
              Отправляя заявку, вы даёте согласие на обработку своих
              персональных данных в соответствии с
              <span class="form__approval-link"
                >политикой конфиденциальности</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TheLandingCheckboxItem from "../../TheLandingCheckboxItem.vue";
import LandingCourseCard from "../../NewLanding/cards/LandingCourseCard.vue";
// import ArrowDown from "../../../../assets/icons/ArrowDown.vue";
import SearchIcon from "../../../../../assets/icons/SearchIcon.vue";
import InputText from "@/components/InputText.vue";
import TheButton from "@/components/Button/Button.vue";
import axios from "axios";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";

// function extractMonthsFromDuration(duration) {
//   const regex = /(\d+).*?месяц/;
//   const matches = duration.match(regex);
//   if (matches && matches.length > 1) {
//     return parseInt(matches[1]);
//   }
//   return 0;
// }
//
// function isDurationInRange(duration, range) {
//   const ranges = {
//     "Менее 3 месяцев": [0, 3],
//     "3-6 месяцев": [3, 6],
//     "6-12 месяцев": [6, 12],
//   };
//   if (range in ranges) {
//     const [min, max] = ranges[range];
//     return duration >= min && duration <= max;
//   }
//   return false;
// }

export default {
  components: {
    TheButton,
    InputText,
    LandingCourseCard,
    TheLandingCheckboxItem,
    // ArrowDown,
    SearchIcon,
  },
  mixins: [AllCoursesMixin],

  watch: {
    searchText: {
      handler() {
        this.getAllCourses();
      },
      immediate: true,
    },
    "$route.query": {
      handler(query) {
        const { courseType, courseLevel, courseDuration } = query;
        this.courseType = courseType;
        this.courseDuration = courseDuration;
        this.courseLevel = courseLevel;
        this.getAllCourses(query);
      },
      deep: true,
    },
  },

  methods: {
    openFilters() {
      this.$router.push({ path: "all-courses/filters" });
    },

    submitForm() {
      this.isCompanyName = this.companyName !== "";
      this.isPhone = this.phone !== "";
      this.isPersonName = this.personName !== "";

      if (this.email && !this.email.includes("@")) {
        this.isERR = true;
        return;
      } else {
        this.isERR = false;
      }
      if (this.isCompanyName && this.isPersonName && this.isPhone) {
        axios
          .post(
            "https://alphaedu.tech/api/v1/application_forms/like_to_study_application/",
            {
              name: this.personName,
              contact_phone: this.phone,
              email: this.email,
            }
          )
          .catch((err) => {
            console.log(err);
          });
        this.email = "";
        this.phone = "";
        this.personName = "";

        this.isForm = true;
        setTimeout(() => {
          this.isForm = false;
        }, 4000);
      }
    },
  },
  data() {
    return {
      show: false,
      personName: "",
      phone: "",
      email: "",
      isPersonName: true,
      isPhone: true,
      isForm: false,
      isERR: false,

      courseLevel: [],
      courseDuration: [],
      searchText: "",
      formData: { name: "", telephone: "", email: "" },
      courseIndex: 0,
    };
  },
};
</script>

<style scoped lang="postcss">
.page {
  width: 100%;
  background: linear-gradient(90deg, #4776e6 0%, #b587fc 100%);
}

.courses-category {
  padding: 100px 100px 0px 100px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  background: #ffffff;
}

.bottom {
  background: #ffffff;
  width: 100%;
  height: 120px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.courses {
  display: inline-flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
}

.course-chosen {
  font-size: 20px;
  font-weight: 500;
  padding: 6px 16px;
  color: white;
  background: #786ce6;
  border-radius: 24px;
  cursor: pointer;
  margin-right: 16px;
  border: 1px solid #ffffff;
}

.course {
  padding: 6px 16px;
  background: #ffffff;
  border: 1.5px solid rgba(120, 108, 230, 0.5);
  border-radius: 24px;
  cursor: pointer;
  margin-right: 16px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #000000;
}

.course-chosen-dropdown {
}

.title {
  font-size: 48px;
  font-weight: 800;
  color: #1f2937;
}

.search-input {
  width: 100%;
  padding-left: 56px;
  padding-right: 8px;
  background: #f3f4f6;
  border-radius: 12px;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  border: none;
}

input::placeholder {
  color: #6b7280;
}

.search-label {
  position: absolute;
  top: 27%;
  left: 18px;
}

.search-div {
  position: relative;
}

.courses-container {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 64px;
  padding: 100px 100px 40px 100px;
  flex-grow: 0;
}

.courses-all {
  display: flex;
  gap: 48px;
}

.courses-options {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.courses-list {
  align-self: self-start;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.courses-list div {
  //align-self: self-start;
}

.courses-button-container {
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
}

.courses-button {
  display: flex;
  gap: 14px;
  align-items: baseline;
  padding: 16px 24px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

.courses-form-container {
  padding: 120px 80px 110px 80px;
  background: white;
}

.courses-form {
  display: flex;
  flex-wrap: wrap;
  padding: 64px 80px;
  background: #2b2e3b;
  border-radius: 40px;
}

.courses-form-text {
  flex-basis: 50%;
  padding-right: 20px;
}

.courses-form-request {
  flex-basis: 50%;
  padding-left: 20px;
}

.courses-form-text1 {
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  background: linear-gradient(
    89.23deg,
    #f98aff -1.14%,
    #b089ff 31.11%,
    #878eff 69.22%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 15px;
}

.courses-form-text2 {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: #e5e7eb;
}

form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form-div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.form-label {
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #e5e7eb;
}

.form-input {
  width: 100%;
  padding: 14px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}

.form-btn {
  margin-top: 24px;
  padding: 13px 25px;
  width: 100%;
  background: #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}

.form-approval {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #e5e7eb;
}

.form-approval-link {
  color: #786ce6;
}

.adaptive-courses2 {
  display: none;
}

.adaptive-courses {
  display: flex;
  flex-wrap: wrap;
}

.adaptive-filter-logo {
  display: none;
}

@media (max-width: 650px) {
  .courses-category {
    padding: 32px 16px;
    gap: 12px;
  }

  .adaptive-courses2 {
    display: flex;
  }

  .adaptive-courses {
    display: none;
  }

  .adaptive-filter-logo {
    display: flex;
  }
  .adaptive-search {
    display: flex;
    gap: 16px;
  }
  .bottom {
    display: none;
  }
  .courses-container {
    padding: 32px 16px;
  }
  .courses-options {
    display: none;
  }
  .courses-list {
    display: flex;
    flex-direction: column;
  }
  .courses-form-container {
    padding: 48px 16px;
  }
  .courses-form {
    padding: 32px 20px;
    gap: 32px;
  }
  .courses-form-text {
    flex-basis: 100%;
  }
  .courses-form-text1 {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .courses-form-text2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .courses-form-request {
    flex-basis: 100%;
    padding: 0;
  }
  .search-input {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
}
</style>
