<template>
  <BreadcrumbsComponent :breadcrumbs="breadcrumbs" />
  <div>
    <div class="w-full flex justify-between items-center">
      <h1 class="text-2xl leading-8 font-semibold text-gray-900 mb-8">
        {{ publication.title }}
      </h1>
      <Button bg="secondary" @click="deleteNewsPublication">{{
        $t("delete_news")
      }}</Button>
    </div>
    <div class="grid grid-cols-4 gap-4">
      <template v-for="card of cards" :key="card.name">
        <div class="rounded-xl shadow-md shadow-md bg-white">
          <router-link :to="card.path">
            <div class="py-6 px-6 flex flex-col gap-6">
              <component :is="card.icon" class="w-6 h-6 text-indigo-600" />
              <div class="flex flex-col gap-2">
                <h3 class="text-xl leading-7 font-semibold text-gray-900">
                  {{ card.name }}
                </h3>
                <p class="text-sm leading-5 font-normal text-gray-500">
                  {{ card.description }}
                </p>
              </div>
            </div>
          </router-link>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { DELETE_NEWS_PUBLICATION, GET_NEWS_PUBLICATION } from "@/store/news";
import BreadcrumbsComponent from "@/components/Breadcrumbs/Breadcrumbs";
import Button from "@/components/Button/Button";
import {
  ClipboardListIcon,
  LockOpenIcon,
  ServerIcon,
} from "@heroicons/vue/outline";

export default {
  components: { BreadcrumbsComponent, Button },
  data() {
    return {
      publication: {},
      breadcrumbs: [],
      cards: [],
    };
  },
  async mounted() {
    const id = this.$route.params.id;
    this.publication = await this[GET_NEWS_PUBLICATION](id);
    this.setBreadcrumbs();
    this.setCards();
  },
  methods: {
    ...mapActions([GET_NEWS_PUBLICATION, DELETE_NEWS_PUBLICATION]),
    setCards() {
      const id = this.$route.params.id;
      this.cards = [
        {
          icon: ClipboardListIcon,
          name: this.$t("short_description"),
          description: this.$t("short_description_description"),
          path: `/news/form/?id=${id}`,
        },
        {
          icon: ServerIcon,
          name: this.$t("news_content"),
          description: this.$t("news_content_description"),
          path: `/news/${id}/content/`,
        },
        {
          icon: LockOpenIcon,
          name: this.$t("access"),
          description: this.$t("access_description"),
          path: `/news/${id}/content/`,
        },
      ];
    },
    setBreadcrumbs() {
      this.breadcrumbs = [
        {
          name: this.$t("news_publication"),
          path: "/news/list",
        },
        {
          name: this.publication.title,
          path: this.$route.path,
        },
      ];
    },
    async deleteNewsPublication() {
      await this[DELETE_NEWS_PUBLICATION](this.$route.params.id);
      this.$router.push("/news/list");
    },
  },
};
</script>
