import { SET_LOADING } from "@/store/core";

export const GET_LANDING_TEACHERS = "GET_LANDING_TEACHERS";
export const SET_LANDING_TEACHERS = "SET_LANDING_TEACHERS";
export const SET_LANDING_TEACHERS_OPTIONS = "SET_LANDING_TEACHERS_OPTIONS";
export const GET_LANDING_TEACHERS_OPTIONS = "GET_LANDING_TEACHERS_OPTIONS";
export const ADD_LANDING_TEACHER = "ADD_LANDING_TEACHERS";
export const DELETE_LANDING_TEACHER = "DELETE_LANDING_TEACHER";
export const ADD_LANDING_TEACHER_IMAGE = "ADD_LANDING_TEACHER_IMAGE";
export const ADD_LANDING_TEACHER_COMPANY = "ADD_LANDING_TEACHERS_COMPANY";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;

  const state = {
    landing_teachers: [],
    landing_teachers_options: ["av"],
  };

  const actions = {
    [GET_LANDING_TEACHERS]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.landing_teachers.get_landing_teachers(params);
      commit(SET_LANDING_TEACHERS, data);
      commit(SET_LOADING, false);
    },
    [GET_LANDING_TEACHERS_OPTIONS]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } =
        await $api.landing_teachers.get_landing_teachers_options();
      commit(SET_LANDING_TEACHERS_OPTIONS, data);
      commit(SET_LOADING, false);
    },
    [ADD_LANDING_TEACHER]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      await $api.landing_teachers.add_landing_teacher(params);
      commit(SET_LOADING, false);
    },
    [ADD_LANDING_TEACHER_IMAGE]: async ({ commit }, { id, file }) => {
      commit(SET_LOADING, true);
      const formData = new FormData();
      formData.append("image", file);
      await $api.landing_teachers.add_landing_teacher_image(id, formData);
      commit(SET_LOADING, false);
    },

    [ADD_LANDING_TEACHER_COMPANY]: async ({ commit }, { id, file }) => {
      commit(SET_LOADING, true);
      const formData = new FormData();
      formData.append("company_image", file);
      await $api.landing_teachers.add_landing_teacher_company(id, formData);
      commit(SET_LOADING, false);
    },
  };

  const mutations = {
    [SET_LANDING_TEACHERS]: (state, payload) => {
      state.landing_teachers = payload;
    },
    [SET_LANDING_TEACHERS_OPTIONS]: (state, payload) => {
      state.landing_teachers_options = payload;
    },
  };
  const getters = {
    landing_teachers: (state) => state.landing_teachers,
    landing_teachers_options: (state) => state.landing_teachers_options,
  };

  return {
    actions,
    mutations,
    state,
    getters,
  };
};

export default (app) => createStore(app);
