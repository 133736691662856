<template>
  <div class="bg-gray-50 rounded-xl p-3 flex place-content-between cursor-pointer overflow-hidden max-w-sm	">
    <ThePopper class="text-xs leading-5 font-medium text-gray-800 flex-1 truncate text-ellipsis	 ">
          {{name}}
      <template #content>
          {{ name }}
      </template>
    </ThePopper>
    <div  class="flex flex-row place-items-center shrink-0">
      <template v-if="status==='loading'">
        <span class="text-xs leading-4 font-medium text-gray-400 mr-2">{{$t('uploading_in_progress')}}</span>
        <RefreshIcon class="text-green-500 w-5 h-5 animate-spin"/>
      </template>
      <template v-else-if="status==='success'">
        <span class="text-xs leading-4 font-medium text-gray-400 mr-2">{{$t('uploading_finished')}}</span>
        <CheckCircleIcon class="text-green-500 w-5 h-5"/>
      </template>
      <template v-else-if="status==='error'">
        <span class="text-xs leading-4 font-medium text-red-600 mr-2">{{$t('uploading_error')}}</span>
        <RefreshIcon class="text-gray-400 w-5 h-5 mr-2" @click="retryUpload"/>
        <TrashIcon class="text-gray-400 h-5" @click="removeFile"/>
      </template>
      <template v-else>
        <span class="text-xs leading-4 font-medium text-gray-400 mr-2">
          {{formattedSize}}
        </span>
        <TrashIcon class="text-gray-400 h-5" @click="removeFile"/>
      </template>
    </div>
  </div>
</template>
<script>
  import  {RefreshIcon,CheckCircleIcon,TrashIcon} from "@heroicons/vue/outline"
  import prettyBytes from "pretty-bytes";
  import ThePopper from "@/components/ThePopper";
  export default {
    components:{RefreshIcon,CheckCircleIcon,TrashIcon,ThePopper},
    props:{
      name:{
        type:String,
        default:''
      },
      status:{
        type:String,
        default:''
      },
      size:{
        type:Number,
        default:0
      },
      id:{
        type:[Number,String],
        default:-1
      }
    },
    methods:{
      removeFile(){
          this.$emit("removeFile",this.id)
      },
      retryUpload(){
          this.$emit('retryUpload',this.id)
      }
    },
    computed:{
      formattedSize(){
        return prettyBytes(this.size)
      },
    }
  }
</script>