import { SET_LOADING } from "@/store/core";

export const GET_NEWS_LIST = "GET_NEWS_LIST";
export const GET_NEWS_PUBLICATION = "GET_NEWS_PUBLICATION";
export const CREATE_NEWS_PUBLICATION = "CREATE_NEWS_PUBLICATION";
export const UPDATE_NEWS_PUBLICATION = "UPDATE_NEWS_PUBLICATION";
export const DELETE_NEWS_PUBLICATION = "DELETE_NEWS_PUBLICATION";
export const CREATE_PUBLICATION_BLOCK = "CREATE_PUBLICATION_BLOCK";
export const DELETE_PUBLICATION_BLOCK = "DELETE_PUBLICATION_BLOCK";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;

  const state = {};

  const actions = {
    [GET_NEWS_LIST]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.news.get_news_list();
      commit(SET_LOADING, false);
      return data;
    },
    [GET_NEWS_PUBLICATION]: async ({ commit }, id) => {
      commit(SET_LOADING, true);
      const { data } = await $api.news.get_news_publication(id);
      commit(SET_LOADING, false);
      return data;
    },
    [CREATE_NEWS_PUBLICATION]: async ({ commit }, form) => {
      commit(SET_LOADING, true);
      const formData = new FormData();
      Object.keys(form).forEach((key) => {
        formData.append(
          key,
          key === "short_description" ? JSON.stringify(form[key]) : form[key]
        );
      });

      const { data } = await $api.news.create_publication(formData);
      commit(SET_LOADING, false);
      return data;
    },
    [UPDATE_NEWS_PUBLICATION]: async ({ commit }, publication) => {
      commit(SET_LOADING, true);
      console.log(publication);
      const formData = new FormData();
      Object.keys(publication).forEach((key) => {
        formData.append(
          key,
          key === "short_description" || key === "content"
            ? JSON.stringify(publication[key])
            : publication[key]
        );
      });

      publication.content.forEach(c => {
        formData.append(c.id, c.content);
      });

      const { data } = await $api.news.update_publication(
        publication.id,
        formData
      );
      commit(SET_LOADING, false);
      return data;
    },
    [DELETE_NEWS_PUBLICATION]: async ({ commit }, newsId) => {
      commit(SET_LOADING, true);
      await $api.news.delete_publication(newsId);
      commit(SET_LOADING, false);
    },
    [CREATE_PUBLICATION_BLOCK]: async ({ commit }, { newsId, block }) => {
      commit(SET_LOADING, true);
      const formData = new FormData();
      Object.keys(block).forEach((key) => {
        formData.append(
          key,
          key === "text" ? JSON.stringify(block[key]) : block[key]
        );
      });

      const { data } = await $api.news.create_publication_block(
        newsId,
        formData
      );
      commit(SET_LOADING, false);
      return data;
    },
    [DELETE_PUBLICATION_BLOCK]: async ({ commit }, id) => {
      commit(SET_LOADING, true);
      const { data } = await $api.news.delete_publication_block(id);
      commit(SET_LOADING, false);
      return data;
    },
  };

  return { state, actions };
};

export default (app) => createStore(app);
