<template>
  <div>
    <VueTimepicker
        v-model="localValue"
        format="hh:mm"
        manual-input
        hide-dropdown
        :placeholder="$t(placeholder)"
        :hour-label="$t('hours_many')"
        :minute-label="$t('minutes')"
    >
      <template v-slot:icon>
        <ClockIcon class="text-gray-400 ml-4"/>
      </template>

    </VueTimepicker>
  </div>
</template>
<script>
  require('vue3-timepicker/dist/VueTimepicker.css')
  import VueTimepicker from 'vue3-timepicker'
  import {ClockIcon} from "@heroicons/vue/solid";

  export default {
    props:{
      modelValue:{},
      placeholder:{
        type:String,
        default:'00:00'
      }
    },
    components:{
      VueTimepicker,ClockIcon
    },
    computed:{
      localValue:{
        get(){
          return this.modelValue
        },
        set(val){
          this.$emit('update:modelValue',val)
        }
      }
    }
  }
</script>
<style lang="postcss">
  .select-list .active{
    @apply bg-indigo-600 !important;
  }
  .vue__time-picker-input{
    @apply rounded-xl border-[1px] border-indigo-600 py-2 w-[162px] h-[50px] !important;
  }
  .vue__time-picker-input::placeholder {
    @apply text-base leading-6 font-normal text-gray-400  !important;
  }
  .vue__time-picker .custom-icon{
    @apply w-[40px];
  }

</style>