<template>
  <AddToCartModal @close="closeCartModal" :modal-open="openModal" />
  <div>
    <div class="course">
      <div
        class="course__info grid grid-cols-2 bg-white"
        id="landing-course-form"
      >
        <CourseInfoVue :course="course" />
        <TheLandingForm :course-info="courseInfo" @open="openCartModal" />
      </div>
      <div class="bottom"></div>
      <div class="company">
        <div class="company__div-1">
          <div class="flex flex-col gap-6">
            <div
              class="font-size-40px font-bold text-white adaptive-company-title"
            >
              Навык, который будет востребован
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Превращайте свое творчество в успешный бизнес с нашей передовой
              платформой ИИ! Наши инструменты предназначены для артистов,
              маркетологов, предпринимателей и творческих личностей, стремящихся
              максимизировать свою продуктивность, уникальность и доход.
              Откройте для себя новые возможности для своих проектов, используя
              мощь искусственного интеллекта.
            </div>
          </div>
          <div
            class="flex-shrink-0 flex items-center justify-center company__img-div"
          >
            <img
              src="../../../../assets/images/landing/gen-ai-tag.png"
              alt=""
              class="company__img"
            />
          </div>
        </div>
        <div class="company__div-2">
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Управление производительностью
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Автоматизировать рутинные процессы и достигать поставленных целей
              быстрее и эффективнее.
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Маркетинг и создание контента
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Запускать успешные маркетинговые кампании с помощью инструментов
              ИИ, ориентированных на максимальное взаимодействие с аудиторией
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Оптимизация процессов
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Автоматизировать процессы написания и создавать качественные
              тексты. тенге.
            </div>
          </div>
        </div>
        <div class="flex gap-3">
          <div class="font-medium text-xl company__sign cursor-pointer">
            Записаться на программу
          </div>
          <div><PurpleArrowRightIcon :fill="'#78CFFF'" /></div>
        </div>
      </div>
    </div>
    <CourseAdvantagesVue />
    <CourseFlexibilityVue />
    <CourseFitPeopleVue />

    <div class="trajectory">
      <div class="flex flex-col gap-120px">
        <div class="flex flex-col gap-8">
          <div class="font-bold before-start__title adaptive-company-title">
            До старта
          </div>
          <div class="grid grid-cols-3 gap-6 adaptive-grid">
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с платформой, графиком обучения
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Познакомим с вашим учебным расписанием, разберемся, где смотреть
                уроки, как общаться с преподавателем и куда сдавать домашние
                задания.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с кураторами и преподавателями
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Представим ваших первых преподавателей, познакомим с кураторами,
                добавим в учебные чаты для общения с другими студентами.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Эффективное обучение
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Научим эффективно учиться, быстро и навсегда запоминать
                информацию любой сложности и объема.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Здоровье Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как сохранить здоровье и зрение, какие недуги у
                профессиональных разработчиков и как их избежать, научим
                обустраивать место для комфортной работы и дадим рекомендации по
                физ. тренировкам для ИТ-инженеров.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Ключевые навыки Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как эффективно управлять своим временем и энергией.
                Вы узнаете, как избежать выгорания и всегда оставаться в хорошем
                настроении, даже если что-то идет не по плану.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Английский язык для Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем о роли английского языка в обучении. Дадим
                реккомендации, как эффективно подтянуть исходный уровень до
                требуемого. Вы получите языковую базу для старта обучения.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CourseNeededMaterials :materials="materials" />
    <CourseMainBlockVue
      :new-learn-bullet="learnBullet"
      :new-course-step="courseStep"
      :gen-a-i="true"
    />
    <CourseFormVue />
    <CourseNextStageVue />
    <!--    <div class="authors">
              <div class="flex flex-col gap-4">
                <div class="font-size-40px text-gray-800 font-bold">
                  Авторы программы
                </div>
                <div class="text-gray-500 text-lg">
                  Реальные практики ИТ с многолетним опытом работы в лидирующих проектах
                </div>
              </div>
              <div class="grid grid-cols-2 gap-6">
                <div class="bg-gray-radius py-10 px-8 flex flex-col gap-10">
                  <div class="flex gap-6">
                    <div>
                      <img src="../../../../assets/images/landing/avatar.png" alt="" />
                    </div>
                    <div class="flex flex-col gap-2">
                      <div class="font-semibold text-2xl">Имя Фамилия</div>
                      <div class="font-normal text-lg text-gray-500">
                        Генеральный директор Alpha Education
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-4">
                    <div class="flex gap-4">
                      <div class="authors__bullet"></div>
                      <div class="font-normal text-lg pt-0">
                        Создатель торговой платформы АГРО24 и образовательных проектов:
                        A2.LIFE, ПОТОК24 и iTec Group.
                      </div>
                    </div>
                    <div class="flex gap-4">
                      <div class="authors__bullet"></div>
                      <div class="font-normal text-lg pt-0">
                        Выпускник факультета Программного обеспечения в сфере
                        Информационных технологий Белорусского государственного
                        университета информатики и радиоэлектроники.
                      </div>
                    </div>
                    <div class="flex gap-4">
                      <div class="authors__bullet"></div>
                      <div class="font-normal text-lg pt-0">
                        В прошлом генеральный директор SaaS провайдера «Мегаплан»,
                        совладелец и управляющий партнер тренинговой компании «Бизнес
                        Молодость».
                      </div>
                    </div>
                  </div>
                </div>
                <div class="bg-gray-radius py-10 px-8 flex flex-col gap-10">
                  <div class="flex gap-6">
                    <div>
                      <img src="../../../../assets/images/landing/avatar.png" alt="" />
                    </div>
                    <div class="flex flex-col gap-2">
                      <div class="font-semibold text-2xl">Имя Фамилия</div>
                      <div class="font-normal text-lg text-gray-500">
                        Генеральный директор Alpha Education
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col gap-4">
                    <div class="flex gap-4">
                      <div class="authors__bullet"></div>
                      <div class="font-normal text-lg pt-0">
                        Создатель торговой платформы АГРО24 и образовательных проектов:
                        A2.LIFE, ПОТОК24 и iTec Group.
                      </div>
                    </div>
                    <div class="flex gap-4">
                      <div class="authors__bullet"></div>
                      <div class="font-normal text-lg pt-0">
                        Выпускник факультета Программного обеспечения в сфере
                        Информационных технологий Белорусского государственного
                        университета информатики и радиоэлектроники.
                      </div>
                    </div>
                    <div class="flex gap-4">
                      <div class="authors__bullet"></div>
                      <div class="font-normal text-lg pt-0">
                        В прошлом генеральный директор SaaS провайдера «Мегаплан»,
                        совладелец и управляющий партнер тренинговой компании «Бизнес
                        Молодость».
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>-->

    <CourseTeachersVue
      :teachers="teachers"
      :scrollToForm="scrollToForm"
      :custom="true"
    />
    <CourseMaterialVue />
    <GuaranteeSliderVue />
    <OurStudentsWorks />
    <CourseReferralVue :col="false" />
    <CourseFaq :new-faq="faq" />
  </div>
</template>

<script>
import PurpleArrowRightIcon from "../../../../assets/icons/PurpleArrowRightIcon.vue";
// import CourseFaq from "./CourseFaq.vue";
import GuaranteeSliderVue from "./GuaranteeSlider.vue";
import CourseReferralVue from "./CourseReferral.vue";
import CourseMaterialVue from "./CourseMaterial.vue";
import CourseTeachersVue from "./CourseTeachers.vue";
import CourseNextStageVue from "./CourseNextStage.vue";
import CourseFormVue from "./CourseForm.vue";
import CourseMainBlockVue from "./CourseMainBlock.vue";
import CourseFitPeopleVue from "./CourseFitPeople.vue";
import CourseFlexibilityVue from "./CourseFlexibility.vue";
import CourseAdvantagesVue from "./CourseAdvantages.vue";
import CourseInfoVue from "./CourseInfo.vue";
import CourseFaq from "@/views/core/Landing/Courses/CourseFaq.vue";
import axios from "axios";
import TheLandingForm from "@/views/core/Landing/NewLanding/sections/TheLandingForm.vue";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";
import { ShoppingCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartMixin";
import AddToCartModal from "@/views/core/Landing/NewLanding/sections/AddToCartModal.vue";
import { AddToCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/AddToCartMixin";
import CourseNeededMaterials from "@/views/core/Landing/Courses/CourseNeededMaterials.vue";
import OurStudentsWorks from "@/views/core/Landing/Courses/OurStudentsWorks.vue";

export default {
  components: {
    OurStudentsWorks,
    CourseNeededMaterials,
    AddToCartModal,
    TheLandingForm,
    CourseFaq,
    CourseInfoVue,
    CourseAdvantagesVue,
    CourseFlexibilityVue,
    CourseFitPeopleVue,
    CourseMainBlockVue,
    CourseFormVue,
    CourseNextStageVue,
    CourseTeachersVue,
    CourseMaterialVue,
    CourseReferralVue,
    GuaranteeSliderVue,
    // CourseFaq,
    PurpleArrowRightIcon,
  },
  props: {
    coursee: {
      type: Object,
    },
  },

  methods: {
    scrollToForm() {
      console.log("Course scroll");
      const formElement = document.getElementById("landing-course-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    submitForm() {
      this.isCompanyName = this.companyName !== "";
      this.isPhone = this.phone !== "";
      this.isPersonName = this.personName !== "";

      if (this.email && !this.email.includes("@")) {
        this.isERR = true;
        return;
      } else {
        this.isERR = false;
      }
      if (this.isCompanyName && this.isPersonName && this.isPhone) {
        axios
          .post(
            "https://alphaedu.tech/api/v1/application_forms/like_to_study_application/",
            {
              name: this.personName,
              contact_phone: this.phone,
              email: this.email,
            }
          )
          .catch((err) => {
            console.log(err);
          });
        this.email = "";
        this.phone = "";
        this.personName = "";

        this.isForm = true;
        setTimeout(() => {
          this.isForm = false;
        }, 4000);
      }
    },
  },
  mixins: [AllCoursesMixin, ShoppingCartMixin, AddToCartMixin],
  async mounted() {
    await this.getAllCourses();
    this.courseInfo = this.landingCourses.length > 0 && this.landingCourses[0];
  },
  data() {
    return {
      materials: {
        title:
          "В зависимости от конкретных разделов и заданий курса, могут потребоваться следующие ресурсы:",
        mat: [
          "Подписка на ChatGPT: Это позволит вам использовать расширенные функции ChatGPT для управления задачами, автоматизации процессов и генерации контента.",
          "Доступ к платформе Midjourney (или аналогичным): Для практических занятий по созданию визуального контента с использованием AI.",
          "Подписки на специализированные инструменты AI для голоса и музыки: Например, платформы для создания AI-сгенерированной речи и музыкальные генераторы, что позволит освоить производство аудиоконтента на новом уровне.",
          "Доступ к инструментам для работы с изображениями и видео: Включая программы типа DALL-E 2 для генерации и редактирования изображений с помощью искусственного интеллекта.",
          "Инструменты для автоматизации и оптимизации рабочих процессов: Например, сервисы для интеграции с Google Sheets/Excel для обработки и анализа данных.",
        ],
        title2: "Общее требование:",
        mat2: [
          "Для полноценной работы со всеми представленными инструментами может потребоваться приобретение индивидуальных или корпоративных подписок на каждый из инструментов. Важно заранее ознакомиться с условиями предоставления услуг и определить, какие подписки наиболее подходят для ваших учебных и профессиональных целей.",
          "Доступ к стабильному интернет-соединению и компьютеру с достаточной вычислительной мощностью для работы с программным обеспечением.",
          "Базовые знания в области информационных технологий и желание учиться новому, поскольку многие инструменты и технологии могут быть сложны для понимания без предварительной подготовки.",
        ],
      },
      courseInfo: {},
      searchText: "Generative AI",
      teachers: [
        {
          name: "Ботагоз Абсаттар",
          position: "Основатель и PM Alpha Education",
          courses: "Бизнес анализ, Веб-разработка, Компьютерные сети",
          profession:
            "Основатель и преподаватель школы программирования Alpha Education. Более 10 лет занимается веб-разработкой, работала фронт-енд разработчиком, бизнес-аналитиком в IT-компаниях Казахстана, а также имеет опыт в сфере информационной безопасности.  Сейчас является проджект менеджером по созданию веб-приложений. ",
          education: [
            "Бакалавр Компьютерных Наук (Eurasian National University)",
            "Магистр Компьютерных Наук (Eurasian National University)",
            "Магистр Телекоммуникации и связи (University College of London)",
          ],
          image: "botagoz.jpeg",
          programs: ["Веб-разработка"],
        },
      ],

      courseStep: [
        {
          title: "1-2 неделя",
          text: [
            "Введение в курс. Что такое веб. Основы веб-технологий. Интернет, W3",
            "Различие между фронтендом и бекендом",
            "Веб-стандарты и протоколы",
          ],
          open: false,
        },
        {
          title: "3-4 неделя",
          text: [
            "Основы HTML и структура веб-страницы",
            "Работа с тегами, атрибутами и элементами",
            " Оформление веб-страниц с помощью CSS. Синтаксис CSS. Псевдоклассы. Разметка таблиц.",
            "    Создание адаптивного дизайна.",
          ],
          open: false,
        },
        {
          title: "5-6 неделя",
          text: [
            " CSS-макеты. Bootstrap4. Работа с Github.",
            "Создание макета (практика)",
            "Flexbox. Введение в препроцессоры.",
          ],
          open: false,
        },
      ],
      learnBullet: [
        "Основы HTML/CSS и JavaScript",
        "Фреймворки и библиотеки: Изучение популярных фреймворков и библиотек, таких как React, Vue.js, для эффективной разработки фронтенд-приложений.",
        "Адаптивный и отзывчивый дизайн: Создание веб-страниц, которые отображаются корректно на разных устройствах и экранах.",
        "Языки программирования: Изучение языков программирования, таких как Python, Node.js, Express.js, для создания серверной логики и обработки данных.",
        "Разработка баз данных: MongoDB",
        "API и веб-серверы. Создание API для взаимодействия с фронтенд-частью и разработка веб-серверов для обработки запросов",
      ],
      course: {
        name: "Generative AI",
        level: "Junior",
        program: "ИИ",
        description:
          "Этот курс направлен на изучение концепций, методов и практического применения Generative AI. Участники научатся создавать генеративные модели, которые могут генерировать новый контент, такой как тексты, изображения, музыку и многое другое. Курс предназначен для того, чтобы дать комплексное понимание возможностей Generative AI и его применения в различных сферах деятельности. Студенты изучат ключевые технологии и алгоритмы, лежащие в основе Generative AI, включая генеративно-состязательные сети (GANs), вариационные автокодировщики (VAEs) и трансформеры. Особое внимание будет уделено практическому применению этих технологий для создания инновационных решений и продуктов.",
        skills: [
          "ChatGPT",
          "AI Voice Tools",
          "AI Photo Tools",
          "AI Music Tools",
          "DALL-E 2",
        ],
        duration: "4 недели",
        start_date: "15 марта",
        price: "100 000 ₸",
      },
      faq: [
        {
          question: "Что такое веб-разработка?",
          answer:
            "Веб-разработка - это процесс создания и поддержки веб-сайтов или приложений, которые функционируют в интернете. Включает в себя работу с фронтендом (клиентская часть) и бекендом (серверная часть).",
          open: false,
        },
        {
          question: "Чем отличается фронтенд от бекенда?",
          answer:
            "Фронтенд отвечает за пользовательский интерфейс и взаимодействие с ним, включая дизайн, верстку и программирование на языках, таких как HTML, CSS и JavaScript. Бекенд, с другой стороны, отвечает за обработку данных, хранение информации и выполнение бизнес-логики на сервере, используя языки программирования, такие как Python, Ruby или Java.",
          open: false,
        },
        {
          question: "Какие языки программирования используются на фронтенде?",
          answer:
            "Языки программирования для бекенда могут варьироваться, но некоторые из наиболее популярных включают Python, Ruby, Java, PHP и JavaScript (с использованием платформы Node.js). Эти языки используются для создания серверной логики, обработки данных и взаимодействия с базами данных.",
          open: false,
        },
        {
          question: "Какая разница между SQL и NoSQL базами данных?",
          answer:
            "SQL (Structured Query Language) и NoSQL (Not Only SQL) - это два основных типа баз данных. SQL базы данных используют структурированные таблицы и SQL запросы для работы с данными. NoSQL базы данных, например MongoDB или Cassandra, предлагают более гибкие модели данных, обычно без использования таблиц и SQL. Выбор между SQL и NoSQL зависит от требований проекта.",
          open: false,
        },
        {
          question: "Какие инструменты используются в веб-разработке?",
          answer:
            "Веб-разработка включает в себя использование различных инструментов. Некоторые популярные инструменты включают текстовые редакторы (например, Visual Studio Code, Sublime Text), системы контроля версий (например, Git), фреймворки (например, React, Angular, Django), инструменты для сборки и автоматизации задач (например, Webpack, Gulp) и многое другое.",
          open: false,
        },
        {
          question: "Что такое API и как он используется в веб-разработке?",
          answer:
            "API (Application Programming Interface) - это набор правил и протоколов, которые позволяют различным программам взаимодействовать друг с другом. В веб-разработке API часто используется для обмена данными между фронтендом и бекендом. Например, веб-приложение может использовать API для получения данных с сервера или отправки данных на сервер.",
          open: false,
        },
        {
          question: "Как развернуть веб-приложение на сервере?",
          answer:
            "Развертывание веб-приложения на сервере включает несколько шагов. Один из способов - это использование виртуального сервера (например, с помощью облачных провайдеров, таких как Amazon Web Services, Microsoft Azure или DigitalOcean), где вы можете установить и настроить необходимое программное обеспечение, такое как веб-сервер (например, Apache или Nginx) и настроить доступ к вашему приложению через интернет.",
          open: false,
        },
        {
          question:
            "Какие навыки и знания необходимы для стать веб-разработчиком?",
          answer:
            "Для становления веб-разработчиком необходимо обладать хорошими знаниями HTML, CSS и JavaScript для работы с фронтендом. Для работы с бекендом полезно знать языки программирования, такие как Python, Ruby, Java или PHP, а также иметь понимание работы баз данных и протоколов обмена данными. Отличные аналитические и проблемно-ориентированные навыки также важны для успешной веб-разработки.",
          open: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.course {
  background: #2b2e3b;
}
.course__info {
  background: #f4f5fa;
  padding: 40px 100px;
}
.bottom {
  background: #f4f5fa;
  width: 100%;
  height: 120px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.company {
  padding: 64px 100px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.company__div-1 {
  display: flex;
  gap: 90px;
}
.company__div-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}
.company__sign {
  color: #78cfff;
}
.company__img {
  width: 196px;
  height: 196px;
}

.demanded {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.demanded__box-1 {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-1__border {
  display: inline-block;
  border-bottom: 2px solid #593ed3;
}
.demanded__box-1 {
  flex-basis: 50%;
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-2 {
  flex-basis: 50%;
  background: #eee6ff;
  border-radius: 32px;
}

.bg-gradient {
  background: linear-gradient(
    89.33deg,
    #eb8aff 7.2%,
    #b792ff 42.58%,
    #898fff 84.4%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.authors {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.authors__bullet {
  margin-top: 10px;
  display: inline-block;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #786ce6;
}

.font-size-40px {
  font-size: 40px;
}
.font-size-36px {
  font-size: 36px;
}
.font-size-28px {
  font-size: 28px;
}
.padding-100px {
  padding: 0px 100px;
}

.form__item {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 32px;
}
.form__item input {
  border: 1px solid #9ca3af;
  border-radius: 16px;
}
.form__btn {
  width: 100%;
  background: #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__btn-white {
  width: 100%;
  background: #fff;
  color: #786ce6;
  border: 2px solid #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__approval-link {
  color: #786ce6;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.text-gray-100 {
  color: #f3f4f6;
}
.text-gray-50 {
  color: #f9fafb;
}
.study-plan2 {
  display: none;
}

@media (max-width: 650px) {
  .course__info {
    padding: 32px 16px;
  }
  #landing-course-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .course__blocks {
    padding: 0;
  }
  .bottom {
    display: none;
  }
  .company {
    padding: 32px 16px;
  }
  .company__div-1 {
    padding: 0;
    width: 100%;
    flex-basis: 100%;
  }
  .company__div-2 {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  .demanded {
    padding: 48px 16px;
  }
  .adaptive-demanded {
    flex-direction: column;
  }
  .trajectory {
    padding: 64px 16px;
  }
  .adaptive-trajectory {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #d1d5db;
  }
  .adaptive-duration {
    flex-direction: column;
    align-items: center;
  }
  .length__item {
    width: fit-content;
  }
  .company__img-div {
    display: none;
  }
  .adaptive-grid {
    display: flex;
    flex-direction: column;
  }
  .study-plan {
    display: none;
  }
  .study-plan2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
  }

  .adaptive-company-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .adaptive-company-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-company-subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
}
</style>
