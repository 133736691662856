<template>
  <TheLandingHeader />
  <main class="py-8 md:py-16 px-10 md:px-20">
    <div
      class="w-full rounded-2xl overflow-hidden shadow-lg shadow-lg"
      v-if="!!newsItem"
    >
      <img
        :src="newsItem.cover"
        :alt="newsItem.title"
        class="object-cover w-full h-127"
      />
      <div class="px-16 py-10">
        <div
          class="flex gap-5 border-b-2 pb-6 mb-10 text-sm leading-5 font-medium text-gray-500"
        >
          <router-link to="/">EdCrunch Academy</router-link>
          <span>/</span>
          <router-link to="/news">{{ $t("news") }}</router-link>
          <span>/</span>
          <router-link :to="$route.path" class="text-indigo-600"
            >{{ newsItem.title }}
          </router-link>
        </div>
        <h2
          class="text-base leading-6 font-semibold tracking-wider uppercase mb-2"
          :style="{ color: newsItem.topic_color }"
        >
          #{{ newsItem.topic }}
        </h2>
        <h1
          class="text-4xl leading-10 font-extrabold tracking-tight text-gray-900 mb-8"
        >
          {{ newsItem.title }}
        </h1>
        <component
            v-for="contentItem in newsItem.content"
            :is="contentItem.type"
            class="mb-6"
            :id="contentItem.id"
            :key="`content_${contentItem.id || contentItem.key}`"
            :content="contentItem.content"
            :is_disabled="true"
            :url="contentItem.content"
            :src="contentItem.src"
            :image="contentItem.content"
        />
      </div>
    </div>
  </main>
  <TheLandingFooter />
</template>

<script>
import TheLandingHeader from "@/views/core/Landing/TheLandingHeader";
import { mapActions } from "vuex";
import TheLandingFooter from "@/views/core/Landing/TheLandingFooter";
import { GET_NEWS_PUBLICATION } from "@/store/news";
import TextContent from "@/views/core/News/components/preview/TextContent";
import ImageContent from "@/views/core/News/components/preview/ImageContent";
import AudioContent from "@/views/core/News/components/preview/AudioContent";
import VideoContent from "@/views/core/News/components/preview/VideoContent";

export default {
  components: {
    TheLandingFooter,
    TheLandingHeader,
    text: TextContent,
    image: ImageContent,
    audio: AudioContent,
    video: VideoContent,
  },
  data() {
    return {
      newsItem: null,
    };
  },
  async mounted() {
    const { id } = this.$route.params;
    const data = await this[GET_NEWS_PUBLICATION](id);
    this.newsItem = {
      ...data,
      content: data.content.map((c) => ({
        ...c,
        content: c.media_file || c.text,
      })),
    };
  },
  methods: {
    ...mapActions([GET_NEWS_PUBLICATION]),
  },
};
</script>
