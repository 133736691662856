<template>
  <button
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="{ hover: hover }"
    class="button"
  >
    <slot></slot>
  </button>
</template>

<script>
export default {
  data() {
    return { hover: false };
  },
};
</script>

<style scoped>
.button {
  display: flex;
  width: 48px;
  height: 48px;
  margin: auto;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  border: 1px solid #7a69ee;
  background-color: white;
  transition: background-color 0.3s;
}

/* Hover state class */
.button.hover {
  background-color: #7a69ee;
}

.button.hover svg path {
  fill: white;
}
</style>
