<template>
  <div>
    <div class="absolute right-0 top-0 z-10 p-2">
      <button @click="toggleFullScreen(computedFileUrl)">На весь экран</button>
    </div>
    <pdf
      :source="computedFileUrl"
      @num-pages="pageCount = $event"
      @page-loaded="page = $event"
      @link-clicked="page = $event"
      :page="page"
      v-bind="$attrs"
      ref="pdf"
      @loaded="getPdfInfo"
    ></pdf>
  </div>
</template>

<script>
import VuePdfEmbed from "vue-pdf-embed";
export default {
  components: {
    pdf: VuePdfEmbed,
  },
  data() {
    return {
      currentPage: 0,
      pageCount: 0,
      page: 1,
      full_screen: false,
    };
  },
  props: {
    content: {
      required: true,
    },
  },
  methods: {
    getPdfInfo({ _pdfInfo }) {
      this.pageCount = _pdfInfo.numPages;
      this.$emit("setMaxPages", this.pageCount);
    },
    toggleFullScreen(url) {
      window.open(url);
    },
  },
  computed: {
    computedFileUrl() {
      if (typeof this.content === "string" || this.content instanceof String) {
        return this.content;
      }
      if (this.content) {
        return URL.createObjectURL(this.content);
      }
      return null;
    },
  },
};
</script>
