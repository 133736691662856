<template>
  <ResourceBlockCard>
    <QuestionText :text="content"/>
    <div>
      <textarea
          class="w-full mt-3 border-gray-200 rounded-xl"
          rows="4"
          v-model="answer.text"
          :disabled="is_disabled "

      />
    </div>
    <AttachmentBlock :attachments="attachments" />
    <SubmitResourceConfirmation
        :show="show_confirmation"
        @submit="handleSubmitAnswer"
        @cancel="show_confirmation=false"
    />
    <div class="grid grid-cols-1 gap-2 sm:grid-cols-2">
      <UploadedFile
          v-for="uploaded_file in answer.files"
          :key="`uploaded_file_${uploaded_file.id}_${uploaded_file.status}`"
          :size="uploaded_file.size"
          :name="uploaded_file.name"
          :status="uploaded_file.status"
          :id="uploaded_file.id"
          @removeFile="removeFile"
          @retryUpload="retryUpload"
      />
    </div>
    <div class="flex place-content-between mt-3">
      <input
          type="file"
          :ref="`question_${id}_file_uploader`"
          class="hidden"
          @change="handleFileUpload"
          :disabled="is_disabled"
      />
      <TheButton bg="outline" @click="handleUploadFileTrigger" :disabled="is_disabled">
        <PaperClipIcon  class="w-4 h-4 inline-block mr-1"/>
        {{$t('attach_file')}}
      </TheButton>
      <TheButton bg="primary" @click="show_confirmation=true; " :disabled="is_disabled">
        {{$t('send')}}
        <PaperAirplaneIcon class="h-4 w-4 inline-block ml-1 rotate-90"/>
      </TheButton>
    </div>
    <AnswerContent v-for="attempt in answers.filter( el => el.id == content_id)[0].user_attempts"
      :answer="attempt"
      :key="`user_attempt_${attempt.id}`"
      />
  </ResourceBlockCard>

</template>
<script>
  import CommonQuestionMixin from "@/components/Resources/ResourceBlock/Questions/CommonQuestionMixin";
  import QuestionText from "@/components/Resources/ResourceBlock/Questions/QuestionText";
  import ResourceBlockCard from "@/components/Resources/ResourceBlock/ResourceBlockCard";
  import UploadedFile from "@/components/Files/UploadedFile";
  import TheButton from "@/components/Button/Button";
  import {PaperClipIcon} from "@heroicons/vue/outline"
  import {PaperAirplaneIcon} from "@heroicons/vue/solid"
  import {reactive} from "vue";
  import AttachmentBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
  import {DELETE_ANSWER_FILE, SUBMIT_ANSWER_FILE} from "@/store/resources";
  import AnswerContent from "../Answers/AnswerContent.vue";
  import {mapFields} from "vuex-map-fields";
  import SubmitResourceConfirmation from "@/components/Resources/Modals/SubmitResourceConfirmation";

  export default {
    components: {
      SubmitResourceConfirmation,
    AttachmentBlock,
    AnswerContent,
    TheButton,
    UploadedFile,
    ResourceBlockCard,
    QuestionText,
    PaperClipIcon,
    PaperAirplaneIcon,
},
    mixins:[CommonQuestionMixin],
    props:{
      text_answer:{
        type:String,
        default:''
      },
      user_answer_files:{
        type:Array,
        default:()=>[]
      }
    },
    data:()=>({
      answer:{
        text:'',
        files:[]
      },
      answers:[],
      show_confirmation:false,
      content_id:0,
    }),
    computed:{
        ...mapFields(['resources.resource'])
    },
    methods:{
      handleUploadFileTrigger(){
        this.$refs[`question_${this.id}_file_uploader`].click();
      },
      async removeFile(id){
        let fileToDeleteIndex = this.answer.files.findIndex(el=>el.id ===id)
        this.answer.files[fileToDeleteIndex].status="loading"
        await this.$store.dispatch(DELETE_ANSWER_FILE,id)
        this.answer.files = this.answer.files.filter(file=>file.id !==id)
      },  
      generateRandomKey() {
        return (new Date()).getTime() + Math.floor(Math.random() * 10000).toString()
      },
      generateFileObject(file){
        return reactive({
          file,
          name:file.name,
          status:"loading",
          size:file.size,
          id:'_'+this.generateRandomKey()
        })
      },
      async handleFileUpload(e){
        const file = e.target.files[0];
        const new_file = this.generateFileObject(file)
        this.answer.files.push(new_file)
        return this.submitFile(new_file)
      },
      async submitFile(fileObject){
        this.setStatusToObject(fileObject,"loading")
        try{
          const {data} = await this.$store.dispatch(SUBMIT_ANSWER_FILE,{file:fileObject.file,question_id:this.id,id:fileObject.id})
          Object.assign(fileObject,data)
          this.setStatusToObject(fileObject,"success")
          setTimeout(()=>{
            this.setStatusToObject(fileObject,'default')
          },1000)
        }catch(e){
          this.setStatusToObject(fileObject,"error")
        }
      },
      retryUpload(id){
        let fileToUpdateIndex = this.answer.files.findIndex(el=>el.id ===id)
        this.submitFile(this.answer.files[fileToUpdateIndex],fileToUpdateIndex)
      },
      setStatusToObject(fileObject,status){
        fileObject.status=status
      },
      handleSubmitAnswer(){
        this.submitAnswer(this.answer.text).then((res)=>{
          this.answers.filter((el) => {return el.id == res.data.id})[0].user_attempts = res.data.user_attempts.sort((a, b) => a.id - b.id)
          this.answers.filter((el) => {return el.id == res.data.id})[0].is_disabled = res.data.is_disabled
          this.$forceUpdate()
          })
        this.show_confirmation=false
      }
    },
    created() {
      this.answer.text = this.text_answer
      this.answer.files=this.user_answer_files
      this.answers = this.resource.contents
      this.answers.forEach( el => {
        if(el.user_attempts) {
          el.user_attempts.sort((a,b) => b.id - a.id)
        }
      })
      this.content_id = this.id
    }
  }
</script>