<template>
  <TheSidebar>
    <div>
      <TheButton bg="link" class="mb-8 flex" @click="goBack">
        <ArrowLeftIcon class="h-4 pr-2" />
        {{ $t("back") }}
      </TheButton>
      <div class="text-base leading-6 font-semibold mb-4">
        {{ course.name }}
      </div>
      <template v-if="course.is_started">
        <div class="flex place-items-center mb-5">
          <ProgressBar
            :progress="course.progress ? course.progress.progress : 0"
            class="flex-auto"
          />
          <span class="pl-2"
            >{{ course.progress ? course.progress.progress : "0" }}%</span
          >
        </div>
        <router-link
          v-if="course.progress.last_page && course.progress.last_resource"
          :to="{
            name: 'ResourcesPassView',
            params: { id: course.progress.last_resource },
            query: { page: course.progress.last_page },
          }"
          replace
        >
          <TheButton bg="primary" class="w-full mb-6">
            {{ $t("continue") }}
          </TheButton>
        </router-link>
      </template>
      <template v-else>
        <p class="text-sm leading-5 font-semibold mb-4">
          {{ $t("starts_with_date", { date: formattedStartAt }) }}
        </p>
      </template>
      <hr />
      <div>
        <component
          :is="route.isDisabled ? 'span' : 'router-link'"
          v-for="route in routes"
          :key="route.route"
          :to="route.route"
          replace
          :class="[
            route.routeName === currentRouteName
              ? 'bg-gray-100 text-gray-900'
              : 'text-gray-600 ',
            !route.isDisabled ? 'hover:bg-gray-50 hover:text-gray-900' : '',
          ]"
          class="flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer"
          :aria-current="
            route.routeName === currentRouteName ? 'page' : undefined
          "
        >
          <span class="truncate flex-1">
            {{ $t(route.label) }}
          </span>
          <svg
            :class="[
              route.routeName === currentRouteName
                ? 'text-gray-400 '
                : 'text-gray-300',
              'ml-3 flex-shrink-0 h-5 w-5  group-hover:text-gray-400 ',
            ]"
            viewBox="0 0 20 20"
            aria-hidden="true"
          >
            <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
          </svg>
        </component>
      </div>
    </div>
  </TheSidebar>
</template>
<script>
import TheSidebar from "@/components/TheSidebar/TheSidebar";
import ProgressBar from "@/components/ProgressBar";
import TheButton from "@/components/Button/Button";
import { ArrowLeftIcon } from "@heroicons/vue/outline";
import { mapFields } from "vuex-map-fields";
import moment from "moment";
export default {
  components: { TheButton, ProgressBar, TheSidebar, ArrowLeftIcon },
  methods: {
    goBack() {
      this.$router.push({ name: "DashboardPage" });
    },
  },
  created() {
    this.active_course_id = this.currentRoute.params.id;
  },
  computed: {
    routes() {
      return [
        {
          label: "about_course",
          route: `/courses/${this.active_course_id}/overview`,
          routeName: "CourseOverview",
          isDisabled: false,
        },
        {
          label: "content",
          route: `/courses/${this.active_course_id}/content`,
          routeName: "CourseContent",
          isDisabled: !this.course.is_started,
        },
        {
          label: "my_attendance",
          route: `/courses/${this.active_course_id}/my-attendance`,
          routeName: "MyAttendance",
          isDisabled: !this.course.is_started,
        },
        {
          label: "my_grades",
          route: `/courses/${this.active_course_id}/my-grades`,
          routeName: "MyGrades",
          isDisabled: !this.course.is_started,
        },
      ];
    },
    currentRouteName() {
      return this.currentRoute.name;
    },
    currentRoute() {
      return this.$route;
    },
    formattedStartAt() {
      return moment(this.course.start_date)
        .locale(this.$i18n.locale)
        .format("DD MMMM");
    },
    ...mapFields(["courses.course", "courses.active_course_id"]),
  },
};
</script>
