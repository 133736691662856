<template>
  <ResourceBlockCard>
    <QuestionText :text="content"/>
      <AttachmentBlock :attachments="attachments"/>
      <SubmitResourceConfirmation
          :show="show_confirmation"
          @submit="handleSubmitAnswer"
          @cancel="show_confirmation=false"
      />
      <span class="text-lg leading-7 font-semibold ">
          {{$tc('input_text_answer',{amount:200})}}
      </span>
      <textarea
          v-model="answer"
          class="w-full mt-11 border-gray-200 rounded-xl"
          rows="10"
          :disabled="is_disabled"
      />
    <div class="flex justify-end">
      <TheButton class="" bg="primary" @click="show_confirmation=true; " :disabled="is_disabled">
          {{$t('send')}}
          <PaperAirplaneIcon class="h-4 w-4 inline-block ml-1 rotate-90"/>
      </TheButton>
    </div>
    <AnswerContent v-for="attempt in answers.filter( el => el.id == content_id)[0].user_attempts"
      :answer="attempt"
      :key="`user_attempt_${attempt.id}`"
      />
  </ResourceBlockCard>
</template>
<script>
import CommonQuestionMixin from "@/components/Resources/ResourceBlock/Questions/CommonQuestionMixin";
import QuestionText from "@/components/Resources/ResourceBlock/Questions/QuestionText";
import ResourceBlockCard from "@/components/Resources/ResourceBlock/ResourceBlockCard";
import AttachmentBlock from "@/components/Resources/ResourceBlock/Attachments/AttachmentsBlock";
import SubmitResourceConfirmation from "@/components/Resources/Modals/SubmitResourceConfirmation";
import {PaperAirplaneIcon} from "@heroicons/vue/solid";
import AnswerContent from "../Answers/AnswerContent.vue";
import {mapFields} from "vuex-map-fields";
import TheButton from "@/components/Button/Button";

export default {
  components: {
    AttachmentBlock, 
    ResourceBlockCard, 
    QuestionText, 
    SubmitResourceConfirmation, 
    PaperAirplaneIcon,
    AnswerContent,
    TheButton,
},
  mixins:[CommonQuestionMixin],
  props:{
    text_answer:{
        type:String,
        default:''
      },
  },
  data:()=>({
    answer:'',
    show_confirmation:false,
    answers:[],
  }),
  methods:{
    handleSubmitAnswer(){
        this.submitAnswer(this.answer).then((res)=>{
          this.answers.filter(el => el.id == res.data.id)[0].user_attempts = res.data.user_attempts
          })
        this.show_confirmation=false
      }
  },
  created() {
      this.answer = this.text_answer
      this.answers = this.resource.contents
      this.content_id = this.id
  },
  computed:{
      ...mapFields(['resources.resource'])
  },
}
</script>