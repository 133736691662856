<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <div>
    <p class="text-2xl text-gray-900 font-semibold mb-4">
      {{ course.name }}
    </p>
    <div
      class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 auto-rows-fr"
    >
      <router-link
        v-for="route in routes"
        :key="route.title"
        custom
        :to="`${route.url}`"
        v-slot="{ navigate, href }"
      >
        <a
          v-if="active_user_has_permission(route.permission)"
          :href="href"
          @click="navigate"
          class="shadow-lg rounded-xl bg-white w-full sm:max-w-fit overflow-hidden p-4"
          style="max-width: 264px"
        >
          <div class="flex flex-col justify-center h-full place-items-start">
            <component :is="route.icon" class="text-indigo-600 h-5 w-5 mb-4" />
            <span class="text-lg leading-5 font-medium tex-gray-900">
              {{ $t(route.title) }}
            </span>
            <span class="mt-2 text-gray-600 text-sm leading-5 font-medium">
              {{ $t(route.description) }}
            </span>
          </div>
        </a>
      </router-link>
      <a
        v-if="active_user_has_permission('can_copy_course')"
        class="shadow-lg rounded-xl bg-white w-full sm:max-w-fit overflow-hidden p-4 cursor-pointer"
        style="max-width: 264px"
        @click="copy_modal_open = true"
      >
        <div class="flex flex-col justify-center h-full place-items-start">
          <DocumentDuplicateIcon class="text-indigo-600 h-5 w-5 mb-4" />
          <span class="text-lg leading-5 font-medium tex-gray-900">
            Копирование курса
          </span>
          <span class="mt-2 text-gray-600 text-sm leading-5 font-medium">
            Создание копии существущего курса с возможностью редактирования
          </span>
        </div>
      </a>
      <router-link
        :to="{ name: 'AttendanceList' }"
        v-if="active_user_has_permission('can_copy_course')"
        class="shadow-lg rounded-xl bg-white w-full sm:max-w-fit overflow-hidden p-4 cursor-pointer"
        style="max-width: 264px"
      >
        <div class="flex flex-col justify-center h-full place-items-start">
          <DocumentDuplicateIcon class="text-indigo-600 h-5 w-5 mb-4" />
          <span class="text-lg leading-5 font-medium tex-gray-900">
            Посещаемость
          </span>
          <span class="mt-2 text-gray-600 text-sm leading-5 font-medium">
            Присутствие студентов на курсах и выгрузка таблиц по посещениям
            студентов
          </span>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'TaskPercentage' }"
        v-if="active_user_has_permission('can_copy_course')"
        class="shadow-lg rounded-xl bg-white w-full sm:max-w-fit overflow-hidden p-4 cursor-pointer"
        style="max-width: 264px"
      >
        <div class="flex flex-col justify-center h-full place-items-start">
          <DocumentDuplicateIcon class="text-indigo-600 h-5 w-5 mb-4" />
          <span class="text-lg leading-5 font-medium tex-gray-900">
            Весовой коэффицент заданий
          </span>
          <span class="mt-2 text-gray-600 text-sm leading-5 font-medium">
            Процентный вес заданий от общего веса
          </span>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'StudentGrades' }"
        v-if="active_user_has_permission('can_copy_course')"
        class="shadow-lg rounded-xl bg-white w-full sm:max-w-fit overflow-hidden p-4 cursor-pointer"
        style="max-width: 264px"
      >
        <div class="flex flex-col justify-center h-full place-items-start">
          <EmptyStudentHatIcon class="text-indigo-600 h-5 w-5 mb-4" />
          <span class="text-lg leading-5 font-medium tex-gray-900">
            Оценки
          </span>
          <span class="mt-2 text-gray-600 text-sm leading-5 font-medium">
            Табель успеваемости учеников
          </span>
        </div>
      </router-link>
    </div>
  </div>
  <CourseCopy />
</template>
<script>
import { GET_COURSE } from "@/store/courses";
import {
  ChartSquareBarIcon,
  ClipboardListIcon,
  ServerIcon,
  UserGroupIcon,
  LockOpenIcon,
  DocumentDuplicateIcon,
} from "@heroicons/vue/outline";
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import TheModal from "@/components/TheModal/TheModal";
import CourseCopy from "@/views/courses/CourseEdit/CourseCopy/CourseCopy";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import StudentHatIcon from "@/assets/icons/StudentHatIcon.vue";
import EmptyStudentHatIcon from "@/assets/icons/EmptyStudentHatIcon.vue";

export default {
  components: {
    EmptyStudentHatIcon,
    StudentHatIcon,
    ChartSquareBarIcon,
    ClipboardListIcon,
    ServerIcon,
    UserGroupIcon,
    LockOpenIcon,
    DocumentDuplicateIcon,
    TheModal,
    CourseCopy,
    Breadcrumbs,
  },
  computed: {
    ...mapFields(["courses.course", "courses.copy_modal_open"]),
    ...mapGetters({
      active_user_has_permission: "active_user_has_permission",
    }),
  },
  methods: {
    ...mapActions([GET_COURSE]),
  },
  data: () => ({
    id: null,
    routes: [],
    breadcrumbs: [],
  }),
  watch: {
    course: function () {
      this.breadcrumbs = [
        {
          name: this.$t("courses"),
          path: "/courses/list",
        },
        {
          name: this.course.name,
          path: this.$route.path,
        },
      ];
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    this[GET_COURSE](this.id);
    this.breadcrumbs = [
      {
        name: this.$t("courses"),
        path: "/courses/list",
      },
      {
        name: this.course.name,
        path: this.$route.path,
      },
    ];
    this.routes = [
      {
        url: `/courses/form?id=${this.id}`,
        title: "about_course",
        description: "about_course_description",
        icon: "ClipboardListIcon",
        permission: "can_edit_course",
      },
      {
        url: `/courses/${this.id}/resources/form`,
        title: "course_content",
        description: "course_content_description",
        icon: "ServerIcon",
        permission: "can_publish_course",
      },
      {
        url: `/courses/${this.id}/groups`,
        title: "groups",
        description: "groups_description",
        icon: "UserGroupIcon",
        permission: "can_add_students_to_course",
      },
      {
        url: `/courses/${this.id}/permission`,
        title: "permission",
        description: "permission_description",
        icon: "LockOpenIcon",
        permission: "can_edit_course_permission",
      },
      {
        url: `/courses/${this.id}/statistics`,
        title: "statistics",
        description: "statistics_description",
        icon: "ChartSquareBarIcon",
        permission: "can_get_group_statistics",
      },
    ];
  },
};
</script>
