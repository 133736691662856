export default {
    props:{
        show:{
            type:Boolean,
            default:false
        },
        className: {
            type: String,
            default: ''
        },
    },
    methods:{
        closeModal(){
            this.$emit('close')
        }
    }
}