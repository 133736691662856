<template>
  <v-select
    :label="label"
    :options="options"
    v-model="localValue"
    :multiple="multiple"
    :reduce="(option) => option.id"
    :placeholder="$t(placeholder)"
  >
    <template v-slot:option="option">
      <component :is="optionComponent" :option="option" :value="option.id" />
    </template>
  </v-select>
</template>

<script>
import vSelect from "vue-select";
import { defineAsyncComponent } from "vue";

export default {
  props: {
    modelValue: {},
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "select",
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      default: () => [],
    },
    optionComponentName: {
      type: String,
      default: "DefaultSelectorOption",
    },
  },
  data: () => ({
    selectedUser: "",
    query: "",
  }),
  components: {
    vSelect,
  },
  computed: {
    optionComponent() {
      return defineAsyncComponent(() =>
        import(`./SelectorOptions/${this.optionComponentName}`)
      );
    },
    localValue: {
      get() {
        return this.modelValue;
      },
      set(selectedValue) {
        this.$emit("change");
        this.$emit("update:modelValue", selectedValue);
      },
    },
  },
};
</script>
<style lang="postcss" scoped>
.v-select {
  @apply relative;
}

.v-select,
.v-select * {
  box-sizing: border-box;
}
@-webkit-keyframes vSelectSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes vSelectSpinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.vs__fade-enter-active,
.vs__fade-leave-active {
  transition: opacity 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}
.vs__fade-enter,
.vs__fade-leave-to {
  opacity: 0;
}
.vs--disabled .vs__clear,
.vs--disabled .vs__dropdown-toggle,
.vs--disabled .vs__open-indicator,
.vs--disabled .vs__search,
.vs--disabled .vs__selected {
  cursor: not-allowed;
  background-color: #f8f8f8;
}
/* Use deep selector to target nested elements */
:deep(.v-select[dir="rtl"] .vs__actions) {
  padding: 0 3px 0 6px;
}
:deep(.v-select[dir="rtl"] .vs__clear) {
  margin-left: 6px;
  margin-right: 0;
}
:deep(.v-select[dir="rtl"] .vs__deselect) {
  margin-left: 0;
  margin-right: 2px;
}
:deep(.v-select[dir="rtl"] .vs__dropdown-menu) {
  text-align: right;
}
:deep(.vs__dropdown-toggle) {
  background-color: red;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: 0 0 2px;
  background: none;
  border: none; /* Remove border */
  border-radius: 0; /* Remove border-radius */
  white-space: nowrap;
  align-items: center; /* Center items vertically */
  @apply py-1 px-3;
}
:deep(.vs__selected-options) {
  width: auto; /* Allow it to adjust based on content */
  overflow-wrap: break-word;
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  align-items: center; /* Center items vertically */
  padding: 0 2px;
  position: relative;
}
:deep(.vs__actions) {
  display: flex;
  align-items: center;
  padding: 4px 6px 0 3px;
}
:deep(.vs--searchable .vs__dropdown-toggle) {
  cursor: text;
}
:deep(.vs--unsearchable .vs__dropdown-toggle) {
  cursor: pointer;
}
:deep(.vs--open .vs__dropdown-toggle) {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
:deep(.vs__open-indicator) {
  fill: rgba(60, 60, 60, 0.5);
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: -webkit-transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
  transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
  transition: transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855),
    -webkit-transform 0.15s cubic-bezier(1, -0.115, 0.975, 0.855);
  transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
}
:deep(.vs--open .vs__open-indicator) {
  -webkit-transform: rotate(180deg) scale(1);
  transform: rotate(180deg) scale(1);
}
:deep(.vs--loading .vs__open-indicator) {
  opacity: 0;
}
:deep(.vs__clear) {
  fill: rgba(60, 60, 60, 0.5);
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-right: 8px;
}
:deep(.vs__dropdown-menu) {
  display: block;
  position: absolute;
  top: calc(100% - 1px);
  left: 0;
  z-index: 1000;
  padding: 5px 0;
  margin: 0;
  width: 100%;
  max-height: 350px;
  min-width: fit-content;
  overflow-y: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
  border: none; /* Remove border */
  border-top-style: none;
  border-radius: 0; /* Remove border-radius */
  text-align: left;
  list-style: none;
  background: #fff;
}
:deep(.vs__no-options) {
  text-align: center;
}
:deep(.vs__dropdown-option) {
  line-height: 1.42857143;
  display: block;
  padding: 3px 20px;
  clear: both;
  color: #333;
  white-space: nowrap;
}
:deep(.vs__dropdown-option:hover) {
  cursor: pointer;
}
:deep(.vs__dropdown-option--highlight) {
  background: #5897fb;
  color: #fff;
}
:deep(.vs__selected) {
  margin: 4px 0 0;
}
:deep(.vs__deselect) {
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-left: 4px;
  padding: 0;
  border: 0;
  cursor: pointer;
  background: none;
  fill: red;
  text-shadow: 0 1px 0 #fff;
}
:deep(.vs--single .vs__selected) {
  background-color: transparent;
  border-color: transparent;
}
:deep(.vs--single.vs--open .vs__selected) {
  position: absolute;
  opacity: 0.4;
}
:deep(.vs--single.vs--searching .vs__selected) {
  display: none;
}
:deep(.vs__search::-ms-clear),
:deep(.vs__search::-webkit-search-cancel-button),
:deep(.vs__search::-webkit-search-decoration),
:deep(.vs__search::-webkit-search-results-button),
:deep(.vs__search::-webkit-search-results-decoration) {
  display: none;
}
:deep(.vs__search),
:deep(.vs__search:focus) {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: 1.4;
  font-size: 1em;
  border: none; /* Remove border */
  outline: none;
  margin: 4px 0 0;
  padding: 0 7px;
  background: none;
  box-shadow: none;
  width: auto; /* Allow width to adjust based on content */
  max-width: 100%;
  flex-grow: 1;
}
:deep(.vs__search::-webkit-input-placeholder) {
  color: inherit;
  font-size: inherit;
}
:deep(.vs__search:-ms-input-placeholder) {
  color: inherit;
  font-size: inherit;
}
:deep(.vs__search::-ms-input-placeholder) {
  color: inherit;
  font-size: inherit;
}
:deep(.vs__search::placeholder) {
  color: inherit;
  font-size: inherit;
}
:deep(.vs--unsearchable .vs__search) {
  opacity: 1;
}
:deep(.vs--unsearchable .vs__search:hover) {
  cursor: pointer;
}
:deep(.vs--single.vs--searching:not(.vs--open):not(.vs--loading) .vs__search) {
  opacity: 0.2;
}
:deep(.vs__spinner) {
  align-self: center;
  opacity: 0;
  font-size: 5px;
  text-indent: -9999em;
  overflow: hidden;
  border: 0.9em solid hsla(0, 0%, 39.2%, 0.1);
  border-left-color: rgba(60, 60, 60, 0.45);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: vSelectSpinner 1.1s linear infinite;
  animation: vSelectSpinner 1.1s linear infinite;
  transition: opacity 0.1s;
}
:deep(.vs__spinner),
:deep(.vs__spinner:after) {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}
:deep(.vs--loading .vs__spinner) {
  opacity: 1;
}
</style>
