import { SET_LOADING } from "@/store/core";

export const GET_SPECIALIZATION = "GET_SPECIALIZATION";
export const GET_SPECIALIZATIONS = "GET_SPECIALIZATIONS";
export const SET_SPECIALIZATIONS = "SET_SPECIALIZATIONS";
export const SET_SPECIALIZATION = "SET_SPECIALIZATION";
export const SAVE_SPECIALIZATION = "SAVE_SPECIALIZATION";
export const DELETE_SPECIALIZATION = "DELETE_SPECIALIZATION";
const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {
    specialization: {},
    specializations: [],
    form: {
      course_ids: "",
    },
    modals: {
      show_courses_select_modal: false,
    },
  };
  const actions = {
    [GET_SPECIALIZATION]: async ({ commit }, id) => {
      const { data } = await $api.specializations.get_item(id);
      commit(SET_SPECIALIZATION, data);
      return data;
    },
    [SAVE_SPECIALIZATION]: async (store, form) => {
      const formData = Object.keys(form).reduce((formData, key) => {
        if (key === "course_ids") {
          form[key].map((course_id) => {
            formData.append("course_ids[]", course_id);
          });
        } else if (key === "description") {
          formData.append(key, JSON.stringify(form[key]));
        } else {
          formData.append(key, form[key]);
        }
        return formData;
      }, new FormData());
      if (form.id) {
        return $api.specializations.update(form.id, formData);
      } else {
        return $api.specializations.store(formData);
      }
    },
    [GET_SPECIALIZATIONS]: async ({ commit }, params) => {
      const { data } = await $api.specializations.get_list(params);
      commit(SET_SPECIALIZATIONS, data);
      return data;
    },
    [DELETE_SPECIALIZATION]: async ({ commit }, id) => {
      commit(SET_LOADING, true);
      await $api.specializations.delete(id);
      commit(SET_LOADING, false);
    },
  };
  const mutations = {
    [SET_SPECIALIZATION]: (store, payload) => {
      store.specialization = payload;
    },
    [SET_SPECIALIZATIONS]: (store, payload) => {
      store.specializations = payload;
    },
  };
  return {
    actions,
    state,
    mutations,
  };
};
export default (app) => {
  return createStore(app);
};
