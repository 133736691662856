<template>
  <div class="flex justify-between items-center mb-6">
    <p class="text-2xl font-semibold leading-8 text-gray-900">
      {{ $t("profile") }}
    </p>
    <Button
      size="sm"
      bg="outline"
      class="flex gap-2 items-center border-[1px] border-gray-300 py-2 px-3 shadow-sm bg-white rounded-md"
      @click="handleEditButton"
    >
      <PencilIcon class="w-4 h-4" />
      <span class="text-sm leading-4 text-gray-700">{{ $t("edit") }}</span>
    </Button>
  </div>
  <div class="bg-white px-8 py-10 text-sm leading-5">
    <div class="flex pb-7 border-b border-gray-200 gap-4">
      <div class="flex-1 text-gray-500">{{ $t("avatar") }}</div>
      <div class="flex flex-2 items-center gap-5">
        <div class="hidden md:block">
          <UserAvatar
            class="rounded-full flex-shrink-0"
            :url="active_user.avatar"
          />
        </div>
        <input
          type="file"
          id="avatar-input"
          hidden
          @change="handleAvatarInputChange"
        />
        <Button
          size="sm"
          bg="outline"
          class="flex gap-2 items-center border-[1px] border-gray-300 py-2 px-4 shadow-sm bg-white rounded-md"
          @click="handleAvatarUpload"
        >
          <span class="text-sm leading-4 text-gray-700">{{
            $t("change_avatar")
          }}</span>
        </Button>
      </div>
    </div>
    <div class="flex py-7 border-b border-gray-200 gap-4">
      <div class="flex-1 text-gray-500">{{ $t("first_name") }}</div>
      <div class="flex-2">{{ active_user.first_name || "" }}</div>
    </div>
    <div class="flex py-7 border-b border-gray-200 gap-4">
      <div class="flex-1 text-gray-500">{{ $t("last_name") }}</div>
      <div class="flex-2">{{ active_user.last_name || "" }}</div>
    </div>
    <div class="flex py-7 border-b border-gray-200 gap-4">
      <div class="flex-1 text-gray-500">{{ $t("email") }}</div>
      <div class="flex-2">{{ active_user.email || "" }}</div>
    </div>
    <div class="flex py-7 border-b border-gray-200 gap-4">
      <div class="flex-1 text-gray-500">{{ $t("phone_number") }}</div>
      <div class="flex-2">{{ active_user.phone_number || "" }}</div>
    </div>
    <div class="flex items-center pt-7 pb-3 gap-4">
      <div class="flex-1 text-gray-500">{{ $t("password") }}</div>
      <div class="flex-2">
        <Button
          size="sm"
          bg="outline"
          class="flex gap-2 items-center border-[1px] border-gray-300 py-2 px-4 shadow-sm bg-white rounded-md"
          @click="handleUpdatePasswordButton"
        >
          <span class="text-sm leading-4 text-gray-700">{{
            $t("update_password")
          }}</span>
        </Button>
      </div>
    </div>
  </div>
  <TheModal :show="editing" @close="handleEditModalClose" class="z-50">
    <div class="p-6 flex flex-col gap-10">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-7 font-semibold text-gray-900">
          {{ $t("edit_profile") }}
        </h2>
        <XIcon
          class="w-6 h-6 text-gray-400 cursor-pointer"
          @click="handleEditModalClose"
        />
      </div>
      <div class="flex flex-col gap-6">
        <div>
          <div class="text-sm leading-5 font-medium text-gray-700 mb-1">
            {{ $t("first_name") }}
          </div>
          <InputText v-model="first_name" />
        </div>
        <div>
          <div class="text-sm leading-5 font-medium text-gray-700 mb-1">
            {{ $t("last_name") }}
          </div>
          <InputText v-model="last_name" />
        </div>
        <div>
          <div class="text-sm leading-5 font-medium text-gray-700 mb-1">
            {{ $t("email") }}
          </div>
          <InputText v-model="email" />
        </div>
        <div>
          <div class="text-sm leading-5 font-medium text-gray-700 mb-1">
            {{ $t("phone_number") }}
          </div>
          <InputText v-model="phone_number" />
        </div>
        <p v-if="!!error" style="color: #dc3545" class="text-sm">{{ error }}</p>
      </div>
      <div class="flex justify-end items-center gap-6">
        <Button
          size="sm"
          bg="outline"
          class="flex gap-2 items-center border-[1px] border-gray-300 py-2 px-4 shadow-sm bg-white rounded-md"
          @click="handleEditModalClose"
        >
          <span class="text-sm leading-4 text-gray-700">{{
            $t("cancel")
          }}</span>
        </Button>
        <Button
          size="sm"
          bg="primary"
          class="flex gap-2 items-center border-[1px] border-gray-300 py-2 px-4 shadow-sm bg-white rounded-md"
          @click="handleEditSave"
        >
          <span class="text-sm leading-4 text-white">{{
            $t("save_changes")
          }}</span>
        </Button>
      </div>
    </div>
  </TheModal>
  <TheModal
    :show="updatingPassword"
    @close="handleUpdatePasswordModalClose"
    class="z-50"
  >
    <div class="p-6 flex flex-col gap-10">
      <div class="flex justify-between items-center">
        <h2 class="text-xl leading-7 font-semibold text-gray-900">
          {{ $t("update_password") }}
        </h2>
        <XIcon
          class="w-6 h-6 text-gray-400 cursor-pointer"
          @click="handleUpdatePasswordModalClose"
        />
      </div>
      <div class="flex flex-col gap-6">
        <div>
          <InputText
            v-model.lazy="currentPassword"
            :type="showCurrentPassword ? 'text' : 'password'"
          >
            <template #label>
              <InputLabel>{{ $t("current_password") }}</InputLabel>
            </template>
            <template #rightIcon>
              <EyeOffIcon
                class="h-5 w-5 text-gray-600"
                v-if="showCurrentPassword"
                @click="showCurrentPassword = false"
              />
              <EyeIcon
                class="h-5 w-5 text-gray-600"
                v-else
                @click="showCurrentPassword = true"
              />
            </template>
          </InputText>
        </div>
        <div>
          <InputText
            v-model.lazy="newPassword"
            :type="showNewPassword ? 'text' : 'password'"
          >
            <template #label>
              <InputLabel>{{ $t("new_password") }}</InputLabel>
            </template>
            <template #rightIcon>
              <EyeOffIcon
                class="h-5 w-5 text-gray-600"
                v-if="showNewPassword"
                @click="showNewPassword = false"
              />
              <EyeIcon
                class="h-5 w-5 text-gray-600"
                v-else
                @click="showNewPassword = true"
              />
            </template>
          </InputText>
        </div>
        <div>
          <InputText
            v-model.lazy="confirmNewPassword"
            :type="showConfirmNewPassword ? 'text' : 'password'"
          >
            <template #label>
              <InputLabel>{{ $t("confirm_new_password") }}</InputLabel>
            </template>
            <template #rightIcon>
              <EyeOffIcon
                class="h-5 w-5 text-gray-600"
                v-if="showConfirmNewPassword"
                @click="showConfirmNewPassword = false"
              />
              <EyeIcon
                class="h-5 w-5 text-gray-600"
                v-else
                @click="showConfirmNewPassword = true"
              />
            </template>
          </InputText>
        </div>
        <p v-if="!!error" style="color: #dc3545" class="text-sm">{{ error }}</p>
      </div>
      <div class="flex justify-end items-center gap-6">
        <Button
          size="sm"
          bg="outline"
          class="flex gap-2 items-center border-[1px] border-gray-300 py-2 px-4 shadow-sm bg-white rounded-md"
          @click="handleUpdatePasswordModalClose"
        >
          <span class="text-sm leading-4 text-gray-700">{{
            $t("cancel")
          }}</span>
        </Button>
        <Button
          size="sm"
          bg="primary"
          class="flex gap-2 items-center border-[1px] border-gray-300 py-2 px-4 shadow-sm bg-white rounded-md"
          @click="handleUpdatePasswordSave"
        >
          <span class="text-sm leading-4 text-white">{{
            $t("save_changes")
          }}</span>
        </Button>
      </div>
    </div>
  </TheModal>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import { EyeIcon, EyeOffIcon, PencilIcon, XIcon } from "@heroicons/vue/outline";
import TheModal from "@/components/TheModal/TheModal";
import Button from "@/components/Button/Button";
import InputText from "@/components/InputText";
import InputLabel from "@/components/InputLabel";
import UserAvatar from "@/components/UserAvatar";
import {
  EDIT_ACTIVE_USER,
  EDIT_ACTIVE_USER_AVATAR,
  UPDATE_PASSWORD,
} from "@/store/auth";
import { OPEN_ALERT } from "@/store/core";

export default {
  name: "profile-view",
  components: {
    PencilIcon,
    TheModal,
    XIcon,
    Button,
    InputText,
    InputLabel,
    UserAvatar,
    EyeIcon,
    EyeOffIcon,
  },
  computed: {
    ...mapFields(["auth.active_user"]),
  },
  data() {
    return {
      editing: false,
      updatingPassword: false,
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmNewPassword: false,
      error: "",
    };
  },
  methods: {
    ...mapActions([
      EDIT_ACTIVE_USER,
      EDIT_ACTIVE_USER_AVATAR,
      UPDATE_PASSWORD,
      OPEN_ALERT,
    ]),
    handleAvatarUpload() {
      const fileInput = document.querySelector("#avatar-input");
      fileInput.click();
    },
    handleAvatarInputChange(event) {
      this[EDIT_ACTIVE_USER_AVATAR]({
        email: this.active_user.email,
        avatar: event.target.files[0],
      })
        .then(() => this[OPEN_ALERT]({ text: "Фотография успешно обновлена!" }))
        .catch(() =>
          this[OPEN_ALERT]({
            text: "Оишбка при при обновлении фотографии",
            variant: "error",
          })
        );
    },
    handleEditButton() {
      this.first_name = this.active_user.first_name;
      this.last_name = this.active_user.last_name;
      this.email = this.active_user.email;
      this.phone_number = this.active_user.phone_number;
      this.editing = true;
    },
    handleEditModalClose() {
      this.editing = false;
      this.first_name = "";
      this.last_name = "";
      this.email = "";
      this.phone_number = "";
    },
    handleEditSave() {
      this[EDIT_ACTIVE_USER]({
        ...this.active_user,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        phone_number: this.phone_number,
      })
        .then(() => {
          this[OPEN_ALERT]({ text: "Изменения сохранены!" });
          this.handleEditModalClose();
        })
        .catch(() => (this.error = "Оишбка при редактировании"));
    },
    handleUpdatePasswordButton() {
      this.updatingPassword = true;
    },
    handleUpdatePasswordModalClose() {
      this.updatingPassword = false;
      this.currentPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";
    },
    handleUpdatePasswordSave() {
      if (
        !this.currentPassword.length ||
        !this.newPassword.length ||
        !this.confirmNewPassword.length
      ) {
        this.error = "Поля не могут пустыми";
      } else if (this.newPassword !== this.confirmNewPassword) {
        this.error = "Некорректное подтверждение нового пароля";
      } else if (this.currentPassword === this.newPassword) {
        this.error = "Новый пароль не может совпадать со старым";
      } else {
        this[UPDATE_PASSWORD]({
          old_password: this.currentPassword,
          password: this.newPassword,
          password_confirmation: this.confirmNewPassword,
        })
          .then(() => {
            this[OPEN_ALERT]({ text: "Пароль успешно обновлен!" });
            this.handleUpdatePasswordModalClose();
          })
          .catch(() => (this.error = "Слабый пароль"));
      }
    },
  },
};
</script>
