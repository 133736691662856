<template>
  <div class="flex flex-col gap-10">
    <div class="flex gap-2 justify-start">
      <Badge
        :has_circle="false"
        :bg="level"
        class="font-medium text-sm py-3 px-3"
        >Уровень: {{ course.level }}</Badge
      >
      <Badge
        :has_circle="false"
        bg="program-white"
        class="font-medium text-sm py-3 px-3"
        >Программа: {{ course.program }}</Badge
      >
    </div>
    <div class="flex flex-col gap-4">
      <div class="font-size-40px font-extrabold text-gray-800 adaptive-title">
        {{ course.name }}
      </div>
      <div class="font-normal text-lg text-gray-500 adaptive-text">
        {{ course.description }}
      </div>
    </div>
    <div class="flex flex-col gap-3">
      <div class="flex gap-3">
        <div class="font-medium text-lg adaptive-subtitle">
          Длительность курса:
        </div>
        <div class="font-medium text-lg text-gray-500 adaptive-text">
          {{ course.duration }}
        </div>
      </div>
      <div class="flex gap-3">
        <div class="font-medium text-lg adaptive-subtitle">Формат:</div>
        <div class="font-medium text-lg text-gray-500 adaptive-text">
          Онлайн
        </div>
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <div class="font-medium text-lg adaptive-subtitle">
        Изучаемые языки и технологии:
      </div>
      <div class="inline-flex flex-wrap gap-2 mb-1">
        <div
          class="course__technology-gradient"
          v-for="(item, index) in course.skills"
          :key="index"
        >
          <div
            class="bg-white py-2 px-4 flex gap-1 w-full h-full course__technology items-center"
          >
            <!--            <div><JavaIcon /></div>-->
            <div class="font-normal text-base text-gray-800">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col gap-4">
      <div class="font-medium text-lg">Вы получите:</div>
      <div class="grid grid-cols-2 gap-3">
        <div class="flex gap-3 items-center">
          <div class="course__icon px-2 py-2"><CertificateIcon /></div>
          <div class="font-normal text-sm text-gray-800">Сертификат</div>
        </div>
        <div class="flex gap-3 items-center">
          <div class="course__icon px-2 py-2"><ProjectIcon /></div>
          <div class="font-normal text-sm text-gray-800">
            Проекты в портфолио
          </div>
        </div>
        <div class="flex gap-3 items-center">
          <div class="course__icon px-2 py-2"><HelpIcon /></div>
          <div class="font-normal text-sm text-gray-800">
            Помощь в трудоустройстве
          </div>
        </div>
        <div class="flex gap-3 items-center">
          <div class="course__icon px-2 py-2"><ProfessionIcon /></div>
          <div class="font-normal text-sm text-gray-800">
            Востребованная профессия
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="flex gap-3 items-center">
        <div class="text-gray-800 font-semibold font-size-36px adaptive-price">
          {{ course.price }}
        </div>
        <!--        <div class="text-gray-800 font-normal text-2xl">в месяц</div>-->
      </div>
      <!--      <div class="text-gray-500 font-normal text-lg">
        При рассрочке на 24 месяцев
      </div>-->
    </div>
  </div>
</template>

<script>
import ProjectIcon from "../../../../assets/icons/ProjectIcon.vue";
import ProfessionIcon from "../../../../assets/icons/ProfessionIcon.vue";
import HelpIcon from "../../../../assets/icons/HelpIcon.vue";
import CertificateIcon from "../../../../assets/icons/CertificateIcon.vue";
// import JavaIcon from "@/assets/icons/JavaIcon.vue";
import Badge from "@/components/TheBadge/TheBadge.vue";

export default {
  components: {
    // JavaIcon,
    ProjectIcon,
    ProfessionIcon,
    HelpIcon,
    CertificateIcon,
    Badge,
  },
  props: {
    course: {
      type: Object,
    },
  },
  data() {
    return {};
  },

  computed: {
    levelOutline() {
      return `${this.level?.toLowerCase()}_outline`;
    },
  },
};
</script>

<style scoped>
.course__level {
  background: #e1d3fe;
  border-radius: 24px;
}
.course__program {
  background: white;
  border-radius: 24px;
}
.course__technology {
  border-radius: 24px;
}
.course__technology-gradient {
  padding: 1px;
  border-radius: 24px;
  background: linear-gradient(
    90.14deg,
    #cc3fd3 55.91%,
    #8453e8 66.25%,
    #525cf3 78.46%
  );
}
.course__icon {
  background: #ffffff;
  border: 1px solid #c8aeff;
  border-radius: 40px;
}

.font-size-36px {
  font-size: 36px;
}
.font-size-40px {
  font-size: 40px;
}

@media (max-width: 650px) {
  .adaptive-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .adaptive-subtitle {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  .adaptive-price {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
  .adaptive-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
