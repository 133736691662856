<template>
  <TheSidebar>
    <div class="mb-8">
      <template v-for="item in navigation">
        <router-link
          v-if="!item.permission || active_user_has_permission(item.permission)"
          :key="item.name"
          :to="{ name: item.route_name }"
          :class="[
            item.route_name === currentRoute
              ? 'bg-gray-100 text-gray-900 text-ellipsis'
              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
          ]"
        >
          <div class="flex w-full">
            <component
              :is="item.icon"
              :class="[
                item.route_name === currentRoute
                  ? 'text-gray-500'
                  : 'text-gray-400 group-hover:text-gray-500',
                'mr-3 flex-shrink-0 h-6 w-6',
              ]"
              aria-hidden="true"
              v-if="item.icon"
            />
            <div
              class="w-full flex justify-between"
              :class="item.icon ? '' : 'ml-10'"
            >
              <span>{{ $t(item.name) }}</span>
              <span
                v-if="item.name === 'notifications' && notifications_count > 0"
                class="circle"
                ><span
                  class="notifications-badge text-xs leading-4 font-medium"
                  >{{
                    notifications_count < 100 ? notifications_count : "99+"
                  }}</span
                ></span
              >
            </div>
          </div>
        </router-link>
      </template>
    </div>

    <div class="py-8 border-t-2">
      <div
        class="text-xs leading-4 font-semibold tracking-wider uppercase text-gray-500 mb-3 pl-3"
      >
        The Landing Page
      </div>
      <template v-for="item in landingNavigation">
        <router-link
          v-if="!item.permission || active_user_has_permission(item.permission)"
          :key="item.name"
          :to="{ name: item.route_name }"
          :class="[
            item.route_name === currentRoute
              ? 'bg-gray-100 text-gray-900 text-ellipsis'
              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'group flex items-center px-2 py-2 text-sm font-medium rounded-md',
          ]"
        >
          <div class="flex">
            <component
              :is="item.icon"
              :class="[
                item.route_name === currentRoute
                  ? 'text-gray-500'
                  : 'text-gray-400 group-hover:text-gray-500',
                'mr-3 flex-shrink-0 h-6 w-6',
              ]"
              aria-hidden="true"
              v-if="item.icon"
            />
            <span class="flex" :class="item.icon ? '' : 'ml-10'">
              {{ $t(item.name) }}
            </span>
          </div>
        </router-link>
      </template>
    </div>
  </TheSidebar>
</template>
<script>
import TheSidebarMixin from "@/components/TheSidebar/TheCabinetSideberMixin";
import TheSidebar from "@/components/TheSidebar/TheSidebar";

export default {
  components: { TheSidebar },
  mixins: [TheSidebarMixin],
};
</script>
<style lang="postcss">
.notifications-badge {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: white;
}
.circle {
  display: inline-table;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  background-color: #ef4444;
  border-radius: 50%;
}
</style>
