const baseUrl = "/api/v1/landing_page/teacher_showcase";

export default ($axios) => ({
  add_landing_teacher(params) {
    return $axios.post(`${baseUrl}/create/`, params);
  },
  add_landing_teacher_image(id, params) {
    return $axios.patch(`${baseUrl}/pk/${id}/add_image/`, params);
  },
  delete_landing_teacher_image(id) {
    return $axios.post(`${baseUrl}/pk/${id}/delete_image/`);
  },
  add_landing_teacher_company(id, params) {
    return $axios.patch(`${baseUrl}/pk/${id}/add_company_image/`, params);
  },
  get_landing_teachers(params) {
    return $axios.get(`${baseUrl}/list/${params}`);
  },
  delete_landing_teacher(id) {
    return $axios.delete(`${baseUrl}`, id);
  },
  get_landing_teachers_options() {
    return $axios.get(`${baseUrl}/options/`);
  },
});
