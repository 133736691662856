<template>
  <AddToCartModal @close="closeCartModal" :modal-open="openModal" />

  <div>
    <!--    <LandingBreadcrumbs :breadcrumbs="breadcrumbs" class="flex items-center" />-->
    <div class="course">
      <div
        class="course__info grid grid-cols-2 bg-white"
        id="landing-course-form"
      >
        <CourseInfoVue :course="course" />
        <TheLandingForm :course-info="course" @open="openCartModal" />
      </div>
      <div class="bottom"></div>
      <div class="company">
        <div class="company__div-1">
          <div class="flex flex-col gap-6">
            <div
              class="font-size-40px font-bold text-white adaptive-company-title"
            >
              Компаниям нужны веб-разработчики.
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              В целом, веб-разработчики нужны на рынке труда для создания и
              поддержки функциональных, привлекательных и пользовательски
              ориентированных веб-решений. Спрос на веб-разработчиков остается
              стабильным, и эта профессия предоставляет возможности для развития
              карьеры и творчества в динамичной и инновационной сфере. Причины,
              почему веб-разработчики необходимы на рынке труда:
            </div>
          </div>
          <div
            class="flex-shrink-0 flex items-center justify-center company__img-div"
          >
            <img
              src="../../../../assets/images/landing/html-tags.png"
              alt=""
              class="company__img"
            />
          </div>
        </div>
        <div class="company__div-2">
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Востребовано
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              21% разработчиков официально работают минимум на две компании.
            </div>
            <div class="font-medium text-lg text-gray-400">
              Источник: liter.kz
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Перспективно
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              К 2028 году спрос на веб-разарботчиков вырастит на 13%.
            </div>
            <div class="font-medium text-lg text-gray-400">
              Источник: kinsta.com
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Высокооплачиваемо
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              По данным на январь 2023 средняя зарплата выросла до 558,7 тыс.
              тенге.
            </div>
            <div class="font-medium text-lg text-gray-400">
              Источник: liter.kz
            </div>
          </div>
        </div>
        <div class="flex gap-3">
          <div class="font-medium text-xl company__sign cursor-pointer">
            Записаться на программу
          </div>
          <div><PurpleArrowRightIcon :fill="'#A599F5'" /></div>
        </div>
      </div>
    </div>
    <div class="demanded">
      <div class="text-4xl font-bold adaptive-company-title">
        Разработчики востребованы на рынке
      </div>
      <div class="flex gap-6 adaptive-demanded">
        <div class="flex flex-col gap-10 px-8 py-10 demanded__box-1">
          <div>
            <div class="text-2xl font-semibold">Более 200 вакансий</div>
            <div class="font-normal text-lg text-gray-500">
              Доступно сейчас в разработке
            </div>
          </div>
          <div>
            <div>
              <div class="text-2xl font-semibold demanded__box-1__border">
                700 000 ₸
              </div>
            </div>

            <div class="font-normal text-lg text-gray-500">
              Средняя зарплата разработчика со стажем от 4 лет
            </div>
          </div>
          <div>
            <div class="font-normal text-lg text-gray-500">
              *данные взяты с сайтов по поиску работы в августе 2022 г.
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-10 px-8 py-10 demanded__box-2">
          <div class="text-2xl font-semibold">График роста вакансий</div>
          <div>
            <img src="../../../../assets/images/landing/web-wages.png" />
          </div>
        </div>
      </div>
    </div>
    <CourseAdvantagesVue />
    <CourseFlexibilityVue />
    <CourseFitPeopleVue />

    <div class="trajectory">
      <div class="text-4xl font-bold adaptive-company-title">
        Учебная траектория
      </div>
      <div class="grid grid-cols-3 gap-6 adaptive-trajectory">
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>от 6 мес</div>
          <div>обучение</div>
        </div>
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>150+ часов</div>
          <div>обучения</div>
        </div>
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>5 вариантов</div>
          <div>специализаций</div>
        </div>
      </div>
      <div class="divider"></div>
      <div class="flex flex-col gap-4">
        <div class="font-medium text-lg">Длительность программы:</div>
        <div class="flex gap-2 adaptive-duration">
          <div
            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-1 length__item"
          >
            <div>6 мес</div>
            <div>|</div>
            <div>Разработчик</div>
          </div>
          <div
            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-2 length__item"
          >
            <div>12 мес</div>
            <div>|</div>
            <div>Digital-master</div>
          </div>
          <div
            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-3 length__item"
          >
            <div>24 мес</div>
            <div>|</div>
            <div>Geek</div>
          </div>
        </div>
      </div>
      <div class="divider"></div>

      <div class="flex flex-col gap-120px">
        <img
          src="../../../../assets/images/landing/study-plan.png"
          alt="study-plan"
          class="study-plan"
        />
        <img
          src="../../../../assets/images/landing/study-plan-mob.png"
          alt="study-plan"
          class="study-plan2"
        />

        <div class="flex flex-col gap-8">
          <div class="font-bold before-start__title adaptive-company-title">
            До старта
          </div>
          <div class="grid grid-cols-3 gap-6 adaptive-grid">
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с платформой, графиком обучения
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Познакомим с вашим учебным расписанием, разберемся, где смотреть
                уроки, как общаться с преподавателем и куда сдавать домашние
                задания.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с кураторами и преподавателями
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Представим ваших первых преподавателей, познакомим с кураторами,
                добавим в учебные чаты для общения с другими студентами.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Эффективное обучение
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Научим эффективно учиться, быстро и навсегда запоминать
                информацию любой сложности и объема.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Здоровье Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как сохранить здоровье и зрение, какие недуги у
                профессиональных разработчиков и как их избежать, научим
                обустраивать место для комфортной работы и дадим рекомендации по
                физ. тренировкам для ИТ-инженеров.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Ключевые навыки Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как эффективно управлять своим временем и энергией.
                Вы узнаете, как избежать выгорания и всегда оставаться в хорошем
                настроении, даже если что-то идет не по плану.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Английский язык для Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем о роли английского языка в обучении. Дадим
                реккомендации, как эффективно подтянуть исходный уровень до
                требуемого. Вы получите языковую базу для старта обучения.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CourseNeededMaterials :materials="materials" />
    <SelectionCriteria />
    <CourseMainBlockVue
      :new-learn-bullet="learnBullet"
      :new-course-step="courseStep"
      :new-course-step-2="courseStep2"
      is-gap="true"
      syllabus="syllabus_web.pdf"
    />
    <CourseFormVue />
    <CourseNextStageVue />
    <!--    <div class="authors">
          <div class="flex flex-col gap-4">
            <div class="font-size-40px text-gray-800 font-bold">
              Авторы программы
            </div>
            <div class="text-gray-500 text-lg">
              Реальные практики ИТ с многолетним опытом работы в лидирующих проектах
            </div>
          </div>
          <div class="grid grid-cols-2 gap-6">
            <div class="bg-gray-radius py-10 px-8 flex flex-col gap-10">
              <div class="flex gap-6">
                <div>
                  <img src="../../../../assets/images/landing/avatar.png" alt="" />
                </div>
                <div class="flex flex-col gap-2">
                  <div class="font-semibold text-2xl">Имя Фамилия</div>
                  <div class="font-normal text-lg text-gray-500">
                    Генеральный директор Alpha Education
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-4">
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Создатель торговой платформы АГРО24 и образовательных проектов:
                    A2.LIFE, ПОТОК24 и iTec Group.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Выпускник факультета Программного обеспечения в сфере
                    Информационных технологий Белорусского государственного
                    университета информатики и радиоэлектроники.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    В прошлом генеральный директор SaaS провайдера «Мегаплан»,
                    совладелец и управляющий партнер тренинговой компании «Бизнес
                    Молодость».
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-radius py-10 px-8 flex flex-col gap-10">
              <div class="flex gap-6">
                <div>
                  <img src="../../../../assets/images/landing/avatar.png" alt="" />
                </div>
                <div class="flex flex-col gap-2">
                  <div class="font-semibold text-2xl">Имя Фамилия</div>
                  <div class="font-normal text-lg text-gray-500">
                    Генеральный директор Alpha Education
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-4">
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Создатель торговой платформы АГРО24 и образовательных проектов:
                    A2.LIFE, ПОТОК24 и iTec Group.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Выпускник факультета Программного обеспечения в сфере
                    Информационных технологий Белорусского государственного
                    университета информатики и радиоэлектроники.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    В прошлом генеральный директор SaaS провайдера «Мегаплан»,
                    совладелец и управляющий партнер тренинговой компании «Бизнес
                    Молодость».
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->

    <CourseTeachersVue
      :teachers="teachers"
      :scrollToForm="scrollToForm"
      :custom="true"
    />
    <CourseMaterialVue />
    <GuaranteeSliderVue />
    <!--    <OurStudentsWorks />-->
    <CourseReferralVue :col="true" />

    <CourseFaq :new-faq="faq" />
  </div>
</template>

<script>
import PurpleArrowRightIcon from "../../../../assets/icons/PurpleArrowRightIcon.vue";
// import CourseFaq from "./CourseFaq.vue";
import GuaranteeSliderVue from "./GuaranteeSlider.vue";
import CourseReferralVue from "./CourseReferral.vue";
import CourseMaterialVue from "./CourseMaterial.vue";
import CourseTeachersVue from "./CourseTeachers.vue";
import CourseNextStageVue from "./CourseNextStage.vue";
import CourseFormVue from "./CourseForm.vue";
import CourseMainBlockVue from "./CourseMainBlock.vue";
import CourseFitPeopleVue from "./CourseFitPeople.vue";
import CourseFlexibilityVue from "./CourseFlexibility.vue";
import CourseAdvantagesVue from "./CourseAdvantages.vue";
import CourseInfoVue from "./CourseInfo.vue";
import CourseFaq from "@/views/core/Landing/Courses/CourseFaq.vue";
import TheLandingForm from "@/views/core/Landing/NewLanding/sections/TheLandingForm.vue";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";
import { ShoppingCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartMixin";
import AddToCartModal from "@/views/core/Landing/NewLanding/sections/AddToCartModal.vue";
import { AddToCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/AddToCartMixin";
import CourseNeededMaterials from "@/views/core/Landing/Courses/CourseNeededMaterials.vue";
// import OurStudentsWorks from "@/views/core/Landing/Courses/OurStudentsWorks.vue";
import TheLandingFormMixin from "@/views/core/Landing/SliderComponents/TheLandingFormMixin";
// import LandingBreadcrumbs from "@/views/core/Landing/Courses/LandingBreadcrumbs.vue";
import SelectionCriteria from "@/views/core/Landing/Courses/SelectionCriteria.vue";

export default {
  components: {
    SelectionCriteria,
    // LandingBreadcrumbs,
    // OurStudentsWorks,
    CourseNeededMaterials,
    AddToCartModal,
    TheLandingForm,
    CourseFaq,
    CourseInfoVue,
    CourseAdvantagesVue,
    CourseFlexibilityVue,
    CourseFitPeopleVue,
    CourseMainBlockVue,
    CourseFormVue,
    CourseNextStageVue,
    CourseTeachersVue,
    CourseMaterialVue,
    CourseReferralVue,
    GuaranteeSliderVue,
    // CourseFaq,
    PurpleArrowRightIcon,
  },
  props: {
    coursee: {
      type: Object,
    },
  },
  methods: {
    scrollToForm() {
      const formElement = document.getElementById("landing-course-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    submitForm() {
      this.isCompanyName = this.companyName !== "";
      this.isPhone = this.phone !== "";
      this.isPersonName = this.personName !== "";

      if (this.email && !this.email.includes("@")) {
        this.isERR = true;
        return;
      } else {
        this.isERR = false;
      }
      if (this.isCompanyName && this.isPersonName && this.isPhone) {
        this.sendLikeToStudyForm();
      }
    },
    setBreadcrumbs() {
      this.breadcrumbs = [
        {
          path: "all-courses",
          name: "Все курсы",
        },
        {
          path: this.$route.path,
          name: "Веб разработка",
        },
      ];
    },
  },
  mixins: [
    AllCoursesMixin,
    ShoppingCartMixin,
    AddToCartMixin,
    TheLandingFormMixin,
  ],
  async mounted() {
    this.setBreadcrumbs();
    await this.getAllCourses();
    this.courseInfo = this.landingCourses.length > 0 && this.landingCourses[0];
  },
  data() {
    return {
      breadcrumbs: [],
      materials: {
        title: "",
        mat: [
          "Компьютер с доступом в Интернет",
          "Текстовый редактор, такой как Sublime Text, Visual Studio Code.",
          "Браузеры для тестирования, такие как Google Chrome, Mozilla Firefox, и Safari",
        ],
      },
      courseInfo: {},
      searchText: "Веб-разработка",
      teachers: [
        {
          name: "Ботагоз Абсаттар",
          position: "Основатель и PM Alpha Education",
          courses: "Бизнес анализ, Веб-разработка, Компьютерные сети",
          profession:
            "Основатель и преподаватель школы программирования Alpha Education. Более 10 лет занимается веб-разработкой, работала фронт-енд разработчиком, бизнес-аналитиком в IT-компаниях Казахстана, а также имеет опыт в сфере информационной безопасности.  Сейчас является проджект менеджером по созданию веб-приложений. ",
          education: [
            "Бакалавр Компьютерных Наук (Eurasian National University)",
            "Магистр Компьютерных Наук (Eurasian National University)",
            "Магистр Телекоммуникации и связи (University College of London)",
          ],
          image: "botagoz.jpeg",
          programs: ["Веб-разработка"],
        },
        {
          name: "Адиль Жексенов",
          position: "Fullstack разрaботчик и преподаватель Alpha Education",
          courses: "Веб-разработка",
          profession:
            "Веб-разработчик с двумя годами опыта в production, включая работу в аутсорсинге и на государственных проектах, обладает опытом как во фронтенд, так и в бэкенд разработке на разнообразных платформах. Портфолио включает в себя успешно внедренные функциональные решения, которые применялись в различных проектах.",
          education: [],
          image: "adil.jpeg",
          programs: ["Веб-разработка"],
        },
      ],

      courseStep: [
        {
          title: "1-2 неделя",
          text: [
            "Введение в курс. Что такое веб. Основы веб-технологий. Интернет, W3",
            "Различие между фронтендом и бекендом",
            "Веб-стандарты и протоколы",
          ],
          open: false,
        },
        {
          title: "3-4 неделя",
          text: [
            "Основы HTML и структура веб-страницы",
            "Работа с тегами, атрибутами и элементами",
            " Оформление веб-страниц с помощью CSS. Синтаксис CSS. Псевдоклассы. Разметка таблиц.",
            "    Создание адаптивного дизайна.",
          ],
          open: false,
        },
      ],
      courseStep2: [
        {
          title: "26-27 неделя",
          text: [
            "Разработка функциональности для загрузки и управления файлами на сервере",
            "Интеграция с Google Cloud Services для хранения и доступа к файлам",
            "Этапы развертывания веб-приложения: от выбора провайдера до конфигурации сервера и публикации",
            "Работа над проектом",
          ],
          open: false,
        },
      ],
      learnBullet: [
        "Основы HTML/CSS и JavaScript",
        "Фреймворки и библиотеки: Изучение популярных фреймворков и библиотек, таких как React, Vue.js, для эффективной разработки фронтенд-приложений.",
        "Адаптивный и отзывчивый дизайн: Создание веб-страниц, которые отображаются корректно на разных устройствах и экранах.",
        "Языки программирования: Изучение языков программирования, таких как Python, Node.js, Express.js, для создания серверной логики и обработки данных.",
        "Разработка баз данных: MongoDB",
        "API и веб-серверы. Создание API для взаимодействия с фронтенд-частью и разработка веб-серверов для обработки запросов",
      ],
      course: {
        name: "Веб-разработка",
        level: "Junior",
        program: "Веб-разработка",
        description:
          "Этот курс предназначен для тех, кто хочет овладеть основами создания интерактивных и привлекательных веб-сайтов. В течение курса студенты изучают различные инструменты и программы, которые помогут им стать успешными фронтенд-разработчиками и бэкенд-разработчиками. Курс начинается с введения в основы веб-разработки, где объясняются ключевые технологии и концепции, такие как HTML, CSS и JavaScript. ",
        skills: [
          "HTML",
          "CSS",
          "Bootstrap",
          "JavaScript",
          "VueJS",
          "ReactJS",
          "NodeJs",
          "ExpressJs",
          "MongoDB",
        ],
        duration: "6 месяцев по 4,5 часа в неделю (162 академических часов)",
        start_date: "август 2024 г",
        price: "600 000 ₸",
      },
      faq: [
        {
          question: "Что такое веб-разработка?",
          answer:
            "Веб-разработка - это процесс создания и поддержки веб-сайтов или приложений, которые функционируют в интернете. Включает в себя работу с фронтендом (клиентская часть) и бекендом (серверная часть).",
          open: false,
        },
        {
          question: "Чем отличается фронтенд от бекенда?",
          answer:
            "Фронтенд отвечает за пользовательский интерфейс и взаимодействие с ним, включая дизайн, верстку и программирование на языках, таких как HTML, CSS и JavaScript. Бекенд, с другой стороны, отвечает за обработку данных, хранение информации и выполнение бизнес-логики на сервере, используя языки программирования, такие как Python, Ruby или Java.",
          open: false,
        },
        {
          question: "Какие языки программирования используются на фронтенде?",
          answer:
            "Языки программирования для бекенда могут варьироваться, но некоторые из наиболее популярных включают Python, Ruby, Java, PHP и JavaScript (с использованием платформы Node.js). Эти языки используются для создания серверной логики, обработки данных и взаимодействия с базами данных.",
          open: false,
        },
        {
          question: "Какая разница между SQL и NoSQL базами данных?",
          answer:
            "SQL (Structured Query Language) и NoSQL (Not Only SQL) - это два основных типа баз данных. SQL базы данных используют структурированные таблицы и SQL запросы для работы с данными. NoSQL базы данных, например MongoDB или Cassandra, предлагают более гибкие модели данных, обычно без использования таблиц и SQL. Выбор между SQL и NoSQL зависит от требований проекта.",
          open: false,
        },
        {
          question: "Какие инструменты используются в веб-разработке?",
          answer:
            "Веб-разработка включает в себя использование различных инструментов. Некоторые популярные инструменты включают текстовые редакторы (например, Visual Studio Code, Sublime Text), системы контроля версий (например, Git), фреймворки (например, React, Angular, Django), инструменты для сборки и автоматизации задач (например, Webpack, Gulp) и многое другое.",
          open: false,
        },
        {
          question: "Что такое API и как он используется в веб-разработке?",
          answer:
            "API (Application Programming Interface) - это набор правил и протоколов, которые позволяют различным программам взаимодействовать друг с другом. В веб-разработке API часто используется для обмена данными между фронтендом и бекендом. Например, веб-приложение может использовать API для получения данных с сервера или отправки данных на сервер.",
          open: false,
        },
        {
          question: "Как развернуть веб-приложение на сервере?",
          answer:
            "Развертывание веб-приложения на сервере включает несколько шагов. Один из способов - это использование виртуального сервера (например, с помощью облачных провайдеров, таких как Amazon Web Services, Microsoft Azure или DigitalOcean), где вы можете установить и настроить необходимое программное обеспечение, такое как веб-сервер (например, Apache или Nginx) и настроить доступ к вашему приложению через интернет.",
          open: false,
        },
        {
          question:
            "Какие навыки и знания необходимы для стать веб-разработчиком?",
          answer:
            "Для становления веб-разработчиком необходимо обладать хорошими знаниями HTML, CSS и JavaScript для работы с фронтендом. Для работы с бекендом полезно знать языки программирования, такие как Python, Ruby, Java или PHP, а также иметь понимание работы баз данных и протоколов обмена данными. Отличные аналитические и проблемно-ориентированные навыки также важны для успешной веб-разработки.",
          open: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.course {
  background: #2b2e3b;
}
.course__info {
  background: #f4f5fa;
  padding: 40px 100px;
}
.bottom {
  background: #f4f5fa;
  width: 100%;
  height: 120px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.company {
  padding: 64px 100px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.company__div-1 {
  display: flex;
  gap: 90px;
}
.company__div-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}
.company__sign {
  color: #a599f5;
}
.company__img {
  width: 140px;
  height: 140px;
}

.demanded {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.demanded__box-1 {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-1__border {
  display: inline-block;
  border-bottom: 2px solid #593ed3;
}
.demanded__box-1 {
  flex-basis: 50%;
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-2 {
  flex-basis: 50%;
  background: #eee6ff;
  border-radius: 32px;
}

.bg-gradient {
  background: linear-gradient(
    89.33deg,
    #eb8aff 7.2%,
    #b792ff 42.58%,
    #898fff 84.4%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.authors {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.authors__bullet {
  margin-top: 10px;
  display: inline-block;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #786ce6;
}

.font-size-40px {
  font-size: 40px;
}
.font-size-36px {
  font-size: 36px;
}
.font-size-28px {
  font-size: 28px;
}
.padding-100px {
  padding: 0px 100px;
}

.form__item {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 32px;
}
.form__item input {
  border: 1px solid #9ca3af;
  border-radius: 16px;
}
.form__btn {
  width: 100%;
  background: #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__btn-white {
  width: 100%;
  background: #fff;
  color: #786ce6;
  border: 2px solid #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__approval-link {
  color: #786ce6;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.text-gray-100 {
  color: #f3f4f6;
}
.text-gray-50 {
  color: #f9fafb;
}
.study-plan2 {
  display: none;
}

@media (max-width: 650px) {
  .course__info {
    padding: 32px 16px;
  }
  #landing-course-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .course__blocks {
    padding: 0;
  }
  .bottom {
    display: none;
  }
  .company {
    padding: 32px 16px;
  }
  .company__div-1 {
    padding: 0;
    width: 100%;
    flex-basis: 100%;
  }
  .company__div-2 {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  .demanded {
    padding: 48px 16px;
  }
  .adaptive-demanded {
    flex-direction: column;
  }
  .trajectory {
    padding: 64px 16px;
  }
  .adaptive-trajectory {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #d1d5db;
  }
  .adaptive-duration {
    flex-direction: column;
    align-items: center;
  }
  .length__item {
    width: fit-content;
  }
  .company__img-div {
    display: none;
  }
  .adaptive-grid {
    display: flex;
    flex-direction: column;
  }
  .study-plan {
    display: none;
  }
  .study-plan2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
  }

  .adaptive-company-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .adaptive-company-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-company-subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
}
</style>
