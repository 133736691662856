<template>
  <TheCorePageHeader v-if="resource.id">
    <vue-countdown
        :time="resource.remaining_time * 1000"
        v-slot="{  hours, minutes, seconds }"
        @end="handleCountdownEnd"
        v-if="resource.has_timer"
    >
      <span class="text-lg leading-8 font-semibold" :class="hours>0 || minutes>30?'text-green-500':'text-red-500'">
       {{ normalize(hours)}}:{{normalize(minutes)}}:{{normalize(seconds)}}
      </span>
    </vue-countdown>
    <div v-else/>
    <h1 class="text-2xl leading-8 font-semibold text-center">
      {{activePageName}}
    </h1>
    <div>
      <XIcon
          class="h-5 w-5 cursor-pointer"
          @click="show_finish_resource_modal=true"
      />
    </div>
  </TheCorePageHeader>
</template>
<script>
import TheCorePageHeader from "@/components/ThePageHeader/TheCorePageHeader";
import {XIcon} from "@heroicons/vue/outline"
import {mapFields} from "vuex-map-fields";
import VueCountdown from '@chenfengyuan/vue-countdown';

export default {
  components: {TheCorePageHeader,XIcon,VueCountdown},
  data:()=>({
    available_until:new Date()
  }),
  computed:{
    ...mapFields([
        'resources.resource',
        'resources.active_page_index',
        'resources.show_finish_resource_modal',
        'resources.show_quiz_timeout_modal']),
    activePageName(){
      return this.resource.title
    }
  },
  methods:{
    normalize(val){
      return val<10? `0${val}`:val
    },
    handleCountdownEnd(){
      this.show_quiz_timeout_modal= true;
    }
  }
}
</script>