<template>
  <div class="flex-1 w-full order-last sm:order-0 flex justify-center text-center items-center">
    <div
        @click="setActivePageIndex(1)"
        class="border-transparent border-t-2 pt-4 px-4 text-sm cursor-pointer pb-4"
        :class="[
            visitedPages.includes(1) || activePage === 1
              ? 'text-indigo-600'
              : 'text-gray-500 hover:text-gray-700 hover:border-gray-300',
            activePage === 1
              ? 'border-indigo-500 text-indigo-600'
              : '',
            ]
          "
    >
      1
    </div>
    <template v-if="pagesAmount > 2">
      <div
          @click="setActivePageIndex(2)"
          class="border-transparent border-t-2 pt-4 px-4 text-sm cursor-pointer pb-4"
          :class="[
              visitedPages.includes(2) || activePage === 2
                ? 'text-indigo-600'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300',
              activePage === 2
                ? 'border-indigo-500 text-indigo-600'
                : '',
              ]
            "
      >
        2
      </div>
      <div
          v-if="pagesAmount > 3 && (activePage < 3 || activePage === pagesAmount)"
          @click="setActivePageIndex(3)"
          class="border-transparent border-t-2 pt-4 px-4 text-sm cursor-pointer pb-4"
          :class="[
              visitedPages.includes(3) || activePage === 3
                ? 'text-indigo-600'
                : 'text-gray-500 hover:text-gray-700 hover:border-gray-300',
              activePage === 3
                ? 'border-indigo-500 text-indigo-600'
                : '',
              ]
            "
      >
        3
      </div>
    </template>
    <template v-if="activePage > 2 && activePage < pagesAmount">
      <div v-if="activePage > 3">...</div>
      <div
          v-if="activePage > 3"
          @click="setActivePageIndex(activePage - 1)"
          class="border-transparent border-t-2 pt-4 px-4 text-sm cursor-pointer pb-4"
      >
        {{ activePage - 1 }}
      </div>
      <div
          @click="setActivePageIndex(activePage)"
          class="border-transparent border-t-2 pt-4 px-4 text-sm cursor-pointer pb-4 text-indigo-600 border-indigo-500"
      >
        {{ activePage }}
      </div>
      <div
          v-if="activePage + 1 < pagesAmount"
          @click="setActivePageIndex(activePage + 1)"
          class="border-transparent border-t-2 pt-4 px-4 text-sm cursor-pointer pb-4"
      >
        {{ activePage + 1 }}
      </div>
      <div v-if="activePage + 2 < pagesAmount">...</div>
    </template>
    <div v-else-if="pagesAmount > 4" class="inline-flex">...</div>
    <div
        v-if="activePage === pagesAmount && pagesAmount > 5"
        @click="setActivePageIndex(pagesAmount - 1)"
        class="border-transparent border-t-2 pt-4 px-4 text-sm cursor-pointer pb-4"
        :class="[
            visitedPages.includes(pagesAmount - 1) || activePage === pagesAmount - 1
              ? 'text-indigo-600'
              : 'text-gray-500 hover:text-gray-700 hover:border-gray-300',
            activePage === pagesAmount - 1
              ? 'border-indigo-500 text-indigo-600'
              : '',
            ]
          "
    >
      {{ pagesAmount - 1 }}
    </div>
    <div
        v-if="pagesAmount > 1"
        @click="setActivePageIndex(pagesAmount)"
        class="border-transparent border-t-2 pt-4 px-4 text-sm cursor-pointer pb-4"
        :class="[
            visitedPages.includes(pagesAmount) || activePage === pagesAmount
              ? 'text-indigo-600'
              : 'text-gray-500 hover:text-gray-700 hover:border-gray-300',
            activePage === pagesAmount
              ? 'border-indigo-500 text-indigo-600'
              : '',
            ]
          "
    >
      {{ pagesAmount }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    activePage: {
      type: [Number, String],
    },
    visitedPages: {
      type: Array,
      default: () => []
    },
    pagesAmount: {
      type: [Number, String],
      default: 1
    }
  },
  methods: {
    setActivePageIndex(n) {
      this.$emit('setActivePageIndex', n)
    }
  }
}
</script>