//todo project main state is there
import { SET_LOADING } from "@/store/core";

export const GET_CERTIFICATE_TYPES = "GET_CERTIFICATE_TYPES";
export const SET_CERTIFICATE_TYPES = "SET_CERTIFICATE_TYPES";
export const CREATE_BULK_CERTIFICATES = "CREATE_BULK_CERTIFICATES";
export const SET_CERTIFICATES = "SET_CERTIFICATES";
export const GET_MY_CERTIFICATES = "GET_MY_CERTIFICATES";
export const CHECK_CERTIFICATE = "CHECK_CERTIFICATE";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {
    certificate_types: [],
    certificates: [],
  };
  const actions = {
    [GET_CERTIFICATE_TYPES]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.certificates.get_certificate_types();
      commit(SET_CERTIFICATE_TYPES, data.results);
      commit(SET_LOADING, false);
      return data.results;
    },
    [CREATE_BULK_CERTIFICATES]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.certificates.create_bulk_certificate(params);
      commit(SET_CERTIFICATE_TYPES, data.results);
      commit(SET_LOADING, false);
      return data.results;
    },
    [GET_MY_CERTIFICATES]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.certificates.get_my_certificates();
      commit(SET_LOADING, false);
      return data.results;
    },
    [CHECK_CERTIFICATE]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } = await $api.certificates.check_certificate(params);
      commit(SET_LOADING, false);
      return data;
    },
  };
  const mutations = {
    [SET_CERTIFICATE_TYPES]: (store, payload) => {
      store.certificate_types = payload;
    },
    [SET_CERTIFICATES]: (store, payload) => {
      store.certificates = payload;
    },
  };

  const getters = {
    certificate_types: (state) => state.certificate_types,
    certificates: (state) => state.certificates,
  };

  return {
    actions,
    state,
    mutations,
    getters,
  };
};
export default (app) => {
  return createStore(app);
};
