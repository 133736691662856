<template>
  <div class="container">
    <div class="review-header text-white font-bold">
      Что о нас говорят наши клиенты:
    </div>
    <div class="grid grid-cols-2 mt-10 adaptive-slider">
      <ReviewCard
        v-for="review in reviews"
        :key="review.key"
        :review="review"
      />
    </div>
    <div class="adaptive-slider2">
      <swiper
        :modules="modules"
        :slides-per-view="1"
        :space-between="16"
        :pagination="pagination"
      >
        <swiper-slide v-for="review in reviews" :key="review.key">
          <ReviewCard :review="review"
        /></swiper-slide>
      </swiper>
    </div>
  </div>
</template>
<script>
import ReviewCard from "@/views/core/Landing/NewLanding/cards/ReviewCard.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "../../../../../assets/css/swiper.css";
export default {
  components: {
    SwiperSlide,
    Swiper,
    ReviewCard,
  },
  data() {
    return {
      pagination: {
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '"></span>';
        },

        bulletClass: "swiper-pagination-bullet",
        bulletActiveClass: "swiper-pagination-bullet-active",
        pagination: "swiper-pagination",
      },
      modules: [Navigation, Pagination],
      reviews: [
        {
          key: 1,
          title: "Асхат Молкенов",
          subtitle: "Координатор “IT в бизнесе” в КазГЮУ",
          content:
            "Система позволяет студентам обучаться в более интересном формате. Геймификация служит дополнительной мотивацией для выполнения заданий",
          rating: "5",
          img: "askhat.jpeg",
        },
        {
          key: 2,
          title: "Ердаулет Абсаттар",
          subtitle: "Директор Alpha Design",
          content:
            "Я пользуюсь этой платформой для организации ресурсов, полезных материалов. Очень удобная платформа для освоения и использования",
          rating: "5",
          img: "yerdaulet.jpeg",
        },
        {
          key: 3,
          title: "Дворец Школьников",
          subtitle: "имени М.Утемисулы",
          content:
            "Отличный инструмент для онлайн занятий. Особенно очень удобная система хранения и просмотр ресурсов",
          rating: "5",
          img: "dvoretz.png",
        },
      ],
    };
  },
};
</script>
<style scoped>
.container {
  margin: 50px 100px;
  background: linear-gradient(70.91deg, #9978df 0%, #667eea 100%);
  border-radius: 40px;
  padding: 64px 80px;
}

.review-header {
  font-size: 40px;
}

.adaptive-slider {
  display: grid;
  column-gap: 40px;
  row-gap: 45px;
}

.adaptive-slider2 {
  display: none;
}

:deep .swiper-pagination-bullet {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e4d7ff;
}

:deep .swiper-pagination-bullet-active {
  margin-top: 16px;
  width: 48px;
  height: 6px;
  border-radius: 4px;
  background: #e5e7eb;
}

@media (max-width: 650px) {
  .container {
    padding: 40px 8px;
    margin: 40px 16px;
  }

  .review-header {
    padding: 0px 12px;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }

  .adaptive-slider {
    display: none;
  }

  .adaptive-slider2 {
    display: flex;
    padding: 40px 20px 0 5px;
  }
}
</style>
