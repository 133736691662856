<template>
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="#fff"
    stroke="#fff"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3002 1.21022C13.2077 1.11752 13.0978 1.04397 12.9768 0.993791C12.8559 0.94361 12.7262 0.917779 12.5952 0.917779C12.4643 0.917779 12.3346 0.94361 12.2136 0.993791C12.0926 1.04397 11.9827 1.11752 11.8902 1.21022L7.00022 6.09022L2.11022 1.20022C2.01764 1.10764 1.90773 1.0342 1.78677 0.984097C1.6658 0.933992 1.53615 0.908203 1.40522 0.908203C1.27429 0.908203 1.14464 0.933992 1.02368 0.984097C0.902716 1.0342 0.792805 1.10764 0.700223 1.20022C0.607642 1.29281 0.534201 1.40272 0.484097 1.52368C0.433992 1.64464 0.408203 1.77429 0.408203 1.90522C0.408203 2.03615 0.433992 2.1658 0.484097 2.28677C0.534202 2.40773 0.607642 2.51764 0.700223 2.61022L5.59022 7.50022L0.700223 12.3902C0.607642 12.4828 0.534201 12.5927 0.484097 12.7137C0.433992 12.8346 0.408203 12.9643 0.408203 13.0952C0.408203 13.2262 0.433992 13.3558 0.484097 13.4768C0.534201 13.5977 0.607642 13.7076 0.700223 13.8002C0.792805 13.8928 0.902716 13.9662 1.02368 14.0163C1.14464 14.0665 1.27429 14.0922 1.40522 14.0922C1.53615 14.0922 1.6658 14.0665 1.78677 14.0163C1.90773 13.9662 2.01764 13.8928 2.11022 13.8002L7.00022 8.91022L11.8902 13.8002C11.9828 13.8928 12.0927 13.9662 12.2137 14.0163C12.3346 14.0665 12.4643 14.0922 12.5952 14.0922C12.7262 14.0922 12.8558 14.0665 12.9768 14.0163C13.0977 13.9662 13.2076 13.8928 13.3002 13.8002C13.3928 13.7076 13.4662 13.5977 13.5163 13.4768C13.5665 13.3558 13.5922 13.2262 13.5922 13.0952C13.5922 12.9643 13.5665 12.8346 13.5163 12.7137C13.4662 12.5927 13.3928 12.4828 13.3002 12.3902L8.41022 7.50022L13.3002 2.61022C13.6802 2.23022 13.6802 1.59022 13.3002 1.21022Z"
      fill="#7A69EE"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
