export default {
    props:{
        instructor:{
            type:Object,
            default:()=>({
                full_name:"Не указано",
                avatar:"",
            })
        },
        description:{
            type:String,
            default:""
        }
    }
}