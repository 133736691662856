<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <div>
    <CoursesSelectModal v-model="form.course_ids" />
    <FormLayout>
      <template #title>
        {{
          $t(isUpdating ? "update_specialization" : "create_new_specialization")
        }}
      </template>
      <template #main>
        <TheCard>
          <div class="text-gray-900 text-lg leading-8 font-semibold mb-4">
            {{ $t("specialization_name") }}
          </div>
          <InputText :placeholder="$t('enter_name')" v-model="form.name" />
        </TheCard>
        <TheCard style="min-width: unset">
          <div class="text-gray-900 text-lg leading-8 font-semibold mb-4">
            {{ $t("description") }}
          </div>
          <TheQuillEditorInput v-model="form.description" />
        </TheCard>
        <TheCard>
          <div class="text-gray-900 text-lg leading-8 font-semibold mb-4">
            {{ $t("specialization_courses") }}
          </div>
          <TheButton
            bg="secondary"
            @click="openCoursesSelectorModal"
            class="mb-5"
          >
            <PlusIcon class="h-5 mr-2" />
            {{ $t("add_courses") }}
          </TheButton>
          <div>
            <CourseCardRowExtraSmall
              v-for="course_id in form.course_ids"
              :key="`course_${course_id}`"
              :name="courses[course_id].name"
              :logo="courses[course_id].logo"
              class="mb-4"
            >
              <template #rightPlace>
                <TrashIcon
                  class="h-6 text-gray-400 cursor-pointer"
                  @click="removeCourse(course_id)"
                />
              </template>
            </CourseCardRowExtraSmall>
          </div>
        </TheCard>
      </template>
      <template #secondary>
        <TheCard class="px-3">
          <div class="text-gray-900 text-lg leading-8 font-semibold mb-4">
            {{ $t("specialization_logo") }}
          </div>
          <FileDropzone v-model="form.logo" :key="form.logo" />
        </TheCard>
      </template>
      <template #actions>
        <div
          class="flex p-3 flex-row place-content-end fixed bottom-0 right-0 bg-white w-full"
        >
          <TheButton bg="outline" class="mr-3" @click="cancel">{{
            $t("cancel")
          }}</TheButton>
          <TheButton bg="secondary" class="mr-3" @click="handleModalOpen">{{
            $t("delete")
          }}</TheButton>
          <TheButton bg="primary" @click="submit">{{
            $t(isUpdating ? "update" : "create")
          }}</TheButton>
        </div>
      </template>
    </FormLayout>
    <TheModal :show="modalOpen" @close="handleModalClose" class="z-50">
      <div class="p-6">
        <div class="flex gap-4 mb-4">
          <div
            class="w-10 h-10 grid place-items-center bg-red-100 rounded-full"
          >
            <ExclamationIcon class="text-red-600 w-6 h-6" />
          </div>
          <div class="flex flex-col gap-2">
            <div class="text-lg text-gray-900 leading-6 font-medium">
              Удалить специализацию
            </div>
            <div class="text-sm text-gray-500 leading-5">
              Вы уверены, что хотите удалить специализацию?
            </div>
          </div>
        </div>
        <div class="flex justify-end gap-3">
          <TheButton bg="outline" class="mr-3" @click="handleModalClose">{{
            $t("cancel")
          }}</TheButton>
          <TheButton bg="danger" class="mr-3" @click="deleteSpecialization">{{
            $t("delete")
          }}</TheButton>
        </div>
      </div>
    </TheModal>
  </div>
</template>
<script>
import TheCard from "@/components/TheCard/TheCard";
import InputText from "@/components/InputText";
import TheQuillEditorInput from "@/components/TheQuillEditorInput";
import FileDropzone from "@/components/FileDropzone";
import TheButton from "@/components/Button/Button";
import { PlusIcon } from "@heroicons/vue/solid";
import { ExclamationIcon, TrashIcon } from "@heroicons/vue/outline";
import FormMixin from "@/views/specializations/Form/FormMixin";
import CoursesSelectModal from "@/components/Courses/Modals/CoursesSelectModal";
import CourseCardRowExtraSmall from "@/components/Courses/Card/CourseCardRowExtraSmall";
import FormLayout from "@/layouts/FormLayout";
import TheModal from "@/components/TheModal/TheModal";

export default {
  components: {
    FormLayout,
    CourseCardRowExtraSmall,
    CoursesSelectModal,
    TheButton,
    FileDropzone,
    TheQuillEditorInput,
    InputText,
    TheCard,
    PlusIcon,
    TrashIcon,
    TheModal,
    ExclamationIcon,
  },
  mixins: [FormMixin],
  name: "TheSpecializationsForm",
};
</script>
