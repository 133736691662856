<template>
  <TheModal :show="modalOpen" class="register-modal-block" @close="closeModal"
    ><div class="register-modal">
      <div class="close-modal-block justify-between">
        <div>Зарегистрируйтесь и начните обучение</div>
        <div class="cursor-pointer" @click="closeModal">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.5004 9.51704C30.3462 9.36253 30.163 9.23995 29.9614 9.15632C29.7598 9.07268 29.5437 9.02963 29.3254 9.02963C29.1071 9.02963 28.891 9.07268 28.6893 9.15632C28.4877 9.23995 28.3046 9.36253 28.1504 9.51704L20.0004 17.6504L11.8504 9.50037C11.6961 9.34607 11.5129 9.22367 11.3113 9.14016C11.1097 9.05665 10.8936 9.01367 10.6754 9.01367C10.4572 9.01367 10.2411 9.05665 10.0395 9.14016C9.83786 9.22367 9.65468 9.34607 9.50037 9.50037C9.34607 9.65468 9.22367 9.83786 9.14016 10.0395C9.05665 10.2411 9.01367 10.4572 9.01367 10.6754C9.01367 10.8936 9.05665 11.1097 9.14016 11.3113C9.22367 11.5129 9.34607 11.6961 9.50037 11.8504L17.6504 20.0004L9.50037 28.1504C9.34607 28.3047 9.22367 28.4879 9.14016 28.6895C9.05665 28.8911 9.01367 29.1072 9.01367 29.3254C9.01367 29.5436 9.05665 29.7597 9.14016 29.9613C9.22367 30.1629 9.34607 30.3461 9.50037 30.5004C9.65468 30.6547 9.83786 30.7771 10.0395 30.8606C10.2411 30.9441 10.4572 30.9871 10.6754 30.9871C10.8936 30.9871 11.1097 30.9441 11.3113 30.8606C11.5129 30.7771 11.6961 30.6547 11.8504 30.5004L20.0004 22.3504L28.1504 30.5004C28.3047 30.6547 28.4879 30.7771 28.6895 30.8606C28.8911 30.9441 29.1072 30.9871 29.3254 30.9871C29.5436 30.9871 29.7597 30.9441 29.9613 30.8606C30.1629 30.7771 30.3461 30.6547 30.5004 30.5004C30.6547 30.3461 30.7771 30.1629 30.8606 29.9613C30.9441 29.7597 30.9871 29.5436 30.9871 29.3254C30.9871 29.1072 30.9441 28.8911 30.8606 28.6895C30.7771 28.4879 30.6547 28.3047 30.5004 28.1504L22.3504 20.0004L30.5004 11.8504C31.1337 11.217 31.1337 10.1504 30.5004 9.51704Z"
              fill="#B3A6F9"
            />
          </svg>
        </div>
      </div>
      <div class="flex flex-col gap-5">
        <InputText
          label="Введите полное имя"
          v-model="personName"
          is-required
          :class="{ 'border-error': !isPersonNameValid }"
          placeholder="Имя"
        />
        <InputText
          type="email"
          placeholder="example@example.com"
          v-model="email"
          :class="{ 'border-error': !isEmailValid }"
          label="E-mail"
        />
        <InputText
          v-model="phone"
          is-password
          is-required
          placeholder="Придумайте пароль"
          label="Пароль"
          :class="{ 'border-error': !isPasswordValid }"
        />
      </div>
      <div class="flex flex-col gap-4">
        <button class="order-button" @click="openRegisterModal">
          Зарегистрироваться
        </button>
        <div class="text-center text-gray-500 confidential-text">
          Продолжая, вы даёте согласие на обработку своих персональных данных в
          соответствии с
          <span class="text-politics">политикой конфиденциальности </span>
        </div>
      </div>
      <div class="stick-grey" />
      <router-link :to="{ name: 'Login' }"
        ><div class="flex gap-4 align-center justify-center">
          <div class="text-gray-800">Уже есть аккаунт?</div>
          <div class="cursor-pointer text-login">Войти</div>
        </div></router-link
      >
    </div>
  </TheModal>
</template>
<script>
import { defineComponent } from "vue";
import TheModal from "@/components/TheModal/TheModal.vue";
import InputText from "@/components/InputText.vue";
import axios from "axios";

export default defineComponent({
  components: { InputText, TheModal },
  props: ["modalOpen"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
    data() {
      return {
        personName: "",
        email: "",
        password: "",
        isPersonNameValid: false,
        isEmailValid: false,
        isPasswordValid: false,
        isFormSubmitted: false,
      };
    },

    validateInputs() {
      this.isPersonNameValid = this.personName !== "";
      this.isEmailValid = this.email !== "" && this.email.includes("@");
      this.isPasswordValid = this.password !== "" && this.password.length > 6;
      return (
        this.isPersonNameValid && this.isEmailValid && this.isPasswordValid
      );
    },
    submitForm() {
      if (this.validateInputs()) {
        axios
          .post("/api/v1/application_forms/like_to_study_application/", {
            name: this.personName,
            contact_phone: this.phone,
            email: this.email,
            organization_name: this.organization,
            program: this.programName,
          })
          .then(() => {
            this.isFormSubmitted = true;
            this.personName = "";
            this.email = "";
            this.password = "";

            // Clear the form or handle the submission success
          })
          .catch((error) => {
            console.error("There was an error submitting the form", error);
            // Handle the submission error
          });
      }
    },
  },
});
</script>

<style scoped>
.register-modal {
  padding: 56px 48px;
  display: flex;
  gap: 40px;
  flex-direction: column;
}
.close-modal-block {
  display: flex;
  gap: 24px;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  align-items: center;
}
.border-error {
  border: 1px solid rgb(239, 68, 68);
}
.order-button {
  display: flex;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 32px;
  background: #7a69ee;
  color: white;
}
.confidential-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.text-politics {
  color: #786ce6;
}
.stick-grey {
  display: flex;
  height: 1.5px;
  align-self: stretch;
  background: #d1d5db;
  width: 100%;
}
.text-login {
  color: #786ce6;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  text-decoration-line: underline;
}
.register-modal-block {
  width: 300px;
  border-radius: 32px;
}
</style>
