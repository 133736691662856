<script>
export default {
  props: {
    img: {
      type: String,
      default: "selection-criteria-graphic.png",
    },
  },
  methods: {
    requireImage(imagePath) {
      if (!imagePath) return "";
      return require(`@/assets/images/landing/${imagePath}`);
    },
  },
};
</script>

<template>
  <div class="main-tariffs flex flex-col gap-10">
    <h1>Отбор</h1>
    <div class="one-tariff flex">
      <div class="tariff-box flex flex-col justify-center gap-10 items-center">
        <div class="price-now">Критерии</div>
      </div>

      <div><div class="line" /></div>
      <div class="tariff-content-box flex flex-col gap-4">
        <img :src="requireImage(img)" />
      </div>
    </div>
  </div>
</template>

<style scoped>
h1 {
  color: #1f2937;
  font-size: 40px;
  font-weight: 800;
}
.main-tariffs {
  background: #f4f5fa;
  padding: 40px 100px;
}
.tariffs-types {
  display: none;
}
.tariffs-mob {
  display: none;
}
.tariffs-tabs-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}
.is-active {
  background: #786ce6;
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 6px;
}
.not-active {
  background: white;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #786ce680;
}
@media (max-width: 650px) {
  .main-tariffs {
    padding: 32px 16px;
    gap: 24px;
  }
  h1 {
    font-size: 28px;
    font-weight: 700;
  }
  .tariffs-types {
    display: flex;
  }
  .tariffs {
    display: none;
  }
  .tariffs-mob {
    display: flex;
  }
}
.one-tariff {
  border-radius: 24px;
  background: white;
  padding: 40px 0;
}
.tariff-box {
  flex-basis: 43%;
  padding: 0 80px;
}
.tariff-content-box {
  flex-basis: 57%;
  padding: 0 80px;
}
.tariff-subheading {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  color: #786ce6;
}
.tariff-heading {
  font-weight: 600;
  font-size: 28px;
}
.price-now {
  font-size: 40px;
  font-weight: 700;
  color: #1f2937;
}
.line {
  height: 100%;
  border-left: 2px dashed #d1d5db;
  min-width: 2px;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 32px;
}
.tariff-benefits {
  color: #6b7280;
  font-size: 14px;
  font-weight: 400;
}
button {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  padding: 16px 115px;
  border-radius: 25px;
  background: #786ce6;
  color: white;
}
</style>
