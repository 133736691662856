import { SET_LOADING } from "./core";

//todo project main state is there
export const GET_USERS = "GET_USERS";
export const SET_USERS = "SET_USERS";
export const SET_GROUPS = "SET_GROUPS";
export const GET_COURSE_GROUPS = "GET_COURSE_GROUPS";
export const DELETE_USER_COURSE_GROUPS = "DELETE_USER_COURSE_GROUPS";
export const CREATE_USER_COURSE_GROUPS = "CREATE_USER_COURSE_GROUPS";
export const CREATE_USER_COURSE_GROUPS_FILE = "CREATE_USER_COURSE_GROUPS_FILE";
export const GET_USERS_NEXT = "GET_USERS_NEXT";
export const GET_USERS_ROLES = "GET_USERS_ROLES";
export const GET_USER_GROUPS = "GET_USER_GROUPS";
export const CREATE_ROLES_FILE = "CREATE_ROLES_FILE";
export const CREATE_USER_ROLE = "CREATE_USER_ROLE";
export const UPDATE_USER_ROLE = "UPDATE_USER_ROLE";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_FROM_FILE = "UPDATE_FROM_FILE";
export const CREATE_USERS = "CREATE_USERS";
export const GET_ACTIVITY_LOGS = "GET_ACTIVITY_LOGS";
export const GET_DETAILED_ACTIVITY = "GET_DETAILED_ACTIVITY";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const GET_CERTIFICATES = "GET_CERTIFICATES";
export const GET_NOTIFICATIONS_COUNT = "GET_NOTIFICATIONS_COUNT"
export const GET_SUBMISSION = "GET_SUBMISSION";
export const GET_GENERAL_STATISTICS = 'GET_GENERAL_STATISTICS';
export const DOWNLOAD_GENERAL_STATISTICS = 'DOWNLOAD_GENERAL_STATISTICS';
export const GET_LOGIN_ATTEMPTS = "GET_LOGIN_ATTEMPTS";
const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {
    users: [],
    groups: [],
  };
  const actions = {
    [GET_USERS]: async ({ commit }, payload) => {
      const { data } = await $api.users.get_list(payload);
      commit(SET_USERS, data);
      return data;
    },
    [GET_COURSE_GROUPS]: async ({ commit }, payload) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.get_course_groups(payload);
      commit(SET_GROUPS, data);
      commit(SET_LOADING, false);
      return data;
    },
    [DELETE_USER_COURSE_GROUPS]: (store, user_course_group_id) => {
      return $api.users.delete_user_course_group(user_course_group_id);
    },
    [CREATE_USER_COURSE_GROUPS]: async ({ commit }, payload) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.create_user_course_groups(payload);
      commit(SET_LOADING, false);
      return data;
    },
    [CREATE_USER_COURSE_GROUPS_FILE]: async ({ commit }, form) => {
      commit(SET_LOADING, true);
      const formData = new FormData();
      formData.append("file", form["file"]);
      formData.append("course", form["course"]);
      formData.append("group_number", form["group_number"]);
      const { data } = await $api.users.create_user_course_groups_file(
        formData
      );
      commit(SET_LOADING, false);
      return data;
    },
    [GET_USERS_NEXT]: async ({ commit }, link) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.get_users_next(link);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_USERS_ROLES]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.get_users_roles(payload);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_USER_GROUPS]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.get_user_groups(payload);
      commit(SET_LOADING, false);
      return data;
    },
    [CREATE_ROLES_FILE]: async ({ commit }, form) => {
      commit(SET_LOADING, true);
      const formData = new FormData();
      console.log(form["excel"]);
      formData.append("excel", form["excel"]);
      const { data } = await $api.users.create_roles_file(formData);
      commit(SET_LOADING, false);
      return data;
    },
    [CREATE_USER_ROLE]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.create_user_role(payload);
      commit(SET_LOADING, false);
      return data;
    },
    [UPDATE_USER_ROLE]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.update_user_role(payload.id, payload);
      commit(SET_LOADING, false);
      return data;
    },
    [DELETE_USER]: async ({ commit }, user_id) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.delete_user(user_id);
      commit(SET_LOADING, false);
      return data;
    },
    [UPDATE_FROM_FILE]: async ({ commit }, payload) => {
      commit(SET_LOADING, true);
      const formData = new FormData();
      formData.append("excel", payload);
      const { data } = await $api.users.update_from_file(formData);
      commit(SET_LOADING, false);
      return data;
    },
    [CREATE_USERS]: async ({ commit }, { users, emailSubscriptionEnabled }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.create_users({
        users,
        email_subscription_enable_for_all: emailSubscriptionEnabled,
      });
      commit(SET_LOADING, false);
      return data;
    },
    [GET_ACTIVITY_LOGS]: async ({ commit }, search) => {
      commit(SET_LOADING, true);
      const params = {};
      if (search) {
        params.search = search;
      }
      const { data } = await $api.users.get_activity_logs(params);
      commit(SET_LOADING, false);
      return data.results;
    },
    [GET_DETAILED_ACTIVITY]: async ({ commit }, { email, date }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.get_detailed_activity({
        search: email,
        start_date: date,
        end_date: date,
      });
      commit(SET_LOADING, false);
      return data.results;
    },
    [GET_NOTIFICATIONS]: async ({ commit }, search) => {
      commit(SET_LOADING, true);
      const params = {};
      if (search) {
        params.search = search;
      }
      const { data } = await $api.users.get_notifications_list(params);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_NOTIFICATIONS_COUNT]:async({commit})=>{
      commit(SET_LOADING, true);
      const {data} = await $api.users.get_notifications_count();
      commit(SET_LOADING, false);
      return data
    },
    [GET_CERTIFICATES]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const {data} = await $api.certificates.get_user_certificates();
      commit(SET_LOADING, false);
      return data
    },
    [GET_SUBMISSION]: async ({ commit }, id) => {
      commit(SET_LOADING, true);
      // const params = {};
      // if (search) {
      //   params.search = search;
      // }
      const { data } = await $api.courses.get_submission_by_id(id);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_GENERAL_STATISTICS]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.get_general_statistics();
      commit(SET_LOADING, false);
      return data.results
    },
    [GET_LOGIN_ATTEMPTS]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.get_login_attempts();
      commit(SET_LOADING, false);
      return data.results;
    },
    [DOWNLOAD_GENERAL_STATISTICS]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.users.download_general_statistics();
      commit(SET_LOADING, false);
      return data;
    },
  };
  const mutations = {
    [SET_USERS]: (store, payload) => {
      store.users = payload;
    },
    [SET_GROUPS]: (store, payload) => {
      store.groups = payload;
    },
  };
  return {
    actions,
    state,
    mutations,
  };
};
export default (app) => {
  return createStore(app);
};
