<template>
  <form @submit.prevent="submit" class="w-full">
    <TheFormHeader header="change_password"/>
    <InputText v-model="password" :type="show_password?'text':'password'">
      <template #label>
        <InputLabel class="mt-10" >{{$t('create_new_password')}}</InputLabel>
      </template>
      <template #rightIcon >
        <EyeOffIcon
            class="h-5 w-5"
            :class="password!==''?'text-gray-500':'text-gray-300'"
            v-if="show_password"
            @click="show_password=false"
        />
        <EyeIcon
            class="h-5 w-5"
            :class="password!==''?'text-gray-500':'text-gray-300'"
            v-else
            @click="show_password=true"/>
      </template>
    </InputText>
    <InputText v-model="password_confirmation" :type="show_password?'text':'password'">
      <template #label>
        <InputLabel class="mt-3" >{{$t('retype_password')}}</InputLabel>
      </template>
      <template #rightIcon >
        <EyeOffIcon
            class="h-5 w-5"
            :class="password_confirmation!==''?'text-gray-500':'text-gray-300'"
            v-if="show_password"
            @click="show_password=false"
        />
        <EyeIcon
            class="h-5 w-5"
            :class="password_confirmation!==''?'text-gray-500':'text-gray-300'"
            v-else
            @click="show_password=true"/>
      </template>
    </InputText>
    <TheButton class="w-full mt-10" bg="primary">
      {{$t('change_password')}}
    </TheButton>
  </form>
</template>
<script>
  import TheSetNewPasswordFormMixin
    from "@/views/auth/ForgotPassword/components/TheSetNewPasswordForm/TheSetNewPasswordFormMixin";
  import InputLabel from "@/components/InputLabel";
  import InputText from "@/components/InputText";
  import TheFormHeader from "@/views/auth/Common/TheFormHeader/TheFormHeader";
  import TheButton from "@/components/Button/Button";
  import { EyeIcon, EyeOffIcon } from '@heroicons/vue/solid'

  export default {
    components: {TheButton, TheFormHeader, InputText, InputLabel, EyeIcon, EyeOffIcon},
    mixins:[TheSetNewPasswordFormMixin]
  }
</script>
