<template>
  <div class="relative w-full flex-1" ref="dropdown">
    <button
      @click="show = !show"
      class="w-full flex items-center justify-between dropdown__main"
    >
      <span class="mr-4 text-sm">{{ $t(gradeSelector) }}</span>

      <svg
        :class="!show ? 'arrow-down' : 'arrow-up'"
        style="margin-top: 2px"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="10"
        role="presentation"
        class="vs__open-indicator"
      >
        <path
          fill="rgba(60, 60, 60, 0.5)"
          d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
        ></path>
      </svg>
    </button>

    <div
      v-show="show"
      class="absolute right-0 shadow-xl w-full z-10 bg-white category-dropdown"
    >
      <div
        @click="
          () => {
            gradeSelector = category.option;
            show = false;
          }
        "
        v-for="(category, index) in categories"
        :key="index"
        class="block text-base dropdown__box"
      >
        <div class="text-sm text-gray-700 py-0.5 ml-3">
          {{ $t(category.option) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_COURSE_TASK_CATEGORIES } from "@/store/course-task-categories";

export const FinalGradeSelector = {
  All: "all_grades",
  Mid: "less_70",
  Low: "less_50",
};

export default {
  props: ["course_id"],
  data() {
    return {
      gradeSelector: FinalGradeSelector.All,
      hover: false,
      groups: [],
      group_id: null,
      categoryType: [],
      show: false,
      categories: [
        { id: 1, label: "Все", option: FinalGradeSelector.All },
        { id: 3, label: "Меньше 70", option: FinalGradeSelector.Mid },
        { id: 2, label: "Меньше 50", option: FinalGradeSelector.Low },
      ],
    };
  },
  methods: {
    handleDropdownClose() {
      this.show = false;
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.handleDropdownClose();
      }
    },
    async fetchCategories() {
      if (this.course_id) {
        const data = await this.$store.dispatch(GET_COURSE_TASK_CATEGORIES, {
          course: this.course_id,
        });
        this.categories = data.task_categories.map((ct) => ({
          ...ct,
          selected: false,
        }));
      }
    },
  },
  async mounted() {
    document.addEventListener("click", this.handleClickOutside, true);
    this.categories = this.categories.map((option) => ({
      ...option,
      value: option.id,
      text: option.label,
      name: option.label,
      selected: false,
    }));
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside, true);
  },
  watch: {
    gradeSelector(newVal) {
      this.$emit("updateGradeSelector", newVal);
    },
  },
};
</script>
<style scoped>
.dropdown__main {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 6px;
  padding: 7px 16px;
}

.arrow-up {
  transform: rotate(180deg);
  transform-origin: center;
}

.category-dropdown {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 6px 0 6px 32px;
}

.dropdown__box {
  cursor: pointer;
}

.dropdown__box:hover {
  background: #5897fb;
  color: #ffffff !important;
}

.dropdown__box:hover .text-gray-700 {
  color: #ffffff !important;
}
</style>
