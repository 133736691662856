import {mapFields} from "vuex-map-fields";
import {mapActions} from "vuex";
import {GET_COURSES} from "@/store/courses";

export default {
    computed:{
        ...mapFields(['courses.courses'])
    },
    methods:{
        ...mapActions([GET_COURSES])
    },
    created() {
        this.GET_COURSES({is_started:true,is_finished:false})
    }
}