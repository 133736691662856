<template>
<svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.998047" width="64" height="64" rx="16" fill="#7D69FC"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.5 19.498C29.3065 19.498 28.1619 19.9722 27.318 20.8161C26.4741 21.66 26 22.8046 26 23.998V25.498H21.5C20.3065 25.498 19.1619 25.9722 18.318 26.8161C17.4741 27.66 17 28.8046 17 29.998V41.998C17 43.1915 17.4741 44.3361 18.318 45.18C19.1619 46.0239 20.3065 46.498 21.5 46.498H42.5C43.6935 46.498 44.8381 46.0239 45.682 45.18C46.5259 44.3361 47 43.1915 47 41.998V29.998C47 28.8046 46.5259 27.66 45.682 26.8161C44.8381 25.9722 43.6935 25.498 42.5 25.498H38V23.998C38 22.8046 37.5259 21.66 36.682 20.8161C35.8381 19.9722 34.6935 19.498 33.5 19.498H30.5ZM35 25.498H29V23.998C29 23.6002 29.158 23.2187 29.4393 22.9374C29.7206 22.6561 30.1022 22.498 30.5 22.498H33.5C33.8978 22.498 34.2794 22.6561 34.5607 22.9374C34.842 23.2187 35 23.6002 35 23.998V25.498Z" fill="white"/>
</svg>


</template>

<script>
export default {

}
</script>

<style>

</style>