export default [
    {
        name:"AuthLayout",
        path:'/',
        component:async ()=>require('../layouts/AuthLayout'),
        meta:{
            isPublic:true
        },
        children:[
            {
                name:'LandingLogin',
                path:'',
                // redirect:()=>"/auth/login"
            },
            {
                name:'Login',
                path:'/auth/login',
                component:async ()=>require('../views/auth/Login/TheLoginPage'),
            },
            {
                name:'Registration',
                path:'register',
                redirect:()=>"/auth/login"
                // component:async()=>require('../views/auth/Registration/TheRegistrationPage'),
            },
            {
                name:'ForgotPassword',
                path:'forgot-password',
                // redirect:()=>"/auth/login"
                component:async()=>require('../views/auth/ForgotPassword/ForgotPassword'),
            }
        ]
    }

]