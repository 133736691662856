<template>
  <TheCorePageHeader v-if="course.id">
    <h1 class="text-2xl leading-8 font-semibold">
      {{course.name}}
    </h1>
    <router-link :to="{name:'CourseEdit', params:{id:course.id}}">
      <XIcon
        class="h-5 cursor-pointer"
      />
    </router-link>
    
  </TheCorePageHeader>
</template>
<script>
import TheCorePageHeader from "@/components/ThePageHeader/TheCorePageHeader";
import {XIcon} from "@heroicons/vue/outline"
import {mapFields} from "vuex-map-fields";

export default {
  components: {TheCorePageHeader,XIcon},
  computed:{
    ...mapFields([
      'courses.course',
    ]),
  },
}
</script>