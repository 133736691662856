<template>
  <AddToCartModal @close="closeCartModal" :modal-open="openModal" />

  <div>
    <div class="course">
      <div
        class="course__info grid grid-cols-2 bg-white"
        id="landing-course-form"
      >
        <CourseInfoVue :course="course" />
        <TheLandingForm :course-info="courseInfo" @open="openCartModal" />
      </div>
      <div class="bottom"></div>
      <div class="company">
        <div class="company__div-1">
          <div class="flex flex-col gap-6">
            <div
              class="font-size-40px font-bold text-white adaptive-company-title"
            >
              Компаниям нужны разработчики игр.
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Разработчики игр играют важную роль в создании увлекательных и
              высококачественных игровых продуктов, которые привлекают
              пользователей, обеспечивают прибыль компаний и способствуют
              технологическому прогрессу в индустрии.
            </div>
          </div>
          <div
            class="flex-shrink-0 flex items-center justify-center company__img-div"
          >
            <img
              src="../../../../assets/images/landing/html-tags.png"
              alt=""
              class="company__img"
            />
          </div>
        </div>
        <div class="company__div-2">
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Развлечение и прибыль
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Игровая индустрия является одной из самых прибыльных и динамично
              развивающихся отраслей.
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Привлечение пользователей
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Разработчики игр играют ключевую роль в создании увлекательных
              игровых механик, уникального геймплея, визуального оформления и
              звукового сопровождения, которые делают игру привлекательной для
              пользователей.
            </div>
          </div>
          <div class="flex flex-col gap-3">
            <div
              class="text-white font-bold font-size-28px adaptive-company-subtitle"
            >
              Технологический прогресс
            </div>
            <div class="text-lg font-normal text-gray-50 adaptive-company-text">
              Компании, занимающиеся разработкой игр, нуждаются в
              высококвалифицированных разработчиках, которые могут применять
              новейшие технологии и инструменты для создания революционных
              игровых продуктов.
            </div>
          </div>
        </div>
        <div class="flex gap-3">
          <div class="font-medium text-xl company__sign cursor-pointer">
            Записаться на программу
          </div>
          <div><PurpleArrowRightIcon :fill="'#A599F5'" /></div>
        </div>
      </div>
    </div>
    <div class="demanded">
      <div class="text-4xl font-bold adaptive-company-title">
        Разработчики востребованы на рынке
      </div>
      <div class="flex gap-6 adaptive-demanded">
        <div class="flex flex-col gap-10 px-8 py-10 demanded__box-1">
          <div>
            <div class="text-2xl font-semibold">Более 40 вакансий</div>
            <div class="font-normal text-lg text-gray-500">
              Доступно сейчас в разработке игр
            </div>
          </div>
          <div>
            <div>
              <div class="text-2xl font-semibold demanded__box-1__border">
                500 000 ₸
              </div>
            </div>

            <div class="font-normal text-lg text-gray-500">
              Средняя зарплата разработчика со стажем от 4 лет
            </div>
          </div>
          <div>
            <div class="font-normal text-lg text-gray-500">
              *данные взяты с сайтов по поиску работы в июне 2023 г.
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-10 px-8 py-10 demanded__box-2">
          <div class="text-2xl font-semibold">График роста вакансий</div>
          <div>
            <img src="../../../../assets/images/landing/gamedev-salary.png" />
          </div>
        </div>
      </div>
    </div>
    <CourseAdvantagesVue />
    <CourseFlexibilityVue />
    <CourseFitPeopleVue />

    <div class="trajectory">
      <div class="text-4xl font-bold adaptive-company-title">
        Учебная траектория
      </div>
      <div class="grid grid-cols-3 gap-6 adaptive-trajectory">
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>от 6 мес</div>
          <div>обучение</div>
        </div>
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>108+ часов</div>
          <div>обучения</div>
        </div>
        <div
          class="py-10 px-8 bg-gray-radius font-semibold text-2xl text-gray-900 flex flex-col items-center justify-center"
        >
          <div>5 вариантов</div>
          <div>специализаций</div>
        </div>
      </div>
      <!--      <div class="flex flex-col gap-4">-->
      <!--        <div class="font-medium text-lg">Длительность программы:</div>-->
      <!--        <div class="flex gap-2">-->
      <!--          <div-->
      <!--            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-1"-->
      <!--          >-->
      <!--            <div>12 мес</div>-->
      <!--            <div>|</div>-->
      <!--            <div>Разработчик</div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-2"-->
      <!--          >-->
      <!--            <div>24 мес</div>-->
      <!--            <div>|</div>-->
      <!--            <div>Digital-master</div>-->
      <!--          </div>-->
      <!--          <div-->
      <!--            class="py-0.5 px-2 font-medium text-sm flex gap-2 length__item-3"-->
      <!--          >-->
      <!--            <div>36 мес</div>-->
      <!--            <div>|</div>-->
      <!--            <div>Geek</div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="flex flex-col gap-120px">
        <!--        <img src="../../../../assets/images/landing/study-plan.png" alt="" />-->

        <div class="flex flex-col gap-8">
          <div class="font-bold before-start__title adaptive-company-title">
            До старта
          </div>
          <div class="grid grid-cols-3 gap-6 adaptive-grid">
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с платформой, графиком обучения
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Познакомим с вашим учебным расписанием, разберемся, где смотреть
                уроки, как общаться с преподавателем и куда сдавать домашние
                задания.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Знакомство с кураторами и преподавателями
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Представим ваших первых преподавателей, познакомим с кураторами,
                добавим в учебные чаты для общения с другими студентами.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Эффективное обучение
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Научим эффективно учиться, быстро и навсегда запоминать
                информацию любой сложности и объема.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Здоровье Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как сохранить здоровье и зрение, какие недуги у
                профессиональных разработчиков и как их избежать, научим
                обустраивать место для комфортной работы и дадим рекомендации по
                физ. тренировкам для ИТ-инженеров.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Ключевые навыки Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем, как эффективно управлять своим временем и энергией.
                Вы узнаете, как избежать выгорания и всегда оставаться в хорошем
                настроении, даже если что-то идет не по плану.
              </div>
            </div>
            <div class="flex flex-col gap-6 bg-gray-radius py-10 px-8">
              <div class="font-semibold text-2xl text-gray-900">
                Английский язык для Разработчика
              </div>
              <hr class="before-start__divider" />
              <div class="font-normal text-lg">
                Расскажем о роли английского языка в обучении. Дадим
                реккомендации, как эффективно подтянуть исходный уровень до
                требуемого. Вы получите языковую базу для старта обучения.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CourseMainBlockVue
      :new-learn-bullet="learnBullet"
      :new-course-step="courseStep"
    />
    <CourseFormVue />
    <CourseNextStageVue />
    <!--    <div class="authors">
          <div class="flex flex-col gap-4">
            <div class="font-size-40px text-gray-800 font-bold">
              Авторы программы
            </div>
            <div class="text-gray-500 text-lg">
              Реальные практики ИТ с многолетним опытом работы в лидирующих проектах
            </div>
          </div>
          <div class="grid grid-cols-2 gap-6">
            <div class="bg-gray-radius py-10 px-8 flex flex-col gap-10">
              <div class="flex gap-6">
                <div>
                  <img src="../../../../assets/images/landing/avatar.png" alt="" />
                </div>
                <div class="flex flex-col gap-2">
                  <div class="font-semibold text-2xl">Имя Фамилия</div>
                  <div class="font-normal text-lg text-gray-500">
                    Генеральный директор Alpha Education
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-4">
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Создатель торговой платформы АГРО24 и образовательных проектов:
                    A2.LIFE, ПОТОК24 и iTec Group.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Выпускник факультета Программного обеспечения в сфере
                    Информационных технологий Белорусского государственного
                    университета информатики и радиоэлектроники.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    В прошлом генеральный директор SaaS провайдера «Мегаплан»,
                    совладелец и управляющий партнер тренинговой компании «Бизнес
                    Молодость».
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-radius py-10 px-8 flex flex-col gap-10">
              <div class="flex gap-6">
                <div>
                  <img src="../../../../assets/images/landing/avatar.png" alt="" />
                </div>
                <div class="flex flex-col gap-2">
                  <div class="font-semibold text-2xl">Имя Фамилия</div>
                  <div class="font-normal text-lg text-gray-500">
                    Генеральный директор Alpha Education
                  </div>
                </div>
              </div>
              <div class="flex flex-col gap-4">
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Создатель торговой платформы АГРО24 и образовательных проектов:
                    A2.LIFE, ПОТОК24 и iTec Group.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    Выпускник факультета Программного обеспечения в сфере
                    Информационных технологий Белорусского государственного
                    университета информатики и радиоэлектроники.
                  </div>
                </div>
                <div class="flex gap-4">
                  <div class="authors__bullet"></div>
                  <div class="font-normal text-lg pt-0">
                    В прошлом генеральный директор SaaS провайдера «Мегаплан»,
                    совладелец и управляющий партнер тренинговой компании «Бизнес
                    Молодость».
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->

    <CourseTeachersVue
      :teachers="teachers"
      :scrollToForm="scrollToForm"
      :custom="true"
    />
    <CourseMaterialVue />
    <CourseReferralVue :col="true" />
    <GuaranteeSliderVue />
    <CourseFaq :new-faq="faq" />
  </div>
</template>

<script>
import PurpleArrowRightIcon from "../../../../assets/icons/PurpleArrowRightIcon.vue";
// import CourseFaq from "./CourseFaq.vue";
import GuaranteeSliderVue from "./GuaranteeSlider.vue";
import CourseReferralVue from "./CourseReferral.vue";
import CourseMaterialVue from "./CourseMaterial.vue";
import CourseTeachersVue from "./CourseTeachers.vue";
import CourseNextStageVue from "./CourseNextStage.vue";
import CourseFormVue from "./CourseForm.vue";
import CourseMainBlockVue from "./CourseMainBlock.vue";
import CourseFitPeopleVue from "./CourseFitPeople.vue";
import CourseFlexibilityVue from "./CourseFlexibility.vue";
import CourseAdvantagesVue from "./CourseAdvantages.vue";
import CourseInfoVue from "./CourseInfo.vue";
import CourseFaq from "@/views/core/Landing/Courses/CourseFaq.vue";
import axios from "axios";
import TheLandingForm from "@/views/core/Landing/NewLanding/sections/TheLandingForm.vue";
import AddToCartModal from "@/views/core/Landing/NewLanding/sections/AddToCartModal.vue";
import AllCoursesMixin from "@/views/core/Landing/Courses/AllCourses/AllCoursesMixin";
import { ShoppingCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/ShoppingCartMixin";
import { AddToCartMixin } from "@/views/core/Landing/Courses/ShoppingCart/AddToCartMixin";

export default {
  components: {
    AddToCartModal,
    TheLandingForm,
    CourseFaq,
    CourseInfoVue,
    CourseAdvantagesVue,
    CourseFlexibilityVue,
    CourseFitPeopleVue,
    CourseMainBlockVue,
    CourseFormVue,
    CourseNextStageVue,
    CourseTeachersVue,
    CourseMaterialVue,
    CourseReferralVue,
    GuaranteeSliderVue,
    // CourseFaq,
    PurpleArrowRightIcon,
  },
  props: {
    coursee: {
      type: Object,
    },
  },
  methods: {
    scrollToForm() {
      const formElement = document.getElementById("landing-course-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    },
    submitForm() {
      this.isCompanyName = this.companyName !== "";
      this.isPhone = this.phone !== "";
      this.isPersonName = this.personName !== "";

      if (this.email && !this.email.includes("@")) {
        this.isERR = true;
        return;
      } else {
        this.isERR = false;
      }
      if (this.isCompanyName && this.isPersonName && this.isPhone) {
        axios
          .post(
            "https://alphaedu.tech/api/v1/application_forms/like_to_study_application/",
            {
              name: this.personName,
              contact_phone: this.phone,
              email: this.email,
            }
          )
          .catch((err) => {
            console.log(err);
          });
        this.email = "";
        this.phone = "";
        this.personName = "";

        this.isForm = true;
        setTimeout(() => {
          this.isForm = false;
        }, 4000);
      }
    },
  },
  mixins: [AllCoursesMixin, ShoppingCartMixin, AddToCartMixin],
  async mounted() {
    await this.getAllCourses();
    this.courseInfo = this.landingCourses.length > 0 && this.landingCourses[0];
  },
  data() {
    return {
      courseInfo: {},
      searchText: "Разработка игр",
      personName: "",
      phone: "",
      email: "",
      isPersonName: true,
      isPhone: true,
      isForm: false,
      isERR: false,
      teachers: [
        {
          name: "Ердаулет Абсаттар",
          position:
            "Основатель и директор команды разработки в Alpha Education",
          courses: "Веб-разработка, Дизайн, Управление",
          profession:
            "Основатель и директор Alpha Education. Имеет огромный опыт в преподавании таких курсов как веб-разработка, графический дизайн, UI/UX дизайн, Блокчейн, Data Science, разработка игр, мобильная разработка. ",
          education: [
            "Бакалавр Компьютерных Наук (Nazarbayev University)",
            "Магистр Инженерного Менеджмента (Nazarbayev University) ",
          ],
          image: "yerdaulet.jpeg",
          programs: ["Дизайн"],
        },
      ],
      applicationForm: {
        name: "",
        email: "",
        telephone: "",
        isLegalEntity: false,
      },

      courseStep: [
        {
          title: "1-2 неделя",
          text: [
            "Введение  в разработку игр",
            "Знакомство  с игровым движком Unity",
            "Установка  Unity и редактора кода",
            "Введение  в язык программирования C#",
            "Создание  скриптов для управления объектами",
            "Реализация  базовых игровых механик",
          ],
          open: false,
        },
        {
          title: "3-4 неделя",
          text: [
            "Игровые объекты.",
            "Функции. Методы. Ресурсы. Спрайты.",
            "Создание анимаций для персонажей и объектов",
            "Использование аниматора для управления переходами между анимациями",
            "Создание пользовательского интерфейса (UI)",
            "Работа с кнопками, ползунками и другими элементами UI",
          ],
          open: false,
        },
        {
          title: "5-6 неделя",
          text: [
            " Использование физического движка Unity",
            "Реализация коллизий и взаимодействия объектов",
            "Применение сил, гравитации и физических материалов",
            "Эффекты частиц.",
          ],
          open: false,
        },
      ],
      learnBullet: [
        "Основам разработки игр на Unity",
        "3D моделированию на Blender",
        "Интеграции 3D моделей в игровые проекты",
        "Разработке собственных игровых проектов",
      ],
      course: {
        name: "Разработка игр",
        level: "Junior",
        program: "Разработка игр",
        description:
          "Курс предназначен для всех, кто хочет освоить разработку игр и научиться создавать качественные 3D модели. В течение курса вы изучите основы разработки игр, включая проектирование игровых механик, создание игровых сцен и программирование игровой логики. Кроме того, вы познакомитесь с Blender - мощным инструментом для 3D моделирования, текстурирования и анимации. В рамках курса вы освоите работу с программой Blender, ознакомитесь с ее интерфейсом и основными инструментами моделирования. Вы научитесь создавать 3D модели, настраивать материалы и текстуры, анимировать объекты и создавать интерактивные игровые сцены. Кроме того, вы изучите программирование игровой логики и научитесь создавать скрипты для управления объектами и поведением в игре.",
        skills: ["C#", "Unity", "Blender"],
        duration: "6 месяцев по 4,5 часа в неделю",
        start_date: "сентябрь 2023",
        price: "100 000 ₸",
      },
      faq: [
        {
          question: "Что такое Unity?",
          answer:
            "Unity - это мощный мультиплатформенный движок для разработки игр и интерактивных приложений. Он позволяет создавать игры для различных платформ, включая компьютеры, мобильные устройства и игровые консоли.",
          open: false,
        },
        {
          question: "Каковы основные компоненты Unity?",
          answer:
            "Основными компонентами Unity являются игровые объекты, компоненты, сцены, скрипты и ресурсы. Игровые объекты представляют элементы в игровом мире, а компоненты предоставляют функциональность для этих объектов. Сцены определяют композицию игровых объектов, а скрипты позволяют программировать поведение объектов. Ресурсы включают текстуры, модели, звуки и другие материалы, используемые в игре.",
          open: false,
        },
        {
          question: "Какие языки программирования поддерживает Unity?",
          answer:
            "Unity поддерживает несколько языков программирования, включая C#, JavaScript (также известный как UnityScript) и Boo. Наиболее распространенным языком программирования в Unity является C#.",
          open: false,
        },
        {
          question: "Как создать игровой объект в Unity?",
          answer:
            "Для создания игрового объекта в Unity нужно создать новый пустой объект или использовать готовый префаб (Prefab). Префабы - это предварительно настроенные объекты, которые могут быть многократно использованы в игре.",
          open: false,
        },
        {
          question: "Как использовать 3D модели, созданные в Blender, в Unity?",
          answer:
            "Чтобы использовать 3D модели, созданные в Blender, в Unity, нужно экспортировать их из Blender в формате, поддерживаемом Unity, таком как FBX или OBJ. Затем можно импортировать экспортированный файл в Unity и использовать его в игровых объектах.",
          open: false,
        },
        {
          question: "Как добавить анимацию к игровому объекту в Unity?",
          answer:
            "Чтобы добавить анимацию к игровому объекту в Unity, необходимо создать анимационный контроллер и определить состояния анимации. Затем можно создать анимации для каждого состояния и настроить их параметры и переходы между состояниями.",
          open: false,
        },
        {
          question: "Как работать с физикой в Unity?",
          answer:
            "Unity имеет встроенный физический движок, который позволяет моделировать реалистичную физику объектов в игре. Чтобы работать с физикой в Unity, нужно добавить компонент физического тела (Rigidbody) к игровому объекту и настроить его параметры, такие как масса, трение и гравитация.",
          open: false,
        },
        {
          question: "Как добавить звуки и музыку в игру в Unity?",
          answer:
            "Чтобы добавить звуки и музыку в игру в Unity, можно использовать аудиокомпоненты. Нужно импортировать аудиофайлы в Unity, создать источник звука, добавить аудиокомпонент к игровому объекту и настроить параметры звука, такие как громкость и позиция в пространстве.",
          open: false,
        },
        {
          question: "Как сделать взаимодействие с пользователем в игре?",
          answer:
            "Взаимодействие с пользователем в игре можно реализовать с помощью пользовательского ввода и интерфейса. Unity предоставляет возможности для обработки пользовательского ввода с помощью скриптов и настройки интерфейса пользователя (UI) с помощью элементов, таких как кнопки, текстовые поля и изображения.",
          open: false,
        },
        {
          question: "Как создать игровую сцену в Unity?",
          answer:
            "Чтобы создать игровую сцену в Unity, нужно создать новую сцену, добавить игровые объекты на сцену, настроить расположение и взаимодействие объектов, настроить освещение и камеру, а также добавить другие необходимые компоненты и настройки для создания желаемого игрового пространства.",
          open: false,
        },
      ],
    };
  },
};
</script>

<style scoped>
.course {
  background: #2b2e3b;
}
.course--rounded {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 24px;
}
.course__blocks {
  padding-left: 48px;
}
.course__info {
  background: #f4f5fa;
  padding: 40px 100px;
}
.course__level {
  background: #e1d3fe;
  border-radius: 24px;
}
.course__program {
  background: white;
  border-radius: 24px;
}
.course__technology {
  border-radius: 24px;
}
.course__technology-gradient {
  padding: 1px;
  border-radius: 24px;
  background: linear-gradient(
    90.14deg,
    #cc3fd3 55.91%,
    #8453e8 66.25%,
    #525cf3 78.46%
  );
}
.course__icon {
  background: #ffffff;
  border: 1px solid #c8aeff;
  border-radius: 40px;
}
.course__discount__time {
  background: #f4f5fa;
  border-radius: 12px;
  height: 80px;
}
.course__discount__text {
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}

.bottom {
  background: #f4f5fa;
  width: 100%;
  height: 120px;
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

.company {
  padding: 64px 100px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.company__div-1 {
  display: flex;
  gap: 90px;
}
.company__div-2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}
.company__sign {
  color: #a599f5;
}
.company__img {
  width: 140px;
  height: 140px;
}

.demanded {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.demanded__box-1 {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-1__border {
  display: inline-block;
  border-bottom: 2px solid #593ed3;
}
.demanded__box-1 {
  flex-basis: 50%;
  background-color: #f4f5fa;
  border-radius: 32px;
}
.demanded__box-2 {
  flex-basis: 50%;
  background: #eee6ff;
  border-radius: 32px;
}

.bg-gradient {
  background: linear-gradient(
    89.33deg,
    #eb8aff 7.2%,
    #b792ff 42.58%,
    #898fff 84.4%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.authors {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.authors__bullet {
  margin-top: 10px;
  display: inline-block;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #786ce6;
}

.font-size-40px {
  font-size: 40px;
}
.font-size-36px {
  font-size: 36px;
}
.font-size-28px {
  font-size: 28px;
}
.padding-100px {
  padding: 0px 100px;
}

.form__item {
  background: #ffffff;
  box-shadow: 0px 0px 20px -3px rgba(45, 38, 60, 0.03),
    0px 0px 10px -2px rgba(45, 38, 60, 0.03);
  border-radius: 32px;
}
.form__item input {
  border: 1px solid #9ca3af;
  border-radius: 16px;
}
.form__btn {
  width: 100%;
  background: #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__btn-white {
  width: 100%;
  background: #fff;
  color: #786ce6;
  border: 2px solid #786ce6;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 25px;
}
.form__approval-link {
  color: #786ce6;
}

.trajectory {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.bg-gray-radius {
  background-color: #f4f5fa;
  border-radius: 32px;
}
.length__item-1 {
  background: #fee0a7;
  border-radius: 24px;
}
.length__item-2 {
  background: #f0eafd;
  border-radius: 24px;
}
.length__item-3 {
  background: #cfd7ff;
  border-radius: 24px;
}
.before-start__title {
  font-size: 40px;
}
.before-start__divider {
  border: 2px solid #786ce6;
}
.gap-120px {
  gap: 120px;
}

.text-gray-100 {
  color: #f3f4f6;
}
.text-gray-50 {
  color: #f9fafb;
}

@media (max-width: 650px) {
  .course__info {
    padding: 32px 16px;
  }
  #landing-course-form {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .course__blocks {
    padding: 0;
  }
  .bottom {
    display: none;
  }
  .company {
    padding: 32px 16px;
  }
  .company__div-1 {
    padding: 0;
    width: 100%;
    flex-basis: 100%;
  }
  .company__div-2 {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }
  .demanded {
    padding: 48px 16px;
  }
  .adaptive-demanded {
    flex-direction: column;
  }
  .trajectory {
    padding: 64px 16px;
  }
  .adaptive-trajectory {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #d1d5db;
  }
  .adaptive-duration {
    flex-direction: column;
    align-items: center;
  }
  .length__item {
    width: fit-content;
  }
  .company__img-div {
    display: none;
  }
  .adaptive-grid {
    display: flex;
    flex-direction: column;
  }
  .adaptive-company-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .adaptive-company-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-company-subtitle {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
  }
}
</style>
