<script>
import { defineComponent } from "vue";
import GuestLectureItem from "@/views/core/Landing/Courses/GuestLectures/GuestLectureItem.vue";

export default defineComponent({
  name: "GuestLectures",
  components: { GuestLectureItem },
  data() {
    return {
      guests: [
        {
          name: "Асхат Молкенов",
          company: "Oraclus",
          position: "Руководитель команды",
          img: "askhat.jpeg",
          logo: "oraclus-white.png",
        },
        {
          name: "Абдиева Камила",
          company: "UIUC",
          position: "Research Assistant",
          img: "kamila.png",
          logo: "uiuc.png",
        },
        {
          name: "Себепова Алина",
          company: "ONE technologies",
          position: "UX/UI дизайнер",
          img: "alina.png",
          logo: "one_tech.png",
        },
        {
          name: "Нуржан Нурсеитов",
          company: 'АО НК "Қазақстан Темір Жолы"',
          position: "IT аудитор",
          img: "nurzhan.jpeg",
          logo: "ktzh.png",
        },
      ],
    };
  },
});
</script>

<template>
  <div class="guest-lectures__body">
    <h2>Гостевые лекторы</h2>
    <div class="guest-lectures__grid">
      <GuestLectureItem
        v-for="(guest, index) in guests"
        :key="index"
        :guest="guest"
      />
    </div>
  </div>
</template>

<style scoped>
.guest-lectures__body {
  padding: 64px 100px;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background: #06172b;
}
h2 {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
}
.guest-lectures__grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

@media (max-width: 650px) {
  .guest-lectures__body {
    padding: 40px 16px;
    gap: 24px;
  }
  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 30.8px;
    text-align: left;
  }
  .guest-lectures__grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}
</style>
