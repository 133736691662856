<template>
  <div class="bg-white rounded-xl px-6 py-4 inline-flex min-w-screen-md fixed bottom-20  shadow-lg" v-if="show">
      <div class="flex-shrink-0">
        <component
            :is="is_successfully_passed?'CheckCircleIcon':'XCircleIcon'"
            :class="is_successfully_passed?'text-green-500':'text-red-500'"
            class="h-7 mr-2"
        />
      </div>
      <div>
        <div>
          <span class="text-sm leading-5 font-medium text-gray-900">{{is_successfully_passed ?$t("quiz_passed"):$t("quiz_failed") }}</span>
          <p class="text-sm leading-5 font-normal text-gray-400">
            {{is_successfully_passed ? $t('quiz_result_with_pass',{result: result}):$t('quiz_result_with_fail',{result: result})}}
          </p>
        </div>
        <div class="inline-flex mt-4">
          <TheButton bg="primary" class="mr-3" @click="retry">{{$t('pass_again')}}</TheButton>
          <TheButton bg="outline" @click="finish">{{$t('finish')}}</TheButton>
        </div>
      </div>
  </div>
</template>
<script>
  import TheButton from "@/components/Button/Button";
  import {XCircleIcon,CheckCircleIcon} from "@heroicons/vue/solid";
  import {mapActions} from "vuex";
  import {FINISH_RESOURCE, RETRY_RESOURCE} from "@/store/resources";

  export default {
    components: {TheButton,XCircleIcon,CheckCircleIcon},
    props:{
        show:{
          type:Boolean,
          default:false
        },
        is_successfully_passed:{
          type:Boolean,
          default:false
        },
        result:{
            type:Number,
            default:0
        },
        resource_id:{
            type:Number,
            required:true
        },
        is_overdue:{
            type:Boolean,
            default:false
        },
      course_id:{
          type:Number,
          required:true
      }
    },
    methods:{
      ...mapActions([FINISH_RESOURCE,RETRY_RESOURCE]),
       async retry(){
          await this[RETRY_RESOURCE](this.resource_id)
          window.location = `/resources/${this.resource_id}/pass`
      },
      finish(){
        this.$router.push(`/courses/${this.course_id}/content`)
      }
    }
  }
</script>