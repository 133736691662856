<template>
  <div class="bg-white fixed bottom-0 w-full border-t border-gray-200 flex justify-between flex-wrap" >
    <div class="w-1/2 sm:max-w-fit">
      <div class="ml-auto sm:ml-0 w-48">
        <router-link :to="prevButtonUrl">
          <TheButton
              v-show="showPrevButton"
              bg="link"
              class="flex items-center absolute left-0 mt-1 ml-2">
            <ArrowLeftIcon class="h-4 mr-2"/>
            <p class="hidden md:block">{{$t('previous')}}</p>
          </TheButton>
        </router-link>
      </div>
    </div>
    <TheResourcesPageFooterPageChanger
        :active-page="activePage"
        :pagesAmount="pagesAmount"
        @setActivePageIndex="setActivePageIndex"
    />
    <div class="w-1/2 sm:max-w-fit">
      <div class="w-48">
        <router-link :to="nextButtonUrl"  v-if="showNextButton">
          <TheButton
              bg="link"
              class="flex items-center absolute right-0 mt-1 mr-2"
          >
            <p class="hidden md:block">{{$t('next')}}</p>
            <ArrowRightIcon class="h-4 ml-2"/>
          </TheButton>
        </router-link>
      </div>
    </div>
    <!-- <TheButton v-if="showSubmit" bg="primary" :disabled="this.resource.is_submitted">
      {{$t('submit')}}
      <PaperAirplaneIcon class="h-4 w-4 inline-block ml-1 rotate-90"/>
    </TheButton> -->
  </div>
</template>
<script>
import {mapFields} from "vuex-map-fields";
import TheButton from "@/components/Button/Button";
import {ArrowRightIcon,ArrowLeftIcon} from "@heroicons/vue/outline";
import TheResourcesPageFooterPageChanger from "@/components/ThePageFooter/TheResourcesPageFooterPageChanger";

export default {
  components: {TheResourcesPageFooterPageChanger, TheButton,ArrowRightIcon,ArrowLeftIcon},
  methods:{
    setActivePageIndex(page){
      return this.$router.replace({query:{page}})
    }
  },
  computed:{
    ...mapFields(['resources.paginator','resources.resource']),
    pagesAmount(){
      return this.paginator.max_page;
    },
    activePage(){
      return this.paginator.current_page
    },
    showNextButton(){
      return this.paginator.next && this.paginator.next.id
    },
    showPrevButton(){
      return this.paginator.prev && this.paginator.prev.id
    },
    nextButtonUrl(){
      const {next} = this.paginator
      if(!this.showNextButton){
        return ''
      }
      return this.$router.resolve({name:"ResourcesPassView",params:{id:next.id},query:{page:next.page}})
    },
    prevButtonUrl(){
      const {prev} = this.paginator
      if(!this.showPrevButton){
        return ''
      }
      return this.$router.resolve({name:"ResourcesPassView",params:{id:prev.id},query:{page:prev.page}})
    },
    visitedPages(){
      return this.resource.visited_pages
    },
    // showSubmit(){
    //   return this.resource.type=='quiz' || this.resource.type=='practice'
    // }
  }
}
</script>