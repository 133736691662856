<template>
  <div class="flex flex-col custom-card bg-white gap-1">
    <div class="font-semibold text-3xl stats-title-box text-white">
      <span class="adaptive-title">Alpha.Edu - Образовательная платформа</span>
      <span class="font-bold adaptive-gradient"> нового поколения. </span>
      <br />
      <span class="adaptive-text">
        Мы стремимся сделать знания доступными каждому, помогаем сформулировать
        ожидания от курсов и выбрать подходящую траекторию обучения, с нами вы
        сможете получить актуальные знания и освоить востребованную профессию из
        любой точки мира</span
      >
    </div>
    <div class="flex flex-col gap-2 mt-16"><LandingStatisticsNumbers /></div>
  </div>
</template>
<script>
import LandingStatisticsNumbers from "@/views/core/Landing/AboutUs/LandingStatisticsNumbers.vue";

export default {
  name: "TheLandingStats",
  components: { LandingStatisticsNumbers },
  data() {
    return {
      stats: [
        { title: "онлайн курсов", number: "105" },
        { title: "экспертов", number: "108" },
        { title: "выпускников", number: "25 000" },
        { title: "видеоуроков", number: "10 000" },
      ],
    };
  },
};
</script>
<style scoped>
.custom-card {
  padding: 100px;
}
.stats-title-box {
  border-radius: 40px;
  background: #06172b;
  padding: 40px;
}
.underscore {
  border-bottom: 1.5px solid #5b4ccc;
  margin-left: 64px;
}

.underscore:first-child {
  margin-left: 0px;
}

.basis-half {
  flex-basis: 50%;
}
.adaptive-gradient {
  background: linear-gradient(67deg, #cc3fd3 0%, #8453e8 45.83%, #525cf3 100%);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 15px;
}

.stats-title {
  color: #1f2937;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.adaptive-stats-number {
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
}

.text-violet {
  color: #6759ea;
}
.text-orange {
  color: #fba564;
}

.adaptive-stats-text {
  color: #6b7280;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.stats-box {
  border-radius: 32px;
  background: #f4f5fa;
  padding: 24px;
}

@media (max-width: 650px) {
  .stats-title-box {
    padding: 24px;
  }
  .adaptive-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    padding: 0;
  }
  .adaptive-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 19.8px;
  }
  .adaptive-gradient {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }

  .stats-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 110%;
  }

  .custom-card {
    padding: 56px 16px;
  }
  .adaptive-stats-number {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .adaptive-stats-text {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-stats-div {
    flex-direction: column;
  }
  .adaptive-stats-flex {
    flex-direction: column;
  }
}
</style>
