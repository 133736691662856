<template>
  <TheSelect
      :label="label"
      v-model="localValue"
      :options="options"
      :optionComponentName="optionComponentName"
      :multiple="multiple"
      :placeholder="placeholder"
  />
</template>
<script>
import TheSelect from "@/components/Selectors/TheSelect";
import TheSelectMixin from "@/components/Selectors/TheSelectMixin";
import { GET_COURSES_SELECT } from "@/store/courses";

export default {
  components: {TheSelect},
  mixins:[TheSelectMixin],
  created() {
    this.action=GET_COURSES_SELECT
  }
}
</script>
