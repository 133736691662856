<template>
  <div class="guarantee">
    <div class="flex flex-col gap-4 padding-100px">
      <div class="font-bold font-size-40px text-gray-800 guarantee-title">
        Гарантия трудоустройства
      </div>
      <div class="font-normal text-lg text-gray-500 guarantee-subtitle">
        Наши выпускники трудоустроились в компании:
      </div>
    </div>
    <div>
      <swiper
        :slides-per-view="screenWidth > 1360 ? 5 : screenWidth < 700 ? 2 : 3"
        :space-between="10"
        @swiper="onSwiper"
        @slideChange="onSlideChange"
        class="flex justify-center items-center h-full"
      >
        <swiper-slide>
          <img src="../../../../assets/images/landing/jusan.png" alt="jusan" />
        </swiper-slide>

        <swiper-slide>
          <img src="../../../../assets/images/landing/kaspi.png" alt="kaspi"
        /></swiper-slide>
        <swiper-slide>
          <img
            src="../../../../assets/images/landing/ed-crunch.png"
            alt="ed-crunch"
        /></swiper-slide>
        <swiper-slide>
          <img src="../../../../assets/images/landing/kassa.png" alt="kassa"
        /></swiper-slide>

        <swiper-slide>
          <img src="../../../../assets/images/landing/epam.png" alt="epam"
        /></swiper-slide>
        <swiper-slide>
          <img
            src="../../../../assets/images/landing/ed-crunch.png"
            alt="ed-crunch"
        /></swiper-slide>
        <!--        <swiper-slide>-->
        <!--          <img src="../../../../assets/images/landing/1fit.png" alt="1fit"-->
        <!--        /></swiper-slide>-->
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  created() {
    this.updateScreenSize();
    window.addEventListener("resize", this.updateScreenSize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  },
  methods: {
    onSwiper: (swiper) => {
      console.log(swiper);
    },
    onSlideChange: () => {},
    updateScreenSize() {
      this.screenWidth = window.innerWidth;
      this.screenHeight = window.innerHeight;
    },
  },
  data() {
    return {
      screenWidth: 0,
      screenHeight: 0,
    };
  },
};
</script>

<style scoped>
.guarantee {
  padding: 100px 0px 60px 0px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.padding-100px {
  padding: 0px 100px;
}
.swiper-slide {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.swiper-slide img {
  margin: 0 auto;
}

.font-size-40px {
  font-size: 40px;
}
.text-gray-50 {
  color: #f9fafb;
}

@media (max-width: 650px) {
  .guarantee {
    padding: 64px 16px;
  }
  .padding-100px {
    padding: 0px;
  }
  .guarantee-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
  .guarantee-subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}
</style>
