<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.6 12.798L6.45 10.648C6.26667 10.4647 6.03333 10.373 5.75 10.373C5.46667 10.373 5.23333 10.4647 5.05 10.648C4.86667 10.8314 4.775 11.0647 4.775 11.348C4.775 11.6314 4.86667 11.8647 5.05 12.048L7.9 14.898C8.1 15.098 8.33333 15.198 8.6 15.198C8.86667 15.198 9.1 15.098 9.3 14.898L14.95 9.24805C15.1333 9.06471 15.225 8.83138 15.225 8.54805C15.225 8.26471 15.1333 8.03138 14.95 7.84805C14.7667 7.66471 14.5333 7.57305 14.25 7.57305C13.9667 7.57305 13.7333 7.66471 13.55 7.84805L8.6 12.798ZM10 20.998C8.61667 20.998 7.31667 20.7354 6.1 20.21C4.88333 19.6847 3.825 18.9724 2.925 18.073C2.025 17.173 1.31267 16.1147 0.788 14.898C0.263333 13.6814 0.000666667 12.3814 0 10.998C0 9.61471 0.262667 8.31471 0.788 7.09805C1.31333 5.88138 2.02567 4.82305 2.925 3.92305C3.825 3.02305 4.88333 2.31071 6.1 1.78605C7.31667 1.26138 8.61667 0.998714 10 0.998047C11.3833 0.998047 12.6833 1.26071 13.9 1.78605C15.1167 2.31138 16.175 3.02371 17.075 3.92305C17.975 4.82305 18.6877 5.88138 19.213 7.09805C19.7383 8.31471 20.0007 9.61471 20 10.998C20 12.3814 19.7373 13.6814 19.212 14.898C18.6867 16.1147 17.9743 17.173 17.075 18.073C16.175 18.973 15.1167 19.6857 13.9 20.211C12.6833 20.7364 11.3833 20.9987 10 20.998Z"
      fill="#7A7DD7"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
