export default [
  {
    name: "StatisticsLayout",
    path: "/",
    component: async () => require("../layouts/CabinetLayout"),
    children: [
      {
        name: "StatisticsView",
        path: "/statistics",
        component: async () => require("@/views/stats/StatisticsView"),
      },
    ],
  },
];
