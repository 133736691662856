//todo logic for store work
import { SET_LOADING } from "@/store/core";
export const GET_RESOURCES = "GET_RESOURCES";
export const SET_FORMDATA = "SET_FORMDATA";

export const SET_RESOURCES = "SET_RESOURCES";
export const GET_RESOURCE = "GET_RESOURCE";
export const GET_RESOURCE_FOR_EDIT = "GET_RESOURCE_FOR_EDIT";
export const SET_RESOURCE = "SET_RESOURCE";
export const FINISH_RESOURCE = "FINISH_RESOURCE";
const SET_RESOURCE_PAGINATOR = "SET_RESOURCE_PAGINATOR";
export const SUBMIT_ANSWER = "SUBMIT_ANSWER";
export const SUBMIT_ANSWER_FILE = "SUBMIT_ANSWER_FILE";
export const DELETE_ANSWER_FILE = "DELETE_ANSWER_FILE";
export const RETRY_RESOURCE = "RETRY_RESOURCE";
// RESOURCE CRUD
export const SAVE_RESOURCE = "SAVE_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const CREATE_RESOURCE = "CREATE_RESOURCE";
export const DELETE_CONTENT = "DELETE_CONTENT";
export const UPDATE_CONTENT = "UPDATE_CONTENT";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const UPLOAD_ATTACHMENT = "UPLOAD_ATTACHMENT";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const ALTER_RESOURCE_TYPE = "ALTER_RESOURCE_TYPE";
// Chapter CRUD
export const SAVE_CHAPTERS = "SAVE_CHAPTERS";
export const DELETE_CHAPTER = "DELETE_CHAPTER";

//answer
export const DELETE_ANSWER = "DELETE_ANSWER";

//statistics
export const DOWNLOAD_STATISTICS = "DOWNLOAD_STATISTICS";
export const GET_STATISTICS = "GET_STATISTICS";
export const GET_NEXT_EXAMS = "GET_NEXT_EXAMS";

//landing page news
export const GET_LANDING_PAGE_NEWS = "GET_LANDING_PAGE_NEWS";
export const GET_LANDING_PAGE_NEWS_ITEM = "GET_LANDING_PAGE_NEWS_ITEM";
export const GET_LANDING_PAGE_REVIEWS = "GET_LANDING_PAGE_REVIEWS";
export const SEND_LANDING_FORM = "SEND_LANDING_FORM";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {
    resources: [],
    resource: {
      title: "Enter chapter title",
    },
    active_page_index: 0,
    main_modal_state: null,
    paginator: {
      cur_page: 1,
      next: {
        resource_id: 1,
        page: 1,
        is_enabled: true,
      },
      prev: {
        resource_id: 1,
        page: 3,
        is_enabled: true,
      },
    },
    form: {
      pages: [
        {
          contents: [],
        },
      ],
      is_timer_enabled: false,
      name: "",
      description: "",
      current_page: 1,
      duration: "",
    },
    show_start_confirmation_modal: false,
    show_attempt_overdue_modal: true,
    formData: {
      personName: "",
      phone: "",
      email: "",
      // Add other form fields as needed
    },
    // show_finish_quiz_confirmation_modal: true,
  };
  const actions = {
    [GET_RESOURCES]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      let { data } = await $api.resources.get_list_with_chapters(payload);
      data = data.map((resource) => {
        const resources = resource.resources.map((child) => ({
          ...child,
          route: `/resources/${child.id}/pass`,
        }));
        return { ...resource, resources };
      });
      commit(SET_RESOURCES, data);
      commit(SET_LOADING, false);
    },
    [RETRY_RESOURCE]: (store, resource_id) => {
      return $api.resources.create_attempt(resource_id);
    },
    [GET_RESOURCE]: async ({ commit }, { id, page }) => {
      if (!page) page = 1;
      commit(SET_LOADING, true);
      const { data } = await $api.resources.get_item({ id, page });
      const { result, ...rest } = data;
      commit(SET_RESOURCE, result);
      commit(SET_RESOURCE_PAGINATOR, rest);
      commit(SET_LOADING, false);
    },
    [GET_RESOURCE_FOR_EDIT]: async ({ commit }, { id }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.resources.get_item_for_edit({ id });
      const { result, ...rest } = data;
      commit(SET_RESOURCE, result);
      commit(SET_RESOURCE_PAGINATOR, rest);
      commit(SET_LOADING, false);
    },
    [FINISH_RESOURCE]: (store, id) => {
      return $api.resources.finish(id);
    },
    [SUBMIT_ANSWER]: (store, data) => {
      return $api.resources.answer(data);
    },
    [SUBMIT_ANSWER_FILE]: (store, { question_id, file }) => {
      const formData = new FormData();
      formData.append("question", question_id);
      formData.append("file", file);
      return $api.resources.submit_file(formData);
    },
    [DELETE_ATTACHMENT]: (store, form) => {
      if (
        form.type === "content" ||
        form.type === "text" ||
        form.type === "image" ||
        form.type === "audio" ||
        form.type === "presentation" ||
        form.type === "youtube_video" ||
        form.type === "video"
      ) {
        return $api.resources.delete_content_attachment(form.id);
      } else {
        return $api.resources.delete_question_attachment(form.id);
      }
    },
    [UPLOAD_ATTACHMENT]: (store, form) => {
      const formData = Object.keys(form).reduce((formData, key) => {
        formData.append(key, form[key]);
        return formData;
      }, new FormData());
      if (
        form.type === "content" ||
        form.type === "text" ||
        form.type === "image" ||
        form.type === "audio" ||
        form.type === "presentation" ||
        form.type === "youtube_video" ||
        form.type === "video"
      ) {
        return $api.resources.create_content_attachment(formData);
      } else {
        return $api.resources.create_question_attachment(formData);
      }
    },
    [DELETE_ANSWER_FILE]: (store, file_id) => {
      return $api.resources.delete_file(file_id);
    },
    [SAVE_CHAPTERS]: (store, form) => {
      return $api.resources.create_chapter(form);
    },
    [DELETE_CHAPTER]: (store, id) => {
      return $api.resources.delete_chapter(id);
    },
    // CRUD
    [DELETE_RESOURCE]: (store, resource_id) => {
      return $api.resources.delete_resource(resource_id);
    },
    [CREATE_RESOURCE]: (store, form) => {
      if (
        form.type === "content" ||
        form.type === "text" ||
        form.type === "image" ||
        form.type === "audio" ||
        form.type === "presentation" ||
        form.type === "youtube_video" ||
        form.type === "video"
      ) {
        return $api.resources.create_content(form);
      } else {
        return $api.resources.create_question(form);
      }
    },
    [DELETE_QUESTION]: (store, id) => {
      return $api.resources.delete_question(id);
    },
    [DELETE_ANSWER]: (store, id) => {
      return $api.resources.delete_answer(id);
    },
    [DELETE_CONTENT]: (store, id) => {
      return $api.resources.delete_content(id);
    },
    [UPDATE_CONTENT]: (store, { id, file, type }) => {
      const formData = new FormData();
      var type_db = type === "image" ? "image" : "content";
      if (file == null) {
        formData.append(type_db, new File([], ""));
      } else {
        formData.append(type_db, file);
      }
      // formData.append('media_url', 'a')
      // formData.append('content', content)
      return $api.resources.update_content(id, formData);
    },
    [SAVE_RESOURCE]: ({ commit }, form) => {
      commit(SET_LOADING, true);
      const formData = Object.keys(form).reduce((formData, key) => {
        // if(key === "pages" ){
        //     form[key].map(el=>{
        //         formData.append(`${key}[]`,el)
        //     })
        // }
        if (key === "pages") {
          form[key].map((el) => {
            el.contents.map((c) => {
              if ("id" in c) {
                c._key = c.id;
              }
              if (
                c.type === "image" ||
                c.type === "video" ||
                c.type === "audio" ||
                c.type === "presentation"
              ) {
                if (c.content instanceof File) {
                  formData.append(c._key, c.content);
                }
              }
              if (c.type === "audio_question") {
                formData.append(c._key, c.src);
              }
            });
            let temp = JSON.stringify(el);
            formData.append(`${key}[]`, temp);
          });
        } else if (key === "description" || key === "overview") {
          formData.append(key, JSON.stringify(form[key]));
        } else {
          formData.append(key, form[key]);
        }
        return formData;
      }, new FormData());
      if (form.id) {
        return $api.resources.update(form.id, formData);
      } else {
        return $api.resources.store(formData);
      }
    },
    [DOWNLOAD_STATISTICS]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      const { data } = await $api.resources.download_statistics(payload);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_STATISTICS]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      const { data } = await $api.resources.get_statistics(payload);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_NEXT_EXAMS]: async ({ commit }, link) => {
      commit(SET_LOADING, true);
      const { data } = await $api.resources.get_next_exams(link);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_LANDING_PAGE_NEWS]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.resources.get_landing_page_news();
      commit(SET_LOADING, false);
      return data;
    },
    [GET_LANDING_PAGE_NEWS_ITEM]: async ({ commit }, id) => {
      commit(SET_LOADING, true);
      const { data } = await $api.resources.get_landing_page_news_by_id(id);
      commit(SET_LOADING, false);
      return data;
    },
    [GET_LANDING_PAGE_REVIEWS]: async ({ commit }) => {
      commit(SET_LOADING, true);
      const { data } = await $api.resources.get_landing_page_student_reviews();
      commit(SET_LOADING, false);
      return data;
    },
    [SEND_LANDING_FORM]: async (_, data) => {
      return $api.resources.send_landing_page_request(data);
    },
    [ALTER_RESOURCE_TYPE]: async ({ commit }, { id, type }) => {
      commit(SET_LOADING, true);
      const { data } = $api.resources.alter_resource_type(id, { type });
      commit(SET_LOADING, false);

      return data;
    },
  };

  const mutations = {
    [SET_FORMDATA]: (state, payload) => {
      state.formData = payload;
    },
    [SET_RESOURCES]: (state, payload) => {
      state.resources = payload;
    },
    [SET_RESOURCE]: (state, payload) => {
      state.resource = payload;
    },
    [SET_RESOURCE_PAGINATOR]: (state, payload) => {
      state.paginator = payload;
    },
  };
  return {
    actions,
    state,
    mutations,
  };
};
export default (app) => createStore(app);
