<template>
  <div class="mb-7 mt-3">
    <div class="quill flex gap-2 mb-3">
      <span class="mt-3 font-semibold">{{ $t('your_answer') }}:</span>
      <span
        :class="revisionClass">{{ $t(answer.status) }}</span>
    </div>
    <TheCard class="bg-gray-50">
      {{answer.answer}}
    </TheCard>

    <AttachmentsBlock :attachments="answer.submissions"/>

    <div v-if="($router.currentRoute._value.name==='ResourcesPassView' && answer.criteria_points.length > 0)" class="quill flex gap-2 mb-3 flex-col">
      <span class="mt-3 font-semibold">{{ $t('your_points') }}:</span>
      <TheCard class="bg-gray-50">
        <div class="flex justify-between">
          <span>{{ $t('overall_point_grade') }}</span>
          <span><span class="text-lg leading-8 font-semibold" :class="{'text-red-600': calcCriteriaPoints < answer.threshold, 'text-green-600':calcCriteriaPoints >= answer.threshold}">{{calcCriteriaPoints}}</span>/{{calcCriteriaWeight}}</span>
        </div>
      </TheCard>
    </div>

    <AnswerComment v-if="answer.instructor_comment" :comment="answer.instructor_comment"/>
  </div>
</template>

<script>
import AttachmentsBlock from '../Attachments/AttachmentsBlock.vue'
import AnswerComment from './AnswerComment.vue'
import TheCard from "@/components/TheCard/TheCard";

export default {
  components: {
    TheCard,
    AttachmentsBlock,
    AnswerComment,
  },
  props: {
    answer: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    revisionClass() {
      return {
        'inline-block text-xs p-1 rounded-xl mt-3': true,
        'bg-red-200 text-red-600': this.answer.status == "being_finalized",
        'bg-green-200 text-green-600': this.answer.status == "finished",
        'bg-blue-200 text-blue-600': this.answer.status == "submitted",
      }
    },
    calcCriteriaPoints(){
      let result
      if(this.answer.criteria_points.length > 1){
        result = this.answer.criteria_points.reduce((a,b) => {
          if(b.point == ''){
            return parseInt(a) + 0
          } else
            return parseInt(a) + parseInt(b.point)
        }, 0)
      } else if (this.answer.criteria_points.length == 1){
        result = this.answer.criteria_points[0].point ? this.answer.criteria_points[0].point : 0
      } else {
        result = 0
      }
      return result
    },
    calcCriteriaWeight(){
      let result
      if(this.answer.criteria.length > 1){
        result = this.answer.criteria.reduce((a,b) => {return a + b.weight}, 0)
      } else {
        result = this.answer.criteria[0].weight
      }
      return result
    }
  }
}
</script>