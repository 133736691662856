<template>
  <TheCard
    class="flex justify-center flex-col relative adaptive-card"
    style="background: white"
  >
    <div class="adaptive-inner-card">
      <div
        class="flex flex-row items-center mb-6"
        style="justify-content: start"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="3"
          viewBox="0 0 22 3"
          fill="none"
        >
          <rect y="0.5" width="22" height="2" rx="1" fill="#786CE6" />
        </svg>
        <span class="platform-text">Платформа для обучения</span>
      </div>
      <p class="h2-custom text-gray-800">
        Образовательная платформа
        <span style="color: #786ce6">нового</span> поколения
      </p>
      <p class="para-text mt-4 adaptive-subtext">
        Мы соединили игровой подход, удобный доступ к материалу и огромное
        разнообразие вопросов в уникальной системе, чтобы сделать ваш
        образовательный процесс более эффективным и увлекательным
      </p>
      <div class="flex gap-8 mt-10 text-gray-800 adaptive-block">
        <div class="flex gap-2 align-center">
          <div><ComponentOneCourseIcon /></div>
          <div class="flex justify-start">
            <span class="adaptive-bullet" style="text-align: start"
              >Большой выбор курсов</span
            >
          </div>
        </div>
        <div class="flex gap-2 align-center">
          <div><ComponentOneHatIcon /></div>
          <div class="flex justify-start">
            <span class="adaptive-bullet" style="text-align: start"
              >Портфолио по итогам обучения</span
            >
          </div>
        </div>
      </div>
      <ComputerIcon class="absolute bottom-0 right-0" />
      <div class="absolute bottom-0 right-0"><ComponentOneSunIcon /></div>
    </div>
  </TheCard>
</template>

<script>
import TheCard from "@/components/TheCard/TheCard";
import ComponentOneHatIcon from "@/assets/icons/ComponentOneHatIcon.vue";
import ComponentOneCourseIcon from "@/assets/icons/ComponentOneCourseIcon.vue";
import ComponentOneSunIcon from "@/assets/icons/ComponentOneSunIcon.vue";
//   import ComputerIcon from "@/assets/icons/ComputerIcon.vue";
export default {
  name: "TheLandingComponentOne",
  components: {
    ComponentOneSunIcon,
    ComponentOneCourseIcon,
    ComponentOneHatIcon,
    TheCard,
  },
};
</script>

<style scoped>
.para-text {
  height: 96px;
  text-align: start;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  @apply text-gray-500;
}
.h2-custom {
  font-size: 48px;
  font-weight: 800;
  line-height: 55px;
  letter-spacing: 0em;
  text-align: left;
}

.platform-text {
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  text-transform: uppercase;
  color: #786ce6;
}
.adaptive-card {
  border-radius: 30px;
  padding: 78px 40px;
  border: 3px solid #786ce6;
  height: 670px;
  margin-bottom: 0;
}
.adaptive-inner-card {
  padding: 100px 64px;
  height: 670px;
  margin-bottom: 0;
  justify-content: center;
}

@media (max-width: 650px) {
  .h2-custom {
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 110%;
  }
  .platform-text {
    font-size: 12px;
  }
  .adaptive-subtext {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .adaptive-bullet {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    margin: 0;
  }
  .adaptive-block {
    flex-direction: column;
  }
  .adaptive-card {
    justify-items: start;
    align-items: start;
    padding: 0;
  }
  .adaptive-inner-card {
    padding: 40px 24px;
  }
}
</style>
