import { mapFields } from 'vuex-map-fields';
import {mapActions} from 'vuex';
import {FORGOT_PASSWORD_REQUEST} from "@/store/auth";
export default {
    methods:{
        ...mapActions({FORGOT_PASSWORD_REQUEST}),
        submit(){
            const {email} = this
            this.FORGOT_PASSWORD_REQUEST({email})
        }
    },
    computed:{
        ...mapFields(['auth.forgot_password_form.email']),
    }
}