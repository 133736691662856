<template>
    <div class="flex flex-col place-items-center">
        <div class="max-w-screen-md min-w-[40%]">
            <TheCard class="w-full">
            <div class="text-base leading-6 font-medium text-indigo-500  pb-4 break-word">
                {{practice.course_name}}
            </div>
            <div class="text-base leading-6 font-semibold text-gray-800 flex align-middle place-items-center pt-4 border-t border-gray-200 break-word">
                <BookOpenIcon class="h-6 mr-2"/>
                {{practice.resource_name}}
            </div>
            <div v-html="practice.question_text" class="text-base leading-5 font-normal text-gray-500 break-word " />
            <div class="text-base leading-6 font-semibold text-gray-800 flex align-middle place-items-center pt-4 pb-4 border-b border-gray-200 break-word">
                <BadgeCheckIcon class="h-6 mr-2"/>
                {{$t('threshold_out_of', {threshold: practice.threshold, max_points: calcCriteriaWeight})}}
            </div>
            </TheCard>
            <TheCard class="w-full" style="min-width:18rem;">
                <div class="flex flex-col ">
                    <p class=" text-base leading-6 font-semibold text-gray-800 flex align-middle place-items-center border-gray-200 break-word">{{$t('task_for_assessment')}}:</p>
                    <p class=" text-gray-800 flex align-start place-items-center border-gray-200 break-word">{{practice.comment}}</p>

                    <FileLink
                        v-for="file in practice.submissions"
                        :url="file.file"
                        :name="file.name"
                        :size="file.size"
                        :key="file.file"
                        class="mt-3"
                    />
                    </div>
            </TheCard>
            <CriterialGrading :practice="practice" :is_point="is_point" />
            <div class="flex justify-center">
                <TheButton bg="primary" @click="submitAssessment()">
                    {{$t('finish_assessment')}}
                </TheButton>
            </div>
        </div>
    </div>
</template>
<script>
import TheCard from '@/components/TheCard/TheCard.vue';
import { BookOpenIcon, BadgeCheckIcon } from '@heroicons/vue/outline';
import FileLink from "@/components/Files/FileLink";
import CriterialGrading from '@/components/CriterialGrading/CriterialGrading.vue';
import TheButton from "@/components/Button/Button";
import { mapActions } from 'vuex';
import {GET_SUBMISSION} from "@/store/users";
export default{
    components:{
        TheCard,
        FileLink,
        BookOpenIcon,
        BadgeCheckIcon,
        CriterialGrading,
        TheButton
    },
    data:()=>({
        practice:{
            course_name:'',
            resource_name:'',
            question_text:'',
            threshold:1,
            criteria:[{description:'test', weight:2, point:undefined}],
            criteria_points:[{description:'test', weight:2, point:undefined}],
            submissions:[]
        },
        is_point:false
    }),
    methods:{
        ...mapActions([GET_SUBMISSION]),
        async getSubmission(){
            const res = await this.GET_SUBMISSION(this.practice_id)
            this.practice = res[0]
            if((this.practice.criteria_points.length < this.practice.criteria.length)){
                for(var criterion of this.practice.criteria){
                    if(!this.practice.criteria_points.find(cp => cp.criterion == criterion.id)){
                        this.practice.criteria_points.push({
                            description: criterion.description,
                            weight: criterion.weight,
                            question: criterion.question,
                            criterion: criterion.id,
                            user_attempt: this.practice.id,
                            point: ''
                        })
                    }
                }
            }   
        },
        async submitAssessment(){
            if(this.is_point){
                this.practice.criteria_points.forEach(function(el){
                    el.point = parseInt(el.point)
                })
                console.log(this.practice.criteria_points)
                await this.$api.courses.update_submission(
                    this.practice_id, {criteria_points:this.practice.criteria_points})
                .then(res => {
                    console.log(res)
                    this.$router.push({name:'NotificationList'})
                })
                this.$router.push({name:'NotificationList'})
            }
        }
    },
    async mounted(){
        await this.getSubmission()
    },
    computed:{
        practice_id(){
            return this.$route.params.id
        },
        calcCriteriaWeight(){
            let result
            if(this.practice.criteria && this.practice.criteria.length > 1){
                result = this.practice.criteria.reduce((a,b) => {return parseInt(a) + parseInt(b.weight)}, 0)
            } else {
                result = this.practice.criteria[0].weight
            }
            return result
        }
    },
    watch:{
        'practice.criteria_points':{
        handler:function(){
            this.is_point = this.practice.criteria.length > 0 && (this.practice.criteria_points.every(p => p.point && p.point <= this.practice.criteria.find(c => c.id == p.criterion).weight))
            console.log(this.is_point)
        },
        deep:true
      }
    }
}
</script>
<style lang="postcss">
    .criterial-grading{
        padding: 1.5rem !important;
    }
</style>