<template>
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.666992 13.3314C0.666992 11.2897 1.1531 9.47166 2.12533 7.87721C3.09755 6.28277 4.16699 4.9411 5.33366 3.85221C6.50033 2.76332 7.56977 1.93227 8.54199 1.35905L10.0003 0.498047V4.34805C10.0003 5.06749 10.2434 5.63644 10.7295 6.05488C11.2156 6.47332 11.76 6.68216 12.3628 6.68138C12.6934 6.68138 13.0095 6.61332 13.3113 6.47721C13.6131 6.3411 13.89 6.11749 14.142 5.80638L14.667 5.16471C16.067 5.98138 17.1948 7.11421 18.0503 8.56321C18.9059 10.0122 19.3337 11.6016 19.3337 13.3314C19.3337 15.0425 18.9156 16.6031 18.0795 18.0132C17.2434 19.4233 16.1448 20.5363 14.7837 21.3522C15.1142 20.8855 15.372 20.3753 15.5572 19.8215C15.7423 19.2678 15.8344 18.6794 15.8337 18.0564C15.8337 17.2786 15.6878 16.5448 15.3962 15.8549C15.1045 15.165 14.6864 14.5474 14.142 14.0022L10.0003 9.94805L5.88783 14.0022C5.32394 14.5661 4.89616 15.1883 4.60449 15.8689C4.31283 16.5494 4.16699 17.2786 4.16699 18.0564C4.16699 18.6786 4.25955 19.267 4.44466 19.8215C4.62977 20.3761 4.88721 20.8863 5.21699 21.3522C3.85588 20.5355 2.75727 19.4225 1.92116 18.0132C1.08505 16.6039 0.666992 15.0433 0.666992 13.3314ZM10.0003 13.2147L12.4795 15.6355C12.81 15.9661 13.0628 16.3355 13.2378 16.7439C13.4128 17.1522 13.5003 17.5897 13.5003 18.0564C13.5003 19.0092 13.16 19.8208 12.4795 20.4912C11.7989 21.1617 10.9725 21.4973 10.0003 21.498C9.0281 21.498 8.20171 21.1628 7.52116 20.4924C6.8406 19.8219 6.50033 19.0099 6.50033 18.0564C6.50033 17.6092 6.58783 17.1767 6.76283 16.759C6.93783 16.3414 7.1906 15.9669 7.52116 15.6355L10.0003 13.2147Z"
      fill="#FC893B"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
