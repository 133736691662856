<template>
  <div class="checkbox-container">
    <div class="checkbox-title">{{ optionsName }}</div>
    <div v-for="(item, index) in options" :key="index" class="checkbox-div">
      <input
        type="checkbox"
        :value="item"
        v-model="checkedOptions"
        class="checkbox-input"
        @change="printEverything"
      />
      <label class="checkbox-label">{{ $t(item["label"]) }}</label>
      <!--      <label class="checkbox-label">{{ item["label"] }}</label>-->
    </div>
    <div
      class="flex checkbox-reset"
      @click="resetFilter"
      v-if="checkedOptions.length > 0 && checkedOptions !== 'all'"
    >
      <div class="checkbox-text-reset">Сбросить фильтр</div>
      <div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.2502 4.75754C15.1731 4.68029 15.0815 4.619 14.9807 4.57718C14.8799 4.53536 14.7718 4.51384 14.6627 4.51384C14.5535 4.51384 14.4455 4.53536 14.3447 4.57718C14.2439 4.619 14.1523 4.68029 14.0752 4.75754L10.0002 8.82421L5.92519 4.74921C5.84803 4.67206 5.75644 4.61086 5.65564 4.5691C5.55484 4.52735 5.4468 4.50586 5.33769 4.50586C5.22858 4.50586 5.12054 4.52735 5.01973 4.5691C4.91893 4.61086 4.82734 4.67206 4.75019 4.74921C4.67303 4.82636 4.61183 4.91795 4.57008 5.01876C4.52833 5.11956 4.50684 5.2276 4.50684 5.33671C4.50684 5.44582 4.52833 5.55386 4.57008 5.65466C4.61183 5.75547 4.67303 5.84706 4.75019 5.92421L8.82519 9.99921L4.75019 14.0742C4.67303 14.1514 4.61183 14.243 4.57008 14.3438C4.52833 14.4446 4.50684 14.5526 4.50684 14.6617C4.50684 14.7708 4.52833 14.8789 4.57008 14.9797C4.61183 15.0805 4.67303 15.1721 4.75019 15.2492C4.82734 15.3264 4.91893 15.3876 5.01973 15.4293C5.12054 15.4711 5.22858 15.4926 5.33769 15.4926C5.4468 15.4926 5.55484 15.4711 5.65564 15.4293C5.75644 15.3876 5.84803 15.3264 5.92519 15.2492L10.0002 11.1742L14.0752 15.2492C14.1523 15.3264 14.2439 15.3876 14.3447 15.4293C14.4455 15.4711 14.5536 15.4926 14.6627 15.4926C14.7718 15.4926 14.8798 15.4711 14.9806 15.4293C15.0814 15.3876 15.173 15.3264 15.2502 15.2492C15.3273 15.1721 15.3885 15.0805 15.4303 14.9797C15.472 14.8789 15.4935 14.7708 15.4935 14.6617C15.4935 14.5526 15.472 14.4446 15.4303 14.3438C15.3885 14.243 15.3273 14.1514 15.2502 14.0742L11.1752 9.99921L15.2502 5.92421C15.5669 5.60754 15.5669 5.07421 15.2502 4.75754Z"
            fill="#786CE6"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    optionsName: String,
    options: Array,
    checkedValues: Array,
  },
  data() {
    return {
      checkedOptions: this.checkedValues || [],
    };
  },
  watch: {
    checkedValues(newValues) {
      this.checkedOptions = newValues;
    },
  },
  methods: {
    printEverything() {
      this.$emit("checkbox-change", this.checkedOptions);
    },
    resetFilter() {
      this.checkedOptions = [];
      this.$emit("checkbox-change", this.checkedOptions);
    },
  },
};
</script>

<style scoped>
.checkbox-container {
  background: white;
  padding: 24px 20px;
  box-shadow: 0px 18px 20px rgba(153, 174, 194, 0.08);
  border-radius: 16px;
  width: 328px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.checkbox-title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
}

.checkbox-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #6b7280;
  vertical-align: middle;
}
.checkbox-input {
  margin-right: 8px;
  vertical-align: middle;
  cursor: pointer;
}
.checkbox-text-reset {
  color: #786ce6;
}
.checkbox-reset {
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

input[type="checkbox"] {
  appearance: none;
  background-color: white;
  border: 1px solid #cdd0d2;
  border-radius: 4px;
  width: 20px;
  height: 20px;
}

input[type="checkbox"]:checked {
  background-color: #6b7280; /* Change the background color of the checkbox when it is checked */
}

@media (max-width: 650px) {
  .checkbox-container {
    box-shadow: none;
  }
}
</style>
