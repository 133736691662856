<template>
    <div>
    <p class="text-2xl text-gray-900 font-semibold mb-4">
        {{$t('notifications')}} {{notifications.length}}
    </p>
    <div class="">
        <TheCard v-for="notification in notifications" :key="`notification_${notification.practice}`" :class="notification.is_complete ? 'not-allowed' : 'cursor-pointer' ">
            <router-link :class="notification.is_complete ? 'not-allowed' : 'cursor-pointer'" :is="notification.is_complete ? 'span' : 'router-link'" :to="notification.is_complete ? '' : destination(notification)">
                <div class="flex justify-between mb-3">
                    <span class="text-sm leading-5 font-normal text-gray-500">
                        {{formatDate(notification.notification.created_at)}}
                    </span>
                    <Badge bg="primary" v-if="notification.is_new">{{ $t('new') }}</Badge>
                    <Badge bg="danger" v-else-if="!notification.is_complete && notification.type!== 'assessment_finished'">{{ $t('not_checked') }}</Badge>
                </div>
                <p class="text-base leading-6 font-semibold text-gray-900">{{notification.notification.title}}</p>
                <!-- TODO:REFACTOR! -->
                <p class="text-base leading-5 font-normal text-gray-500">{{$t(notification.notification.type+'_not')}}</p> 
            </router-link>
        </TheCard>
    </div>
  </div>
</template>
<script>
import TheCard from "@/components/TheCard/TheCard.vue";
import Badge from "@/components/TheBadge/TheBadge.vue";
import { mapActions } from "vuex";
import moment from "moment";
import { GET_NOTIFICATIONS } from "@/store/users";
export default{
    components:{
        TheCard,
        Badge
    },
    methods:{
        ...mapActions([GET_NOTIFICATIONS]),
        formatDate(date){
            moment.locale(this.currentLocale)
            return moment(date).format('DD.MM.YYYY, HH:mm')
        },
        destination(notification){
            return notification.notification.type === "assessment_finished" ? `/resources/${notification.resource}/pass` : `/notifications/${notification.practice}`;
        }
    },
    data:()=>({
        notifications:[
        ]
    }),
    async mounted(){
        this.notifications = await this.GET_NOTIFICATIONS()
    },
}
</script>
<style lang="postcss">
.not-allowed {cursor: not-allowed;}
</style>