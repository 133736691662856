<template>
  <div  v-html="text" class="quill"/>
</template>
<script>
  export default {
    props:{
      text:{
        type:String,
        default:""
      }
    }
  }
</script>