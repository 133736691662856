<template>
  <InputText additionalClass="w-80" :placeholder="$t('courses_search')" @click="setSearchModalState(true)" >
    <template #leftIcon>
      <SearchIcon class="h-5 w-5 text-gray-400 font-normal" aria-hidden="true"/>
    </template>
  </InputText>
  <GlobalSearchModal/>
</template>
<script>
import InputText from "@/components/InputText";
import { SearchIcon } from "@heroicons/vue/solid"
import GlobalSearchInputMixin from "@/components/GlobalSearch/GlobalSearchInputMixin";
import GlobalSearchModal from "@/components/GlobalSearch/GlobalSearchModal";

export default {
  components: {GlobalSearchModal, InputText,SearchIcon},
  mixins:[GlobalSearchInputMixin],
  mounted() {
    this._keyListener = function(e) {
      if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();

        this.setSearchModalState(true);
      }
    };

    document.addEventListener('keydown', this._keyListener.bind(this));
  },
  beforeUnmount() {
    document.removeEventListener('keydown', this._keyListener);
  }
}
</script>
