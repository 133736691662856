import { SET_LOADING } from "@/store/core";
export const GET_COURSE_TASK_CATEGORIES = "GET_COURSE_TASK_CATEGORIES";
export const CREATE_COURSE_TASK_CATEGORIES = "CREATE_COURSE_TASK_CATEGORIES";
export const CREATE_BULK_COURSE_TASK_CATEGORIES =
  "CREATE_BULK_COURSE_TASK_CATEGORIES";
export const DELETE_COURSE_TASK_CATEGORY = "DELETE_COURSE_TASK_CATEGORY";

const createStore = (app) => {
  const { $api } = app.config.globalProperties;
  const state = {};
  const actions = {
    [GET_COURSE_TASK_CATEGORIES]: async ({ commit }, params) => {
      commit(SET_LOADING, true);
      const { data } =
        await $api.course_task_categories.get_course_task_categories(params);
      commit(SET_LOADING, false);
      return data;
    },
    [CREATE_COURSE_TASK_CATEGORIES]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      const { data } =
        await $api.course_task_categories.create_course_task_categories(
          payload
        );
      commit(SET_LOADING, false);
      return data;
    },
    [CREATE_BULK_COURSE_TASK_CATEGORIES]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      const { data } =
        await $api.course_task_categories.create_bulk_course_task_categories(
          payload
        );
      commit(SET_LOADING, false);
      return data;
    },
    [DELETE_COURSE_TASK_CATEGORY]: async ({ commit }, payload = {}) => {
      commit(SET_LOADING, true);
      await $api.course_task_categories.delete_course_task_category(payload);
      commit(SET_LOADING, false);
    },
  };
  const mutations = {};
  return {
    actions,
    state,
    mutations,
  };
};
export default (app) => createStore(app);
