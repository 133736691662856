export default [
  {
    name: "NewsLayout",
    path: "/news",
    component: async () => require("@/layouts/CabinetLayout"),
    children: [
      {
        name: "NewsList",
        path: "list",
        component: async () => require("@/views/core/News/NewsList.vue"),
      },
      {
        name: "NewsEdit",
        path: "edit/:id",
        component: async () => require("@/views/core/News/NewsEdit.vue"),
      },
      {
        name: "NewsForm",
        path: "form",
        component: async () => require("@/views/core/News/NewsForm.vue"),
      },
    ],
  },
  {
    name: "NewsContentLayout",
    path: "/news",
    component: async () => require("@/layouts/CourseAdminLayout"),
    children: [
      {
        name: "NewsContent",
        path: ":id/content",
        component: async () => require("@/views/core/News/NewsContent.vue"),
      },
      {
        name: "NewsPreview",
        path: ":id/preview",
        component: async () => require("@/views/core/News/NewsPreview.vue"),
      },
    ],
  },
];
