<script setup></script>

<template>
  <div class="opp-5">
    <div class="flex flex-col gap-4 justify-center">
      <div class="opp-1-title">Геймификация</div>
      <div class="opp-1-text">
        Все формы, поля, таблицы, секции и элементы проработаны по всем
        стандартам UI/UX дизайна, что позволяет быстро освоиться
      </div>
    </div>
    <div>
      <img src="../../../../assets/images/landing/opp-6.png" />
    </div>
  </div>
</template>

<style scoped>
.opp-5 {
  background: #e3f8dc;
  border-radius: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 80px;
  padding: 80px 40px;
}
.opp-1-title {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
}
.opp-1-text {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

@media (max-width: 650px) {
  .opp-6 {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
