<template>
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.599609 7.39961C0.599609 6.73687 1.13687 6.19961 1.79961 6.19961C2.46235 6.19961 2.99961 6.73687 2.99961 7.39961V12.1996C2.99961 12.8624 2.46235 13.3996 1.79961 13.3996C1.13687 13.3996 0.599609 12.8624 0.599609 12.1996V7.39961Z"
      fill="#7A7DD7"
    />
    <path
      d="M3.79961 7.26628V11.6108C3.79961 12.2168 4.14201 12.7708 4.68407 13.0418L4.72394 13.0618C5.16828 13.2839 5.65824 13.3996 6.15503 13.3996H10.4879C11.2506 13.3996 11.9073 12.8613 12.0569 12.1134L13.0169 7.3134C13.2149 6.32333 12.4576 5.39961 11.4479 5.39961H8.59961V2.19961C8.59961 1.31595 7.88327 0.599609 6.99961 0.599609C6.55778 0.599609 6.19961 0.957782 6.19961 1.39961V1.93294C6.19961 2.62533 5.97504 3.29904 5.55961 3.85294L4.43961 5.34628C4.02418 5.90018 3.79961 6.57389 3.79961 7.26628Z"
      fill="#7A7DD7"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style></style>
