<template>
  <TheModal :show="show_doc_modal" class="mt-10">
    <div class="flex flex-col md:flex-row p-6">
      <div class="w-full">
      <span class="flex justify-between items-center mb-6 p-4">
        <p class="text-xl text-gray-900 font-semibold">{{$t('program_name')}}</p>
        <TheButton bg="outline" @click="$emit('closeModal')">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </TheButton>

      </span>
        <div class="pl-4 pr-4">
          <div class="mb-5">
            <TheButton bg="outline" class="text-gray-500 h-full w-fit">
              <UploadIcon class="h-5 mr-2" />
              <a :href="computedUrl" target="_blank">
                {{$t('open_new_window')}}
              </a>
            </TheButton>
          </div>
          <embed :src="computedUrl" type="application/pdf" width="100%" height="700px">
        </div>

      </div>
    </div>
  </TheModal>
</template>

<script>
import TheModal from "@/components/TheModal/TheModal";
import TheButton from "@/components/Button/Button";
import { UploadIcon } from "@heroicons/vue/outline";
export default {
  name: "DocumentsModal",
  components: {TheButton, TheModal, UploadIcon},
  props:{
    show_doc_modal:{
      type:Boolean
    }
  },
  computed:{
    computedUrl() {
      return "/"+ this.$t("doc_name_login");
    }
  }
}
</script>

<style scoped>

</style>