import {mapActions} from "vuex";
import {GET_COURSES} from "@/store/courses";
import {mapFields} from "vuex-map-fields";

export default {
    computed:{
        ...mapFields(['courses.courses'])
    },
    methods:{
        ...mapActions([GET_COURSES])
    },
    async created() {
        await this.GET_COURSES({is_started:false})
    }
}