<template>
    <div  class="p-7 flex bg-white  rounded-xl shadow-lg flex-col-reverse lg:flex-row " style="min-height: 238px;min-width: fit-content">
    <img :src="logo" class=" hidden lg:flex rounded-md" style="width: 108px;height: 84px" />
    <div class="ml-3 flex-auto ">
      <CourseCardSpecializations :specializations="specializations"/>
      <p class="text-gray-900 text-xl font-semibold mt-2" >{{name}}</p>
      <p class=" mt-2 text-gray-900 leading-6 font-medium mb-4" >{{currentChapter}}:
      <span class="font-normal">
        {{currentLesson}}
      </span>
      </p>
      <div class="flex place-items-center mb-4">
        <ProgressBar :progress="progress" style="width: 200px; "/>
        <span class="ml-3 text-xs leading-5 font-normal">
          {{progress}}%
        </span>
      </div>
      <router-link v-if="lastResourceId && lastPage"  :to="{name:'ResourcesPassView',params:{id:lastResourceId},query:{page:lastPage}}" replace>
        <TheButton bg="primary" >
          {{$t('continue')}}
        </TheButton>
      </router-link>

    </div>
    <div style="width: 244px" class="mb-4">
      <div class="bg-gray-50 flex place-items-center p-4 rounded-xl	" style="height: 4rem">
        <span class="text-sm leading-5 font-medium text-gray-800 flex-1">
          {{$t('average_grade')}}
        </span>
        <span :class="`text-${gradeColor}-600  leading-5 font-medium `">
            {{average_grade}}
        </span>
        /100
      </div>
<!--      <div class="bg-gray-50 flex place-items-center p-4 rounded-xl	mt-2 text-sm leading-5 font-medium  text-gray-800 flex-col	">-->
<!--        <div class="flex place-items-center">-->
<!--          <FireIcon class="h-6" :class="`text-${days_in_a_row>1?'indigo-600':'gray-300'} `"/>-->
<!--          <span>{{days_in_a_row}} &nbsp;</span>-->
<!--          {{$tc('days_without_stop',days_in_a_row)}}-->
<!--        </div>-->
<!--        <div class="flex flex-row space-x-1 mt-4">-->
<!--          <div v-for="day in weekdays" :key="day.name" class="flex flex-col place-items-center">-->
<!--              <span>{{$t(day.name)}}</span>-->
<!--              <CheckCircleIcon class="h-7 text-indigo-600" v-if="day.checked"/>-->
<!--            <div v-else class="h-7 w-7 bg-white rounded-full border"/>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    </div>
</template>
<script>
import CourseCard from "@/components/Courses/Card/CourseCard";
import ProgressBar from "@/components/ProgressBar";
import TheButton from "@/components/Button/Button";

export default {
  components: {TheButton, ProgressBar},
  data(){
    return {
      weekdays:[
          {name:'mon',checked:true},
          {name:'tue',checked:true},
          {name:'wed',checked:false},
          {name:'thu',checked:false},
          {name:'fri',checked:false},
          {name:'sat',checked:false},
          {name:'sun',checked:false}
      ]
    }
  },
  props:{
    currentLesson:{
      type:String,
      default:'1.Методика преподавания'
    },
    days_in_a_row:{
      type:Number,
      default:0
    },
    currentChapter:{
      type:String,
      default:'Введение в курс'
    },
    average_grade:{
      type:Number,
      default:0
    },
    progress:{
      type:Number,
      default:0
    },
    lastResourceId:{
      type:Number,
      default:1
    },
    lastPage:{
      type:Number,
      default:1
    }
  },
  mixins:[CourseCard],
  computed:{
    gradeColor(){
      if(this.average_grade<60){
        return 'red'
      }
      return 'green'
    }
  }
}
</script>