<template>
  <div class="advantages">
    <div class="text-white text-4xl font-bold adaptive-block-title">
      Преимущества программы
    </div>
    <div class="advantages__block grid grid-cols-2 gap-6">
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <PersonIcon />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Плавный вход</div>
          <div class="text-lg">Подойдет даже новичкам в ИТ</div>
        </div>
      </div>
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <CrownIcon />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Перспективы карьерного роста</div>
          <div class="text-lg">Повысите свою ценность на рынке труда</div>
        </div>
      </div>
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <PurpleStarIcon />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Универсальные навыки</div>
          <div class="text-lg">
            Изучите не только базу, но и продвинутые инструменты
          </div>
        </div>
      </div>
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <CalendarIcon />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Быстрые результаты</div>
          <div class="text-lg">
            Возможность трудоустроиться уже через 9 месяцев после начала
            обучения
          </div>
        </div>
      </div>
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <StudentHatIcon />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Обучение на практике</div>
          <div class="text-lg">Решите более 50 практических задач</div>
        </div>
      </div>
      <div class="advantages__block__item flex gap-8">
        <div
          class="rounded-2xl bg-transparent icon__div flex items-center justify-center flex-shrink-0"
        >
          <div
            class="bg-white rounded-xl icon flex items-center justify-center"
          >
            <FireIcon />
          </div>
        </div>
        <div class="flex flex-col gap-1">
          <div class="font-bold text-2xl">Популярные специализаций</div>
          <div class="text-lg">Осознанно выберите свою</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PurpleStarIcon from "../../../../assets/icons/PurpleStarIcon.vue";
import PersonIcon from "../../../../assets/icons/PersonIcon.vue";
import CrownIcon from "../../../../assets/icons/CrownIcon.vue";
import CalendarIcon from "../../../../assets/icons/CalendarIcon.vue";
import StudentHatIcon from "../../../../assets/icons/StudentHatIcon.vue";
import FireIcon from "../../../../assets/icons/FireIcon.vue";

export default {
  components: {
    PurpleStarIcon,
    PersonIcon,
    CrownIcon,
    CalendarIcon,
    StudentHatIcon,
    FireIcon,
  },
};
</script>

<style scoped>
.advantages {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  background: linear-gradient(90deg, #593ed3 0%, #524ed9 45.83%, #3d64e6 100%);
  color: white;
}
.advantages__block__item {
  padding: 40px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 32px;
}
.icon__div {
  width: 64px;
  height: 64px;
}
.icon {
  width: 46px;
  height: 46px;
}

.bg-transparent {
  background: rgba(255, 255, 255, 0.2);
}

@media (max-width: 650px) {
  .advantages {
    padding: 40px 16px;
  }
  .advantages__block {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .adaptive-block-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}
</style>
