<template>
  <div class="material">
    <div class="text-4xl font-bold text-gray-800 adaptive-block-title">
      Игровой подход и удобный доступ к материалу
    </div>
    <div class="grid grid-cols-2 gap-8 adaptive-material">
      <div
        class="material__item-1 flex flex-col gap-4 justify-between overflow-hidden"
      >
        <div class="flex flex-col gap-3 px-10 py-10">
          <div class="font-semibold text-2xl text-gray-900">
            Платформа всегда под рукой
          </div>
          <div class="text-lg font-normal">
            Проходите занятия, тренируйте навыки, делайте домашние задания на
            телефоне или компьютере в любом месте и в любое время
          </div>
        </div>
        <div>
          <img
            src="../../../../assets/images/landing/opp-6.png"
            alt="Material"
            class="material__img"
          />
        </div>
      </div>
      <div class="grid grid-rows-2 gap-6 adaptive-material">
        <div class="material__item-2 px-10 py-10 flex flex-col gap-8">
          <div>
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="-0.00195312"
                width="64"
                height="64"
                rx="16"
                fill="#7D69FC"
                fill-opacity="0.2"
              />
              <rect
                x="8.33691"
                y="8.62109"
                width="46.5455"
                height="46.5455"
                rx="16"
                fill="white"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M31.1375 43.8721C31.2717 43.9423 31.4218 43.9779 31.5719 43.9767C31.722 43.9756 31.8709 43.9387 32.0064 43.8674L36.3311 41.5555C37.5574 40.9018 38.5178 40.1708 39.2671 39.3198C40.8962 37.4655 41.7857 35.099 41.7699 32.6588L41.7186 24.6095C41.7138 23.6824 41.1048 22.8551 40.2043 22.5544L32.1589 19.8551C31.6744 19.691 31.1436 19.6946 30.6677 19.8634L22.6528 22.659C21.7572 22.9716 21.1592 23.8048 21.1641 24.7331L21.2153 32.7765C21.2312 35.2202 22.1513 37.5761 23.8072 39.4113C24.5638 40.2504 25.5327 40.9707 26.7725 41.6138L31.1375 43.8721ZM29.9926 34.4119C30.1732 34.5854 30.4075 34.671 30.6418 34.6686C30.8761 34.6674 31.1092 34.5795 31.2874 34.4035L36.0135 29.7442C36.3687 29.3935 36.365 28.8301 36.0062 28.4843C35.6462 28.1384 35.0666 28.1408 34.7115 28.4914L30.6284 32.516L28.9566 30.909C28.5966 30.5631 28.0182 30.5667 27.6619 30.9173C27.3068 31.268 27.3104 31.8314 27.6704 32.1773L29.9926 34.4119Z"
                fill="#7D69FC"
              />
            </svg>
          </div>
          <div class="flex flex-col gap-3">
            <div class="font-semibold text-2xl text-gray-900">
              Много полезной практики
            </div>
            <div class="font-normal text-lg text-gray-500">
              Наблюдайте за своими успехами по всем текущим курсам. Вы сможете
              просматривать все оценки за задания, дедлайны и позицию и т.д.
            </div>
          </div>
        </div>
        <div class="material__item-2 px-10 py-10 flex flex-col gap-8">
          <div>
            <svg
              width="64"
              height="65"
              viewBox="0 0 64 65"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.498047"
                width="64"
                height="64"
                rx="16"
                fill="#7D69FC"
              />
              <path
                d="M26.434 29.854C26.434 29.098 26.6487 28.5147 27.078 28.104C27.5167 27.6934 28.0813 27.488 28.772 27.488C29.4627 27.488 30.0227 27.6934 30.452 28.104C30.8813 28.5147 31.096 29.098 31.096 29.854C31.096 30.61 30.8813 31.1934 30.452 31.604C30.0227 32.0147 29.4627 32.22 28.772 32.22C28.0813 32.22 27.5167 32.0147 27.078 31.604C26.6487 31.1934 26.434 30.61 26.434 29.854ZM35.94 27.642L30.55 37.498H28.24L33.63 27.642H35.94ZM28.758 28.888C28.31 28.888 28.086 29.21 28.086 29.854C28.086 30.4887 28.31 30.806 28.758 30.806C28.9727 30.806 29.1407 30.7267 29.262 30.568C29.3833 30.4094 29.444 30.1714 29.444 29.854C29.444 29.21 29.2153 28.888 28.758 28.888ZM33.098 35.286C33.098 34.53 33.3127 33.9467 33.742 33.536C34.1713 33.1254 34.7313 32.92 35.422 32.92C36.1127 32.92 36.6727 33.1254 37.102 33.536C37.5313 33.9467 37.746 34.53 37.746 35.286C37.746 36.042 37.5313 36.6254 37.102 37.036C36.6727 37.4467 36.1127 37.652 35.422 37.652C34.7313 37.652 34.1713 37.4467 33.742 37.036C33.3127 36.6254 33.098 36.042 33.098 35.286ZM35.408 34.32C35.1933 34.32 35.0253 34.3994 34.904 34.558C34.792 34.7167 34.736 34.9594 34.736 35.286C34.736 35.9207 34.96 36.238 35.408 36.238C35.6227 36.238 35.7907 36.1587 35.912 36C36.0333 35.8414 36.094 35.6034 36.094 35.286C36.094 34.9687 36.0333 34.7307 35.912 34.572C35.7907 34.404 35.6227 34.32 35.408 34.32Z"
                fill="white"
              />
              <path
                d="M32.1696 16.9727C40.8372 16.9727 47.8636 23.9991 47.8636 32.6667C47.8636 41.3343 40.8372 48.3607 32.1696 48.3607C23.502 48.3607 16.4756 41.3343 16.4756 32.6667C16.4756 23.9991 23.502 16.9727 32.1696 16.9727Z"
                stroke="white"
                stroke-opacity="0.3"
                stroke-width="2.87733"
                stroke-linecap="round"
              />
              <path
                d="M32.1709 16.9727C40.8385 16.9727 47.8649 23.9991 47.8649 32.6667"
                stroke="white"
                stroke-width="2.87733"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <div class="flex flex-col gap-3">
            <div class="font-semibold text-2xl text-gray-900">
              Прогресс обучения
            </div>
            <div class="font-normal text-lg text-gray-500">
              Наблюдайте за своими успехами по всем текущим курсам. Вы сможете
              просматривать все оценки за задания, дедлайны и позицию и т.д.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecurityIcon from "../../../../assets/icons/SecurityIcon.vue";
import ProcentIcon from "../../../../assets/icons/ProcentIcon.vue";

export default {
  SecurityIcon,
  ProcentIcon,
};
</script>

<style scoped>
.material {
  padding: 80px 100px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.material__item-1 {
  background: linear-gradient(261.61deg, #ece9ff 2.57%, #edebfc 97.17%);
  border-radius: 32px;
}
.material__item-2 {
  background: #f4f5fa;
  border-radius: 40px;
}
.material__img {
  transform: translate(20%, 10%);
}

.text-gray-50 {
  color: #f9fafb;
}

@media (max-width: 650px) {
  .material {
    padding: 48px 16px;
  }
  .adaptive-material {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .adaptive-next-stage2 {
    display: flex;
  }
  .adaptive-block-title {
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}
</style>
