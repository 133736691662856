<template>
  <TheModal :show="show_finish_resource_modal">
    <div class="flex p-4">
      <div class="flex mr-4">
        <div class="bg-red-100 p-2 rounded-full align-center h-fit">
          <ExclamationIcon class="h-5 w-5 text-red-600 "/>
        </div>
      </div>
      <div class="flex flex-col ">
        <h1 class="text-lg leading-6 font-medium text-gray-900 mb-2">
          {{$t('finish_resource_confirmation_title')}}
        </h1>
        <div class="w-100 text-sm leading-5 font-normal text-gray-500 mb-4">
          {{$t('finish_resource_confirmation_text')}}
        </div>
        <div class="flex place-content-end">
          <TheButton
              bg="outline" @click="setModalState(false)" class="mr-3">{{ $t('cancel') }}</TheButton>
          <TheButton bg="danger" @click="finishResource">{{ $t('finish') }}</TheButton>
        </div>
      </div>
    </div>

  </TheModal>
</template>

<script>
import TheButton from "@/components/Button/Button";
import TheModal from "@/components/TheModal/TheModal";
import {mapFields} from "vuex-map-fields";
import {ExclamationIcon} from '@heroicons/vue/outline'

export default {
  components: {
    TheModal,
    TheButton,
    ExclamationIcon
  },
  computed:{
    ...mapFields(['resources.show_finish_resource_modal',"resources.resource.course_id"])
  },
  methods:{
    finishResource(){
      this.setModalState(false)
      this.$router.replace({name:"CourseContent",params:{id:this.course_id}})
    },
    setModalState(val){
      this.show_finish_resource_modal = val;
    },

  }
}
</script>