<template>
  <svg
    width="28"
    height="29"
    viewBox="0 0 28 29"
    :fill="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.2839 9.9818C15.1758 10.0886 15.09 10.2157 15.0315 10.3559C14.9729 10.4961 14.9427 10.6465 14.9427 10.7985C14.9427 10.9504 14.9729 11.1008 15.0315 11.241C15.09 11.3812 15.1758 11.5084 15.2839 11.6151L17.5006 13.8318H6.76725C6.12559 13.8318 5.60059 14.3568 5.60059 14.9985C5.60059 15.6401 6.12559 16.1651 6.76725 16.1651H17.5006L15.2839 18.3818C15.1758 18.4886 15.09 18.6157 15.0315 18.7559C14.9729 18.8961 14.9427 19.0465 14.9427 19.1985C14.9427 19.3504 14.9729 19.5008 15.0315 19.641C15.09 19.7812 15.1758 19.9084 15.2839 20.0151C15.7389 20.4701 16.4623 20.4701 16.9173 20.0151L21.1056 15.8268C21.2137 15.7189 21.2995 15.5907 21.3581 15.4495C21.4166 15.3084 21.4468 15.1571 21.4468 15.0043C21.4468 14.8515 21.4166 14.7002 21.3581 14.5591C21.2995 14.4179 21.2137 14.2897 21.1056 14.1818L16.9173 9.9818C16.8105 9.87372 16.6833 9.78791 16.5431 9.72935C16.4029 9.67078 16.2525 9.64062 16.1006 9.64062C15.9487 9.64062 15.7982 9.67078 15.6581 9.72935C15.5179 9.78791 15.3907 9.87372 15.2839 9.9818Z"
      fill="fill"
    />
  </svg>
</template>

<script>
export default {
  props: ["fill"],
};
</script>

<style></style>
