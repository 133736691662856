export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";

const createStore = () => {
  const cartLocalStorageKey = "my_shopping_cart";
  // Retrieve cart items from local storage or initialize as an empty array
  const storedCartItems = (
    JSON.parse(localStorage.getItem(cartLocalStorageKey)) || []
  ).filter((item) => {
    // Check if the item is not an empty object
    return Object.keys(item).length > 0;
  });

  const state = {
    cartItems: storedCartItems,
    cartLocalStorageKey,
  };

  const actions = {
    [ADD_TO_CART]: ({ commit }, payload) => {
      commit(ADD_TO_CART, payload);
    },
    [REMOVE_FROM_CART]: ({ commit }, payload) => {
      commit(REMOVE_FROM_CART, payload);
    },
    [CLEAR_CART]: ({ commit }, payload) => {
      commit(CLEAR_CART, payload);
    },
  };

  const mutations = {
    [ADD_TO_CART]: (state, item) => {
      state.cartItems.push(item);
      localStorage.setItem(
        state.cartLocalStorageKey,
        JSON.stringify(state.cartItems)
      ); // Update local storage
    },
    [REMOVE_FROM_CART]: (state, item) => {
      state.cartItems = state.cartItems.filter(
        (course) => course.id !== item.id
      );
      localStorage.setItem(
        state.cartLocalStorageKey,
        JSON.stringify(state.cartItems)
      ); // Update local storage
    },
    [CLEAR_CART]: (state) => {
      state.cartItems = [];
      localStorage.removeItem(state.cartLocalStorageKey); // Clear local storage
    },
  };

  const getters = {
    cartItems: (state) => state.cartItems,
  };

  return { state, actions, mutations, getters };
};

export default (app) => createStore(app);
