<template>
  <Breadcrumbs :breadcrumbs="breadcrumbs" />
  <div>
    <div class="grid grid-cols-2">
      <span class="text-2xl text-gray-900 font-semibold mb-4 pt-2">
        {{ title }}
      </span>
      <!--      <div class="flex h-fit place-content-end gap-3">-->
      <!--        <InputText-->
      <!--          :placeholder="$t('search_by_name_email')"-->
      <!--          v-model="filters.search"-->
      <!--          class="w-fit"-->
      <!--        >-->
      <!--          <template #leftIcon>-->
      <!--            <SearchIcon class="h-5 text-gray-400" />-->
      <!--          </template>-->
      <!--        </InputText>-->
      <!--        <TheButton-->
      <!--          class="button-white"-->
      <!--          bg="outline"-->
      <!--          @click="downloadStatistics"-->
      <!--        >-->
      <!--          <DownloadIcon class="h-5 mr-2" />-->
      <!--          {{ $t("download_statistics") }}-->
      <!--        </TheButton>-->
      <!--      </div>-->
    </div>
    <TheCard class="overflow-x-hidden min-w-full flex flex-col gap-10">
      <TheCardHeader
        class="text-sm leading-5 font-medium mb-4 border-b border-gray-300 w-full flex flex-col gap-8"
        style="padding: 0px"
      >
        <div class="flex content-center">
          <span class="text-gray-400 text-sm leading-5 font-medium pt-2 mr-2"
            >{{ $t("filter") }}:
          </span>
          <StatisticsGroupSelector
            v-if="course.id"
            label="name"
            :course_id="course.id"
            v-model="filters.group_number"
            :placeholder="$t('select_group')"
            class="text-sm leading-5 font-medium border-none"
          />
        </div>
        <div class="flex">
          <div
            v-for="(st, index) in statistics"
            :key="index"
            class="w-fit py-4 px-8 place-content-center flex cursor-pointer"
            :class="
              filters.selected_type === st.key
                ? 'selected_content_types_list'
                : 'not_selected_content_types_list'
            "
            @click="filters.selected_type = st.key"
          >
            {{ $t(st.label) }}
          </div>
        </div>
      </TheCardHeader>
      <component
        :is="statistics.find((s) => s.key === filters.selected_type).component"
        :group="filters.group_number"
      />

      <!--      <TheTable class="overflow-x-auto overflow-y-hidden">-->
      <!--        <TheTableHead v-if="filters.selected_type === 'quizzes'">-->
      <!--          <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{-->
      <!--            $t("name")-->
      <!--          }}</TheTableHeadCell>-->
      <!--          <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{-->
      <!--            $t(`progress_by_${filters.selected_type}`)-->
      <!--          }}</TheTableHeadCell>-->
      <!--          <TheTableHeadCell-->
      <!--            v-for="exam in exams_selected"-->
      <!--            :key="exam.title"-->
      <!--            class="pl-4 pr-3 sm:pl-6"-->
      <!--            >{{ exam.chapter_name + " " + exam.title }}</TheTableHeadCell-->
      <!--          >-->
      <!--          <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{-->
      <!--            $t("average_grade")-->
      <!--          }}</TheTableHeadCell>-->
      <!--        </TheTableHead>-->
      <!--        <TheTableHead v-else>-->
      <!--          <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{-->
      <!--            $t("name")-->
      <!--          }}</TheTableHeadCell>-->
      <!--          <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{-->
      <!--            $t("status")-->
      <!--          }}</TheTableHeadCell>-->
      <!--          <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{-->
      <!--            $t(`progress_by_${filters.selected_type}`)-->
      <!--          }}</TheTableHeadCell>-->
      <!--          <template v-for="exam in exams_selected" :key="exam.title">-->
      <!--            <TheTableHeadCell-->
      <!--              class="pl-4 pr-3 sm:pl-6"-->
      <!--              style="max-width: 7rem"-->
      <!--              >{{ exam.chapter_name + " " + exam.title }}</TheTableHeadCell-->
      <!--            >-->
      <!--            <TheTableHeadCell class="pl-4 pr-3 sm:pl-6">{{-->
      <!--              $t("number_attempts")-->
      <!--            }}</TheTableHeadCell>-->
      <!--          </template>-->
      <!--        </TheTableHead>-->
      <!--        <TheTableBody v-if="filters.selected_type === 'quizzes'">-->
      <!--          <TheTableRow :key="`quizz_${exam.user.id}`" v-for="exam in exams">-->
      <!--            <TheTableRowCell class="pl-4 pr-3 sm:pl-6">-->
      <!--              <div class="flex items-center">-->
      <!--                <div class="flex-shrink-0">-->
      <!--                  <UserAvatar class="rounded-full" :url="exam.user.avatar" />-->
      <!--                </div>-->
      <!--                <div class="ml-4">-->
      <!--                  <div class="font-medium text-gray-900">-->
      <!--                    {{ exam.user.full_name }}-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </TheTableRowCell>-->
      <!--            <TheTableRowCell class="pl-4 pr-3 sm:pl-6">-->
      <!--              <div class="flex">-->
      <!--                <ProgressBar-->
      <!--                  :progress="exam.quiz_progress"-->
      <!--                  style="width: 150px"-->
      <!--                  class="mt-2 mr-2"-->
      <!--                />-->
      <!--                <span>{{ exam.quiz_progress }}%</span>-->
      <!--              </div>-->
      <!--            </TheTableRowCell>-->
      <!--            <TheTableRowCell-->
      <!--              class="pl-4 pr-3 sm:pl-6"-->
      <!--              :key="`quizz_${exam.user.id}_${quiz.id}`"-->
      <!--              v-for="quiz in exams_selected"-->
      <!--            >-->
      <!--              {{ getQuizScore(exam, quiz.id) }}-->
      <!--            </TheTableRowCell>-->
      <!--            <TheTableRowCell class="pl-4 pr-3 sm:pl-6">-->
      <!--              {{ exam.average_grade }}-->
      <!--            </TheTableRowCell>-->
      <!--          </TheTableRow>-->
      <!--        </TheTableBody>-->
      <!--        <TheTableBody v-else>-->
      <!--          <TheTableRow :key="`practice_${exam.user.id}`" v-for="exam in exams">-->
      <!--            <TheTableRowCell class="pl-4 pr-3 sm:pl-6">-->
      <!--              <div class="flex items-center">-->
      <!--                <div class="h-10 w-10 flex-shrink-0">-->
      <!--                  <UserAvatar-->
      <!--                    class="h-10 w-10 rounded-full flex-shrink-0"-->
      <!--                    :url="exam.user.avatar"-->
      <!--                  />-->
      <!--                </div>-->
      <!--                <div class="ml-4">-->
      <!--                  <div class="font-medium text-gray-900">-->
      <!--                    {{ exam.user.full_name }}-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </TheTableRowCell>-->
      <!--            <TheTableRowCell class="pl-4 pr-3 sm:pl-6">{{-->
      <!--              status-->
      <!--            }}</TheTableRowCell>-->
      <!--            <TheTableRowCell class="pl-4 pr-3 sm:pl-6">-->
      <!--              <div class="flex">-->
      <!--                <ProgressBar-->
      <!--                  :progress="exam.practice_progress"-->
      <!--                  style="width: 150px"-->
      <!--                  class="mt-2 mr-2"-->
      <!--                />-->
      <!--                <span>{{ exam.practice_progress }}%</span>-->
      <!--              </div>-->
      <!--            </TheTableRowCell>-->
      <!--            <template-->
      <!--              :key="`practice_${exam.user.id}_${practice.id}`"-->
      <!--              v-for="practice in exams_selected"-->
      <!--            >-->
      <!--              <TheTableRowCell class="pl-4 pr-3 sm:pl-6">-->
      <!--                {{-->
      <!--                  exam.practices.find((el) => el.resource_id == practice.id)-->
      <!--                    ? $t(-->
      <!--                        exam.practices.find(-->
      <!--                          (el) => el.resource_id == practice.id-->
      <!--                        ).status-->
      <!--                      )-->
      <!--                    : $t("not_submitted")-->
      <!--                }}-->
      <!--              </TheTableRowCell>-->
      <!--              <TheTableRowCell class="pl-4 pr-3 sm:pl-6">-->
      <!--                {{-->
      <!--                  exam.practices.find((el) => el.resource_id == practice.id)-->
      <!--                    ? exam.practices.find((el) => el.resource_id == practice.id)-->
      <!--                        .num_attempts-->
      <!--                    : ""-->
      <!--                }}-->
      <!--              </TheTableRowCell>-->
      <!--            </template>-->
      <!--          </TheTableRow>-->
      <!--        </TheTableBody>-->
      <!--      </TheTable>-->
    </TheCard>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
// import InputText from "@/components/InputText.vue";
// import { SearchIcon, DownloadIcon } from "@heroicons/vue/outline";
// import TheButton from "@/components/Button/Button";
import TheCard from "@/components/TheCard/TheCard.vue";
import TheCardHeader from "@/components/TheCard/TheCardHeader.vue";
import { GET_COURSE } from "@/store/courses";
import { mapActions } from "vuex";
// import TheTable from "@/components/TheTable/TheTable.vue";
// import TheTableHead from "@/components/TheTable/TheTableHead.vue";
// import TheTableHeadCell from "@/components/TheTable/TheTableHeadCell.vue";
// import TheTableBody from "@/components/TheTable/TheTableBody.vue";
// import TheTableRow from "@/components/TheTable/TheTableRow.vue";
// import TheTableRowCell from "@/components/TheTable/TheTableRowCell.vue";
// import UserAvatar from "@/components/UserAvatar.vue";
// import ProgressBar from "@/components/ProgressBar.vue";
import { getCurrentInstance } from "vue";
import {
  GET_RESOURCES,
  GET_STATISTICS,
  DOWNLOAD_STATISTICS,
  GET_NEXT_EXAMS,
} from "@/store/resources";
import Breadcrumbs from "@/components/Breadcrumbs/Breadcrumbs";
import StatisticsGroupSelector from "@/components/Selectors/StatisticsGroupSelector.vue";
import OverallStatistics from "@/views/courses/CourseEdit/Statistics/OverallStatistics.vue";
import PerformanceGrades from "@/views/courses/CourseEdit/Statistics/PerformanceGrades.vue";
import AttendanceStatistics from "@/views/courses/CourseEdit/Statistics/AttendanceStatistics.vue";
import EngagementStatistics from "@/views/courses/CourseEdit/Statistics/EngagementStatistics.vue";
import ProgressStatistics from "@/views/courses/CourseEdit/Statistics/ProgressStatistics.vue";
export default {
  components: {
    StatisticsGroupSelector,
    // InputText,
    // SearchIcon,
    // TheButton,
    // DownloadIcon,
    TheCard,
    TheCardHeader,
    // TheTable,
    // TheTableHead,
    // TheTableHeadCell,
    // TheTableBody,
    // TheTableRow,
    // TheTableRowCell,
    // UserAvatar,
    // ProgressBar,
    Breadcrumbs,
  },
  computed: {
    ...mapFields(["courses.course", "quizzes.quizzes", "resources.resources"]),
    title() {
      return this.$t("statistics") + " " + this.course.name;
    },
    exams_selected() {
      return this.filters.selected_type === "quizzes"
        ? this.quizzes
        : this.practices;
    },
  },
  data: () => {
    return {
      filters: {
        search: "",
        selected_type: "overall_statistics",
        group_number: null,
      },
      quizzes: [],
      practices: [],
      exams: [],
      nextLink: null,
      breadcrumbs: [],
      statistics: [
        {
          key: "overall_statistics",
          label: "overall_statistics",
          component: OverallStatistics,
        },
        {
          key: "performance_and_grades",
          label: "performance_and_grades",
          component: PerformanceGrades,
        },
        {
          key: "attendance",
          label: "attendance",
          component: AttendanceStatistics,
        },
        {
          key: "engagement",
          label: "engagement",
          component: EngagementStatistics,
        },
        { key: "progress", label: "progress", component: ProgressStatistics },
      ],
    };
  },
  methods: {
    ...mapActions([
      GET_COURSE,
      DOWNLOAD_STATISTICS,
      GET_RESOURCES,
      GET_STATISTICS,
      GET_NEXT_EXAMS,
    ]),
    async downloadStatistics() {
      let a = document.createElement("a");
      let baseUrl =
        this.instance.appContext.config.globalProperties.$config.backendUrl;
      let link = "";
      link =
        baseUrl +
        `/api/v1/resources/statistics/download?course=${
          this.course.id
        }&group_number=${
          this.filters.group_number ? this.filters.group_number : ""
        }&search=${this.filters.search}`;
      a.href = link;
      a.click();
    },
    async getExams() {
      const data = await this.GET_STATISTICS({ ...this.filters });
      this.exams = data.results;
      this.nextLink = data.next;
    },
    getQuizScore(exam, id) {
      const quiz = exam.quizzes.find((el) => el.resource_id == id);
      return quiz ? quiz.score : "";
    },
    async extendExams() {
      if (
        window.innerHeight + window.pageYOffset >=
        document.body.offsetHeight
      ) {
        if (this.nextLink) {
          const data = await this.GET_NEXT_EXAMS(
            this.nextLink.split("/resources")[1]
          );
          this.exams = this.exams.concat(data.results);
          this.nextLink = data.next;
        }
      }
    },
  },
  setup() {
    const instance = getCurrentInstance();
    return { instance };
  },
  watch: {
    "filters.search"() {
      this.getExams();
    },
    "filters.group_number"() {
      this.getExams();
    },
  },
  async mounted() {
    await this.GET_COURSE(this.$route.params.id);
    this.breadcrumbs = [
      {
        name: this.$t("courses"),
        path: "/courses/list",
      },
      {
        name: this.course.name,
        path: `/courses/${this.course.id}/edit`,
      },
      {
        name: this.$t("statistics"),
        path: `/courses/${this.course.id}/statistics`,
      },
    ];
    this.filters.course = this.course.id;
    await this.GET_RESOURCES({ course_id: this.course.id });
    this.resources.forEach((element) => {
      element.resources.forEach((r) => {
        if (r.type === "quiz") {
          this.quizzes.push(r);
        } else if (r.type === "practice") {
          this.practices.push(r);
        }
      });
    });
    this.getExams();
    window.onscroll = () => {
      this.extendExams();
    };
  },
};
</script>
<style scoped lang="postcss">
.selected_content_types_list {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #4f46e5;
  border-bottom: 2px solid #6366f1;
}
.not_selected_content_types_list {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #6b7280;
}
.divider {
  border-width: 1px;
  @apply w-0 border-gray-200;
}
.content-center {
  align-content: center;
}
</style>
