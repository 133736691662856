<template>
  <ContentBlockForm :content_name="$t('text')" :_key="_key" :attachments="attachments">
    <TheQuillEditorInput v-model="local_text"/>
  </ContentBlockForm>
</template>
<script>
  import ContentBlockForm from "@/views/resources/Form/components/ContentBlockForm";
  import TheQuillEditorInput from "@/components/TheQuillEditorInput";

  export default {
    components: {ContentBlockForm,TheQuillEditorInput},
    props:{
      attachments:[],
      content:{},
      _key:{
        type:String,
        required:true
      }
    },
    computed:{
      local_text:{
        get(){
          return this.content
        },
        set(event){
          this.$emit('contentChange',event)
        }
      }
    }
  }
</script>