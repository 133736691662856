<template>
  <div  aria-hidden="true">
    <div class="bg-gray-200  overflow-hidden" :class="computedClass" >
      <div class=" bg-indigo-600 " :class="is_rounded?'rounded-full':''" :style="`width:${progress}%;height: 6px`" />
    </div>
  </div>
</template>
<script>
  export default {
    props:{
      is_rounded:{
        type:Boolean,
        default:true
      },
      progress:{
        type:[Number,String]
      }
    },
    computed:{
      computedClass(){
        return `${this.is_rounded?'rounded-full':''}`
      }
    }
  }
</script>