import { mapActions } from "vuex";
import {
  CREATE_BULK_COURSE_TASK_CATEGORIES,
  DELETE_COURSE_TASK_CATEGORY,
  GET_COURSE_TASK_CATEGORIES,
} from "@/store/course-task-categories";

export default {
  data() {
    return {
      course_id: null,
      loading: "Сохранить",
    };
  },
  computed: {
    landingCourses() {
      return this.$store.getters.landing_courses || [];
    },
  },
  async mounted() {
    this.course_id = this.$route.params.id;
    await this.getTaskCategories();
  },
  methods: {
    ...mapActions([
      GET_COURSE_TASK_CATEGORIES,
      CREATE_BULK_COURSE_TASK_CATEGORIES,
      DELETE_COURSE_TASK_CATEGORY,
    ]),
    async getTaskCategories() {
      try {
        const data = await this[GET_COURSE_TASK_CATEGORIES]({
          course: this.course_id,
        });
        this.categories = data["task_categories"];
      } catch (error) {
        console.error("Error fetching task categories:", error);
      }
    },
    async createTaskCategories() {
      this.loading = "Сохраняем...";
      const data = [];
      this.categories.map((c) => {
        if (!c?.id) {
          data.push(c);
        }
      });

      await this[CREATE_BULK_COURSE_TASK_CATEGORIES]({
        data: data,
        course: this.course_id,
      });
      this.loading = "Сохранено";
      this.getTaskCategories();

      setTimeout(() => {
        this.loading = "Сохранить";
      }, 5000);
    },
    async deleteCategory() {
      const splicedData = this.categories.splice(this.toDeleteCategory, 1);
      if (splicedData[0]?.id) {
        await this[DELETE_COURSE_TASK_CATEGORY]({
          course: this.course_id,
          task: splicedData[0]?.id,
        });
      }
    },
  },
};
