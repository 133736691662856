<template>
  <div style="background-color: #1f2937; height: 373px" class="mt-4">
    <div class="relative flex-1 pt-16 px-8 mx-10" style="color: #d1d5db">
      <div class="flex flex-row">
        <div class="basis-1/5">
          <img src="../../../assets/images/landing/logo.png" />
        </div>
        <div class="basis-1/5 flex flex-col gap-4">
          <p class="uppercase text-sm">{{ $t("information") }}</p>
          <p class="text-sm">
            <router-link to="/info">{{ $t("details") }}</router-link>
          </p>
          <p class="text-sm">{{ $t("entry_into_registries") }}</p>
          <p class="text-sm">{{ $t("licenses") }}</p>
        </div>
        <div class="basis-1/5 flex flex-col gap-4">
          <p class="uppercase text-sm">EdCrunch</p>
          <p class="text-sm">{{ $t("courses") }}</p>
          <p class="text-sm">{{ $t("contacts_low") }}</p>
          <p class="text-sm">{{ $t("FAQ") }}</p>
        </div>
        <div class="basis-1/5 flex flex-col gap-4">
          <p class="uppercase text-sm">{{ $t("relevant_departments") }}</p>
          <p class="text-sm">Link</p>
          <p class="text-sm">Link</p>
          <p class="text-sm">Link</p>
        </div>
        <div class="basis-1/5 flex flex-col gap-4">
          <p class="uppercase text-sm">{{ $t("support") }}</p>
          <p class="text-sm">helpdesk@edca.kz</p>
        </div>
      </div>
      <hr class="mt-14" style="color: #9ca3af" />
      <div class="grid place-items-center text-sm mt-12" style="color: #9ca3af">
        {{ $t("edcrunch_footer") }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheLandingFooter",
};
</script>

<style scoped></style>
