const baseUrl = "/api/v1/attendance";
export default ($axios) => ({
  create_attendance_record(params) {
    return $axios.post(
      `${baseUrl}/group/${params.course}/date/${params.date}/create/`,
      { students: params.students }
    );
  },
  get_attendance_records(params) {
    return $axios.get(
      `${baseUrl}/group/${params.course}/date/${params.date}/read/`
    );
  },
  download_attendance_records(params) {
    return $axios({
      url: `${baseUrl}/group/${params.course}/date/${params.date}/download/`,
      method: "GET",
      responseType: "blob",
    });
  },
  get_student_attendance_records(params) {
    return $axios.get(
      `${baseUrl}/group/${params.course}/student/${params.user}/details/`
    );
  },
  get_course_attendance_groups(params) {
    return $axios.get(`${baseUrl}/course/${params.course}/options/`);
  },
  get_my_attendance(params) {
    return $axios.get(
      `${baseUrl}/group/${params.course}/my_attendance/?date_from=${params.date_from}&date_to=${params.date_to}`
    );
  },
  get_attendance_statistics(params) {
    return $axios.get(
      `${baseUrl}/group/${params.group_number}/statistics/?date_from=${params.date_from}&date_to=${params.date_to}`
    );
  },
});
