<template>
  <div class="relative w-full flex-1" ref="dropdown">
    <button
      @click="show = !show"
      class="w-full flex items-center justify-between dropdown__main"
    >
      <span class="mr-4 text-sm">{{
        categories.find((c) => c.selected)
          ? categories.find((c) => c.selected).name
          : "Все направления"
      }}</span>

      <svg
        :class="!show ? 'arrow-down' : 'arrow-up'"
        style="margin-top: 2px"
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="10"
        role="presentation"
        class="vs__open-indicator"
      >
        <path
          fill="rgba(60, 60, 60, 0.5)"
          d="M9.211364 7.59931l4.48338-4.867229c.407008-.441854.407008-1.158247 0-1.60046l-.73712-.80023c-.407008-.441854-1.066904-.441854-1.474243 0L7 5.198617 2.51662.33139c-.407008-.441853-1.066904-.441853-1.474243 0l-.737121.80023c-.407008.441854-.407008 1.158248 0 1.600461l4.48338 4.867228L7 10l2.211364-2.40069z"
        ></path>
      </svg>
    </button>

    <div
      v-if="useCheckbox"
      v-show="show"
      class="absolute right-0 shadow-xl w-full z-10 bg-white category-dropdown"
    >
      <div
        v-for="(category, index) in categories"
        :key="index"
        class="block text-base"
      >
        <div
          class="flex justify-between items-center"
          @click="category.selected = !category.selected"
        >
          <div class="text-sm text-gray-700 py-3">
            <span class="ml-2">
              {{ category.name }}
            </span>
          </div>
          <div class="w-5 h-5">
            <div class="table-checkbox flex items-center">
              <input-checkbox v-model="category.selected" />
            </div>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <TheButton
          class="category-dropdown__button"
          @click="handleDropdownClose"
          >Применить</TheButton
        >
      </div>
    </div>

    <div
      v-else
      v-show="show"
      class="absolute right-0 shadow-xl w-full z-10 bg-white category-dropdown"
    >
      <div
        v-for="(category, index) in categories"
        :key="index"
        class="block text-base"
      >
        <div
          class="flex justify-between items-center"
          @click="chooseCategory(index)"
        >
          <div class="text-sm text-gray-700 py-1 w-full no-checkbox__div">
            <span class="ml-2">
              {{ category.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import InputCheckbox from "@/components/InputCheckbox.vue";
import TheButton from "@/components/Button/Button.vue";
import { GET_COURSE_TASK_CATEGORIES } from "@/store/course-task-categories";

export default {
  components: { TheButton, InputCheckbox },
  props: {
    course_id: {
      type: Number,
    },
    useCheckbox: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      groups: [],
      group_id: null,
      categoryType: [],
      show: false,
      categories: [],
    };
  },
  methods: {
    chooseCategory(index) {
      this.categories = this.categories.map((c, ind) =>
        ind === index ? { ...c, selected: true } : { ...c, selected: false }
      );
      this.show = false;
    },

    handleDropdownClose() {
      this.show = false;
    },
    handleClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.handleDropdownClose();
      }
    },
    async fetchCategories() {
      if (this.course_id) {
        const data = await this.$store.dispatch(GET_COURSE_TASK_CATEGORIES, {
          course: this.course_id,
        });
        this.categories = data.task_categories.map((ct) => ({
          ...ct,
          selected: false,
        }));
      }
    },
  },
  async mounted() {
    document.addEventListener("click", this.handleClickOutside, true);
    await this.fetchCategories();
  },
  unmounted() {
    document.removeEventListener("click", this.handleClickOutside, true);
  },

  watch: {
    categories: {
      deep: true,
      handler() {
        this.$emit("updateSelectedCategories", this.categories);
      },
    },
    async course_id(newVal) {
      if (newVal !== null) {
        console.log("avv");
        await this.fetchCategories();
      }
    },
  },
};
</script>
<style scoped>
.dropdown__main {
  border: 1px solid rgba(60, 60, 60, 0.26);
  border-radius: 6px;
  padding: 7px 16px;
}

.arrow-up {
  transform: rotate(180deg);
  transform-origin: center;
}
.category-dropdown {
  margin-top: 4px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 8px 16px;
}
.category-dropdown__button {
  color: #4338ca;
  background: #e0e7ff;
  border-radius: 6px;
  padding: 8px 0;
  flex: 1 1 0;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  margin-top: 8px;
}

.no-checkbox__div:hover {
  color: white;
  background: #4f46e5;
}
</style>
