import { LIKE_TO_STUDY, WANT_TO_LEARN } from "@/store/landing-form";
import { mapActions } from "vuex";

export default {
  data() {
    return {};
  },

  methods: {
    ...mapActions([WANT_TO_LEARN, LIKE_TO_STUDY]),
    sendWantToLearnForm() {
      this[WANT_TO_LEARN]({
        name: this.personName,
        contact_phone: this.phone,
        email: this.email,
        organization_name: this.organization,
        program: this.programName,
      })
        .then(() => {
          this.isFormSubmitted = true;
          this.personName = "";
          this.email = "";
          this.phone = "";
          this.organization = "";
          this.programName = "";
        })
        .catch((error) => {
          console.error("There was an error submitting the form", error);
        });
    },
    sendLikeToStudyForm() {
      this[LIKE_TO_STUDY]({
        name: this.personName,
        contact_phone: this.phone,
        email: this.email,
      })
        .then(() => {
          this.email = "";
          this.phone = "";
          this.personName = "";

          this.isForm = true;
          setTimeout(() => {
            this.isForm = false;
          }, 4000);
        })
        .catch((error) => {
          console.error("There was an error submitting the form", error);
        });
    },
  },
};
