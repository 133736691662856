<script setup></script>

<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
  >
    <rect x="3" y="3" width="40" height="40" rx="12" fill="white" />
    <g clip-path="url(#clip0_604_4129)">
      <path
        d="M23 23L15.54 20.02L16 31L14 31L14.48 19.59L13 19L23 15L33 19L23 23ZM23 18C22.45 18 22 18.22 22 18.5C22 18.78 22.45 19 23 19C23.55 19 24 18.78 24 18.5C24 18.22 23.55 18 23 18ZM23 24L28.57 21.77C29.28 22.71 29.77 23.84 29.93 25.07C29.63 25.03 29.32 25 29 25C26.45 25 24.22 26.37 23 28.41C22.3807 27.3708 21.5022 26.5101 20.4505 25.9124C19.3987 25.3146 18.2098 25.0002 17 25C16.68 25 16.37 25.03 16.07 25.07C16.23 23.84 16.72 22.71 17.43 21.77L23 24Z"
        fill="#786CE6"
      />
    </g>
    <rect
      x="1.5"
      y="1.5"
      width="43"
      height="43"
      rx="13.5"
      stroke="#786CE6"
      stroke-opacity="0.2"
      stroke-width="3"
    />
    <defs>
      <clipPath id="clip0_604_4129">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(13 13)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped></style>
