<template>
  <MultipleChoiceQuestion
      :content="content"
      :available_answers="availableAnswersFormatted"
      :hasImages="true"
      v-if="!multiple_answers_available"
  />
  <MultipleAnswersQuestion
      :content="content"
      :available_answers="availableAnswersFormatted"
      v-else
  />
</template>

<script>
  import MultipleAnswersQuestion from "@/components/Resources/ResourceBlock/Questions/MultipleAnswersQuestion";
  import MultipleChoiceQuestion from "@/components/Resources/ResourceBlock/Questions/MultipleChoiceQuestion";
  export default {
    props:{
      multiple_answers_available:{
        type:Boolean,
        default:false
      },
      available_answers: {
        type:Array,
        default:()=>[]
      },
      content:{},
    },
    components:{
      MultipleAnswersQuestion,
      MultipleChoiceQuestion
    },
    methods:{
      formatFileToUrl(file){
        if(!file){
          return null
        }
        if (typeof file === 'string' || file instanceof String){
          return file
        }
        if(file){
          return URL.createObjectURL(file)
        }
        return null
      }
    },
    computed:{
      availableAnswersFormatted(){
        return this.available_answers.map(el=>({...el,image:this.formatFileToUrl(el.image)}))
      }
    }
  }
</script>