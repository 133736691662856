<template>
  <div class="bg-white rounded-lg w-full overflow-hidden h-127">
    <img :src="localContent" class="w-full" />
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      required: true,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    localContent() {
      if (typeof this.image === "string" || this.image instanceof String) {
        return this.image;
      } else {
        return URL.createObjectURL(this.image);
      }
    },
  },
};
</script>
