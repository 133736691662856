const baseUrl = "/api/v1/certificates";
export default ($axios) => ({
  get_user_certificates() {
    return $axios.get(`${baseUrl}/`);
  },
  get_certificate_types() {
    return $axios.get(`${baseUrl}/admin/list_types`);
  },
  create_bulk_certificate(params) {
    return $axios.post(`${baseUrl}/admin/bulk_create/`, params);
  },
  get_my_certificates() {
    return $axios.get(`${baseUrl}/my_certificates/`);
  },
  check_certificate(params) {
    return $axios.get(`${baseUrl}/search_certificate/`, { params });
  },
});
