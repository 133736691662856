<script>
import TheWideModal from "@/components/TheModal/TheWideModal.vue";
export default {
  components: { TheWideModal },
  props: ["showVideoModal", "closeVideoModal", "urlVideo"],
};
</script>

<template>
  <TheWideModal
    :show="showVideoModal"
    @close="closeVideoModal"
    class-name="modal"
  >
    <div class="video-modal flex flex-col gap-2 justify-center relative">
      <div
        class="cursor-pointer close-modal absolute -top-1.5 right-0"
        @click="closeVideoModal"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30.4999 9.51704C30.3457 9.36253 30.1625 9.23995 29.9609 9.15632C29.7593 9.07268 29.5432 9.02963 29.3249 9.02963C29.1066 9.02963 28.8905 9.07268 28.6888 9.15632C28.4872 9.23995 28.3041 9.36253 28.1499 9.51704L19.9999 17.6504L11.8499 9.50037C11.6956 9.34607 11.5124 9.22367 11.3108 9.14016C11.1092 9.05665 10.8931 9.01367 10.6749 9.01367C10.4567 9.01367 10.2406 9.05665 10.039 9.14016C9.83737 9.22367 9.65419 9.34607 9.49988 9.50037C9.34558 9.65468 9.22318 9.83786 9.13967 10.0395C9.05616 10.2411 9.01318 10.4572 9.01318 10.6754C9.01318 10.8936 9.05616 11.1097 9.13967 11.3113C9.22318 11.5129 9.34558 11.6961 9.49988 11.8504L17.6499 20.0004L9.49988 28.1504C9.34558 28.3047 9.22318 28.4879 9.13967 28.6895C9.05616 28.8911 9.01318 29.1072 9.01318 29.3254C9.01318 29.5436 9.05616 29.7597 9.13967 29.9613C9.22318 30.1629 9.34558 30.3461 9.49988 30.5004C9.65419 30.6547 9.83737 30.7771 10.039 30.8606C10.2406 30.9441 10.4567 30.9871 10.6749 30.9871C10.8931 30.9871 11.1092 30.9441 11.3108 30.8606C11.5124 30.7771 11.6956 30.6547 11.8499 30.5004L19.9999 22.3504L28.1499 30.5004C28.3042 30.6547 28.4874 30.7771 28.689 30.8606C28.8906 30.9441 29.1067 30.9871 29.3249 30.9871C29.5431 30.9871 29.7592 30.9441 29.9608 30.8606C30.1624 30.7771 30.3456 30.6547 30.4999 30.5004C30.6542 30.3461 30.7766 30.1629 30.8601 29.9613C30.9436 29.7597 30.9866 29.5436 30.9866 29.3254C30.9866 29.1072 30.9436 28.8911 30.8601 28.6895C30.7766 28.4879 30.6542 28.3047 30.4999 28.1504L22.3499 20.0004L30.4999 11.8504C31.1332 11.217 31.1332 10.1504 30.4999 9.51704Z"
            fill="white"
          />
        </svg>
      </div>
      <div class="video-block">
        <iframe
          :src="urlVideo"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </TheWideModal>
</template>

<style scoped>
.video-modal {
  display: flex;
  justify-content: center;
}
.close-modal {
  display: flex;
  justify-content: end;
  align-items: end;
}
.video-block {
  position: relative;
  height: 500px;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.video-block iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.modal {
  width: 90vw;
  height: 90vh;
}
</style>
