<template>
  <!-- <div class="flex flex-col goal-header">
    <p class="text-gray-900 text-5xl font-extrabold">
      Хотите создать свою онлайн-школу?
Запустите свои курсы у нас <span style="color:#786CE6">уже сейчас</span>
    </p>
    <p class="mt-5 text-gray-500 text-2xl">Регистрируйтесь и пробуйте весь функционал в действии</p>
  </div>
  <div class="flex mx-18 gap-20 mt-20" style="margin-left: 100px; margin-right:100px">
    <div class="w-3/5 h-12 flex items-center justify-center">
      <div>
        <h1 class="text-2xl font-semibold">Легкая и быстрая сборка курсов</h1>
        <p class="text-lg text-gray-500">Вы сможете создавать уроки, тесты, домашние задания и вебинары в несколько кликов с помощью удобных инструментов</p>
        <TheButton class="mt-6 py-4 px-6 custom-button">Создать свой курс
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none">
            <path d="M9.10029 0.700032C9.00765 0.791555 8.9341 0.900556 8.8839 1.02072C8.8337 1.14088 8.80785 1.26981 8.80785 1.40003C8.80785 1.53026 8.8337 1.65919 8.8839 1.77935C8.9341 1.89951 9.00765 2.00851 9.10029 2.10003L11.0003 4.00003H1.80029C1.25029 4.00003 0.800293 4.45003 0.800293 5.00003C0.800293 5.55003 1.25029 6.00003 1.80029 6.00003H11.0003L9.10029 7.90003C9.00765 7.99155 8.9341 8.10056 8.8839 8.22072C8.8337 8.34088 8.80785 8.46981 8.80785 8.60003C8.80785 8.73026 8.8337 8.85919 8.8839 8.97935C8.9341 9.09951 9.00765 9.20851 9.10029 9.30003C9.49029 9.69003 10.1103 9.69003 10.5003 9.30003L14.0903 5.71003C14.183 5.61752 14.2565 5.50763 14.3067 5.38666C14.3569 5.26568 14.3827 5.136 14.3827 5.00503C14.3827 4.87406 14.3569 4.74438 14.3067 4.62341C14.2565 4.50243 14.183 4.39255 14.0903 4.30003L10.5003 0.700032C10.4088 0.607392 10.2998 0.533841 10.1796 0.483642C10.0594 0.433442 9.93052 0.407593 9.80029 0.407593C9.67007 0.407593 9.54114 0.433442 9.42098 0.483642C9.30082 0.533841 9.19182 0.607392 9.10029 0.700032Z" fill="#7A69EE"/>
          </svg>
        </TheButton>
      </div>
    </div>
    <div class="w-2/5 h-12">
      <img class="goal-img w-full" src="../../../assets/images/landing/goal1.png"/>
    </div>
  </div>
  <div class="flex mx-18 gap-20 mt-20" style="margin-left: 100px; margin-right:100px">
    <div class="w-3/5 h-12 flex items-center justify-center">
      <div>
        <h1 class="text-2xl font-semibold">Статистка обучения</h1>
        <p class="text-lg text-gray-500">Улучшайте курс с помощью набора инструментов аналитики по курсам и урокам</p>
        <TheButton class="mt-6 py-4 px-6 custom-button">Попробовать
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none">
            <path d="M9.10029 0.700032C9.00765 0.791555 8.9341 0.900556 8.8839 1.02072C8.8337 1.14088 8.80785 1.26981 8.80785 1.40003C8.80785 1.53026 8.8337 1.65919 8.8839 1.77935C8.9341 1.89951 9.00765 2.00851 9.10029 2.10003L11.0003 4.00003H1.80029C1.25029 4.00003 0.800293 4.45003 0.800293 5.00003C0.800293 5.55003 1.25029 6.00003 1.80029 6.00003H11.0003L9.10029 7.90003C9.00765 7.99155 8.9341 8.10056 8.8839 8.22072C8.8337 8.34088 8.80785 8.46981 8.80785 8.60003C8.80785 8.73026 8.8337 8.85919 8.8839 8.97935C8.9341 9.09951 9.00765 9.20851 9.10029 9.30003C9.49029 9.69003 10.1103 9.69003 10.5003 9.30003L14.0903 5.71003C14.183 5.61752 14.2565 5.50763 14.3067 5.38666C14.3569 5.26568 14.3827 5.136 14.3827 5.00503C14.3827 4.87406 14.3569 4.74438 14.3067 4.62341C14.2565 4.50243 14.183 4.39255 14.0903 4.30003L10.5003 0.700032C10.4088 0.607392 10.2998 0.533841 10.1796 0.483642C10.0594 0.433442 9.93052 0.407593 9.80029 0.407593C9.67007 0.407593 9.54114 0.433442 9.42098 0.483642C9.30082 0.533841 9.19182 0.607392 9.10029 0.700032Z" fill="#7A69EE"/>
          </svg>
        </TheButton>
      </div>
    </div>
    <div class="w-2/5 h-12">
      <img class="goal-img w-full" src="../../../assets/images/landing/goal1.png"/>
    </div>
  </div> -->
  <div class="contact-us-card-div">
    <div
      class="flex flex-col text-white contact-us-card items-center adaptive-contact"
    >
      <span class="text-center adaptive-contact-title"
        >Оставьте заявку и мы свяжемся с вами для консультации</span
      >
      <TheButton bg="primary" class="contact-us-button" @click="scrollToForm"
        >Оставить заявку
      </TheButton>
    </div>
  </div>
</template>

<script>
import TheButton from "@/components/Button/Button";
export default {
  name: "TheLandingGoals",
  components: {
    TheButton,
  },
  methods: {
    scrollToForm() {
      const formElement = document.getElementById("landing-form");
      if (formElement) {
        formElement.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>
<style scoped>
.contact-us-button {
  max-width: 200px;
  padding: 13px 25px;
  border-radius: 25px;
  margin-top: 40px;
}
.contact-us-card-div {
  background: #06172b;
  padding: 80px 100px;
}
.contact-us-card {
  padding: 80px 215px;
  background: #2f3342;
  border-radius: 40px;
}
.goal-header {
  margin-top: 100px;
  margin-left: 100px;
  margin-right: 100px;
}

.custom-button {
  background: #fff;
  box-sizing: border-box;
  color: #7a69ee;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 8px;
  width: 248px;
  height: 56px;
  border: 2px solid #7a69ee;
  filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.05));
  border-radius: 32px;
}

.adaptive-contact-title {
  vertical-align: middle;
  font-weight: 700;
  font-size: 40px;
  color: #fff;
}

@media (max-width: 650px) {
  .adaptive-contact-title {
    font-size: 28px;
  }

  .contact-us-card-div {
    padding: 40px 16px;
  }

  .contact-us-card {
    padding: 80px 20px;
  }
}
</style>
