<template>
  <div class="pb-12">
    <div
      class="fixed top-0 left-0 w-full h-16 flex justify-between items-center shadow-base bg-white"
    >
      <div class="h-full w-14" />
      <div class="text-2xl leading-6 font-semibold text-gray-900">
        {{ $t("preview_mode") }}
      </div>
      <div class="w-14">
        <router-link :to="`/news/${$route.params.id}/content`">
          <XIcon class="w-6 h-6 text-gray-900" />
        </router-link>
      </div>
    </div>
    <div class="bg-white rounded-4xl px-16 py-16 shadow-lg shadow-lg">
      <BreadcrumbsComponent :breadcrumbs="breadcrumbs" />
      <div class="pt-10 border-t border-gray-200 flex flex-col gap-8">
        <div class="flex flex-col gap-2">
          <h2
            class="text-base leading-6 font-semibold tracking-wider uppercase"
            :style="{ color: publication.topic_color }"
          >
            #{{ publication.topic }}
          </h2>
          <h1
            class="text-4xl leading-10 font-extrabold tracking-tight text-gray-900"
          >
            {{ publication.title }}
          </h1>
        </div>
        <component
          v-for="contentItem in publication.content"
          :is="contentItem.type"
          class="mb-6"
          :id="contentItem.id"
          :key="`content_${contentItem.id || contentItem.key}`"
          :content="contentItem.content"
          :attachments="contentItem.attachments"
          :is_disabled="true"
          :url="contentItem.content"
          :src="contentItem.src"
          :image="contentItem.content"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TextContent from "@/views/core/News/components/preview/TextContent";
import { mapFields } from "vuex-map-fields";
import ImageContent from "@/views/core/News/components/preview/ImageContent";
import AudioContent from "@/views/core/News/components/preview/AudioContent";
import VideoContent from "@/views/core/News/components/preview/VideoContent";
import BreadcrumbsComponent from "@/components/Breadcrumbs/Breadcrumbs";
import { mapActions } from "vuex";
import { GET_NEWS_PUBLICATION } from "@/store/news";
import { XIcon } from "@heroicons/vue/solid";

export default {
  components: {
    BreadcrumbsComponent,
    text: TextContent,
    image: ImageContent,
    audio: AudioContent,
    video: VideoContent,
    XIcon,
  },
  data() {
    return {
      breadcrumbs: [],
      publication: {},
    };
  },
  async mounted() {
    const data = await this[GET_NEWS_PUBLICATION](this.$route.params.id);
    this.publication = {
      ...data,
      content: data.content.map((c) => ({
        ...c,
        content: c.media_file || c.text,
      })),
    };
    this.setBreadcrumbs();
  },
  methods: {
    ...mapActions([GET_NEWS_PUBLICATION]),
    setBreadcrumbs() {
      this.breadcrumbs = [
        {
          name: "EdCrunch Academy",
          path: "/",
        },
        {
          name: this.$t("news"),
          path: "/news/list",
        },
        {
          name: this.publication.title,
          path: `/news/edit/${this.$route.params.id}`,
        },
      ];
    },
  },
  computed: {
    ...mapFields(["core.news.content"]),
  },
};
</script>
